import {
  GET_TENANT_BEGIN,
  GET_TENANT_SUCCESS,
  GET_TENANT_FAILURE,
} from "../../action/tenant/getTenant";

const initialState = {
  tenantLoading: true,
  tenantData: null,
};

const getTenantReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TENANT_BEGIN:
      return {
        ...state,
        tenantLoading: true,
        tenantData: null,
      };
    case GET_TENANT_SUCCESS:
      return {
        ...state,
        tenantData: payload,
        tenantLoading: false,
      };
    case GET_TENANT_FAILURE:
      return {
        ...state,
        tenantData: null,
        tenantLoading: false,
      };
    default:
      return state;
  }
};

export default getTenantReducer;
