import React from "react";
import { getUserRole } from "../../service/AuthService";
// import CronJob from "./cronjob/Index";
// import FAQ from "./FaqSetup/FaqIndex";
// import JobTemplate from "./JobTemplate/Index";
import SMSTemplate from "./smstemplates/Index";
import EmailTemplate from "./emailtemplates/Index";

import JobTemplate from "./JobTemplate/Index";
// import SMSTemplate from "./smsTemplate/Index";
// import TagManagement from "./tagmanagement/Index";
function AdminConsole() {
  return (
    <div className="row">
      <>
        {/* {getUserRole() != "CustomerSuccess" && <CronJob />}
        {getUserRole() != "CustomerSuccess" && <EmailTemplate />}
        <FAQ /> */}
        {/* <JobTemplate /> */}

        {getUserRole() != "CustomerSuccess" && <SMSTemplate />}
        {getUserRole() != "CustomerSuccess" && <EmailTemplate />}

        <JobTemplate />
        {/* {getUserRole() != "CustomerSuccess" && <SMSTemplate />}
        <TagManagement /> */}
      </>
    </div>
  );
}

export default AdminConsole;
