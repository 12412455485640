import React from "react";
import { SidebarLogo, roleNames } from "../../../constants/Constants";
import {
  getTenantLogo,
  getOwner,
  isCurator,
  getUserRole,
  getTenantType,
} from "../../../service/AuthService";

const SidebarTop = ({ user, role, tenant }) => {
  const tenantType = getTenantType();
  return (
    <div className="top">
      <div className="d-flex align-items-center">
        {getTenantLogo() ? (
          <img
            src={getTenantLogo()}
            width="50"
            height="50"
            className="p-2"
            style={{
              borderRadius: "50%",
              objectFit: "contain",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              background: "white",
            }}
          />
        ) : (
          <div
            className="brand-logo"
            style={{ backgroundImage: `url(${SidebarLogo})` }}
          ></div>
        )}

        <div className="title px-2">
          <h6 className="mb-0">{user}</h6>
          <small className="mb-0">
            Role:{" "}
            {getOwner()
              ? getUserRole() == "Admin"
                ? "Client Admin"
                : isCurator()
                ? "Curator"
                : `${roleNames[role]}`
              : isCurator()
              ? tenantType === 1
                ? "Client Curator"
                : "Curator"
              : roleNames[role]}
          </small>
          <br />
          {tenant && (
            <small className="mb-0">
              Company: {tenant.replace("GIG - ", "")}
            </small>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidebarTop;
