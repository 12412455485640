import React, { useState } from "react";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import { toast } from "react-toastify";
import sparkles from "../../../../../assets/lottie/sparkles.json";
import checkMark from "../../../../../assets/lottie/checkmark.json";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../common/loader/Loader";

const { Anime } = ReactAnime;

function DeactivateClient(props) {
  const [deleteValue, setdelete] = useState(false);
  const [talentEmailChecked, setTalentEmailChecked] = useState(false);
  const [loop] = useState(false);
  const [loading, setLoading] = useState(false);
  props?.parent == "tenantDetailsPopUp" && props?.setOpenDropDownDiv(false);

  const close = () => {
    props.parent == "tenantDetailsPopUp" && props.closeModal();
    props.handleSuccess(true);
    props.parent == "tenantDetailsPopUp" && props.setopenTenantPopUP(false);
  };
  const handleCheck = (e) => {
    // console.log(e.target.checked)
    if (e.target.checked) {
      setTalentEmailChecked(true);
    } else {
      setTalentEmailChecked(false);
    }
  };

  const deleteFun = async () => {
    // api call to send data
    const clientName =
      props.tenantObj.firstName + " " + props.tenantObj.lastName;
    const tenantName = props.tenantName;
    const userid = props.userid;
    let email = "";
    if (talentEmailChecked === true) {
      email = props.tenantEmail;
    }
    const paramters = {
      tenantName,
      userid,
      email,
      clientName,
      emailAddress: props.tenantEmail,
      tenantType: props.tenantType,
    };
    try {
      setLoading(true);
      const res = await post("/dashboard/sa/deletebyname", paramters);
      if (res.status === 200) {
        setdelete(true);
        setLoading(false);
        props.getTenantData();
      } else {
        toast.error("Error occured while updating record");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing Tenant Details");
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div v-if="showDeleteRequestForm">
        {props.employeeDelete !== "employeedelete" ? (
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i className="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to deactivate this Employee?
            </p>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Confirm Delete
                {}
              </button>
            </div>
          </div>
        ) : deleteValue === false ? (
          <div className="bl-modal-body">
            <div className="text-center">
              <div className="avatar avatar-lg avatar-red2">
                <i
                  className="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              {props.users === "users" ? (
                <p className="text-center lead mt-3">
                  Are you sure you want to deactivate this Client?
                </p>
              ) : (
                <p className="text-center lead mt-3">
                  Are you sure you want to delete this Employee?
                </p>
              )}

              <p>Name: {props.tenantName.replace("GIG - ", "")}</p>
              <div className="col-12 d-flex justify-content-center">
                <div className="form-check form-switch d-flex">
                  <p className="mr-2" style={{ marginTop: 3 }}>
                    Send Email To{" "}
                    {props?.tenantObj?.typename || props.tenantType}
                  </p>
                  <div>
                    <input
                      name="candidateEmail"
                      className="form-check-input ms-1"
                      type="checkbox"
                      onChange={(e) => handleCheck(e)}
                      checked={talentEmailChecked ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center my-4">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary mr-2"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={deleteFun}
              >
                <span>Deactivate</span>{" "}
              </button>
            </div>
          </div>
        ) : (
          <div
            className="section section-sm pb-0 d-block"
            style={{ display: "none" }}
          >
            <div
              id="EmployeeManageSuccessAnimation"
              className="success-animation"
            >
              <div className="checkmark">
                <Lottie loop={loop} animationData={checkMark} play></Lottie>
              </div>
              <Anime
                initial={[
                  {
                    targets: "#LottieSparkles",
                    opacity: 0,
                    delay: 1000,
                  },
                ]}
              >
                <div className="sparkles">
                  <Lottie
                    loop={loop}
                    animationData={sparkles}
                    id="LottieSparkles"
                    play
                  ></Lottie>
                </div>
              </Anime>
            </div>
            <div className="success-message" style={{ opacity: "1" }}>
              <div className="container">
                <div className="col-lg-12">
                  <h4 className="text-center">
                    {props.users === "users"
                      ? "Client Deactivated!"
                      : "Employee Deleted!"}
                  </h4>
                  <div className="text-center">
                    {props.users === "users" ? (
                      <p>The selected client has been Deactivated</p>
                    ) : (
                      <p>The selected employee has been deleted</p>
                    )}
                  </div>
                  <div className="card card-flat card-lg">
                    <div className="card-body">
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          {props.users !== "users" ? "Employee" : "Client"}
                        </label>
                        <div>{props.tenantName.replace("GIG - ", "")}</div>
                      </div>
                      {props.users !== "users" ? (
                        <>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Email
                            </label>
                            <div>name@company.com</div>
                          </div>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Role
                            </label>
                            <div>Recruiter</div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-secondary mr-2"
                      onClick={close}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DeactivateClient;
