import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import ProfileImage from "../../../../common/profileimage/ProfileImage";
import { getAdditionalInfoOverView } from "../../../../../action/talentcommunity/AdditionalInfoOverView";
import Loader from "../../../../common/loader/Loader";
import PaginationComponent from "../../../../common/pagination/Pagination";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";

const AdditionalInfoReminderEmail = (props) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState({
    all: 0,
    filled: 0,
    notFilled: 0,
  });
  const [overViewData, setOverViewData] = useState([]);

  const { loading, additionalInfoOverviewData } = useSelector(
    (state) => ({
      loading: state.getAdditionalInfoOverView.loading,
      additionalInfoOverviewData:
        state.getAdditionalInfoOverView.additionalInfoOverviewData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (additionalInfoOverviewData) {
      setCount({
        all: additionalInfoOverviewData?.All || 0,
        filled: additionalInfoOverviewData?.Filled || 0,
        notFilled: additionalInfoOverviewData?.NotFilled || 0,
      });
      setOverViewData(
        additionalInfoOverviewData?.data
          ? JSON.parse(additionalInfoOverviewData.data)
          : []
      );
    }
  }, [additionalInfoOverviewData]);

  useEffect(() => {
    fetchCuratorData(
      props.poolId,
      10,
      1,
      selectedTab === "invited" ? "All" : selectedTab
    );
  }, [selectedTab, currentPage, dispatch]);

  const fetchCuratorData = (poolId, limit, currentPage, tab) => {
    const payload = {
      poolId: props.poolId,
      limit: 10,
      currentPage: 1,
      tab: tab,
    };
    dispatch(getAdditionalInfoOverView(payload));
  };

  const handleClickTab = (item) => {
    setSelectedTab(item);
  };
  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    fetchCuratorData(
      props.poolId,
      10,
      currentPage,
      selectedTab === "invited" ? "All" : selectedTab
    );
  };

  const handleReminderSend = async (item) => {
    setIsLoading(true);
    let data = {
      poolId: props.poolId,
      candidateId: item.candidateId,
      id_reminder: item.id_reminder,
    };
    await post("/community/additionalInfoManualReminderMail", data)
      .then(async (res) => {
        toast.success("Reminder Send Successfully!");
        setIsLoading(false);
        fetchCuratorData(
          props.poolId,
          10,
          1,
          selectedTab === "invited" ? "All" : selectedTab
        );
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong, Please try again later.");
      });
  };

  const handleBulkReminder = async () => {
    setIsLoading(true);
    let data = {
      poolId: props.poolId,
    };
    await post("/community/additionalInfoBulkManualReminderMail", data)
      .then(async (res) => {
        toast.success("Bulk Reminder(s) Send Successfully!");
        setIsLoading(false);
        fetchCuratorData(
          props.poolId,
          10,
          1,
          selectedTab === "invited" ? "All" : selectedTab
        );
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong, Please try again later.");
      });
  };

  return (
    <>
      {(loading || isLoading) && <Loader />}
      <div className="m-2">
        <div className="">
          <div className="">
            <div className="">
              <div className="row justify-content-center">
                <div className="col-xl-12">
                  <div className="hero-card-header card card-lg card-flat card-bottom-straight">
                    <div className="card-body pb-0 d-flex flex-column justify-content-between">
                      <ul className="nav nav-tabs justify-content-between">
                        <li className="nav-item w-25 d-flex justify-content-center">
                          <button
                            type="button"
                            className={
                              selectedTab == "All"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                            onClick={() => handleClickTab("All")}
                          >
                            <span className="tag mb-1">{count.all}</span>{" "}
                            <div>All</div>
                          </button>
                        </li>{" "}
                        <li className="nav-item w-25 d-flex justify-content-center">
                          <button
                            type="button"
                            className={
                              selectedTab == "filled"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                            onClick={() => handleClickTab("filled")}
                          >
                            <span className="tag mb-1">{count.filled}</span>{" "}
                            <div>Filled Form</div>
                          </button>
                        </li>{" "}
                        <li className="nav-item w-25 d-flex justify-content-center">
                          <button
                            type="button"
                            className={
                              selectedTab == "notFilled"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                            onClick={() => handleClickTab("notFilled")}
                          >
                            <span className="tag mb-1">{count.notFilled}</span>{" "}
                            <div>Not Filled Form</div>
                          </button>
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <div
              className="card card-flat card-lg card-top-straight"
              style={{ borderTop: "none" }}
            >
              <div className="card-body">
                <div>
                  <div className="table-wrap">
                    <table className="table table-sortable table-hoverable-cells table-cards-1024">
                      <thead>
                        <tr className="d-none d-lg-table-row">
                          <th className="d-flex align-items-start">
                            Candidate Name{" "}
                            <i
                              aria-hidden="true"
                              className="ml-2 font-coral font-16"
                            />
                          </th>{" "}
                          <th>
                            Invited On{" "}
                            <i
                              aria-hidden="true"
                              className="ml-2 font-muted font-16"
                            />
                          </th>{" "}
                          <th className="text-center ">
                            Form Filled{" "}
                            <i
                              aria-hidden="true"
                              className=" ml-2 font-muted font-16"
                            />
                          </th>{" "}
                          <th className="text-center ">
                            Reminder{" "}
                            <i
                              aria-hidden="true"
                              className="ml-2 font-muted font-16"
                            />
                          </th>
                          {selectedTab === "notFilled" && (
                            <th className="">
                              Action{" "}
                              <i
                                aria-hidden="true"
                                className="ml-2 font-muted font-16"
                              />
                            </th>
                          )}
                        </tr>
                      </thead>{" "}
                      {selectedTab === "All" && (
                        <tbody>
                          {overViewData &&
                            overViewData?.map((item, index) => (
                              <tr key={index}>
                                <td className="no-hover">
                                  {/* <span className="ml-2">{item.name}</span> */}
                                  <div className="avatar-name-candidatestableview d-flex align-items-center">
                                    <div>
                                      <span>
                                        <ProfileImage
                                          imageSrc={item?.profileImagePath}
                                          imageWidth={"35"}
                                          imageHeight={"35"}
                                          avatarSize={"35"}
                                          avatarRound={"80px"}
                                          firstName={item?.firstName}
                                          lastName={item?.lastName}
                                        />
                                      </span>
                                    </div>
                                    <div className="ml-3">
                                      <strong className="mb-0 me-2">
                                        {item.firstName} {item.lastName}
                                      </strong>
                                    </div>
                                  </div>
                                </td>
                                <td className="no-hover">
                                  {moment(
                                    new Date(item.invitationCreatedDate),
                                    "YYYYMMDD"
                                  ).format("MM/DD/YYYY")}
                                </td>
                                <td className="text-center no-hover">
                                  <span
                                    className={`tag ${
                                      item.isAICompleted
                                        ? "tag-green4"
                                        : "tag-red1"
                                    }`}
                                  >
                                    {item.isAICompleted ? "Yes" : "No"}
                                  </span>
                                </td>
                                <td className="text-center no-hover">
                                  {item.reminderCount}
                                </td>
                                {/* <td className="no-hover">{item.status}</td> */}
                              </tr>
                            ))}
                        </tbody>
                      )}
                      {selectedTab === "filled" && (
                        <tbody>
                          {overViewData.map((item, index) => (
                            <tr key={index}>
                              <td className="no-hover">
                                <div className="avatar-name-candidatestableview d-flex align-items-center">
                                  <div>
                                    <span>
                                      <ProfileImage
                                        imageSrc={item?.profileImagePath}
                                        imageWidth={"35"}
                                        imageHeight={"35"}
                                        avatarSize={"35"}
                                        avatarRound={"80px"}
                                        firstName={item?.firstName}
                                        lastName={item?.lastName}
                                      />
                                    </span>
                                  </div>
                                  <div className="ml-3">
                                    <strong className="mb-0 me-2">
                                      {item.firstName} {item.lastName}
                                    </strong>
                                  </div>
                                </div>
                              </td>
                              <td className="no-hover">
                                {moment(
                                  new Date(item.invitationCreatedDate),
                                  "YYYYMMDD"
                                ).format("MM/DD/YYYY")}
                              </td>
                              <td className="text-center no-hover">
                                <span
                                  className={`tag ${
                                    item.isAICompleted
                                      ? "tag-green4"
                                      : "tag-red1"
                                  }`}
                                >
                                  {item.isAICompleted ? "Yes" : "No"}
                                </span>
                              </td>
                              <td className="text-center no-hover">
                                {item.reminderCount}
                              </td>
                              {/* <td className="no-hover">{item.status}</td> */}
                            </tr>
                          ))}
                        </tbody>
                      )}
                      {selectedTab === "notFilled" && (
                        <tbody>
                          {overViewData.map((item, index) => (
                            <tr key={index}>
                              <td className="no-hover">
                                <div className="avatar-name-candidatestableview d-flex align-items-center">
                                  <div>
                                    <span>
                                      <ProfileImage
                                        imageSrc={item?.profileImagePath}
                                        imageWidth={"35"}
                                        imageHeight={"35"}
                                        avatarSize={"35"}
                                        avatarRound={"80px"}
                                        firstName={item?.firstName}
                                        lastName={item?.lastName}
                                      />
                                    </span>
                                  </div>
                                  <div className="ml-3">
                                    <strong className="mb-0 me-2">
                                      {item.firstName} {item.lastName}
                                    </strong>
                                  </div>
                                </div>
                              </td>
                              <td className="no-hover">
                                {moment(
                                  new Date(item.invitationCreatedDate),
                                  "YYYYMMDD"
                                ).format("MM/DD/YYYY")}
                              </td>
                              <td className="text-center no-hover">
                                <span
                                  className={`tag ${
                                    item.isAICompleted
                                      ? "tag-green4"
                                      : "tag-red1"
                                  }`}
                                >
                                  {item.isAICompleted ? "Yes" : "No"}
                                </span>
                              </td>
                              <td className="text-center no-hover">
                                {item.reminderCount}
                              </td>
                              <td
                                className="no-hover"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent:"space-between"
                                }}
                              >
                                <span
                                  style={{
                                    width: "80px",
                                    display: "inline-block",
                                    textWrap: "nowrap"
                                  }}
                                  className="mr-2"
                                >
                                  {item.reminderCount === 0
                                    ? "1st Reminder Due"
                                    : item.reminderCount === 1
                                    ? "2nd Reminder Due"
                                    : "No Response"}
                                </span>

                                <div
                                  className="tooltip-wrapper"
                                  data-toggle="tooltip"
                                  title={
                                    item.reminderCount < 2
                                      ? "Button will be enabled once 1st and 2nd reminders are sent."
                                      : ""
                                  }
                                >
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    disabled={item.reminderCount < 2}
                                    onClick={() => handleReminderSend(item)}
                                  >
                                    Resend
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {selectedTab === "notFilled" &&
                      overViewData[0]?.reminderCount > 1 &&
                      overViewData?.length > 1 && (
                        <div className="text-right mt-4 mb-2 mr-4">
                          <a
                            style={{ color: "#0d6efd" }}
                            href="#"
                            className=""
                            onClick={() => handleBulkReminder()}
                          >
                            Send Bulk Reminder
                          </a>
                        </div>
                      )}
                    <hr className="m-0" />
                    <div>
                      {overViewData?.length > 0 && (
                        <>
                          <PaginationComponent
                            currentPage={currentPage}
                            totalItems={
                              selectedTab === "all"
                                ? count.all
                                : selectedTab === "filled"
                                ? count.filled
                                : count.notFilled
                            }
                            itemsPerPage={10}
                            onPageChange={handlePageChange}
                            limit={10}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalInfoReminderEmail;
