import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPaperPlane, FaUserPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Loader from "../../loader/Loader";
import Tabs from "../Tabs";
import SentSMS from "./SentSMS";
import AddRecipientsForSms from "../recipients/AddRecipientsForSms";
import { FetchLastMessageSentByUser } from "../../../action/communication/Communication";

const SMS = ({
  selectedTalents,
  smsContent,
  setSmsContent,
  errorData,
  handleConfirmation,
  isFrom,
  setShowJobModal,
  selectedRecipients,
  setSelectedRecipients,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAllRecipients, setShowAllRecipients] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("compose");
  const [isSending, setIsSending] = useState(false);

  const [loading, setLoading] = useState(false);
  const [addRecipientsModal, setAddRecipientsModal] = useState(false);

  useEffect(() => {
    if (selectedTalents && selectedTalents?.length > 0) {
      setSelectedRecipients([...selectedTalents]);
    }
    if (selectedTalents && selectedTalents?.length === 1) {
      dispatch(
        FetchLastMessageSentByUser({
          messageTo:
            selectedTalents[0]?.homePhoneCode + selectedTalents[0]?.homePhone,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isSending) {
      setTimeout(() => {
        setIsSending(false);
      }, 2000);
    }
  }, [isSending]);

  const [charCount, setCharCount] = useState(smsContent.length); // State to track character count

  // const handleContentChange = (value) => {
  //   // Update the character count and content
  //   if (value.length <= 622) {
  //     setSmsContent(value);
  //     setCharCount(value.length);
  //   } else {
  //     // Truncate the content if it exceeds the limit
  //     console.log(value);
  //     setSmsContent(value.slice(0, 622));
  //     setCharCount(622);
  //   }
  // };

  const handleCandidateRemove = (item) => {
    setSelectedRecipients((prev) =>
      prev.filter((candidate) => candidate.userId !== item.userId)
    );
  };
  const handleNavigate = () => {
    setShowJobModal(false);
    history.push("/high5Hire/talents");
  };
  const handleClear = () => {
    setSmsContent("");
  };
  return (
    <>
      <Container style={{ fontSize: "14px", zIndex: "3" }}>
        {loading && <Loader />}

        <Tabs
          from={"SMS"}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isFrom={isFrom}
        />

        <Row style={{ minHeight: "60vh", fontSize: "14px", marginTop: "5px" }}>
          {activeTab === "compose" &&
            (addRecipientsModal ? (
              <AddRecipientsForSms
                setAddRecipientsModal={setAddRecipientsModal}
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
              />
            ) : (
              <>
                <Col md={8} sm={12} lg={8}>
                  <div className="mb-3 mt-2">
                    <div className="mt-4">
                      {(!selectedRecipients ||
                        selectedRecipients ||
                        selectedRecipients?.length > 0) &&
                        isFrom !== "TalentProfileEdit" && (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setAddRecipientsModal(true);
                                setShowModal(true);
                              }}
                            >
                              {" "}
                              <FaUserPlus className="mr-2" /> Add Recipients
                            </button>
                          </>
                        )}
                    </div>
                    <div className="color">{errorData["recipient"]}</div>
                  </div>
                  <Row className="messageForm mb-5">
                    <Col sm={12} md={12}>
                      <div className="d-flex justify-content-between">
                        <label htmlFor="content" className="label mb-2">
                          Message Body* {/* Display remaining characters */}
                        </label>
                        <label className="label mb-2">{`( ${
                          160 - smsContent.length
                        } Characters left out of 160 )`}</label>
                      </div>

                      <textarea
                        value={smsContent}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue.length <= 622) {
                            setSmsContent(inputValue);
                          }
                        }}
                        style={{
                          width: "100%",
                          minHeight: "120px",
                          overflowX: "scroll",
                          fontWeight: "normal",
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "0px",
                        }}
                        onKeyDown={(e) => {
                          if (
                            smsContent.length >= 622 &&
                            e.key !== "Backspace"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          const pastedText =
                            e.clipboardData.getData("text/plain");
                          const newText = smsContent + pastedText;
                          const trimmedText = newText.slice(0, 622);
                          setSmsContent(trimmedText);
                        }}
                      />

                      <div className="color">{errorData["msgContent"]}</div>
                    </Col>

                    <Col sm={12} md={12}>
                      <Row className="d-flex justify-content-between mt-3">
                        <Col
                          md={12}
                          sm={12}
                          className="d-flex justify-content-between"
                        >
                          <button
                            className="btn btn-sm btn-success align-items-right mt-2"
                            onClick={() => {
                              handleClear();
                            }}
                            disabled={isSending || smsContent === ""}
                          >
                            <i className="fa fa-eraser"></i> Clear
                          </button>
                          <button
                            onClick={() => {
                              handleConfirmation("sendSms");
                            }}
                            style={{ float: "right" }}
                            disabled={
                              isSending ||
                              smsContent === "" ||
                              !selectedRecipients ||
                              selectedRecipients?.length === 0
                            }
                            className="btn btn-sm btn-primary align-items-right mt-2"
                          >
                            <FaPaperPlane size={18} className="mr-2" /> Send
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={4}
                  sm={12}
                  lg={4}
                  style={{
                    borderLeft: "1px solid #ccc",
                    height: "80vh",
                    overflow: "scroll",
                  }}
                >
                  <div>
                    {selectedRecipients?.length > 0 && (
                      <strong>
                        Selected
                        {selectedRecipients?.length > 1
                          ? " Recipients"
                          : " Recipient"}
                        {" : "}
                        {selectedRecipients?.length}
                      </strong>
                    )}
                  </div>

                  <div className="recipient-user-list">
                    {selectedRecipients?.length > 0 &&
                      (showAllRecipients
                        ? selectedRecipients.map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          ))
                        : selectedRecipients.slice(0, 5).map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          )))}
                    {selectedRecipients?.length > 5 && !showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(true)}
                      >
                        <span>View all</span>
                      </button>
                    )}
                    {selectedRecipients?.length > 5 && showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(false)}
                      >
                        <span>View less</span>
                      </button>
                    )}
                  </div>
                </Col>
              </>
            ))}

          {activeTab === "sent" && (
            <SentSMS selectedRecipients={selectedRecipients} />
          )}
        </Row>

        {/* {previewEmail && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Email Preview"}
            isModalOpen={previewEmail}
            setModalClose={setPreviewEmail}
            isFrom="EmailBroadcast"
            modalFor="preview"
            data={{
              content: smsContent,
              selectedCandidates: selectedTalents.map((t) => t.email),
              selectedRecruiter: selectedRecruiter,
            }}
          />
        )} */}
      </Container>
    </>
  );
};

export default SMS;
