import { toast } from "react-toastify";

export const joinTalentPool = (formData, resumeFile) => {
  if (resumeFile?.fileName === "") {
    toast.error("Please upload the resume");
    return false;
  } else if (formData.firstName === "") {
    toast.error("Please enter first name");
    return false;
  } else if (formData.lastName === "") {
    toast.error("Please enter last name");
    return false;
  } else if (formData.email === "") {
    toast.error("Please enter first name");
    return false;
  } else if (formData.experienceLevel === "") {
    toast.error("Please select experience");
    return false;
  } else if (formData.city === "") {
    toast.error("Please enter city name");
    return false;
  }
  return true;
};
