import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import AssignExistingCurators from "./AssignExistingCurators";
import {
  getRecruiterDataForCurator,
  getRecruiterCountForCurator,
  getClientTalentCommunity,
} from "../../../action/curator/AssignCurator";
// import { getDropdownValues } from "../../../../actions/common/GetDropdownValues";
import Loader from "../../common/loader/Loader";
import ClientTalentCommunity from "./ClientTalentCommunity";
import { post } from "../../../service/ApiService";
import UpdateCurator from "./UpdateCurator";
import {
  getUpdateCurator,
  getUpdateCuratorCount,
} from "../../../action/curator/UpdateCurator";
import { getClientNameData } from "../../../action/common/ClientName";
import ClientData from "./ClientData";
import {
  getTenantType,
  getUserRole,
  getUserTenant,
  getUserTenantID,
} from "../../../service/AuthService";

const AssignCurator = ({
  assignCuratorShow,
  setAssignCuratorShow,
  getTenantData,
}) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const isFirstRenderSearch = useRef(true);
  const [activeTab, setActiveTab] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSize, setDataSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataTotal, setDataTotal] = useState(0);
  const [commonLoading, setCommonLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedTempValue, setSelectedTempValue] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [validation, setValidation] = useState({
    community: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    country: "",
    client: "",
  });
  const [selectedData, setSelectedData] = useState({
    country: "",
    state: "",
    address: [],
    language: [],
    region: [],
    jobTitle: [],
    activity: [],
    roles: [],
    jobCategory: [],
    experience: [],
  });
  const [filterParams, setFilterParams] = useState({});
  const [sortArray, setSortArray] = useState([]);
  const [sortArrayUpdateCurator, setSortArrayUpdateCurator] = useState([]);
  const [tenant, setTenant] = useState(null);
  const [curatorLoading, setCuratorLoading] = useState(false);
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);

  const {
    recruiterData,
    recruiterCount,
    communityData,
    loading,
    countLoading,
    communityLoading,
    updateCuratorLoading,
    updateCuratorData,
    updateCuratorCount,
    updateCuratorCountLoading,
    clientName,
    nameLoading,
  } = useSelector(
    (state) => ({
      recruiterData: state.getRecruiterForCuratorReducer.recruiterData,
      recruiterCount: state.getRecruiterForCuratorReducer.recruiterCount,
      communityData: state.getRecruiterForCuratorReducer.communityData,
      communityLoading: state.getRecruiterForCuratorReducer.communityLoading,
      loading: state.getRecruiterForCuratorReducer.loading,
      countLoading: state.getRecruiterForCuratorReducer.countLoading,
      updateCuratorData: state.getUpdateCuratorReducer.updateCuratorData,
      updateCuratorLoading: state.getUpdateCuratorReducer.loading,
      updateCuratorCount: state.getUpdateCuratorReducer.updateCuratorCount,
      updateCuratorCountLoading: state.getUpdateCuratorReducer.countLoading,
      clientName: state.getClientNameReducer.clientName,
      nameLoading: state.getClientNameReducer.nameLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    // dispatch(getDropdownValues());
    if (getTenantType() == 1) {
      setTenant({
        label: getUserTenant(),
        value: getUserTenantID(),
      });
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    recruiterCount.length > 0 &&
      setDataTotal(recruiterCount[0]["recruiterCount"]);
  }, [recruiterCount]);

  useEffect(() => {
    setDataSize(10);
  }, [activeTab]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchRecruiterData(
          1,
          dataSize,
          searchData,
          sortArray,
          true,
          clientData ? clientData.value : null
        );
        const payload = {
          searchText: searchData,
          filterObject: filterParams,
          poolId: clientData ? clientData.value : null,
        };
        dispatch(getRecruiterCountForCurator(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  useEffect(() => {
    if (isFirstRenderSearch.current) {
      isFirstRenderSearch.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchValue.length === 0 || searchValue.length >= 2) {
        fetchDataUpdateCurator(1, dataSize, searchValue, sortArray);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      jobTitle:
        status == "" ? [] : status == "jobTitle" ? val : selectedData.jobTitle,
      language:
        status == "" ? [] : status == "language" ? val : selectedData.language,
      region:
        status == "" ? [] : status == "region" ? val : selectedData.region,
      jobCategory:
        status == ""
          ? []
          : status == "jobCategory"
          ? val
          : selectedData.jobCategory,
      roles: status == "" ? [] : status == "roles" ? val : selectedData.roles,
      activity:
        status == "" ? [] : status == "activity" ? val : selectedData.activity,
      experience:
        status == ""
          ? []
          : status == "experience"
          ? val
          : selectedData.experience,
      country:
        status == ""
          ? ""
          : status == "country"
          ? val
          : status == "state"
          ? ""
          : status == "address"
          ? ""
          : selectedData.country,
      state:
        status == ""
          ? ""
          : status == "state"
          ? val
          : status == "address"
          ? ""
          : status == "country"
          ? ""
          : selectedData.state,
      address:
        status == ""
          ? []
          : status == "address"
          ? val
          : status == "country"
          ? []
          : status == "state"
          ? []
          : selectedData.address,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  const applyFilter = (val, status) => {
    setopenTenantPopUP(false);
    setCurrentPage(1);
    const payload = {
      currentPage: 1,
      limit: dataSize,
      searchText: searchData,
      sortQuery: sortArray,
      filterObject: getFilterParamsNew(val, status),
      poolId: clientData ? clientData.value : null,
      tenantId: tenant ? tenant.value : 0,
    };
    dispatch(getRecruiterDataForCurator(payload));
    const countPayload = {
      searchText: searchData,
      filterObject: getFilterParamsNew(val, status),
      poolId: clientData ? clientData.value : null,
    };
    dispatch(getRecruiterCountForCurator(countPayload));
    clearAll();
  };

  const fetchCommunityData = (value) => {
    const payload = {
      tenantId: tenant ? tenant.value : 0,
      searchText: value ? value : "",
    };
    dispatch(getClientTalentCommunity(payload));
  };

  const fetchClientDetails = (value) => {
    let payload = {
      searchText: value ? value : "",
    };
    dispatch(getClientNameData(payload));
  };

  const fetchRecruiterData = (
    pageNumber,
    limit,
    searchVal,
    sortArray,
    keepFilterParams,
    client
  ) => {
    const payload = {
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
      filterObject: keepFilterParams
        ? filterParams
        : getFilterParamsNew("", ""),
      poolId: client,
      tenantId: tenant ? tenant.value : 0,
    };
    dispatch(getRecruiterDataForCurator(payload));
  };
  const fetchDataUpdateCurator = (pageNumber, limit, searchVal, sortArray) => {
    const payload = {
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
      poolId: clientData ? clientData.value : null,
      tenantId: tenant ? tenant.value : 0,
    };
    dispatch(getUpdateCurator(payload));
    dispatch(getUpdateCuratorCount(payload));
  };

  const assignCuratorValidate = () => {
    let isValid = true;
    let communityError = "";
    let clientError = "";
    if (!clientData || !clientData?.value) {
      toast.error("Please select client");
      clientError = "*Required field";
      isValid = false;
    } else if (!clientData || !clientData?.value) {
      toast.error("Please select community");
      communityError = "*Required field";
      isValid = false;
    } else if (selectedValues.length == 0) {
      toast.error("Please select atleast one recruiter");
      isValid = false;
    }
    setValidation({
      ...validation,
      community: communityError,
      client: clientError,
    });
    return isValid;
  };

  const handleAssignCurator = async () => {
    let validObj = assignCuratorValidate();
    if (validObj) {
      try {
        setCommonLoading(true);
        let communityName = communityData.filter(
          (item) => item.poolId == clientData?.value
        )[0].poolname;
        const payload = {
          communityId: clientData?.value,
          communityName: communityName,
          recruiterDetails: selectedValues,
          tenantId: tenant ? tenant.value : 0,
          clientName: tenant ? tenant.label : "",
        };

        await post("/curator/assignexistingcurator", payload);
        setCommonLoading(false);
        setAssignCuratorShow(false);
        toast.success("Curator invited successfully");
        if (getUserRole() == "CustomerSuccess") {
          getTenantData();
        }
      } catch (err) {
        toast.error("Something went wrong. please try again later");
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRecruiterData(
      pageNumber,
      dataSize,
      searchData,
      sortArray,
      true,
      clientData ? clientData.value : null
    );
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchRecruiterData(
      1,
      ds,
      searchData,
      sortArray,
      true,
      clientData ? clientData.value : null
    );
  };
  const handleUpdateCuratorSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchDataUpdateCurator(1, ds, searchValue, sortArray);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const handleUpdateCuratorSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const fetchRecruiter = (client) => {
    let poolId = client ? client.value : null;
    fetchRecruiterData(1, dataSize, searchData, sortArray, true, poolId);
    const payload = {
      searchText: searchData,
      filterObject: filterParams,
      poolId,
    };
    dispatch(getRecruiterCountForCurator(payload));
    clearAll();
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchRecruiterData(
      1,
      dataSize,
      searchData,
      sortedData,
      true,
      clientData ? clientData.value : null
    );
  };

  const applyUpdateCuratorSort = (sortedData) => {
    setSortArray(sortedData);
    setCurrentPage(1);
    fetchDataUpdateCurator(1, dataSize, searchValue, sortedData);
  };

  const clearAll = () => {
    setSelectedValues([]);
    setSelectedTempValue([]);
  };

  const handleFetchRecruiter = async () => {
    setCurrentPage(1);
    try {
      if (!activeTab) {
        setCuratorLoading(true);
        const clientPayload = {
          poolId: clientData ? clientData.value : null,
        };
        const res = await post("/curator/getcuratorexist", clientPayload);
        if (res.data[0]?.curatorExist == "true") {
          setActiveTab("update");
        } else {
          setActiveTab("existing");
        }
        setCuratorLoading(false);
      }
      fetchRecruiter(clientData);
      fetchDataUpdateCurator(1, dataSize, "", sortArray);
    } catch (error) {
      setCuratorLoading(false);
    }
  };

  return (
    <>
      {(countLoading ||
        loading ||
        commonLoading ||
        updateCuratorLoading ||
        updateCuratorCountLoading ||
        curatorLoading) && <Loader />}
      <Modal
        size={activeTab ? "xl" : "lg"}
        show={assignCuratorShow}
        aria-labelledby="example-modal-sizes-title-lg"
        // style={{ zIndex: 999999 }}
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Manage Curator"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAssignCuratorShow(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="d-flex flex-wrap justify-content-between row pl-3">
            <div className="d-flex justify-content-center row mt-3 mb-3 ml-1">
              <div
                className={`d-flex ${
                  activeTab ? "col-8" : "col-10"
                } pr-4 pt-3 ${!activeTab && "mb-5"}`}
              >
                <div className="w-40 mr-3">
                  <ClientData
                    nameLoading={nameLoading}
                    clientName={clientName}
                    fetchClientDetails={fetchClientDetails}
                    setTenant={setTenant}
                    tenant={tenant}
                    validation={validation}
                    setClientData={setClientData}
                  />
                </div>
                <div className="w-50 mr-3">
                  <ClientTalentCommunity
                    communityLoading={communityLoading}
                    communityData={communityData}
                    fetchCommunityData={fetchCommunityData}
                    setClientData={setClientData}
                    clientData={clientData}
                    validation={validation}
                    tenant={tenant}
                  />
                </div>
                <button
                  type="button"
                  className="btn-sm btn-primary"
                  onClick={handleFetchRecruiter}
                  disabled={clientData == null}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
          {activeTab && (
            <>
              <div className="d-flex flex-wrap justify-content-between row pl-3">
                <div className="d-flex justify-content-between mt-3 col-8">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        onClick={() => setActiveTab("update")}
                        type="button"
                        className={`nav-link pb-3 ${
                          activeTab === "update" ? "active" : ""
                        }`}
                      >
                        <div>Remove Curator</div>
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        onClick={() => setActiveTab("existing")}
                        type="button"
                        className={`nav-link pb-3 ${
                          activeTab === "existing" ? "active" : ""
                        }`}
                      >
                        <div>Assign Curator</div>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <hr className="mt-0" />
            </>
          )}
          <div className="d-none d-block-1200 mb-3"></div>
          {activeTab == "existing" && (
            <>
              <AssignExistingCurators
                setAssignCuratorShow={setAssignCuratorShow}
                recruiterData={recruiterData}
                dataTotal={dataTotal}
                currentPage={currentPage}
                dataSize={dataSize}
                searchData={searchData}
                handlePageChange={handlePageChange}
                handleSizeChange={handleSizeChange}
                handleSearch={handleSearch}
                setCommonLoading={setCommonLoading}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                sortArray={sortArray}
                setSortArray={setSortArray}
                applySort={applySort}
                fetchData={fetchRecruiterData}
                applyFilter={applyFilter}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setSelectedTempValue={setSelectedTempValue}
                selectedTempValue={selectedTempValue}
                clientData={clientData}
                filterParams={filterParams}
                openTenantPopUP={openTenantPopUP}
                setopenTenantPopUP={setopenTenantPopUP}
              />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => setAssignCuratorShow(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAssignCurator}
                >
                  <span>Submit</span>
                </button>
              </div>
            </>
          )}
          {activeTab == "update" && (
            <UpdateCurator
              setAssignCuratorShow={setAssignCuratorShow}
              tenant={tenant}
              updateCuratorData={updateCuratorData}
              updateCuratorCount={updateCuratorCount}
              handleSizeChange={handleUpdateCuratorSizeChange}
              dataSize={dataSize}
              fetchDataUpdateCurator={fetchDataUpdateCurator}
              searchData={searchValue}
              handleSearch={handleUpdateCuratorSearch}
              applySort={applyUpdateCuratorSort}
              setSortArray={setSortArrayUpdateCurator}
              sortArray={sortArrayUpdateCurator}
              getTenantData={getTenantData}
              clientData={clientData}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignCurator;
