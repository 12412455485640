import React from "react";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import {
  currency_list,
  salary_type,
} from "../../../../../../constants/DropDownData";

function Preferences({
  profileInfo,
  setProfileInfo,
  profileInfoValidation,
  salaryType,
  setSalaryType,
  isFormValid,
}) {
  const handleChange = (e) => {
    if (e.target.id === "preferredSalary" || e.target.id === "minContractRate")
      setProfileInfo({
        ...profileInfo,
        ["preferredSalary"]: e.target.value.slice(0, 7),
        ["minContractRate"]: e.target.value.slice(0, 7),
      });
    else
      setProfileInfo({
        ...profileInfo,
        [e.target.id]: e.target.value,
      });
  };

  return (
    <>
      <div>
        <div className="row mt-3">
          <div className="col-lg-4">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                placeholder="Preferred Salary"
                id="preferredSalary"
                style={{
                  border:
                    isFormValid &&
                    profileInfoValidation.preferredSalary &&
                    profileInfo.preferredSalary == "" &&
                    "2px solid #ff0000",
                }}
                value={profileInfo.preferredSalary}
                onChange={(e) => {
                  if (isNaN(e.target.value)) return;
                  if (e.target.value === "0") {
                    toast.error("Cannot Be Zero");
                  } else if (+e.target.value < 0) {
                    toast.error("Negative numbers are not allowed");
                  } else {
                    handleChange(e);
                  }
                }}
              />
              <label>Expected Salary / Rate*</label>
            </div>
            {isFormValid &&
              profileInfoValidation.preferredSalary &&
              profileInfo.preferredSalary == "" && (
                <small className="validation">
                  {profileInfoValidation.preferredSalary}
                </small>
              )}
          </div>

          <div class="col-lg-4">
            <div class="form-floating">
              <select
                class="form-select"
                id="salaryType"
                value={salaryType}
                onChange={(e) => {
                  setSalaryType(e.target.value);
                }}
                style={{
                  border:
                    isFormValid &&
                    salaryType === "Select" &&
                    "2px solid #ff0000",
                }}
              >
                {salary_type.map((item) => (
                  <option value={item.value}>{item.value}</option>
                ))}
              </select>
              <label>Rate Type*</label>
            </div>
            {isFormValid && salaryType === "Select" && (
              <small className="validation">*Required Field</small>
            )}
          </div>

          <div class="col-lg-4">
            <div class="form-floating">
              <select
                class="form-select"
                disabled={!profileInfo.preferredSalary > 0}
                id="preferredSalaryCurrency"
                style={{
                  border:
                    profileInfoValidation.preferredSalaryCurrency &&
                    "2px solid #ff0000",
                }}
                value={
                  profileInfo.preferredSalaryCurrency &&
                  profileInfo.preferredSalaryCurrency.split("/")[0]
                }
                onChange={handleChange}
              >
                <option value="" selected disabled>
                  Select Type
                </option>
                {currency_list.map((item) => (
                  <option value={item.code}>{item.code}</option>
                ))}
              </select>
              <label>
                Currency Type
                {profileInfo.preferredSalary > 0 && "*"}
              </label>
            </div>
            <small className="validation">
              {profileInfoValidation.preferredSalaryCurrency}
            </small>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preferences;
