import Validator from "email-validator";
export const BulkUploadExcelValidation = (item) => {
  const [
    firstName,
    lastName,
    email,
    homePhoneCode,
    homePhone,
    primarySkills,
    jobTitle,
    experienceYear,
    experienceMonth,
    city,
    state,
    country,
    preferredLocations,
  ] = item;

  let errorObj = [];

  if (firstName === undefined) {
    errorObj.push("First name is empty");
  }
  if (lastName === undefined) {
    errorObj.push("Last name is empty");
  }
  if (email === undefined) {
    errorObj.push("Email is empty");
  }
  if (homePhoneCode === undefined) {
    errorObj.push("Home phone code is empty");
  }
  if (homePhone === undefined) {
    errorObj.push("Home phone is empty");
  }
  if (primarySkills === undefined) {
    errorObj.push("Primary skills are empty");
  }

  if (jobTitle === undefined) {
    errorObj.push("Job title is empty");
  }
  if (experienceYear === undefined) {
    errorObj.push("Experience year is empty");
  }
  if (experienceMonth === undefined) {
    errorObj.push("Experience month is empty");
  }
  if (experienceMonth < 0 || experienceMonth > 11) {
    errorObj.push("Experience month should be between 0 and 11");
  }

  if (email !== undefined && email.trim().length > 0) {
    if (!Validator.validate(email)) {
      errorObj.push("Invalid Email");
    }
  }

  if (city === undefined) {
    errorObj.push("City  is empty");
  }
  if (state === undefined) {
    errorObj.push("State  is empty");
  }
  if (country === undefined) {
    errorObj.push("Country  is empty");
  }
  if (preferredLocations === undefined) {
    errorObj.push("PreferredLocations  is empty");
  }

  return errorObj;
};
