import React, { useState, useEffect, useRef } from "react";
import "react-tagsinput/react-tagsinput.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import GoogleLocationAPI from "../../common/location/GoogleLocationAPI";

function SubmittalDetailsInfo({
  weightage,
  setWeightage,
  addJobData,
  component,
  jobTitleList,
  setJobTitleList,
  edit,
  selectedRadius,
  setSelectedRadius,
  locationList,
  setLocationList,
  preferredLocation,
  setPreferredLocation,
}) {
  const [addJobTitle, setAddJobTitle] = useState(false);
  const searchInput = useRef(null);
  const [radiusOption, setRadiusOption] = useState("Less than 10 miles"); // Define and initialize radiusOption state

  useEffect(() => {
    if (edit) {
      setPreferredLocation(locationList.map((e) => e?.location));
    }
  }, []);
  const handleJobTitle = (event) => {
    if (
      jobTitleList.length === 0 ||
      (jobTitleList.length < 5 &&
        jobTitleList[jobTitleList.length - 1].trim() !== "")
    ) {
      setJobTitleList((prevState) => [...prevState, ""]);
    } else {
      toast.error("Job title can't be empty");
    }
  };

  useEffect(() => {}, [jobTitleList]);

  const deleteJobTitle = (index) => {
    const updatedQuestions = [...jobTitleList];
    updatedQuestions.splice(index, 1);
    setJobTitleList(updatedQuestions);
    setWeightage({ ...weightage, jobList: updatedQuestions });
  };

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    let location = [...locationList];
    location.splice(index, 1);
    setLocationList(location);
  };

  const toolTip = () => {
    return (
      <Tooltip style={{ zIndex: 99999999, maxWidth: "300px" }}>
        <div style={{ textAlign: "left", marginLeft: "12px" }}>
          <h6>What to do:</h6>
          <ol>
            <li>
              Select the criteria that best fit your requirements by checking
              the corresponding check boxes.
            </li>
            <li>
              Adjust the criteria as needed to ensure the most accurate
              candidate matches.
            </li>
            <li>
              Hover over each parameter for its definition if you have any
              questions.
            </li>
            <li>
              Once you’ve selected your criteria, click “NEXT” to apply the
              settings and move the next section.
            </li>
          </ol>
        </div>
      </Tooltip>
    );
  };

  const handleJobList = (e) => {
    setAddJobTitle(!addJobTitle);
    setWeightage({ ...weightage, jobTitle: e.target.checked });
    if (!e.target.checked) {
      setJobTitleList([]);
    }
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...jobTitleList];
    onChangeValue[index] = value;
    setJobTitleList(onChangeValue);
    setWeightage({ ...weightage, jobList: onChangeValue });
  };

  const handleRadiusChange = (value) => {
    setSelectedRadius(value);
  };

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    if (preferredLocation.includes(searchInput.current.value)) {
      toast.error("You Have Already Added This Location");
    } else {
      setPreferredLocation([...preferredLocation, searchInput.current.value]);
      setLocationList([
        ...locationList,
        {
          address: `${city ? city + "," : ""}${address.country}`,
          location: searchInput.current.value,
          long: address?.lng,
          lat: address?.lat,
        },
      ]);
      searchInput.current.value = "";
    }
  };

  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          {!component && (
            <div className="d-flex">
              <div className="mx-1 mt-2 mb-3">
                <h5 style={{ color: "#f45e57" }}>
                  Automatched Parameter Setup
                </h5>
                <span className="m-1 ">
                  Select the criteria for automatching candidates.
                </span>
              </div>
              <div className="d-flex ml-auto mr-2">
                <OverlayTrigger placement="bottom" overlay={toolTip()}>
                  <label
                    className=""
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    What to do...
                  </label>
                </OverlayTrigger>
              </div>
            </div>
          )}

          <div className="mb-3 ml-4">
            <div className="d-flex justify-content-start align-items-center">
              <input
                style={{ width: "2%" }}
                className="mr-3"
                checked={true}
                type="checkbox"
                disabled
              />
              <i
                className="fas fa-user-cog"
                style={{ color: "#f45e57", width: "20px" }}
              ></i>
              <span className="bold mr-1">Skills</span>
              <span>{addJobData.skills}</span>
              {/* <i
                className="fa fa-info-circle mr-2 ml-2"
                style={{ color: "grey" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Skills"
              ></i>{" "} */}
            </div>

            <div className="d-flex justify-content-start align-items-center mt-2">
              <input
                style={{ width: "2%" }}
                className="mr-3"
                type="checkbox"
                checked={weightage?.location}
                disabled={edit}
                onChange={(e) => {
                  setWeightage({ ...weightage, location: e.target.checked });
                  setPreferredLocation([]);
                }}
              />
              <i
                className="fad fa-fw fa-map-marked-alt"
                style={{ color: "#f45e57", width: "20px" }}
              ></i>
              <span className="bold mr-1">Location</span>
              {/* <span className="tag tag-orange1">{addJobData?.city}</span> */}
              <span className="">
                {addJobData?.isCountry
                  ? addJobData?.country
                  : addJobData?.location}
              </span>
              {/* <i
                className="fa fa-info-circle mr-2 ml-2"
                style={{ color: "grey" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Location"
              ></i> */}
            </div>

            {weightage?.location && (
              <>
                <div>
                  <div class="row mb-12 ">
                    <div class="col-12">
                      <div class="mb-2 ml-2 d-flex">
                        <label>
                          Where else do you want talent to be located?
                        </label>
                      </div>
                      <div>
                        <div class="form-inline-edit">
                          <div>
                            <div>
                              <div className="m-2">
                                {preferredLocation
                                  .filter(
                                    (value, index, self) =>
                                      self.indexOf(value) === index
                                  )
                                  .map((item, index) => (
                                    <>
                                      <span className="tag tag-blue3 mb-1 mr-1">
                                        {item}
                                        <button
                                          className="btn btn-text"
                                          onClick={() => deleteLocation(index)}
                                          disabled={edit}
                                        >
                                          <strong className="font-18 font-black">
                                            &times;
                                          </strong>
                                        </button>
                                      </span>
                                    </>
                                  ))}
                              </div>
                              {!edit && (
                                <div className="d-flex align-items-center justify-content-center">
                                  <div
                                    className="form-floating ml-2 mb-2"
                                    style={{ width: "290px" }}
                                  >
                                    <GoogleLocationAPI
                                      setLocation={setLocation}
                                      searchInput={searchInput}
                                    />
                                  </div>
                                  <div className="ml-auto mr-1"></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex align-items-center ml-2">
                  <span className="bold mr-1">Radius:</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {radiusOption}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setRadiusOption("Less than 10 miles");
                          handleRadiusChange(10);
                        }}
                      >
                        Less than 10 miles
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setRadiusOption("Less than 20 miles");
                          handleRadiusChange(20);
                        }}
                      >
                        Less than 20 miles
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setRadiusOption("Less than 50 miles");
                          handleRadiusChange(50);
                        }}
                      >
                        Less than 50 miles
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
              </>
            )}
            <div className="d-flex justify-content-start align-items-center mt-2">
              <input
                style={{ width: "2%" }}
                className="mr-3"
                checked={weightage?.education}
                disabled={edit}
                type="checkbox"
                onChange={(e) =>
                  setWeightage({
                    ...weightage,
                    education: e.target.checked,
                  })
                }
              />
              <i
                className="fad fa-user-visor"
                style={{ color: "#f45e57", width: "20px" }}
              ></i>
              <span className="bold mr-1">Education</span>
              {/* <span className="tag tag-lightPink">{addJobData?.education}</span> */}
              <span className="">{addJobData?.education}</span>
              {/* <i
                className="fa fa-info-circle mr-2 ml-2"
                style={{ color: "grey" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Education"
              ></i> */}
            </div>

            <div className="d-flex justify-content-start align-items-center mt-2">
              <input
                style={{ width: "2%" }}
                className="mr-3"
                checked={weightage?.experienceLevel}
                disabled={edit}
                type="checkbox"
                onChange={(e) =>
                  setWeightage({
                    ...weightage,
                    experienceLevel: e.target.checked,
                  })
                }
              />
              <i
                className="fad fa-user-visor"
                style={{ color: "#f45e57", width: "20px" }}
              ></i>
              <span className="bold mr-1">Experience</span>
              <span className="">{addJobData?.experienceLevel}</span>
              {/* <i
                className="fa fa-info-circle mr-2 ml-2"
                style={{ color: "grey" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Experience Level"
              ></i> */}
            </div>
            <div className="d-flex justify-content-start align-items-center mt-2">
              <input
                style={{ width: "2%" }}
                className="mr-3"
                type="checkbox"
                checked={addJobTitle}
                disabled={edit}
                onChange={(e) => handleJobList(e)}
              />
              <i
                className="fas fa-clipboard-list-check "
                style={{ color: "#f45e57", width: "20px" }}
              ></i>
              <span className="bold mr-1">Job Title</span>
              <span className="">{addJobData?.jobTitle}</span>{" "}
              {/* <i
                className="fa fa-info-circle mr-2 ml-2"
                style={{ color: "grey" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Job Title"
              ></i> */}
              {addJobTitle && jobTitleList?.length < 2 && (
                <i
                  className="fas fa-plus-circle fa-fw-dropdown ml-2"
                  style={{ color: "#009eff", cursor: "pointer" }}
                  onClick={() => handleJobTitle()}
                  disabled={jobTitleList?.length === 2 ? true : false}
                ></i>
              )}
            </div>
            {jobTitleList?.length > 0 &&
              addJobTitle &&
              jobTitleList?.map((item, index) => (
                <div className="d-flex mb-1" key={index}>
                  <input
                    style={{ width: "auto", marginLeft: "16%" }}
                    className="form-control"
                    type="text"
                    value={item}
                    onChange={(e) => handleChange(e, index)}
                  />

                  <i
                    className="fal fa-fw fa-trash-alt ml-2"
                    style={{ alignSelf: "center", cursor: "pointer" }}
                    onClick={() => deleteJobTitle(index)}
                  ></i>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmittalDetailsInfo;
