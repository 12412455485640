import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { roleNames } from "../../constants/Constants";
import TopNavbar from "../layout/TopNavbar";
import SideNavBar from "../layout/SideNavbar";
import Footer from "../layout/Footer";
import { post, get } from "../../service/ApiService";
import {
  getUserRole,
  getCandidateId,
  getCandidateName,
  getUserId,
  getUserTenant,
  getTenantType,
} from "../../service/AuthService";
import Loader from "../loader/Loader";
import { getProfileInfo } from "../../action/profile/Profile";
import {
  getDropdownValues,
  getCandidateDropdownValues,
} from "../../action/common/Dropdown";
import ContactInfo from "./accountdetails/ContactInfo";
import RecruitmentDetails from "./accountdetails/RecruitmentDetails";
import Security from "./accountdetails/Security";
import EditLogo from "./accountdetails/EditLogo";
import EmailNotifications from "./accountdetails/EmailNotifications";
import TermsAndCondition from "./accountdetails/TermsAndCondition";
import AboutUs from "./accountdetails/AboutUs";
import Preference from "./preferences/Index";
import AvatarUpload from "../common/fileupload/AvatarUpload";

const Index = () => {
  const dispatch = useDispatch();
  const [accountDeatils, setAccountDetails] = useState("accountDeatils");
  const [preference, setPreferences] = useState(false);
  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tenant: "",
    tenantType: "",
    userid: "",
  });
  const [toggle, setToggle] = useState(true);
  const { user, role, tenant, userid } = userdetails;
  const [candidateData, setCandidateData] = useState([]);
  const [createdBy, setCreatedBy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [recData, setRecData] = useState({});
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });

  const {
    dropdownLoading,
    dropdownData,
    logoLoading,
    contactInfo,
    contactInfoLoading,
    candidateDropdownLoading,
  } = useSelector(
    (state) => ({
      contactInfo: state.profileInfoReducer.profileDate,
      contactInfoLoading: state.profileInfoReducer.loading,
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      candidateDropdownLoading:
        state.dropdownValuesReducer.candidateDropdownLoading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
      logoLoading: state.profileInfoReducer.logoLoading,
    }),
    shallowEqual
  );

  const UploadProfileImage = async () => {
    try {
      let parameters = {
        ...imageData,
        userId: getCandidateId(),
        role: role,
        oldProfilePath: role === "Recruiter" ? recData?.profileImagePath : "",
      };

      const res = await post(
        "/common/contactinfo/saveProfileImage",
        parameters
      );
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.response.data.errMessage);
    }
  };
  useEffect(() => {
    if (imageData.base64 && imageData.fileName) {
      UploadProfileImage();
    }
  }, [imageData]);

  useEffect(() => {
    if (recData) {
      setImageData({
        ...imageData,
        base64: recData.profileImagePath,
      });
      const userTokenJSON = localStorage.getItem("userToken");
      const userTokenObject = JSON.parse(userTokenJSON);
      userTokenObject.profileImagePath = recData.profileImagePath;
      const updatedUserTokenJSON = JSON.stringify(userTokenObject);
      localStorage.setItem("userToken", updatedUserTokenJSON);
    }
  }, [recData]);

  useEffect(() => {
    if (contactInfo && contactInfo.length > 0) {
      setRecData(contactInfo[0]);
    }
  }, [contactInfo]);

  const profileType = (type) => {
    if (type === "accountDeatils") {
      if (accountDeatils === false) {
        setAccountDetails(true);
      } else {
        setAccountDetails(false);
      }
      setPreferences(false);
    }

    if (type === "preference") {
      if (preference === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
      setAccountDetails(false);
    }
  };

  const getProfile = () => {
    const username = getCandidateName();
    const userrole = getUserRole();
    const iduser = getUserId();
    setUser({
      user: username,
      role: userrole,
      userid: iduser,
      tenant: getUserTenant(),
      tenantType: getTenantType(),
    });
  };

  useEffect(() => {
    dispatch(getDropdownValues());
    dispatch(getCandidateDropdownValues());
    const payload = {
      userid: getCandidateId(),
    };
    dispatch(getProfileInfo(payload));
    getProfile();
  }, []);

  return (
    <>
      {((getTenantType() == 1 && logoLoading) ||
        loading ||
        dropdownLoading ||
        candidateDropdownLoading ||
        categoryLoading ||
        contactInfoLoading) && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavbar setToggle={setToggle} toggle={toggle} />
        <SideNavBar />
        <div id="Content">
          <>
            <div
              className="section-sm section"
              style={{ width: "100%", minHeight: "90%" }}
            >
              <AvatarUpload
                imageData={imageData}
                setImageData={setImageData}
                user={user}
                role={role}
                roleNames={roleNames}
                UploadProfileImage={UploadProfileImage}
              />
              <Tabs style={{ borderBottom: "revert" }} className="mt-4">
                <div className="translate-up-100">
                  <div className="d-flex justify-content-center">
                    <TabList>
                      <ul className="nav nav-tabs nav-tabs-white mt-3 flex-wrap flex-nowrap-md justify-content-center">
                        <Tab
                          onClick={() => {
                            profileType("accountDeatils");
                          }}
                          className={
                            accountDeatils
                              ? "nav-link pb-3 active"
                              : "nav-link pb-3"
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Account Details{" "}
                        </Tab>
                        {(role === "Recruiter" || role === "Candidate") && (
                          <Tab
                            onClick={() => {
                              profileType("preference");
                            }}
                            className={
                              preference
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Preferences
                          </Tab>
                        )}
                        {/* {((role === "Recruiter" && tenant.includes("GIG - ")) ||
                          (role === "Admin" && tenantType === 2)) && (
                          <Tab
                            onClick={() => {
                              profileType("Terms and conditions");
                            }}
                            className={
                              terms ? "nav-link pb-3 active" : "nav-link pb-3"
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Terms of Service
                          </Tab>
                        )} */}
                      </ul>
                    </TabList>
                  </div>

                  <TabPanel>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-8">
                          <ContactInfo
                            getProfile={getProfile}
                            setLoading={setLoading}
                          />
                          {recData.yearsOfExperience && recData.industries && (
                            <RecruitmentDetails
                              data={recData}
                              dropdownData={dropdownData}
                              setLoading={setLoading}
                              setCategoryLoading={setCategoryLoading}
                            />
                          )}

                          {role === "Admin" && <AboutUs />}
                          {role !== "SuperAdmin" && <EmailNotifications />}
                          <Security userid={userid} />
                          {getTenantType() == 1 && <EditLogo />}
                          {role === "Recruiter" &&
                            tenant.includes("GIG - ") && <TermsAndCondition />}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  {(preference || role === "Recruiter") && (
                    <TabPanel>
                      <Preference
                        data={candidateData[0]}
                        loading={loading}
                        setLoading={setLoading}
                        recData={recData}
                      />
                    </TabPanel>
                  )}
                  {/* <TabPanel></TabPanel> */}
                </div>
              </Tabs>
            </div>
            {getTenantType() == 1 && <Footer />}
          </>
        </div>
      </body>
    </>
  );
};

export default Index;
