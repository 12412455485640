import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddCommunityDetails from "../../addcommunity/Index";

const EditSubCommunity = ({ jobData }) => {
  const [editJob, setEditJob] = useState(false);
  const handleEditClick = () => {
    setEditJob(true);
  };

  return (
    <>
      <div
        className="dropdown px-1"
        style={{
          fontSize: "17px",
          cursor: "pointer",
        }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Edit"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => handleEditClick()}
      >
        <i className="fal fa-pencil fa-fw btn btn-icon"></i>
      </div>
      <Modal show={editJob} size="lg">
        <Modal.Header>
          <button
          style={{zIndex:"999"}}
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditJob(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddCommunityDetails
            edit={true}
            editPoolDetails={jobData}
            closePanel={() => setEditJob(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditSubCommunity;
