import React, { useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../../../../../../service/ApiService";
import Loader from "../../../../../../loader/Loader";
import ResumeUpload from "./ResumeUpload";
import DeleteResume from "./DeleteResume";

const Index = ({
  resumeFile,
  setResumeFile,
  parsed,
  setParsedData,
  setParsed,
}) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [hideResumeBtn, setHideResumeBtn] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateParsed = (data, value) => {
    setParsed(value);
    setParsedData(data);
  };
  
  const uploadResume = async (doc) => {
    setLoading(true);
    try {
      setSubmitClicked(true);
      const payload = {
        filename: doc.fileName,
        base64: doc.base64,
      };
      const resultPy = await post("/talent/parseuploadresume", payload);
      if (resultPy.status == 200) {
        const modifiedData = {
          name: {
            raw: resultPy?.data?.name ?? "",
            first: resultPy?.data?.name
              ? resultPy?.data?.name?.split(" ")[0]
              : "",
            last: resultPy?.data?.name
              ? resultPy?.data?.name?.split(" ")[1]
              : "",
            middle: "",
            title: "",
          },
          phoneNumbers: [resultPy?.data?.phone ?? ""],
          websites: [],
          emails: [resultPy?.data?.email ?? ""],
          location: {
            formatted: resultPy?.data?.location?.address ?? "",
            postalCode: resultPy?.data?.location?.zipCode
              ? resultPy?.data?.location?.zipCode[0]
              : "",
            state: resultPy?.data?.location?.state ?? "",
            country: resultPy?.data?.location?.country ?? "",
            rawInput: "Sector 55, Noida, India",
            city: resultPy?.data?.location?.city ?? "",
          },
          education: resultPy?.data?.education
            ? resultPy?.data?.education?.map((x, i) => ({
                organization: x[i]?.school,
                accreditation: {
                  educationLevel: x[i]?.program,
                  education: x[i]?.major,
                },
              }))
            : [],
          profession: resultPy?.data?.designation ?? "",
          skills: resultPy?.data?.primary_skills
            ? resultPy?.data?.primary_skills?.map((x, i) => ({
                name: x,
                id: i,
              }))
            : [],
        };

        const finalresultPy = {
          ...modifiedData,
          ...resumeFile,
          h5h: true,
        };
        updateParsed(finalresultPy, true);
        toast.success("Resume uploaded successfully");
        setSubmitClicked(false);
        setLoading(false);
      } else {
        toast.error(
          "Something went wrong while uploading resume, please try again."
        );
        setHideResumeBtn(false);
        setResumeFile({ fileName: "", base64: "" });
        setSubmitClicked(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading resume, please try again."
      );
      setHideResumeBtn(false);
      setResumeFile({ fileName: "", base64: "" });
      setSubmitClicked(false);
      setLoading(false);
    }
  };
  const onRemove = (e) => {
    setResumeFile({ fileName: "", base64: "" });
    setHideResumeBtn(false);
    setShowConfirmation(false);
    toast.success("Resume deleted successfully ");
  };

  return (
    <div className="w-100">
      {loading && <Loader />}
      <div className={`d-flex d-block-768 w-100`}>
        {parsed && (
          <div className="">
            <h6 className={`my-auto mr-2 `}>
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={resumeFile?.fileName}
              >
                {resumeFile?.fileName?.length > 25
                  ? resumeFile?.fileName.slice(0, 25) + "..."
                  : resumeFile?.fileName}
              </span>
            </h6>
          </div>
        )}
        {parsed && resumeFile && resumeFile.fileName && (
          <div className="d-flex gap-1 mr-1 justify-content-left">
            <button
              type="button"
              className="btn btn-icon "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i class="fa fa-check-circle" style={{ color: "green" }}></i>
              </span>
            </button>

            <button
              type="button"
              className="btn btn-icon  "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i
                  class="fal fa-fw fa-trash-alt"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                ></i>
              </span>
            </button>
          </div>
        )}
        <ResumeUpload
          submitClicked={submitClicked}
          resumeFile={resumeFile}
          setResumeFile={setResumeFile}
          uploadResume={uploadResume}
          hideResumeBtn={hideResumeBtn}
          setHideResumeBtn={setHideResumeBtn}
          parsed={parsed}
        />
      </div>
      {showConfirmation && (
        <DeleteResume
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          resumeFile={resumeFile}
          onRemove={onRemove}
        />
      )}
    </div>
  );
};

export default Index;
