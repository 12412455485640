import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Preview from "../communication/email/EmailPreview";

const ModalView = (props) => {
  const {
    modalSize,
    modalTitle,
    isModalOpen,
    setModalClose,
    data,
    modalFor,
    isFrom,
    parentModalClose,
  } = props;

  const onClose = () => {
    setModalClose(false);
    if (isFrom === "ManualEntry") {
      parentModalClose();
    }
    if (isFrom === "TalentProfileEdit") {
      parentModalClose(false);
    }
  };

  return (
    <>
      <Modal
        size={modalSize}
        show={isModalOpen}
        backdrop="static"
        keyboard={false}
      >
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">{modalTitle}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {isFrom === "EmailBroadcast" && modalFor === "preview" && (
            <Preview isFrom={isFrom} closeModal={onClose} data={data} />
          )}

          {isFrom === "DispositionOverview" && modalFor === "preview" && (
            <Preview isFrom={isFrom} closeModal={onClose} data={data} />
          )}

          {isFrom === "EmailOverview" && modalFor === "preview" && (
            <Preview isFrom={isFrom} closeModal={onClose} data={data} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalView;
