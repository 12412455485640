import {
  FETCH_CAREER_FILTER_JOB_LIST_BEGIN,
  FETCH_CAREER_FILTER_JOB_LIST_SUCCESS,
  FETCH_CAREER_FILTER_JOB_LIST_FAILURE,
  GET_CAREER_JOB_COUNT_BEGIN,
  GET_CAREER_JOB_COUNT_SUCCESS,
  GET_CAREER_JOB_COUNT_FAILURE,
  FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN,
  FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS,
  FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE,
  GET_SAVED_CAREER_JOB_COUNT_BEGIN,
  GET_SAVED_CAREER_JOB_COUNT_SUCCESS,
  GET_SAVED_CAREER_JOB_COUNT_FAILURE,
} from "../../../../action/disposition/job/career/CareerHome";

const initialState = {
  loading: false,
  jobFilterList: null,
  careerCountLoading: false,
  careerCount: [],
  savedJobloading: false,
  savedJobFilterList: null,
  savedJobCountLoading: true,
  savedJobCount: [],
};

const getCareerHomeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CAREER_FILTER_JOB_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        jobFilterList: null,
      };
    case FETCH_CAREER_FILTER_JOB_LIST_SUCCESS:
      return {
        ...state,
        jobFilterList: payload,
        loading: false,
      };
    case FETCH_CAREER_FILTER_JOB_LIST_FAILURE:
      return {
        ...state,
        jobFilterList: payload,
        loading: false,
      };
    case GET_CAREER_JOB_COUNT_BEGIN:
      return {
        ...state,
        careerCountLoading: true,
        careerCount: [],
      };
    case GET_CAREER_JOB_COUNT_SUCCESS:
      return {
        ...state,
        careerCount: payload,
        careerCountLoading: false,
      };
    case GET_CAREER_JOB_COUNT_FAILURE:
      return {
        ...state,
        careerCount: [],
        careerCountLoading: false,
      };
    case FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN:
      return {
        ...state,
        savedJobloading: true,
        savedJobFilterList: null,
      };
    case FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS:
      return {
        ...state,
        savedJobFilterList: payload,
        savedJobloading: false,
      };
    case FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE:
      return {
        ...state,
        savedJobFilterList: payload,
        savedJobloading: false,
      };
    case GET_SAVED_CAREER_JOB_COUNT_BEGIN:
      return {
        ...state,
        savedJobCountLoading: true,
        savedJobCount: [],
      };
    case GET_SAVED_CAREER_JOB_COUNT_SUCCESS:
      return {
        ...state,
        savedJobCount: payload,
        savedJobCountLoading: false,
      };
    case GET_SAVED_CAREER_JOB_COUNT_FAILURE:
      return {
        ...state,
        savedJobCount: [],
        savedJobCountLoading: false,
      };
    default:
      return state;
  }
};

export default getCareerHomeReducer;
