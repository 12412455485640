import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../constants/Constants";
import ImageUpload from "../../common/fileupload/ImageUpload";
import { getUserTenantID, getUserTenant } from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
import { getTenantLogo } from "../../../action/profile/Profile";

const EditLogo = () => {
  const dispatch = useDispatch();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [saveClicked, setSaveClicked] = useState(false);
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });

  const { tenantLogo } = useSelector(
    (state) => ({
      tenantLogo: state.profileInfoReducer.tenantLogo,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (tenantLogo && tenantLogo.length > 0) {
      let data = tenantLogo[0];
      let filename = data?.logourl?.split("/").pop();
      setImageData({
        ...imageData,
        base64: data?.logoBase64,
        fileName: filename,
      });
      const userTknString = localStorage.getItem("userToken");
      const userTkn = JSON.parse(userTknString);
      userTkn.logo = data.logourl ?? "";
      const modifiedUserTknString = JSON.stringify(userTkn);
      localStorage.setItem("userToken", modifiedUserTknString);
    }
  }, [tenantLogo]);

  useEffect(() => {
    dispatch(getTenantLogo());
  }, []);

  const save = async () => {
    setSaveClicked(true);
    const paramters = {
      ...imageData,
      tenantId: getUserTenantID(),
      tenantName: getUserTenant(),
    };
    try {
      await post("/profile/updatelogo", paramters);
      window.location.reload();
      toast.success("Logo Updated");
      setSaveClicked(false);
    } catch (error) {
      setSaveClicked(false);
      toast.error("Error Occured While saving tenant  Details");
    }
  };

  const handleCancel = () => {
    setReadOnlyMode(true);
    if (tenantLogo && tenantLogo.length > 0) {
      let data = tenantLogo[0];
      let filename = data?.logourl?.split("/").pop();
      setImageData({
        ...imageData,
        base64: data?.logoBase64,
        fileName: filename,
      });
    }
  };

  return (
    <>
      <div className={readOnlyMode && "read-only mb-2"}>
        <div className="card card-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">Edit Logo</h6>
              {readOnlyMode ? (
                <button
                  type="button"
                  v-if="form.security.readOnly"
                  className="btn btn-text"
                  onClick={() => {
                    setReadOnlyMode(false);
                  }}
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Edit
                </button>
              ) : (
                <div>
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={save}
                    className="btn btn-sm btn-primary"
                  >
                    {!saveClicked && <span>Update</span>}
                    {saveClicked && (
                      <span>
                        Updating
                        <img width="20px" src={ButtonLoader} alt="" />
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
            {!readOnlyMode && (
              <ImageUpload imageData={imageData} setImageData={setImageData} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLogo;
