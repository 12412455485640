import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";

import SideNavBar from "../../layout/SideNavbar";
import TopNavBar from "../../layout/TopNavbar";
import SMSTemplateFullView from "./SMSTemplateFullView";
import Loader from "../../common/loader/Loader";
import { getSMSTemplates } from "../../../action/adminconsole/SMSTemplate";
import Pagination from "../../common/pagination/Pagination";
import NoRecord from "../../common/NoRecord";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function SMSTemplate() {
  const dispatch = useDispatch();
  const [smsFullView, setSmsFullView] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedSMSData, setSelectedSMSData] = useState({});
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempData, setTempData] = useState([]);

  console.log("tempData----------->", tempData);

  const [smsEvents, setSmsEvents] = useState({});

  console.log("smsEvents----------->", smsEvents);

  const [loading, setLoading] = useState(false);

  const [recipientPersona, setRecipientPersona] = useState(null);
  const [eventTemplates, setEventTemplates] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(20);

  const { SMSLoading, SMSData, SMSUpdated } = useSelector(
    (state) => ({
      SMSLoading: state.smsTemplateReducer.SMSLoading,
      SMSData: state.smsTemplateReducer.SMSData,
      SMSUpdated: state.smsTemplateReducer.SMSUpdated,
    }),
    shallowEqual
  );

  console.log("SMSData----------->", SMSData);

  useEffect(() => {
    dispatch(getSMSTemplates());
  }, []);

  useEffect(() => {
    dispatch(getSMSTemplates());
  }, [SMSUpdated]);

  useEffect(() => {
    if (SMSData.length > 0) {
      setTempData(SMSData);

      const groupSMSTemplates = () => {
        // const sortedData =
        //   SMSData &&
        //   SMSData?.slice().sort((a, b) =>
        //     a.recipients[0].localeCompare(b.recipients[0])
        //   );

        const customSort = (a, b) => {
          // First, sort by recipients
          const recipientsComparison = a.recipients[0].localeCompare(
            b.recipients[0]
          );

          // If recipients are the same, sort by events
          return recipientsComparison === 0
            ? a.events.localeCompare(b.events)
            : recipientsComparison;
        };

        const sortedData = SMSData && SMSData?.sort(customSort);

        const startIndex = (currentPage - 1) * limit;
        const slicedData =
          sortedData && sortedData.slice(startIndex, startIndex + limit);
        const groupedData = {};

        slicedData?.forEach((template) => {
          const recipients = template.recipients || [];

          if (recipients.length > 0) {
            const recipientKey = recipients[0];
            if (!groupedData[recipientKey]) {
              groupedData[recipientKey] = [];
            }

            groupedData[recipientKey].push(template);
          }
        });
        for (const key in groupedData) {
          if (groupedData.hasOwnProperty(key)) {
            groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
          }
        }

        // setSmsEvents(groupedData);
      };
      groupSMSTemplates();
      let latestUpdate;

      if (
        SMSData &&
        SMSData.length > 0 &&
        selectedSMSData &&
        Object.keys(selectedSMSData).length > 0
      ) {
        latestUpdate = SMSData.find(
          (item) => item.id_smsTemplate === selectedSMSData.id_smsTemplate
        );
      }

      if (latestUpdate) {
        setSelectedSMSData(latestUpdate);
      }
    }
  }, [SMSData]);

  useEffect(() => {
    setLoading(true);
    const sortedData = tempData?.sort((a, b) =>
      a.recipients[0].localeCompare(b.recipients[0])
    );

    const startIndex = (currentPage - 1) * limit;
    const slicedData =
      sortedData && sortedData.slice(startIndex, startIndex + limit);

    const groupedData = {};

    slicedData &&
      slicedData.forEach((template) => {
        const recipients = template.recipients || [];

        if (recipients.length > 0) {
          const recipientKey = recipients[0];
          if (!groupedData[recipientKey]) {
            groupedData[recipientKey] = [];
          }

          groupedData[recipientKey].push(template);
        }
      });
    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
      }
    }
    setSmsEvents(groupedData);
    setLoading(false);
  }, [tempData, currentPage, limit]);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const handleClearInput = () => {
    setCurrentPage(1);
    setSearchValue("");
    setTempData(SMSData);
    setRecipientPersona(null);
    setEventTemplates(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(parseInt(event.target.value));
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    let filteredData = SMSData;
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setTempData(SMSData);
    } else {
      const searchTermLower = e.target.value.toLowerCase();
      filteredData = tempData.filter((template) => {
        const recipientsMatch = template.recipients.some((recipient) =>
          recipient.toLowerCase().includes(searchTermLower)
        );
        const eventsMatch = template.events
          .toLowerCase()
          .includes(searchTermLower);
        return recipientsMatch || eventsMatch;
      });
      setTempData(filteredData);
    }
  };

  return (
    <>
      {SMSLoading && <Loader />}
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 px-5">
                  <div className="mt-3">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-4 col-sm-12">
                        <h5 className="text-left">
                          SMS Templates ({`${tempData?.length}`})
                        </h5>
                      </div>

                      <div className="col-md-2"></div>
                      <div className="col-md-3 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                        <form className="d-flex w-100">
                          <div
                            className="input-group"
                            style={{ border: "0.5px solid #ddd" }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by Recipient or Template"
                              aria-label="Search"
                              value={searchValue}
                              style={{ fontSize: "14px" }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>

                      <div className="col-md-2 col-sm-12 d-flex justify-content-md-end align-items-center mb-2">
                        <div className="d-flex align-items-center text-left text-nowrap me-2">
                          <span>Show Records:</span>
                          <select
                            className="form-select form-select-sm mx-2"
                            value={limit}
                            onChange={handleLimitChange}
                            style={{ width: "80px" }}
                          >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={SMSData && SMSData?.length}>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  {Object.keys(smsEvents).length > 0 ? (
                    <>
                      <div className="col mt-3 ">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Recipient Persona
                                </div>
                              </th>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Template Name
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Frequency
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Scheduled Send Time
                                </div>
                              </th>{" "}
                              <th>
                                <div className="d-flex align-items-center">
                                  Modified Status
                                </div>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {Object.keys(smsEvents).map((item, m) => (
                            <tbody
                              key={m}
                              className="table-bordered table responsiveTable"
                            >
                              {smsEvents[item].map((x, i) => (
                                <>
                                  <tr
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        m % 2 === 0 ? "#F6F6F6" : "",
                                    }}
                                  >
                                    {i === 0 ? (
                                      <td
                                        rowSpan={smsEvents[item].length}
                                        className="text-start"
                                      >
                                        <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                          {item}
                                        </span>
                                      </td>
                                    ) : null}

                                    <td className="p-relative">{x.events}</td>

                                    <td>{x.smsFrequency}</td>
                                    <td>{x.time}</td>
                                    <td>
                                      {x.updatedByEmail &&
                                      x.updatedDateTime !== ""
                                        ? "Updated by " +
                                          x.updatedByEmail +
                                          " on " +
                                          moment(x.updatedDateTime).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </td>
                                    <td className="d-flex justify-content-center align-items-center mv-auto">
                                      <button
                                        type="button"
                                        style={{
                                          width: "70px",
                                          // height: "30px",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                        className="btn btn-sm btn-secondary ml-2"
                                        onClick={() => {
                                          setSmsFullView(true);
                                          setSelectedSMSData(x);
                                        }}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                      {tempData && tempData?.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalItems={tempData?.length}
                          itemsPerPage={limit}
                          onPageChange={handlePageChange}
                          limit={limit}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <NoRecord />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>

        {smsFullView && (
          <SMSTemplateFullView
            smsFullView={smsFullView}
            setSmsFullView={setSmsFullView}
            selectedSMSData={selectedSMSData}
            setSelectedSMSData={setSelectedSMSData}
            createNew={createNew}
            setCreateNew={setCreateNew}
          />
        )}
      </body>
    </>
  );
}

export default SMSTemplate;
