import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";

export const FETCH_JOB_ID_FILTERS_BEGIN = "FETCH_JOB_ID_FILTERS_BEGIN";
export const FETCH_JOB_ID_FILTERS_SUCCESS = "FETCH_JOB_ID_FILTERS_SUCCESS";
export const FETCH_JOB_ID_FILTERS_FAILURE = "FETCH_JOB_ID_FILTERS_FAILURE";

export const FETCH_JOB_LOCATION_FILTERS_BEGIN =
  "FETCH_JOB_LOCATION_FILTERS_BEGIN";
export const FETCH_JOB_LOCATION_FILTERS_SUCCESS =
  "FETCH_JOB_LOCATION_FILTERS_SUCCESS";
export const FETCH_JOB_LOCATION_FILTERS_FAILURE =
  "FETCH_JOB_LOCATION_FILTERS_FAILURE";

export const FETCH_JOB_TITLE_FILTERS_BEGIN = "FETCH_JOB_TITLE_FILTERS_BEGIN";
export const FETCH_JOB_TITLE_FILTERS_SUCCESS =
  "FETCH_JOB_TITLE_FILTERS_SUCCESS";
export const FETCH_JOB_TITLE_FILTERS_FAILURE =
  "FETCH_JOB_TITLE_FILTERS_FAILURE";

export const fetchJobIdFilterData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_ID_FILTERS_BEGIN,
    });
    const res = await post("/filter/job/fetchJobIdFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_ID_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_ID_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const fetchJobLocationFilterData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_LOCATION_FILTERS_BEGIN,
    });
    const res = await post("/filter/job/fetchJobLocationFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_LOCATION_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_LOCATION_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const fetchJobTitleFilters = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_TITLE_FILTERS_BEGIN,
    });
    const res = await post("/filter/job/fetchJobTitlesFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_TITLE_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_TITLE_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
