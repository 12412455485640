import React, { useState } from "react";
import Select from "react-select";
import { visaStatus } from "../../../constants/Constants";

const VisaStatus = (props) => {
  const [isRequired, setIsRequired] = useState(false);

  const handleIsGlobal = (e) => {
    if (e.target.checked) {
      props?.setAddJobData({
        ...props?.addJobData,
        visaStatus: ["Not Required"],
      });
      setIsRequired(true);
      // setDisabledPreferred(false);
    } else {
      props?.setAddJobData({
        ...props?.addJobData,
        visaStatus: [],
      });
      setIsRequired(false);
      // setDisabledPreferred(true);
    }
  };
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <div class="mb-2 d-flex">
            <label>Work Authorization Requirement*</label>
            <div
              class="form-floating ml-auto"
              style={{ margin: "15px 0px 0px 50px" }}
            >
              <div className="form-check form-switch d-flex align-items-center">
                <label className="form-check-label pt-0" htmlFor="remoteSwitch">
                  Not Required
                </label>
                <input
                  className="form-check-input ml-3"
                  type="checkbox"
                  id="isFresher"
                  checked={props?.addJobData?.visaStatus?.includes(
                    "Not Required"
                  )}
                  onChange={(e) => handleIsGlobal(e)}
                />
              </div>
            </div>
          </div>

          {!isRequired &&
            !props?.addJobData?.visaStatus?.includes("Not Required") && (
              <div
                className="form-floating Hover-Dropdown mb-2"
                style={{ zIndex: "1000" }}
              >
                <Select
                  isMulti
                  options={visaStatus}
                  name="visaStatus"
                  className={
                   ( props?.addJobVal.visaStatus && props?.addJobData?.visaStatus.length==0)
                      ? "border border-danger border-radius"
                      : ""
                  }
                  onChange={(e) => {
                    props?.setAddJobData({
                      ...props?.addJobData,
                      visaStatus: e.map((visa) => visa.value),
                    });
                  }}
                  isSearchable={true}
                  value={visaStatus?.filter((item) =>
                    props?.addJobData?.visaStatus.includes(item.value)
                  )}
                ></Select>
              </div>
            )}
          {(props?.addJobVal.visaStatus && props?.addJobData?.visaStatus.length==0) && (
            <small className="validation ml-2">*Required field</small>
          )}
        </div>
      </div>
    </>
  );
};

export default VisaStatus;
