import {
  GET_ALL_TAGS_BEGIN,
  GET_ALL_TAGS_SUCCESS,
  GET_ALL_TAGS_FAILURE,
} from "../../action/tagmanagement/getAllTags";

const initialState = {
  loading: true,
  tagsData: null,
};

const tagReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_TAGS_BEGIN:
      return {
        ...state,
        loading: true,
        tagsData: null,
      };
    case GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        tagsData: payload,
        loading: false,
      };
    case GET_ALL_TAGS_FAILURE:
      return {
        ...state,
        tagsData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default tagReducer;
