import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";

export const GET_JOBTITLE_RECRUITER_FILTER_BEGIN =
  "GET_JOBTITLE_RECRUITER_FILTER_BEGIN";
export const GET_JOBTITLE_RECRUITER_FILTER_SUCCESS =
  "GET_JOBTITLE_RECRUITER_FILTER_SUCCESS";
export const GET_JOBTITLE_RECRUITER_FILTER_FAILURE =
  "GET_JOBTITLE_RECRUITER_FILTER_FAILURE";
export const GET_STATE_RECRUITER_FILTER_BEGIN =
  "GET_STATE_RECRUITER_FILTER_BEGIN";
export const GET_STATE_RECRUITER_FILTER_SUCCESS =
  "GET_STATE_RECRUITER_FILTER_SUCCESS";
export const GET_STATE_RECRUITER_FILTER_FAILURE =
  "GET_STATE_RECRUITER_FILTER_FAILURE";
export const GET_COUNTRY_RECRUITER_FILTER_BEGIN =
  "GET_COUNTRY_RECRUITER_FILTER_BEGIN";
export const GET_COUNTRY_RECRUITER_FILTER_SUCCESS =
  "GET_COUNTRY_RECRUITER_FILTER_SUCCESS";
export const GET_COUNTRY_RECRUITER_FILTER_FAILURE =
  "GET_COUNTRY_RECRUITER_FILTER_FAILURE";
export const GET_ROLES_RECRUITER_FILTER_BEGIN =
  "GET_ROLES_RECRUITER_FILTER_BEGIN";
export const GET_ROLES_RECRUITER_FILTER_SUCCESS =
  "GET_ROLES_RECRUITER_FILTER_SUCCESS";
export const GET_ROLES_RECRUITER_FILTER_FAILURE =
  "GET_ROLES_RECRUITER_FILTER_FAILURE";
export const GET_CATEGORY_RECRUITER_FILTER_BEGIN =
  "GET_CATEGORY_RECRUITER_FILTER_BEGIN";
export const GET_CATEGORY_RECRUITER_FILTER_SUCCESS =
  "GET_CATEGORY_RECRUITER_FILTER_SUCCESS";
export const GET_CATEGORY_RECRUITER_FILTER_FAILURE =
  "GET_CATEGORY_RECRUITER_FILTER_FAILURE";

export const getRecruiterJobTitleList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBTITLE_RECRUITER_FILTER_BEGIN,
    });
    const res = await post(
      "/filter/recruiter/getjobtitleforrecruiter",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_JOBTITLE_RECRUITER_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOBTITLE_RECRUITER_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getRecruiterStateList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATE_RECRUITER_FILTER_BEGIN,
    });
    const res = await post("/filter/recruiter/getstateforrecruiter", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_STATE_RECRUITER_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STATE_RECRUITER_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getRecruiterCountryList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_RECRUITER_FILTER_BEGIN,
    });
    const res = await post("/filter/recruiter/getcountryforrecruiter", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_COUNTRY_RECRUITER_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COUNTRY_RECRUITER_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getRecruiterRolesList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROLES_RECRUITER_FILTER_BEGIN,
    });
    const res = await post("/filter/recruiter/getrolesforrecruiter", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_ROLES_RECRUITER_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ROLES_RECRUITER_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getRecruiterjobCategory = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_RECRUITER_FILTER_BEGIN,
    });
    const res = await post(
      "/filter/recruiter/getjobcategoryforrecruiter",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_CATEGORY_RECRUITER_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_RECRUITER_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
