import moment from "moment";
import React from "react";
import { Card, Col, Row, Badge } from "react-bootstrap";
import { FaFilter, FaIndustry, FaUser, FaClock } from "react-icons/fa";
import { camelCaseValueWithSpace } from "../../functions/CommonFunction";

function extractNonEmptyValues(jsonString) {
  const filterObject = JSON.parse(jsonString);
  let result = [];
  for (const key in filterObject) {
    if (
      filterObject[key].length > 0 ||
      Object.keys(filterObject[key])?.length != 0
    ) {
      if (
        key === "address" ||
        key === "jobTitle" ||
        key === "dispositionStatus" ||
        key === "educations" ||
        key === "positionTypes" ||
        key === "recruiterName" ||
        key === "subscriptionStatus" ||
        key === "visaStatus" ||
        key === "sourceChannel" ||
        key === "candidateTags"
      ) {
        const status = filterObject[key].map((item) => item.value);
        result.push({ [key]: status });
      } else if (key === "experienceLevel") {
        const status = filterObject[key].map((item) => item.label);
        result.push({ [key]: status });
      } else if (key === "skills" || key === "keywords") {
        const values = [];

        for (const termKey in filterObject[key][0]) {
          if (filterObject[key][0][termKey].length > 0) {
            values.push(...filterObject[key][0][termKey]);
          }
        }

        if (values.length > 0) {
          result.push({ [key]: values });
        }
      }
      if (key === "state" || key === "country" || key === "workAuthCountry") {
        const state = filterObject[key];
        result.push({ [key]: [state] });
      }
      if (key === "payRate") {
        result.push({
          [key]: [
            `${filterObject[key]?.min} ${filterObject[key]?.currency} - ${filterObject[key]?.max} ${filterObject[key]?.currency} ${filterObject[key]?.payType}`,
          ],
        });
      }
    }
  }
  return result;
}

const SubPoolCard = ({ data, setPool, setSubPool, setSelectedPool }) => {
  const infoDetails = extractNonEmptyValues(data?.info || "{}");
  const filterByText = infoDetails;
  return (
    <>
      <Card className="subPoolCards">
        <Card.Body>
          <Card.Title
            onClick={() => {
              setPool(true);
              setSubPool(false);
              setSelectedPool(JSON.parse(data?.info));
            }}
            style={{ cursor: "pointer" }}
          >
            <span style={{ fontSize: "16px" }}>
              <strong>{data?.name}</strong>
            </span>
          </Card.Title>
          <hr />
          <Row>
            <Col md={12} sm={12} lg={12}>
              <FaFilter /> Criteria :{" "}
              {filterByText.length > 0 ? (
                filterByText?.map((item, index) => (
                  <span className="tag tag-orange1">
                    <div key={index}>
                      {Object.keys(item)?.map((key) => (
                        <div key={key}>
                          <strong>{camelCaseValueWithSpace(key)}: </strong>
                          {Array?.isArray(item[key]) ? (
                            item[key].join(", ")
                          ) : (
                            <span>{item[key]}</span>
                          )}
                        </div>
                      ))}
                    </div>
                  </span>
                ))
              ) : (
                <span className="tag tag-orange1">No filters applied</span>
              )}
            </Col>
          </Row>

          {data?.industry && (
            <Row>
              <Col md={12} sm={12} lg={12} className="mb-2">
                <FaIndustry /> Industry :{" "}
                <span className="tag tag-blue3">{data?.industry}</span>
              </Col>
            </Row>
          )}

          <hr />
          <Row>
            <Col md={12} sm={12} lg={12} className="mb-2">
              <FaUser /> Created By : {data?.firstName + " " + data?.lastName}
            </Col>
            <Col md={12} sm={12} lg={12}>
              <FaClock /> Created On :{" "}
              {moment(data?.createdAt).format("MM/DD/YYYY")}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default SubPoolCard;
