import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/button";

function SupportModal({ supportData, closeSupportModal }) {
  const [URL, setURL] = useState();

  useEffect(() => {
    if (supportData.ImageURL) {
      setURL(JSON.parse(supportData.ImageURL)[0].ImageURL);
    }
  }, []);

  return (
    <Modal show={true} size="lg" animation={true}>
      <Modal.Header>
        <Modal.Title className="pb-0 pt-1 font-16">
          <h6>FAQ</h6>
        </Modal.Title>
        <button
          type="button"
          onClick={closeSupportModal}
          className="close p-0 bl-modal-close-btn closable"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="fal fa-times closable"></i>
        </button>
      </Modal.Header>

      <hr />

      <Modal.Body>
        <div className="pl-5">
          {supportData.Type === "text" ? (
            <div className="m-3 mb-5">
              <h4>{supportData.Title} </h4> <br />
              <div>
                <h6>Answer: </h6>
                <div
                  className="pr-3 overflow-auto ml-2"
                  dangerouslySetInnerHTML={{ __html: supportData.Content }}
                ></div>
              </div>
              {URL && (
                <div className="row">
                  <div className="col-lg-4">
                    <h6 className="mb-2">Uploaded Image:</h6>
                    <a href={URL} target="_blank">
                      <img src={URL} alt="" width="100%" height="100%" />
                    </a>
                    {/* <img src={URL} alt="" width="100%" height="100%" /> */}
                    {/* <img src={URL} alt="" width="700" height="350" /> */}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="m-5">
              <h3>Video Coming Soon...</h3>
            </div>
          )}
        </div>
        <hr />
        <div className="d-flex m-3">
          For any other queries, please contact alerts@high5hire.com
          {/* <button>

                    </button> */}
          <button
            className="ml-auto"
            variant="secondary"
            onClick={closeSupportModal}
          >
            Close
          </button>
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
                <div className='d-flex'>
                    For any other queries, please contact alerts@high5hire.com
                    <Button variant="secondary" onClick={closeSupportModal}>
                        Close
                    </Button>
                </div>            
            </Modal.Footer> */}
    </Modal>
  );
}

export default SupportModal;
