import {
  GET_CURATOR_INVITE_DETAILS_BEGIN,
  GET_CURATOR_INVITE_DETAILS_SUCCESS,
  GET_CURATOR_INVITE_DETAILS_FAILURE,
  GET_CURATOR_INVITE_DATA_BEGIN,
  GET_CURATOR_INVITE_DATA_SUCCESS,
  GET_CURATOR_INVITE_DATA_FAILURE,
} from "../../action/curator/ApproveRejectCurator";

const initialState = {
  inviteDetailsLoading: false,
  inviteDetails: {},
  curatorStatusLoading: false,
  curatorStatus: [],
};

const approveRejectCuratorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURATOR_INVITE_DETAILS_BEGIN:
      return {
        ...state,
        inviteDetailsLoading: true,
        inviteDetails: [],
      };
    case GET_CURATOR_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        inviteDetailsLoading: false,
        inviteDetails: payload,
      };
    case GET_CURATOR_INVITE_DETAILS_FAILURE:
      return {
        ...state,
        inviteDetailsLoading: false,
        inviteDetails: [],
      };
    
    case GET_CURATOR_INVITE_DATA_BEGIN:
      return {
        ...state,
        curatorDataLoading: true,
        curatorStatus: [],
      };
    case GET_CURATOR_INVITE_DATA_SUCCESS:
      return {
        ...state,
        curatorDataLoading: false,
        curatorStatus: payload,
      };
    case GET_CURATOR_INVITE_DATA_FAILURE:
      return {
        ...state,
        curatorDataLoading: false,
        curatorStatus: [],
      };
    default:
      return state;
  }
};

export default approveRejectCuratorReducer;
