import { toast } from "react-toastify";
import { post, get } from "../../service/ApiService";

export const GET_PROFILE_INFO_BEGIN = "GET_PROFILE_INFO_BEGIN";
export const GET_PROFILE_INFO_SUCCESS = "GET_PROFILE_INFO_SUCCESS";
export const GET_PROFILE_INFO_FAILURE = "GET_PROFILE_INFO_FAILURE";

export const getProfileInfo = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROFILE_INFO_BEGIN,
    });
    const res = await post("/profile/contactinfo", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_PROFILE_INFO_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROFILE_INFO_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const GET_TENANT_LOGO_BEGIN = "GET_TENANT_LOGO_BEGIN";
export const GET_TENANT_LOGO_SUCCESS = "GET_TENANT_LOGO_SUCCESS";
export const GET_TENANT_LOGO_FAILURE = "GET_TENANT_LOGO_FAILURE";

export const getTenantLogo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TENANT_LOGO_BEGIN,
    });
    const res = await get("/profile/gettenantlogo");
    if (res.status === 200) {
      dispatch({
        type: GET_TENANT_LOGO_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TENANT_LOGO_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};