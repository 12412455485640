import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";

import sparkles from "../../../../assets/lottie/sparkles.json";
import checkMark from "../../../../assets/lottie/checkmark.json";
import { post } from "../../../../service/ApiService";
import Loader from "../../../loader/Loader";

const { Anime } = ReactAnime;

const UnsubscribeModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  selectedTalents,
  fetchData,
  setSelectedTalents,
  fetchTalentCount,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loop] = useState(true);
  const handleConfirmation = async () => {
    setLoading(true);
    try {
      await post("/talent/bulkTalentUnsubscribe", {
        candidateId: selectedTalents.map((item) => item.userId + ""),
      }).then((res) => {
        setLoading(false);
        setShowSuccessModal(true);
        fetchData(1, true);
        fetchTalentCount(true);
      });
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSuccessClose = () => {
    setSelectedTalents([]);
    setShowSuccessModal(false);
    setShowConfirmationModal(false);
  };

  const areAllUnsubbed = () => {
    return selectedTalents.every((x) => x.newSletter === "Unsubscribed");
  };

  return (
    <div className="">
      {/* Confirmation Modal */}
      {!showSuccessModal ? (
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
        >
          <Modal.Header closeButton>
            <h6>Confirmation</h6>
          </Modal.Header>
          <Modal.Body className="p-4">
            {loading && <Loader />}
            {areAllUnsubbed() ? (
              selectedTalents.length === 1 ? (
                <p>Selected talent is already unsubscribed.</p>
              ) : (
                <p>Selected talents are already unsubscribed.</p>
              )
            ) : (
              <p>
                Are you sure you want to unsubscribe the
                <strong> {selectedTalents?.length}</strong> selected{" "}
                {selectedTalents.length * 1 === 1 ? "talent" : "talents"}? After
                this action, selected{" "}
                {selectedTalents.length * 1 === 1 ? "talent" : "talents"} will
                no longer receive any mails from High5.
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmationModal(false)}
            >
              {!areAllUnsubbed() ? "Cancel" : "Close"}
            </Button>
            {!areAllUnsubbed() && (
              <Button variant="primary" onClick={handleConfirmation}>
                Confirm
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={showSuccessModal} size="lg" onHide={handleSuccessClose}>
          <Modal.Header closeButton>
            <h6>Unsubscribed</h6>
          </Modal.Header>
          <Modal.Body>
            <>
              <div class="section section-sm pb-0">
                <div class="success-animation">
                  <div class="checkmark">
                    <Lottie loop={loop} animationData={checkMark} play></Lottie>
                  </div>
                  <Anime
                    initial={[
                      {
                        targets: "#LottieSparkles",
                        opacity: 0,
                        delay: 1000,
                      },
                    ]}
                  >
                    <div class="sparkles">
                      <Lottie
                        loop={loop}
                        animationData={sparkles}
                        id="LottieSparkles"
                        play
                      ></Lottie>
                    </div>
                  </Anime>
                </div>
                <Anime
                  initial={[
                    {
                      targets: ".success-message",
                      opacity: 1,
                      delay: 800,
                      easing: "linear",
                    },
                  ]}
                >
                  <div class="success-message" style={{ opacity: "0" }}>
                    <div class="container">
                      <div class="col-lg-12">
                        <>
                          <h4 class="text-center">
                            {selectedTalents.length * 1 === 1
                              ? `1 talent Unsubscribed!`
                              : `${selectedTalents.length} talents Unsubscribed!`}
                          </h4>
                          <div class="text-center">
                            <p>
                              Unsubscribed successfully. The listed{" "}
                              {selectedTalents.length * 1 === 1
                                ? "talent"
                                : "talents"}{" "}
                              will no longer receive any mails from High5.
                            </p>
                          </div>
                          {/*
                          <div className="card card-flat card-lg">
                            <div className="container mt-5">
                               <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">Sr No</th>
                                    <th scope="col">Candidate Name</th>
                                    <th scope="col">Email ID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">1</th>
                                    <td>John Doe</td>
                                    <td>john.doe@example.com</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">2</th>
                                    <td>Jane Smith</td>
                                    <td>jane.smith@example.com</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">3</th>
                                    <td>Bob Johnson</td>
                                    <td>bob.johnson@example.com</td>
                                  </tr>
                                </tbody>
                              </table> 
                            </div>
                          </div>
                              */}
                        </>
                      </div>
                    </div>
                  </div>
                </Anime>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSuccessClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default UnsubscribeModal;
