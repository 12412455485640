import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";

export const GET_DISPOSITION_DISQUALIFY_BEGIN =
  "GET_DISPOSITION_DISQUALIFY_BEGIN";
export const GET_DISPOSITION_DISQUALIFY_SUCCESS =
  "GET_DISPOSITION_DISQUALIFY_SUCCESS";
export const GET_DISPOSITION_DISQUALIFY_FAILURE =
  "GET_DISPOSITION_DISQUALIFY_FAILURE";

export const getCommunityDispositionDisqualify = (payloadObj) => async (
  dispatch
) => {
  try {
    dispatch({
      type: GET_DISPOSITION_DISQUALIFY_BEGIN,
    });
    const res = await post(
      "/community/getCommunityDisqualifyDisposition",
      payloadObj
    );
    if (res.status === 200) {
      dispatch({
        type: GET_DISPOSITION_DISQUALIFY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DISPOSITION_DISQUALIFY_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};