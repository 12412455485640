import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import TopNavBar from "../../../../layout/career/TopNavbar";
import JobList from "./JobList";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../loader/Loader";
import { getTalentDetails } from "../../../../../action/talent/TalentDetails";
import {
  fetchCareerFilterJobList,
  getCareerJobCount,
  fetchCareerFilterSavedJobList,
  getSavedJobCareerCount,
} from "../../../../../action/disposition/job/career/CareerHome";
import { industryCategories } from "../../../../../constants/Constants";

const Index = () => {
  const dispatch = useDispatch();
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterParams, setFilterParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const location = useLocation();
  const [savedJobs, setSavedJobs] = useState([]);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    industries: [],
    jobLocation: [],
    jobType: [],
  });
  let path = window.location.pathname.split("/").pop();

  const {
    candidateDetailsLoading,
    candidateData,
    jobFilterList,
    jobFilterListLoading,
    careerCount,
    careerCountLoading,
    savedJobFilterList,
    savedJobFilterListLoading,
    savedJobCareerCount,
    savedJobCareerCountLoading,
  } = useSelector(
    (state) => ({
      candidateDetailsLoading:
        state.talentDetailsReducer.candidateDetailsLoading,
      candidateData: state.talentDetailsReducer.candidateData,
      jobFilterList: state.getCareerHomeReducer.jobFilterList,
      jobFilterListLoading: state.getCareerHomeReducer.loading,
      careerCount: state.getCareerHomeReducer.careerCount,
      careerCountLoading: state.getCareerHomeReducer.careerCountLoading,
      savedJobFilterList: state.getCareerHomeReducer.savedJobFilterList,
      savedJobFilterListLoading: state.getCareerHomeReducer.savedJobloading,
      savedJobCareerCount: state.getCareerHomeReducer.savedJobCount,
      savedJobCareerCountLoading:
        state.getCareerHomeReducer.savedJobCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    getCandidateData();
    fetchData(1, true);
  }, []);

  const getCandidateData = () => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };
    dispatch(getTalentDetails(payload));
  };

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      fetchSuggestions(value);
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [value]);

  const fetchSuggestions = async (value) => {
    try {
      applySearch();
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [location.state?.category, candidateData]);

  const fetchJobs = () => {
    if (candidateData) {
      let savedJobIds = [];
      if (sessionStorage.getItem("userId") == null) {
        const savedJobsJSON = localStorage.getItem("savedJobIds");
        savedJobIds = savedJobsJSON ? JSON.parse(savedJobsJSON) : [];
        setSavedJobs(savedJobIds);
      }
      if (sessionStorage.getItem("userId")) {
        savedJobIds = candidateData?.favouriteJobs
          ? JSON.parse(candidateData?.favouriteJobs)
          : [];
        setSavedJobs(savedJobIds);
      }

      const getJob = async () => {
        const candidate = sessionStorage.getItem("userId") || "";
        try {
          let industriesArray = industryCategories?.filter(
            (x) => x.path === location.state?.category
          );
          const payload = {
            candidateId: candidate,
            category:
              industriesArray.length > 0 &&
              location.state?.category !== "all-jobs"
                ? industriesArray[0].jobName
                : "",
            currentPage: 1,
            limit: 5,
            filterObjects: [],
            searchText: value,
          };
          if (candidate && path === "savedjobs") {
            dispatch(fetchCareerFilterSavedJobList(payload));
            dispatch(getSavedJobCareerCount(payload));
          } else {
            dispatch(fetchCareerFilterJobList(payload));
            dispatch(getCareerJobCount(payload));
          }
          setJobs(
            path === "savedjobs"
              ? jobFilterList?.filter((j) => savedJobIds.includes(j.jobId))
              : jobFilterList
          );
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };
      getJob();
    }
  };

  const fetchData = (currentPage, type) => {
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: currentPage,
      limit: 5,
      filterObjects: selectedData,
      searchText: value,
    };
    dispatch(fetchCareerFilterJobList(payload));
  };

  const addJobToLocalStorage = (jobId) => {
    setSavedJobs((prevSavedJobs) => {
      const isJobSaved = prevSavedJobs.includes(jobId);
      if (!isJobSaved) {
        const updatedJobs = [...prevSavedJobs, jobId];
        localStorage.setItem("savedJobIds", JSON.stringify(updatedJobs));
        return updatedJobs;
      } else {
        const updatedJobs = prevSavedJobs.filter(
          (savedJobId) => savedJobId !== jobId
        );
        localStorage.setItem("savedJobIds", JSON.stringify(updatedJobs));
        return updatedJobs;
      }
    });
    setJobs(jobFilterList?.filter((j) => savedJobs.includes(j.jobId)));
  };

  const addFavouriteJob = async (jobId) => {
    let jobArray = [];
    if (candidateData.favouriteJobs == null) {
      let idString = jobId.toString();
      jobArray = [jobId];
    } else {
      let favJobArray = JSON.parse(candidateData?.favouriteJobs);
      if (favJobArray?.includes(jobId)) {
        jobArray = favJobArray.filter((f) => f != jobId);
      } else {
        jobArray = [...favJobArray, jobId];
      }
    }
    const params = {
      candidateId: sessionStorage.getItem("userId"),
      favouriteJobs: JSON.stringify(jobArray),
    };
    setLoading(true);
    await post("/favourites/favouritejobs", params)
      .then((res) => {
        getCandidateData();
        setLoading(false);
        !candidateData?.favouriteJobs?.includes(jobId)
          ? toast.success("Job added to favorites!")
          : toast.success("Job removed from favorites!");
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleSaveJob = (jobId) => {
    if (sessionStorage.getItem("userId") == null) addJobToLocalStorage(jobId);
    else addFavouriteJob(jobId);
  };

  const applyFilter = (val, status) => {
    setCurrentPage(1);
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: 1,
      limit: 5,
      filterObjects: getFilterParamsNew(val, status),
      searchText: value,
    };
    if (candidate && path === "savedjobs") {
      dispatch(fetchCareerFilterSavedJobList(payload));
      dispatch(getSavedJobCareerCount(payload));
    } else {
      dispatch(fetchCareerFilterJobList(payload));
      dispatch(getCareerJobCount(payload));
    }
  };

  const applySearch = (val, status) => {
    setCurrentPage(1);
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: 1,
      limit: 5,
      filterObjects: Object.keys(filterParams).length > 0 ? filterParams : "",
      searchText: value,
    };
    if (candidate && path === "savedjobs") {
      dispatch(fetchCareerFilterSavedJobList(payload));
      dispatch(getSavedJobCareerCount(payload));
    } else {
      dispatch(fetchCareerFilterJobList(payload));
      dispatch(getCareerJobCount(payload));
    }
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      industries:
        status == ""
          ? []
          : status == "industries"
          ? val
          : selectedData.industries,
      jobType:
        status == "" ? [] : status == "jobType" ? val : selectedData.jobType,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  return (
    <>
      {(loading ||
        candidateDetailsLoading ||
        (careerCountLoading && savedJobCareerCountLoading) ||
        (jobFilterListLoading && savedJobFilterListLoading)) && <Loader />}
      <TopNavBar
        savedJobs={savedJobs}
        setSavedJobs={setSavedJobs}
        isLoginModal={isLoginModal}
        setIsLoginModal={setIsLoginModal}
        isFrom="AllJobs"
      />
      <JobList
        savedJobs={savedJobs}
        path={path}
        jobs={
          path === "savedjobs"
            ? sessionStorage.getItem("userId") == null
              ? jobs
              : savedJobFilterList
            : jobFilterList
        }
        handleSaveJob={handleSaveJob}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        applyFilter={applyFilter}
        careerCount={
          path === "savedjobs"
            ? sessionStorage.getItem("userId") == null
              ? [{ count: jobs?.length }]
              : savedJobCareerCount
            : careerCount
        }
        fetchData={fetchData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default Index;
