import React from "react";
import EditSubCommunity from "./EditSubCommunity";
import ShareJob from "./sharejob/Index";
import {
  getUserRole,
  getUserTenantID,
} from "../../../../service/AuthService";

const Index = (props) => {
  const { sideView, setSideView } = props;

  const handleSidePanelView = (view) => {
    setSideView(view);
  };

  return (
    <div>
      <div className="card card-flat card-lg card-bottom-straight">
        <div className="card-body pb-0">
          <div className="d-flex align-items-start">
            <div>
              <p className="mb-0">
                Community ID: <strong>{props?.jobData?.poolId}</strong>
              </p>
              <br /> <br />
            </div>
            <div className="ml-auto">
              {(getUserRole() === "CustomerSuccess" ||
                (props?.jobData?.fk_tenant == getUserTenantID() &&
                  getUserRole() == "Recruiter")) && (
                <EditSubCommunity jobData={props?.jobData} />
              )}
              {(getUserRole() === "Recruiter" ||
                getUserRole() === "CommunityManager") && (
                <ShareJob jobData={props?.jobData} />
              )}
            </div>
          </div>
          <hr className="m-0 hr-white" />
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView === "overview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("overview")}
              >
                Overview
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView === "qna" ? "nav-link pb-3 active" : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("qna")}
              >
                Screening
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView === "activity"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("activity")}
              >
                Activity
              </button>
            </li>
            {getUserRole() !== "CustomerSuccess" && (
              <li className="nav-item">
                <button
                  type="button"
                  className={
                    sideView === "notes"
                      ? "nav-link pb-3 active"
                      : "nav-link pb-3"
                  }
                  onClick={() => handleSidePanelView("notes")}
                >
                  Notes
                </button>
              </li>
            )}
            {getUserRole() === "CustomerSuccess" && (
              <li className="nav-item">
                <button
                  type="button"
                  className={
                    sideView === "curator"
                      ? "nav-link pb-3 active"
                      : "nav-link pb-3"
                  }
                  onClick={() => handleSidePanelView("curator")}
                >
                  Curator
                </button>
              </li>
            )}
            {getUserRole() === "CustomerSuccess" && (
              <li className="nav-item">
                <button
                  type="button"
                  className={
                    sideView === "source"
                      ? "nav-link pb-3 active"
                      : "nav-link pb-3"
                  }
                  onClick={() => handleSidePanelView("source")}
                >
                  Source
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
