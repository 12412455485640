import React from "react";
import { getUserRole } from "../../../service/AuthService";

const SidePanelTabs = ({ general, pools, communities, updateTab }) => {
  return (
    <div className="w-100" style={{ borderBottom: "1px solid #ccc" }}>
      <ul className="nav nav-tabs">
        <li className="nav-item mb-0 pb-0">
          <button
            type="button"
            className={general ? "nav-link active" : "nav-link"}
            onClick={() => {
              updateTab("general");
            }}
          >
            <span>General</span>
          </button>
        </li>
        {pools.length > 0 && getUserRole() !== "CommunityManager" && (
          <li className="nav-item mb-0 pb-0">
            <button
              type="button"
              className={communities ? "nav-link active" : "nav-link"}
              onClick={() => {
                updateTab("communinties");
              }}
            >
              <span>Communities</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SidePanelTabs;
