import React from "react";
import TCHeader from "../../../talentcommunity/TalentCommunityHeader";
import CommunityList from "../../../talentcommunity/communitylist/CommunityList";

const Index = () => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card card-flat card-borderless p-4 p-0-600 mb-3">
            <TCHeader />
            <hr className="mt-0 pt-0" />
            < CommunityList/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
