import React, { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "../../common/pagination/Pagination";

const TemplatePagination = ({
  searchValue,
  searchData,
  openSidePanel,
  currentPage,
  setCurrentPage,
  category,
  publicTemplates,
  onChangeCount
}) => {
  const [templateData, setTemplateData] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const pageLimit = 5;
  const itemsPerPage = 10;


  useEffect(() => {
    setCurrentPage(1);
    const temp = searchData
      .filter((item) =>
        category === "all" ? true : item.category === category
      )
      .filter((i) => (publicTemplates ? i.createdBy == 1 : i.createdBy != 1));
    setTemplateData(temp.slice(0, itemsPerPage));
    setDataTotal(temp.length);
    onChangeCount(temp.length)
  }, [searchData,category]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const slicedData =
      searchData &&
      searchData
        .filter((item) =>
          category === "all" ? true : item.category === category
        )
        .filter((i) => (publicTemplates ? i.createdBy == 1 : i.createdBy != 1))
        .slice(startIndex, startIndex + itemsPerPage);
    setTemplateData(slicedData);
  };

  return (
    <>
      <div className="px-3 pt-0 table-cards-1024">
        <table className="table table-hoverable-cells table-cards-1024">
          <thead>
            <tr>
              <th scope="col">Job Title</th>
              <th scope="col">Job Type</th>
              <th scope="col">Template Name</th>
              <th scope="col">Category</th>
              <th scope="col">Created On</th>
              <th scope="col">Last Used</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          {templateData.map((item) => (
            <tbody>
              <tr
                onClick={() => {
                  openSidePanel(item);
                }}
              >
                <td>{item.jobTitle}</td>
                <td>{item.jobType}</td>
                <td>{item.templateName}</td>
                <td>{item.category}</td>
                <td>{moment(item.createdDate).format("MM/DD/YYYY")}</td>
                <td>
                  {item.lastUsed
                    ? moment(item.lastUsed).format("MM/DD/YYYY")
                    : "Not yet used"}
                </td>
                <td>
                  <span
                    class={
                      item.status === "Active"
                        ? "tag tag-green1"
                        : "tag tag-red1"
                    }
                  >
                    {item.status === "Deactive" ? "Deactivated" : item.status}
                  </span>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      {templateData.length === 0 && (
        <div>
          <div class="text-center p-3">
            <div class="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">
              No records found {searchValue && "for " + searchValue}
            </div>
          </div>
        </div>
      )}
      <hr />
      {templateData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={dataTotal}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          limit={pageLimit}
        />
      )}
    </>
  );
};

export default TemplatePagination;
