import React from "react";
import { PageNotFoundImage } from "../../constants/Constants";

function PageNotFound() {
  return (
    <section className="section section-sm bg-gray3 text-center bg-cover bg-cover h-100 notFound">
      <div className="container-fluid h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-lg-6">
            <div className="notFoundIcon">
              <img height="60px" width="132px" src={PageNotFoundImage} alt="" />
              <lottie-player
                src="/lottie/404.json"
                background="transparent"
                speed="1"
                className="comingSoonImg notFoundGif"
                loop
                autoplay
              ></lottie-player>
            </div>
            <h4 className="font-bold brand_blue">
              <span className="font-coral">We're Sorry</span>
            </h4>
            <p> but we can't seem to find the page you are looking for</p>
            <a className="btn btn-coral btn-rounded mt-3 mb-5" href="/">
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
