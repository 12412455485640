import {
  GET_JOB_SUMMARY_BEGIN,
  GET_JOB_SUMMARY_SUCCESS,
  GET_JOB_SUMMARY_FAILURE,
  GET_JOB_SUMMARY_COUNT_BEGIN,
  GET_JOB_SUMMARY_COUNT_SUCCESS,
  GET_JOB_SUMMARY_COUNT_FAILURE,
} from "../../../../action/dashboard/superadmin/client/JobSummary";

const initialState = {
  loading: true,
  countLoading: true,
  jobData: [],
  jobSummaryCount: [],
};

const getJobSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOB_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
        jobData: [],
      };
    case GET_JOB_SUMMARY_SUCCESS:
      return {
        ...state,
        jobData: payload,
        loading: false,
      };
    case GET_JOB_SUMMARY_FAILURE:
      return {
        ...state,
        jobData: [],
        loading: false,
      };
    case GET_JOB_SUMMARY_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        jobSummaryCount: [],
      };
    case GET_JOB_SUMMARY_COUNT_SUCCESS:
      return {
        ...state,
        jobSummaryCount: payload,
        countLoading: false,
      };
    case GET_JOB_SUMMARY_COUNT_FAILURE:
      return {
        ...state,
        jobSummaryCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getJobSummaryReducer;
