import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import HereMap from "../../../../location/HereMap";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../common/loader/Loader";
import ImageUpload from "../../../../common/fileupload/ImageUpload";
import { editClientValidation } from "../../../../validation/EditClient";

function EditClient(props) {
  const [formChanged, setFormChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    ID_user: "",
    lastname: "",
    middlename: "",
    name: "",
    stateName: "",
    CountryName: "",
    cityName: "",
    address: "",
    zipCode: "",
    mobilePhone: "",
  });
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });
  const [errors, setErrors] = useState({});
  const [phoneValidation, setPhoneValidation] = useState(true);

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formChanged) {
      return; // Disable submit button if no changes are made
    }
    // get our new errors
    const newErrors = editClientValidation(form, phoneValidation);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      // alert("please fill all details ")
    } else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          ...imageData,
          tenantName: props.tenantlist.tenantname,
          logourl: props.tenantlist.logourl,
        };
        const res = await post("/dashboard/sa/edittenant", paramters);

        if (res.status === 200) {
          setLoading(false);
          props.getTenantData();
          toast.success("Client details updated successfully ");
          props.setopenTenantPopUP(false);
          close();
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occurred While updating tenant  Details", error);
      }
    }
    // api call
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    setFormChanged(true);
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const close = () => {
    props.closeAdd();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
    setFormChanged(true);
  };

  let type = "";
  if (props.userlist) {
    type = props.userlist.type;
  }

  useEffect(() => {
    setForm({
      ID_user: props.tenantlist.id_useraccessdetail,
      lastname: props.tenantlist.lastName ?? "",
      email: props.tenantlist.useradminaccount ?? "",
      middlename: props.tenantlist.MiddleName ?? "",
      name: props.tenantlist.firstName ?? "",
      tenant: props.tenantlist.tenantname.replace("GIG - ", "") ?? "",
      tenanttype: props.tenantlist.typename ?? "",
      stateName: props.tenantlist.addressState ?? "",
      CountryName: props.tenantlist.country ?? "",
      cityName: props.tenantlist.addressCity ?? "",
      address: props.tenantlist.address ?? "",
      zipCode: props.tenantlist.zipcode ?? "",
      mobilePhone: props.tenantlist.mobilePhone ?? "",
      mobilePhoneCode: props.tenantlist.mobilePhoneCode ?? "",
    });
    let filename = props?.tenantlist?.logourl?.split("/").pop();
    setImageData({
      ...imageData,
      base64: props?.tenantlist?.logoBase64,
      fileName: filename,
    });
  }, []);

  useEffect(() => {
    setFormChanged(true);
  }, [imageData]);

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                Edit Client
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="showBody" class="bl-modal-body pb-0">
              <div
                v-if="isAssign === false"
                class="card card-flat card-borderless bg-gray4 text-center"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter Tenant Type "
                            value={form.tenanttype}
                            onChange={(e) =>
                              setField("tenanttype", e.target.value)
                            }
                            isInvalid={!!errors.tenanttype}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.tenanttype}
                          </Form.Control.Feedback>
                          <label>Client Type*</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter Tenant Name "
                            value={form.tenant}
                            onChange={(e) => setField("tenant", e.target.value)}
                            isInvalid={!!errors.tenant}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.tenant}
                          </Form.Control.Feedback>
                          <label>Client Name*</label>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter email "
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                          <label>Email ID*</label>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            value={form.name}
                            onChange={(e) => setField("name", e.target.value)}
                            isInvalid={!!errors.name}
                          />

                          <label>First Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            value={form.lastname}
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            isInvalid={!!errors.lastname}
                          />

                          <label>Last Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.lastname}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4 ">
                      <div className="col-lg-12">
                        <div className="">
                          <PhoneInput
                            inputClass={"form-control font-14 w-100"}
                            style={{
                              border: errors.mobilePhone && "1px solid #ff0000",
                            }}
                            required="true"
                            placeholder="mobile"
                            id="homePhone"
                            country={"us"}
                            value={form.mobilePhone}
                            name="homePhone"
                            onChange={(value, country, e) => {
                              _onChangeText(value, country);
                              console.log(value, country.dialCode);
                              setForm({
                                ...form,
                                mobilePhone: value,
                                mobilePhoneCode: country.dialCode,
                              });
                              setFormChanged(true);
                            }}
                          />
                        </div>
                        {errors.mobilePhone && (
                          <span style={{ float: "left" }}>
                            <small
                              className="validation"
                              style={{ float: "left" }}
                            >
                              {errors.mobilePhone}
                            </small>
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMap
                          setForm={setForm}
                          form={form}
                          setFormChanged={setFormChanged}
                        />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                            />
                            <label>Address</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressCity"
                              placeholder="Address City"
                              value={form.cityName}
                              onChange={(e) =>
                                setField("cityName", e.target.value)
                              }
                              isInvalid={!!errors.cityName}
                            />
                            <label>City* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.cityName}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="stateName"
                              placeholder="Address State"
                              value={form.stateName}
                              onChange={(e) =>
                                setField("stateName", e.target.value)
                              }
                              isInvalid={!!errors.stateName}
                            />
                            <label> State* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.stateName}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="CountryName"
                              placeholder="Address Country"
                              value={form.CountryName}
                              onChange={(e) =>
                                setField("CountryName", e.target.value)
                              }
                              isInvalid={!!errors.CountryName}
                            />
                            <label> Country* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.CountryName}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ImageUpload
                          imageData={imageData}
                          setImageData={setImageData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={props.closeAdd}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
                disabled={!formChanged}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditClient;
