import React, { useEffect, useState } from "react";
import PaginationComponent from "../../common/pagination/Pagination";
import { FaTrashAlt } from "react-icons/fa";

const DataTable = ({ recipients, setRecipients, handleCandidateRemove }) => {
  const [data, setData] = useState(recipients);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    const startIndex = (selectedPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newData = recipients.slice(startIndex, endIndex);
    setData(newData);
  };
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  useEffect(() => {
    handlePageChange(1);
  }, [recipients]);
  return (
    <div className="container pt-5">
      {/* <h2>Recipients</h2> */}
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {/* <th>User ID</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Subscription</th>
              <th> Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((recipient) => (
              <tr key={recipient.userId}>
                {/* <td>{recipient.userId}</td> */}
                <td>{recipient.firstName}</td>
                <td>{recipient.lastName}</td>
                <td>{recipient.email}</td>
                <td>{recipient.newSletter || "Subscribed"}</td>
                <td>
                  {recipient.homePhoneCode} {recipient.homePhone}
                </td>
                <td>
                  <span
                    className="pointer"
                    onClick={() => handleCandidateRemove(recipient)}
                  >
                    <FaTrashAlt />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationComponent
        currentPage={currentPage}
        totalItems={recipients.length}
        itemsPerPage={pageSize}
        onPageChange={handlePageChange}
        limit={5}
      />
    </div>
  );
};

export default DataTable;
