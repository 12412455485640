import React, { useState, useEffect } from "react";
import "react-tabs/style/react-tabs.css";
import Index from "../talentpool/talentlist/Index";
import TopNavbar from "../layout/TopNavbar";
import SideNavBar from "../layout/SideNavbar";
import { getTenantType, getUserRole } from "../../service/AuthService";
// import TalentView from "../hiringmanager/talenttabs/TalentView";
// import TalentLists from "../superadmin/talenttabs/TalentLists";
// import CMTalentList from "../communitymanager/CMTalentList";
import Footer from "../layout/Footer";

function CommonTalentList() {
  const [toggle, setToggle] = useState(true);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
  }, []);

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavbar setToggle={setToggle} toggle={toggle} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            {/* <div className="container "> */}
            <div className="mx-3">
              {/* if role is recruitrer then shoe this otherwise show hiring manager component  */}

              {userRole === "Recruiter" && <Index />}
              {userRole === "HiringManager" && <Index />}
              {userRole === "Admin" && <Index />}
              {userRole === "SuperAdmin" && <Index />}
              {userRole === "CustomerSuccess" && <Index />}
              {userRole === "CommunityManager" && <Index />}
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default CommonTalentList;
