import React from "react";

const FormHeader = () => {
  return (
    <>
      <h4 style={{ textAlign: "center" }}>SIGN UP FOR THE</h4>
      <h4 style={{ textAlign: "center", color: "#ff8f73" }}>
        HIGH5 RECRUITER MARKETPLACE
      </h4>
      <h2 style={{ textAlign: "center" }}>
        Step 1. Complete Online Recruiting Form
      </h2>
      <h2 style={{ textAlign: "center" }}>Step 2. Make More Money</h2>
      <p style={{ textAlign: "center" }}>
        <span style={{ fontWeight: "bold" }}>
          It takes less than two minutes
        </span>{" "}
        to begin your expertise and monetize your worth.
      </p>
    </>
  );
};

export default FormHeader;
