import React, { useEffect, useRef, useState } from "react";
import GoogleLocationCityAPI from "../../common/location/GoogleLocationAPI";

const TitleAndLocation = ({
  addJobData,
  addJobVal,
  setAddJobData,
  edit,
  userLoading,
}) => {
  const [countryValue, setCountryValue] = useState({
    address1: "",
    address2: "",
    address3: "",
    city: addJobData?.city ? addJobData?.city : "",
    country: addJobData?.country ? addJobData?.country : "",
    locality: "",
    route: "",
    state: addJobData?.state ? addJobData?.state : "",
    street: "",
    zip: "",
  });
  const searchInput = useRef(null);
  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setAddJobData({
      ...addJobData,
      city: city,
      state: address.state,
      country: address.country,
      location: searchInput?.current?.value,
    });
    setCountryValue(address);
  };

  // useEffect(() => {
  //   const city = countryValue.locality || countryValue.city;
  //   if (addJobData.isCountry) {
  //     searchInput.current.value = countryValue.country;
  //   } else if (countryValue) {
  //     searchInput.current.value =
  //       (city ? city + "," : "") + countryValue.country;
  //   } else {
  //     searchInput.current.value =
  //       (addJobData?.city ? addJobData?.city + "," : "") + addJobData?.country;
  //   }
  // }, [addJobData]);
  useEffect(() => {
    const city = countryValue.locality || countryValue.city;
    if (addJobData.isCountry) {
      searchInput.current.value = addJobData.country;
    } else {
      // Use addJobData.city and addJobData.country directly for the initial value
      searchInput.current.value = addJobData.location;
    }
  }, [addJobData]);

  useEffect(() => {
    if (addJobData.isCountry) {
      searchInput.current.value = addJobData.country;
    } else {
      // Use addJobData.city and addJobData.country directly for the initial value
      searchInput.current.value = addJobData.location;
    }
  }, []);

  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-5">
              <div className="form-floating">
                <input
                  type={"text"}
                  className={
                    addJobVal.jobTitle && addJobData?.jobTitle.trim() === ""
                      ? "form-control border border-danger w-100"
                      : "form-control"
                  }
                  placeholder="Enter Job Title"
                  value={addJobData.jobTitle}
                  name="jobTitle"
                  onChange={(e) =>
                    setAddJobData({ ...addJobData, jobTitle: e.target.value })
                  }
                  disabled={edit}
                />
                <label>Job Title*</label>
              </div>
              {addJobVal.jobTitle && addJobData?.jobTitle.trim() === "" && (
                <small className="validation ml-2">*Required field</small>
              )}
            </div>
            <div className="col-lg-7">
              <div className="form-floating ">
                <GoogleLocationCityAPI
                  setLocation={setLocation}
                  searchInput={searchInput}
                  addJobData={addJobData}
                  edit={edit}
                  className={
                    addJobVal?.location && countryValue?.country?.trim() === ""
                      ? "form-control border border-danger w-100"
                      : "form-control"
                  }
                />
                <label>Location*</label>
              </div>
              <div className="d-flex justify-content-between">
                {addJobVal?.location &&
                  countryValue?.country?.trim() === "" && (
                    <small className="validation ml-2">*Required field</small>
                  )}
                <div
                  className="form-check form-check-square"
                  style={{ border: "none", background: "none" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="isFresher"
                    checked={addJobData.isCountry}
                    disabled={edit}
                    onChange={(e) =>
                      setAddJobData({
                        ...addJobData,
                        isCountry: e.target.checked,
                      })
                    }
                  />

                  <label className="ml-2 pt-0" htmlFor="remoteSwitch">
                    <small>Show Country only</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleAndLocation;
