import React, { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";

function Certifications(props) {
  const inputDataObj = {
    certificationName: "",
    certificate: "",
    issueDate: "",
    expiryDate: "",
  };

  const inputValObj = {
    certificationName: "",
    certificate: "",
    issueDate: "",
    expiryDate: "",
  };

  const handleChange = (e, i, issueDate) => {
    if (e.target.id === "isSelect") {
      const newArray = [...props.certifications];
      newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
      props.setCertifications(newArray);
      newArray = [...newArray];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.checked,
        expiryDate: "",
      };
      // props.setExperiences(newArray)
      //  props.setProfileInfo({ ...props.profileInfo, currentEmployer: newArray[i].employerName, designation: newArray[i].jobTitle })
    } else if (e.target.id === "issueDate") {
      let newArray = [...props.certifications];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.value,
        expiryDate: "",
      };
      props.setCertifications(newArray);
    } else if (e.target.id === "expiryDate" && issueDate === "") {
      toast.error("Please select Issue Date before selecting Expiry Date");
    } else if (e.target.id === "expiryDate" && e.target.value === issueDate) {
      toast.error("Expiry Date and Issue Date Cannot be the Same");
    } else if (e.target.id === "expiryDate" && e.target.value < issueDate) {
      toast.error("Expiry Date Cannot Be Less Than Issue Date");
    } else {
      let newArray = [...props.certifications];
      newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
      props.setCertifications(newArray);
    }
    // const newArray = [...props.certifications]
    // newArray[i] = { ...newArray[i], [e.target.id]: e.target.value }
    // props.setCertifications(newArray);
  };

  // Delete Certification
  const handleDelete = (e, i) => {
    props.setCertifications(
      props.certifications.filter((item, index) => index !== i)
    );
    props.setCertificationValidation(
      props.certificationValidation.filter((item, index) => index !== i)
    );
  };

  // Add Certification
  const handleAdd = () => {
    props.setCertifications([...props.certifications, inputDataObj]);
    props.setCertificationValidation([
      ...props.certificationValidation,
      inputValObj,
    ]);
  };

  return (
    <div>
      <div class="certfication">
        {props?.certifications?.map((certification, i) => (
          <div class="d-flex" v-for="item in list">
            <div class="mr-3">
              <div class="avatar avatar-sm">{i + 1}</div>
            </div>
            <div class="flex-grow-1">
              <div class="row mb-2">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Certification Name"
                      id="certificationName"
                      style={{
                        border:
                          props.certificationValidation[i]?.certificationName &&
                          "2px solid #ff0000",
                      }}
                      value={certification.certificationName}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Certification Name</label>
                  </div>
                  <small className="validation">
                    {props.certificationValidation[i]?.certificationName}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Certificate No."
                      id="certificate"
                      style={{
                        border:
                          props.certificationValidation[i]?.certificate &&
                          "2px solid #ff0000",
                      }}
                      value={certification.certificate}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Certificate No.</label>
                  </div>
                  {/* <small className="validation">{props.certificationValidation[i]?.certificate}</small> */}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-floating form-date">
                    <label>Issue Date</label>
                    <input
                      type="date"
                      max="2050-12-31"
                      class="form-control"
                      placeholder="Issue Date"
                      id="issueDate"
                      style={{
                        border:
                          props.certificationValidation[i]?.issueDate &&
                          "2px solid #ff0000",
                      }}
                      value={certification.issueDate}
                      onChange={(e) =>
                        handleChange(e, i, certification.issueDate)
                      }
                    />
                  </div>
                  <small className="validation">
                    {props.certificationValidation[i]?.issueDate}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating form-date">
                    <label>Expiry Date</label>
                    <input
                      max="2050-12-31"
                      type="date"
                      class="form-control"
                      placeholder="Expiry Date"
                      id="expiryDate"
                      style={{
                        border:
                          props.certificationValidation[i]?.expiryDate &&
                          "2px solid #ff0000",
                      }}
                      value={certification.expiryDate}
                      onChange={(e) =>
                        handleChange(e, i, certification.issueDate)
                      }
                    />
                  </div>
                  <small className="validation">
                    {props.certificationValidation[i]?.expiryDate}
                  </small>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div class="ml-3">
              {props.certifications.length !== 1 && (
                <button
                  type="button"
                  class="btn btn-icon"
                  onClick={(e) => handleDelete(e, i)}
                >
                  <i class="fal fa-fw fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>
        ))}

        <div>
          <button type="button" onClick={handleAdd} class="btn btn-sm btn-text">
            <i class="fal fa-plus mr-1" aria-hidden="true" />
            <strong>Add Certification</strong>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Certifications;
