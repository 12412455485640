import {
  FETCH_CURATOR_CLIENT_DATA_BEGIN,
  FETCH_CURATOR_CLIENT_DATA_SUCCESS,
  FETCH_CURATOR_CLIENT_DATA_FAILURE,
  GET_CURATOR_DATA_COUNT_BEGIN,
  GET_CURATOR_DATA_COUNT_SUCCESS,
  GET_CURATOR_DATA_COUNT_FAILURE,
} from "../../action/curationtasks/GetCuratorClients.js";

const initialState = {
  loading: false,
  curatorClientsData: null,
  curatorClientsDataCount: null,
  curatorCountLoading: false,
};

const getCuratorClientDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CURATOR_CLIENT_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        curatorClientsData: null,
      };
    case FETCH_CURATOR_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        curatorClientsData: payload,
        loading: false,
      };
    case FETCH_CURATOR_CLIENT_DATA_FAILURE:
      return {
        ...state,
        curatorClientsData: payload,
        loading: false,
      };

    case GET_CURATOR_DATA_COUNT_BEGIN:
      return {
        ...state,
        curatorCountLoading: true,
        curatorClientsDataCount: null,
      };
    case GET_CURATOR_DATA_COUNT_SUCCESS:
      return {
        ...state,
        curatorClientsDataCount: payload,
        curatorCountLoading: false,
      };
    case GET_CURATOR_DATA_COUNT_FAILURE:
      return {
        ...state,
        curatorClientsDataCount: payload,
        curatorCountLoading: false,
      };
    default:
      return state;
  }
};

export default getCuratorClientDataReducer;
