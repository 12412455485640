import React, { useState } from "react";
import { Row, Col} from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import { MdBusinessCenter } from "react-icons/md";
import { FaGraduationCap } from "react-icons/fa";

const CommunityDetails = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const description = data[0]?.description || ""; // Handle potential undefined data

  const truncatedDescription =
    description.length > 300 && !isExpanded
      ? description.slice(0, 300) + "..."
      : description;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Row>
        <Col
          md={12}
          sm={12}
          className="mt-4 d-flex justify-content-between mb-2"
        >
          <div>
            <span className="mr-2">Community :</span>
            <span style={{ color: "#FF5F57", fontSize: "18px" }}>
              <b>{data[0]?.poolname}</b>
            </span>
          </div>
          <div>
            <span className="mr-2">Community ID :</span>
            <span>
              <b style={{ color: "#FF5F7" }}>{data[0]?.poolId}</b>
            </span>
          </div>
        </Col>
        <hr />
        <Col md={12} sm={12} lg={12}>
          <span className="text-secondary">
            <b>General</b>
          </span>
        </Col>

        <Col md={4} sm={12} lg={4} className="mt-3 mb-3">
          <span style={{ fontSize: "13px" }}>
            <MdLocationOn size={18} /> {data[0]?.city + ", " + data[0]?.country}
          </span>
          <br />
          <span className="ml-3">
            <small>Location</small>
          </span>
        </Col>
        <Col md={3} sm={12} lg={3} className="mt-3 mb-3">
          <span style={{ fontSize: "13px" }}>
            <MdBusinessCenter size={18} /> {data[0]?.experienceLevel}
          </span>
          <br />
          <span className="ml-3">
            <small>Experience</small>
          </span>
        </Col>
        <Col md={3} sm={12} lg={3} className="mt-3 mb-3">
          <span style={{ fontSize: "13px" }}>
            <FaGraduationCap size={18} /> {data[0]?.highestEducation}
          </span>
          <br />
          <span className="ml-3">
            <small>Education</small>
          </span>
        </Col>
        {/* <Col md={4} sm={12} lg={4} className="mt-3 mb-3">
          <span  style={{ fontSize: "13px" }}>
            <FaPlaneDeparture size={18} className="tag tag-blue3 m-1 mt-2"/> {JSON.parse(data[0]?.visaStatus)}
          </span>

          <br />
          <span className="ml-3">
            <small>Work Authorization Requirement</small>
          </span>
        </Col> */}
        <Col md={12} sm={12} lg={12} className="mt-3 mb-3">
          <span className="text-secondary">
            <b>Work Authorization Requirement</b>
          </span>
          <br />
          {JSON.parse(data[0]?.visaStatus) &&
            JSON.parse(data[0]?.visaStatus).map((visa, index) => (
              <span key={index} className="tag tag-blue3 m-1 mt-2">
                {visa}
              </span>
            ))}
        </Col>

        <Col md={12} sm={12} lg={12} className="mt-3 mb-3">
          <span className="text-secondary">
            <b>Required Skills</b>
          </span>
          <br />

          {JSON.parse(data[0]?.primarySkills)
            .flat()
            .map((item2) => (
              <span className="tag tag-blue3 m-1 mt-2">{item2}</span>
            ))}
          <br />
        </Col>
        <Col md={12} sm={12} lg={12} className="mt-3 mb-3">
          <span className="text-secondary">
            <b>Description</b>
          </span>
          <br />

          <p
            style={{ fontSize: "13px" }}
            className="mt-2"
            dangerouslySetInnerHTML={{
              __html: truncatedDescription,
            }}
          ></p>

          <div className="text-left">
            <button
              type="button"
              className="btn btn-text"
              onClick={toggleExpansion}
            >
              {!isExpanded && description.length > 300 ? (
                <span>Show More</span>
              ) : (
                <span>Show Less</span>
              )}
            </button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CommunityDetails;
