import React from "react";
import { getCandidateName } from "../../../service/AuthService";

const NameAndOwner = ({ setGroupData, groupData, list, handleChange }) => {
  const name = getCandidateName();
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="groupname"
                  placeholder="Enter a community name"
                  onChange={(e) =>
                    setGroupData({ ...groupData, groupname: e.target.value })
                  }
                  value={groupData?.groupname}
                />
                <label>Community Name*</label>
              </div>
              {groupData?.poolName && (
                <small className="validation ml-2">
                  {groupData?.createdBy}
                </small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="ownername"
                  disabled
                  placeholder="Enter a community name"
                  value={name}
                />
                <label>Owner Name</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NameAndOwner;
