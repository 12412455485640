import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";

import {
  getCandidateId,
  getTenantType,
  isCurator,
  getUserRole,
} from "../../../../service/AuthService";
import ProfileImage from "../../../profileimage/ProfileImage";
import {
  FetchSmsByUserToCandidate,
  UpdateMessageReadStatus,
} from "../../../../action/communication/Communication";
import Pagination from "../../../common/pagination/Pagination";

const Messages = ({ candidateData }) => {
  const dispatch = useDispatch();
  const [smsList, setSMSList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    sendSMSCandidateLoading,
    sendSMSCandidateList,
    sendSMSCandidateListCount,
  } = useSelector(
    (state) => ({
      sendSMSCandidateLoading:
        state.communicationReducer.sendSMSCandidateLoading,
      sendSMSCandidateList:
        state.communicationReducer.sendSMSCandidateList?.result,
      sendSMSCandidateListCount:
        state.communicationReducer.sendSMSCandidateList?.total,
    }),
    shallowEqual
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [candidateData, dispatch]);

  const fetchData = (currentPage) => {
    const reqPayload = {
      userId: getCandidateId(),
      candidateNumber: candidateData?.homePhoneCode + candidateData?.homePhone,
      poolId: candidateData?.fk_talentPool ?? null,
      jobId: candidateData?.jobId ?? null,
      currentPage: currentPage,
    };
    dispatch(FetchSmsByUserToCandidate(reqPayload));
    const payload = {
      userId: candidateData?.userId,
    };
    dispatch(UpdateMessageReadStatus(payload));
  };

  useEffect(() => {
    if (getTenantType() === 1 && isCurator() && getUserRole() === "Recruiter") {
      setSMSList(
        sendSMSCandidateList?.filter(
          (item) => item?.senderRole === "Client Curator"
        )
      );
    } else {
      setSMSList(sendSMSCandidateList);
    }
  }, [sendSMSCandidateList, sendSMSCandidateListCount]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  const getSenderColor = (name) => {
    const colors = [
      "#0078D4",
      "#FF4500",
      "#008000",
      "#0000FF",
      "#FF1493",
      "#FFD700",
      "#6A5ACD",
    ];
    const index = name.charCodeAt(0) % colors.length; // Dynamic color based on name's first letter
    return colors[index];
  };

  return (
    <div className="w-100">
      {sendSMSCandidateLoading && (
        <div className="loading-text-container">
          <div className="loading-text">
            <i>Loading...</i>
          </div>
        </div>
      )}
      {!sendSMSCandidateLoading && smsList?.length === 0 && (
        <div className="text-center p-5">
          <div className="avatar avatar-lg">
            <i aria-hidden="true" className="fad fa-users-slash"></i>
          </div>
          <div className="mt-2">No records found</div>
        </div>
      )}
      <div className="mb-5 w-100 p-2">
        <div className="row mb-5">
          {smsList &&
            smsList?.map((message, index) => (
              <div
                key={index}
                className="d-flex col-md-12 message-container mb-4"
              >
                <div className="mr-2">
                  <ProfileImage
                    imageSrc={candidateData?.profileImagePath || ""}
                    imageWidth={"25"}
                    imageHeight={"25"}
                    avatarSize={"30"}
                    avatarRound={"90px"}
                    firstName={
                      message.senderName?.split(" ")[0] ||
                      candidateData?.firstName
                    }
                    lastName={
                      message.senderName?.split(" ")[1] ||
                      candidateData?.lastName
                    }
                  />
                </div>

                <div className="row ml-3">
                  {/* Message content */}
                  <div
                    className={`message-content ${
                      message?.direction === "outbound" ? "sent" : "received"
                    }`}
                    style={{
                      ...(message?.direction === "outbound"
                        ? sentMessageStyle
                        : receivedMessageStyle),
                    }}
                  >
                    {/* Sender Name and Role */}
                    <div
                      className="message-header mb-3"
                      style={{
                        color: getSenderColor(message.senderName || "default"),
                      }}
                    >
                      {message?.direction === "outbound" &&
                      message?.senderRole &&
                      message?.senderName
                        ? `${message.senderName} (${message.senderRole})`
                        : `${candidateData?.firstName} ${candidateData?.lastName}`}
                    </div>

                    {/* Message Body */}
                    <p className="message-body">{message?.messageBody}</p>

                    {/* Job Title and Community */}
                    {message?.jobTitle && (
                      <div>
                        <small>
                          <strong>Job Title:</strong> {message.jobTitle}
                        </small>
                      </div>
                    )}
                    {message?.poolname && (
                      <div>
                        <small>
                          <strong>Community:</strong> {message.poolname}
                        </small>
                      </div>
                    )}
                  </div>

                  {/* Timestamp */}
                  <div className="row message-timestamp mt-n2">
                    {message?.updatedDateTime && (
                      <div className="col text-left">
                        <small>
                          {moment(message.updatedDateTime)
                            .tz("Asia/Kolkata")
                            .format("MM/DD/YYYY hh:mm A")}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {smsList && smsList?.length > 0 && (
          <div className="mb-4">
            <hr className="m-0" />
            <Pagination
              currentPage={currentPage}
              totalItems={sendSMSCandidateListCount}
              itemsPerPage={5}
              onPageChange={handlePageChange}
              limit={5}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Inline CSS objects
const receivedMessageStyle = {
  backgroundColor: "#f3f2f1", // light gray for received messages
  borderRadius: "16px 16px 16px 4px", // rounded corners
  padding: "10px 15px", // internal padding
  marginBottom: "10px", // spacing between messages
  maxWidth: "65%", // controls the message bubble width
  alignSelf: "flex-start", // align to the left
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // subtle shadow
};

const sentMessageStyle = {
  backgroundColor: "#f8f9f9",
  borderRadius: "4px 16px 16px  16px", // different corner rounding
  //boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // subtle shadow for depth
  padding: "10px",
  marginBottom: "10px",
  maxWidth: "75%",
  alignSelf: "flex-end",
  textAlign: "left",
};

export default Messages;
