import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import SuccessModal from "../../../common/successmodal/SuccessModal";

const ConfirmModal = ({
  showConfirmModal,
  setShowConfirmModal,
  handleInvite,
  selectedTalents,
  selectedJobs,
  setShowJobModal,
  setSelectedTalents,
  successModalForm,
  setSuccessModalForm,
  isFrom,
}) => {
  const [viewAllMandatory, setViewAllMandatory] = useState(false);
  const [successData, setSuccessData] = useState({
    title: `Moved ${selectedTalents.length > 1 ? "Talents" : "Talent"} to ${
      isFrom === "moveToJob" ? "Job" : "Community"
    }`,
    subTitle: `${selectedTalents.length > 1 ? "Talents" : "Talent"} moved to ${
      isFrom === "moveToJob" ? "Job" : "Community"
    } successfully`,
    htmlTag: `<div class="card-body">
  <div class="mb-2 mt-1 row">
    <div class="col-6 ">
      <span class="">
        <strong>Job Title</strong>
      </span>
    </div>
    <div class="col-6">
      : <span class="tag tag-blue2">${selectedJobs[0]?.jobTitle}</span>
    </div>
  </div>
  <div class="mb-2 mt-1 row">
    <div class="col-6 ">
      <label class="font-bold pt-0">Total Talents Moved</label>
    </div>
    <div class="col-6">
      : <span class="tag tag-orange">${selectedTalents?.length}</span>
    </div>
  </div>
  
</div>
`,
  });

  const closeModal = () => {
    setSuccessModalForm(false);
    setShowJobModal(false);
    setSelectedTalents([]);
  };

  return (
    <>
      {successModalForm ? (
        <SuccessModal
          showSuccessModal={successModalForm}
          setShowSuccessModal={setSuccessModalForm}
          closeModal={closeModal}
          successData={successData}
        />
      ) : (
        <Modal show={showConfirmModal} size="lg">
          <div className="modal-header" id="NewCandidateModal">
            <h6>Confirm Talent Move</h6>
            {showConfirmModal && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowConfirmModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <Modal.Body>
            <div className="row d-block-force  p-4">
              <span>
                You are about to move{" "}
                <strong>
                  {selectedTalents?.length}{" "}
                  {selectedTalents?.length > 1 ? "talents" : "talent"}
                </strong>{" "}
                from the Talent Community to the Job{" "}
                <strong title="Job Title">{selectedJobs[0]?.jobTitle}</strong>{" "}
                Shortlisted tab.
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel{" "}
            </button>
            <button onClick={handleInvite}>Confirm</button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ConfirmModal;
