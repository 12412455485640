import React from "react";

const SearchBar = ({
  showSearch,
  isSearchExpanded,
  setIsSearchExpanded,
  searchInput,
  handleSearchType,
}) => {
  const searchIconStyle = {
    borderRadius: "50%",
  };

  const roundedInputStyle = {
    borderRadius: "15px",
    transition: "width 0.5s",
  };

  const expandSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const handleInputFocus = () => {
    setIsSearchExpanded(true);
  };

  return (
    showSearch && (
      <div className="d-flex w-100 d-block-768 justify-content-end align-items-center gap-3 align-self-center">
        <div
          className="d-flex justify-content-end align-items-center gap-2"
          style={{ maxWidth: "500px", minWidth: "250px" }}
        >
          <div>
            <span
              className="rounded-circle"
              style={searchIconStyle}
              onClick={expandSearch}
            >
              <i className="fa fa-search "></i>
            </span>
          </div>
          <input
            type="text"
            className={`form-control ${isSearchExpanded ? "w-100" : "w-50"}`}
            style={{ ...roundedInputStyle }}
            placeholder="Search"
            onFocus={handleInputFocus}
            value={searchInput}
            onChange={handleSearchType}
          />
        </div>
      </div>
    )
  );
};

export default SearchBar;
