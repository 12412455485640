const Footer = () => {
  return (
    <footer
      id="Footer"
      className="footer-container clearfix nitro-offscreen"
      style={{ backgroundColor: "#001730", color: "white" }}
    >
      <div className="widgets_wrapper">
        <div className="column-container">
          <div className="column one-third col-4 mt-4">
            <aside id="media_image-2" className="widget widget_media_image">
              <a target="_blank" href="https://www.high5hire.com/">
                <img
                  width="66"
                  height="30"
                  alt="high 5 footer logo"
                  style={{ maxWidth: "100%", height: "auto" }}
                  nitro-lazy-src="https://cdn-dkckk.nitrocdn.com/wBvmMpIdxzUDdgmlZlWlVUqEjKKjivtU/assets/images/optimized/rev-dd9a30c/wp-content/uploads/2021/09/high5-footer-logo.svg"
                  className="image wp-image-5604 attachment-full size-full ls-is-cached lazyloaded"
                  decoding="async"
                  nitro-lazy-empty=""
                  id="MTQzMDo0NDQ=-1"
                  src="https://cdn-dkckk.nitrocdn.com/wBvmMpIdxzUDdgmlZlWlVUqEjKKjivtU/assets/images/optimized/rev-dd9a30c/wp-content/uploads/2021/09/high5-footer-logo.svg"
                />
              </a>
            </aside>
          </div>
          <div className="column one-sixth col-2 mt-4">
            <aside id="nav_menu-5" className="widget widget_nav_menu">
              <h4>Products</h4>
              <div className="menu-products-menu-container">
                <ul id="menu-products-menu" className="menu">
                  <li
                    id="menu-item-237"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-237"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/recruiter-marketplace/"
                      style={{ color: "white" }}
                    >
                      High5 Marketplace
                    </a>
                  </li>
                  <li
                    id="menu-item-5729"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5729"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/trusted-talent/"
                      style={{ color: "white" }}
                    >
                      Trusted Talent
                    </a>
                  </li>
                  <li
                    id="menu-item-5730"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5730"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/talent-community/"
                      style={{ color: "white" }}
                    >
                      Talent Community
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="column one-sixth col-2 mt-4">
            <aside id="nav_menu-2" className="widget widget_nav_menu">
              <h4>Customers</h4>
              <div className="menu-customers-menu-container">
                <ul id="menu-customers-menu" className="menu">
                  <li
                    id="menu-item-5685"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5685"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/employers/"
                      style={{ color: "white" }}
                    >
                      For Employers
                    </a>
                  </li>
                  <li
                    id="menu-item-5684"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5684"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/recruiters/"
                      style={{ color: "white" }}
                    >
                      For Recruiters
                    </a>
                  </li>
                  <li
                    id="menu-item-5727"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5727"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/total-talent-solutions/professionals/"
                      style={{ color: "white" }}
                    >
                      For Professionals
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="column one-sixth col-2 mt-4">
            <aside id="nav_menu-3" className="widget widget_nav_menu">
              <h4>
                <span>Hire Talent</span>
              </h4>
              <div className="menu-hire-talent-container">
                <ul id="menu-hire-talent" className="menu">
                  <li
                    id="menu-item-5962"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5962"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/hire-developers/"
                      style={{ color: "white" }}
                    >
                      Hire Developers
                    </a>
                  </li>
                  <li
                    id="menu-item-5960"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5960"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/hire-workforce-professionals/"
                      style={{ color: "white" }}
                    >
                      Hire Workforce Management Professionals
                    </a>
                  </li>
                  <li
                    id="menu-item-6015"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6015"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/hire-recruiters/"
                      style={{ color: "white" }}
                    >
                      Hire Recruiters
                    </a>
                  </li>
                  <li
                    id="menu-item-6485"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6485"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/hire-medical-professionals/"
                      style={{ color: "white" }}
                    >
                      Hire Medical Professionals
                    </a>
                  </li>
                  <li
                    id="menu-item-6525"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6525"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/hire-allied-health-professionals/"
                      style={{ color: "white" }}
                    >
                      Hire Allied Health Professionals
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="column one-sixth col-2 mt-4">
            <aside id="nav_menu-4" className="widget widget_nav_menu">
              <h4>More</h4>
              <div className="menu-about-us-menu-container">
                <ul id="menu-about-us-menu" className="menu">
                  <li
                    id="menu-item-5963"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5963"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/how-high5-compares/"
                      style={{ color: "white" }}
                    >
                      <span>Why High5</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-5725"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5497 current_page_item menu-item-5725"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/about-us/"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      <span>About Us</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-3558"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3558"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/resources/"
                      style={{ color: "white" }}
                    >
                      <span>Resources</span>
                    </a>
                  </li>
                  <li
                    id="menu-item-5755"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5755"
                  >
                    <a
                      target="_blank"
                      href="https://www.high5hire.com/contact-us/"
                      style={{ color: "white" }}
                    >
                      <span>Contact Us</span>
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer_copy text-center">
        <div className="container">
          <div className="column one">
            <div className="copyright">
              Copyright © 2022 High5. All Rights Reserved.
            </div>
            <ul className="social"></ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
