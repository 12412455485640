import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Card from "../superadmin/Card";
import {
  getTenantType,
  getUserTenant,
  isCurator,
} from "../../../service/AuthService";
import { getRecruiterDashboardData } from "../../../action/dashboard/superadmin/recruiter/RecruiterDashboard";
import Index from "./talentcommunity/Index";
import CurationTask from "../../curationtasks/CurationTask";
import TalentList from "../../talentpool/talentlist/Index";

const DashboardCards = () => {
  const dispatch = useDispatch();
  const [dashboardCount, setDashboardCount] = useState({
    jobCount: 0,
    communityCount: 0,
    talentCount: 0,
    privateTalentCount: 0,
    externalTalentCount: 0,
    clientCount: 0,
    clientCommunity: 0,
  });
  const [view, setView] = useState({
    jobs: false,
    talents: false,
    externalTalent: false,
    privateTalent: false,
    publicTalentCommunity: false,
    subCommunity: false,
    curationTasks: false,
    talentCommunities: false,
  });

  const cardStyle = {
    minWidth: "15rem",
    maxWidth: "20rem",
    marginRight: "10px",
    flex: "1 1 0", // Ensure equal width distribution
  };

  const { recruiterCount } = useSelector(
    (state) => ({
      recruiterCount: state.getrecruiterDashboardReducer.recruiterCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (getTenantType() === 1) {
      setView({
        jobs: false,
        talents: false,
        externalTalent: false,
        privateTalent: true,
        publicTalentCommunity: false,
        subCommunity: false,
        curationTasks: false,
        talentCommunities: false,
      });
    } else {
      setView({
        jobs: false,
        talents: true,
        externalTalent: false,
        privateTalent: false,
        publicTalentCommunity: false,
        subCommunity: false,
        curationTasks: false,
        talentCommunities: false,
      });
    }
    dispatch(getRecruiterDashboardData());
  }, []);

  useEffect(() => {
    if (recruiterCount && recruiterCount.length > 0) {
      const newDashboardCount = {
        ...dashboardCount,
        jobCount:
          recruiterCount.find((item) => item.type === "jobCount")?.count || 0,
        talentCount:
          recruiterCount.find((item) => item.type === "talentCount")?.count ||
          0,
        privateTalentCount:
          recruiterCount.find((item) => item.type === "privateTalentCount")
            ?.count || 0,
        externalTalentCount:
          recruiterCount.find((item) => item.type === "externalTalentCount")
            ?.count || 0,
        clientCount:
          getTenantType() === 2
            ? recruiterCount.find((item) => item.type === "clientCount")
                ?.count || 0
            : 0,
        clientCommunity:
          getTenantType() === 1
            ? recruiterCount.find((item) => item.type === "clientCommunity")
                ?.count || 0
            : 0,
      };

      if (getTenantType() === 2) {
        newDashboardCount.communityCount =
          recruiterCount.find((item) => item.type === "communityCount")
            ?.count || 0;
      }
      setDashboardCount(newDashboardCount);
    }
  }, [recruiterCount]);

  return (
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div className="d-flex gap-2 overflow" style={{ width: "100%" }}>
          <div className="d-flex justify-content-around  px-2 d-block-768  mb-2 mb-xl-0 w-100">
            {getTenantType() === 1 ? (
              <>
                <Card
                  view={view}
                  cardStyle={cardStyle}
                  setView={setView}
                  elem={{
                    heading: `${getUserTenant()} Talent Profiles`,
                    title: "privateTalent",
                    count: dashboardCount.privateTalentCount,
                    tooltip: `Total number of talent registered in ${getUserTenant().replace(
                      "GIG - ",
                      ""
                    )}. This does not include the talent invited to jobs or those who have received invitations but not register on the platform.`,
                  }}
                  isFrom={"recDashboard"}
                />
                <Card
                  view={view}
                  cardStyle={cardStyle}
                  setView={setView}
                  elem={{
                    heading: "High5 Talent Profiles",
                    title: "externalTalent",
                    count: dashboardCount.externalTalentCount,
                    tooltip: `Total number of talent registered in High5. This does not include the talent invited to jobs or those who have received invitations but not register on the platform.`,
                  }}
                  isFrom={"recDashboard"}
                />
              </>
            ) : (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Talent Pools",
                  title: "Talent",
                  count: dashboardCount.talentCount,
                  tooltip: `Total number of talent registered in ${getUserTenant().replace(
                    "GIG - ",
                    ""
                  )}. This does not include the talent invited to jobs or those who have received invitations but not register on the platform.`,
                }}
                isFrom={"recDashboard"}
              />
            )}
            {getTenantType() === 2 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Branded Talent Communities",
                  title: "Branded Talent Communities",
                  count: dashboardCount.communityCount,
                  tooltip:
                    "Total number of branded talent communities created by other companies.",
                }}
                isFrom={"recDashboard"}
              />
            )}
            {isCurator() && getTenantType() == 2 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Curation Tasks",
                  title: "Curation Tasks",
                  count: dashboardCount.clientCount,
                  tooltip: `Total number of clients`,
                }}
                isFrom={"recDashboard"}
              />
            )}
            {getTenantType() === 1 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Talent Communities",
                  title: "Talent Communities",
                  count: dashboardCount.clientCommunity,
                  tooltip: `Total number of talent community`,
                }}
                isFrom={"recDashboard"}
              />
            )}
            <Card
              view={view}
              cardStyle={cardStyle}
              setView={setView}
              elem={{
                heading: "Jobs",
                title: "Jobs",
                count: dashboardCount.jobCount,
                tooltip: "Total number of jobs registered.",
              }}
              isFrom={"recDashboard"}
            />{" "}
          </div>
        </div>
        <hr />
        <div>
          {/* {view.jobs && <JobList />} */}

          {view.publicTalentCommunity && <Index />}

          {(view.talents || view.privateTalent || view.externalTalent) && (
            <TalentList
              tab={
                view.privateTalent
                  ? "Private Talent Pool"
                  : view.externalTalent
                  ? "External Talent Pool"
                  : null
              }
            />
          )}
          {view.curationTasks && <CurationTask />}
          {/* {view?.talentCommunities && <Index />} */}
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
