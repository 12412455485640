import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
// import Sort from "../../commoncomponents/sort/Index";
// import { orderList } from "../../pagedata/PageData";
import Pagination from "../../common/pagination/Pagination";
import ProfileImage from "../../common/profileimage/ProfileImage";
import RecSidePanel from "../../recruiter/sidepanel/Index";
import NoRecord from "../../common/NoRecord";
import Loader from "../../common/loader/Loader";
import { post } from "../../../service/ApiService";
import { getUserRole } from "../../../service/AuthService";

const UpdateCurator = ({
  updateCuratorData,
  updateCuratorCount,
  handleSizeChange,
  dataSize,
  fetchDataUpdateCurator,
  searchData,
  handleSearch,
  applySort,
  sortArray,
  setSortArray,
  getTenantData,
  tenant,
  clientData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [tenantObj, settenantObj] = useState({});
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);
  const [removeCurator, setRemoveCurator] = useState(false);
  const [selectedCurator, setSelectedCurator] = useState({});
  const [loading, setLoading] = useState(false);
  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Location",
      value: "country",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Email",
      value: "email",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    const tempArray = sortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [dataSize]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchDataUpdateCurator(pageNumber, dataSize, searchData, sortArray);
  };
  const openTenantPopUPFun = (item) => {
    setopenTenantPopUP(true);
    settenantObj(item);
  };

  const handleRemoveCurator = (item) => {
    setRemoveCurator(true);
    setSelectedCurator(item);
  };

  const confirmRemove = async () => {
    if (!tenant) {
      toast.error("Please select the client");
      return;
    } else if (!clientData) {
      toast.error("Please select the community");
      return;
    }
    let payload = {
      poolDetails: {
        recruiterId: selectedCurator?.id_userAccessdetail,
        poolId: clientData ? clientData.value : null,
      },
    };
    setLoading(true);
    await post("/curator/removecurator", payload).then((resp) => {
      setLoading(false);
      fetchDataUpdateCurator(1, 10, "", []);
      setRemoveCurator(false);
      if (getUserRole() == "CustomerSuccess") {
        getTenantData();
      }
    });
  };
  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = sortObject?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    fetchDataUpdateCurator(1, dataSize, searchData, []);
    setSort(false);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="bl-modal-body">
        <div className="d-flex justify-content-between mb-3">
          <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
            <select
              class="form-select mb-2"
              value={dataSize}
              onChange={handleSizeChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="d-flex justify-content-end col-6">
            <div>
              {/* <Sort
                sort={sort}
                setSort={setSort}
                sortCount={sortCount}
                sortData={updateCuratorData}
                orderArray={orderList}
                sortObjects={sortObject}
                applySort={applySort}
                setSortCount={setSortCount}
                setSortArray={setSortArray}
                sortArray={sortArray}
                sortingFor={"Sort by Name, Location, and Email"}
                sortType={sortType}
                setSorttype={setSorttype}
                sortApplied={sortApplied}
                setSortApplied={setSortApplied}
                sortClearAll={sortClearAll}
              /> */}
            </div>
            <div className="search-input-container w-60">
              <input
                type="text"
                value={searchData}
                className="form-control"
                placeholder="Search Recruiter by Name and Email"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Recruiter Name</th>
              <th scope="col">Email</th>
              {/* <th scope="col">Last logged in</th>
              <th scope="col">Created on</th>
              <th scope="col">Status</th> */}
              <th scope="col" style={{ width: 150 }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {updateCuratorData?.length > 0 ? (
              updateCuratorData?.map((item, index) => (
                <tr key={index}>
                  <td style={{ cursor: "pointer" }}>
                    <div
                      className="avatar-name-candidatestableview d-flex align-items-center"
                      onClick={() => openTenantPopUPFun(item)}
                    >
                      <div>
                        <span>
                          <ProfileImage
                            imageSrc={item?.profileImagePath}
                            imageWidth={"35"}
                            imageHeight={"35"}
                            avatarSize={"35"}
                            avatarRound={"80px"}
                            firstName={item?.firstName}
                            lastName={item?.LastName}
                          />
                        </span>
                      </div>
                      <div className="ml-3">
                        <strong className="mb-0 me-2">
                          {item.firstName} {item.LastName}
                        </strong>
                      </div>
                    </div>
                  </td>
                  <td>{item.email}</td>
                  {/* <td>
                    {item.LastLogin ? (
                      <span>
                        {moment(new Date(item.LastLogin)).format("MM/DD/YYYY")}
                      </span>
                    ) : (
                      "Never"
                    )}
                  </td> */}
                  {/* <td>
                    <span>
                      {moment(new Date(item.createddatetime)).format(
                        "MM/DD/YYYY"
                      )}
                    </span>
                  </td> */}
                  {/* <td>
                    {item.status === "Deactivate" ? (
                      <span className="tag tag-red2">Deactivated</span>
                    ) : item.status === "Invited" ? (
                      <span className="tag tag-blue3">Invited</span>
                    ) : item.status === "Pending" ? (
                      <span className="tag tag-orange1">Pending</span>
                    ) : item.status === null ? (
                      <span className="tag tag-green4">Approved</span>
                    ) : item.status === "Approved" ? (
                      item.recruiterStatus === "Hyperactive" ? (
                        <span className="tag tag-green4">Hyperactive</span>
                      ) : item.recruiterStatus === "Active" ? (
                        <span className="tag tag-blue2">Active</span>
                      ) : item.recruiterStatus === "Inactive" ? (
                        <span className="tag tag-orange1">Inactive</span>
                      ) : item.recruiterStatus === "Never Logged In" ? (
                        <span className="tag tag-red2">Never Logged In</span>
                      ) : (
                        <span className="tag tag-green4">Approved</span>
                      )
                    ) : (
                      <span className="tag">{item.status}</span>
                    )}
                  </td> */}

                  <td>
                    <button
                      className="btn btn-danger btn-sm "
                      onClick={() => handleRemoveCurator(item)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <div className="d-flex justify-content-center align-items-center">
                    <NoRecord />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <hr class="m-0" />
        {updateCuratorData?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={updateCuratorCount[0]?.TotalCount ?? 0}
            itemsPerPage={dataSize}
            onPageChange={handlePageChange}
            limit={dataSize}
          />
        )}
      </div>

      {openTenantPopUP && (
        <RecSidePanel
          details={tenantObj}
          closePanel={() => setopenTenantPopUP(false)}
          isFrom={"HighAdmin"}
        />
      )}

      <Modal
        show={removeCurator}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Remove Curator"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setRemoveCurator(false);
            }}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Modal.Body>
          <div className="m-2">
            <p className="ml-2">
              Do you want to remove{" "}
              <strong>
                {selectedCurator?.firstName} {selectedCurator?.LastName}
              </strong>{" "}
              as assigned recruiter.{" "}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-sm"
            variant="secondary"
            onClick={() => {
              setRemoveCurator(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn-sm"
            variant="primary"
            onClick={() => confirmRemove()}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateCurator;
