import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { ButtonLoader, domain } from "../../../constants/Constants";
import { getUserId } from "../../../service/AuthService";

const Security = () => {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [showPassword, setShowpassword] = useState(false);
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);

  const [enableConfirm, setEnableConfirm] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);
  let [enableSubmit, setEnableSubmit] = useState(false);

  const showPasswordClick = () => {
    if (showPassword === false) {
      setShowpassword(true);
    } else {
      setShowpassword(false);
    }
  };

  const changePassword = () => {
    setReadOnlyMode(false);
  };

  const cancelChangePassword = () => {
    setReadOnlyMode(true);
    setSubmittingFrom(false);
    setSaveClicked(true);

    setPassword("");
    setConfirmPassword("");
    setPasswordValidation(false);
    setPasswordLength(0);
    setEnableSubmit(false);
    setEnableConfirm(false);
  };

  const saveChangePassword = async () => {
    if (submittingForm == false) {
      setSubmittingFrom(true);
      setSaveClicked(false);

      // call api to send data to server side
      const userId = getUserId();
      const data = {
        newPassword: ConfirmPassword,
        userId: userId,
        domain,
      };
      const res = await post(`/resetpassword/${userId}`, data);
      if (res.status === 200) {
        setTimeout(() => {
          setSubmittingFrom(false);
          setSaveClicked(true);
          setTimeout(() => {
            setReadOnlyMode(true);
          }, 1000);
          toast.success("Password has been successfully changed .");
        }, 1000);
      } else {
        toast.error("Error occured ");
      }
    }
  };

  const handlePassword = (e) => {
    const { id, value } = e.target;

    if (id === "confirmPassword") {
      setConfirmPassword(value);
    }

    if (id === "newPassword") {
      setPassword(value);

      const isLongEnough = value.length >= 8;
      setPasswordLength(isLongEnough);

      const specialCharacterPresent = /^(?=.*[!@#$%^&*])/;
      const numberPresent = /^(?=.*[0-9])/;
      const isValid =
        numberPresent.test(value) || specialCharacterPresent.test(value);
      setPasswordValidation(isValid);

      const enableConfirm = isLongEnough && isValid;
      setEnableConfirm(enableConfirm);
    }
  };

  useEffect(() => {
    if (password === "" || ConfirmPassword === "") {
      setEnableSubmit(false);
      setMatchPassword(false);
    } else {
      const isMatch = password === ConfirmPassword && password !== "";
      setEnableSubmit(isMatch);
      setMatchPassword(!isMatch);
    }
  }, [ConfirmPassword, password]);

  return (
    <>
      <div className={readOnlyMode && "read-only mb-2"}>
        <div className="card card-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">Security</h6>
              {readOnlyMode ? (
                <button
                  type="button"
                  v-if="form.security.readOnly"
                  className="btn btn-text"
                  onClick={changePassword}
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Change Password
                </button>
              ) : (
                <div>
                  <button
                    type="button"
                    onClick={cancelChangePassword}
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={saveChangePassword}
                    className="btn btn-sm btn-primary"
                    disabled={!enableSubmit}
                  >
                    {saveClicked && <span>Save Changes</span>}

                    {submittingForm && (
                      <span>
                        Saving Changes
                        <img width="20px" src={ButtonLoader} alt="" />
                      </span>
                    )}
                  </button>
                </div>
              )}
            </div>
            {readOnlyMode ? (
              <div v-if="form.security.readOnly">
                <div className="form-floating form-password mb-2 w-100 ">
                  <button
                    onClick={showPasswordClick}
                    type="button"
                    className="btn btn-sm btn-text btn-text-accent"
                  ></button>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-3">
                  <p className="mt-2">
                    <small>
                      <strong className="mr-2 mb-1 d-block">
                        Password Requirements
                      </strong>
                    </small>
                    <span
                      className={
                        passwordLength !== true
                          ? "tag mr-2"
                          : "tag mr-2 tag-green2"
                      }
                    >
                      Min 8 Characters{" "}
                      {passwordLength === false ? (
                        <i
                          v-if="!form.security.passwordLength"
                          className="ml-1 far fa-circle"
                        ></i>
                      ) : (
                        <i className="ml-1 fas fa-check-circle"></i>
                      )}
                    </span>
                    <span
                      className={
                        passwordValidation !== true
                          ? "tag mr-2"
                          : "tag mr-2 tag-green2"
                      }
                    >
                      1 Number Or Special Character{" "}
                      {passwordValidation === false ? (
                        <i
                          v-if="!form.security.passwordCondition"
                          className="ml-1 far fa-circle"
                        ></i>
                      ) : (
                        <i className="ml-1 fas fa-check-circle"></i>
                      )}
                    </span>
                  </p>
                </div>
                <div className="form-floating form-password mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="newPassword"
                    aria-describedby="newPasswordSetup"
                    placeholder="New Password"
                    onChange={handlePassword}
                    value={password}
                    autocomplete="new-password"
                  />
                  <label htmlFor="newPassword">New Password</label>

                  <button
                    onClick={showPasswordClick}
                    type="button"
                    className="btn btn-sm btn-text btn-text-accent"
                  >
                    <i
                      className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>

                <div className="form-floating mb-2">
                  <input
                    v-model="form.security.passwordConfirmation"
                    type={showPassword ? "text" : "password"}
                    disabled={!enableConfirm || submittingForm}
                    className="form-control"
                    id="confirmPassword"
                    aria-describedby="newPasswordConfirmation"
                    placeholder="Confirm Password"
                    onChange={handlePassword}
                    autocomplete="new-password"
                    value={ConfirmPassword}
                  />
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  {matchPassword && (
                    <small className="text-danger mb-2">
                      Passwords do not match
                    </small>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Security;
