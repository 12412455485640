import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_TALENT_EMAILS_BEGIN = "GET_TALENT_EMAILS_BEGIN";
export const GET_TALENT_EMAILS_SUCCESS = "GET_TALENT_EMAILS_SUCCESS";
export const GET_TALENT_EMAILS_FAILURE = "GET_TALENT_EMAILS_FAILURE";

export const getAllTalentEmails = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TALENT_EMAILS_BEGIN,
    });
    const res = await post("/talent/gettalentemails", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_TALENT_EMAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TALENT_EMAILS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
