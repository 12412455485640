import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaFileInvoice, FaCopy, FaFile } from "react-icons/fa";
import AddPool from "../addcommunity/Index";
import ExistingSubCommunity from "./ExistingCommunity";
// import CreateJobWithTemplate from "../../../job/addjob/addjobtype/CreateJobWithTemplate";
import JobDescriptionGenerator from "../../chatgpt/ChatGPT";

const CommunityOptionModal = ({
  setShowAddPool,
  onChange,
  groupId,
  setSuccessFormOpen,
  setSuccessData,
  clientId,
  type,
}) => {
  const [showAddNewPool, setShowAddNewPool] = useState(false);
  const [createExisting, setCreateExisting] = useState(false);
  const [createExistingSCForm, setCreateExistingSCForm] = useState(false);
  const [High5AI, setHigh5AI] = useState(false);
  const [createWithTemplateSCForm, setCreateWithTemplateSCForm] = useState(
    false
  );
  const [chatBotEnabled, setChatBotEnabled] = useState(true);
  const [poolData, setPoolData] = useState({});
  const [createWithTemplate, setCreateWithTemplate] = useState(false);
  const [poolId, setPoolId] = useState("");
  const iconObj = { width: "20px", color: "#f45e57" };

  const handleNewPoolSelect = () => {
    setShowAddNewPool(true);
    //setShowAddPool(false)
  };
  return (
    <>
      {!showAddNewPool &&
        !createExisting &&
        !createExistingSCForm &&
        !createWithTemplate &&
        !createWithTemplateSCForm &&
        !High5AI && (
          <div className="m-3">
            <h5 class="">
              Create Community
              {/* <i
                className="fa fa-info-circle ml-2"
                style={{ color: "#f45e57" }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Create Sub Community"
              ></i> */}
            </h5>
            <hr></hr>
            <div>
              <div
                className="card card-flat mb-3 mt-4 bg-gray4 card-hoverable"
                onClick={() => handleNewPoolSelect()}
              >
                <div className="card-body d-flex align-items-center">
                  {/* <div>
                    <FaFile style={iconObj} />
                  </div> */}
                  <div className="m-2 ml-3">
                    <h5>
                      <FaFile style={iconObj} /> New Community
                    </h5>
                    <p>Create new community from scratch</p>
                  </div>
                </div>
              </div>
              <div
                className="card card-flat mb-3  bg-gray4 card-hoverable"
                onClick={() => setCreateExisting(true)}
              >
                <div className="card-body d-flex align-items-center">
                  {/* <div>
                    <FaCopy style={iconObj} />
                  </div> */}
                  <div className="m-2 ml-3">
                    <h5>
                      <FaCopy style={iconObj} /> Copy / Clone an Existing Community
                    </h5>
                    <p>
                      Replicate an existing community and customize as
                      needed.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="card card-flat mb-3 bg-gray4 card-hoverable"
                onClick={() => {
                  setCreateWithTemplate(true);
                }}
              >
                <div className="card-body d-flex align-items-center">
                  {/* <div>
                    <FaFileInvoice style={iconObj} />
                  </div> */}
                  <div className="m-2 ml-3">
                    <h5>
                      <FaFileInvoice style={iconObj} />
                      Use Templates
                    </h5>
                    <p>Use High5Hire template and customize as needed. </p>
                  </div>
                </div>
              </div>
              {/* <div
                className="card card-flat mb-2 bg-gray4 card-hoverable"
                onClick={() => {
                  setHigh5AI(true);
                }}
              >
                <div className="card-body d-flex align-items-center">
                  <div>
                    <FaFileInvoice style={iconObj} />
                  </div>
                  <div className="m-2 ml-3">
                    <h6>Create New Sub Community From High5Hire AI</h6>
                    <p>Using High5Hire AI to create the sub community</p>
                  </div>
                </div>
              </div> */}
              {/* <button className="btn btn-sm" style={{backgroundColor: "#f45e57", border:"#f45e57"}} onClick={()=>setShowAddPool(false)}>
                Cancel
              </button> */}
            </div>
          </div>
        )}

      {showAddNewPool && (
        <AddPool
          setShowAddPool={setShowAddPool}
          groupId={groupId}
          isFrom="NewSubCommunity"
          setSuccessFormOpen={setSuccessFormOpen}
          setSuccessData={setSuccessData}
        />
      )}
      {createExisting && (
        <ExistingSubCommunity
          setPoolId={setPoolId}
          setCreateExisting={setCreateExisting}
          setCreateExistingSCForm={setCreateExistingSCForm}
          clientId={clientId}
          type={type}
        />
      )}
      {createExistingSCForm && (
        <AddPool
          setShowAddPool={setShowAddPool}
          isFrom="ExistingSubCommunity"
          poolId={poolId}
          groupId={groupId}
          setSuccessFormOpen={setSuccessFormOpen}
          setSuccessData={setSuccessData}
        />
      )}
      {/* {createWithTemplate && (
        <>
          <CreateJobWithTemplate
            isFrom="SubCommunity"
            setCreateWithTemplate={setCreateWithTemplate}
            setCreateWithTemplateSCForm={setCreateWithTemplateSCForm}
            setPoolData={setPoolData}
            //   setTemplateSelected={props.setTemplateSelected}
            //   setChooseTemplateDetails={setChooseTemplateDetails}
            //   templateSelected={props.templateSelected}
            //   createJobWithExisting={props.createJobWithExisting}
            // setChooseJobDetails={setChooseJobDetails}
            // jobListData={jobListData}
          />
        </>
      )} */}
      {createWithTemplateSCForm && (
        <AddPool
          setShowAddPool={setShowAddPool}
          isFrom="TemplateSubCommunity"
          poolId={poolId}
          poolData={poolData}
          groupId={groupId}
          setSuccessFormOpen={setSuccessFormOpen}
          setSuccessData={setSuccessData}
        />
      )}

      {High5AI && (
        <>
          <JobDescriptionGenerator
            // isModalOpen={isModalOpen}
            // setModalClose={setModalClose}
            isFrom="CreateSubCommunity"
            modalFor="subCommunityJD"
            // closeModal={onClose}
            // data={data}
          />
        </>
      )}
    </>
  );
};

export default CommunityOptionModal;
