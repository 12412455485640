import React from "react";
import moment from "moment";

const CommunicationPreference = ({
  editEmail,
  setEditEmail,
  handleSubmit,
  reason,
  setReason,
  preference,
  setPreference,
  setScheduleSubDate,
  scheduleSubDate,
  notificationType,
  setNotificationType,
  setSelectedNotificationType,
  setCommentText,
  commentText,
}) => {
  const curDate = new Date();
  const initialPref = {
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  };
  const initialReason = {
    noWish: false,
    notRelavent: false,
    neverSigned: false,
    other: false,
  };
  const onClickEmailEdit = () => {
    setEditEmail(true);
  };

  const handlePreference = (e) => {
    let p = { ...initialPref, [e.target.name]: true };
    setPreference(p);
  };

  const handleReason = (e) => {
    let p = { ...initialReason, [e.target.name]: true };
    setReason(p);
  };

  const handleNotificationType = (e) => {
    let temp = { ...notificationType };
    if (e.target.name === "daily") {
      temp["daily"] = true;
      temp["weekly"] = false;
    }
    if (e.target.name === "weekly") {
      temp["daily"] = false;
      temp["weekly"] = true;
    }
    setNotificationType(temp);
    setSelectedNotificationType(e.target.name);
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <label className="p-0 mt-2">Communication Preference</label>
        {editEmail === false ? (
          <button
            type="button"
            className="btn btn-text"
            style={{ fontSize: "12px" }}
            onClick={() => {
              onClickEmailEdit();
            }}
          >
            <i className="fas fa-pencil-alt mr-1"></i>
            Edit
          </button>
        ) : (
          <div>
            <button
              type="button"
              onClick={() => setEditEmail(false)}
              className="btn btn-sm btn-secondary mr-3"
              style={{ fontSize: "12px" }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleSubmit("emailPreference");
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className={`mt-2 ${editEmail ? "" : "disabled-container"}`}>
        <div className="">
          <div className="form-inline-edit">
            <div className="p-0 mt-3">
              <input
                type="checkbox"
                style={{ width: "15px" }}
                className="d-inline-block mr-1"
                name="subscribe"
                onClick={handlePreference}
                checked={preference.subscribe}
              />
              <a
                href="#"
                style={{ color: "black" }}
                className="font-regular"
                name="subscribe"
                onClick={handlePreference}
                for=""
              >
                Subscribe
              </a>
            </div>

            <div className="d-flex  align-items-center">
              <div>
                <input
                  type="checkbox"
                  style={{ width: "15px" }}
                  className="d-inline-block mr-1"
                  onClick={handlePreference}
                  name="scheduleSub"
                  checked={preference.scheduleSub}
                />
                <a
                  href="#"
                  style={{ color: "black" }}
                  className="font-regular"
                  onClick={handlePreference}
                  name="scheduleSub"
                  for=""
                >
                  Unsubscribe, then Resubscribe on
                </a>
              </div>
              {(preference?.scheduleSub || true) && (
                <div className=" px-2">
                  <input
                    type="date"
                    disabled={!preference?.scheduleSub}
                    value={scheduleSubDate}
                    onChange={(e) => {
                      setScheduleSubDate(e.target.value);
                    }}
                    min={moment(
                      new Date(curDate.setDate(curDate.getDate() + 1))
                    ).format("YYYY-MM-DD")}
                    className=""
                  />
                </div>
              )}
            </div>

            <div className="p-0 mt-2">
              <input
                type="checkbox"
                style={{ width: "15px" }}
                className="d-inline-block mr-1"
                name="unsubscribe"
                onClick={handlePreference}
                checked={preference.unsubscribe}
              />
              <a
                href="#"
                style={{ color: "black" }}
                className="font-regular"
                name="unsubscribe"
                onClick={handlePreference}
                for=""
              >
                Unsubscribe
              </a>
            </div>

            {preference.subscribe && (
              <div className="d-flex flex-column justify-content-center  gap-2 mt-4">
                <p>Select a notification type</p>{" "}
                <div className="">
                  <input
                    type="radio"
                    name="daily"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleNotificationType}
                    checked={notificationType.daily}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="daily"
                    onClick={handleNotificationType}
                  >
                    Daily
                  </a>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="weekly"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleNotificationType}
                    checked={notificationType.weekly}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="weekly"
                    onClick={handleNotificationType}
                  >
                    Weekly
                  </a>
                </div>
              </div>
            )}

            {(preference.unsubscribe || preference.scheduleSub) && (
              <div className="d-flex flex-column justify-content-center  gap-2 mt-4">
                <p>
                  If you have a moment, please let us know why you are
                  unsubscribing.
                </p>{" "}
                <div className="">
                  <input
                    type="radio"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleReason}
                    id=""
                    name="noWish"
                    checked={reason.noWish}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="noWish"
                    onClick={handleReason}
                    for=""
                  >
                    I no longer wish to receive these emails
                  </a>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="notRelavent"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleReason}
                    checked={reason.notRelavent}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="notRelavent"
                    onClick={handleReason}
                    for=""
                  >
                    These emails are not relavent to me
                  </a>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="neverSigned"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleReason}
                    checked={reason.neverSigned}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="neverSigned"
                    onClick={handleReason}
                  >
                    I never signed up for this mailing list
                  </a>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="other"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-1"
                    onClick={handleReason}
                    checked={reason.other}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="other"
                    onClick={handleReason}
                  >
                    Other (please specify):
                  </a>
                </div>
                {reason.other && (
                  <div className="form-floating mt-3">
                    <textarea
                      className="form-control"
                      maxLength={250}
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style={{ height: "100px" }}
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    ></textarea>
                    <label for="floatingTextarea2">Enter Comments</label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunicationPreference;
