import React, { useEffect, useState } from "react";
import { getDifferenceInDays } from "../../functions/CommonFunction";
import { monthNames } from "../../../constants/Constants";
import { AsYouType } from "libphonenumber-js";
import { getUserTenantID, getTenantType } from "../../../service/AuthService";
import { CurrencySymbols } from "../../../constants/CurrencySymbols";
import { IoLocationOutline } from "react-icons/io5";

function CandidateProfileOverview({
  candidateData,
  disposition,
  isFrom,
  getTab,
  jobData,
}) {
  let certifications =
    candidateData?.certifications &&
    typeof candidateData?.certifications === "string"
      ? JSON.parse(candidateData?.certifications)
      : candidateData?.certifications;
  let highestEducation =
    candidateData?.highestEducation && candidateData?.highestEducation;
  let educations =
    candidateData?.educations && typeof candidateData?.educations === "string"
      ? JSON.parse(candidateData?.educations)
      : candidateData?.educations;
  let experiences =
    candidateData?.experiences && typeof candidateData?.experiences === "string"
      ? JSON.parse(candidateData?.experiences)
      : candidateData?.experiences;
  let licenses =
    candidateData?.licenses && typeof candidateData?.licenses === "string"
      ? JSON.parse(candidateData?.licenses)
      : candidateData?.licenses;
  let preferredLocationS =
    candidateData?.preferredLocation &&
    typeof candidateData?.preferredLocation === "string"
      ? JSON.parse(candidateData?.preferredLocation)
      : candidateData?.preferredLocation;
  let primarySkills =
    candidateData?.primarySkills &&
    typeof candidateData?.primarySkills === "string"
      ? JSON.parse(candidateData?.primarySkills)
      : candidateData?.primarySkills;
  let positionTypes =
    candidateData?.positionTypes &&
    typeof candidateData?.positionTypes === "string"
      ? JSON.parse(candidateData?.positionTypes)
      : candidateData?.positionTypes;
  let secondarySkills =
    candidateData?.secondarySkills &&
    typeof candidateData?.secondarySkills === "string"
      ? JSON.parse(candidateData?.secondarySkills)
      : candidateData?.secondarySkills;
  let skillSet = candidateData?.skillSet
    ? typeof candidateData?.skillSet === "string"
      ? JSON.parse(candidateData?.skillSet).filter((item) => item !== "")
      : candidateData?.skillSet.filter((item) => item !== "")
    : candidateData?.skillSet;

  const getCertificationData = (candidateData) => {
    if (candidateData?.statusData && candidateData.statusData.length > 0) {
      const certificationData = [];

      candidateData.statusData.forEach((statusItem) => {
        const parseAndConcat = (jsonData) => {
          const parsedData = JSON.parse(jsonData);
          return (parsedData[0]?.answer || []).concat(
            parsedData[0]?.other || []
          );
        };

        const array1 =
          statusItem?.projectManager &&
          parseAndConcat(statusItem?.projectManager);
        const array2 =
          statusItem?.BAQuestion && parseAndConcat(statusItem?.BAQuestion);
        const finalData = [...(array1 || []), ...(array2 || [])].filter(
          (item) => item !== "Other"
        );

        certificationData.push(...finalData);
      });
      setCertificationData(certificationData);
    }
  };
  const getCertificationForSubComData = (candidateData) => {
    if (
      (candidateData?.projectManager
        ? JSON.parse(candidateData?.projectManager)
        : []
      ).length > 0 ||
      (candidateData?.BAQuestion ? JSON.parse(candidateData?.BAQuestion) : [])
        .length > 0
    ) {
      const certificationData = candidateData?.projectManager
        ? JSON.parse(candidateData?.projectManager)
        : [];
      const certificationBAData = candidateData?.BAQuestion
        ? JSON.parse(candidateData?.BAQuestion)
        : [];
      const arr = certificationData[0]?.answer
        ? certificationData[0]?.answer?.filter((e) => e !== "Other" && e !== "")
        : [];
      const arr1 = certificationData[0]?.other
        ? certificationData[0]?.other?.filter((e) => e !== "")
        : [];
      const arr2 = certificationBAData[0]?.answer
        ? certificationBAData[0]?.answer?.filter(
            (e) => e !== "Other" && e !== ""
          )
        : [];
      const arr3 = certificationBAData[0]?.other
        ? certificationBAData[0]?.other?.filter((e) => e !== "")
        : [];
      const finalArr = [...arr, ...arr1, ...arr2, ...arr3].filter(
        (e) => e !== "" && e !== undefined
      );
      setCertificationForSubComData(finalArr);
    }
  };

  const getNursingSpecialty = (candidateData) => {
    if (candidateData?.statusData && candidateData.statusData.length > 0) {
      const finalData = {
        specialty: new Set(),
        license: new Set(),
        certification: new Set(),
      };

      candidateData.statusData.forEach((statusItem) => {
        const generalQuestion = statusItem?.generalQuestion;

        if (generalQuestion) {
          const parsedData = JSON.parse(generalQuestion);

          parsedData.forEach((item) => {
            if (item.name === "Nursing License") {
              item.answer.forEach((license) => finalData.license.add(license));
            }
            if (item.name === "Nursing Specialty") {
              let data = item.answer || [];
              if (item.other) {
                data.push(...item.other);
              }
              data = data.filter((e) => e !== "Other");
              data.forEach((specialty) => finalData.specialty.add(specialty));
            }
            if (item.name === "Nursing Certification") {
              let data = item.answer || [];
              if (item.other) {
                data.push(...item.other);
              }
              data = data.filter((e) => e !== "Other");
              data.forEach((certification) =>
                finalData.certification.add(certification)
              );
            }
          });
        }
      });

      // Convert Sets to arrays
      const nursingSpecialty = {
        specialty: Array.from(finalData.specialty),
        license: Array.from(finalData.license),
        certification: Array.from(finalData.certification),
      };

      setNursingSpecialtyData([nursingSpecialty]);
    }
  };

  const getInternationalPlacement = (candidateData) => {
    if (candidateData?.statusData && candidateData.statusData.length > 0) {
      const finalData = {
        specialty: new Set(),
        license: new Set(),
        jobType: new Set(),
      };

      candidateData.statusData.forEach((statusItem) => {
        const recruiterQuestion = statusItem?.recruiterQuestion;

        if (recruiterQuestion) {
          const parsedData = JSON.parse(recruiterQuestion);

          parsedData.forEach((item) => {
            if (item.name === "Recruiter") {
              item.answer.forEach((license) => finalData.license.add(license));
            }
            if (item.name === "Category") {
              item.answer.forEach((specialty) =>
                finalData.specialty.add(specialty)
              );
            }
            if (item.name === "Job Type") {
              item.answer.forEach((jobType) => finalData.jobType.add(jobType));
            }
          });
        }
      });

      // Convert sets to arrays
      const InternationalPlacement = {
        specialty: Array.from(finalData.specialty),
        license: Array.from(finalData.license),
        jobType: Array.from(finalData.jobType),
      };

      setInternationalPlacementData([InternationalPlacement]);
    }
  };

  const [general, setGeneral] = useState(false);
  const [remark, setRemark] = useState(true);
  const [skills, setSkills] = useState(true);
  const [certificationDataToggle, setCertificationDataToggle] = useState(true);
  const [nursingSpecialtyDataToggle, setNursingSpecialtyDataToggle] =
    useState(true);
  const [
    internationalPlacementDataToggle,
    setInternationalPlacementDataToggle,
  ] = useState(true);
  const [RecruitedJobCategoryDataToggle, setRecruitedJobCategoryDataToggle] =
    useState(true);
  const [workHistory, setWorkHistory] = useState(true);
  const [certification, setCertifications] = useState(true);
  const [education, setEducation] = useState(true);
  const [preferredLocation, setPreferredLocation] = useState(true);
  const [source, setSource] = useState(true);
  const [generalQuestion, setGeneralQuestion] = useState(true);
  const [generalQuestionRecruiter, setGeneralQuestionRecruiter] =
    useState(true);
  const [industryExperience, setIndustryExperience] = useState(true);
  const [license, setLicense] = useState(true);
  const [description, setDescription] = useState(true);

  const [resumes, setResumes] = useState(true);
  const [ViewAllSkill, setViewAllSkill] = useState(false);
  const [ViewAllCertification, setViewAllCertification] = useState(false);

  const [social, setSocial] = useState(true);
  const [resumeModal, setResumeModal] = useState(false);
  const [reasonForUnsub, setReasonForUnsub] = useState(false);
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [certificationData, setCertificationData] = useState([]);
  const [certificationForSubComData, setCertificationForSubComData] = useState(
    []
  );
  const [nursingSpecialtyData, setNursingSpecialtyData] = useState([]);
  const [internationalPlacementData, setInternationalPlacementData] = useState(
    []
  );

  useEffect(() => {
    getCertificationData(candidateData);
    getNursingSpecialty(candidateData);
    getInternationalPlacement(candidateData);
    getCertificationForSubComData(candidateData);
  }, [candidateData]);

  useEffect(() => {
    preferredLocationS
      ? setPreferredLocations([...new Set(preferredLocationS?.map((e) => e))])
      : setPreferredLocations([]);
  }, [candidateData]);

  const jobBoard = [
    "Facebook",
    "LinkedIn",
    "Indeed",
    "CareerBuilder",
    "Dice",
    "Twitter",
  ];

  const onCollapsibleClick = (val) => {
    if (val === "general") {
      general ? setGeneral(false) : setGeneral(true);
    }
    if (val === "reasonForUnsub") {
      reasonForUnsub ? setReasonForUnsub(false) : setReasonForUnsub(true);
    }
    if (val === "remark") {
      remark ? setRemark(false) : setRemark(true);
    }

    if (val === "preferredLocation") {
      preferredLocation
        ? setPreferredLocation(false)
        : setPreferredLocation(true);
    }

    if (val === "source") {
      source ? setSource(false) : setSource(true);
    }

    if (val === "generalQuestion") {
      generalQuestion ? setGeneralQuestion(false) : setGeneralQuestion(true);
    }
    if (val === "generalQuestionRecruiter") {
      generalQuestionRecruiter
        ? setGeneralQuestionRecruiter(false)
        : setGeneralQuestionRecruiter(true);
    }

    if (val === "industryExperience") {
      industryExperience
        ? setIndustryExperience(false)
        : setIndustryExperience(true);
    }

    if (val === "skills") {
      skills ? setSkills(false) : setSkills(true);
    }
    if (val === "certificationData") {
      certificationDataToggle
        ? setCertificationDataToggle(false)
        : setCertificationDataToggle(true);
    }
    if (val === "nursingSpecialtyData") {
      nursingSpecialtyDataToggle
        ? setNursingSpecialtyDataToggle(false)
        : setNursingSpecialtyDataToggle(true);
    }
    if (val === "internationalPlacementData") {
      internationalPlacementDataToggle
        ? setInternationalPlacementDataToggle(false)
        : setInternationalPlacementDataToggle(true);
    }
    if (val === "RecruitedJobCategory") {
      RecruitedJobCategoryDataToggle
        ? setRecruitedJobCategoryDataToggle(false)
        : setRecruitedJobCategoryDataToggle(true);
    }

    if (val === "workHistory") {
      workHistory ? setWorkHistory(false) : setWorkHistory(true);
    }

    if (val === "certification") {
      certification ? setCertifications(false) : setCertifications(true);
    }

    if (val === "education") {
      education ? setEducation(false) : setEducation(true);
    }

    if (val === "license") {
      license ? setLicense(false) : setLicense(true);
    }

    if (val === "description") {
      description ? setDescription(false) : setDescription(true);
    }

    if (val === "social") {
      social ? setSocial(false) : setSocial(true);
    }

    if (val === "resumes") {
      resumes ? setResumes(false) : setResumes(true);
    }
  };

  const onSkillClick = () => {
    setViewAllSkill(!ViewAllSkill);
  };
  const onCertificationDataClick = () => {
    setViewAllCertification(!ViewAllCertification);
  };

  let positionType = "";

  if (
    candidateData?.preferredPositionType &&
    candidateData?.preferredPositionType?.length > 0
  ) {
    candidateData?.preferredPositionType?.forEach((item) => {
      positionType = positionType + "/" + item;
    });
  }

  return (
    <>
      <div>
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => onCollapsibleClick("general")}
          >
            <i
              className={
                general ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
              }
            ></i>

            <span>
              <small>General</small>
            </span>
          </button>
        </div>
        <div className={general ? "ml-4 d-none mb-2" : "ml-4 mb-2"}>
          {getTab !== "unsubscribed" && candidateData?.homePhone && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-phone"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData?.homePhone
                      ? new AsYouType().input(
                          candidateData.homePhone[0] === "+"
                            ? candidateData.homePhone
                            : candidateData.homePhoneCode &&
                              candidateData.homePhoneCode.includes("+")
                            ? `${candidateData.homePhoneCode}${candidateData.homePhone}`
                            : `+${candidateData.homePhone}`
                        )
                      : ""}
                  </p>
                  <p class="mb-0">
                    <small>Contact No</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {getTab !== "unsubscribed" && candidateData?.workPhone && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-phone"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData?.workPhone
                      ? new AsYouType().input(
                          candidateData.workPhone[0] === "+"
                            ? candidateData.workPhone
                            : candidateData.workPhoneCode &&
                              candidateData.workPhoneCode.includes("+")
                            ? `${candidateData.workPhoneCode}${candidateData.workPhone}`
                            : `+${candidateData.workPhone}`
                        )
                      : ""}
                  </p>
                  <p class="mb-0">
                    <small>Contact no</small>
                  </p>
                </div>
              </div>
            </>
          )}

          {(candidateData?.email || candidateData?.candidateEmail) && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-envelope"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData?.email
                      ? candidateData?.email
                      : candidateData?.candidateEmail}
                  </p>
                  <p class="mb-0">
                    <small>Email</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {(candidateData?.city || candidateData?.state) && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <IoLocationOutline color="#000" size={18} className="mr-1" />
              </div>
              <div className="ml-2">
                {
                  <p className="mb-0">
                    {/* {candidateData?.city +
                      ", " +
                      candidateData?.state +
                      ", " +
                      candidateData?.country} */}
                    {candidateData.city ? candidateData.city : ""}
                    {candidateData.state
                      ? (candidateData.city ? ", " : "") + candidateData.state
                      : ""}
                    {candidateData.country
                      ? (candidateData.city || candidateData.state
                          ? ", "
                          : "") + candidateData.country
                      : ""}
                  </p>
                }
                {/* {candidateData?.country ? (
                  <p className="mb-0">{candidateData?.country}</p>
                ) : candidateData?.city ? (
                  <p className="mb-0">{candidateData?.city}</p>
                ) : (
                  ""
                )} */}
                <p className="mb-0">
                  <small>Location</small>
                </p>
              </div>
            </div>
          )}
          {positionTypes?.length > 0 && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="fad fa-fw fa-piggy-bank"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">{positionTypes?.join(", ")}</p>
                <p className="mb-0">
                  <small>Job Type</small>
                </p>
              </div>
            </div>
          )}

          {candidateData?.experienceLevel && (
            // candidateData?.experienceMonth !== null &&
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i class="fas fa-user-visor"></i>
                </div>
                <div class="ml-2">
                  {/* {candidateData?.experienceYear
                      ? candidateData?.experienceYear
                      : "0"}
                    {"."}
                    {candidateData?.experienceMonth != 0
                      ? candidateData?.experienceMonth
                      : "0"}
                    {" Years"} */}
                  {candidateData?.experienceLevel === "Less than one year"
                    ? "Fresher"
                    : candidateData?.experienceLevel}
                  {/* {candidateData?.experienceYear ? "Years" : ""} */}
                  <p class="mb-0">
                    <small>Experience</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData?.highestEducation &&
            candidateData?.highestEducation !== "Select" && (
              <>
                <div className="d-flex mb-2 pl-1">
                  <div className="avatar avatar-sm">
                    <i className="fa fa-graduation-cap"></i>
                  </div>
                  <div class="ml-2">
                    <p class=" mb-0">
                      {candidateData?.highestEducation || "Not Updated"}
                    </p>
                    <p class="mb-0">
                      <small>Highest Education</small>
                    </p>
                  </div>
                </div>
              </>
            )}

          {candidateData.preferredSalary !== 0 &&
            candidateData.preferredSalary && (
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fas fa-money-bill"></i>
                </div>
                <div className="ml-2">
                  {CurrencySymbols[
                    candidateData.preferredSalaryCurrency.slice(0, 3)
                  ]
                    ? CurrencySymbols[
                        candidateData.preferredSalaryCurrency.slice(0, 3)
                      ]
                    : ""}
                  {Number(candidateData.preferredSalary).toLocaleString()}{" "}
                  {CurrencySymbols[
                    candidateData.preferredSalaryCurrency.slice(0, 3)
                  ]
                    ? ""
                    : candidateData.preferredSalaryCurrency.slice(0, 3)}
                  <p className="mb-0">
                    <small>
                      {candidateData?.salaryType === "Hourly"
                        ? "Expected hourly salary"
                        : candidateData?.salaryType === "Weekly"
                        ? "Expected weekly salary"
                        : candidateData?.salaryType === "Monthly"
                        ? "Expected monthly salary"
                        : "Expected annual salary"}{" "}
                    </small>
                  </p>
                </div>
              </div>
            )}

          {candidateData?.visaStatus && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i class="fad fa-plane-departure" aria-hidden="true"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData?.visaStatus || "Not Updated"}
                    {candidateData?.workAuthCountry
                      ? ", " + candidateData?.workAuthCountry
                      : ""}
                  </p>
                  <p class="mb-0">
                    <small>Immigration Status</small>
                  </p>
                </div>
              </div>
            </>
          )}

          {candidateData?.RecruiterName &&
            (isFrom === "QualifiedCandidates" ||
              isFrom === "inviteTalent" ||
              isFrom === "TalentPool") && (
              <>
                <div className="d-flex mb-2 pl-1">
                  <div className="avatar avatar-sm">
                    <i className="fa fa-user"></i>
                  </div>
                  <div class="ml-2">
                    <p class=" mb-0">{candidateData?.RecruiterName}</p>
                    <p class="mb-0">
                      <small>Invited by</small>
                    </p>
                  </div>
                </div>
              </>
            )}
          {candidateData?.RecruiterName &&
            isFrom === "TalentListView" &&
            candidateData?.dispositionStatus == 33 && (
              <>
                <div className="d-flex mb-2 pl-1">
                  <div className="avatar avatar-sm">
                    <i className="fa fa-user"></i>
                  </div>
                  <div class="ml-2">
                    <p class=" mb-0">{candidateData?.RecruiterName}</p>
                    <p class="mb-0">
                      <small>Invited by</small>
                    </p>
                  </div>
                </div>
              </>
            )}
          <div className="d-flex mb-2 pl-1">
            {candidateData?.RecruiterName && (
              <>
                <div className="avatar avatar-sm">
                  <i className="fa fa-user"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">{`${candidateData?.RecruiterName}`}</p>
                  <p class="mb-0">
                    <small>Invited by</small>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="d-flex mb-2 pl-1">
            <div className="avatar avatar-sm">
              <i className="fas fa-bring-forward"></i>
            </div>
            <div class="ml-2">
              <p class=" mb-0">
                {getTenantType() == 2
                  ? getUserTenantID() == 5788
                    ? jobBoard.includes(candidateData.source)
                      ? "High5 Job Board"
                      : candidateData.source == "Job Board"
                      ? "Crintell"
                      : candidateData.source == "JobDiva"
                      ? "JobDiva"
                      : "Internal"
                    : candidateData.source == "Job Board"
                    ? "Crintell"
                    : candidateData.source == "JobDiva"
                    ? "JobDiva"
                    : "Internal"
                  : getTenantType() == 1
                  ? candidateData.tenantId &&
                    candidateData.tenantId
                      .map(Number)
                      .includes(getUserTenantID())
                    ? "Internal"
                    : candidateData.fk_tenant &&
                      candidateData.fk_tenant == getUserTenantID()
                    ? "Internal"
                    : "External"
                  : candidateData.source == "Job Board"
                  ? "Crintell"
                  : candidateData.source == "JobDiva"
                  ? "JobDiva"
                  : "Internal"}
              </p>
              <p class="mb-0">
                <small>Source channel</small>
              </p>
            </div>
          </div>
        </div>

        {((candidateData.dispositionStatusName === "Rejected" &&
          candidateData.prevStatus === 33) ||
          (candidateData.dispositionStatusName === "Invited" &&
            candidateData.prevStatus === 12)) && (
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => onCollapsibleClick("remark")}
            >
              <i
                className={
                  remark ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
                }
              ></i>

              <span>
                <small>Remark</small>
              </span>
            </button>
          </div>
        )}
        <div className={remark ? "ml-4 d-none mb-2" : "ml-4 mb-2"}>
          <span className="tag tag-lg">
            {candidateData.dispositionStatusName && (
              <span>
                {candidateData.dispositionStatusName === "Sourced"
                  ? "Approval Pending"
                  : candidateData.dispositionStatusName == "Pending"
                  ? "Offer Pending"
                  : candidateData.dispositionStatusName === "Rejected"
                  ? candidateData.prevStatus === 33
                    ? "Withdrawn from Invited"
                    : "Disqualified"
                  : candidateData.dispositionStatusName === "Invited"
                  ? candidateData.prevStatus === 12
                    ? "Invited from Ineligible Candidates"
                    : "Invited"
                  : candidateData.dispositionStatusName}
              </span>
            )}
          </span>
        </div>

        {preferredLocations && preferredLocations?.length !== 0 && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("preferredLocation")}
              >
                <i
                  className={
                    preferredLocation
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Preferred Locations</small>
                </span>
              </button>
            </div>
            <div
              className={
                preferredLocation === true
                  ? "mb-2 ml-4 pl-1 d-none"
                  : "mb-2 ml-4 pl-1"
              }
            >
              {preferredLocations?.map((item, index) => {
                return item?.length > 1 ? (
                  <span key={index} className="tag tag-blue3 mb-2 mr-1">
                    {item}
                  </span>
                ) : (
                  ""
                );
              })}
            </div>
          </>
        )}

        {skillSet && skillSet?.length !== 0 && !candidateData.isFresher && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("industryExperience")}
              >
                <i
                  className={
                    industryExperience
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Industry</small>
                </span>
              </button>
            </div>
            <div
              className={
                industryExperience === true
                  ? "mb-4 ml-4 pl-1 d-none"
                  : "mb-4 ml-4 pl-1"
              }
            >
              {skillSet?.map((item, i) => (
                <span key={i} className="tag tag-blue3 mb-1 mr-1">
                  {item}
                </span>
              ))}
            </div>
          </>
        )}
        {primarySkills && primarySkills?.length > 0 && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("skills")}
              >
                <i
                  className={
                    skills ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Skills</small>
                </span>
              </button>
            </div>
            <div
              className={
                skills === true ? "mb-2 ml-4 pl-1 d-none" : "mb-2 ml-4 pl-1"
              }
            >
              {primarySkills?.length > 0 && (
                <label className="mb-1 p-0 m-0" for="Secondary">
                  <small className="">Primary Skills</small>
                </label>
              )}

              <div className="mb-2">
                {primarySkills.slice(0, 10).map((item, i) => (
                  <span key={i} className="tag tag-blue3 mb-1 mr-1">
                    {item}
                  </span>
                ))}
                {primarySkills?.length > 10 &&
                  ViewAllSkill === true &&
                  primarySkills
                    .slice(11, primarySkills?.length)
                    .map((item, i) => (
                      <span key={i} class="tag tag-blue3 mb-1 mr-1">
                        {item}
                      </span>
                    ))}
                {primarySkills?.length > 10 && (
                  <div class="mt-2">
                    <button
                      onClick={onSkillClick}
                      type="button"
                      class="btn btn-text"
                    >
                      {ViewAllSkill === false ? (
                        <span>View all skills</span>
                      ) : (
                        <span>View Top 10 Skills</span>
                      )}
                    </button>
                  </div>
                )}
              </div>

              {secondarySkills?.length > 0 && (
                <label className="mb-1 p-0 m-0" for="Secondary">
                  <small className="">Secondary Skills</small>
                </label>
              )}
              <div>
                {secondarySkills?.slice(0, 10).map((item, i) => (
                  <span key={i} className="tag tag-blue3 mb-1 mr-1">
                    {item}
                  </span>
                ))}
                {secondarySkills?.length > 10 &&
                  ViewAllSkill === true &&
                  secondarySkills
                    .slice(11, secondarySkills?.length)
                    .map((item, i) => (
                      <span key={i} class="tag  mb-1 mr-1">
                        {item}
                      </span>
                    ))}
                {secondarySkills?.length > 10 && (
                  <div class="mt-2">
                    <button
                      onClick={onSkillClick}
                      type="button"
                      class="btn btn-text"
                    >
                      {ViewAllSkill === false ? (
                        <span>View all skills</span>
                      ) : (
                        <span>View Top 10 Skills</span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {experiences &&
          experiences?.length > 0 &&
          experiences[0]?.employerName && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("workHistory")}
                >
                  <i
                    className={
                      workHistory
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Work Experience</small>
                  </span>
                </button>
              </div>

              <div
                className={
                  workHistory ? "mb-4 ml-4 pl-1 d-none" : "mb-4 ml-4 pl-1"
                }
              >
                {experiences
                  .filter((item) => item.employerName !== "")
                  .map((item, i) => (
                    <div key={i} className="d-flex">
                      <div className="mr-3 d-flex flex-column align-items-center">
                        <i className="fad fa-fw fa-briefcase mt-1"></i>
                        <div className="view-panel-spacer mt-1"></div>
                      </div>

                      <div className="pb-3">
                        <p className="mb-1">
                          <strong>
                            {item?.employerName}{" "}
                            <span className="font-regular">
                              {item?.industry ? "(" + item.industry + ")" : ""}
                            </span>
                          </strong>
                        </p>
                        <p className="mb-0">
                          <small>
                            <strong>{item?.jobTitle}</strong>
                          </small>
                        </p>
                        <p>
                          {item?.endDate !== "" &&
                          item?.endDate !== undefined ? (
                            <small>
                              {monthNames[new Date(item?.startDate).getMonth()]}{" "}
                              {new Date(item?.startDate).getFullYear()} -{" "}
                              {monthNames[new Date(item?.endDate).getMonth()]}{" "}
                              {new Date(item?.endDate).getFullYear()} (
                              {getDifferenceInDays(
                                item?.startDate,
                                item?.endDate
                              )}
                              )
                            </small>
                          ) : item?.startDate !== "" &&
                            item?.startDate !== undefined ? (
                            <small>
                              {monthNames[new Date(item.startDate).getMonth()]}{" "}
                              {new Date(item.startDate).getFullYear()} - (
                              {getDifferenceInDays(
                                item.startDate,
                                item.endDate
                              )}
                              )
                            </small>
                          ) : (
                            ""
                          )}
                        </p>
                        {item.description && <p>{item.description}</p>}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

        {educations &&
          educations?.length > 0 &&
          educations[0]?.educationProgram !== "" && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("education")}
                >
                  <i
                    className={
                      education
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Education</small>
                  </span>
                </button>
              </div>

              <div
                className={
                  education === true
                    ? "mb-4 ml-4 pl-1 d-none"
                    : "mb-4 ml-4 pl-1"
                }
              >
                {educations
                  .filter((item) => item.school !== "")
                  .map((item, i) => (
                    <div key={i} className="d-flex">
                      <div className="mr-3 d-flex flex-column align-items-center">
                        <i className="fad fa-graduation-cap fa-fw mt-1"></i>
                      </div>

                      <div>
                        <p className="mb-1">
                          <strong>{item.school}</strong>
                        </p>
                        <p>
                          <small>
                            {item.graduatedYear}-{item.educationProgram}-
                            {item.major}
                          </small>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

        {candidateData?.description &&
          candidateData?.description?.length > 0 && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("description")}
                >
                  <i
                    className={
                      description
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Description</small>
                  </span>
                </button>
              </div>
              <div
                className={
                  description === true
                    ? "mb-4 ml-4 pl-1 d-none"
                    : "mb-4 ml-4 pl-1"
                }
              >
                <div className="d-flex mb-2 pl-1">
                  <div class="ml-2">
                    <p class=" mb-0">{candidateData?.description}</p>
                  </div>
                </div>
              </div>
            </>
          )}

        {(candidateData?.facebook ||
          candidateData?.twitter ||
          candidateData?.linkedIn) && (
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => onCollapsibleClick("social")}
            >
              <i
                className={
                  social ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
                }
              ></i>
              <span>
                <small>Social</small>
              </span>
            </button>
          </div>
        )}

        <div className={social ? "ml-4 d-none" : "ml-4"}>
          {candidateData?.facebook && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-facebook"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData?.facebook?.slice(0, 8) === "https://"
                        ? candidateData?.facebook
                        : "https://" + candidateData?.facebook
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData?.facebook}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> Facebook Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData?.twitter && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-twitter"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData?.twitter?.slice(0, 8) === "https://"
                        ? candidateData?.twitter
                        : "https://" + candidateData?.twitter
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData?.twitter}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> Twitter Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData?.linkedIn && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-linkedin"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData?.linkedIn?.slice(0, 8) === "https://"
                        ? candidateData?.linkedIn
                        : "https://" + candidateData?.linkedIn
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData?.linkedIn}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> LinkedIn Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        {getTab === "unsubscribed" && candidateData?.unsubscribedReason && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("reasonForUnsub")}
              >
                <i
                  className={
                    reasonForUnsub
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Reason for unsubscribing</small>
                </span>
              </button>
            </div>
            <div
              className={
                reasonForUnsub === true
                  ? "mb-2 ml-4 pl-1 d-none"
                  : "mb-2 ml-4 pl-1"
              }
            >
              <div className="mb-2">
                <span>{candidateData?.unsubscribedReason}</span>
              </div>
            </div>
          </>
        )}

        <div className="mb-5"></div>
      </div>
    </>
  );
}

export default CandidateProfileOverview;
