import React, { useState } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import Modal from "react-bootstrap/Modal";
const { Anime } = ReactAnime;

function SuccessModal({
  showSuccessModal,
  setShowSuccessModal,
  closeModal,
  successData,
  isFrom,
}) {
  const [loop] = useState(false);

  const closeAllModal = (e) => {
    closeModal();
    setShowSuccessModal(false);
  };

  const closeModalHandler = () => {
    closeModal();
  };

  return (
    <>
      <Modal show={showSuccessModal} onHide={closeAllModal} size="active">
        <div class="bl-modal-header">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModal}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Modal.Body>
          <div class="section section-sm pb-0">
            <div class="success-animation">
              <div class="checkmark">
                <Lottie loop={loop} animationData={checkMark} play></Lottie>
              </div>
              <Anime
                initial={[
                  {
                    targets: "#LottieSparkles",
                    opacity: 0,
                    delay: 1000,
                  },
                ]}
              >
                <div class="sparkles">
                  <Lottie
                    loop={loop}
                    animationData={sparkles}
                    id="LottieSparkles"
                    play
                  ></Lottie>
                </div>
              </Anime>
            </div>
            <Anime
              initial={[
                {
                  targets: ".success-message",
                  opacity: 1,
                  delay: 800,
                  easing: "linear",
                },
              ]}
            >
              <div class="success-message" style={{ opacity: "0" }}>
                <div class="container">
                  <div class="col-lg-12">
                    <>
                      <h4 class="text-center">{successData.title}</h4>
                      <div
                        class="text-center"
                        dangerouslySetInnerHTML={{
                          __html: successData.subTitle,
                        }}
                      ></div>
                      <div style={{ padding: "10px" }}></div>
                      <div class="card card-flat card-lg">
                        <div
                          class="card-body"
                          dangerouslySetInnerHTML={{
                            __html: successData.htmlTag,
                          }}
                        ></div>
                      </div>
                    </>
                    <div class="text-center py-4">
                      <button
                        type="button"
                        class="btn btn-secondary mr-2"
                        data-dismiss="modal"
                        onClick={closeModalHandler}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Anime>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SuccessModal;
