import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../loader/Loader";

const Login = ({
  job,
  setEmail,
  email,
  setEditTalent,
  emailExist,
  setEmailExist,
  setApplyNewTalent,
  setApplyWithNewTalent,
  setModalSize,
  setCandidateDetails,
  setNewsLetterStatus,
  candidateDetails,
  setCandidateStatus,
  setTrackedData,
  trackedData,
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { recruiterid } = useParams();

  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setCandidateDetails({ ...candidateDetails, email: e.target.value });
  };

  const emailExists = async () => {
    if (email == "") {
      toast.error("Please enter your email");
    } else if (!validate(email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = {
        jobId: job.jobId,
        email: email,
        recruiterId: recruiterid,
      };
      setLoading(true);
      const url = "/disposition/applyjob/isemailexist";
      await post(url, params)
        .then((res) => {
          setLoading(false);
          if (res.data.message) {
            toast.error(res.data.message);
          } else if (res.data.candidateStatus === "New Talent") {
            setEmailExist(false);
            setApplyNewTalent(false);
            setApplyWithNewTalent(true);
            setCandidateStatus(res.data);
            setCandidateDetails({
              ...candidateDetails,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
            });
            setTrackedData({
              ...trackedData,
              jobId: job.jobId,
              poolId: job.poolId,
              navigatedPages: trackedData.navigatedPages + "/apply",
              firstName: res.data.firstName,
              lastName: res.data.lastName,

              emailId: candidateDetails.email,
              data: JSON.stringify({
                ...candidateDetails,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                resumeFileName: "",
              }),
            });

            post("/auditLog/candidateData", {
              ...trackedData,
              jobId: job.jobId,
              poolId: job.poolId,
              formType: "A",
              navigatedPages: trackedData.navigatedPages + "/apply",
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              emailId: candidateDetails.email,

              data: JSON.stringify({
                ...candidateDetails,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                resumeFileName: "",
              }),
            });
          } else if (res.data.candidateStatus === "Existing Talent") {
            setCandidateStatus(res.data);
            setNewsLetterStatus(res?.data?.newSletter);
            setCandidateDetails({
              ...candidateDetails,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              homePhone: res.data.homePhone,
              homePhoneCode: res.data.homePhoneCode,
            });
            setEmailExist(false);
            setApplyNewTalent(false);
            setTrackedData({
              ...trackedData,
              jobId: job.jobId,
              poolId: job.poolId,
              navigatedPages: trackedData.navigatedPages + "/apply",
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              emailId: res.data.email,
              data: JSON.stringify({
                ...candidateDetails,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                resumeFileName: "",
              }),
            });
            post("/auditLog/candidateData", {
              ...trackedData,
              jobId: job.jobId,
              poolId: job.poolId,
              formType: "A",
              navigatedPages: trackedData.navigatedPages + "/apply",
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              emailId: res.data.email,
              data: JSON.stringify({
                ...candidateDetails,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                resumeFileName: "",
              }),
            });

            if (res.data.status === 32) {
              setApplyWithNewTalent(true);
            } else {
              setEditTalent(true);
            }
          } else if (res.data.candidateStatus === "Login Talent") {
            setCandidateStatus(res.data);
            setEmailExist(true);
          }
        })
        .catch(() => {
          toast.error("Please try again");
          setLoading(false);
        });
    }
  };

  const loginUser = async () => {
    if (email == "" || password == "") {
      toast.error(
        "There are a few incomplete required fields. Please complete them to continue. "
      );
    } else {
      const params = { userName: email, password: password };
      setLoading(true);
      const res = await post("/candidatelogin", params)
        .then((res) => {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("name", res.data.name);
          sessionStorage.setItem("userId", res.data.userId);
          sessionStorage.setItem("userName", res.data.userName);
          let namesArray = res?.data?.name.split(" ");
          setTrackedData({
            ...trackedData,
            jobId: job.jobId,
            poolId: job.poolId,
            navigatedPages: trackedData.navigatedPages + "/Edit Talent",
            firstName: namesArray && namesArray[0],
            lastName: namesArray && namesArray[1],
            emailId: candidateDetails.email,
            data: JSON.stringify({
              ...candidateDetails,
              firstName: namesArray && namesArray[0],
              lastName: namesArray && namesArray[1],
              resumeFileName: "",
            }),
          });
          post("/auditLog/candidateData", {
            ...trackedData,
            jobId: job.jobId,
            poolId: job.poolId,
            formType: "A",
            navigatedPages: trackedData.navigatedPages + "/Edit Talent",
            firstName: namesArray && namesArray[0],
            lastName: namesArray && namesArray[1],
            emailId: candidateDetails.email,
            data: JSON.stringify({
              ...candidateDetails,
              firstName: namesArray && namesArray[0],
              lastName: namesArray && namesArray[1],
              resumeFileName: "",
            }),
          });
          setLoading(false);
          setApplyNewTalent(false);
          setEditTalent(true);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Please check credentials");
        });
    }
  };

  const forgetPassword = async (e) => {
    e.preventDefault();
    window.open(`/careers/forgot-passwordDS`, "_blank");
  };

  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setModalSize("login");
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div style={{ marginLeft: "16px", marginTop: "-9px" }}>
        <h6>Apply With</h6>
      </div>
      <div className="d-flex flex-column mx-5 mb-3 "></div>
      <div>
        <div className="d-flex flex-column mx-5 my-3">
          <div className="form-floating ml-1">
            <input
              type="text"
              className={"form-control"}
              name="groupName"
              placeholder="Enter a group name"
              onChange={(e) => handleEmail(e)}
            />
            <label>Email*</label>
          </div>
        </div>
        {emailExist && (
          <div className="d-flex flex-column mx-5 my-3">
            <div className="form-floating ml-1 form-password">
              <input
                type={showPassword ? "text" : "password"}
                className={"form-control"}
                name="groupName"
                placeholder="Enter a group name"
                onChange={(e) => setPassword(e.target.value)}
              />
              <label>Password*</label>
              {password.length > 0 && (
                <button
                  onClick={() => showPasswordClick()}
                  type="button"
                  className="btn btn-sm btn-text btn-text-accent h-100"
                >
                  <i
                    className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                    aria-hidden="true"
                  ></i>
                </button>
              )}
            </div>
            <p className="text-right my-1">
              <a href="" onClick={forgetPassword}>
                Forgot Password?
              </a>
            </p>
          </div>
        )}
        {emailExist ? (
          <div className="row mx-5 mb-3">
            <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => loginUser()}
            >
              Login
            </button>
          </div>
        ) : (
          <div className="row mx-5 mb-2">
            <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => emailExists()}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
