import React, { useState } from "react";
import { toast } from "react-toastify";
import { getUserId, getUserRole } from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";
import Loader from "../../../loader/Loader";
import GigRecruiter from "./GigRecruiter";
import RegistrationOverview from "./RegistrationOverview";
import LoginOverview from "./LoginOverview";
import RecruitmentPipeline from "./RecruitmentPipeline";
import InviteRecruiter from "./recruitertasks/InviteRecruiter";
import ShareLink from "./recruitertasks/ShareLink";
import { inviteRecruiterValidation } from "../../../validation/Recruiter";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [recruiterTab, setRecruiterTab] = useState("users");
  const [currentPage, setCurrentPage] = useState(1);
  const [smShow, setSmShow] = useState(false);
  const [PublicShareLinkValue, setPublicShareLinkButton] = useState(false);
  const [formModel, setFormModel] = useState({
    firstName: "",
    lastName: "",
    Email: "",
    mobile: "",
    mobilePhoneCode: "",
  });
  const [Modelvalidation, setModelValidation] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    mobileValidation: true,
    mobileError: "",
  });

  const onTabClick = (type) => {
    setRecruiterTab(type);
    setCurrentPage(1);
  };
  let sendMailLink = `${
    window.location.origin
  }/RecruiterRegistrationForm/${getUserId()}`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText(sendMailLink);
    toast.success("Link Copied Successfully!!!");
  };

  const handleModelClick = async (e) => {
    e.preventDefault();
    let validation = inviteRecruiterValidation(formModel, Modelvalidation);
    if (validation?.validationArray.length > 0) {
      setModelValidation(validation?.validationObj);
    } else {
      setModelValidation({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        mobileError: "",
      });
      try {
        setLoading(true);
        const res = await post("/dashboard/sa/inviterecruiter", formModel);
        if (res.data.ReturnText === "Invited Successfully") {
          setFormModel({
            firstName: "",
            lastName: "",
            Email: "",
          });
          setSmShow(false);
          toast.success("Recruiter invited successfully");
        } else {
          toast.error(res.data.ReturnText);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Some Error Occurred while Inviting");
      }
    }
  };

  return (
    <div className="py-5">
      {loading && <Loader />}
      <div className="d-flex d-block-1200 justify-content-between">
        <div className="d-flex justify-content-between mt-3">
          <ul class="nav nav-tabs  ">
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("users");
                }}
                type="button"
                class={
                  recruiterTab == "users"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Gig Recruiters</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("regOverview");
                }}
                type="button"
                class={
                  recruiterTab == "regOverview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                {" "}
                <div>Registration Overview</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("loginOverview");
                }}
                type="button"
                class={
                  recruiterTab == "loginOverview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Login Overview</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("recruitmentPipe");
                }}
                type="button"
                class={
                  recruiterTab == "recruitmentPipe"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Recruitment Pipeline</div>
              </button>
            </li>
          </ul>
        </div>{" "}
        <hr className="d-none d-block-1200 mt-0" />
        {getUserRole() === "CommunityManager" && (
          <div className="d-flex align-items-end justify-content-center mb-1 gap-2">
            <div className="flex-item">
              <button
                type="button"
                class="btn btn-sm"
                onClick={() => setSmShow(true)}
              >
                <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>
                Invite
              </button>
            </div>

            <div className="flex-item">
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => setPublicShareLinkButton(true)}
              >
                <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                Invitation Link
              </button>
            </div>
          </div>
        )}
      </div>
      <hr className="mt-0 d-none-1200" />
      <div className="d-none d-block-1200 mb-3"></div>
      {recruiterTab == "users" && <GigRecruiter />}
      {recruiterTab == "regOverview" && <RegistrationOverview />}
      {recruiterTab == "loginOverview" && <LoginOverview />}
      {recruiterTab == "recruitmentPipe" && <RecruitmentPipeline />}
      {PublicShareLinkValue && (
        <ShareLink
          PublicShareLinkValue={PublicShareLinkValue}
          setPublicShareLinkButton={setPublicShareLinkButton}
          sendMailLink={sendMailLink}
          handleCopy={handleCopy}
        />
      )}
      {smShow && (
        <InviteRecruiter
          smShow={smShow}
          setSmShow={setSmShow}
          formModel={formModel}
          setFormModel={setFormModel}
          Modelvalidation={Modelvalidation}
          setModelValidation={setModelValidation}
          handleModelClick={handleModelClick}
        />
      )}
    </div>
  );
};

export default Index;
