import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import "react-tagsinput/react-tagsinput.css";
import _ from "lodash";
import {
  getUserRole,
  getCandidateName,
} from "../../../../service/AuthService";
import moment from "moment";
import { toast } from "react-toastify";
import { currency_list } from "../../../functions/CommonFunction";
import Experience from "./Experience";
import Description from "./Description";
import PositionAndDates from "./PositionAndDates";
import Contract from "./Contract";
import PlacementAndReferral from "./PlacementAndReferal";
import TemplateName from "./TemplateName";
import Industry from "./Industry";
import PrimarySkills from "./PrimarySkills";
import SecondarySkills from "./SecondarySkills";
import Salary from "./Salary";
import WorkPlaceTypeAndLocation from "./WorkPlaceTypeAndLocation";
import TitleAndStartDate from "./TitleAndStartDate";
import VisaStatus from "./VisaStatus";
import PublishJob from "./PublishJob";

const JobDetails = ({
  primarySkillInput,
  setPrimarySkillInput,
  secondarySkillInput,
  setSecondarySkillInput,
  addJobData,
  setAddJobData,
  value,
  setValue,
  addJobVal,
  addJobType,
  dropdownList,
  documents,
  setDocuments,
  preview,
  handleBackNext,
  addTemplate,
  templateName,
  setTemplateName,
  templateCategory,
  setTemplateCategory,
  editTemplate,
  isFormValid,
  edit,
  setCategoryLoading,
  radius,
  setRadius
}) => {
  const name = getCandidateName();
  const today = moment();
  let todayDate = today.format("MM/DD/YYYY");
  const [visaStatusInput, setVisaStatusInput] = useState("");
  const [openForReferral, setOpenForReferral] = useState(false);

  let skillSetOption =
    Object.keys(dropdownList).length > 0
      ? dropdownList.skillSet.map((item) => {
          const skillSetObj = {
            value: item.PositionName,
            label: item.PositionName,
          };
          return skillSetObj;
        })
      : [];

  const handleNumberOfPositions = (e) => {
    setAddJobData({
      ...addJobData,
      numberOfPositions: e.target.value,
      allowedSubmittals: e.target.value * 5,
    });
  };

  const onChange = (value) => {
    setValue(value);
    setAddJobData({ ...addJobData, description: value.toString("html") });
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 999,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.PositionName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0 || Object.keys(dropdownList).length === 0
          ? []
          : dropdownList.skills.filter(
              (item) =>
                item.PositionName.toLowerCase().includes(inputValue) &&
                ![
                  ...addJobData.primarySkills,
                  ...addJobData.secondarySkills,
                ].includes(item.PositionName)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => (
      <div>{suggestion.PositionName}</div>
    );

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.PositionName);
        }}
        theme={theme}
      />
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "placementFee") {
      let value = e.target.value;
      if (value) {
        if (+value === 0) {
          toast.error("Cannot Be Zero");
        } else if (+value < 0 || +value === -0) {
          toast.error("Negative numbers are not allowed");
        } else {
          setAddJobData({ ...addJobData, [e.target.name]: e.target.value });
        }
      } else {
        setAddJobData({ ...addJobData, [e.target.name]: e.target.value });
      }
    } else if (
      e.target.name === "maximumPay" ||
      e.target.name === "minimumPay"
    ) {
      setAddJobData({
        ...addJobData,
        [e.target.name]: e.target.value.slice(0, 7),
      });
    } else if (e.target.name === "title") {
      setAddJobData({
        ...addJobData,
        [e.target.name]:
          e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
      });
    } else {
      setAddJobData({ ...addJobData, [e.target.name]: e.target.value });
    }
  };

  const handleChangeLocation = (e) => {
    setAddJobData({
      ...addJobData,
      location: { ...addJobData.location, [e.target.name]: e.target.value },
    });
  };

  const onUploadHandler = (file, type) => {
    let docs = [...documents];
    if (file) {
      if (file[0].size / 1024 > 2048) {
        toast.error("File size exceeded 2 MB");
      } else {
        file.forEach((eachFile) => {
          const reader = new FileReader();
          reader.readAsDataURL(eachFile);
          reader.onload = (event) => {
            const obj = {
              documentName: eachFile.name,
              base64: event.target.result,
              uploadedDate: todayDate,
              uploadedBy: name,
              category:
                type === "Video" ? "Video Job Description" : "Job Description",
              role: getUserRole(),
              type,
            };
            docs.push(obj);
            setDocuments((current) => [...current, obj]);
          };
        });
      }
    }
  };

  return (
    <div>
      <div className="d-flex mb-2">
        <label className="ml-auto mr-4">*Denotes mandatory field</label>
      </div>

      {/* TEMPLATE NAME */}
      {(addTemplate || editTemplate) && (
        <TemplateName
          addJobData={addJobData}
          templateName={templateName}
          editTemplate={editTemplate}
          setTemplateName={setTemplateName}
          addJobVal={addJobVal}
          isFormValid={isFormValid}
        />
      )}

      {/* JOB TITLE and PREFERRED START DATE */}
      <TitleAndStartDate
        addTemplate={addTemplate}
        editTemplate={editTemplate}
        isFormValid={isFormValid}
        handleChange={handleChange}
        addJobData={addJobData}
        setTemplateCategory={setTemplateCategory}
        templateCategory={templateCategory}
        skillSetOption={skillSetOption}
        addJobVal={addJobVal}
        preview={preview}
        setAddJobData={setAddJobData}
        edit={edit}
      />

      {/* No of POSITIONS, ACTIVE FROM and EXPIRES ON */}
      {!(addTemplate || editTemplate) && (
        <PositionAndDates
          addJobData={addJobData}
          handleNumberOfPositions={() => handleNumberOfPositions()}
          setAddJobData={setAddJobData}
          handleChange={handleChange}
          addJobVal={addJobVal}
          isFormValid={isFormValid}
        />
      )}

      {/* WORK PLACE TYPE and LOCATION */}
      <WorkPlaceTypeAndLocation
        addJobData={addJobData}
        setAddJobData={setAddJobData}
        radius = {radius}
         setRadius = {setRadius}
        isFormValid={isFormValid}
        handleChangeLocation={handleChangeLocation}
        addJobVal={addJobVal}
        handleChange={handleChange}
        edit={edit}
        changePreferredLocation={(location) => {
          setAddJobData({
            ...addJobData,
            preferredLocation: location,
          });
        }}
      />

      {/* SALARY */}
      <Salary
        addJobData={addJobData}
        handleChange={handleChange}
        addJobType={addJobType}
        isFormValid={isFormValid}
        currency_list={currency_list}
      />

      {/* CONTRACT DURATION */}
      {addJobType.contractSelected && (
        <Contract handleChange={handleChange} addJobData={addJobData} />
      )}

      {/*VISA STATUS*/}
      <VisaStatus
        addJobData={addJobData}
        setAddJobData={setAddJobData}
        isFormValid={isFormValid}
      />

      {/* PRIMARY SKILLS */}
      <PrimarySkills
        addJobData={addJobData}
        setAddJobData={setAddJobData}
        autocompleteRenderInput={autocompleteRenderInput}
        isFormValid={isFormValid}
        primarySkillInput={primarySkillInput}
        setPrimarySkillInput={setPrimarySkillInput}
        edit={edit}
      />

      {/* SECONDARY SKILLS */}
      <SecondarySkills
        secondarySkillInput={secondarySkillInput}
        setSecondarySkillInput={setSecondarySkillInput}
        autocompleteRenderInput={autocompleteRenderInput}
        addJobData={addJobData}
        setAddJobData={setAddJobData}
      />

      {/* INDUSTRY */}
      <Industry
        addJobData={addJobData}
        setAddJobData={setAddJobData}
        skillSetOption={skillSetOption}
        addJobVal={addJobVal}
        isFormValid={isFormValid}
        dropdownList={dropdownList?.skillSet}
        setCategoryLoading={setCategoryLoading}
      />

      {/* REQUIRED EXPERIENCE and REQUIRED EDUCATION */}
      <Experience
        handleChange={(e) => handleChange(e)}
        setAddJobData={setAddJobData}
        addJobData={addJobData}
        dropdownList={dropdownList}
        isFormValid={isFormValid}
        edit={edit}
      />

      {/* DESCRIPTION */}
      <Description
        value={value}
        onChange={onChange}
        isFormValid={isFormValid}
        addJobData={addJobData}
        addTemplate={addTemplate}
        editTemplate={editTemplate}
        documents={documents}
        setDocuments={setDocuments}
        onUploadHandler={onUploadHandler}
      />

      {/* PLACEMENT and REFERRAL */}
      <PlacementAndReferral
        addJobData={addJobData}
        handleChange={handleChange}
        openForReferral={openForReferral}
        setOpenForReferral={setOpenForReferral}
        isFormValid={isFormValid}
        setAddJobData={setAddJobData}
      />
      <PublishJob addJobData={addJobData} setAddJobData={setAddJobData} />
    </div>
  );
};

export default JobDetails;
