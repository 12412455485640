import moment from "moment";
import React from "react";
import { BiHistory } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import ReactQuill from "react-quill";

const TemplateIndex = (props) => {
  const { SMSData, isValid, sanitizeHTML } = props;
  return (
    <>
      {!props.editMode && (
        <button
          type="button"
          className="btn btn-secondary btn-sm mt-2"
          onClick={() => {
            props.setEditMode(true);
          }}
          style={{ float: "right" }}
        >
          <FaEdit /> Edit
        </button>
      )}
      <label htmlFor="editSubject" className="form-label">
        <b>Recipient Persona</b>
      </label>
      <input
        type="text"
        className="form-control"
        id="editSubject"
        style={{
          border:
            isValid && SMSData?.recipients[0] === "" && "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
          borderRadius: "0px",
          fontSize: "0.9em",
        }}
        value={SMSData?.recipients[0]}
        disabled={true}
      />

      <label htmlFor="editContent" className="form-label">
        <b>Content</b>
      </label>
      <ReactQuill
        value={SMSData?.smsContent.replace(/\n/g, "<br>")}
        style={{
          border:
            isValid &&
            sanitizeHTML(SMSData?.smsContent) === "" &&
            "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
        }}
        theme="snow"
        readOnly={true}
        className="mb-2"
      />

      {SMSData &&
        SMSData?.logDetails &&
        JSON.parse(SMSData?.logDetails)?.length > 0 && (
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12 mb-3 mt-1">
              <hr />
              <b>
                <BiHistory size={20} color="#3ab549" /> Audit Logs
              </b>
            </div>
            <div className="col-md-12 mb-2">
              <table className="table-bordered table responsiveTable">
                <thead className="bg-light text">
                  <tr>
                    <th>Time</th>
                    <th>Email ID</th>
                    <th>User Name</th>
                    <th>Action</th>
                    <th>IP Address</th>
                    <th>Browser</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(SMSData?.logDetails)?.map((item) => {
                    return (
                      <tr>
                        <td>
                          {moment(item.createdDateTime).format(
                            "MM/DD/YYYY HH:mm:ss"
                          )}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.firstName + " " + item.lastName}</td>
                        <td>
                          <span
                            className={`tag mb-1 mr-1 px-2 ${
                              item.action === "Activated"
                                ? "tag-green3"
                                : item.action === "Paused"
                                ? "tag-red1"
                                : "tag-blue1"
                            }`}
                          >
                            {item.action}
                          </span>
                        </td>
                        <td>{item.operatingsystem}</td>
                        <td>
                          {item?.browser
                            ? JSON.parse(item?.browser).name +
                              " " +
                              "Version " +
                              JSON.parse(item?.browser).version
                            : "Not Found"}
                        </td>
                        <td>{item?.location ? item?.location : "Not Found"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </>
  );
};

export default TemplateIndex;
