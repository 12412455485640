import {
  GET_PROFILE_INFO_BEGIN,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
  GET_TENANT_LOGO_BEGIN,
  GET_TENANT_LOGO_SUCCESS,
  GET_TENANT_LOGO_FAILURE,
} from "../../action/profile/Profile";

const initialState = {
  loading: false,
  profileDate: [],
  logoLoading: false,
  tenantLogo: null,
};

const profileInfoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE_INFO_BEGIN:
      return {
        ...state,
        loading: true,
        profileDate: [],
      };
    case GET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        profileDate: payload,
        loading: false,
      };
    case GET_PROFILE_INFO_FAILURE:
      return {
        ...state,
        profileDate: payload,
        loading: false,
      };
    case GET_TENANT_LOGO_BEGIN:
      return {
        ...state,
        loading: true,
        tenantLogo: null,
      };
    case GET_TENANT_LOGO_SUCCESS:
      return {
        ...state,
        tenantLogo: payload,
        loading: false,
      };
    case GET_TENANT_LOGO_FAILURE:
      return {
        ...state,
        tenantLogo: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default profileInfoReducer;
