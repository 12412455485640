import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import {
  getOwner,
  getCandidateName,
  getUserRole,
  loginUserName,
  getUserToken,
} from "../../../service/AuthService";
import useOutsideClick from "../../functions/useOutsideClick";
import Event from "../../common/googleanalytic/Tracking";
import { apiDomain } from "../../../constants/Constants";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggleProfile, setToggleProfile] = useState(false);
  const username = getCandidateName();
  const userprofile = { name: username.substr(0, username.indexOf(" ")) };
  const userRole = getUserRole();
  const ref = useRef();
  useOutsideClick(ref, () => {
    setToggleProfile(false);
  });

  const profileImagePath = JSON.parse(
    localStorage.getItem("userToken")
  ).profileImagePath;

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = getUserToken();
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async () => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      let role = getUserRole();
      let name = loginUserName();
      let result = `${name},${role}`;
      if (res.status === 200) {
        Event("Logout", result, window.location.pathname);
        localStorage.clear();
        history.go("/login");
      }
    } catch (error) {
      toast.error("Unable to Logout!");
      console.log(error);
    }
  };

  const toggleProfileDropdown = () => {
    setToggleProfile(!toggleProfile);
  };

  return (
    <div className="dropdown ml-3">
      <button
        className="btn btn-icon dropdown-toggle"
        type="button"
        ref={ref}
        onClick={toggleProfileDropdown}
      >
        {profileImagePath ? (
          <img
            src={profileImagePath}
            alt=""
            width="30"
            height="30"
            style={{
              borderRadius: "50%",
              objectFit: "contain",
            }}
          />
        ) : (
          <Avatar
            className="avatar avatar-sm "
            name={userprofile.name}
            round="80px"
          />
        )}
      </button>
      <div>
        {toggleProfile && (
          <div
            className="dropdown-menu"
            style={{ display: "revert", right: "-14px", top: "30px" }}
          >
            {!window.location.href.includes("/myprofile") && (
              <>
                {" "}
                <a href="/high5hire/myprofile" className="dropdown-item">
                  <i className="fal fa-user-circle mr-3"></i>Profile
                </a>
                <hr />
              </>
            )}
            {getOwner() &&
              !window.location.href.includes("/users") &&
              userRole !== "HiringManager" &&
              userRole !== "Recruiter" && (
                <>
                  <a href="/high5hire/users" className="dropdown-item">
                    <i className="fal fa-user-circle mr-3"></i>Users
                  </a>
                  <hr />
                </>
              )}{" "}
            <a href="#top" className="dropdown-item" onClick={onClickLogout}>
              <i className="fal fa-sign-out mr-3"></i> Logout{" "}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileDropdown;
