import React from "react";
import { Row, Col } from "react-bootstrap";

const FormFooter = ({ check, setCheck, handleChange, agree, setAgree }) => {
  return (
    <>
      <Row>
        <Col>
          <div className="form-check">
            <input
              style={{ width: "1.2rem", height: "1.2rem" }}
              className="form-check-input mt-3"
              type="checkbox"
              checked={check}
              onChange={(e) => setCheck(e.target.checked)}
              id="referredCheck"
              required
            />
            <label className="form-check-label ml-3" for="flexCheckDefault">
              Check if you are referred to High5.
            </label>
            {check && (
              <Row>
                <Col>
                  <label className="form-check-label">Who referred you?</label>
                  <input
                    type="text"
                    className="form-control"
                    id="isreferred"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-check">
            <input
              style={{ width: "1.2rem", height: "1.2rem" }}
              className="form-check-input mt-3"
              type="checkbox"
              id="referredCheck"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <label
              className="form-check-label ml-3"
              for="receiveCommunications"
            >
              I agree to receive communications from High5.*
            </label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-5">
          We're committed to your privacy. High5 uses the information you
          provide to us to contact you about our relevant content, products, and
          services. You may unsubscribe from these communications at any time.
        </Col>
      </Row>
    </>
  );
};

export default FormFooter;
