import {
  GET_JOB_DISPOSITION_BEGIN,
  GET_JOB_DISPOSITION_SUCCESS,
  GET_JOB_DISPOSITION_FAILURE,
  GET_JOB_DISPOSITION_COUNT_BEGIN,
  GET_JOB_DISPOSITION_COUNT_SUCCESS,
  GET_JOB_DISPOSITION_COUNT_FAILURE,
  GET_JOB_DISPOSITION_DISQUALIFY_BEGIN,
  GET_JOB_DISPOSITION_DISQUALIFY_SUCCESS,
  GET_JOB_DISPOSITION_DISQUALIFY_FAILURE,
} from "../../../../action/disposition/job/jobdetails/JobDetails";

const initialState = {
  dispositionDetailsLoading: true,
  dispositionDetails: [],
  dispositionCountLoading: true,
  dispositionCount: [],
  dispositionDisqualifyLoading: true,
  dispositionDisqualify: [],
};

const jobDispositionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOB_DISPOSITION_BEGIN:
      return {
        ...state,
        dispositionDetailsLoading: true,
        dispositionDetails: [],
      };
    case GET_JOB_DISPOSITION_SUCCESS:
      return {
        ...state,
        dispositionDetails: payload,
        dispositionDetailsLoading: false,
      };
    case GET_JOB_DISPOSITION_FAILURE:
      return {
        ...state,
        dispositionDetails: [],
        dispositionDetailsLoading: false,
      };
    case GET_JOB_DISPOSITION_COUNT_BEGIN:
      return {
        ...state,
        dispositionCountLoading: true,
        dispositionCount: [],
      };
    case GET_JOB_DISPOSITION_COUNT_SUCCESS:
      return {
        ...state,
        dispositionCount: payload,
        dispositionCountLoading: false,
      };
    case GET_JOB_DISPOSITION_COUNT_FAILURE:
      return {
        ...state,
        dispositionCount: [],
        dispositionCountLoading: false,
      };
    case GET_JOB_DISPOSITION_DISQUALIFY_BEGIN:
      return {
        ...state,
        dispositionDisqualifyLoading: true,
        dispositionDisqualify: [],
      };
    case GET_JOB_DISPOSITION_DISQUALIFY_SUCCESS:
      return {
        ...state,
        dispositionDisqualify: payload,
        dispositionDisqualifyLoading: false,
      };
    case GET_JOB_DISPOSITION_DISQUALIFY_FAILURE:
      return {
        ...state,
        dispositionDisqualify: [],
        dispositionDisqualifyLoading: false,
      };
    default:
      return state;
  }
};

export default jobDispositionReducer;
