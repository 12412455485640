import React from "react";

const Certification = ({
  certification,
  onCollapsibleClick,
  certifications,
  testId,
  setTestId,
  removeItemFromArray,
}) => {
  return (
    <>
      {certification.length > 0 && (
        <button
          onClick={() => {
            onCollapsibleClick("certifications");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {!certifications ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Certifications</small>
          </span>
        </button>
      )}
      <div className={certifications ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <div class="d-flex mb-2 pl-1 ml-4">
          <div>
            {certification?.map(
              (item, index) =>
                (index < 2 || testId.includes("certifications")) && (
                  <div
                    key={index}
                    className="tag tag-blue3"
                    style={{ margin: "2px" }}
                  >
                    <span>{item}</span>
                  </div>
                )
            )}
            {!testId.includes("certifications") ? (
              certification?.length > 2 && (
                <div
                  className="tag"
                  onClick={() => setTestId([...testId, "certifications"])}
                  style={{ margin: "2px", cursor: "pointer" }}
                >
                  +{certification?.length - 2} more
                </div>
              )
            ) : (
              <div
                className="tag"
                onClick={() => removeItemFromArray("certifications")}
                style={{ margin: "2px", cursor: "pointer" }}
              >
                Show less
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Certification;
