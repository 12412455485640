import React, { useEffect, useState } from "react";
import "react-tagsinput/react-tagsinput.css";
import { post } from "../../../../service/ApiService";
import ShowVettingModal from "./ShowVettingModal";
import { Modal } from "react-bootstrap";
import AssessmentPreview from "./AssessmentPreview";
import CreateAssessment from "./CreateAssessment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { validate } from "./Validation";
import { toast } from "react-toastify";
import DisplayQuestions from "./DisplayQuestion";
import ShortUniqueId from "short-unique-id";
import Loader from "../../../common/loader/Loader";
import QuestionList from "./QuestionList";

function InterviewPlan({
  addJobData,
  setAddJobData,
  setScreeningQuestions,
  vettingDetails,
  setVettingDetails,
  questionList,
  setQuestionList,
  setIsManualReviewer,
  edit,
  setVettingData,
}) {
  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];

  let difficulty = "";
  difficultyData.forEach((obj) => {
    if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
  });
  const [createAssessmentAI, setCreateAssessmentAI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const uid = new ShortUniqueId({ length: 10 });
  const [questionData, setquestionData] = useState({
    question: "",
    type: "",
    difficulty: difficulty,
    answer: [],
    options: ["", ""],
    skill: "",
    remark: "",
    imageUrl: "",
    modifiedBy: "",
    uploadedFileInfo: "",
    deleted: false,
    id: "",
  });
  const [addQuestionVal, setAddQuestionVal] = useState({
    question: "",
    type: "",
    skill: "",
    options: "",
    answer: "",
  });
  const [generateAssessment, setGenerateAssessment] = useState("Manual");
  const [next, setNext] = useState(false);
  // const [questionList, setQuestionList] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const [createAssessment, setCreateAssessment] = useState(false);
  const [displayVetting, setDisplayVetting] = useState(true);
  const [showVettingModal, setShowVettingModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [newAssessment, setNewAssessment] = useState([]);

  const savedQuestions = () => {
    const result = validate(questionData);
    if (result.validationArray.length > 0) {
      toast.error(result.validationArray[0]);
      setAddQuestionVal(result.validation);
    } else if (questionIndex !== -1) {
      // setQuestionList((state) => [...state, questionData]);
      setQuestionList((state) => {
        // Make a copy of the state array
        const updatedList = [...state];

        // Find the index of the question you want to update
        const indexToUpdate = questionIndex; /* your desired index here */

        // Update the element at the specified index
        updatedList[
          indexToUpdate
        ] = questionData /* your updated question data here */;

        return updatedList;
      });
      setQuestionIndex(-1);
      setquestionData({
        question: "",
        type: "",
        difficulty: difficulty,
        answer: [],
        options: ["", ""],
        skill: "",
        remark: "",
        imageUrl: "",
        modifiedBy: "",
        uploadedFileInfo: "",
        deleted: false,
        id: "",
      });
      setCreateAssessment(false);
    } else {
      setQuestionList((state) => [...state, questionData]);
      setquestionData({
        question: "",
        type: "",
        difficulty: difficulty,
        answer: [],
        options: ["", ""],
        skill: "",
        remark: "",
        imageUrl: "",
        modifiedBy: "",
        uploadedFileInfo: "",
        deleted: false,
        id: "",
      });
      setCreateAssessment(false);
    }
  };
  useEffect(() => {}, [questionList]);

  const toolTip = () => {
    return (
      <Tooltip style={{ zIndex: 99999999, maxWidth: "300px" }}>
        <div style={{ textAlign: "left", marginLeft: "12px" }}>
          <h6>What to do:</h6>
          <ol>
            <li>
              Choose whether to incorporate vetting for this community or
              not.
            </li>
            <li>
              Choose between using system recommended vetting criteria or
              creating custom vetting questions.
            </li>
            <li>
              If using system recommended criteria, review and add them as
              needed.
            </li>
            <li>
              If creating custom vetting questions, select the question type
              (MCQ or one-way video answer) and duration (10 to 15 minutes),
              then write and input your questions based on the job requirements.
            </li>
            <li>
              Once satisfied with your vetting settings, click ‘Next’ to save
              your selections and proceed to the next section of the job posting
              set up.
            </li>
          </ol>
        </div>
      </Tooltip>
    );
  };

  const createAssessmentUsingAI = async (item) => {
    // if (Object.keys(addJobData?.vettingDetails)?.length === 0) {
    setLoading(true);
    let difficulty = "";
    difficultyData.forEach((obj) => {
      if (obj.exp === addJobData?.experienceLevel) difficulty = obj.val;
    });
    setVettingData(item);
    let skillsObj = {
      Skills: item.skills,
      Duration: 20,
      Difficulty: item.difficulty,
      AssessmentType: "General",
      JobTitle: `${addJobData?.jobTitle}_high5_${uid()}`,
      PassingScore: 45,
      createdFrom: "talentPool",
      limit: item.num,
      instructions: item.instructions,
      type: item.type,
    };

    await post("/chatGpt/createAssessment", skillsObj).then((res) => {
      setLoading(false);
      if (res?.data?.message == "High5Hire Not Available now use custom now") {
        toast.warning(
          "Oops! It seems High5Hire AI is not available right now, please create new assessment using custom option."
        );

        // setVettingDetails(res?.data);
        // setAddJobData({
        //   ...addJobData,
        //   createNewAssessment: false,
        // });
      } else {
        //grouping questions based on the type
        if (Object.keys(res?.data).length > 0) {
          const sortedQuestions = res?.data?.questions?.sort((a, b) => {
            if (a.type < b.type) return -1;
            if (a.type > b.type) return 1;
            return 0;
          });
          setQuestionList(sortedQuestions);
        } else {
          toast.warning(
            "Oops! It seems High5Hire AI is not available right now, please create new assessment using custom option."
          );
        }
      }
    });
  };

  const handleAssessment = async (e) => {
    if (e.target.checked) {
      if (Object.keys(vettingDetails).length === 0) {
        setLoading(true);
        let difficulty = "";
        difficultyData.forEach((obj) => {
          if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
        });
        let skillsObj = {
          Skills: addJobData?.primarySkills?.flat(1),
          Duration: 20,
          Difficulty: difficulty,
          AssessmentType: "General",
          JobTitle: `${addJobData.jobTitle}_high5_${uid()}`,
          PassingScore: 45,
          createdFrom: "talentPool",
        };

        await post("/vetIntegration/createAssessment", skillsObj).then(
          (res) => {
            setLoading(false);
            if (
              res?.data?.message == "No question found for the selected skills"
            ) {
              toast.warning(
                "Oops! It seems there are no pre-defined recommended assessment available for selected skills, please create new assessment."
              );
              setVettingDetails(res?.data);
              setAddJobData({
                ...addJobData,
                createNewAssessment: false,
              });
            } else {
              setVettingDetails(res?.data);
              setAddJobData({
                ...addJobData,
                createNewAssessment: true,
              });
            }
          }
        );
      }
    } else {
      setAddJobData({
        ...addJobData,
        createNewAssessment: false,
      });
    }
  };
  const handleVetting = (e) => {
    if (e.target.checked) {
      setAddJobData({ ...addJobData, vettingRequired: true });
    } else {
      setAddJobData({
        ...addJobData,
        vettingRequired: false,
        createNewAssessment: false,
        reviewerEmail: "",
        reviewerFirstName: "",
        reviewerLastName: "",
      });
      setIsManualReviewer(false);
    }
  };

  const handleEdit = (item, index) => {
    setQuestionIndex(index);
    setquestionData(item);
    setCreateAssessment(true);
  };

  const deleteQuestion = (index) => {
    const updatedQuestions = [...questionList];
    updatedQuestions.splice(index, 1);
    setQuestionList(updatedQuestions);
  };

  const handleCancelAssessment = () => {
    setCreateAssessment(false);
    setquestionData({
      question: "",
      type: "",
      difficulty: difficulty,
      answer: [],
      options: ["", ""],
      skill: "",
      remark: "",
      imageUrl: "",
      modifiedBy: "",
      uploadedFileInfo: "",
      deleted: false,
      id: "",
    });
    setAddQuestionVal({
      question: "",
      type: "",
      skill: "",
      options: "",
      answer: "",
    });
  };
  return (
    <>
      {loading && <Loader />}
      <div
        className="card-body"
        style={{ pointerEvents: `${edit ? "none" : ""}` }}
      >
        {/* <div className="d-flex" style={{ textAlign: "end" }}>
          <div className="mx-4"></div>
          <OverlayTrigger placement="bottom" overlay={toolTip()}>
            <label
              className="ml-auto mr-4"
              style={{ color: "red", cursor: "pointer", padding: "0" }}
            >
              What to do...
            </label>
          </OverlayTrigger>
        </div> */}
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            {!next && (
              <>
                <div className="d-flex">
                  <p>
                    Please select how you want to create assessment to include
                    in the application form, subject to approval by High5 Admin within 48 hours </p>

                  <div
                    className="d-flex ml-auto mr-2"
                    style={{ textAlign: "end" }}
                  >
                    <OverlayTrigger placement="bottom" overlay={toolTip()}>
                      <label
                        className=""
                        style={{
                          color: "red",
                          cursor: "pointer",
                          padding: "0",
                        }}
                      >
                        What to do...
                      </label>
                    </OverlayTrigger>
                  </div>
                </div>

                {questionList?.length > 0 && !next && (
                  <>
                    <QuestionList
                      questionList={questionList}
                      handleEdit={handleEdit}
                      deleteQuestion={deleteQuestion}
                    />
                    <hr style={{marginTop:'0px'}}></hr>
                  </>
                )}

                <div className="m-2">
                  <input
                    type="radio"
                    name="daily"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-2"
                    onClick={() => setGenerateAssessment("Manual")}
                    checked={generateAssessment === "Manual"}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="daily"
                    onClick={() => setGenerateAssessment("Manual")}
                    //className="individualLabel"
                    for=""
                  >
                    Create Your Own Assessment
                  </a>
                </div>
                <div className="m-2">
                  <input
                    type="radio"
                    name="weekly"
                    style={{ width: "15px" }}
                    className="d-inline-block mr-2"
                    onClick={() => setGenerateAssessment("AI")}
                    checked={generateAssessment === "AI"}
                  />
                  <a
                    href="#"
                    style={{ color: "black" }}
                    className="font-regular"
                    name="weekly"
                    onClick={() => setGenerateAssessment("AI")}
                    //className="individualLabel"
                    for=""
                  >
                    Use High5 AI to Generate Assessment <span
                    className="ml-2"
                    style={{
                      border: "solid 1px gray",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    BETA
                  </span>
                  <i
                    className="far fa-info-circle mr-2 ml-2"
                    // style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="This is an AI feature and can provider inaccurate or biased responses"
                  ></i>
                  </a>
                </div>
                <hr></hr>
                <button
                  className="btn btn-sm btn-primary m-2"
                  
                  onClick={() => setNext(true)}
                >
                  <i className="fa fa-check mr-2 ml-2"></i>Proceed
                </button>
              </>
            )}

            {next && (
              <DisplayQuestions
                questionList={questionList}
                setCreateAssessment={setCreateAssessment}
                displayVetting={displayVetting}
                handleEdit={handleEdit}
                deleteQuestion={deleteQuestion}
                setCreateAssessmentAI={setCreateAssessmentAI}
                createAssessmentUsingAI={createAssessmentUsingAI}
                generateAssessment={generateAssessment}
                addJobData={addJobData}
              />
            )}
          </div>
        </div>
      </div>

      {showVettingModal && (
        <ShowVettingModal
          setScreeningQuestions={setScreeningQuestions}
          setAddJobData={setAddJobData}
          addJobData={addJobData}
          setShowVettingModal={setShowVettingModal}
          setDisplayVetting={setDisplayVetting}
        />
      )}
      <Modal show={showSkills} size="lg">
        <Modal.Header className="bg-gray4">
          <h6>Assessment</h6>
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowSkills(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <AssessmentPreview questionList={vettingDetails} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-dismiss="modal"
            onClick={() => setShowSkills(false)}
          >
            Cancel
          </button>
          {/* <button className="btn btn-sm btn-primary ">Edit</button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={createAssessment} size="lg">
        <Modal.Header className="bg-gray4">
          <h6>Create Question</h6>
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCancelAssessment()}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <CreateAssessment
            newAssessment={newAssessment}
            setNewAssessment={setNewAssessment}
            setquestionData={setquestionData}
            questionData={questionData}
            addJobData={addJobData}
            addQuestionVal={addQuestionVal}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-dismiss="modal"
            onClick={() => handleCancelAssessment()}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary "
            onClick={() => savedQuestions()}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default InterviewPlan;
