import {
  FETCH_MESSAGE_OVERVIEW_STATS_BEGIN,
  FETCH_MESSAGE_OVERVIEW_STATS_SUCCESS,
  FETCH_MESSAGE_OVERVIEW_STATS_FAILURE,
  FETCH_RECENT_MESSAGES_BEGIN,
  FETCH_RECENT_MESSAGES_SUCCESS,
  FETCH_RECENT_MESSAGES_FAILURE,
} from "../../action/communication/GetMessageOverviewStats";

const initialState = {
  messageOverViewDataLoading: false,
  messageOverViewData: null,
  recentMessageList: null,
  recentMessageListLoading: false,
};

const getMessageOverviewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_MESSAGE_OVERVIEW_STATS_BEGIN:
      return {
        ...state,
        messageOverViewDataLoading: true,
        messageOverViewData: null,
      };
    case FETCH_MESSAGE_OVERVIEW_STATS_SUCCESS:
      return {
        ...state,
        messageOverViewData: payload,
        messageOverViewDataLoading: false,
      };
    case FETCH_MESSAGE_OVERVIEW_STATS_FAILURE:
      return {
        ...state,
        messageOverViewData: payload,
        messageOverViewDataLoading: false,
      };

    case FETCH_RECENT_MESSAGES_BEGIN:
      return {
        ...state,
        recentMessageListLoading: true,
        recentMessageList: null,
      };
    case FETCH_RECENT_MESSAGES_SUCCESS:
      return {
        ...state,
        recentMessageList: payload,
        recentMessageListLoading: false,
      };
    case FETCH_RECENT_MESSAGES_FAILURE:
      return {
        ...state,
        recentMessageList: payload,
        recentMessageListLoading: false,
      };

    default:
      return state;
  }
};

export default getMessageOverviewReducer;
