import Validator from "email-validator";

export const editUserValidation = (form) => {
  const {
    lastName,
    email,
    firstName,
    zipcode,
    addressCity,
    addressState,
    country,
  } = form;
  const newErrors = {};

  if (!firstName || firstName === "")
    newErrors.firstName = "First Name  cannot be blank!";
  else if (!/^[A-Z a-z\s]+$/.test(firstName))
    newErrors.firstName = "Invalid First Name ";
  if (!lastName || lastName === "")
    newErrors.lastName = "Last Name  cannot be blank!";
  else if (!/^[A-Z a-z\s]+$/.test(lastName))
    newErrors.lastName = "Invalid Last Name ";
  if (!zipcode || zipcode === "") newErrors.zipcode = "ZipCode cannot be Blank";
  else if (!/^[0-9a-zA-Z ]+$/.test(zipcode))
    newErrors.zipcode = "Invalid ZipCode";
  if (!addressCity || addressCity === "")
    newErrors.addressCity = "City cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(addressCity))
    newErrors.addressCity = "Invalid City Name ";
  if (!addressState || addressState === "")
    newErrors.addressState = "State cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(addressState))
    newErrors.addressState = "Invalid State Name ";
  if (!country || country === "") newErrors.country = "Country cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(country))
    newErrors.country = "Invalid Country Name ";
  if (!email || email === "") newErrors.email = "Email id cannot be Blank";
  else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
  return newErrors;
};
