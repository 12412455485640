import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_SMS_DATA_BEGIN = "GET_SMS_DATA_BEGIN";
export const GET_SMS_DATA_SUCCESS = "GET_SMS_DATA_SUCCESS";
export const GET_SMS_DATA_FAILURE = "GET_SMS_DATA_FAILURE";

export const UPDATE_SMS_DATA_BEGIN = "UPDATE_SMS_DATA_BEGIN";
export const UPDATE_SMS_DATA_SUCCESS = "UPDATE_SMS_DATA_SUCCESS";
export const UPDATE_SMS_DATA_FAILURE = "UPDATE_SMS_DATA_FAILURE";

export const GET_SMS_CONTENT_BEGIN = "GET_SMS_CONTENT_BEGIN";
export const GET_SMS_CONTENT_SUCCESS = "GET_SMS_CONTENT_SUCCESS";
export const GET_SMS_CONTENT_FAILURE = "GET_SMS_CONTENT_FAILURE";

export const getSMSTemplates = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SMS_DATA_BEGIN,
    });
    const res = await get("/adminConsole/getSmsTemplates");
    console.log("response", res.data);
    if (res.status === 200) {
      dispatch({
        type: GET_SMS_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    console.log("error----------", error);
    dispatch({
      type: GET_SMS_DATA_FAILURE,
      payload: error?.response?.data || {},
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
export const getSMSContent = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SMS_CONTENT_BEGIN,
    });
    const res = await post("/msgtwoway/getSmsContent", data);
    if (res.status === 200) {
      dispatch({
        type: GET_SMS_CONTENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SMS_CONTENT_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
export const updateSMSTemplate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SMS_DATA_BEGIN,
    });
    const res = await post("/adminConsole/updateSmsContent", data);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_SMS_DATA_SUCCESS,
        payload: res.data,
      });
      toast.success("SMS Template updated successfully!");
    }
  } catch (error) {
    dispatch({
      type: UPDATE_SMS_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
