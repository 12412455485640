import Validator from "email-validator";

export const editClientValidation = (form, phoneValidation) => {
  const {
    lastname,
    email,
    name,
    tenant,
    tenanttype,
    zipCode,
    cityName,
    stateName,
    CountryName,
    mobilePhone,
  } = form;
  const newErrors = {};
  // name errors
  if (!tenant || tenant === "")
    newErrors.tenant = "Tenant Name cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(tenant))
    newErrors.tenant = "Invalid Tenant Name ";
  if (!name || name === "") newErrors.name = "First Name  cannot be blank!";
  else if (!/^[A-Z a-z\s]+$/.test(name)) newErrors.name = "Invalid First Name ";
  if (!tenanttype || tenanttype === "")
    newErrors.tenanttype = "Tenant Type cannot be Blank";
  if (!mobilePhone || mobilePhone === "")
    newErrors.mobilePhone = "*Required Field";
  else if (!phoneValidation) newErrors.mobilePhone = "Invalid Phone number";
  if (!lastname || lastname === "")
    newErrors.lastname = "Last Name  cannot be blank!";
  else if (!/^[A-Z a-z\s]+$/.test(lastname))
    newErrors.lastname = "Invalid Last Name ";
  if (!zipCode || zipCode === "") newErrors.zipCode = "ZipCode cannot be Blank";
  else if (!/^[0-9a-zA-Z ]+$/.test(zipCode))
    newErrors.zipCode = "Invalid ZipCode";
  if (!cityName || cityName === "") newErrors.cityName = "City cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(cityName))
    newErrors.cityName = "Invalid City Name ";
  if (!stateName || stateName === "")
    newErrors.stateName = "State cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(stateName))
    newErrors.stateName = "Invalid State Name ";
  if (!CountryName || CountryName === "")
    newErrors.CountryName = "Country cannot be Blank";
  else if (!/^[A-Z a-z\s]+$/.test(CountryName))
    newErrors.CountryName = "Invalid Country Name ";
  if (!email || email === "") newErrors.email = "Email id cannot be Blank";
  else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
  return newErrors;
};
