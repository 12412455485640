import React from "react";

const Experience = ({ status, details, experience, onCollapsibleClick }) => {
  return (
    <>
      {status !== "Invited" && details.yearsOfExperience && (
        <button
          onClick={() => {
            onCollapsibleClick("experience");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {!experience ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Experience</small>
          </span>
        </button>
      )}
      <div className={experience ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <div class="d-flex mb-2 pl-1 ml-4">
          <div>
            {details.yearsOfExperience === "0" && (
              <span>1-3 years (Junior Recruiter)</span>
            )}
            {details.yearsOfExperience === "0-1" && (
              <span>1-3 years (Junior Recruiter)</span>
            )}
            {details.yearsOfExperience === "1-3" && (
              <span>1-3 years (Junior Recruiter)</span>
            )}
            {details.yearsOfExperience === "1-5" && (
              <span>3-5 years (Midlevel Recruiter)</span>
            )}
            {details.yearsOfExperience === "3-5" && (
              <span>3-5 years (Midlevel Recruiter)</span>
            )}
            {details.yearsOfExperience === "5-10" && (
              <span>5+ years (Senior Recruiter)</span>
            )}
            {details.yearsOfExperience === "10-20" && (
              <span>5+ years (Senior Recruiter)</span>
            )}
            {details.yearsOfExperience === "5+" && (
              <span>5+ years (Senior Recruiter)</span>
            )}
            {details.yearsOfExperience === "7+" && (
              <span>5+ years (Senior Recruiter)</span>
            )}
            {details.yearsOfExperience === "20+" && (
              <span>5+ years (Senior Recruiter)</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
