import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../loader/Loader";
import {
  getCandidateName,
  getUserEmail,
} from "../../../../../service/AuthService";

const DeactivateJob = (props) => {
  const { jobData, deactivate, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const handleDeactivate = async () => {
    const payload = {
      jobId: jobData.jobId,
      jobTitle: jobData.jobTitle,
      jobStatus: jobData.jobStatus,
      prevJobStatus: jobData.prevJobStatus,
      name: getCandidateName(),
      email: getUserEmail(),
      isPublished: jobData.isPublished,
    };
    try {
      setLoading(true);
      const res = await post("/job/deactivatejob", payload);
      if (res.status === 200) {
        toast.success("Job Deactivated Successfully");
        handleClose(true);
        setLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred");
      handleClose(false);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal show={deactivate} onHide={() => handleClose(false)} size="delete">
        <Modal.Body>
          <div>
            <div className="bl-modal-body  text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                You're about to deactivate this job, and all of its data.
                <br />
                {props.jobData.jobTitle}
              </p>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  data-dismiss="modal"
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="close p-0 bl-modal-close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleClose(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleDeactivate}
                >
                  Confirm Deactivate
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeactivateJob;
