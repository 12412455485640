// #####################
// Routes List
// #####################

const previlageConfig = {};

const common = [
  {
    component: "Joblist",
    url: "/requests",
    title: "Request List",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "ViewJobDetails",
    url: "/requests/:Id",
    title: "View Job Details",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "TagsTemplate",
    url: "/tagsTemplate",
    title: "Tags Template",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "CommonTalentList",
    url: "/talents",
    title: "commonTalent",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "TalentPool",
    url: "/talentcommunity?view=${viewParam}",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "PoolCard",
    url: "/talentcommunity/:communityId",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "PoolCard",
    url: "/talentcommunity/:communityId/:Tab",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "AdminConsole",
    url: "/adminConsole",
    title: "AdminConsole",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "SMSTemplate",
    url: "/smsTemplate",
    title: "SMS Template",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "EmailTemplate",
    url: "/emailTemplate",
    title: "Email Template",
  },
  {
    component: "JobTemplate",
    url: "/jobTemplate",
    title: "JobTemplate",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "Profile",
    url: "/myprofile",
    title: "Profile",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
];

const dashboard = {
  SuperAdmin: {
    component: "SuperAdminDashboard",
    url: "/",
    title: "SuperAdmin",
  },
  Recruiter: {
    component: "RecruiterDashboard",
    url: "/",
    title: "Recruiter",
  },
  CommunityManager: {
    component: "CommunityManagerDashboard",
    url: "/",
    title: "Community Manager Dashboard",
  },
  HiringManager: {
    component: "HiringManagerDashboard",
    url: "/",
    title: "HiringManager",
  },
};

export { previlageConfig, dashboard, common };
