import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginData: [],
  loading: false,
  error: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    fetchLoginLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
        loginData: [],
        error: false,
      };
    },
    fetchLogin: (state, action) => {
      return {
        ...state,
        loginData: action.payload,
        loading: false,
        error: false,
      };
    },
    fetchLoginError: (state, action) => {
      return {
        ...state,
        error: action.payload,
        loginData: [],
        loading: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { fetchLoginLoading, fetchLogin, fetchLoginError } =
  loginSlice.actions;

export default loginSlice.reducer;
