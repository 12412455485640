import React from "react";
import Modal from "react-bootstrap/Modal";

const ApproveRecruiterModal = ({ approveModal, setApproveModal, approve }) => {
  return (
    <Modal show={approveModal}>
      <Modal.Header>
        <h6>Approve Gig Recruiter</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setApproveModal(false);
          }}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-3">
          <div className="text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i className="fas fa-exclamation-triangle fa-fw"></i>
            </div>
          </div>
          <p className="text-center lead mt-3">
            Are you sure you want to approve this Recruiter?
          </p>
          <div className="text-center my-4">
            <button
              type="button"
              className="btn btn-secondary mr-3"
              data-dismiss="modal"
              onClick={() => {
                setApproveModal(false);
              }}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={approve}>
              Confirm Approve
            </button>
          </div>
          <div className="form-floating mt-2"></div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveRecruiterModal;
