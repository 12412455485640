import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ToolTip = (props) => {
  const { errorInfo, id } = props;

  return (
    <>
      {errorInfo && (
        <span data-tip data-for={`my-tooltip${id}`}>
          <i className="fa fa-info-circle me-2 ml-2 font-brandBlue"></i>
        </span>
      )}
      {errorInfo && (
        <span className="bold">
          <ReactTooltip id={`my-tooltip${id}`}>
            {errorInfo &&
              errorInfo.map((item, value) => {
                return <li>{item}</li>;
              })}
          </ReactTooltip>
        </span>
      )}
    </>
  );
};
export default ToolTip;
