import { combineReducers } from "redux";
import loginReducer from "../reducer/auth/Login";
import getDashboardCountReducer from "./dashboard/superadmin/Dashboard";
import getClientUserDataReducer from "./dashboard/superadmin/client/ClientUserData";
import getJobSummaryReducer from "./dashboard/superadmin/client/JobSummary";
import getTalentSummaryReducer from "./dashboard/superadmin/client/TalentSummary";
import getTalentListReducer from "./talent/TalentList";
import getTalentEmailReducer from "./talent/GetTalentEmail";
import addCandidateReducer from "./talent/AddTalent";
import getTalentCountReducer from "./talent/TalentCount";
import getTalentCommunityReducer from "./talentcommunity/TalentCommunity.js";
import getClientNameReducer from "./common/ClientName.js";
import getHmDetailsReducer from "./clientadmin/HiringManager.js";
import communicationReducer from "./communication/Communication.js";
import tagReducer from "./tagmanagement/getAllTags";
import adminTagReducer from "./tagmanagement/adminFetchAllTags";
import getRecruiterUserDataReducer from "./dashboard/superadmin/gigrecruiter/GigRecruiter.js";
import getRegistrationOverviewReducer from "./dashboard/superadmin/gigrecruiter/RegistrationOverview.js";
import getLoginOverviewReducer from "./dashboard/superadmin/gigrecruiter/LoginOverview.js";
import getRecruitementPipelineReducer from "./dashboard/superadmin/gigrecruiter/RecruitmentPipeline.js";
import getRecruiterForCuratorReducer from "./curator/AssignCurator.js";
import getUpdateCuratorReducer from "./curator/UpdateCurator.js";
import getTalentRecruiterReducer from "./filter/talent/TalentRecruiterFilter.js";
import getRecruiterFilterReducer from "./filter/recruiter/RecruiterFilter.js";
import dropdownValuesReducer from "./common/Dropdown.js";
import joblistReducer from "./job/Joblist.js";
import joblistCountReducer from "./job/JoblistHeaderCounts.js";
import getJobFilterReducer from "./filter/job/JobFilter.js";
import jobDetailsReducer from "./job/JobDetails.js";
import getRecruiterListReducer from "./common/Recruiter.js";
import getHigh5UsersReducer from "./dashboard/superadmin/high5users/High5Users.js";
import getCuratorClientDataReducer from "./curationtasks/GetCuratorClients.js";
import getrecruiterDashboardReducer from "./dashboard/superadmin/recruiter/RecruiterDashboard.js";
import getTalentSubPoolListReducer from "./talent/TalentSubPoolList";
import getCuratorDetailsReducer from "./curator/Curators.js";
import talentDetailsReducer from "./talent/TalentDetails.js";
import getCareerHomeReducer from "./disposition/job/career/CareerHome.js";
import getTalentNameReducer from "./filter/talent/TalentNameFilter.js";
import getTalentStateReducer from "./filter/talent/TalentStateFilter.js";
import getTalentCountryReducer from "./filter/talent/TalentCountryFilter.js";
import getTalentJobTitleReducer from "./filter/talent/TalentJobTitleFilter.js";
import getTalentSourceReducer from "./filter/talent/TalentSourceFilter.js";
import getTalentTagReducer from "./filter/talent/TalentTagFilter.js";
import approveRejectCuratorReducer from "./curator/ApproveRejectCurator.js";
import communityDispositionCount from "./disposition/talentcommunity/CommunityDispositionCount.js";
import communityDisposition from "./disposition/talentcommunity/CommunityDispositionDetails.js";
import communityDispositionDisqualify from "./disposition/talentcommunity/CommunityDisqualifyDisposition.js";
import getEmailOverviewReducer from "./communication/GetEmailOverviewStats.js";
import getMessageOverviewReducer from "./communication/GetMessageOverviewStats.js";
import countryData from "./talent/TalentCountryFilter";
import stateData from "./talent/TalentStateFilter";
import jobDispositionCountReducer from "./disposition/job/jobdetails/JobDetails.js";
import smsTemplateReducer from "./adminconsole/SMSTemplate.js";
import emailTemplateReducer from "./adminconsole/EmailTemplate.js";
import addJob from "./job/AddJob";
import JobList from "./job/Joblist.js";
import jobDispositionReducer from "./disposition/job/jobdetails/JobDetails.js";
import talentHistoryReducer from "./talent/GetTalentHistory.js";
import profileInfoReducer from "./profile/Profile.js";
import candidateDetailsReducer from "./candidate/CandidateDetails";
import getTenantReducer from "./tenant/getTenant";

export default combineReducers({
  loginReducer,
  getDashboardCountReducer,
  getClientUserDataReducer,
  getJobSummaryReducer,
  getTalentSummaryReducer,
  getTalentListReducer,
  getTalentEmailReducer,
  addCandidateReducer,
  getTalentCountReducer,
  getTalentCommunityReducer,
  getClientNameReducer,
  getHmDetailsReducer,
  communicationReducer,
  tagReducer,
  adminTagReducer,
  getRecruiterUserDataReducer,
  getRegistrationOverviewReducer,
  getLoginOverviewReducer,
  getRecruitementPipelineReducer,
  getRecruiterForCuratorReducer,
  getUpdateCuratorReducer,
  getTalentRecruiterReducer,
  getRecruiterFilterReducer,
  dropdownValuesReducer,
  joblistReducer,
  joblistCountReducer,
  getJobFilterReducer,
  jobDetailsReducer,
  getRecruiterListReducer,
  getHigh5UsersReducer,
  getrecruiterDashboardReducer,
  getCuratorClientDataReducer,
  getTalentSubPoolListReducer,
  getCuratorDetailsReducer,
  talentDetailsReducer,
  getCareerHomeReducer,
  communityDispositionCount,
  getTalentNameReducer,
  getTalentStateReducer,
  getTalentCountryReducer,
  getTalentJobTitleReducer,
  getTalentSourceReducer,
  getTalentTagReducer,
  approveRejectCuratorReducer,
  communityDisposition,
  communityDispositionDisqualify,
  getEmailOverviewReducer,
  getMessageOverviewReducer,
  countryData,
  stateData,
  jobDispositionCountReducer,
  smsTemplateReducer,
  emailTemplateReducer,
  addJob,
  JobList,
  jobDispositionReducer,
  talentHistoryReducer,
  profileInfoReducer,
  candidateDetailsReducer,
  getTenantReducer,
});
