import {
  GET_COMMON_DROPDOWN_BEGIN,
  GET_COMMON_DROPDOWN_SUCCESS,
  GET_COMMON_DROPDOWN_FAILURE,
  GET_CANDIDATE_DROPDOWN_BEGIN,
  GET_CANDIDATE_DROPDOWN_SUCCESS,
  GET_CANDIDATE_DROPDOWN_FAILURE,
} from "../../action/common/Dropdown";

const initialState = {
  dropdownLoading: false,
  dropdownData: [],
  candidateDropdownLoading: false,
  candidateDropdownData: null,
};

const dropdownValuesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMON_DROPDOWN_BEGIN:
      return {
        ...state,
        dropdownData: [],
        dropdownLoading: true,
      };
    case GET_COMMON_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdownData: payload,
        dropdownLoading: false,
      };
    case GET_COMMON_DROPDOWN_FAILURE:
      return {
        ...state,
        dropdownData: payload,
        dropdownLoading: false,
      };
    case GET_CANDIDATE_DROPDOWN_BEGIN:
      return {
        ...state,
        candidateDropdownLoading: true,
      };
    case GET_CANDIDATE_DROPDOWN_SUCCESS:
      return {
        ...state,
        candidateDropdownData: payload,
        candidateDropdownLoading: false,
      };
    case GET_CANDIDATE_DROPDOWN_FAILURE:
      return {
        ...state,
        candidateDropdownLoading: false,
      };
    default:
      return state;
  }
};

export default dropdownValuesReducer;
