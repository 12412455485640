import React, { useEffect, useRef, useState } from "react";
import {
  getTenantType,
  getUserRole,
  isCurator,
} from "../../../../service/AuthService";
// import ReminderBroadcast from "../../../commoncomponents/talentcommunity/ReminderBroadcast";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import Loader from "../../../common/loader/Loader";
// import UnsubscribeModal from "../../../talent/talentcommunity/UnsubscribeModal";
// import SMSModal from "../../../talent/talentcommunity/SMSModal";
// import EmailModal from "../../../talent/talentcommunity/EmailModal";
// import TagModal from "../../../talent/talentcommunity/TagModal";
// import SubCommModal from "../../../talent/talentcommunity/SubCommModal";
import VettingInviteModal from "./VettingInviteModal";
import ReadyToHireModal from "./ReadyToHireModal";
// import MoveToJob from "../../../talent/talentcommunity/MoveToJob";

const DispositionHeader = (props) => {
  const {
    view,
    setView,
    isFrom,
    jobData,
    dispositionDetails,
    filterData,
    setFilterData,
    dispositionCount,
    dispositionCountLoading,
    selectedTalents,
    setSelectedTalents,
    fetchData,
    filterLength,
    Tab,
  } = props;

  const numberFormatter = new Intl.NumberFormat("en-US");

  // const [showFilter, setShowFilter] = useState(false);
  const [showBroadcast, setShowBroadcast] = useState(false);
  const { poolId } = useParams();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [selectedYesOrNoStatus, setSelectedYesOrNoStatus] = useState([]);
  const [selectedNumericAnswers, setSelectedNumericAnswers] = useState([]);
  const [selectedArrayAnswers, setSelectedArrayAnswers] = useState([]);
  const [selectedTextAnswers, setSelectedTextAnswers] = useState([]);
  const [languagePreference, setLanguagePreference] = useState([]);
  const [toggleActions, setToggleActions] = useState(false);
  const [showjobModal, setShowJobModal] = useState(false);
  const [showSubCommModal, setShowSubCommModal] = useState(false);
  const [showVettingModal, setShowVettingModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showReadyToHireModal, setShowReadyToHireModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const handleTab = (isRecruiter, status) => {
    switch (status) {
      case 38:
        setView("automatched");
        break;
      case 33:
        setView("invited");
        break;
      case 42:
        setView("submitted");
        break;

      default:
        setView("automatched");
        break;
    }
  };

  useEffect(() => {
    const role = getUserRole();
    const isRecruiter = role === "Recruiter";
    if (Tab) {
      handleTab(isRecruiter, Tab * 1);
    } else {
      setView("automatched");
    }
  }, [Tab]);

  useEffect(() => {
    clearFilter();
  }, [view]);

  const clearFilter = () => {
    setSelectedYesOrNoStatus([]);
    setSelectedArrayAnswers([]);
    setSelectedTextAnswers([]);
    setLanguagePreference([]);
    setSelectedNumericAnswers([]);
    setFilterData(dispositionDetails);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const totalCountExcludingDisqualified = dispositionCount?.reduce(
      (total, current) => {
        if (
          current.Status !== "Disqualified" &&
          current.Status !== "Qualified"
        ) {
          total += current.Count;
        }
        return total;
      },
      0
    );
    setTotalCount(totalCountExcludingDisqualified);
  }, [dispositionCount]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };

  const countHandler = (type) => {
    if (type === "Automatched") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Invite") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Qualified") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Submitted") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Vetting") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Shortlist") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Hired") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Offered") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    } else if (type === "Disqualified") {
      const count = dispositionCount?.filter((e) => e.Status === type);
      // return new Intl.NumberFormat()?.format(count[0]?.Count ?? 0);
      return 0
    }
  };

  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  const areAllUnsubbed = () => {
    return selectedTalents.every((x) => x.newSletter === "Unsubscribed");
  };

  const handleAction = (action) => {
    if (action === "inviteToJob") setShowJobModal(true);
    if (action === "inviteToSubComm") setShowSubCommModal(true);
    if (action === "inviteToVetting") setShowVettingModal(true);
    if (action === "readyToHire") setShowReadyToHireModal(true);
    if (action === "bulkTag") setShowTagModal(true);
    if (action === "email") setShowEmailModal(true);
    if (action === "sms") setShowSMSModal(true);
    if (action === "unsubscribe") setShowConfirmationModal(true);
    toggleActionsDropdown();
  };
  return (
    <>
      {dispositionCountLoading && <Loader />}
      <div className="card-body pb-0 d-flex flex-column justify-content-between">
        <div className="d-flex justify-content-between">
          <p className="px-1">
            Active Candidates ({numberFormatter.format(totalCount)}){" "}
          </p>

          <div className="d-block">
            {jobData?.vettingDetails == null &&
              (getUserRole() === "CustomerSuccess" ||
                (getTenantType() == 1 && isCurator())) &&
              view === "submitted" &&
              countHandler("Submitted") > 0 && (
                <button
                  className="btn btn-sm btn-primary mr-2"
                  onClick={() => handleAction("inviteToVetting")}
                >
                  Create Vetting
                </button>
              )}
            {(getUserRole() === "CustomerSuccess" ||
              (getTenantType() == 1 && isCurator())) &&
              (jobData?.vettingDetails == null
                ? view === "submitted"
                : view === "hired") && (
                <div className="dropdown" ref={dropdownRef}>
                  <button
                    className="btn btn-sm dropdown-toggle"
                    type="button"
                    onClick={toggleActionsDropdown}
                    style={{
                      zIndex: 90,
                    }}
                    disabled={areAllUnsubbed() || selectedTalents.length === 0}
                  >
                    Actions
                  </button>
                  <div>
                    {toggleActions && (
                      <div
                        className="dropdown-menu"
                        style={{
                          display: "revert",
                          right: "-4px",
                          top: "32px",
                          zIndex: 89,
                        }}
                      >
                        <div>
                          <button
                            onClick={() => handleAction("inviteToJob")}
                            className="dropdown-item btn-xs"
                          >
                            Move to Job
                          </button>
                          <hr className="p-0 m-1" />
                        </div>{" "}
                        {/* <div>
                          <button
                            onClick={() => handleAction("inviteToSubComm")}
                            className="dropdown-item btn-xs"
                          >
                            Move to Community
                          </button>
                          <hr className="p-0 m-1" />
                        </div>{" "} */}
                        <div>
                          <button
                            onClick={() => handleAction("bulkTag")}
                            className="dropdown-item btn-xs"
                          >
                            {" "}
                            Bulk Tag
                          </button>
                          <hr className="p-0 m-1" />
                        </div>{" "}
                        {/* <div>
                          <button
                            onClick={() => handleAction("email")}
                            className="dropdown-item btn-xs"
                          >
                            {" "}
                            Email/SMS
                          </button>
                          <hr className="p-0 m-1" />
                        </div> */}
                        {/* <div>
                            <button
                              onClick={() => handleAction("unsubscribe")}
                              className="dropdown-item btn-xs"
                            >
                              {" "}
                              Move to Unsubscribed
                            </button>
                          </div> */}
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>

          {view === "vetting" &&
            (jobData?.readyToHireQA === null ||
              jobData?.readyToHireQA === "[]") &&
            countHandler("Vetting") > 0 &&
            (getUserRole() === "CustomerSuccess" ||
              (getTenantType() == 1 && isCurator())) && (
              <button
                className="btn btn-sm btn-primary mr-2"
                onClick={() => handleAction("readyToHire")}
              >
                Ready To Hire screening
              </button>
            )}
        </div>
        <ul className="nav d-flex nav-tabs " style={{ overflowX: "auto" }}>
          {
            <li className="nav-item w-25 d-flex flex-column justify-content-center">
              <button
                type="button"
                className={
                  view == "automatched"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => setView("automatched")}
              >
                <span className="tag mb-1">{countHandler("Automatched")}</span>
                <div>Automatched</div>
              </button>
            </li>
          }
          {/* {getUserRole() == "Recruiter" &&
            getUserRole() !== "CommunityManager" && (
              <li className="nav-item w-25 d-flex flex-column justify-content-center">
                <button
                  type="button"
                  className={
                    view == "qualified"
                      ? "nav-link pb-3 active"
                      : "nav-link pb-3"
                  }
                  onClick={() => setView("qualified")}
                >
                  <span className="tag mb-1">{countHandler("Qualified")}</span>
                  <div>Invited</div>
                </button>
              </li>
            )} */}

          {
            <li className="nav-item w-25 d-flex flex-column justify-content-center">
              <button
                type="button"
                className={
                  view == "invite" ? "nav-link pb-3 active" : "nav-link pb-3"
                }
                onClick={() => setView("invite")}
              >
                <span className="tag mb-1">{countHandler("Invite")}</span>
                <div>Invited</div>
              </button>
            </li>
          }
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "submitted" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("submitted")}
            >
              <span className="tag mb-1">
                {countHandler("Submitted")}
                {/* {countHandler("Submitted") > 0 && <span className="jewel" />} */}
              </span>
              <div>Engaged</div>
            </button>
          </li>
          {jobData?.vettingDetails && (
            <li className="nav-item w-25 d-flex flex-column justify-content-center">
              <button
                type="button"
                className={
                  view == "vetting" ? "nav-link pb-3 active" : "nav-link pb-3"
                }
                onClick={() => setView("vetting")}
              >
                <span className="tag mb-1">
                  {countHandler("Vetting")}
                  {/* {countHandler("Vetting") > 0 && <span className="jewel" />} */}
                </span>
                <div>Vetted</div>
              </button>
            </li>
          )}
          {(jobData?.readyToHireQA !== null ||
            jobData?.readyToHireQA !== "[]") &&
            jobData?.vettingDetails && (
              <li className="nav-item w-25 d-flex flex-column justify-content-center">
                <button
                  type="button"
                  className={
                    view == "hired" ? "nav-link pb-3 active" : "nav-link pb-3"
                  }
                  onClick={() => setView("hired")}
                >
                  <span className="tag mb-1">{countHandler("Hired")}</span>
                  <div>Ready To Hire</div>
                </button>
              </li>
            )}
        </ul>
        {showBroadcast && (
          <>
            {/* <ReminderBroadcast
              showModal={showBroadcast}
              setShowModal={setShowBroadcast}
              dispositionDetails={dispositionDetails}
              jobData={jobData}
            /> */}
          </>
          // <ScreeningFilter
          //   jobData={jobData}
          //   handleFilter={handleFilter}
          //   setShowFilter={setShowFilter}
          //   selectedYesOrNoStatus={selectedYesOrNoStatus}
          //   setSelectedYesOrNoStatus={setSelectedYesOrNoStatus}
          //   dispositionDetails={dispositionDetails}
          //   setFilterData={setFilterData}
          //   selectedNumericAnswers={selectedNumericAnswers}
          //   setSelectedNumericAnswers={setSelectedNumericAnswers}
          //   setSelectedTextAnswers={setSelectedTextAnswers}
          //   selectedTextAnswers={selectedTextAnswers}
          //   languagePreference={languagePreference}
          //   setLanguagePreference={setLanguagePreference}
          //   setSelectedArrayAnswers={setSelectedArrayAnswers}
          //   selectedArrayAnswers={selectedArrayAnswers}
          // />
        )}
        {/* {showjobModal && (
          <MoveToJob
            showjobModal={showjobModal}
            setShowJobModal={setShowJobModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
            tenantId={jobData?.fk_tenant}
            jobData={jobData}
          />
        )}{" "} */}
        {/* {showSubCommModal && (
          <SubCommModal
            showjobModal={showSubCommModal}
            setShowJobModal={setShowSubCommModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
            tenantId={jobData?.fk_tenant}
            jobData={jobData}
          />
        )}{" "} */}
        {/* {showTagModal && (
          <TagModal
            showjobModal={showTagModal}
            setShowJobModal={setShowTagModal}
            selectedTalents={selectedTalents}
            fetchData={fetchData}
            setSelectedTalents={setSelectedTalents}
          />
        )}{" "} */}
        {/* {showEmailModal && (
          <EmailModal
            showjobModal={showEmailModal}
            setShowJobModal={setShowEmailModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
          />
        )}{" "} */}
        {/* {showSMSModal && (
          <SMSModal
            showjobModal={showSMSModal}
            setShowJobModal={setShowSMSModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
          />
        )} */}
        {/* {showConfirmationModal && (
          <UnsubscribeModal
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            selectedTalents={selectedTalents}
            fetchData={fetchData}
            setSelectedTalents={setSelectedTalents}
          />
        )} */}
        {showVettingModal && (
          <VettingInviteModal
            showVettingModal={showVettingModal}
            setShowVettingModal={setShowVettingModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
            jobData={jobData}
            view={view}
          />
        )}
        {showReadyToHireModal && (
          <ReadyToHireModal
            showReadyToHireModal={showReadyToHireModal}
            setShowReadyToHireModal={setShowReadyToHireModal}
            selectedTalents={selectedTalents}
            setSelectedTalents={setSelectedTalents}
            jobData={jobData}
            view={view}
          />
        )}
      </div>
    </>
  );
};

export default DispositionHeader;
