import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaPaperPlane } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FetchEmailsByUserToCandidate } from "../../../../action/communication/Communication";
import { getCandidateId, isCurator } from "../../../../service/AuthService";
import Loader from "../../../loader/Loader";
// import ModalView from "../../commoncomponents/modal/Modal";
import ModalView from "../../../modal/ModalView";
import Pagination from "../../../common/pagination/Pagination";

const Emails = ({
  handleCommunicationModal,
  candidateData,
  poolId,
  setIsCandidateFrom,
  setOpenFullViewEmail,
  openFullViewEmail,
}) => {
  const dispatch = useDispatch();
  const {
    sendEmailCandidateLoading,
    sendEmailCandidateList,
    sendEmailCandidateListCount,
  } = useSelector(
    (state) => ({
      sendEmailCandidateLoading:
        state.communicationReducer.sendEmailCandidateLoading,
      sendEmailCandidateList:
        state.communicationReducer.sendEmailCandidateList?.result,
      sendEmailCandidateListCount:
        state.communicationReducer.sendEmailCandidateList?.total,
    }),
    shallowEqual
  );

  const [selectedMail, setSelectedMail] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [dispatch, candidateData?.email, poolId, candidateData?.jobId]);

  const fetchData = (currentPage) => {
    let payload = {
      userId: getCandidateId(),
      currentPage: currentPage,
      candidateEmail: candidateData?.email,
      ...(poolId ? { poolId } : { jobId: candidateData?.jobId }),
    };
    dispatch(FetchEmailsByUserToCandidate(payload));
  };
  // Group emails by date
  // const groupedEmails =
  //   sendEmailCandidateList &&
  //   sendEmailCandidateList.reduce((acc, email) => {
  //     const date = moment(email?.createdDateTime).format("MM/DD/YYYY");
  //     if (!acc[date]) {
  //       acc[date] = [];
  //     }
  //     acc[date].push(email);
  //     return acc;
  //   }, {});

  // Function to determine the role based on tenant type
  const getSenderRole = (role, tenantName) => {
    const curatorCheck = isCurator();

    if (role === "CustomerSuccess") {
      return "High5 Admin";
    } else if (role === "Recruiter" && !curatorCheck) {
      return "Recruiter";
    } else if (role === "Recruiter" && curatorCheck) {
      return tenantName && tenantName.includes("GIG -")
        ? "Gig Curator"
        : "Client Curator";
    } else if (role === "HiringManager") {
      return "Hiring Manager";
    } else {
      return role;
    }
  };

  // Function to modify the JOIN HIGH5 TODAY button to a "Click Here" text
  // const replaceJoinHigh5Button = (content) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(content, "text/html");

  //   // Select all anchor tags in the content
  //   const allButtons = doc.querySelectorAll("a");

  //   // Loop through each anchor tag
  //   allButtons.forEach((button) => {
  //     // Modify the button's text to "Click Here"
  //     button.textContent = "Click Here";

  //     // Style it to look like regular text
  //     button.style.textDecoration = "none";  // Remove underline
  //     button.style.color = "#555555"            // Set color to black (or any other styling you need)
  //     button.style.pointerEvents = "none";   // Disable clicks
  //     button.style.cursor = "default";       // Change cursor to default to indicate no action
  //   });

  //   return doc.body.innerHTML; // Return the modified HTML content
  // };

  return (
    <>
      {sendEmailCandidateLoading && <Loader />}
      <div
        className="d-flex justify-content-start align-content-start"
        style={{
          marginBottom: "10px",
          backgroundColor: "#fff",
        }}
      >
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => {
            setIsCandidateFrom("email");
            handleCommunicationModal();
          }}
        >
          <FaPaperPlane size={14} className="mr-2" />
          Compose Email
        </button>
      </div>

      {sendEmailCandidateList && sendEmailCandidateList?.length === 0 && (
        <div style={dateHeadingStyle}>
          <div className="text-center p-5">
            <div className="avatar avatar-lg">
              <i aria-hidden="true" className="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No records found</div>
          </div>
        </div>
      )}
      <div className="w-100">
        <div className="email-date d-flex flex-column gap-2 mb-5">
          {sendEmailCandidateList?.map((email, index) => (
            <div key={index}>
              <div style={dateHeadingStyle}></div>
              <span>
                <small className="tag tag-blue3">
                  Sent On :{" "}
                  {moment(email?.createdDateTime).format("MM/DD/YYYY")}
                </small>
                <small className="tag tag-blue3">
                  Sent By:{" "}
                  {email.senderName && email.senderName !== " "
                    ? `${email.senderName} (${getSenderRole(
                        email.rolename,
                        email.tenantname
                      )})`
                    : "Auto-generated"}
                </small>

                {email.poolname ? (
                  <small className="tag tag-blue3">
                    Community : {email?.poolname}
                  </small>
                ) : email.jobTitle ? (
                  <small className="tag tag-blue3">
                    Job : {email?.jobTitle}
                  </small>
                ) : null}
              </span>
              <div className="email" style={sentEmailStyle}>
                <div className="email-header" style={emailHeaderStyle}>
                  <span>{email?.MailSubject?.replace(/<[^>]*>/g, "")}</span>
                  <span style={emailHeaderTimestampStyle}>
                    {moment(email?.createdDateTime).format("hh:mm A")}
                  </span>
                  <td>
                    <span className="font-primary font-regular">
                      <button
                        style={{ color: "grey !important" }}
                        onClick={() => {
                          setOpenFullViewEmail(true);
                          setSelectedMail(email);
                        }}
                        className="btn btn-text"
                        title="View Email"
                      >
                        <i className="fas fa-envelope"></i>
                      </button>
                    </span>
                  </td>
                </div>
              </div>
            </div>
          ))}
        </div>
        {sendEmailCandidateList && sendEmailCandidateList?.length > 0 && (
          <div className="mb-4">
            <hr className="m-0" />
            <Pagination
              currentPage={currentPage}
              totalItems={sendEmailCandidateListCount}
              itemsPerPage={5}
              onPageChange={handlePageChange}
              limit={5}
            />
          </div>
        )}
      </div>
      {openFullViewEmail && (
        <ModalView
          modalSize={"lg"}
          modalTitle={"View Email"}
          isModalOpen={openFullViewEmail}
          setModalClose={setOpenFullViewEmail}
          isFrom="DispositionOverview"
          modalFor="preview"
          data={{
            subject: selectedMail.MailSubject.replace(/\s*\([^)]*\)\s*$/, ""),
            to: selectedMail?.MailTo.toString().slice(2, -2),
            content: selectedMail?.MailContent,
            footer: selectedMail?.footer || "",
          }}
        />
      )}
    </>
  );
};

// Inline CSS objects
const emailHeaderStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  cursor: "pointer",
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  gap: "10px",
  alignItems: "center",
};

const emailHeaderTimestampStyle = {
  fontSize: "12px",
  color: "#6c757d",
};

const emailContentStyle = {
  padding: "10px",
  borderRadius: "5px",
};

const sentEmailStyle = {
  // Border styles or other customizations
};

const dateHeadingStyle = {
  borderRadius: "0px",
  padding: "5px",
  marginBottom: "10px",
  textAlign: "center",
};

export default Emails;
