import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SignUp from "./SignUp";

const JobDetails = ({
  job,
  setSocialMediaApply,
  setLoginData,
  isLoginModal,
}) => {
  const { recruiterid } = useParams();

  return (
    <>
      <div className="main_job_requirements_part_1a mt-2 mb-2 ml-3">
        <span>
          <h6>Job Requirement</h6>
        </span>
        <p dangerouslySetInnerHTML={{ __html: job.jobDescription }}></p>
      </div>
      <div className="main_job_requirements_part_1a ml-3">
        <span>
          <h6>Required Skills</h6>
        </span>
        <div className="main_container-key_points mt-2">
          {job?.primarySkills &&
            JSON.parse(job?.primarySkills)?.map((item, i) => (
              <div className="main_container-key_points_style" key={i}>
                <span className="main_container-key_points_style_text">
                  {item}
                </span>
              </div>
            ))}
        </div>
      </div>
      {sessionStorage.getItem("token") == null && (
        <SignUp
          setSocialMediaApply={setSocialMediaApply}
          setLoginData={setLoginData}
          isLoginModal={isLoginModal}
        />
      )}
      <div className="main_footer-heading ml-3 mt-3">
        <span>We’re always on the lookout for Talent</span>
        <div className="main_frame">
          <div className="main_footer-sub_heading">
            <span>
              If you are thinking about a future with us, sign up to stay
              connected and be informed of any new opportunities
            </span>
          </div>
          <div className="">
            {!sessionStorage.getItem("token") && (
              <button
                className="main_footer-button_style"
                onClick={() => {
                  window.open(
                    `/careers/8/recruiter/${recruiterid}/jointalentpool`,
                    "_blank"
                  );
                }}
              >
                <span>Talent Pool</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
