import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ActivateJob from "./ActivateJob";
import { getJobDetails } from "../../../../../action/job/JobDetails";

const Index = (props) => {
  const dispatch = useDispatch();
  const [activate, setActivate] = useState(false);

  const handleClose = (value) => {
    setActivate(false);
    if (value) {
      const payload = {
        jobId: props.jobData.jobId,
      };
      dispatch(getJobDetails(payload));
    }
  };

  return (
    <>
      <div
        className="dropdown px-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Activate"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setActivate(true)}
        style={{ cursor: "pointer", fontSize: "large" }}
      >
        <i className="fal fa-key fa-fw"></i>
      </div>
      {activate && (
        <ActivateJob
          activate={activate}
          handleClose={handleClose}
          jobData={props.jobData}
        />
      )}
    </>
  );
};

export default Index;
