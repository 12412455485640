import {
  GET_USER_DATA_BEGIN,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  GET_USER_DATA_COUNT_BEGIN,
  GET_USER_DATA_COUNT_SUCCESS,
  GET_USER_DATA_COUNT_FAILURE,
} from "../../../../action/dashboard/superadmin/gigrecruiter/GigRecruiter";

const initialState = {
  loading: true,
  countLoading: true,
  recruiterData: [],
  userDataCount: [],
};

const getRecruiterUserDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterData: [],
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        recruiterData: payload,
        loading: false,
      };
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        recruiterData: [],
        loading: false,
      };
    case GET_USER_DATA_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        userDataCount: [],
      };
    case GET_USER_DATA_COUNT_SUCCESS:
      return {
        ...state,
        userDataCount: payload,
        countLoading: false,
      };
    case GET_USER_DATA_COUNT_FAILURE:
      return {
        ...state,
        userDataCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getRecruiterUserDataReducer;
