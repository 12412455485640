import Validator from "email-validator";

export const addUserValidation = (form) => {
  const { lastname, email, name, Usertype, cityName, stateName, CountryName } =
    form;
  const newErrors = {};
  if (!name || name === "") newErrors.name = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(name) || /^\s*$/.test(name))
    newErrors.name = "Invalid First Name ";
  if (!Usertype || Usertype === "") newErrors.Usertype = "*Required Field";
  if (!lastname || lastname === "") newErrors.lastname = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(lastname) || /^\s*$/.test(lastname))
    newErrors.lastname = "Invalid Last Name ";
  if (!cityName || cityName === "") newErrors.cityName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(cityName) || /^\s*$/.test(cityName))
    newErrors.cityName = "Invalid City Name ";
  if (!stateName || stateName === "") newErrors.stateName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(stateName) || /^\s*$/.test(stateName))
    newErrors.stateName = "Invalid State Name ";
  if (!CountryName || CountryName === "")
    newErrors.CountryName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(CountryName) || /^\s*$/.test(CountryName))
    newErrors.CountryName = "Invalid Country Name ";
  if (!email || email === "") newErrors.email = "*Required Field";
  else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
  return newErrors;
};
