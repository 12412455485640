import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";
import { post } from "../../../service/ApiService";
import SidePanelHeader from "./SidePanelHeader";
import SidePanelTabs from "./SidePanelTabs";
import General from "./general/General";
import LinkedIn from "./general/LinkedIn";
import Roles from "./general/Roles";
import Education from "./general/Education";
import Certification from "./general/Certification";
import Regions from "./general/Regions";
import Language from "./general/Language";
import Experience from "./general/Experience";
import JobCategory from "./general/JobCategory";
import SubCategory from "./general/SubCategory";
import ReferredBy from "./general/ReferredBy";
import JobTitles from "./general/JobTitles";
import RejectionReason from "./general/RejectionReason";
import Clients from "./general/Clients";
import Community from "./community/Community";
import RejectionModal from "./activity/RejectionModal";
import DeleteInvitationModal from "./activity/DeleteInvitationModal";
import DeactivateModal from "./activity/DeactivateModal";
import ActivateModal from "./activity/ActivateModal";
import ApproveRecruiterModal from "./activity/ApproveRecruiterModal";

function Index(props) {
  let details = props.details;
  let prefix = props.prefix;
  let skill = details?.skills ? JSON.parse(details?.skills) : [];
  let languages = details?.languages ? JSON.parse(details?.languages) : [];
  let jobTitles = details?.jobTitle ? JSON.parse(details?.jobTitle) : [];
  let industries = details?.industries ? JSON.parse(details?.industries) : [];
  let subCategories = details?.subCategory
    ? JSON.parse(details?.subCategory)
    : [];
  let region = details?.regions ? JSON.parse(details?.regions) : [];
  let certification = details?.certification
    ? JSON.parse(details?.certification)
    : [];

  let client = details?.clients
    ? JSON.parse(details.clients).map((item) => item.tenantname)
    : [];

  let pools = details?.Pools ? JSON.parse(details.Pools) : [];

  let education = details?.education;

  const [generalDetails, setGeneralDetails] = useState(true);
  const [linkedIn, setLinkedIn] = useState(false);
  const [skills, setSkills] = useState(false);
  const [regions, setRegions] = useState(false);
  const [clients, setClients] = useState(false);
  const [community, setCommunity] = useState(true);
  const [certifications, setCertification] = useState(false);
  const [qualification, setQualifications] = useState(false);
  const [language, setLanguage] = useState(false);
  const [experience, setExperience] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [subCategory, setsubCategory] = useState(false);
  const [jobTitle, setJobTitle] = useState(false);
  const [referred, setReferred] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reject, setReject] = useState(false);
  const [remove, setDelete] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [activate, setActivate] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [reason, setReason] = useState(false);
  const [removeChecked, setRemoveChecked] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [testId, setTestId] = useState([]);
  const [general, setGeneral] = useState(true);
  const [communities, setCommunities] = useState(false);

  let status = details.status;

  const onCollapsibleClick = (category) => {
    if (category === "generalDetails") {
      setGeneralDetails(!generalDetails);
    }
    if (category === "linkedIn") {
      setLinkedIn(!linkedIn);
    }
    if (category === "skills") {
      setSkills(!skills);
    }
    if (category === "status") {
      setLinkedIn(!linkedIn);
    }
    if (category === "language") {
      setLanguage(!language);
    }
    if (category === "experience") {
      setExperience(!experience);
    }
    if (category === "industry") {
      setIndustry(!industry);
    }
    if (category === "subCategory") {
      setsubCategory(!subCategory);
    }
    if (category === "jobTitle") {
      setJobTitle(!jobTitle);
    }
    if (category === "regions") {
      setRegions(!regions);
    }
    if (category === "certifications") {
      setCertification(!certifications);
    }

    if (category === "referred") {
      setReferred(!referred);
    }
    if (category === "clients") {
      setClients(!clients);
    }
    if (category === "qualification") {
      setQualifications(!qualification);
    }
    if (category === "community") {
      setCommunity(!community);
    }
  };

  const activateRecruiter = async () => {
    setLoading(true);
    const res = await post("/recruiter/activaterecruiter", {
      email: details.email,
      firstName: details.firstName,
    });
    if (res.status === 200) {
      toast.success("Activated Successfully");
      props.closePanel();
    }
  };

  const approve = async () => {
    try {
      setLoading(true);
      let res;
      res = await post("/recruiter/approverecruiter", {
        ...details,
      });
      if (res.status === 200) {
        activate
          ? toast.success("Activated Successfully")
          : toast.success("Approved Successfully");
        props.closePanel();
      }
      setApproveModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Approving");
    }
  };

  const updateStatus = async (status) => {
    try {
      setLoading(true);
      let res = await post("/recruiter/updaterecruiterstatus", {
        ...details,
        status,
        rejectionReason,
        removeChecked,
      });
      if (res.status === 200) {
        toast.success(
          `${status === "Deactivate" ? "Deactivated" : status} Successfully`
        );
        props.closePanel();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setRemoveChecked("Accepted");
    } else {
      setRemoveChecked("Rejected");
    }
  };

  const removeItemFromArray = (item) => {
    let tempArray = [...testId];
    const index = tempArray.indexOf(item);
    tempArray.splice(index, 1);
    setTestId(tempArray);
  };

  const updateTab = (type) => {
    if (type === "general") {
      setGeneral(true);
      setCommunities(false);
    } else {
      setGeneral(false);
      setCommunities(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div class="view-panel show" style={{ backgroundColor: "white" }}>
        <div>
          <div class="px-3 py-2">
            <button
              type="button"
              class="btn btn-icon"
              onClick={props.closePanel}
            >
              <i aria-hidden="true" class="fal fa-times"></i>
            </button>
          </div>
          {details && (
            <SidePanelHeader
              details={details}
              industries={industries}
              prefix={prefix}
              status={status}
              isFrom={props.isFrom}
              setApproveModal={setApproveModal}
              setDelete={setDelete}
              setDeactivate={setDeactivate}
              setActivate={setActivate}
              setReject={setReject}
            />
          )}
        </div>
        <SidePanelTabs
          general={general}
          pools={pools}
          communities={communities}
          updateTab={updateTab}
        />
        {general && details && (
          <div class="view-panel-body">
            <General
              details={details}
              generalDetails={generalDetails}
              onCollapsibleClick={onCollapsibleClick}
            />
            <LinkedIn
              details={details}
              onCollapsibleClick={onCollapsibleClick}
              linkedIn={linkedIn}
            />
            <Roles
              skill={skill}
              skills={skills}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <Education
              education={education}
              qualification={qualification}
              onCollapsibleClick={onCollapsibleClick}
            />
            <Certification
              certification={certification}
              certifications={certifications}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <Regions
              region={region}
              regions={regions}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <Language
              language={language}
              languages={languages}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <Experience
              status={status}
              details={details}
              experience={experience}
              onCollapsibleClick={onCollapsibleClick}
            />
            <JobCategory
              industry={industry}
              industries={industries}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <SubCategory
              subCategory={subCategory}
              subCategories={subCategories}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <ReferredBy
              details={details}
              referred={referred}
              onCollapsibleClick={onCollapsibleClick}
            />
            <JobTitles
              jobTitle={jobTitle}
              jobTitles={jobTitles}
              onCollapsibleClick={onCollapsibleClick}
            />
            <Clients
              isFrom={props.isFrom}
              client={client}
              clients={clients}
              onCollapsibleClick={onCollapsibleClick}
              testId={testId}
              setTestId={setTestId}
              removeItemFromArray={removeItemFromArray}
            />
            <RejectionReason
              details={details}
              setReason={setReason}
              reason={reason}
            />
          </div>
        )}
        <Community communities={communities} pools={pools} />
      </div>
      {reject && (
        <RejectionModal
          reject={reject}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          setReject={setReject}
          updateStatus={updateStatus}
        />
      )}
      {remove && (
        <DeleteInvitationModal
          remove={remove}
          setDelete={setDelete}
          updateStatus={updateStatus}
        />
      )}
      {deactivate && (
        <DeactivateModal
          deactivate={deactivate}
          setDeactivate={setDeactivate}
          removeChecked={removeChecked}
          handleCheck={handleCheck}
          updateStatus={updateStatus}
        />
      )}
      {activate && (
        <ActivateModal
          activate={activate}
          setActivate={setActivate}
          activateRecruiter={activateRecruiter}
        />
      )}
      {approveModal && (
        <ApproveRecruiterModal
          approveModal={approveModal}
          setApproveModal={setApproveModal}
          approve={approve}
        />
      )}
    </>
  );
}

export default Index;
