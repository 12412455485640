import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useSelector, shallowEqual } from "react-redux";
import { apiDomain } from "../../../constants/Constants";
import {
  getUserTenantID,
  getUserRole,
  getCandidateName,
  getUserEmail,
} from "../../../service/AuthService";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import NameAndOwner from "./NameAndOwnwe";
import Purpose from "./Purpose";
import TitleAndLocation from "./TitleAndLocation";
import Industry from "./Industry";
import ExperienceAndEducation from "./ExperienceAndEducation";
import { validate } from "./Validation";
import SubmittalDetailsInfo from "./Submittal";
import Vetting from "./vettingplans/index";
import ScreeningComponent from "./screeningplan/Index";
import RichTextEditor from "react-rte";
import PrimarySkills from "./PrimarySkills";
import ShortUniqueId from "short-unique-id";
import VisaStatus from "./VisaStatus";
import ReadyToHireQA from "./ReadyToHireQ&A";
import { Button, Form, Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AdditionalInfo from "./AdditionalInfo";
import { googleApiKey } from "../../../constants/Constants";
import ClientHM from "./ClientHM";

const AddPool = (props) => {
  const userId = getUserTenantID();
  const uid = new ShortUniqueId({ length: 10 });
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [chatBotEnabled, setChatBotEnabled] = useState(false);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [screeningQuestions, setScreeningQuestions] = useState(
    props?.editPoolDetails?.screeningQuestion
      ? JSON.parse(props?.editPoolDetails?.screeningQuestion)
      : []
  );

  const [additionalQuestions, setAdditionalQuestions] = useState(
    props?.editPoolDetails?.additionalInfo
      ? JSON.parse(props?.editPoolDetails?.additionalInfo)
      : []
  );
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isPool, setIsPool] = useState(true);
  const [value, setValue] = useState(
    props?.edit
      ? RichTextEditor.createValueFromString(
          props?.editPoolDetails?.description,
          "html"
        )
      : props?.isFrom === "TemplateSubCommunity"
      ? RichTextEditor.createValueFromString(
          props?.poolData?.description,
          "html"
        )
      : RichTextEditor.createEmptyValue()
  );

  const [isScreening, setIsScreening] = useState(false);
  const [isAutomatch, setIsAutomatch] = useState(false);
  const [isReadyToHire, setIsReadyToHire] = useState(false);
  const [isVetting, setIsVetting] = useState(false);
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [vettingDetails, setVettingDetails] = useState({});
  const [questionList, setQuestionList] = useState([]);
  const [isManualReviewer, setIsManualReviewer] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState(10);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedHM, setSelectedHM] = useState([]);
  let jobData =
    props?.edit === true
      ? {
          userId: userId,
          poolName: props.editPoolDetails?.poolname,
          poolOwner: props.editPoolDetails?.ownername,
          jobTitle: props?.editPoolDetails?.jobTitle,
          selectedCandidates: [],
          workPlaceType: props?.editPoolDetails.workPlaceType,
          city: props?.editPoolDetails.city,
          state: props?.editPoolDetails.state,
          country: props?.editPoolDetails.country,
          hires: 0,
          targetSize: props?.editPoolDetails.targetSize,
          duration: "",
          primarySkills: props?.editPoolDetails?.primarySkills
            ? JSON.parse(props?.editPoolDetails?.primarySkills)
            : [],
          industry: props?.editPoolDetails?.industry
            ? JSON.parse(props?.editPoolDetails?.industry)
            : [],
          education: props?.editPoolDetails?.highestEducation,
          skillSet: props?.editPoolDetails?.industry
            ? JSON.parse(props?.editPoolDetails?.industry)
            : [],
          experienceLevel: props?.editPoolDetails?.experienceLevel,
          onsiteWorkDays: props?.editPoolDetails?.onsiteWorkDays,
          workPlaceType: props?.editPoolDetails?.workPlaceType,
          weightage: props?.editPoolDetails?.weightage
            ? JSON.parse(props?.editPoolDetails?.weightage)
            : {
                primarySkills: [],
                jobTitle: false,
                location: false,
                experienceLevel: false,
                education: false,
                jobList: [],
              },
          autoTagging: "",
          grouping: props?.editPoolDetails.groups,
          skills: props?.editPoolDetails?.skills,
          visaStatus: props?.editPoolDetails?.visaStatus
            ? JSON.parse(props?.editPoolDetails?.visaStatus)
            : [],
          subCategory: props?.editPoolDetails?.subCategory
            ? JSON.parse(props?.editPoolDetails?.subCategory)
            : [],
          location: props?.editPoolDetails.location,
          description: value.toString("html"),
          isCountry: false,
          isRadius: props?.editPoolDetails.isRadius,
          radiusValue: props?.editPoolDetails.radiusValue,
          address: props?.editPoolDetails?.address
            ? JSON.parse(props?.editPoolDetails?.address)
            : {},
          zipCode: props?.editPoolDetails?.zipCode,
          generalQuestion: props?.editPoolDetails?.generalQuestion
            ? JSON.parse(props?.editPoolDetails?.generalQuestion)
            : [],
          fkTenant: props?.editPoolDetails?.fk_tenant,
          client: props?.editPoolDetails?.fk_tenant,
          hm: props?.editPoolDetails?.HM,
        }
      : {
          userId: userId,
          poolName: "",
          poolOwner: "",
          jobTitle: "",
          description: "",
          selectedCandidates: [],
          isRemote: true,
          address: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
          location: "",
          numberOfPositions: 0,
          targetSize: 50,
          duration: "",
          workPlaceType: "Remote",
          primarySkills: [],
          industry: [],
          education: "",
          skillSet: [],
          visaStatus: [],
          experienceLevel: "",
          onsiteWorkDays: "",
          screeningQuestions: [],
          weightage: {
            primarySkills: [],
            jobTitle: false,
            location: false,
            experienceLevel: false,
            education: false,
            jobList: [],
          },
          autoTagging: "",
          grouping: props?.groupId ? [+props?.groupId] : [],
          skills: "",
          subCategory: [],
          isCountry: false,
          isRadius: false,
          radiusValue: 30,
          address: {},
          zipCode: "",
          generalQuestion: [],
          screeningRequired: false,
          vettingRequired: false,
          createNewAssessment: false,
          client: "",
          hm: [],
        };

  const [addJobData, setAddJobData] = useState(jobData);
  const [locationList, setLocationList] = useState(
    props?.editPoolDetails?.locationList
      ? JSON.parse(props?.editPoolDetails?.locationList)
      : []
  );
  const [jobTitleList, setJobTitleList] = useState([]);
  const [readyToHireQAList, setReadyToHireQAList] = useState(
    props?.edit
      ? props.editPoolDetails?.readyToHireQA
        ? JSON.parse(props.editPoolDetails?.readyToHireQA)
        : []
      : []
  );
  const [loading, setLoading] = useState(false);
  const [dropdownList, setDropDown] = useState({});
  const [dropDownSkills, setDropDownSkills] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [checkedAssessments, setCheckedAssessments] = useState([]);
  const [certification, setCertification] = useState("");
  const [skills, setSkills] = useState("");
  const [publishAccess, setPublishAccess] = useState([
    "Company Recruiters (Curator)",
  ]);
  const [vettingData, setVettingData] = useState({});
  const { userLoading, employeeLoading } = useSelector(
    (state) => ({
      userLoading: state.getHmDetailsReducer.loading,
      employeeLoading: state.getHmDetailsReducer.employeesDetailsLoading,
    }),
    shallowEqual
  );
  const [addJobVal, setAddJobVal] = useState({
    poolName: "",
    location: "",
    title: "",
    primary: "",
    purpose: "",
    visaStatus: "",
    education: "",
    experience: "",
    skillSet: "",
    subCategory: "",
    screeningEducation: "",
    screeningCertification: "",
    screeningLanguage: "",
    screeningShift: "",
    rEmail: "",
    rLName: "",
    rFName: "",
  });

  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];
  const publishingOptions = [
    {
      value: "Company Recruiters (Curator)",
      label: `Branded Talent Pool`,
      para: "Branded talent pool.",
    },
    {
      value: "High5 Talent Pool",
      label: "High5 Talent Pool",
      para: "Gain access to high5 diversified talent pool from quick talent submissions.",
    },
    {
      value: "Marketplace Recruiters (Gig Recruiters)",
      label: "Marketplace Recruiters (Gig Recruiters)",
      para: "This option leverages our extensive network of registered Gig Recruiters to automatically match talent from their talent pool. Additionally, it provides an avenue for gig recruiter to submit talent from their alternative sources.",
    },
  ];
  const [confirmShow, setConfirmShow] = useState(false);

  useEffect(() => {
    if (props?.edit) {
      screeningQuestions.forEach((ele) => {
        if (ele.name == "Language") setSelectedLanguages(JSON.parse(ele.input));
      });
    } else if (props?.isFrom === "ExistingSubCommunity") {
      getPoolById();
    } else if (props?.isFrom === "TemplateSubCommunity") {
      setAddJobData({
        userId: "",
        poolName: props.poolData?.jobTitle,
        poolOwner: "",
        jobTitle: props?.poolData?.jobTitle,
        selectedCandidates: [],
        city: props?.poolData?.city,
        state: props?.poolData?.state,
        country: props?.poolData?.country,
        hires: 0,
        targetSize: props?.poolData?.targetSize,
        duration: "",
        primarySkills: props?.poolData?.primarySkills
          ? [JSON.parse(props?.poolData?.primarySkills)]
          : [],
        industry: props?.poolData?.industries
          ? JSON.parse(props?.poolData?.industries)
          : [],
        education: props?.poolData?.education
          ? JSON.parse(props?.poolData?.education)[0]
          : "",
        skillSet: props?.poolData?.industries
          ? JSON.parse(props?.poolData?.industries)
          : [],
        experienceLevel: props?.poolData?.experienceLevel,
        weightage: props?.poolData?.weightage
          ? JSON.parse(props?.poolData?.weightage)
          : {
              primarySkills: [],
              jobTitle: false,
              location: false,
              experienceLevel: false,
              education: false,
              jobList: [],
            },
        autoTagging: "",
        skills: props?.poolData?.primarySkills
          ? JSON.parse(props?.poolData?.primarySkills)
              ?.map((e) => e)
              .join(" OR ")
          : "",
        visaStatus: props?.poolData?.visaStatus
          ? JSON.parse(props?.poolData?.visaStatus)
          : [],
        subCategory: props?.poolData?.subCategory
          ? JSON.parse(props?.poolData?.subCategory)
          : [],
        location: "",
        description: value.toString("html"),
        isCountry: false,
        isRadius: props?.poolData.isRadius,
        radiusValue: props?.poolData.radiusValue,
        address: props?.poolData?.address,
        zipCode: props?.poolData?.zipCode,
        generalQuestion: [],
        grouping: props?.groupId ? [+props?.groupId] : [],
        client: "",
        hm: [],
      });
    }
  }, []);

  const getPoolById = async () => {
    setLoading(true);
    await get(`/community/getCommunityById?communityId=${props?.poolId}`).then((res) => {
      let setData = res.data;
      setAddJobData({
        userId: setData?.userId,
        poolName: setData?.poolname,
        poolOwner: setData?.ownername,
        jobTitle: setData?.jobTitle,
        description: setData?.description,
        selectedCandidates: [],
        workPlaceType: setData?.workPlaceType,
        city: setData?.city,
        state: setData?.state,
        country: setData?.country,
        hires: 0,
        targetSize: setData?.targetSize,
        duration: "",
        primarySkills: setData?.primarySkills
          ? JSON.parse(setData?.primarySkills)
          : [],
        industry: setData?.industry ? JSON.parse(setData?.industry) : [],
        education: setData?.highestEducation,
        skillSet: setData?.industry ? JSON.parse(setData?.industry) : [],
        experienceLevel: setData?.experienceLevel,
        onsiteWorkDays: setData?.onsiteWorkDays,
        workPlaceType: setData?.workPlaceType,
        weightage: setData?.weightage
          ? JSON.parse(setData?.weightage)
          : {
              primarySkills: [],
              jobTitle: false,
              location: false,
              experienceLevel: false,
              education: false,
              jobList: [],
            },
        autoTagging: "",
        grouping: props?.groupId ? [+props?.groupId] : [],
        skills: setData?.skills,
        visaStatus: setData?.visaStatus ? JSON.parse(setData?.visaStatus) : [],
        subCategory: setData?.subCategory
          ? JSON.parse(setData?.subCategory)
          : [],
        location: setData?.location,
        isCountry: false,
        isRadius: setData?.isRadius,
        radiusValue: setData?.radiusValue,
        address: setData?.address ? JSON.parse(setData?.address) : {},
        zipCode: setData?.zipCode,
        generalQuestion: setData?.generalQuestion
          ? JSON.parse(setData?.generalQuestion)
          : [],
        fkTenant: "",
        client: "",
        hm: "",
      });
      setValue(
        RichTextEditor.createValueFromString(setData?.description, "html")
      );
      setLoading(false);
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await get(`/dropdown/dropdownvalues`, {
        domainName: apiDomain,
      });
      setDropDown([res.data[0]]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  let skillSetOption =
    Object.keys(dropdownList)?.length > 0
      ? dropdownList?.skillSet?.map((item) => {
          const skillSetObj = {
            value: item.PositionName,
            label: item.PositionName,
          };
          return skillSetObj;
        })
      : [];

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };
    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };
    const getSuggestionValue = (suggestion) => suggestion.PositionName;
    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownSkills.filter(
              (item) =>
                item.toLowerCase().includes(inputValue) &&
                !addJobData.vettingDetails.map((i) => i.name).includes(item)
            );
      return suggestValues;
    };
    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const addPoolInDB = async () => {
    setLoading(true);
    // createAssessment
    let finalQandA = [...screeningQuestions];
    if (finalQandA?.map((i) => i.name).includes("Language")) {
      let que = [...finalQandA.filter((i) => i.name !== "Language")];
      que.push({
        ...finalQandA.filter((i) => i.name === "Language")[0],
        input: JSON.stringify(selectedLanguages),
      });
      finalQandA = [...que];
    }
    let difficulty = "";
    difficultyData.forEach((obj) => {
      if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
    });
    const tempQuestion = questionList?.map((e) => {
      delete e?._id;
      return e;
    });
    const assessmentType = tempQuestion?.filter(
      (e) => e?.type === "MCQ"
    )?.length;

    let skillsObj = {
      Skills: Object.keys(vettingData).length
        ? Array.isArray(vettingData?.skills)
          ? vettingData?.skills
          : vettingData?.skills?.split(",").map((item) => item?.trim())
        : addJobData?.primarySkills?.flat(1),
      Duration: tempQuestion?.length * 2,
      Difficulty: difficulty,
      AssessmentType:
        assessmentType === tempQuestion.length ? "MCQ" : "General",
      JobTitle: `${addJobData.jobTitle}}`,
      PassingScore: 45,
      createdFrom: "talentPool",
      questions: tempQuestion,
    };

    const vetRes = props?.edit
      ? ""
      : questionList?.length > 0
      ? await post("/vetIntegration/createAssessmentWithQuestions", skillsObj)
      : "";
    let arr = [];
    if (
      !props?.edit &&
      vetRes?.data?.message == "No question found for the selected skills"
    ) {
      toast.warning(
        "Oops! It seems there are no pre-defined questions available."
      );
      setCheckedAssessments([]);
      setLoading(false);
      return;
    } else {
      arr.push(vetRes.data);

      let params = {
        poolname: addJobData.poolName,
        hires: addJobData.hires,
        targetSize: addJobData.targetSize,
        city: addJobData.city,
        state: addJobData?.state,
        country: addJobData?.country,
        location: addJobData?.location,
        jobTitle: addJobData?.jobTitle,
        description: addJobData?.description,
        vettingDetails: JSON.stringify(addJobData?.vettingDetails),
        screeningQuestion: JSON.stringify(finalQandA),
        additionalInfo: JSON.stringify(additionalQuestions),
        primarySkills: JSON.stringify(addJobData?.primarySkills),
        workPlaceType: addJobData?.workPlaceType,
        experienceLevel: addJobData?.experienceLevel,
        highestEducation: addJobData?.education,
        grouping: JSON.stringify(addJobData?.grouping),
        weightage: JSON.stringify(addJobData?.weightage),
        skills: addJobData?.skills,
        subCategory: JSON.stringify(addJobData.subCategory),
        industry: JSON.stringify(addJobData.skillSet),
        visaStatus: JSON.stringify(addJobData.visaStatus),
        id_pool: props?.edit ? props?.editPoolDetails?.id_pool : "",
        isCountry: addJobData.isCountry,
        isRadius: addJobData.isRadius,
        radiusValue: addJobData.radiusValue,
        zipCode: addJobData.zipCode,
        generalQuestion: JSON.stringify(addJobData.generalQuestion),
        readyToHireQA: JSON.stringify(readyToHireQAList),
        reviewerFirstName: getCandidateName().split(" ")[0],
        reviewerLastName: getCandidateName().split(" ").slice(-1)[0],
        reviewerEmail: getUserEmail(),
        isManualReviewer: isManualReviewer,
        vettingDetails: props?.edit
          ? props?.editPoolDetails?.vettingDetails
          : JSON.stringify(vetRes.data),
        publishTo: publishAccess,
        locationList: JSON.stringify(locationList),
        client: +addJobData.client,
        HM: JSON.stringify(addJobData?.hm),
        poolId: props?.edit ? props?.editPoolDetails?.poolId : "",
        poolowner:
          getUserRole() === "CustomerSuccess"
            ? `High5 Admin-${getCandidateName()}`
            : `Client Curator-${getCandidateName()}`,
      };

      if (props?.edit) {
        const res = await post("/talent/editPool", params)
          .then(async (res) => {
            toast.success("Community successfully updated !");
            setLoading(false);
            props?.closePanel();
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          });
      } else {
        const getLongLatFromCity = async (city, state, country) => {
          try {
            const address = `${city}, ${state}, ${country}`;
            const encodedAddress = encodeURIComponent(address);

            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleApiKey}`
            );

            const data = await response.json();
            if (data.status === "OK" && data.results.length > 0) {
              const location = data.results[0].geometry.location;
              return { longitude: location.lng, latitude: location.lat };
            } else {
              throw new Error(
                "Unable to retrieve longitude and latitude for the given address"
              );
            }
          } catch (error) {
            console.error("Error fetching longitude and latitude:", error);
            throw error;
          }
        };

        let { longitude, latitude } = await getLongLatFromCity(
          addJobData.city,
          addJobData.state,
          addJobData.country
        );

        params = {
          ...params,
          longitude: longitude,
          latitude: latitude,
        };
        await post("/community/addcommunity", params)
          .then(async (res) => {
            let automatchedData = {
              jobId: res.data.poolId,
              jobTitle: addJobData.jobTitle,
              primarySkills: addJobData.primarySkills,
              education: addJobData.education,
              experienceLevel: addJobData.experienceLevel,
              JprimarySkills: addJobData.primarySkills,
              JjobTitle: addJobData.weightage.jobTitle,
              Jlocation: addJobData.weightage.location,
              JexperienceLevel: addJobData.weightage.experienceLevel,
              Jeducation: addJobData.weightage.education,
              jJobList: addJobData.weightage.jobTitle
                ? [addJobData.jobTitle, ...jobTitleList]
                : [],
              city: addJobData.city,
              country: addJobData?.country,
              isRemote: addJobData.isRemote,
              createdby: addJobData?.client,
              publishTo: publishAccess,
              longitude: longitude || "",
              latitude: latitude || "",
              radiusValue: selectedRadius,
              locationList: locationList,
            };

            automatchedData = {
              ...automatchedData,
              longitude: longitude,
              latitude: latitude,
            };
            // await post(
            //   "/automatch/automatchPoolJobForTalents",
            //   automatchedData
            // ).then((res) => {
            //   setLoading(false);
            // });
            setLoading(false);
            props?.setSuccessData((state) => ({
              ...state,
              poolId: res.data.poolId,
            }));
            props?.setSuccessFormOpen(true);
            props.getPoolsFromDB();
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err);
          });
      }
    }
  };
  const onChange = (value) => {
    setValue(value);
    setAddJobData({ ...addJobData, description: value.toString("html") });
  };

  const handleConfirm = () => {
    setConfirmShow(false);
    addPoolInDB();
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (value, checkedStatus) => {
    if (checkedStatus) {
      setPublishAccess((prevAccess) => [...(prevAccess || []), value]);
    } else {
      setPublishAccess((prevAccess) =>
        prevAccess ? prevAccess.filter((item) => item !== value) : []
      );
    }
  };

  const submit = (status) => {
    let result = validate(
      addJobData,
      screeningQuestions,
      "add",
      selectedLanguages,
      value,
      jobTitleList,
      readyToHireQAList,
      questionList,
      isManualReviewer,
      additionalQuestions
    );
    if (result.validationArray.length > 0) {
      toast.error(result.validationArray[0]);
      setAddJobVal(result.validation);
    } else if (props?.edit) {
      addPoolInDB();
    } else {
      setConfirmShow(true);
    }
  };

  const nextButton = (type) => {
    let result = validate(
      addJobData,
      screeningQuestions,
      "next",
      selectedLanguages,
      value
    );
    if (result.validationArray.length > 0) {
      toast.error(result.validationArray[0]);
      setAddJobVal(result.validation);
    } else {
      setIsPool(false);
      setIsAdditionalInfo(true);
    }
  };

  const toolTip = () => {
    return (
      <Tooltip style={{ zIndex: 99999999, maxWidth: "300px" }}>
        <div style={{ textAlign: "left", marginLeft: "12px" }}>
          <h6>What to do:</h6>
          <ol>
            <li>
              Select one or multiple publishing options based on your
              preference.
            </li>
            <li>Review and confirm your publishing selections.</li>
            <li>Click 'Confirm' to publish your community.</li>
          </ol>
        </div>
      </Tooltip>
    );
  };
  return (
    <>
      {(loading || userLoading || employeeLoading) && <Loader />}
      <div className="d-flex mb-2 align-items-center">
        <h5 className="ml-3">{props?.edit ? "Update" : "Create"} Community</h5>
        <label className="ml-auto mr-4">*denotes required field</label>
      </div>
      <hr />

      {/* Nav */}
      <div className="d-flex justify-content-left mt-3 ml-2">
        <ul class="nav nav-tabs ">
          <li class="nav-item">
            <div class={isPool ? "nav-link pb-3 active" : "nav-link pb-3"}>
              Community Info
            </div>
          </li>{" "}
          <li class="nav-item ml-2">
            <div
              class={
                isAdditionalInfo ? "nav-link pb-3 active" : "nav-link pb-3"
              }
            >
              Additional Info
            </div>
          </li>
          <li class="nav-item ml-2">
            <div class={isScreening ? "nav-link pb-3 active" : "nav-link pb-3"}>
              Knockout Assessment
            </div>
          </li>
          <li class="nav-item ml-2">
            <div class={isAutomatch ? "nav-link pb-3 active" : "nav-link pb-3"}>
              Automatch
            </div>
          </li>
          <li class="nav-item ml-2">
            <div class={isVetting ? "nav-link pb-3 active" : "nav-link pb-3"}>
              Vetting
            </div>
          </li>
          <li class="nav-item ml-2">
            <div
              class={isReadyToHire ? "nav-link pb-3 active" : "nav-link pb-3"}
            >
              Ready To Hire
            </div>
          </li>
        </ul>
      </div>

      {isPool && (
        <>
          <ClientHM
            setAddJobData={setAddJobData}
            addJobData={addJobData}
            addJobVal={addJobVal}
            edit={props?.edit}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            selectedHM={selectedHM}
            setSelectedHM={setSelectedHM}
          />
          <NameAndOwner
            setAddJobData={setAddJobData}
            addJobData={addJobData}
            addJobVal={addJobVal}
            edit={props?.edit}
          />
          <TitleAndLocation
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            addJobVal={addJobVal}
            edit={props?.edit}
            userLoading={userLoading}
          />
          <PrimarySkills
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            autocompleteRenderInput={autocompleteRenderInput}
            primarySkillinput={primarySkillinput}
            setPrimarySkillInput={setPrimarySkillInput}
            addJobVal={addJobVal}
            edit={props?.edit}
          />
          <VisaStatus
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            isFormValid={isFormValid}
            addJobVal={addJobVal}
          />
          {/* <Industry
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            skillSetOption={skillSetOption}
            addJobVal={addJobVal}
            dropdownList={dropdownList?.skillSet}
          /> */}
          <ExperienceAndEducation
            setAddJobData={setAddJobData}
            addJobData={addJobData}
            dropdownList={dropdownList}
            addJobVal={addJobVal}
            edit={props?.edit}
          />
          <Purpose
            addJobData={addJobData}
            addJobVal={addJobVal}
            value={value}
            setValue={setValue}
            setAddJobData={setAddJobData}
            onChange={onChange}
            setChatBotEnabled={setChatBotEnabled}
            chatBotEnabled={chatBotEnabled}
          />
          <hr />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={() => {
                props.setShowAddPool(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                nextButton("screening");
              }}
            >
              Next{" "}
            </button>
          </div>{" "}
        </>
      )}
      {isAutomatch && (
        <>
          <SubmittalDetailsInfo
            addJobData={addJobData}
            selectedRadius={selectedRadius}
            setSelectedRadius={setSelectedRadius}
            setAddJobData={setAddJobData}
            weightage={addJobData.weightage}
            jobTitleList={jobTitleList}
            setJobTitleList={setJobTitleList}
            locationList={locationList}
            setLocationList={setLocationList}
            setWeightage={(data) =>
              setAddJobData({ ...addJobData, weightage: data })
            }
            edit={props?.edit}
            preferredLocation={preferredLocation}
            setPreferredLocation={setPreferredLocation}
          />
          <hr />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm mr-2"
              onClick={() => {
                setIsVetting(false);
                setIsScreening(true);
                setIsAutomatch(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
              }}
            >
              Back{" "}
            </button>

            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setIsAutomatch(false);
                setIsVetting(true);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
              }}
            >
              Next{" "}
            </button>
          </div>{" "}
        </>
      )}
      {isScreening && (
        <>
          <ScreeningComponent
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            screeningQuestions={screeningQuestions}
            setScreeningQuestions={setScreeningQuestions}
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
            skillSetOption={skillSetOption}
            addJobVal={addJobVal}
            skills={skills}
            setSkills={setSkills}
            certification={certification}
            setCertification={setCertification}
            edit={props?.edit}
          />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm mr-2"
              onClick={() => {
                setIsAutomatch(false);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(true);
              }}
            >
              Back{" "}
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setIsAutomatch(true);
                setIsVetting(false);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
              }}
            >
              Next{" "}
            </button>
          </div>{" "}
        </>
      )}

      {isVetting && (
        <>
          <Vetting
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            setScreeningQuestions={setScreeningQuestions}
            setAssessmentDetails={setAssessmentDetails}
            vettingDetails={vettingDetails}
            setVettingDetails={setVettingDetails}
            questionList={questionList}
            setQuestionList={setQuestionList}
            setIsManualReviewer={setIsManualReviewer}
            edit={props?.edit}
            setVettingData={setVettingData}
          />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm mr-2"
              onClick={() => {
                setIsVetting(false);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
                setIsAutomatch(true);
              }}
            >
              Back{" "}
            </button>

            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setIsVetting(false);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(true);
                setIsAdditionalInfo(false);
                setIsAutomatch(false);
              }}
            >
              Next{" "}
            </button>
          </div>{" "}
        </>
      )}

      {isReadyToHire && (
        <>
          <ReadyToHireQA
            edit={props?.edit}
            setAddJobData={setAddJobData}
            addJobData={addJobData}
            setReadyToHireQAList={setReadyToHireQAList}
            readyToHireQAList={readyToHireQAList}
          />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm mr-2"
              onClick={() => {
                setIsVetting(true);
                setIsScreening(false);
                setIsPool(false);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
                setIsAutomatch(false);
              }}
            >
              Back{" "}
            </button>
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={() => {
                if (props?.edit) {
                  props?.closePanel();
                } else {
                  props?.setShowAddPool(false);
                }
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => submit(props?.edit ? "Update" : "Create")}
            >
              {props?.edit ? "Update" : "Create"}
            </button>
          </div>{" "}
        </>
      )}
      {isAdditionalInfo && (
        <>
          <AdditionalInfo
            edit={props?.edit}
            setAddJobData={setAddJobData}
            addJobData={addJobData}
            addJobVal={addJobVal}
            screeningQuestions={additionalQuestions}
            setScreeningQuestions={setAdditionalQuestions}
          />
          <div className="text-right mb-2 mr-2">
            <button
              className="btn btn-sm mr-2"
              onClick={() => {
                setIsVetting(false);
                setIsScreening(false);
                setIsPool(true);
                setIsReadyToHire(false);
                setIsAdditionalInfo(false);
                setIsAutomatch(false);
              }}
            >
              Back{" "}
            </button>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setIsVetting(false);
                setIsScreening(true);
                setIsPool(false);
                setIsAdditionalInfo(false);
                setIsReadyToHire(false);
                setIsAutomatch(false);
              }}
            >
              Next{" "}
            </button>
          </div>{" "}
        </>
      )}
      {confirmShow && (
        <Modal show={confirmShow} onHide={() => setConfirmShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                Publish Community
              </span>
            </Modal.Title>
          </Modal.Header>
          <hr />
          <Modal.Body className="p-3">
            <div className="d-flex">
              <div className="">
                <p>Please select sourcing channels.</p>
              </div>
              <OverlayTrigger placement="bottom" overlay={toolTip()}>
                <label
                  className="ml-auto mr-4"
                  style={{ color: "red", cursor: "pointer", padding: "0" }}
                >
                  What to do...
                </label>
              </OverlayTrigger>
            </div>
            <div className="row">
              {publishingOptions.map((option, index) => (
                <>
                  <div
                    key={index}
                    className="col-12 d-inline-flex align-items-center"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      id={`option-${index}`}
                      value={option.value}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "-3px",
                      }}
                      // disabled={index === 0 ? true : false}
                      checked={publishAccess.includes(option.value)}
                      onChange={(e) =>
                        handleCheckboxChange(e.target.value, e.target.checked)
                      }
                    />
                    <Form.Check.Label
                      htmlFor={`option-${index}`}
                      className="mb-0 ml-2"
                      style={{ marginTop: "-20px" }}
                    >
                      <strong>{option.label}</strong>
                    </Form.Check.Label>
                  </div>
                  <div className="mb-3">
                    <small>- {option?.para}</small>
                  </div>
                </>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setConfirmShow(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirm()}
              disabled={!publishAccess || publishAccess.length === 0}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AddPool;
