import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../loader/Loader";
import DatePicker from "../../../../common/input/DatePicker";
import {
  getCandidateName,
  getUserEmail,
} from "../../../../../service/AuthService";

const ActivateJob = (props) => {
  const { jobData, activate, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [expiresOn, setExpiresOn] = useState("");

  useEffect(() => {
    setExpiresOn(jobData.expiresOn);
  }, []);

  const handleChange = (e) => {
    setExpiresOn(e.target.value);
  };

  const handleActivate = async () => {
    const payload = {
      jobId: jobData.jobId,
      expiresOn: new Date(expiresOn),
      jobTitle: jobData.jobTitle,
      jobStatus: jobData.jobStatus,
      prevJobStatus: jobData.prevJobStatus,
      name: getCandidateName(),
      email: getUserEmail(),
    };
    try {
      setLoading(true);
      const res = await post("/job/activatejob", payload);
      if (res.status === 200) {
        toast.success("Job Activated Successfully");
        handleClose(true);
        setLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred");
      handleClose(false);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal show={activate} onHide={() => handleClose(false)} size="delete">
        <Modal.Body>
          <div>
            <div className="bl-modal-body  text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                You're about to Activate this job, and all of its data.
              </p>
              <p>
                The job is going to expire on{" "}
                {moment(expiresOn).format("MM/DD/YYYY")}. Do you want to change
                the expiry date?
              </p>
              <div className="col-lg-6 m-auto">
                <div className="form-floating form-date ">
                  <DatePicker
                    type="date"
                    text="Expires on*"
                    name="expiresOn"
                    value={moment(new Date(expiresOn)).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    className="form-control"
                    minRange={moment(jobData.activeFrom).format("YYYY-MM-DD")}
                    maxRange="9999-12-31"
                  />
                </div>
              </div>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  data-dismiss="modal"
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="close p-0 bl-modal-close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleClose(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleActivate}
                >
                  Confirm Activate
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActivateJob;
