import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";

const PreferredSkills = ({
  handleSubmit,
  toggleEdit,
  setSkillSetList,
  skillSetList,
  topSkillEdit,
  handleSkillCancel,
}) => {
  const [skillSet, setSkillSet] = useState("");
  const [dropdownList, setDropdownList] = useState([]);

  const { candidateDropdownData } = useSelector(
    (state) => ({
      candidateDropdownData: state.dropdownValuesReducer.candidateDropdownData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (candidateDropdownData && candidateDropdownData.length > 0) {
      setDropdownList(candidateDropdownData.map((skill) => skill.SourceName));
    }
  }, [candidateDropdownData]);

  const handleChangeInput = (skillSet) => {
    setSkillSet(skillSet);
  };

  const handleChange = (tags) => {
    setSkillSetList(tags);
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.filter((item) =>
              item.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    let suggestions = getSuggestions(skillSet);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <label>Preferred Skills</label>
        {!topSkillEdit ? (
          <button
            type="button"
            className="btn btn-text"
            style={{ fontSize: "12px" }}
            onClick={() => {
              toggleEdit("skillSet");
            }}
          >
            <i className="fas fa-pencil-alt mr-1"></i>
            Edit
          </button>
        ) : (
          <div>
            <button
              type="button"
              onClick={handleSkillCancel}
              className="btn btn-sm btn-secondary mr-3"
              style={{ fontSize: "12px" }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleSubmit("skillSet");
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="w-100-768">
          <div className="form-inline-edit">
            {topSkillEdit === false ? (
              <div>
                <div className="toggle justify-content-start">
                  <div>
                    {skillSetList.length > 0 ? (
                      skillSetList.map((item) => (
                        <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                      ))
                    ) : (
                      <span className="tag tag-red2 mb-1 mr-1">
                        No data found
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={skillSetList}
                    onChange={handleChange}
                    inputValue={skillSet}
                    onChangeInput={handleChangeInput}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferredSkills;
