import { toast } from "react-toastify";
import { get, post } from "../../../../service/ApiService";

export const GET_TALENT_SUMMARY_BEGIN = "GET_TALENT_SUMMARY_BEGIN";
export const GET_TALENT_SUMMARY_SUCCESS = "GET_TALENT_SUMMARY_SUCCESS";
export const GET_TALENT_SUMMARY_FAILURE = "GET_TALENT_SUMMARY_FAILURE";

export const GET_TALENT_SUMMARY_COUNT_BEGIN = "GET_TALENT_SUMMARY_COUNT_BEGIN";
export const GET_TALENT_SUMMARY_COUNT_SUCCESS =
  "GET_TALENT_SUMMARY_COUNT_SUCCESS";
export const GET_TALENT_SUMMARY_COUNT_FAILURE =
  "GET_TALENT_SUMMARY_COUNT_FAILURE";

export const getClientTalentSummary = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TALENT_SUMMARY_BEGIN,
    });
    const res = await post("/dashboard/sa/getclienttalentsummary", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_TALENT_SUMMARY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TALENT_SUMMARY_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getClientTalentSummaryCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TALENT_SUMMARY_COUNT_BEGIN,
    });
    const res = await post(
      "/dashboard/sa/getclienttalentsummarycount",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_TALENT_SUMMARY_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TALENT_SUMMARY_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
