import React, { useState, useEffect, useRef } from "react";
import { googleApiKey } from "../../constants/Constants";
// const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";
const googleMapApiJs = "https://maps.googleapis.com/maps/api/js";

const GoogleLocationCountryAPI = (props) => {
  const { setLocation, searchInput, className, setContactInfo ,contactInfo} = props;
  const [addressData, setAddressData] = useState(null);

  const loadAsyncScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const extractAddress = (place) => {
    const address = {
      address1: "",
      address2: "",
      address3: "",
      street: "",
      route: "",
      locality: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      if (types.includes("sublocality_level_1")) {
        address.address1 = value;
      }
      if (types.includes("sublocality_level_2" || "sublocality")) {
        address.address2 = value;
      }
      if (
        types.includes("point_of_interest" || "establishment" || "neighborhood")
      ) {
        address.address3 = value;
      }
      if (types.includes("street_number")) {
        address.street = value;
      }
      if (types.includes("route")) {
        address.route = value;
      }
      if (types.includes("locality" || "political")) {
        address.locality = value;
      }
      if (types.includes("administrative_area_level_2" || "political")) {
        address.city = value;
      }
      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }
      if (types.includes("postal_code")) {
        address.zip = value;
      }
      if (types.includes("country")) {
        address.country = value;
      }
    });
    return address;
  };

  // Address Change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    let addressData = extractAddress(place);
    setAddressData(addressData);
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;
    var options = {
      types: ["(regions)"],
    };
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      options
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    loadAsyncScript(
      `${googleMapApiJs}?key=${googleApiKey}&libraries=places`,
      () => initAutocomplete()
    );
    if (searchInput.current) {
      searchInput.current.value = "";
    }
  }, [searchInput]);

  useEffect(() => {
    if (addressData) {
      setLocation(addressData, searchInput);
    }
  }, [addressData]);

  useEffect(() => {
    if (searchInput?.current && contactInfo.country) {
      searchInput.current.value = contactInfo.country || ""; // Set the input to the current country value
    }
  }, [contactInfo.country]);

  const handleChangeLocation = (event) => {
    if (searchInput.current.value == "" && contactInfo) {
      setContactInfo({ ...contactInfo, country: searchInput.current.value });
    }
  };

  return (
    <input
      type="text"
      className={className ?? "form-control"}
      name="city"
      ref={searchInput}
      placeholder="Enter a country name"
      onChange={(e) => handleChangeLocation(e)}
    />
  );
};

export default GoogleLocationCountryAPI;
