import {
  GET_RECRUITER_DASHBOARD_BEGIN,
  GET_RECRUITER_DASHBOARD_SUCCESS,
  GET_RECRUITER_DASHBOARD_FAILURE,
} from "../../../../action/dashboard/superadmin/recruiter/RecruiterDashboard";

const initialState = {
  loading: true,
  recruiterCount: [],
};

const getrecruiterDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_DASHBOARD_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterCount: [],
      };
    case GET_RECRUITER_DASHBOARD_SUCCESS:
      return {
        ...state,
        recruiterCount: payload,
        loading: false,
      };
    case GET_RECRUITER_DASHBOARD_FAILURE:
      return {
        ...state,
        recruiterCount: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default getrecruiterDashboardReducer;
