import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import buttonLoader from "../../../../../../../images/button-loader.gif";

const ResumeUpload = ({
  submitClicked,
  resumeFile,
  setResumeFile,
  uploadResume,
  hideResumeBtn,
  setHideResumeBtn,
  parsed,
}) => {
  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file[0].name)[1]; // "
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      let doc = { fileName: "", base64: "" };
      reader.onload = (event) => {
        doc.fileName = name;
        doc.base64 = event.target.result;
        setResumeFile(doc);
        setHideResumeBtn(true);
        if (parsed) {
          toast.success("Your resume uploaded successfully");
        } else {
          uploadResume(doc);
        }
      };
    } else {
      toast.error("Invalid file type");
    }
  };

  return (
    <div>
      {submitClicked ? (
        <button type="button" class="btn btn-secondary btn-sm ">
          <span v-if="submittingForm">
            Uploading
            <img width="20px" alt="" src={buttonLoader} />
          </span>
        </button>
      ) : (
        <Dropzone
          onDrop={(file) => onDragDropChangeHandler(file)}
          multiple={false}
          disabled={resumeFile && resumeFile.fileName}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <button
                type="button"
                className="btn btn-sm btn-secondary ml-3"
                disabled={hideResumeBtn}
              >
                <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                Upload Resume*
              </button>

              <input type="file" {...getInputProps()} accept=".docx, .pdf" />
            </div>
          )}
        </Dropzone>
      )}
    </div>
  );
};

export default ResumeUpload;
