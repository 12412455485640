import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import NoRecord from "../../../../common/NoRecord";

const VettingRequirement = (props) => {
  const { jobData, setVetting, vetting } = props;

  let vettingDetails = JSON.parse(jobData?.vettingDetails);
  const parts = vettingDetails?.testName?.split("_");
  const result = parts[0];

  return (
    <Modal size="xl" show={vetting}>
      <Modal.Header>
        <h6 className="pt-o mt-3">Vetting Requirement</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setVetting(false)}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            {vettingDetails ? (
              <>
                <div className="row mb-3 px-3">
                  <div className="col-lg-3">
                    <strong>Assessment</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Skills</strong>
                  </div>
                  <div className="col-lg-2">
                    <strong>Type</strong>
                  </div>
                  <div className="col-lg-2">
                    <strong>Difficulty</strong>
                  </div>
                  <div className="col-lg-2">
                    <strong>Duration</strong>
                  </div>
                </div>
                <hr />

                <div className="row mb-3 px-3">
                  <div className="col-lg-3">{result}</div>
                  <div className="col-lg-3">
                    {vettingDetails?.skills?.join(", ")}
                  </div>
                  <div className="col-lg-2">
                    <select className="form-select font-14" disabled>
                      <option
                        value="MCQ"
                        selected={vettingDetails?.testCategory === "MCQ"}
                      >
                        MCQ
                      </option>
                      <option
                        value="OneWay"
                        selected={vettingDetails?.testCategory === "OneWay"}
                      >
                        OneWay
                      </option>
                      <option
                        value="General"
                        selected={vettingDetails?.testCategory === "General"}
                      >
                        General
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-2">
                    <select className="form-select font-14" disabled>
                      {["Simple", "Medium", "Advanced"].map((item) => (
                        <option
                          value={item}
                          selected={
                            vettingDetails?.difficulty === item ||
                            (item === "simple" && !vettingDetails?.difficulty)
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-2">
                    <select className="form-select font-14" disabled>
                      <option>{vettingDetails?.details?.duration} mins</option>
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <NoRecord />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setVetting(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VettingRequirement;
