import { toast } from "react-toastify";
import Validator from "email-validator";

export const validate = (
  addJobData,
  screeningQuestions,
  type,
  selectedLanguages,
  value,
  jobTitleList,
  readyToHireQAList,
  questionList,
  isManualReviewer,
  additionalQuestions
) => {
  let validationArray = [];
  let validation = {
    poolName: "",
    poolOwner: "",
    title: "",
    primary: "",
    purpose: "",
    numberOfPositions: "",
    allowedSubmittals: "",
    screeningQuestions: "",
    additionalQuestions: "",
    targetSize: "",
    location: "",
    education: "",
    experience: "",
    visaStatus: "",
    skillSet: "",
    subCategory: "",
    screeningEducation: "",
    screeningCertification: "",
    screeningLanguage: "",
    screeningShift: "",
    rFName: "",
    rLName: "",
    rEmail: "",
    client: "",
  };

  if (addJobData.client == "") {
    validation = { ...validation, client: "Required Field" };
    validationArray.push("Please Select Client");
  }
  if (addJobData.poolName?.trim() === "") {
    validation = { ...validation, poolName: "Required Field" };
    validationArray.push("Please Enter Community Name");
  }
  // if (addJobData.targetSize == 0 || addJobData.targetSize < 0) {
  //   validation = { ...validation, targetSize: "Required Field" };
  //   validationArray.push("Please enter the valid target size");
  // }
  if (addJobData.jobTitle?.trim() === "") {
    validation = { ...validation, jobTitle: "Required Field" };
    validationArray.push("Please Enter Job Title");
  }
  if (!addJobData.location) {
    validation = { ...validation, location: "Required Field" };
    validationArray.push("Please Enter Location");
  }
  if (addJobData.primarySkills.length === 0) {
    validation = { ...validation, primary: "Required Field" };
    validationArray.push("Please Enter Mandatory Skills");
  }

  if (addJobData?.description) {
    const strippedString = addJobData?.description?.replace(
      /<\/?[^>]+(>|$)|&nbsp;|\s+/g,
      ""
    );
    if (strippedString.length === 0) {
      validation = { ...validation, purpose: "Required Field" };
      validationArray.push("Please Enter Job Description. It can not be empty");
    } else {
      validation = { ...validation, purpose: "" };
    }
  }
  if (addJobData?.description === "<p><br></p>") {
    validation = { ...validation, purpose: "Required Field" };
    validationArray.push("Please Enter Job Description. It can not be empty");
  }
  if (!addJobData?.description) {
    validation = { ...validation, purpose: "Required Field" };
    validationArray.push("Please Enter Job Description. It can not be empty");
  }
  if (addJobData.visaStatus.length === 0) {
    validation = { ...validation, visaStatus: "Required Field" };
    validationArray.push("Please Enter Work Authorization Requirement");
  }
  // if (addJobData.skillSet == "") {
  //   validation = { ...validation, skillSet: "Required Field" };
  //   validationArray.push("Please Enter Job Category");
  // }
  // if (addJobData.subCategory == "") {
  //   validation = { ...validation, subCategory: "Required Field" };
  //   validationArray.push("Please Enter Sub Category");
  // }
  if (addJobData.experienceLevel == "") {
    validation = { ...validation, experience: "Required Field" };
    validationArray.push("Please Select Experience");
  }
  if (addJobData.education == "") {
    validation = { ...validation, education: "Required Field" };
    validationArray.push("Please Select Education");
  }
  // if (addJobData.weightage.jobTitle) {
  //   if (jobTitleList?.length < 2) {
  //     validationArray.push("Please enter valid numbers of job titles");
  //   }
  // }
  if (readyToHireQAList?.filter((e) => e?.question?.trim() == "")?.length > 0) {
    validationArray.push("Please enter a question");
  }
  // else if(readyToHireQAList?.length===0){
  //   validationArray.push("Ready to hire can't be empty")
  // }
  if (type === "add" && screeningQuestions.length > 0) {
    // if (screeningQuestions.length === 0) {
    //   validation = { ...validation, screeningQuestions: "Required Field" };
    //   validationArray.push("Please Enter Screening Questions");
    // }
    screeningQuestions.forEach((element) => {
      if (element.name == "Custom Question") {
        if (element.question == "") {
          validation = { ...validation, screeningQuestions: "Required Field" };
          validationArray.push("Please add knockout question.");
        }
      }
      if (
        element.name == "Skills and Proficiency" ||
        element.name === "License/Certification"
      ) {
        if (element.selectedOption === "") {
          validation = { ...validation, screeningQuestions: "Required Field" };
          validationArray.push(
            "Please enter the value for knockout assessment question."
          );
        }
      }
    });
  }
  if (type === "add" && additionalQuestions.length > 0) {
    // if (screeningQuestions.length === 0) {
    //   validation = { ...validation, screeningQuestions: "Required Field" };
    //   validationArray.push("Please Enter Screening Questions");
    // }
    additionalQuestions.forEach((element) => {
      if (element?.options?.length > 0) {
        if (element.selectedOption === "") {
          validation = { ...validation, additionalQuestions: "Required Field" };
          validationArray.push("Please enter the value for Additional Info.");
        }
      }
    });
  }
  if (!addJobData?.createNewAssessment && addJobData?.vettingRequired) {
    if (questionList?.length < 5) {
      validationArray.push("Please add at least 5 vetting questions");
    }
  }
  const regexs = /^[A-Z a-z\s]+$/;
  if (isManualReviewer) {
    if (addJobData?.reviewerFirstName?.trim()?.length === 0) {
      validation = { ...validation, rFName: "Required Field" };
      validationArray.push("Please Enter Reviewer's First Name");
    }
    if (addJobData?.reviewerFirstName?.trim()?.length > 0) {
      if (!regexs?.test(addJobData?.reviewerFirstName)) {
        validation = {
          ...validation,
          rFName: "Special characters & numbers not allowed",
        };
        validationArray.push("Please Enter Valid Reviewer's First Name");
      }
    }
    if (addJobData?.reviewerLastName?.trim()?.length === 0) {
      validation = { ...validation, rLName: "Required Field" };
      validationArray.push("Please Enter Reviewer's Last Name");
    }
    if (addJobData?.reviewerLastName?.trim()?.length > 0) {
      if (!regexs?.test(addJobData?.reviewerLastName)) {
        validation = {
          ...validation,
          rLName: "Special characters & numbers not allowed",
        };
        validationArray.push("Please Enter Valid Reviewer's Last Name");
      }
    }
    if (addJobData?.reviewerEmail?.trim()?.length === 0) {
      validation = { ...validation, rEmail: "Required Field" };
      validationArray.push("Please Enter Email");
    }
    if (addJobData?.reviewerEmail?.trim()?.length > 0) {
      if (!Validator.validate(addJobData?.reviewerEmail)) {
        validation = { ...validation, rEmail: "Enter Valid Email" };
        validationArray.push("Please Enter Valid Email");
      }
    }
  }
  return { validation, validationArray };
};
