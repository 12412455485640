import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import { getTalentHistory } from "../../../../action/talent/GetTalentHistory";
import Loader from "../../../loader/Loader";
import NoRecord from "../../../common/NoRecord";

const CandidateHistory = (props) => {
  const dispatch = useDispatch();

  const { talentHistoryData, talentHistoryLoading } = useSelector(
    (state) => ({
      talentHistoryData: state.talentHistoryReducer.talentHistoryData,
      talentHistoryLoading: state.talentHistoryReducer.talentHistoryLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let param = {
      candidateId: props.talentData?.userId,
    };
    dispatch(getTalentHistory(param));
  }, [dispatch, props.talentData?.userId]);

  const getDate = (date) => {
    const formattedDate = moment(date).isAfter(moment().subtract(1, "days"))
      ? moment(date).fromNow()
      : moment(date).format("MM/DD/YYYY");

    return formattedDate;
  };

  return (
    <>
      {talentHistoryLoading && <Loader />}
      <Card className="candidate-history-card-custom">
        <div className="candidate-history-content-custom p-3">
          {talentHistoryData && talentHistoryData.length > 0 ? (
            talentHistoryData?.map((activity, index) => (
              <div
                key={index}
                className="candidate-history-timeline-custom pb-3 d-flex align-items-start"
              >
                <FaCircle
                  className="candidate-history-icon-custom mr-3"
                  aria-hidden="true"
                />
                <div>
                  <small className="candidate-history-date-custom">
                    {getDate(activity?.activityDate)}
                  </small>
                  <p className="candidate-history-status-custom mb-0">
                    <strong>
                      <span className="tag tag-blue3 mr-1">
                        {activity?.activityType?.toUpperCase()}
                      </span>
                    </strong>
                    <strong>
                      {" "}
                      for the {activity.category} '{activity?.activityFor}'
                    </strong>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center">
              <NoRecord />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default CandidateHistory;
