import React, { useState } from "react";
import Select from "react-select";
import "react-tagsinput/react-tagsinput.css";
// import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import PreferredLocation from "../../../location/PreferredLocation";
import _ from "lodash";
import {
  currency_list,
  dropDownData,
} from "../../../../constants/DropDownData";

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function PreferencesDS(props) {
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);

  const visaStatus = [
    "Citizen",
    "Green Card",
    "GC EAD",
    "H1B",
    "H4 EAD",
    "L2EAD",
    "Not Required",
    "OPT EAD",
    "TN1",
    "TN2",
  ];

  let positionTypeOption = dropDownData.PositionType?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  positionTypeOption = _.sortBy(positionTypeOption, ["value"]);

  let skillSetOption = dropDownData.skillSet?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  skillSetOption = _.sortBy(skillSetOption, ["value"]);

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  const handlePositiontype = (e) => {
    setPositionTypes(e);
    props.setProfileInfo({
      ...props.profileInfo,
      positionTypes: e.map((pType) => pType.value),
    });
  };

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setProfileInfo({
      ...props.profileInfo,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, primarySkills: skills });
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, secondarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChange = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeExperienceYear = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceYear: e.target.value,
    });
  };

  const handleChangeExperienceMonth = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceMonth: e.target.value,
    });
  };

  const onRemoteSwitch = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.checked,
    });
  };

  const onIsfresherSwitch = (e) => {
    if (e.target.id == "isFresher" && e.target.checked === true) {
      // disable experince tab
      console.log(e.target.id, e.target.checked);
      props.setProfileInfo({ ...props.profileInfo, experienceLevel: " " });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    } else {
      // enable tab
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "",
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    }
  };
  //File upload block
  const setFileForSending = (file) => {
    const { name } = file;
    const type = name.split(".")[1];
    if (type === "pdf" || type === "docx" || type === "doc") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        props.setProfileInfo({
          ...props.profileInfo,
          fileName: name,
          base64: event.target.result,
        });
        toast.success("File uploaded successfully");
      };
    } else {
      toast.error("Please Upload Only PDF or WORD Document");
    }
  };

  const onRemove = (e) => {
    props.setProfileInfo({ ...props.profileInfo, fileName: "", base64: "" });
    toast.success("File removed successfully");
  };
  // File upload on drag
  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue?.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  return (
    <>
      <div>
        <div class="card-body" style={{ padding: "10px" }}>
          <div class="row mb-2" style={{ marginTop: "-13px" }}>
            <div class="col-lg-12">
              <label>Let us know your expected salary* </label>
            </div>
          </div>
          {/* For talent community */}
          {props.message === "JoinCommunity" ? (
            <div class="row mb-2">
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Preferred Salary"
                    id="preferredSalary"
                    value={props.profileInfo.preferredSalary}
                    //onChange={handleChange}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        toast.error("Cannot Be Zero");
                      } else if (+e.target.value < 0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  <label>Expected Annual Salary*</label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalary}
                </small>
              </div>

              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Minimum Contract Rate"
                    id="minContractRate"
                    value={props.profileInfo.minContractRate}
                    //onChange={handleChange}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        toast.error("Cannot Be Zero");
                      } else if (+e.target.value < 0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  <label>Expected Hourly Salary</label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.minContractRate}
                </small>
              </div>

              {/* Currency type */}
              <div class="col-lg-4">
                <div class="form-floating">
                  <select
                    class="form-select"
                    disabled={!props.profileInfo.preferredSalary > 0}
                    id="preferredSalaryCurrency"
                    style={{
                      border:
                        props.profileInfoValidation.preferredSalaryCurrency &&
                        "2px solid #ff0000",
                    }}
                    value={props.profileInfo.preferredSalaryCurrency}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    {/* {dropDownData?.CurrencyTime?.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (  
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))} */}
                    {currency_list.map((item) => (
                      <option value={item.code}>{item.code}</option>
                    ))}
                  </select>
                  <label>
                    Currency Type
                    {props.profileInfo.preferredSalary > 0 && "*"}
                  </label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalaryCurrency}
                </small>
              </div>
            </div>
          ) : (
            <div class="row mb-2">
              {props?.job?.type === "Full Time" ? (
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="Preferred Salary"
                      id="preferredSalary"
                      value={props.profileInfo.preferredSalary}
                      //onChange={handleChange}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          toast.error("Cannot Be Zero");
                        } else if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                    <label>Expected Annual Salary*</label>
                  </div>
                  <small className="validation">
                    {props.profileInfoValidation.preferredSalary}
                  </small>
                </div>
              ) : (
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="Minimum Contract Rate"
                      id="minContractRate"
                      value={props.profileInfo.minContractRate}
                      //onChange={handleChange}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          toast.error("Cannot Be Zero");
                        } else if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                    <label>Expected Hourly Salary*</label>
                  </div>
                  <small className="validation">
                    {props.profileInfoValidation.minContractRate}
                  </small>
                </div>
              )}
              {/* Currency type */}
              <div class="col-lg-6">
                <div class="form-floating">
                  <select
                    class="form-select"
                    disabled={!props.profileInfo.preferredSalary > 0}
                    id="preferredSalaryCurrency"
                    style={{
                      border:
                        props.profileInfoValidation.preferredSalaryCurrency &&
                        "2px solid #ff0000",
                    }}
                    value={props.profileInfo.preferredSalaryCurrency}
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    {/* {dropDownData?.CurrencyTime?.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (  
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))} */}
                    {currency_list.map((item) => (
                      <option value={item.code}>{item.code}</option>
                    ))}
                  </select>
                  <label>
                    Currency Type
                    {props.profileInfo.preferredSalary > 0 && "*"}
                  </label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalaryCurrency}
                </small>
              </div>
              {/*  */}
            </div>
          )}
          <div class="row  mt-2">
            <div class="col-12" style={{ marginTop: "-8px" }}>
              <label>Could you mention your preferred locations?* </label>
              <div class=" d-flex ">
                <div
                  class="form-floating ml-auto"
                  style={{ margin: "0px 0px 0px -22px" }}
                >
                  <div
                    className="form-check form-switch d-flex align-items-center"
                    style={{ paddingLeft: "21.5rem" }}
                  >
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open For Hybrid
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isHybrid}
                      // checked={props?.profileInfo?.preferedLocations?.includes(
                      //   "Hybrid"
                      // )}
                      onChange={(e) => {
                        props.setProfileInfo({
                          ...props.profileInfo,
                          // preferedLocations: [
                          //   ...props.profileInfo.preferedLocations,
                          //   e.target.checked && "Hybrid",
                          // ],
                          isHybrid: e.target.checked,
                          isRemote: false,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div
                  class="form-floating ml-auto"
                  style={{ margin: "0px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open For Onsite
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isOnsite}
                      onChange={(e) =>
                        props.setProfileInfo({
                          ...props.profileInfo,
                          isOnsite: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div> */}
                <div
                  class="form-floating ml-auto"
                  style={{ margin: "0px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open For Remote
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isRemote}
                      // checked={props?.profileInfo?.preferedLocations?.includes(
                      //   "Remote"
                      // )}
                      onChange={(e) =>
                        props.setProfileInfo({
                          ...props.profileInfo,
                          // preferedLocations: [
                          //   ...props.profileInfo.preferedLocations,
                          //   e.target.checked && "Remote",
                          // ],
                          isRemote: e.target.checked,
                          isHybrid: false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {props.profileInfo.isRemote != true && (
                <div>
                  <div class="form-inline-edit">
                    <div>
                      <div>
                        <div className="m-2">
                          {preferredLocation.map((item, index) => (
                            <>
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                                <button
                                  className="btn btn-text"
                                  onClick={() => deleteLocation(index)}
                                >
                                  <strong className="font-18 font-black">
                                    &times;
                                  </strong>
                                </button>
                              </span>
                            </>
                          ))}
                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="form-floating w-100">
                            <PreferredLocation
                              setLocation={(loc) => {
                                if (preferredLocation.includes(loc)) {
                                  toast.error(
                                    "You Have Already Added This Location"
                                  );
                                } else {
                                  setPreferredLocation((cur) => [...cur, loc]);
                                  props.changePreferredLocation([
                                    ...preferredLocation,
                                    loc,
                                  ]);
                                }
                              }}
                            />
                          </div>
                          <div className="ml-auto mr-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <small className="validation">
                {props.profileInfoValidation.preferredLocation}
              </small>
            </div>
          </div>

          {/* <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2" style={{ marginTop: "-38px" }}>
                <label>Industry</label>
                <button
                  onClick={() =>
                    console.log(dropDownData.skillSet.map((i) => i.SourceName))
                  }
                  className="btn btn-text"
                ></button>
              </div>
              <Select
                isMulti
                value={skillSet}
                options={skillSetOption}
                onChange={handleSkillSet}
                styles={customStyles}
                isSearchable={true}
              />
            </div>
          </div> */}

          {/* {props.parent === "DirectSourcing" ? null : (
            <div class="row">
              <div class="col-12">
                <div class="p-5 p-0-600">
                  <h6 class="mt-2">Upload Resumes</h6>
                  <p>Accepted File Type: .docx, .doc, .pdf</p>
                  <div class="col-sm-12">
                    <Dropzone onDrop={onDragDropChangeHandler}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <div class="talentuploadmaindiv">
                              <div class="talentuploadicon">
                                <label>
                                  <i class="fas fa-cloud-upload-alt"></i>
                                </label>
                                <input
                                  type="file"
                                  name="myfile"
                                  {...getInputProps()}
                                />
                              </div>
                              {props.profileInfo.fileName ? (
                                <div class="talentuploadtext">
                                  {props.profileInfo.fileName}
                                </div>
                              ) : (
                                <div class="talentuploadtext">
                                  Click or Drag and Drop To Upload
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {props.profileInfo.fileName && (
                      <div class="modal-footer mt-4 mb-0">
                        <button
                          type="button"
                          className="btn btn-secondary mr-2"
                          onClick={onRemove}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}

export default PreferencesDS;
