import React, { useState } from "react";
import ScreeningQuestions from "./ScreeningQuestions";
import VettingRequirement from "./VettingRequirement";
// import AutomatchedParameters from "./AutomatchedParameters";

const Index = (props) => {
  const { jobData } = props;
  const [qna, setQNA] = useState(false);
  const [vetting, setVetting] = useState(false);
  const [automatch, setAutomatch] = useState(false);
  return (
    <>
      {jobData?.screeningRequired &&
        JSON.parse(jobData.screeningQuestions).length > 0 && (
          <button
            onClick={() => setQNA(true)}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            <span>
              <small>
                Screening Questions
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setQNA(true);
                  }}
                >
                  <span className="ml-1">
                    <i
                      className="fas fa-external-link-square"
                      style={{ fontSize: "small", color: "#FF5F57" }}
                    />
                  </span>
                </a>
              </small>
            </span>
          </button>
        )}

      {jobData?.vettingRequired && JSON.parse(jobData.vettingDetails) && (
        <button
          onClick={() => setVetting(true)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          <span>
            <small>
              Vetting Requirement
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setVetting(true);
                }}
              >
                <span className="ml-1">
                  <i
                    className="fas fa-external-link-square"
                    style={{ fontSize: "small", color: "#FF5F57" }}
                  />
                </span>
              </a>
            </small>
          </span>
        </button>
      )}
      <button
        onClick={() => setAutomatch(true)}
        type="button"
        className="btn btn-collapsible mb-2"
      >
        <span>
          <small>
            Automatched Parameters
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setAutomatch(true);
              }}
            >
              <span className="ml-1">
                <i
                  className="fas fa-external-link-square"
                  style={{ fontSize: "small", color: "#FF5F57" }}
                />
              </span>
            </a>
          </small>
        </span>
      </button>
      {qna && (
        <ScreeningQuestions jobData={jobData} setQNA={setQNA} qna={qna} />
      )}
      {vetting && (
        <VettingRequirement
          jobData={jobData}
          setVetting={setVetting}
          vetting={vetting}
        />
      )}
      {/* {automatch && (
        <AutomatchedParameters
          jobData={jobData}
          setAutomatch={setAutomatch}
          automatch={automatch}
        />
      )} */}
    </>
  );
};

export default Index;
