import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { get } from "../../../service/ApiService";
import { getCandidateName } from "../../../service/AuthService";
// import SupportModal from "./SupportModal";
import useOutsideClick from "../../functions/useOutsideClick";

const Support = () => {
  const [toggleHelp, setToggleHelp] = useState(false);
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [supportType, setSupportType] = useState(0);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);

  const ref = useRef();
  useOutsideClick(ref, () => {
    setToggleHelp(false);
  });
  const fetchSupportAPI = async () => {
    let id = JSON.parse(localStorage.getItem("userToken")).roleId;
    await get("/support").then((data) => {
      setSupport(data.data.filter((s) => s.fk_role.includes(id)));
    });
  };

  useEffect(() => {
    fetchSupportAPI();
  }, []);

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  return (
    <>
      <button
        type="button"
        title="Support"
        className="btn btn-action-panel btn-icon btn-nav ml-3"
        onClick={() => setToggleHelp(!toggleHelp)}
      >
        <i className="fal fa-fw fa-life-ring"></i>
      </button>
      {toggleHelp && (
        <div
          className="action-panel"
          style={{
            display: "revert",
            right: "0px",
            top: "50px",
            textAlign: "left",
          }}
        >
          <OutsideClickHandler
            onOutsideClick={() => !supportModal && setToggleHelp(false)}
          >
            <div className="py-3 px-4">
              <p className="lead mb-0">Hello {getCandidateName()}</p>
              <strong>What can we help you with today?</strong>
              <div className="search-field mb-3 mt-2">
                <div className="field-wrap">
                  <input
                    type="search"
                    onChange={handleSupportSearch}
                    placeholder="Search Support"
                  />
                  <span className="icon">
                    <i aria-hidden="true" className="fas fa-search"></i>
                  </span>
                </div>
              </div>
              <strong>
                <small>Common Questions</small>
              </strong>
              <ul className="mt-1 ml-0 list-unstyled">
                {enableSupportSearch
                  ? supportData.length !== 0
                    ? supportData.map((s, i) => (
                        <li key={i}>
                          <a
                            onClick={openSupportModal}
                            href=""
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(s.Title),
                            }}
                          ></a>
                        </li>
                      ))
                    : "No Questions available "
                  : support.length !== 0
                  ? support.map((s, i) => (
                      <li key={i}>
                        <a onClick={openSupportModal} href="">
                          {s.Title}
                        </a>
                      </li>
                    ))
                  : "No Questions available "}
              </ul>
            </div>
          </OutsideClickHandler>
        </div>
      )}
      {/* {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )} */}
    </>
  );
};

export default Support;
