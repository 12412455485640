import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import Automatch from "./Automatch";
import NoRecord from "../../../../../common/NoRecord";
import { getUserRole } from "../../../../../../service/AuthService";
import Pagination from "../../../../../common/pagination/Pagination";
import { getJobDispositionDetails } from "../../../../../../action/disposition/job/jobdetails/JobDetails";

const Index = (props) => {
  const { jobData, calculateScore, fetchLatestEmailStatus, view } = props;
  const [automatchData, setAutomatchData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();

  const { dispositionDetails, dispositionCount } = useSelector(
    (state) => ({
      dispositionDetails: state.jobDispositionReducer.dispositionDetails,
      dispositionCount: state.jobDispositionReducer.dispositionCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(
            paginationArray,
            setPaginationArray,
            "autoMatched"
          );
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    if (dispositionDetails.length > 0) {
      if (props?.value !== "" && props?.value !== null) {
        let data = dispositionDetails.map((e) => ({
          ...e,
          name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
        }));
        const regex = new RegExp(props.value, "i");
        let filter = data
          .filter(
            (item) => item.dispositionStatus == 38 && regex.test(item.name)
          )
          .sort((a, b) => b.automatchDate.localeCompare(a.automatchDate));
        setAutomatchData(filter);
      } else {
        setAutomatchData(
          dispositionDetails
            .filter((item) => item.dispositionStatus == 38)
            .sort((a, b) => b.automatchDate.localeCompare(a.automatchDate))
        );
      }
    } else {
      setAutomatchData(
        dispositionDetails
          .filter((item) => item.dispositionStatus == 38)
          .sort((a, b) => b.automatchDate.localeCompare(a.automatchDate))
      );
    }
  }, [dispositionDetails, props?.value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payload));
  };

  return (
    <div>
      <div className="table-wrap">
        {automatchData.length > 0 ? (
          <>
            <table className="table table-sortable table-hoverable-cells table-cards-1024">
              <thead className="mb-2">
                <tr className="d-none d-lg-table-row">
                  <th className="hover">Candidate </th>
                  <th>Date </th>
                  <th className="no-hover">Resumes </th>
                  <th className="no-hover">Email status </th>
                  <th className="">Score</th>
                </tr>
              </thead>
              <tbody>
                <Automatch
                  jobData={jobData}
                  automatchData={automatchData}
                  calculateScore={calculateScore}
                  view={view}
                />
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalItems={
                dispositionCount?.filter((e) => e.Status == "Automatched")[0]
                  ?.Count
              }
              itemsPerPage={15}
              onPageChange={handlePageChange}
              limit={15}
            />
          </>
        ) : (
          <NoRecord />
        )}
      </div>
    </div>
  );
};

export default Index;
