import {
  GET_SOURCE_FILTER_BEGIN,
  GET_SOURCE_FILTER_SUCCESS,
  GET_SOURCE_FILTER_FAILURE,
} from "../../../action/filter/talent/TalentSourceFilter";

const initialState = {
  sourceLoading: false,
  sourceData: null,
};

const getTalentSourceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SOURCE_FILTER_BEGIN:
      return {
        ...state,
        sourceLoading: true,
        sourceData: null,
      };
    case GET_SOURCE_FILTER_SUCCESS:
      return {
        ...state,
        sourceData: payload,
        sourceLoading: false,
      };
    case GET_SOURCE_FILTER_FAILURE:
      return {
        ...state,
        sourceData: null,
        sourceLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentSourceReducer;
