import React, { useState } from "react";
import RichTextEditor from "react-rte";
import { post } from "../../service/ApiService";
import Loader from "../common/loader/Loader";

function JobDescriptionGenerator(props) {
  const { isModalOpen, setModalClose, isFrom, closeModal, data } = props;

  const [jobTitle, setJobTitle] = useState(data?.jobTitle);
  const [tone, setTone] = useState("formal");
  const [instructions, setInstructions] = useState("");
  const [size, setSize] = useState(200);
  const [generatedDescription, setGeneratedDescription] = useState("");
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeJDdata = (value) => {
    setGeneratedDescription(value.toString("html"));
  };

  const displayToneDescription = () => {
    switch (tone) {
      case "formal":
        return "Formal tone is appropriate for professional job positions.";
      case "professional":
        return "Professional tone maintains a business-like demeanor while still being approachable.";
      case "engaging":
        return "Engaging tone captures the reader's attention and keeps them interested.";
      case "inclusive":
        return "Inclusive tone promotes diversity and equality, making all candidates feel welcome.";
      case "warm":
        return "Warm tone creates a friendly and inviting atmosphere for potential candidates.";
      default:
        return "";
    }
  };

  const generatePrompt = (jobTitle) => {
    let prompt = `Create a ${tone} job description for ${jobTitle}`;
    if (instructions.trim() !== "") {
      prompt += " with " + instructions.trim();
    }
    prompt +=
      " in less than " +
      size +
      " words.response should be in html format and keep header as h6";
    return prompt;
  };

  const generateJobDescription = async () => {
    let payload = {
      prompt: generatePrompt(jobTitle),
    };

    setLoading(true);
    const res = await post("/chatGpt/createJobDescription", payload);

    if (res.status === 200) {
      setGeneratedDescription(res.data);
      setLoading(false);
      setShowAcceptButton(true);
    }
  };

  const acceptJobDescription = () => {
    let updatedJDdata = RichTextEditor.createValueFromString(
      generatedDescription,
      "html"
    );
    data.onChange(updatedJDdata);
    setModalClose(false);
  };

  const rejectJobDescription = () => {
    setModalClose(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container">
        <div className="m-3">
          <strong style={{ textAlign: "center" }}>
            High5 AI Job Description Generator
          </strong>
        </div>

        <div className="card card-flat bg-gray4 m-1">
          <div className="form-floating m-3">
            <strong>Community Job Title</strong>
            <input
              type="text"
              placeholder="e.g., Software Engineer"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              disabled={isFrom !=="CreateSubCommunity" ?true:false}
              style={{ backgroundColor: "#f2f2f2" }}
            />
          </div>
        </div>
        <div className="card card-flat bg-gray4 m-1" id="toneSizeCard">
          <div className="form-floating m-3">
            <strong>Select Tone</strong>
            <div className="flex-container">
              <div>
                <select value={tone} onChange={(e) => setTone(e.target.value)}>
                  <option value="formal">Formal</option>
                  <option value="professional">Professional</option>
                  <option value="engaging">Engaging</option>
                  <option value="inclusive">Inclusive</option>
                  <option value="warm">Warm</option>
                </select>
              </div>
            </div>
            <div id="toneDescription" style={{ marginTop: "10px" }}>
              {displayToneDescription()}
            </div>
          </div>
        </div>
        <div className="card card-flat bg-gray4 m-1">
          <div className="m-3">
            <strong>Any Other Instructions?</strong>
            <label
              htmlFor="instructions"
              style={{ color: "#666", fontSize: "12px" }}
              className="mb-2"
            >
              Please include any specific skills, qualifications,
              responsibilities, or application process that should be
              highlighted in the job description.
            </label>
            <textarea
              id="instructions"
              rows="4"
              placeholder="Enter instructions here..."
              style={{ fontSize: "12px" }}
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
            ></textarea>
            <label htmlFor="size">Select Max Length (words):</label>
            <input
              type="number"
              id="size"
              min="100"
              max="1000"
              value={size}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value <= 1000) {
                  setSize(value);
                }
              }}
              className="mb-2"
            />
            <button
              onClick={generateJobDescription}
              className="mb-2 text-right"
            >
              Generate Description
            </button>
          </div>
        </div>
        <div
          id="jobDescription"
          style={{ overflow: "auto", maxHeight: "400px" }}
        >
          {generatedDescription && (
            <div className="card card-flat bg-gray4 m-1">
              <strong className="m-2">Generated Job Description:</strong>
              <div className="card-body p-4 disabled-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: generatedDescription,
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
        {showAcceptButton && (
          <div className="text-right m-2">
            <button
              class="btn btn-secondary btn-sm mr-3"
              id="acceptButton"
              onClick={rejectJobDescription}
            >
              Cancel
            </button>
            <button
              className={"btn btn-primary btn-sm"}
              id="acceptButton"
              onClick={acceptJobDescription}
            >
              Accept
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default JobDescriptionGenerator;
