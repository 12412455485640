import React from "react";
import RichTextEditor from "react-rte";
import { toolbarRTEConfig } from "../../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
// import ModalView from "../../../commoncomponents/modal/Modal";

const Purpose = ({
  addJobVal,
  addJobData,
  setAddJobData,
  onChange,
  value,
  setValue,
  setChatBotEnabled,
  chatBotEnabled,
}) => {
  console.log("addJobVal", addJobVal);
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-between text-right mb-1">
              <span>Community Job Description*</span>

              {addJobData.jobTitle !== "" && (
                <div>
                  <FontAwesomeIcon
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Use High5 AI to effortlessly generate job descriptions"
                    icon={faMagic}
                    onClick={() => setChatBotEnabled(true)}
                    style={{ color: "blue" }}
                    className={
                      !chatBotEnabled ? "magic-wand animate-wand" : "magic-wand"
                    }
                  />
                  {"  "}High5 AI{" "}
                  <span
                    className="ml-2"
                    style={{
                      border: "solid 1px gray",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    BETA
                  </span>
                  <i
                    className="far fa-info-circle mr-2 ml-2"
                    // style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="This is an AI feature and can provider inaccurate or biased responses"
                  ></i>
                </div>
              )}
            </div>

            <div className="form-floating">
              <RichTextEditor
                toolbarConfig={toolbarRTEConfig}
                value={value}
                onChange={onChange}
                className={addJobVal.purpose ? "border border-danger" : ""}
              />
            </div>
           
            {addJobVal.purpose && (
              <small className="validation ml-2">*Required field</small>
            )}
          </div>
        </div>
      </div>

      {/* {chatBotEnabled && (
        <ModalView
          modalSize={"lg"}
          modalTitle={"Create Job Description"}
          isModalOpen={chatBotEnabled}
          setModalClose={setChatBotEnabled}
          isFrom="AddSubCommunity"
          modalFor="jobDescription"
          data={{ onChange: onChange, jobTitle: addJobData.jobTitle }}
        />
      )} */}
    </>
  );
};

export default Purpose;
