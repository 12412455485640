import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getClientTalentSummary,
  getClientTalentSummaryCount,
} from "../../../../action/dashboard/superadmin/client/TalentSummary";
import { getClientNameData } from "../../../../action/common/ClientName";
import Loader from "../../../common/loader/Loader";
import TalentTable from "./TalentTable";

const TalentSummary = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("Total");
  const [options, setOptions] = useState([{ value: "0", label: "All" }]);
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "All Clients",
  });
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [selectedClient, setSelectedClient] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);

  const [sortArray, setSortArray] = useState([]);

  const {
    talentData,
    loading,
    countLoading,
    talentSummaryCount,
    clientName,
    nameLoading,
  } = useSelector(
    (state) => ({
      loading: state.getTalentSummaryReducer.loading,
      countLoading: state.getTalentSummaryReducer.countLoading,
      talentData: state.getTalentSummaryReducer.talentData,
      talentSummaryCount: state.getTalentSummaryReducer.talentSummaryCount,
      nameLoading: state.getClientNameReducer.nameLoading,
      clientName: state.getClientNameReducer.clientName,
    }),
    shallowEqual
  );

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchText.length === 0 || searchText.length >= 2) {
        let payload = {
          searchText: searchText,
        };
        dispatch(getClientNameData(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchText]);

  useEffect(() => {
    fetchTalentData("Total", 1, 10, null, "", sortArray);
    const payload = {
      tenantId: null,
      searchText: "",
    };
    dispatch(getClientTalentSummaryCount(payload));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
    if (active == "Total") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Total"));
      setDataSize(10);
    } else if (active == "Invited") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Invited"));
      setDataSize(10);
    } else if (active == "Submitted") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Submitted"));
      setDataSize(10);
    } else if (active == "Shortlisted") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Shortlisted"));
      setDataSize(10);
    } else if (active == "Offered") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Offered"));
      setDataSize(10);
    } else if (active == "Hired") {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Hired"));
      setDataSize(10);
    } else {
      talentSummaryCount.length > 0 && setDataTotal(getCount("Disqualified"));
      setDataSize(10);
    }
  }, [active, talentSummaryCount]);

  useEffect(() => {
    if (clientName.length > 0) {
      let op = clientName?.map((item, index) => ({
        value: item.id,
        label: item?.tenantname.replace("GIG - ", ""),
      }));
      setOptions([{ value: 0, label: "All" }, ...op]);
    }
  }, [clientName]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        const tenantId = selectedClient == 0 ? null : selectedClient;
        fetchTalentData(active, 1, dataSize, tenantId, searchData, sortArray);
        const payload = {
          tenantId: tenantId,
          searchText: searchData,
        };
        dispatch(getClientTalentSummaryCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const fetchTalentData = (
    status,
    pageNumber,
    limit,
    tenantId,
    searchVal,
    sortArray
  ) => {
    const payload = {
      status: status,
      currentPage: pageNumber,
      limit: limit,
      tenantId: tenantId,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getClientTalentSummary(payload));
  };

  const getCount = (status) => {
    let count = 0;
    if (talentSummaryCount.length > 0) {
      count = talentSummaryCount.filter((item) => item.Type == status)[0]
        ?.Count;
    }
    return count;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchTalentData(
      active,
      pageNumber,
      dataSize,
      tenantId,
      searchData,
      sortArray
    );
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchTalentData(active, 1, ds, tenantId, searchData, sortArray);
  };

  const tabChange = (status) => {
    setCurrentPage(1);
    setActive(status);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchTalentData(status, 1, 10, tenantId, searchData, []);
  };

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  const handleChange = (selected) => {
    isFirstRender.current = true;
    setSelectedOption(selected);
    setSearchData("");
    const tenantId = selected.value == 0 ? null : selected.value;
    fetchTalentData(active, 1, 10, tenantId, "", []);
    const payload = {
      tenantId: tenantId,
      searchText: "",
    };
    dispatch(getClientTalentSummaryCount(payload));
    setSelectedClient(tenantId);
  };

  const handleChangeInput = (e) => {
    setSearchText(e);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchTalentData(active, 1, dataSize, tenantId, searchData, sortedData);
  };

  return (
    <div>
      {(loading || countLoading || nameLoading) && <Loader />}
      <div className=" card">
        <div className="card-body  px-4 pb-4 ">
          <div className="d-flex justify-content-between mb-4 border-bottom align-items-center pb-2">
            <h6>Active Talent</h6>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ width: 200 }}>
                <Select
                  options={options}
                  isSearchable={true}
                  onChange={handleChange}
                  styles={styleSheet}
                  value={selectedOption}
                  onInputChange={handleChangeInput}
                />
              </div>{" "}
            </div>
          </div>
          <div className="d-flex  justify-content-between">
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Overall count of talents in the recruitment pipeline. 
                It represents the number of individuals considered for job opportunities, including newly invited talents. 
                This doesn't represent the TOTAL count of Talent registered in the High5 / Recruiters community."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Total" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Total");
              }}
            >
              <div>
                <span className="tag">{getCount("Total")}</span>
              </div>
              <span className="">Total</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of invited talents for any jobs."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Invited" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Invited");
              }}
            >
              <div>
                <div>
                  <span className="tag">{getCount("Invited")}</span>
                </div>
              </div>
              <span className="">Invited</span>
            </a>

            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of talents who have submitted their applications for job positions. "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Submitted" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Submitted");
              }}
            >
              <div>
                <span className="tag">{getCount("Submitted")}</span>
              </div>
              <span className="">Submitted</span>{" "}
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of talents shortlisted for jobs in High5Hire."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Shortlisted" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Shortlisted");
              }}
            >
              <div>
                <span className="tag">{getCount("Shortlisted")}</span>
              </div>
              <span className="">Shortlisted</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of Talents who got Job offers from High5Hire."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Offered" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Offered");
              }}
            >
              <div>
                <span className="tag">{getCount("Offered")}</span>
              </div>
              <span className="">Offered</span>
            </a>

            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="The number of talents accepting job offers and awaiting the formal onboarding process."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Hired" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Hired");
              }}
            >
              <div>
                <span className="tag">{getCount("Hired")}</span>
              </div>
              <span className="">Hired</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="The number of talents disqualified or eliminated from the recruitment process."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Disqualified" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Disqualified");
              }}
            >
              <div>
                <span className="tag">{getCount("Disqualified")}</span>
              </div>
              <span className="">Disqualified</span>
            </a>
          </div>
        </div>
      </div>
      <TalentTable
        talents={talentData}
        isFrom={"RecPipe"}
        prefix={active}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        currentPage={currentPage}
        handleSearch={handleSearch}
        searchData={searchData}
        tenantId={selectedClient == 0 ? null : selectedClient}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        fetchData={fetchTalentData}
      />
    </div>
  );
};

export default TalentSummary;
