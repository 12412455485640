import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  margin-left: 30px;
  font-family: Arial, sans-serif;
`;

export const Header = styled.h2`
  margin-bottom: 4px;
`;

export const Subtitle = styled.p`
  color: gray;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const HeaderContainer = styled.div`
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

export const HeaderDetails = styled.div`
  font-size: 14px;
  color: gray;
`;

export const Entries = styled.div`
  margin-top: 16px;
`;

export const EntryContainer = styled.div`
  display: flex;
  border-left: 2px solid green;
  padding: 8px;
  margin-bottom: 16px;
`;

export const Date = styled.div`
  font-weight: bold;
  width: 80px;
  font-size: 14px;
`;

export const EntryContent = styled.div`
  margin-left: 16px;
`;

export const Editor = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const Actions = styled.div`
  font-size: 14px;
`;

export const Action = styled.div`
  color: ${(props) => (props.type === "Deleted" ? "red" : "black")};
  margin-top: 4px;
`;