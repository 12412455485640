import React, { useState } from "react";
import Avatar from "react-avatar";
import moment from "moment";
import NoRecord from "../../../../common/NoRecord";

const Index = (props) => {
  const { jobData } = props;
  return (
    <>
      <div className="row mb-2">
        <div className="col-lg-12">
          {jobData.notes ? (
            JSON.parse(jobData.notes).map((item) => (
              <div className="d-flex mb-2">
                <Avatar size="30" name={item.name} round="60px" />
                <div className="ml-2">
                  <p className="m-0">
                    <strong>{item.name}</strong>
                    <small className="ml-2">
                      {moment(item.createdDate).format("MM/DD/YYYY")}
                    </small>
                    <p className="">
                      <small>{item.comments}</small>
                    </p>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <NoRecord />
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
