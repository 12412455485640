import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import ContactInfo from "./ContactInfo";
import ProfileInfo from "./ProfileInfo";
import Experience from "./Experience";
import Education from "./Education";
import Certifications from "./Certification";
// import ModalView from "../../../commoncomponents/modal/Modal";
import { isValidUrl } from "../../../functions/CommonFunction";

import License from "./License";
import imageLoader from "../../../../images/button-loader.gif";
import {
  contactInfoValidation,
  profileInfoValidation,
  experienceValidation,
  educationValidation,
  certificationValidation,
  licenseValidation,
} from "../validations/ManualEntry";
import {
  checkEmptyObject,
  checkListOfEmptyObject,
} from "../../../functions/CommonFunction";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../loader/Loader";
import { getCandidateDropdown } from "../../../../action/talent/AddTalent";
import Preferences from "./Preferences";
import { getTalentList } from "../../../../action/talent/TalentList";
import {
  getTenantType,
  getUserTenantID,
} from "../../../../service/AuthService";

function AddTalentManualEntry(props) {
  const { setSuccessTalentFormOpen, handleClear, isFrom, isFor } = props;
  const dispatch = useDispatch();
  const { existingCandidateList } = props;
  const [contact, setContact] = useState(true);
  const [profileInfo, setProfileInfo] = useState(false);
  const [experience, setExperience] = useState(false);
  const [education, setEducation] = useState(false);
  const [certification, setCertification] = useState(false);
  const [license, setLicense] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [preferences, setPreferences] = useState(false);
  const [extractedData, setExtractedData] = useState(props.extractedResumeText);

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const [load, setLoading] = useState(false);

  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state?.AddCandidate?.loading,
      dropDownData: state?.AddCandidate?.dropDownData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!dropDownData) {
      dispatch(getCandidateDropdown());
    }
  }, [dropDownData]);

  useEffect(() => {
    if (props.data) {
      let tempContactInfo = { ...contactInfo };
      let tempProfileInfo = { ...profileData };
      tempContactInfo["firstName"] = props.data?.firstName;
      tempContactInfo["lastName"] = props.data?.lastName;
      tempContactInfo["email"] = props.data?.email;
      tempContactInfo["zipcode"] = props.data?.zipcode;
      tempContactInfo["address"] = props.data?.address;
      tempContactInfo["addressCity"] = props.data?.city;
      tempContactInfo["addressState"] = props.data?.state;
      tempContactInfo["country"] = props.data?.country;
      tempContactInfo["workPhoneCode"] = props.data?.workPhoneCode ?? "";
      tempContactInfo["workPhone"] = props.data?.workPhone ?? "";
      tempContactInfo["homePhoneCode"] = props.data?.homePhoneCode ?? "";
      tempContactInfo["homePhone"] = props.data?.homePhone ?? "";
      tempContactInfo["workPhoneValidation"] = true;
      tempContactInfo["homePhoneValidation"] = true;
      tempProfileInfo["facebook"] = props.data?.facebook;
      tempProfileInfo["twitter"] = props.data?.twitter;
      tempProfileInfo["linkedIn"] = props.data?.linkedIn;
      tempProfileInfo["preferredSalary"] = props.data?.preferredSalary;
      tempProfileInfo["preferredSalaryCurrency"] =
        props.data?.preferredSalaryCurrency;
      tempProfileInfo["minContractRate"] = props.data?.minContractRate;
      tempProfileInfo["minContractRateCurrency"] =
        props.data?.minContractRateCurrency;
      tempProfileInfo["description"] = props.data?.description;
      tempProfileInfo["fileName"] = props.data?.fileName;
      tempProfileInfo["filePath"] = props.data?.filePath;
      tempProfileInfo["positionTypes"] = props.data?.positionTypes;
      tempProfileInfo["preferredLocation"] = props.data?.preferredLocation;
      tempProfileInfo["primarySkills"] = props.data?.primarySkills;
      tempProfileInfo["secondarySkills"] = props.data?.secondarySkills;
      tempProfileInfo["skillSet"] = props.data?.skillSet;
      tempProfileInfo["source"] = props.data?.source;
      tempProfileInfo["visaStatus"] = props.data?.visaStatus;
      tempProfileInfo["designation"] = props.data?.designation;
      tempProfileInfo["currentEmployer"] = props.data?.currentEmployer;
      tempProfileInfo["experienceLevel"] = props.data?.experienceLevel;
      tempProfileInfo["experienceYear"] = props.data?.experienceYear;
      tempProfileInfo["experienceMonth"] = props.data?.experienceMonth;
      tempProfileInfo["isFresher"] = props.data?.isFresher;
      tempProfileInfo["isRemote"] = props.data?.isRemote;
      tempProfileInfo["workAuthCountry"] = props.data?.workAuthCountry;

      setContactInfo(tempContactInfo);
      setProfileData(tempProfileInfo);
      setLicenses(props.data?.licenses ? props.data?.licenses : []);
      setCertifications(
        props.data?.certifications ? props.data?.certifications : []
      );
      setEducations(props.data?.educations ? props.data?.educations : []);
      setExperiences(props.data?.experiences ? props.data?.experiences : []);
      setExtractedData(props.data?.keywords);
    }
  }, [props.data]);

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      if (contact === false) {
        setContact(true);
      } else {
        setContact(false);
      }
    }
    if (type === "profile") {
      if (profileInfo === false) {
        setProfileInfo(true);
      } else {
        setProfileInfo(false);
      }
    }
    if (type === "experience") {
      if (experience === false) {
        setExperience(true);
      } else {
        setExperience(false);
      }
    }
    if (type === "education") {
      if (education === false) {
        setEducation(true);
      } else {
        setEducation(false);
      }
    }
    if (type === "certification") {
      if (certification === false) {
        setCertification(true);
      } else {
        setCertification(false);
      }
    }
    if (type === "license") {
      if (license === false) {
        setLicense(true);
      } else {
        setLicense(false);
      }
    }
    if (type === "preferences") {
      if (preferences === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
    }
  };

  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);

  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const [certifications, setCertifications] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);
  const [certificationVal, setCertificationVal] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);
  const [licenses, setLicenses] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);
  const [licenseVal, setLicenseVal] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    zipcode: "",
    address: "",
    addressCity: "",
    addressState: "",
    country: "",
    workPhoneCode: "",
    workPhone: "",
    homePhoneCode: "",
    homePhone: "",
    longitude: "",
    latitude: "",
    workPhoneValidation: true,
    homePhoneValidation: true,
  });
  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    address: "",
    addressCity: "",
    addressState: "",
    country: "",
  });
  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: "",
    preferredSalaryCurrency: "USD/Year",
    minContractRate: "",
    minContractRateCurrency: "USD/Hour",
    description: "",
    fileName: "",
    base64: "",
    positionTypes: [],
    preferredLocation: [],
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    source: "",
    visaStatus: "",
    designation: "",
    currentEmployer: "",
    experienceLevel: "",
    experienceYear: 0,
    experienceMonth: 0,
    isFresher: false,
    workAuthCountry: "",
    isRemote: false,
  });
  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    preferredLocation: "",
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    designation: "",
    preferredSalary: "",
  });

  const validationFun = () => {
    let isValid = true;
    let isContactValidated = false;
    let isProfileValidated = false;
    let isExperienceValidated = false;
    let isEducationValidated = false;
    let isCertificationValidated = false;
    let isLicenseValidated = false;
    /*****************************ContactInfo ***********************/
    let contactInfoVal = contactInfoValidation(contactInfo);
    if (!checkEmptyObject(contactInfoVal.validation)) {
      setContactVal(contactInfoVal.validation);
      setContact(true);
      setLoading(false);
      isValid = false;
      toast.error(contactInfoVal.validationObj[0]);
    } else if (contactInfo.homePhone == "") {
      isValid = false;
      toast.error("Please Enter Phone Number");
    } else if (!contactInfo.workPhoneValidation) {
      isValid = false;
      toast.error("Please Enter Valid Alternative Phone");
    } else if (
      contactInfo.workPhone !== "" &&
      !contactInfo?.homePhoneValidation
    ) {
      isValid = false;
      toast.error("Please Enter Valid Primary Phone");
    } else {
      setContactVal(contactInfoVal.validation);
      isContactValidated = true;
      setProfileInfo(true);
      setContact(true);
    }
    /*****************************ProfileInfo ***********************/
    if (isContactValidated) {
      let profileInfoVal = profileInfoValidation(profileData);

      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        setProfileInfo(true);
        setLoading(false);
        isValid = false;
        toast.error(profileInfoVal.validationObj[0]);
      } else if (profileData.fileName === "" || !profileData.fileName) {
        setProfileVal(profileInfoVal.validation);
        toast.error("Please Upload Your Resume In Profile Info");
      } else if (profileData.linkedIn && !isValidUrl(profileData.linkedIn)) {
        setProfileVal(profileInfoVal.validation);
        isValid = false;
        toast.error("Invalid LinkedIn URL");
      } else {
        isProfileValidated = true;
        setProfileVal(profileInfoVal.validation);
        setContact(true);
        setExperience(true);
      }
    }
    if (isContactValidated && isProfileValidated) {
      /*****************************Experience ***********************/

      let experienceVal = experienceValidation(experiences);

      if (!checkListOfEmptyObject(experienceVal.validation)) {
        setExperienceVal(experienceVal.validation);
        setExperience(true);
        isValid = false;
        setLoading(false);
        toast.error(experienceVal.validationObj[0]);
      } else {
        isExperienceValidated = true;
        setExperienceVal(experienceVal.validation);
      }
      /*****************************Education***********************/
      let educationVal = educationValidation(educations);
      if (!checkListOfEmptyObject(educationVal.validation)) {
        setEducationVal(educationVal.validation);
        setEducation(true);
        setLoading(false);
        isValid = false;
        toast.error(educationVal.validationObj[0]);
      } else {
        isEducationValidated = true;
        setEducationVal(educationVal.validation);
      }
      /*****************************Certificate***********************/
      let certificationVal = certificationValidation(certifications);
      if (!checkListOfEmptyObject(certificationVal.validation)) {
        setCertificationVal(certificationVal.validation);
        setCertification(true);
        setLoading(false);
        isValid = false;
        toast.error(certificationVal.validationObj[0]);
      } else {
        isCertificationValidated = true;
        setCertificationVal(certificationVal.validation);
      }
      /*****************************License***********************/
      let licenseVal = licenseValidation(licenses);
      if (!checkListOfEmptyObject(licenseVal.validation)) {
        setLicenseVal(licenseVal.validation);
        setLicense(true);
        toast.error(licenseVal.validationObj[0]);
        setLoading(false);
      } else {
        isLicenseValidated = true;
        setLicenseVal(licenseVal.validation);
      }
    }

    let obj = {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
    };
    return obj;
  };

  const submitAddTalent = async () => {
    setIsFormValid(true);
    let validObj = validationFun();

    const {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
    } = validObj;

    if (
      isContactValidated &&
      isProfileValidated &&
      isExperienceValidated &&
      isEducationValidated &&
      isCertificationValidated &&
      isLicenseValidated
    ) {
      setContact(false);
      setProfileInfo(false);
      setExperience(false);
      setEducation(false);
      setCertification(false);
      setLicense(false);
      setSubmitClicked(true);

      let talentData = {
        ...contactInfo,
        ...profileData,
      };
      let experience =
        experiences?.length > 1
          ? experiences?.filter((item) => checkEmptyObject(item) === false)
          : experiences;
      let education =
        educations?.length > 1
          ? educations?.filter((item) => checkEmptyObject(item) === false)
          : educations;
      let certification =
        certifications?.length > 1
          ? certifications?.filter((item) => checkEmptyObject(item) === false)
          : certifications;
      let license =
        licenses?.length > 1
          ? licenses?.filter((item) => checkEmptyObject(item) === false)
          : licenses;
      talentData.experiences = experience;
      talentData.educations = education;
      talentData.certifications = certification;
      talentData.licenses = license;
      talentData.domainName = domain;

      talentData.experienceLevel = findRange(
        profileData.experienceYear,
        profileData.experienceMonth
      );

      const licenseCertification = [
        ...talentData.licenses.map((e) => e.licenseName),
        ...talentData.certifications?.map((e) => e.certificationName),
      ];
      const finalLicenseCertification = licenseCertification.filter(
        (e) => e !== ""
      );
      const licenseState = talentData.licenses.map((e) => e.state);
      const finalLicenseState = licenseState.filter((e) => e !== "");

      let paramObj = {
        longitude: talentData?.longitude,
        latitude: talentData?.latitude,
        firstName: talentData.firstName.trim(),
        workAuthCountry: talentData.workAuthCountry,
        lastName: talentData.lastName.trim(),
        email: talentData.email.trim(),
        address: talentData.address,
        city: talentData.addressCity,
        state: talentData.addressState,
        country: talentData.country,
        zipcode: talentData.zipcode,
        homePhoneCode: talentData.homePhoneCode,
        homePhone: talentData.homePhone,
        workPhone: talentData.workPhone,
        workPhoneCode: talentData.workPhoneCode,
        facebook: talentData.facebook,
        twitter: talentData.twitter,
        linkedIn: talentData.linkedIn,
        preferredSalary: talentData.preferredSalary,
        preferredSalaryCurrency: talentData.preferredSalaryCurrency,
        minContractRate: talentData.minContractRate,
        minContractRateCurrency: talentData.minContractRateCurrency,
        description: talentData.description,
        positionTypes: JSON.stringify(talentData.positionTypes),
        base64: talentData.base64,
        preferredLocation: JSON.stringify(
          profileData.isRemote
            ? [...profileData.preferredLocation, "Remote"]
            : profileData.preferredLocation
        ),
        primarySkills: JSON.stringify(talentData.primarySkills),
        secondarySkills: JSON.stringify(talentData?.secondarySkills),
        skillSet: JSON.stringify(talentData.skillSet),
        source: talentData.source,
        visaStatus: talentData.visaStatus,
        designation: talentData.designation.trim(),
        currentEmployer: talentData.currentEmployer,
        experienceMonth: profileData.experienceMonth?.toString(),
        experienceYear: profileData.experienceYear?.toString(),
        experienceLevel: talentData.experienceLevel,
        isFresher: talentData.isFresher,
        isRemote: talentData.isRemote,
        fileName: talentData.fileName,
        filePath: talentData?.filePath || "",
        experiences: talentData.isFresher
          ? []
          : JSON.stringify(talentData.experiences),
        educations: JSON.stringify(talentData.educations),
        certifications: JSON.stringify(talentData.certifications),
        licenses: JSON.stringify(talentData.licenses),
        keywords: JSON.stringify(extractedData),
        finalLicenseCertification: JSON.stringify(finalLicenseCertification),
        finalLicenseState: JSON.stringify(finalLicenseState),
        fk_tenant: getUserTenantID(),
      };

      if (props?.isFrom === "TalentProfileEdit") {
        paramObj.oldFileName = props.data?.fileName;
        paramObj.candidateId = props.data?.userId;
        try {
          setLoading(true);
          const res = await post("/talent/updatetalent/", paramObj);
          if (res.status === 200) {
            toast.success("Talent Updated Successfully");
            setLoading(false);
            isFor === "TalentPool" && handleClear();
            const automatchedTalent = {
              primarySkills: talentData.primarySkills,
              keywords: talentData.keywords,
              educations: talentData.educations,
              city: talentData.addressCity,
              preferredLocation:
                talentData.preferredLocation == null
                  ? []
                  : talentData.preferredLocation,
              designation: talentData.designation,
              experienceLevel: talentData.experienceLevel,
              userId: props.data?.userId,
              tenantType: getTenantType(),
              tenantId: getUserTenantID(),
            };
            post("/automatch/automatchedTalentForJobs", automatchedTalent);
            props.closeModal();
            setLoading(false);
            if (props.fetchData) {
              props.fetchData(1);
            } else {
              let payload = {
                currentPage: 1,
                limit: 10,
                status: null,
                tab: "All",
                sortParam: [],
              };
              dispatch(getTalentList(payload));
            }
          }
        } catch (error) {
          setSubmitClicked(false);
          toast.error(error?.response?.data?.errMessage);
        }
      } else {
        try {
          setLoading(true);
          const res = await post("/talent/addtalent/", paramObj);
          if (res.status === 200) {
            const automatchedTalent = {
              primarySkills: talentData.primarySkills,
              keywords: talentData.keywords,
              educations: talentData.educations,
              city: talentData.addressCity,
              preferredLocation: talentData.preferredLocation,
              designation: talentData.designation,
              experienceLevel: talentData.experienceLevel,
              userId: res.data.candidateId,
              tenantType: getTenantType(),
              tenantId: getUserTenantID(),
            };
            setLoading(false);
            toast.success("Talent added successfully!");
            props.closeModal();
            post("/automatch/automatchedTalentForJobs", automatchedTalent);
            props.setSuccessTalentData({
              title: "Talent Created",
              subTitle: "Talent has been created successfully",
              name: paramObj.firstName + " " + paramObj.lastName,
              experienceMonth: paramObj.experienceMonth,
              experienceYear: paramObj.experienceYear,
              preferredLocation: talentData.preferredLocation,
              primarySkills: talentData.primarySkills,
            });
            if (props.fetchData()) {
              props.fetchData(1);
            } else {
              let payload = {
                currentPage: 1,
                limit: 10,
                status: null,
                tab: "All",
                sortParam: [],
              };
              dispatch(getTalentList(payload));
            }
            setSuccessTalentFormOpen(true);
            setSubmitClicked(false);
          }
        } catch (error) {
          setSubmitClicked(false);

          setLoading(false);
          if (error?.response?.data?.errMessage === "Email ID Already Exists")
            setContact(true);
          toast.error(error?.response?.data?.errMessage);
        }
      }
      setSubmitClicked(false);
    }
  };

  useEffect(() => {
    if (props.parsed) {
      //  profileInfo
      let location = props?.parsedData?.location || "";
      if (props?.parsedData?.isAddedByCrintell) {
        if (props?.parsedData?.profile?.Locations?.length) {
          location = props?.parsedData?.profile?.Locations[0];
        }
      } else if (props?.parsedData?.h5h) {
        if (props?.parsedData?.location?.length) {
          location = props?.parsedData?.location;
        }
      } else {
        if (props.parsedData?.location) {
          location = props?.parsedData?.location;
        }
      }

      // if added by crintell we are checking this data else previous data
      let name1 = props.parsedData?.name?.split(" ");
      const contactInfoObject = props?.parsedData?.h5h
        ? {
            email: props.parsedData?.email ?? "",
            address: location ? location?.address : "",
            country: location ? location?.country : "",
            addressState: location ? location?.state : "",
            addressCity: location ? location?.city : "",
            zipcode: "",
            firstName: props.parsedData?.name ? name1[0] : "",
            lastName: props.parsedData?.name ? name1[1] : "",
            workPhone: props.parsedData?.phone ? props.parsedData?.phone : "",
          }
        : {
            address: location?.rawInput,
            email: props?.parsedData?.data?.emails.toString(),
            country: location?.country ? location?.country : "",
            addressState: location?.state ? location?.state : "",
            addressCity: location?.city ? location?.city : "",
            zipcode: "",
            firstName: props?.parsedData?.data?.name?.first,
            lastName: props?.parsedData?.data?.name?.last,
            workPhone: props?.parsedData?.data?.phoneNumbers[0],
            homePhone: props?.parsedData?.data?.phoneNumbers[1],
          };
      setContactInfo({
        ...contactInfo,
        ...contactInfoObject,
      });
      let Skill = props.parsedData.h5h
        ? props.parsedData?.primary_skills?.length
          ? props?.parsedData?.primary_skills?.map((item) => {
              return item;
            })
          : []
        : props?.parsedData?.data?.skills?.length
        ? props?.parsedData?.data?.skills?.map((item) => {
            return item.name;
          })
        : [];

      Skill = Skill.filter((SkillItem) => SkillItem);
      // if addedby crintell we are adding this below
      const profileInfoObject = props?.parsedData?.h5h
        ? {
            primarySkills: props.parsedData?.primary_skills ?? [],
            secondarySkills: props.parsedData?.secondary_skills ?? [],
            linkedIn: props.parsedData?.linkedin ?? "",
            facebook: props.parsedData?.websites ?? "",
            skillSet: [],
            description: "",
            experienceYear: props.parsedData?.experience ?? 0,
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
            designation: props?.parsedData?.designation ?? "",
          }
        : {
            primarySkills: Skill,
            linkedIn: props.parsedData.data?.linkedin,
            facebook: props.parsedData.data?.websites[0],
            skillSet: [props.parsedData.data?.profession],
            description: props.parsedData.data?.summary,
            experienceYear:
              props.parsedData.data?.totalYearsExperience?.toString(),
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
          };
      setProfileData(Skill);
      setProfileData({
        ...profileData,
        ...profileInfoObject,
      });

      let experience = [];
      let experienceVal2 = [];
      if (!props.parsedData.h5h) {
        experience =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: item.organization,
            jobTitle: item.jobTitle,
          }));
        experienceVal2 =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: "",
            industry: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }));
      }
      experienceVal2 && setExperienceVal(experienceVal2);
      experience && setExperiences(experience);
      let education = [];
      let educationVal2 = [];
      let objArray = [];
      if (props.parsedData?.education) {
        Object.keys(props.parsedData?.education[0])?.forEach((key) =>
          objArray.push({
            index: key,
            edu: props.parsedData?.education[0][key],
          })
        );
      }
      if (props.parsedData?.h5h) {
        education =
          props.parsedData?.education?.length > 0
            ? objArray?.map((item) => ({
                graduatedYear: item?.edu?.year,
                educationProgram: item?.edu?.program,
                school: item.edu?.school,
                major: item.edu?.major,
              }))
            : [];
        educationVal2 =
          props.parsedData?.education?.length > 0 &&
          props.objArray?.map((item) => ({
            graduatedYear: "",

            program: "",
            school: "",
            major: "",
          }));
      } else {
        education =
          props.parsedData.data?.education &&
          props.parsedData.data?.education.map((item) => ({
            educationProgram: item.accreditation?.education,
            school: item.organization,
            major: item.accreditation?.inputStr,
          }));
        educationVal2 =
          props.parsedData?.data?.education &&
          props.parsedData?.data?.education.map((item) => ({
            graduatedYear: "",
            educationType: "",
            educationProgram: "",
            school: "",
            major: "",
          }));
      }
      educationVal2 && setEducationVal(educationVal2);
      education && setEducations(education);
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      {(loading || load) && <Loader />}
      {submitClicked && <Loader />}
      <div>
        <div class="bl-modal-body" style={{ padding: "0px 30px 30px 30px" }}>
          <div class="mb-2 mt-3">
            <div class="card mb-2">
              <div class="card-body">
                {props.fileName && (
                  <div
                    v-if="item.filename"
                    class="d-flex align-items-center justify-content-between hoverable"
                  >
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-coral avatar-sm">
                        <i class="fad fa-user"></i>
                      </div>
                      <p class="mb-0 ml-2">File Name</p>
                    </div>
                    <div>
                      <i
                        v-if="item.isExpanded"
                        class="fal fa-fw fa-chevron-square-down"
                      ></i>
                      <i class="fal fa-fw fa-chevron-square-up"></i>
                    </div>
                  </div>
                )}
                <div v-if="item.isExpanded">
                  <div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("contact");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {contact === true ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Contact Info</small>
                      </span>
                    </button>
                    <div
                      className={
                        contact === true ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                        <ContactInfo
                          contactInfo={contactInfo}
                          setContactInfo={setContactInfo}
                          contactInfoValidation={contactVal}
                          profileInfo={profileData}
                          setProfileInfo={setProfileData}
                          isFormValid={isFormValid}
                          isFrom={props?.isFrom ? props?.isFrom : "ManualEntry"}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("profile");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {profileInfo === true ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Profile Info</small>
                      </span>
                    </button>
                    <div
                      className={
                        profileInfo === true
                          ? "ml-2 ml-0-600 mt-2 mb-4"
                          : "d-none"
                      }
                    >
                      <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                        <ProfileInfo
                          profileInfo={profileData}
                          setProfileInfo={setProfileData}
                          profileInfoValidation={profileVal}
                          changePreferredLocation={(location) => {
                            setProfileData({
                              ...profileData,
                              preferredLocation: location,
                            });
                          }}
                          isFormValid={isFormValid}
                          dropDownData={dropDownData}
                          isFrom={props.isFrom}
                          extractedData={extractedData}
                          setExtractedData={setExtractedData}
                        />
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        onCollapsibleClick("experience");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                      disabled={profileData.isFresher}
                    >
                      {experience === true && !profileData.isFresher ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Work Experience</small>
                      </span>
                    </button>
                    <div
                      className={
                        experience === true && !profileData.isFresher
                          ? "ml-2 ml-0-600 mt-2 mb-4"
                          : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <Experience
                            experiences={experiences}
                            setExperiences={setExperiences}
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            experienceValidation={experienceVal}
                            setExperienceValidation={setExperienceVal}
                            dropDownData={dropDownData}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("education");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {education === true ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Education</small>
                      </span>
                    </button>
                    <div
                      className={
                        education === true
                          ? "ml-2 ml-0-600 mt-2 mb-4"
                          : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <Education
                            educations={educations}
                            setEducations={setEducations}
                            educationValidation={educationVal}
                            setEducationValidation={setEducationVal}
                            dropDownData={dropDownData}
                          />
                        </div>
                      </div>
                    </div>
                    {props.parent !== "applyForm" && (
                      <>
                        <button
                          onClick={() => {
                            onCollapsibleClick("certification");
                          }}
                          type="button"
                          class="btn btn-collapsible mb-2"
                        >
                          {certification === true ? (
                            <i class="fal fa-angle-up fa-fw mt-1"></i>
                          ) : (
                            <i class="fal fa-angle-down fa-fw"></i>
                          )}
                          <span>
                            <small>Certifications</small>
                          </span>
                        </button>
                        <div
                          className={
                            certification === true
                              ? "ml-2 ml-0-600 mt-2 mb-4"
                              : "d-none"
                          }
                        >
                          <div class="card card-flat card-lg ml-4 ml-0-600">
                            <div class="card-body">
                              <Certifications
                                certifications={certifications}
                                setCertifications={setCertifications}
                                certificationValidation={certificationVal}
                                setCertificationValidation={setCertificationVal}
                                dropDownData={dropDownData}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            onCollapsibleClick("license");
                          }}
                          type="button"
                          class="btn btn-collapsible mb-2"
                        >
                          {license === true ? (
                            <i class="fal fa-angle-up fa-fw mt-1"></i>
                          ) : (
                            <i class="fal fa-angle-down fa-fw"></i>
                          )}
                          <span>
                            <small>Licenses</small>
                          </span>
                        </button>
                        <div
                          className={
                            license === true
                              ? "ml-2 ml-0-600 mt-2 mb-4"
                              : "d-none"
                          }
                        >
                          <div class="card card-flat card-lg ml-4 ml-0-600">
                            <div class="card-body">
                              <License
                                licenses={licenses}
                                setLicenses={setLicenses}
                                setCertifications={setCertifications}
                                licenseValidation={licenseVal}
                                setLicenseValidation={setLicenseVal}
                                dropDownData={dropDownData}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {props.parent == "applyForm" && (
                      <>
                        <button
                          onClick={() => {
                            onCollapsibleClick("preferences");
                          }}
                          type="button"
                          class="btn btn-collapsible mb-2"
                        >
                          {preferences === true ? (
                            <i class="fal fa-angle-up fa-fw mt-1"></i>
                          ) : (
                            <i class="fal fa-angle-down fa-fw"></i>
                          )}
                          <span>
                            <small>Preferences</small>
                          </span>
                        </button>
                        <div
                          className={
                            preferences === true
                              ? "ml-2 ml-0-600 mt-2 mb-1"
                              : "d-none"
                          }
                        >
                          <div class="card card-flat card-lg ml-4 ml-0-600">
                            <Preferences
                              parent="DirectSourcing"
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              profileInfoValidation={profileVal}
                              changePreferredLocation={(location) => {
                                setProfileData({
                                  ...profileData,
                                  preferredLocation: location,
                                });
                              }}
                              job={props.job}
                              message={props.message}
                              isFormValid={isFormValid}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {showSuccessModal && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Add Talent"}
            isModalOpen={showSuccessModal}
            setModalClose={setShowSuccessModal}
            isFrom="ManualEntry"
            successData={successData}
            parentModalClose={props.closeModal}
          />
        )} */}
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            disabled={submitClicked}
            onClick={() => {
              setIsFormValid(false);
              props.closeModal();
            }}
          >
            Cancel
          </button>
          {props?.isFrom === "TalentProfileEdit" ? (
            <button type="button" class="btn btn-primary btn-sm">
              {submitClicked === false ? (
                <span
                  onClick={() => {
                    submitAddTalent();
                  }}
                >
                  Update
                </span>
              ) : (
                <span v-if="submittingForm">
                  Updating Talent
                  <img width="20px" src={imageLoader} alt="" />
                </span>
              )}
            </button>
          ) : submitClicked === false ? (
            <button
              type="button"
              class="btn btn-primary btn-sm"
              onClick={() => {
                if (
                  existingCandidateList
                    .map((i) => i.email)
                    .includes(contactInfo.email)
                )
                  toast.error("Talent Already Exists");
                else submitAddTalent();
              }}
            >
              <span>Add</span>
            </button>
          ) : (
            <button type="button" class="btn btn-primary btn-sm">
              <span v-if="submittingForm">
                Adding Talent
                <img width="20px" src={imageLoader} alt="" />
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
export default AddTalentManualEntry;
