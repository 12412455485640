import React from "react";
import CommunityList from "../../../talentcommunity/communitylist/CommunityList";
import {
  getTenantType,
  getUserTenantID,
  isCurator,
} from "../../../../service/AuthService";
import TCHeader from "../../../curationtasks/TCHeader";

const Index = () => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card card-flat card-borderless p-1 p-0-600 mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mr-3 mb-0 mb-xl-0">
                <div className="d-flex justify-content-between mt-3">
                  {getTenantType() === 1 ? (
                    <h6>Talent Communities</h6>
                  ) : (
                    <h6>Branded Talent Communities</h6>
                  )}
                </div>
              </h6>
              {getTenantType() === 1 && isCurator() && (
                <TCHeader
                  type={getTenantType() === 1 ? "" : "public"}
                  clientId={getTenantType() === 1 ? getUserTenantID() : null}
                />
              )}
            </div>
            <hr className="mt-0 pt-0" />
            <CommunityList
              type={getTenantType() === 1 ? "" : "public"}
              clientId={getTenantType() === 1 ? getUserTenantID() : null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
