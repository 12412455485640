import React from "react";
import { FaEdit } from "react-icons/fa";
import ReactQuill from "react-quill";
import { BiHistory } from "react-icons/bi";
import moment from "moment";

const TemplateIndex = (props) => {
  const { emailData, isValid, sanitizeHTML } = props;
  return (
    <>
      {!props.editMode && !props.previewMode && !props.testMailMode && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-sm m-0 mb-2"
            style={{ float: "right" }}
            onClick={() => {
              props.setEditMode(true);
            }}
          >
            <FaEdit /> Edit
          </button>
        </>
      )}
      <label htmlFor="editSubject" className="form-label mt-2 pt-0">
        <b>Recipient Persona</b>
      </label>
      <input
        type="text"
        className="form-control"
        id="editSubject"
        style={{
          border:
            isValid && emailData?.recipients[0] === "" && "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
          borderRadius: "0px",
          fontSize: "0.9em",
        }}
        value={emailData?.recipients[0]}
        disabled={true}
      />
      <label htmlFor="editSubject" className="form-label">
        <b>Subject</b>
      </label>
      <input
        type="text"
        className="form-control"
        id="editSubject"
        style={{
          border:
            isValid && emailData?.mailSubject === "" && "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
          borderRadius: "0px",
          fontSize: "0.9em",
        }}
        value={emailData?.mailSubject}
        disabled={true}
      />
      <label htmlFor="editContent" className="form-label">
        <b>Content</b>
      </label>
      <ReactQuill
        value={emailData?.mailContent}
        style={{
          border:
            isValid &&
            sanitizeHTML(emailData?.mailContent) === "" &&
            "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
        }}
        theme="snow"
        readOnly={true}
        // style={{ lineHeight: "0" }}
      />
      <label htmlFor="editFooter" className="form-label">
        <b>Footer</b>
      </label>
      <ReactQuill
        value={emailData?.footer + emailData?.unsubscribeContent}
        style={{
          border:
            isValid &&
            sanitizeHTML(emailData?.footer + emailData?.unsubscribeContent) ===
              "" &&
            "2px solid #ff0000",
          backgroundColor: "#F6F6F6",
        }}
        readOnly={true}
        theme="snow"
        className="mb-2"
      />
      {emailData &&
        emailData?.logDetails &&
        JSON.parse(emailData?.logDetails)?.length > 0 && (
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-12 mb-3 mt-1">
              <hr />
              <b>
                <BiHistory size={20} color="#3ab549" /> Audit Logs
              </b>
            </div>
            <div className="col-md-12 mb-2">
              <table className="table-bordered table responsiveTable">
                <thead className="bg-light text">
                  <tr>
                    <th>Time</th>
                    <th>Email ID</th>
                    <th>User Name</th>
                    <th>Action</th>
                    <th>IP Address</th>
                    <th>Browser</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(emailData?.logDetails)?.map((item) => {
                    return (
                      <tr>
                        <td>
                          {moment(item.createdDateTime).format(
                            "MM/DD/YYYY HH:mm:ss"
                          )}
                        </td>
                        <td>{item.email}</td>
                        <td>{item.firstName + " " + item.lastName}</td>
                        <td>
                          <span
                            className={`tag mb-1 mr-1 px-2 ${
                              item.action === "Activated"
                                ? "tag-green3"
                                : item.action === "Paused"
                                ? "tag-coral"
                                : "tag-blue1"
                            }`}
                          >
                            {item.action}
                          </span>
                        </td>
                        <td>{item.operatingsystem}</td>
                        <td>
                          {item?.browser
                            ? JSON.parse(item?.browser).name +
                              " " +
                              "Version " +
                              JSON.parse(item?.browser).version
                            : "Not Found"}
                        </td>
                        <td>{item?.location ? item?.location : "Not Found"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </>
  );
};

export default TemplateIndex;
