import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ShowSupportQuestions from "./ShowSupportQuestions";
import EditSupportQuestions from "./EditSupportQuestions";
import { get, post } from "../../../service/ApiService";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Loader from "../../common/loader/Loader";
import { toast } from "react-toastify";
import TemplateSidePanel from "./TemplateSidePanel";
import AddTemplate from "./AddJobTemplate";
import { getTenantType, getUserRole } from "../../../service/AuthService";
import Pagination from "./TemplatePagination";
import MainAddJob from "../../job/addjob/addjobtype/JobDetails";
import TopNavBar from "../../layout/TopNavbar";
import SideNavBar from "../../layout/SideNavbar";
import Footer from "../../layout/Footer";

function JobTemplate() {
  const [isQuestionsModel, setIsQuestionsModel] = useState(false);
  const [isEmailModel, setIsEmailModel] = useState(false);
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [FAQData, setFAQData] = useState({});
  const [sidePanel, setSidePanel] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const roleDetails = [
    { name: "Admin", id: "1" },
    { name: "Candidate", id: "2" },
    { name: "Recruiter", id: "3" },
    { name: "Hiring Manager", id: "4" },
    // {name: "Employee", id: '5'},
    { name: "Super Admin", id: "6" },
    // {name: "Sales Admin", id: '7'},
    { name: "Customer Success Manager", id: "8" },
    { name: "Marketplace Manager", id: "9" },
    { name: "High5 Admin", id: "11" },
  ];

  const [templates, setTemplates] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [categoryPublic, setCategoryPublic] = useState("all");
  const [categoryUser, setCategoryUser] = useState("all");
  const [templateDetails, setTemplateDetails] = useState({});
  const [editTemplate, setEditTemplate] = useState(false);
  const [publicTemplates, setPublicTemplates] = useState(true);
  const [userTemplates, setUserTemplates] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [count, setCount] = useState({ publicTemplates: 0, userTemplates: 0 });

  const handleChangeCount = (tab, count) => {
    if (tab === "public") {
      setCount((data) => ({ ...data, publicTemplates: count }));
    } else {
      setCount((data) => ({ ...data, userTemplates: count }));
    }
  };

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const fetchFAQ_API = async () => {
    try {
      setLoading(true);
      let res = await get("/support");
      if (res.status === 200) {
        setFaq(
          res.data.map((i) => ({
            ...i,
            ImageURL: i.ImageURL ? JSON.parse(i.ImageURL)[0].ImageURL : "",
          }))
        );
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      let res = await get("/job/fetchTemplates");
      if (res.status === 200) {
        setTemplates(
          res.data.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          )
        );
        setSearchData(
          res.data.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          )
        );
        let temp = res?.data || [];
        setCount({
          publicTemplates: temp?.filter((e) => e.createdBy === 1).length,
          userTemplates: temp?.filter((e) => e.createdBy != 1).length,
        });
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing Templates");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Some Error Occured While accessing Templates");
    }
  };

  useEffect(() => {
    fetchFAQ_API();
    fetchTemplates();
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    setSearchData(
      templates.filter(
        (item) =>
          item?.title.toLowerCase().includes(value.toLowerCase()) ||
          item?.templateName.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const close = () => {
    setIsQuestionsModel(!isQuestionsModel);
  };

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
      <SideNavBar />
      <div id="Content">
        <div className="section section-sm">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 px-5">
                <h5 className="mb-4">Job Templates</h5>
                {loading && <Loader />}

                <div className="px-3">
                  {/* TEMPLATES */}
                  <>
                    {true && (
                      <div className="d-flex align-items-center justify-content-end mb-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm"
                          onClick={() => setTemplateModal(true)}
                        >
                          <span>
                            <i
                              className="fas fa-fw mr-2 fa-plus "
                              aria-hidden="true"
                            ></i>
                            Add Job Template
                          </span>
                        </button>
                      </div>
                    )}
                    {true && (
                      <div>
                        <Accordion>
                          <AccordionDetails>
                            <div className="row">
                              <div className="col-lg-12">
                                <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
                                  <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                                    <li class="nav-item">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPublicTemplates(true);
                                          setUserTemplates(false);
                                        }}
                                        class={
                                          publicTemplates
                                            ? "nav-link pb-3 mx-2 bg-transparent active"
                                            : "nav-link pb-3 mx-2 bg-transparent"
                                        }
                                      >
                                        <span class="tag mb-1">
                                          {count.publicTemplates}
                                        </span>
                                        <div>Public Templates</div>
                                      </button>
                                    </li>
                                    <li class="nav-item">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setPublicTemplates(false);
                                          setUserTemplates(true);
                                        }}
                                        class={
                                          userTemplates
                                            ? "nav-link pb-3 mx-2 bg-transparent active"
                                            : "nav-link pb-3 mx-2 bg-transparent"
                                        }
                                      >
                                        <span class="tag mb-1">
                                          {count.userTemplates}
                                        </span>
                                        <div>User Templates</div>
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                                {publicTemplates && (
                                  <div className="p-3">
                                    <div className="mb-2">
                                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                          <select
                                            className="form-select mb-2 font-14"
                                            onChange={(e) =>
                                              setCategoryPublic(e.target.value)
                                            }
                                          >
                                            <option value="all">All</option>
                                            {[
                                              ...new Set(
                                                templates.map(
                                                  (item) => item.category
                                                )
                                              ),
                                            ].map((item) => (
                                              <option
                                                selected={
                                                  item === categoryPublic
                                                }
                                                value={item}
                                              >
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                          <input
                                            onChange={(e) =>
                                              handleSearch(e.target.value)
                                            }
                                            type="search"
                                            className="form-control small font-14"
                                            placeholder="Search Templates"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {publicTemplates && (
                                  <Pagination
                                    searchData={searchData}
                                    category={categoryPublic}
                                    openSidePanel={(item) => {
                                      setTemplateDetails(item);
                                      setSidePanel(true);
                                    }}
                                    searchValue={searchValue}
                                    publicTemplates={publicTemplates}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    onChangeCount={(count) =>
                                      handleChangeCount("public", count)
                                    }
                                  />
                                )}
                                {userTemplates && (
                                  <div className="p-3">
                                    <div className="mb-2">
                                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                          <select
                                            className="form-select mb-2 font-14"
                                            onChange={(e) =>
                                              setCategoryUser(e.target.value)
                                            }
                                          >
                                            <option value="all">All</option>
                                            {[
                                              ...new Set(
                                                templates
                                                  .filter(
                                                    (i) => i.createdBy != 1
                                                  )
                                                  .map((item) => item.category)
                                              ),
                                            ].map((item) => (
                                              <option
                                                selected={item === categoryUser}
                                                value={item}
                                              >
                                                {item}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                          <input
                                            onChange={(e) =>
                                              handleSearch(e.target.value)
                                            }
                                            type="search"
                                            className="form-control small font-14"
                                            placeholder="Search Templates"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {userTemplates && (
                                  <Pagination
                                    searchData={searchData}
                                    category={categoryUser}
                                    openSidePanel={(item) => {
                                      setTemplateDetails(item);
                                      setSidePanel(true);
                                    }}
                                    searchValue={searchValue}
                                    publicTemplates={publicTemplates}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    onChangeCount={(count) =>
                                      handleChangeCount("user", count)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </>
                </div>

                <Modal show={isQuestionsModel} size="lg">
                  <Modal.Header>
                    <Modal.Title className="pt-1 font-16">
                      <h6>Add Frequently Asked Questions</h6>
                    </Modal.Title>
                    <button
                      type="button"
                      onClick={close}
                      className="close p-0 bl-modal-close-btn closable"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fal fa-times closable"></i>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <ShowSupportQuestions
                      close={close}
                      fetchFAQ_API={fetchFAQ_API}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={editModal} size="lg">
                  <Modal.Header>
                    <Modal.Title className="pt-1 font-16">
                      <h6>Edit Frequently Asked Questions</h6>
                    </Modal.Title>
                    <button
                      type="button"
                      onClick={() => setEditModal(false)}
                      className="close p-0 bl-modal-close-btn closable"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fal fa-times closable"></i>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <EditSupportQuestions
                      close={() => setEditModal(false)}
                      fetchFAQ_API={fetchFAQ_API}
                      FAQData={FAQData}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={false} size="lg">
                  <Modal.Header closeButton>
                    <h6 class="">
                      Add {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
                    </h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setTemplateModal(false)}
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <AddTemplate
                      templates={templates}
                      closePanel={() => setTemplateModal(false)}
                      fetchTemplates={fetchTemplates}
                    />
                  </Modal.Body>
                  {false && (
                    <Modal.Footer>
                      <div className="row align-items-center">
                        <div className="col-lg-12 my-2 mb-lg-0">
                          <div className="form-check pl-0 form-switch d-flex align-items-center">
                            <label className="form-check-label pt-0">
                              <strong>Save as Template?</strong>
                            </label>
                            <input
                              className="form-check-input ml-3"
                              type="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => setTemplateModal(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => setTemplateModal(false)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  )}
                </Modal>

                <Modal show={templateModal || editTemplate} size="lg">
                  <Modal.Header>
                    <h6 class="">{editTemplate ? "Edit" : "Add"} Template</h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <MainAddJob
                      addTemplate={templateModal}
                      editTemplate={editTemplate}
                      closePanel={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      fetchTemplate={fetchTemplates}
                      tempDetails={templateDetails}
                    />
                  </Modal.Body>
                </Modal>

                {sidePanel && templateDetails && (
                  <TemplateSidePanel
                    setLoading={setLoading}
                    fetchTemplates={fetchTemplates}
                    setEditTemplate={setEditTemplate}
                    closePanel={() => setSidePanel(false)}
                    details={templateDetails}
                  />
                )}

                <Modal show={false} size="lg">
                  <Modal.Header closeButton>
                    <h6 class="">
                      Edit {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
                    </h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setEditTemplate(false)}
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <MainAddJob
                      addTemplate={templateModal}
                      editTemplate={editTemplate}
                      templates={templates}
                      closePanel={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      fetchTemplate={fetchTemplates}
                      tempDetails={templateDetails}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
}

export default JobTemplate;
