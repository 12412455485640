import React from "react";

const ReferredBy = ({ details, referred, onCollapsibleClick }) => {
  return (
    <>
      {typeof details?.isreferred === "string" &&
        details.isreferred.trim().length > 0 && (
          <button
            onClick={() => {
              onCollapsibleClick("referred");
            }}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            {!referred ? (
              <i className="fal fa-angle-up fa-fw mt-1"></i>
            ) : (
              <i className="fal fa-angle-down fa-fw"></i>
            )}
            <span>
              <small>Referred By</small>
            </span>
          </button>
        )}
      <div className={referred ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <div class="d-flex mb-2 pl-1 ml-4">{details.isreferred}</div>
      </div>
    </>
  );
};

export default ReferredBy;
