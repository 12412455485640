import {
  GET_CANDIDATE_DETAILS_BEGIN,
  GET_CANDIDATE_DETAILS_SUCCESS,
  GET_CANDIDATE_DETAILS_FAILURE,
} from "../../action/candidate/getCandidateDetails";

const initialState = {
  candidateDetailsLoading: true,
  candidateData: null,
};

const candidateDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CANDIDATE_DETAILS_BEGIN:
      return {
        ...state,
        candidateDetailsLoading: true,
      };
    case GET_CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        candidateData: payload,
        candidateDetailsLoading: false,
      };
    case GET_CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        candidateDetailsLoading: false,
      };
    default:
      return state;
  }
};

export default candidateDetailsReducer;
