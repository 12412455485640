import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { domain, emailDomain } from "../../../constants/Constants";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import JobSidePanel from "../myjobscard/JobSidePanel";
import Loader from "../../loader/Loader";

const OffersCard = ({
  onChangeClick,
  tabType,
  offer,
  candidateData,
  getOffers,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [description, setDescription] = useState(false);
  const [jobDetails, setJobDetails] = useState(true);
  const [enableValue, setenable] = useState(false);
  const [isJobCard, setIsJobCard] = useState(false);
  const [jobData, setJobData] = useState({});
  const [loading, setLoading] = useState(false);
  const getJobDetails = async (id) => {
    setLoading(true);
    const params = { domain: domain, jobId: offer?.jobId };
    const res = await post("/job/getjobdetails", params);
    if (res.status === 200) {
      console.log("res", res);
      setJobData(res.data[0]);
      setLoading(false);
      setIsJobCard(true);
    } else {
      setLoading(false);
    }
  };

  const handleModel = () => {
    setModal(false);
    setConfirmModal(false);
  };
  const handleOfferUpdate = async (e) => {
    if (e.target.name === "reject" && reason === "")
      return toast.error("Please select a reason.");

    setLoader(true);
    let params = {};

    if (e.target.name === "reject") {
      params = {
        status: 29,
        disqualifyComments: `Offer Rejected : ${reason}`,
        disqualifiedDate: new Date(),
        id_disposition: offer.id_disposition,
        candidateId: offer.candidateId,
        jobTitle: offer.jobTitle,
        jobTenant: offer.jobTenant,
        jobId: offer.jobId ? offer.jobId : offer?.fk_talentPool,
        candidateEmail: candidateData.email,
        candidateFname: candidateData.firstName,
        candidateLname: candidateData.lastName,
        createdBy: offer.createdBy,
        recruiterId: offer.recruiterId,
        offeredBy: offer.offeredBy,
      };
    } else {
      params = {
        status: 11,
        id_disposition: offer.id_disposition,
        candidateId: offer.candidateId,
        jobTitle: offer.jobTitle,
        jobTenant: offer.jobTenant,
        jobId: offer.jobId ? offer.jobId : offer?.fk_talentPool,
        candidateEmail: candidateData.email,
        candidateFname: candidateData.firstName,
        candidateLname: candidateData.lastName,
        createdBy: offer.createdBy,
        joiningDate: offer.joiningDate,
        recruiterId: offer.recruiterId,
        offeredBy: offer.offeredBy,
      };
    }

    try {
      setLoading(true);
      let response = await post("/disposition/updatedisposition", params);
      if (response.status === 200) {
        if (e.target.name !== "reject") {
          toast.success("Offer accepted");
          await SMS("accept");
          await SMS2("accept");
          window.location.reload();
          setLoading(false);
        } else {
          toast.success("Offer declined");
          await SMS("reject");
          await SMS2("reject");
          window.location.reload();
          setLoading(false);
        }
        handleModel();
        setLoader(false);
        setenable(true);
        getOffers();
        setLoading(false);
      }
    } catch (err) {
      setLoader(false);
      // toast.error("Something went wrong");
    }
  };

  const SMS = async (str) => {
    try {
      let phoneNumber =
        candidateData?.workPhone?.replaceAll(" ", "") ||
        `${candidateData?.homePhoneCode}${candidateData?.homePhone?.replaceAll(
          " ",
          ""
        )}`;

      const name = candidateData?.firstName;
      const jobName = offer.jobTitle;

      const getContent = async (event) => {
        const req = { event, recipient: '["Candidate"]' };
        const res = await post("/msgtwoway/getSmsContent", req);
        return res.status === 200 ? res.data[0] : null;
      };

      const hiringContent = await getContent("Hired");
      const rejectedContent = await getContent("Offer Declined");

      const contentText = (content) => {
        let messageContent = content?.smsContent || "";
        messageContent = messageContent.replace("[First Name]", name);
        messageContent = messageContent.replace("[Job Title]", jobName);
        messageContent = messageContent.replace(
          "[CareerPageLinkToLogin]",
          `${emailDomain}/careers/jobList`
        );
        return messageContent;
      };

      const payload = [
        {
          recieverNumber: [phoneNumber],
          //   message: `Hi ${name}, Congratulations! You are hired for the ${jobName} job.\nPlease click below to login into the application and officially get started.
          //   Click Here ${emailDomain}/careers/jobList\nThe High5 Team `,
          //   message: `Hi ${name}, You have rejected the ${jobName} job offer.\nPlease click below to log in for more details.
          //   Click Here ${emailDomain}/careers/jobList\nThe High5 Team `,
          message:
            contentText(str === "accept" ? hiringContent : rejectedContent) +
            `\n ${hiringContent?.footer}`,
        },
      ];

      if (phoneNumber?.startsWith("+1")) {
        await post("/msgtwoway/telSms", payload);
      } else {
        await post("/massages/sentMassageWhatsapp", payload);
      }
    } catch (error) {
      // Handle errors if needed
      // toast.error("An error occurred");
    }
  };
  const SMS2 = async (str) => {
    let name = candidateData?.firstName;
    let jobName = offer.jobTitle;
    let tenant = {
      tenant: offer.fk_tenant,
      createdBy: offer.createdBy,
      // tenant: offer.jobTenant,
    };

    // const numbers = await post("/msgtwoway/getAllNumbers", tenant);
    const numbers = await post("/msgtwoway/getNumbersForOffer", {
      jobId: offer.jobId,
      createdBy: offer.createdBy,
    });
    const numberData = numbers.data;

    if (numberData.length === 0) return;

    const recepientsDataSms = [];
    const recepientsDataWhatsApp = [];

    await numberData.map((obj) => {
      const recepientDataSms = [];
      const recepientDataWhatsApp = [];

      let home = obj.homePhone ? obj.homePhone : "";
      let mobile = obj.mobilePhone ? obj.mobilePhone : "";
      let work = obj.workPhone ? obj.workPhone : "";

      if (home.charAt(0) != "+") home = `+${obj.homePhone}`;
      if (mobile.charAt(0) != "+") mobile = `+${obj.mobilePhone}`;
      if (work.charAt(0) != "+") work = `+${obj.workPhone}`;

      if (home.charAt(0) == "+" && home.charAt(1) != "1" && home.length > 10)
        recepientDataWhatsApp.push(home.replaceAll(" ", ""));
      if (
        mobile.charAt(0) == "+" &&
        mobile.charAt(1) != "1" &&
        mobile.length > 10
      )
        recepientDataWhatsApp.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) != "1" && work.length > 10)
        recepientDataWhatsApp.push(work.replaceAll(" ", ""));

      if (home.charAt(0) == "+" && home.charAt(1) == "1")
        recepientDataSms.push(home.replaceAll(" ", ""));
      if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
        recepientDataSms.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) == "1")
        recepientDataSms.push(work.replaceAll(" ", ""));

      if (recepientDataSms.length > 0) {
        recepientsDataSms.push({
          recieverNumber: recepientDataSms,
          userId: Math.floor(Math.random() * 1000000),
          message: `Hi ${
            obj.firstName
          }, Just letting you know ${name} has been ${
            str === "accept" ? "hired" : "rejected"
          } for the ${jobName} job.\nClick below to see details.\n${emailDomain}\nThe High5 Team`,
        });
      }

      if (recepientDataWhatsApp.length > 0) {
        recepientsDataWhatsApp.push({
          recieverNumber: recepientDataWhatsApp,
          message: `Hi ${
            obj.firstName
          }, Just letting you know ${name} has been ${
            str === "accept" ? "hired" : "rejected"
          } for the ${jobName} job.\nClick below to see details.\n${emailDomain}\nThe High5 Team`,
        });
      }
    });

    try {
      if (recepientsDataSms.length > 0)
        await post("/msgtwoway/telSms", recepientsDataSms);
    } catch (error) {
      console.log(error);
      // toast.error("An error occurred");
    }
    try {
      if (recepientsDataWhatsApp.length > 0)
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
    } catch (error) {
      console.log(error);
      // toast.error("An error occurred");
    }
  };
  const getReasons = async () => {
    setLoader(true);
    try {
      let response = await get("/makeanoffer/getRejectReasons");
      if (response.status === 200) {
        setReasons(response.data);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      // toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getReasons();
  }, []);
  return (
    <>
      {loading && <Loader />}
      {/* <a href="/candidate/jobs/1212321" class=" card card-flat p-0 w-100 mb-1"> */}
      <div class="card-body ">
        <div>
          <div>
            <div>
              <span class="tag tag-coral mr-1">
                {offer?.jobId ? "Job offer" : "Ready To Hire"}
              </span>
              {/* {moment(new Date()).diff(offer?.offeredDate, "days") == 1 && (
                  <span class="tag tag-green3">New</span>
                )} */}
            </div>{" "}
            <h6
              class="mt-2"
              onClick={() => getJobDetails()}
              style={{ cursor: "pointer" }}
            >
              {offer?.jobTitle}
            </h6>
          </div>{" "}
          <div class="d-flex align-items-center">
            {offer?.jobType && (
              <span class="d-flex align-items-center me-3">
                <i
                  aria-hidden="true"
                  class="far fa-suitcase me-2 font-brandBlue"
                ></i>{" "}
                <span class="font-regular font-primary font-gray1">
                  {offer?.jobType}
                </span>
              </span>
            )}{" "}
            <span class="d-flex align-items-center me-3">
              <i
                aria-hidden="true"
                class="far fa-map-marker-alt me-2 font-brandBlue"
              ></i>{" "}
              <span class="font-regular font-primary font-gray1">
                {offer?.country == "" ? offer?.workplaceType : offer?.country}
              </span>
            </span>{" "}
            {offer?.offeredSalary && (
              <span class="d-flex align-items-center me-3">
                <i
                  aria-hidden="true"
                  class="far fa-piggy-bank me-2 font-brandBlue"
                ></i>{" "}
                <span class="font-regular font-primary font-gray1">
                  {/* {offer?.offeredSalary} {offer?.offeredCurrency} */}
                  {Number(offer?.offeredSalary).toLocaleString() +
                    " " +
                    offer?.offeredCurrency}
                </span>
              </span>
            )}
          </div>{" "}
          {offer?.OfferedRecruiterFname && (
            <div class="mt-1">
              <small class="font-regular">
                {`Offered by ${offer?.OfferedRecruiterFname} ${offer?.OfferedRecruiterLname}`}
              </small>
            </div>
          )}
        </div>{" "}
        <hr class="mb-2" />{" "}
        {offer?.jobId && (
          <div>
            <button
              type="button"
              class="btn btn-sm btn-blue-disabled mr-1"
              onClick={() => {
                setConfirmModal(true);
              }}
              disabled={
                offer?.statusname === "Hired" ||
                offer?.statusname === "Offer Rejected"
              }
            >
              <span>Accept</span>{" "}
            </button>{" "}
            <button
              type="button"
              class="btn btn-danger btn-sm btn-blue-disabled"
              disabled={
                offer?.statusname === "Hired" ||
                offer?.statusname === "Offer Rejected"
              }
              onClick={() => {
                setModal(true);
              }}
            >
              <span>Decline</span>{" "}
            </button>
          </div>
        )}
      </div>
      {/* </a> */}
      <Modal
        size="lg"
        show={confirmModal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">{"Accept offer"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleModel}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <>
            <div>
              <div className="text-center mt-4">
                <p className=" mb-1">
                  Great! We are glad that you are excited to proceed with this
                  offer.
                </p>
                <p className=" ">Would you like to accept the offer?</p>{" "}
              </div>
            </div>

            <div v-if="!formComplete" className="modal-footer ">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={handleModel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleOfferUpdate}
                name="accept"
              >
                Accept
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={modal}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">{"Decline offer"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleModel}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <>
            <div>
              <div className="text-center">
                <h6 className="mt-3">{offer?.jobTitle}</h6>
                <p>
                  {offer.workplaceType} / {offer.jobTenant} / {offer.jobType}
                </p>
              </div>
            </div>
            <div className="mt-3 mb-0 text-center">
              <p className="mb-0">
                Are you sure you want to decline this offer?
              </p>
            </div>
            <div className="card-body px-5 ">
              <div className="form-floating  ">
                <select
                  className={`form-select font-14`}
                  id="category"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {reasons.map((item) => (
                    <option key={item.Id} value={item.ReasonForRejection}>
                      {item.ReasonForRejection}
                    </option>
                  ))}
                </select>
                <label for="category">
                  Please select your reason for declining this offer.*
                </label>
              </div>
            </div>

            <div v-if="!formComplete" className="modal-footer ">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={handleModel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={handleOfferUpdate}
                name="reject"
              >
                Decline
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>{" "}
      {isJobCard && Object.keys(jobData).length > 0 && (
        <JobSidePanel
          setIsJobCard={setIsJobCard}
          jobData={jobData}
          candidateData={candidateData}
          isFrom="offers"
          offer={offer}
        />
      )}
    </>
  );
};

export default OffersCard;
