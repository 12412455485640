import {
  GET_TALENT_COUNT_BEGIN,
  GET_TALENT_COUNT_SUCCESS,
  GET_TALENT_COUNT_FAILURE,
} from "../../action/talent/TalentCount";

const initialState = {
  loading: true,
  talentCount: null,
};

const getTalentCountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        talentCount: null,
      };
    case GET_TALENT_COUNT_SUCCESS:
      return {
        ...state,
        talentCount: payload,
        loading: false,
      };
    case GET_TALENT_COUNT_FAILURE:
      return {
        ...state,
        talentCount: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getTalentCountReducer;
