import React from "react";
import Modal from "react-bootstrap/Modal";

const DeleteInvitationModal = ({ remove, setDelete, updateStatus }) => {
  return (
    <Modal show={remove}>
      <Modal.Header>
        <h6>Delete Invitation</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setDelete(false);
          }}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="bl-modal-body  text-center">
          <div className="avatar avatar-lg avatar-red2 mr-3">
            <i class="fas fa-exclamation-triangle fa-fw"></i>
          </div>
          <p className="text-center lead mt-3">
            Are you sure you want to delete this invitation
          </p>
        </div>
      </Modal.Body>
      <div className="d-flex align-items-center justify-content-center m-3">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => {
            setDelete(false);
          }}
        >
          Cancel
        </button>

        <button
          className="btn btn-sm btn-primary ml-2"
          onClick={() => {
            updateStatus("Deleted");
          }}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default DeleteInvitationModal;
