import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_COMMON_DROPDOWN_BEGIN = "GET_COMMON_DROPDOWN_BEGIN";
export const GET_COMMON_DROPDOWN_SUCCESS = "GET_COMMON_DROPDOWN_SUCCESS";
export const GET_COMMON_DROPDOWN_FAILURE = "GET_COMMON_DROPDOWN_FAILURE";

export const getDropdownValues = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMMON_DROPDOWN_BEGIN,
    });
    const res = await get("/dropdown/dropdownlist");
    if (res.status === 200) {
      dispatch({
        type: GET_COMMON_DROPDOWN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COMMON_DROPDOWN_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const GET_CANDIDATE_DROPDOWN_BEGIN = "GET_CANDIDATE_DROPDOWN_BEGIN";
export const GET_CANDIDATE_DROPDOWN_SUCCESS = "GET_CANDIDATE_DROPDOWN_SUCCESS";
export const GET_CANDIDATE_DROPDOWN_FAILURE = "GET_CANDIDATE_DROPDOWN_FAILURE";

export const getCandidateDropdownValues = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CANDIDATE_DROPDOWN_BEGIN,
    });
    const res = await get("/dropdown/candidatedropdownlist");
    if (res.status === 200) {
      dispatch({
        type: GET_CANDIDATE_DROPDOWN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CANDIDATE_DROPDOWN_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
