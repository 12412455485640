import { validate } from "email-validator";

export const inviteRecruiterValidation = (formModel, Modelvalidation) => {
  let validationArray = [];
  let validationObj = {
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    mobileError: "",
  };
  if (formModel.firstName.trim() === "") {
    validationObj = { ...validationObj, firstNameError: "Required Field" };
    validationArray.push("First Name Cannot be blank");
  }
  if (formModel.lastName.trim() === "") {
    validationObj = { ...validationObj, lastNameError: "Required Field" };
    validationArray.push("Last Name cannot be blank");
  }
  if (formModel.Email.trim() === "") {
    validationObj = {
      ...validationObj,
      emailError: "Email Cannot be blank",
    };
    validationArray.push("invalid email");
  }
  if (formModel.Email && validate(formModel.Email) === false) {
    validationObj = { ...validationObj, emailError: "Invalid Email" };
    validationArray.push("Invalid email");
  }
  if (!Modelvalidation.mobileValidation) {
    validationObj = {
      ...validationObj,
      mobileError: "Invalid phone number",
    };
    validationArray.push("Invalid phone number");
  }
  return { validationArray, validationObj };
};
