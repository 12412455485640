import React from "react";

const LinkedIn = ({ details, onCollapsibleClick, linkedIn }) => {
  return (
    <>
      {details.linkedInAddress && (
        <button
          onClick={() => {
            onCollapsibleClick("linkedIn");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {!linkedIn ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>LinkedIn URL</small>
          </span>
        </button>
      )}
      <div className={linkedIn ? "ml-2 ml-0-600 mt-2 mb-2" : "d-none"}>
        <div class="d-flex pl-1 ml-4">
          <p>
            <a target="_blank" href={details.linkedInAddress}>
              {details.linkedInAddress}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default LinkedIn;
