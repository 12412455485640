import React, { useState } from "react";
import ScreeningQuestions from "./ScreeningQuestions";
import VettingRequirement from "./VettingRequirement";
import AutomatchedParameters from "./AutomatchedParameters";
import ReadyToHireQA from "./ReadyToHireQA";
import AdditionalQuestions from "./AdditionalInfo";

const Index = (props) => {
  const { jobData } = props;
  const [qna, setQNA] = useState(false);
  const [additional, setAdditional] = useState(false);
  const [vetting, setVetting] = useState(false);
  const [automatch, setAutomatch] = useState(false);
  const [readyToHireQA, setReadyToHireQA] = useState(false);
  return (
    <>
      {(jobData?.additionalInfo)
        ?.length > 0 && (
        <button
          onClick={() => setAdditional(true)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          <span>
            <small>
              Additional Info
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setAdditional(true);
                }}
              >
                <span className="ml-1">
                  <i
                    className="fas fa-external-link-square"
                    style={{ fontSize: "small", color: "#FF5F57" }}
                  />
                </span>
              </a>
            </small>
          </span>
        </button>
      )}
      {(jobData.screeningQuestion ? JSON.parse(jobData.screeningQuestion) : [])
        ?.length > 0 && (
        <button
          onClick={() => setQNA(true)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          <span>
            <small>
              Knockout Assessment
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setQNA(true);
                }}
              >
                <span className="ml-1">
                  <i
                    className="fas fa-external-link-square"
                    style={{ fontSize: "small", color: "#FF5F57" }}
                  />
                </span>
              </a>
            </small>
          </span>
        </button>
      )}
      {jobData.vettingDetails != null && (
        <button
          onClick={() => setVetting(true)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          <span>
            <small>
              Vetting
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setVetting(true);
                }}
              >
                <span className="ml-1">
                  <i
                    className="fas fa-external-link-square"
                    style={{ fontSize: "small", color: "#FF5F57" }}
                  />
                </span>
              </a>
            </small>
          </span>
        </button>
      )}
      {jobData.readyToHireQA &&
        jobData?.readyToHireQA !== '[""]' &&
        jobData?.readyToHireQA !== "[]" && (
          <button
            onClick={() => setReadyToHireQA(true)}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            <span>
              <small>
                Ready To Hire
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setReadyToHireQA(true);
                  }}
                >
                  <span className="ml-1">
                    <i
                      className="fas fa-external-link-square"
                      style={{ fontSize: "small", color: "#FF5F57" }}
                    />
                  </span>
                </a>
              </small>
            </span>
          </button>
        )}
      <button
        onClick={() => setAutomatch(true)}
        type="button"
        className="btn btn-collapsible mb-2"
      >
        <span>
          <small>
            Automatch
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setAutomatch(true);
              }}
            >
              <span className="ml-1">
                <i
                  className="fas fa-external-link-square"
                  style={{ fontSize: "small", color: "#FF5F57" }}
                />
              </span>
            </a>
          </small>
        </span>
      </button>
      {/* {qna && ( */}
      <ScreeningQuestions jobData={jobData} setQNA={setQNA} qna={qna} />
      <AdditionalQuestions
        jobData={jobData}
        setQNA={setAdditional}
        qna={additional}
      />
      {/* )} */}
      {/* {vetting && ( */}
      <VettingRequirement
        jobData={jobData}
        setVetting={setVetting}
        vetting={vetting}
      />
      {/* )} */}
      {automatch && (
        <AutomatchedParameters
          jobData={jobData}
          setAutomatch={setAutomatch}
          automatch={automatch}
        />
      )}
      {readyToHireQA && (
        <ReadyToHireQA
          jobData={jobData}
          setQNA={setReadyToHireQA}
          qna={readyToHireQA}
        />
      )}
    </>
  );
};

export default Index;
