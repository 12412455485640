import {
    GET_DISPOSITION_DISQUALIFY_BEGIN,
    GET_DISPOSITION_DISQUALIFY_SUCCESS,
    GET_DISPOSITION_DISQUALIFY_FAILURE,
  } from "../../../action/disposition/talentcommunity/CommunityDisqualifyDisposition";
  
  const initialState = {
    dispositionDisqualifyLoading: true,
    dispositionDisqualify: [],
  };
  
  const dispositionDisqualifyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_DISPOSITION_DISQUALIFY_BEGIN:
        return {
          ...state,
          dispositionDisqualifyLoading: true,
          dispositionDisqualify: [],
        };
      case GET_DISPOSITION_DISQUALIFY_SUCCESS:
        return {
          ...state,
          dispositionDisqualify: payload,
          dispositionDisqualifyLoading: false,
        };
      case GET_DISPOSITION_DISQUALIFY_FAILURE:
        return {
          ...state,
          dispositionDisqualify: [],
          dispositionDisqualifyLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default dispositionDisqualifyReducer;