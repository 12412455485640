import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import HereMap from "../../../../location/HereMap";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../common/loader/Loader";
import ImageUpload from "../../../../common/fileupload/ImageUpload";
import { addClientValidation } from "../../../../validation/AddClient";

function AddClient(props) {
  const dropdownRef = useRef(null);
  const [form, setForm] = useState({
    lastname: "",
    email: "",
    name: "",
    tenant: "",
    tenanttype: "1",
    stateName: "",
    CountryName: "",
    cityName: "",
    address: "",
    zipCode: "NA",
    phone: "",
  });
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });
  const [toggleActions, setToggleActions] = useState(false);

  const [errors, setErrors] = useState({});
  const [phoneValidation, setPhoneValidation] = useState(true);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };
  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  const [loading, setLoading] = useState(false);

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = addClientValidation(form, phoneValidation);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      //  alert("please fill all details ")
    } else if (!phoneValidation) {
      toast.error("Please enter a valid phone number");
    } else if (form.phone == "") {
      newErrors.phone = "*Required Field";
    } else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          ...imageData,
        };
        const res = await post("/dashboard/sa/savetenant", paramters);
        if (res.data.ReturnText === "Successfully added") {
          setLoading(false);
          toast.success("Client Added Successfully ");
          props.closeAdd(true);
          props.getTenantData();
        } else {
          setLoading(false);
          toast.error(res.data.ReturnText);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While saving  tenant  Details");
        props.closeAdd(true);
      }
    }
    // api call
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };
  const close = () => {
    props.closeAdd(true);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  let type = "";
  if (props.userlist) {
    type = props.userlist.type;
  }

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                Add Client
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="showBody" class="bl-modal-body pb-0">
              <div
                v-if="isAssign === false"
                class="card card-flat card-borderless bg-gray4 text-center"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row mt-4">
                      {false && (
                        <div class="col-lg-4">
                          <div class="form-floating">
                            {
                              <Form.Control
                                as="select"
                                disabled
                                onChange={(e) =>
                                  setField("tenanttype", e.target.value)
                                }
                                isInvalid={!!errors.tenanttype}
                              >
                                <option value="">Select Client</option>
                                <option selected value="1">
                                  Client
                                </option>
                              </Form.Control>
                            }
                            <Form.Control.Feedback type="invalid">
                              {errors.tenanttype}
                            </Form.Control.Feedback>
                            <label>Client Type*</label>
                          </div>
                        </div>
                      )}
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2 "
                            style={{
                              border: errors.tenant && "1px solid #ff0000",
                            }}
                            name="tenant"
                            value={form.tenant}
                            onChange={(e) => setField("tenant", e.target.value)}
                            placeholder="Enter Tenant Name"
                          />
                          <label>Client Name*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.tenant}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.email && "1px solid #ff0000",
                            }}
                            name="email"
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            placeholder="Enter email"
                          />
                          <label>Email ID*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.email}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.name && "1px solid #ff0000",
                            }}
                            name="firstName"
                            value={form.name}
                            onChange={(e) => setField("name", e.target.value)}
                            placeholder="First Name*"
                          />
                          <label>First Name*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.name}
                          </small>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.lastname && "1px solid #ff0000",
                            }}
                            name="lastname"
                            value={form.lastname}
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            placeholder="Enter Last Name"
                          />
                          <label>Last Name*</label>
                          {errors.lastname && (
                            <small
                              className="validation"
                              style={{ float: "left" }}
                            >
                              {errors.lastname}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 ">
                      <div className="col-lg-12">
                        <div className="mb-2">
                          <label style={{ float: "left" }}>
                            Phone<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex flex-column">
                          <PhoneInput
                            inputClass={"form-control font-14 w-100"}
                            style={{
                              border: errors.phone && "1px solid #ff0000",
                            }}
                            required={true}
                            placeholder="mobile"
                            id="homePhone"
                            country={"us"}
                            value={form.phone}
                            name="homePhone"
                            onChange={(value, country, e) => {
                              _onChangeText(value, country);
                              setForm({
                                ...form,
                                phone: value,
                                phoneCode: country.dialCode,
                              });
                            }}
                          />
                          {errors.phone && (
                            <span style={{ float: "left" }}>
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.phone}
                              </small>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMap setForm={setForm} form={form} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                            />
                            <label>Address </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control mr-2"
                              id="cityName"
                              style={{
                                border:
                                  errors.cityName &&
                                  form.cityName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="cityName"
                              value={form.cityName}
                              onChange={handleChange}
                              placeholder="Enter City"
                            />
                            <label htmlFor="cityName">City*</label>
                            {errors.cityName && form.cityName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.cityName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="stateName"
                              style={{
                                border:
                                  errors.stateName &&
                                  form.stateName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="stateName"
                              value={form.stateName}
                              onChange={handleChange}
                              placeholder="Address State"
                            />
                            <label htmlFor="stateName">State*</label>
                            {errors.stateName && form.stateName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.stateName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="CountryName"
                              style={{
                                border:
                                  errors.CountryName &&
                                  form.CountryName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="CountryName"
                              value={form.CountryName}
                              onChange={handleChange}
                              placeholder="Address Country"
                            />
                            <label htmlFor="CountryName">Country*</label>
                            {errors.CountryName && form.CountryName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.CountryName}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2 ml-2 mr-3">
                        <ImageUpload
                          imageData={imageData}
                          setImageData={setImageData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddClient;
