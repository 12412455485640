import { post } from "../../service/ApiService";
import {
  fetchLoginLoading,
  fetchLogin,
  fetchLoginError,
} from "../../reducer/auth/Login";

export const login = (params) => async (dispatch) => {
  try {
    dispatch(fetchLoginLoading(true));
    const res = await post("/login", params);
    dispatch(fetchLogin(res.data));
  } catch (error) {
    dispatch(dispatch(fetchLoginError(error.response.data)));
  }
};
