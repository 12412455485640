import React, { useState } from "react";
import { post } from "../../../../../../service/ApiService";
import { toast } from "react-toastify";
import axios from "axios";
import {
  getUserTenantID,
  getUserTenant,
  getCandidateName,
  getCandidateId,
  getUserRole,
} from "../../../../../../service/AuthService";
import { domain } from "../../../../../../constants/Constants";
import Loader from "../../../../../loader/Loader";

const ResendMail = (props) => {
  const { talentData, jobData } = props;
  const [loading, setLoading] = useState(false);

  async function shortenUrl(longUrl) {
    const headers = {
      Accept: "application/json",
      Authorization:
        "Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv",
      "Content-Type": "application/json",
    };
    const requestBody = {
      url: longUrl,
      domain: "high5hire.live",
    };

    try {
      const response = await axios.post(
        "https://api.tinyurl.com/create",
        requestBody,
        { headers }
      );
      if (response.data && response.data.data && response.data.data.tiny_url) {
        const shortenedUrl = response.data.data.tiny_url;
        return shortenedUrl;
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  }

  const resendMail = async () => {
    try {
      const userid = getCandidateId();
      const sendMailLink = `${domain}/career/jobs/${jobData.jobId}/recruiter/${userid}`;
      const shortenedUrl = await shortenUrl(sendMailLink);
      const usertenant = getUserTenant();
      const loggedinusername = getCandidateName();

      const inviteReq = {
        event: "Reminder Invite (Manual - After 48 Hours auto reminder)",
        recipient: '["Candidate"]',
      };
      // const res = await post("/msgtwoway/getSmsContent", inviteReq);
      // const inviteContent = res.status === 200 ? res?.data : [];

      // if (inviteContent && inviteContent?.length !== 0) {
      //   let content = inviteContent[0]?.smsContent;
      //   content = content?.replace("[First Name]", talentData?.firstName);
      //   content = content?.replace("[Job Name]", jobData?.jobTitle);
      //   content = content?.replace("[CLICK HERE]", shortenedUrl);
      //   inviteContent[0].smsContent = content;
      // }

      // const payloadWaSms = [
      //   {
      //     recieverNumber: [
      //       `${talentData.homePhoneCode?.substring(1)}${talentData.homePhone}`,
      //     ],
      //     message: `${inviteContent[0]?.smsContent} \n ${inviteContent[0]?.footer}`,
      //   },
      // ];
      // const payloadSms = [
      //   {
      //     recieverNumber: [
      //       `${talentData.homePhoneCode}${talentData.homePhone}`,
      //     ],
      //     message: `${inviteContent[0]?.smsContent} \n ${inviteContent[0]?.footer}`,
      //     senderRole: getUserRole() || null,
      //     senderName: getCandidateName() || null,
      //     userId: getCandidateId() || null,
      //     fk_tenant: getUserTenantID() || null,
      //   },
      // ];
      const resendMailPayload = {
        sendMailLink,
        jobTitle: jobData.jobTitle,
        candidateEmail: talentData.email,
        candidateFname: talentData.firstName,
        candidateLname: talentData.lastName,
        candidateId: talentData.userId,
        jobId: jobData.jobId,
        candidateTenant: usertenant,
        recruiterName: loggedinusername,
        recruiterId: userid,
        isFrom: "JobDisposition",
      };

      const promises = [post("/disposition/resendinvitationmail", resendMailPayload)];

      // if (talentData.homePhone && talentData.homePhoneCode) {
      //   promises.push(
      //     post("/msgtwoway/telSms", payloadSms)
      //     // post("/massages/sentMassageWhatsapp", payloadWaSms)
      //   );
      // }

      await Promise.allSettled(promises);

      setLoading(false);
      toast.success(`Reminder email has been sent successfully`);
    } catch (error) {
      setLoading(false);
      toast.error("Oops! Something went wrong. Please try again later");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <button type="button" onClick={() => resendMail()} className="btn btn-sm">
        Resend
      </button>
    </>
  );
};

export default ResendMail;
