import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import ResendMail from "./ResendMail";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import {
  getJobDispositionDetails,
  getJobDispositionCount,
  getJobDispositionDisqualify,
} from "../../../../../../action/disposition/job/jobdetails/JobDetails";
import TalentProfile from "../../../../../talentpool/talentprofile/TalentProfile.js";
import { getUserRole } from "../../../../../../service/AuthService";
import EmailStatus from "../../../../../emailstatus/EmailStatus.js";
import ProfileImage from "../../../../../profileimage/ProfileImage.js";

const Invite = (props) => {
  const { jobData, invitedData, view } = props;

  const dispatch = useDispatch();
  const { Id } = useParams();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [candidateData, setCandidateData] = useState({});

  const diffDate = (someday) => {
    const today = moment();
    return today.diff(someday, "hours");
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payloadDisposition));
    dispatch(getJobDispositionCount(payload));
    dispatch(getJobDispositionDisqualify(payloadDisqualify));
    setDisqualifyPopup(false);
  };

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const onDisqualifyClick = (data) => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
    setDisqualifyData(data);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setCandidateData(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {invitedData.map((item, index) => (
        <tr key={index}>
          <td
            className="no-hover"
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"35"}
              imageHeight={"35"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />

            <span className="ml-2 font-16 p-4">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase()}
            </span>
          </td>
          <td>
            {item?.invitedDate
              ? moment(item?.invitedDate).format("MM/DD/YYYY")
              : ""}
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
            >
              <button
                type="button"
                data-toggle="modal"
                disabled={item.filePath == null && true}
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                onClick={() => handleResume(item)}
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>{" "}
          {getUserRole() === "Recruiter" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </button>

                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          <td className="no-hover text-right text-left-1024">
            <hr className="d-none d-block-1024 my-2" />
            {jobData.jobStatus !== 23 &&
              jobData.jobStatus !== 26 &&
              jobData.jobStatus !== 39 &&
              (diffDate(item?.invitedDate) > 48 &&
              getUserRole() === "Recruiter" ? (
                <div className="btn-group btn-group-sm btn-split">
                  <ResendMail talentData={item} jobData={jobData} />
                  <button
                    type="button"
                    onClick={() => openDisqualify(item)}
                    className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  />
                  {disqualifyData.userId == item.userId && openDropDown && (
                    <OutsideClickHandler
                      onOutsideClick={() => setOpenDropDown(false)}
                    >
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        style={{
                          display: "revert",
                          right: "0px",
                          top: "30px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => onDisqualifyClick(disqualifyData)}
                          className="dropdown-item"
                        >
                          {getUserRole() == "Recruiter" ||
                          getUserRole() == "CommunityManager"
                            ? "Withdraw"
                            : "Disqualify"}
                        </button>
                      </div>
                    </OutsideClickHandler>
                  )}
                </div>
              ) : (
                <button
                  type="button"
                  onClick={() => onDisqualifyClick(item)}
                  className="btn btn-sm"
                >
                  {getUserRole() == "Recruiter" ||
                  getUserRole() == "CommunityManager"
                    ? "Withdraw"
                    : "Disqualify"}
                </button>
              ))}
          </td>
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setCandidateProfile={setCandidateProfile}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
          isFrom={"invite"}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Invited"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={candidateData}
          handleSuccess={handleModel}
          openDisqualify={openDisqualify}
          jobData={jobData}
          disqualifyData={disqualifyData}
          openDropDown={openDropDown}
          setOpenDropDown={setOpenDropDown}
          onDisqualifyClick={onDisqualifyClick}
          parent="inviteTab"
          isFrom="inviteTab"
          component="jobDisposition"
        />
      )}
    </>
  );
};

export default Invite;
