import React, { useState } from "react";
import "react-tabs/style/react-tabs.css";
import { getTenantType } from "../../../service/AuthService";
import Joblist from "./Joblist";
import TopNavbar from "../../layout/TopNavbar";
import SideNavBar from "../../layout/SideNavbar";
import Footer from "../../layout/Footer";

const Index = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavbar setToggle={setToggle} toggle={toggle} />
      <SideNavBar />
      <div id="Content">
        <div className="section section-sm">
          <div className="container">
            <Joblist />
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
