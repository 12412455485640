import {
  GET_HIGH5_USER_DATA_BEGIN,
  GET_HIGH5_USER_DATA_SUCCESS,
  GET_HIGH5_USER_DATA_FAILURE,
  GET_HIGH5_EDIT_USER_DATA_BEGIN,
  GET_HIGH5_EDIT_USER_DATA_SUCCESS,
  GET_HIGH5_EDIT_USER_DATA_FAILURE,
} from "../../../../action/dashboard/superadmin/high5users/High5Users";

const initialState = {
  loading: true,
  usersData: [],
  editUserData: null,
};

const getHigh5UsersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_HIGH5_USER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        usersData: [],
      };
    case GET_HIGH5_USER_DATA_SUCCESS:
      return {
        ...state,
        usersData: payload,
        loading: false,
      };
    case GET_HIGH5_USER_DATA_FAILURE:
      return {
        ...state,
        usersData: [],
        loading: false,
      };
    case GET_HIGH5_EDIT_USER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        editUserData: null,
      };
    case GET_HIGH5_EDIT_USER_DATA_SUCCESS:
      return {
        ...state,
        editUserData: payload,
        loading: false,
      };
    case GET_HIGH5_EDIT_USER_DATA_FAILURE:
      return {
        ...state,
        editUserData: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default getHigh5UsersReducer;
