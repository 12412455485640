import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { post } from "../../service/ApiService";
import ApplyCommunity from "./ApplyCommunity";
import Loader from "../common/loader/Loader";

const Index = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [jobCategory, setJobCategory] = useState("");

  const getPoolById = async () => {
    const params = { poolId: id };
    setLoading(true);
    await post("/talent/getPoolInfo", params).then((res) => {
      setPoolInfo(res.data);
      if (res.data) {
        let jobCategory = JSON.parse(res.data.industry);
        setJobCategory(jobCategory[0]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getPoolById();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {jobCategory && (
        <ApplyCommunity
          job={poolInfo}
          jobCategory={jobCategory}
        />
      )}
    </div>
  );
};

export default Index;
