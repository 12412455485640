import moment from "moment";
import React from "react";

const History = ({ talentData, isFrom }) => {
  const getDate = (date) => {
    const formattedDate = moment(date).isAfter(moment().subtract(1, "days"))
      ? moment(date).fromNow()
      : moment(date).format("MM/DD/YYYY");
    return formattedDate;
  };

  const activities = [
    { date: talentData?.offerAcceptedDate, label: "Offer Accepted" },
    { date: talentData?.reOfferDate, label: "Re-Offered" },
    { date: talentData?.disqualifiedDate, label: "Ineligible" },
    { date: talentData?.offerRejectedDate, label: "Offer Declined" },
    { date: talentData?.offeredDate, label: "Offered" },
    { date: talentData?.shortlistedDate, label: "Shortlisted" },
    {
      date: talentData?.submittedDate,
      label: "Submitted",
    },
    { date: talentData?.invitedDate, label: "Invited" },
    { date: talentData?.automatchDate, label: "Automatched" },
  ];

  const poolActivities = [
    { date: talentData?.disqualifiedDate, label: "Ineligible" },
    { date: talentData?.offeredDate, label: "Ready To Hire" },
    {
      date:
        talentData?.vettingDetails &&
        talentData?.vettingDetails[0]?.status === "completed"
          ? talentData?.vettedDate
          : null,
      label: "Vetted",
    },
    {
      date: talentData?.submittedDate,
      label: "Engaged",
    },
    { date: talentData?.invitedDate, label: "Invited" },
    { date: talentData?.automatchDate, label: "Automatched" },
  ];

  // Filter out null dates and sort by date descending (most recent first)
  const sortedActivities = activities
    ?.filter((activity) => activity?.date)
    ?.sort((a, b) => new Date(b.date) - new Date(a.date));

  const sortedPoolActivities = poolActivities
    .filter((activity) => activity?.date)
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      {isFrom === "TalentPool" && (
        <div className="timeline-wrap" style={{ maxHeight: "unset" }}>
          {sortedPoolActivities.map((activity, index) => (
            <div className="timeline pb-3" key={index}>
              <i className="fad fa-circle fa-fw icon" aria-hidden="true"></i>
              <div>
                <small>{getDate(activity?.date)}</small>
                <p className="mb-0">
                  <strong>{activity?.label}</strong>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      {isFrom === "jobDisposition" && (
        <div className="timeline-wrap" style={{ maxHeight: "unset" }}>
          {sortedActivities.map((activity, index) => (
            <div className="timeline pb-3" key={index}>
              <i className="fad fa-circle fa-fw icon" aria-hidden="true"></i>
              <div>
                <small>{getDate(activity?.date)}</small>
                <p className="mb-0">
                  <strong>{activity?.label}</strong>
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default History;
