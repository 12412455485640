import React, { useState, useEffect } from "react";
import TopNavbar from "../../layout/TopNavbar";
import SideNavBar from "../../layout/SideNavbar";
import { getTenantType } from "../../../service/AuthService";
import Dashboard from "./Dashboard";
import Footer from "../../layout/Footer";

function Index() {
  const [toggle, setToggle] = useState(true);
  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavbar isFrom={"SAEAnew"} setToggle={setToggle} toggle={toggle} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm ">
            <div class="container ">
              <div class="row justify-content-center">
                <Dashboard />
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default Index;
