import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
  careerFilterCategories,
  jobTypeOptions,
} from "../../../../../../constants/Constants";

const JobFilter = ({ selectedData, setSelectedData, applyFilter }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [showCategoryChecklist, setShowCategoryChecklist] = useState(false);
  const [showJobTypeChecklist, setShowJobTypeChecklist] = useState(false);

  const filterItems = () => {
    return jobTypeOptions.map((item) => item?.value);
  };

  const handleCheckboxChange = (
    selectedItems,
    selectedItem,
    setSelectedItems,
    type
  ) => {
    if (selectedItems.includes(selectedItem)) {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item !== selectedItem)
      );
      let data = {
        ...selectedData,
        [type]: selectedData[type].filter((item) => item !== selectedItem),
      };
      setSelectedData(data);
      applyFilter(
        data[type].filter((item) => item !== selectedItem),
        type
      );
    } else {
      setSelectedItems((prevItems) => [...prevItems, selectedItem]);

      let data = {
        ...selectedData,
        [type]: [...selectedData[type], selectedItem],
      };
      setSelectedData(data);
      applyFilter([...data[type], selectedItem], type);
    }
  };

  const handleToggleChecklist = (setShowChecklist) => {
    setShowChecklist((prevShowChecklist) => !prevShowChecklist);
  };

  const handleClear = () => {
    setSelectedCategories([]);
    setSelectedJobTypes([]);
    let data = {
      industries: [],
      jobLocation: [],
      jobType: [],
    };
    setSelectedData(data);
    applyFilter(data, "");
  };

  return (
    <>
      {" "}
      <div className="d-flex justify-content-between px-3">
        <h6>Filter Jobs</h6>
        <div className="">
          <button
            disabled={
              selectedCategories.length === 0 && selectedJobTypes?.length === 0
            }
            type="button"
            className="btn btn-text"
            onClick={handleClear}
          >
            <span className="font-bold">Clear All</span>
          </button>
        </div>
      </div>
      <div
        className="container mt-4 mb-1"
        style={{
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          background: "#F9F9F9",
        }}
      >
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <label htmlFor="searchCategory" className="mr-2">
            Category
          </label>
          <button
            className="btn btn-link btn-sm mt-3 "
            onClick={() => handleToggleChecklist(setShowCategoryChecklist)}
          >
            <FontAwesomeIcon icon={showCategoryChecklist ? faMinus : faPlus} />
          </button>
        </div>
        {showCategoryChecklist && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ul className="list-group w-100" id="categoryList">
                    {careerFilterCategories.map((category) => (
                      <li key={category} className="list-group-item">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input mt-3"
                            id={`category${category}`}
                            value={category}
                            checked={selectedCategories.includes(category)}
                            onChange={() =>
                              handleCheckboxChange(
                                selectedCategories,
                                category,
                                setSelectedCategories,
                                "industries"
                              )
                            }
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor={`category${category}`}
                          >
                            {category}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr
          style={{
            margin: "15px 0",
            marginBottom: "0",
            borderTop: "1px solid #B7AFAF",
          }}
        />
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <label htmlFor="searchJobType" className="mr-2">
            Job Type
          </label>
          <button
            className="btn btn-link btn-sm mt-3 "
            onClick={() => handleToggleChecklist(setShowJobTypeChecklist)}
          >
            <FontAwesomeIcon icon={showJobTypeChecklist ? faMinus : faPlus} />
          </button>
        </div>
        {showJobTypeChecklist && (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ul className="list-group w-100" id="jobTypeList">
                    {filterItems().map((jobType) => (
                      <li key={jobType} className="list-group-item">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input mt-3"
                            id={`jobType${jobType}`}
                            value={jobType}
                            checked={selectedJobTypes.includes(jobType)}
                            onChange={() =>
                              handleCheckboxChange(
                                selectedJobTypes,
                                jobType,
                                setSelectedJobTypes,
                                "jobType"
                              )
                            }
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor={`jobType${jobType}`}
                          >
                            {jobType}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr
          style={{
            margin: "15px 0",
            marginBottom: "0",
            borderTop: "1px solid #B7AFAF",
          }}
        />
      </div>
    </>
  );
};

export default JobFilter;
