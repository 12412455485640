import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import {
  getUserRole,
  getTenantType,
} from "../../../../../../service/AuthService";
import ResumeDownload from "../resume/Index";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import MakeAnOfferPopup from "../offered/MakeAnOfferPopup";
import {
  getJobDispositionDetails,
  getJobDispositionCount,
  getJobDispositionDisqualify,
} from "../../../../../../action/disposition/job/jobdetails/JobDetails";
import TalentProfile from "../../../../../talentpool/talentprofile/TalentProfile.js";
import EmailStatus from "../../../../../emailstatus/EmailStatus.js";
import ProfileImage from "../../../../../profileimage/ProfileImage.js";

const Shortlisted = (props) => {
  const { jobData, shortlistedData, view } = props;
  const dispatch = useDispatch();
  const { Id } = useParams();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState({});
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [makeAnOfferPopup, setMakeAnOfferPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const onDisqualifyClick = () => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const makeAnOfferOpen = (item) => {
    setSelectedCandidate(item);
    setMakeAnOfferPopup(true);
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payloadDisposition));
    dispatch(getJobDispositionCount(payload));
    dispatch(getJobDispositionDisqualify(payloadDisqualify));
    setDisqualifyPopup(false);
    setMakeAnOfferPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {shortlistedData.map((item, i) => (
        <tr key={i}>
          <td
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"35"}
              imageHeight={"35"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />
            <span className="ml-2 font-16">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase() + item?.lastName.slice(1)}
              {item?.movedFrom ? (
                <i class="fas fa-users ml-2" title={`Moved from Community`}></i>
              ) : (
                ""
              )}
            </span>
          </td>
          <td className="no-hover">
            <label className="d-none d-block-1024">
              <strong>Updated On</strong>
            </label>
            {moment(new Date(item.shortlistedDate)).format("MM/DD/YYYY")}
          </td>
          <td className="no-hover">
            <label className="d-none d-block-1024">
              <strong>Resumes</strong>
            </label>
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                onClick={() => handleResume(item)}
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          {getUserRole() === "HiringManager" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </button>

                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          <td className="no-hover">
            {
              <span className="">
                {props.calculateScore(jobData, item) + "%"}
              </span>
            }
          </td>
          {getUserRole() !== "SuperAdmin" && (
            <td className="no-hover text-right text-left-1024">
              <hr className="d-none d-block-1024 my-2" />
              {getTenantType() == 1 &&
                jobData.jobStatus !== 23 &&
                jobData.jobStatus !== 26 &&
                jobData.jobStatus !== 39 && (
                  <div className={"btn-group btn-group-sm btn-split"}>
                    <button
                      type="button"
                      onClick={() => makeAnOfferOpen(item)}
                      className="btn btn-sm"
                    >
                      Make an Offer
                    </button>
                    <button
                      type="button"
                      onClick={() => openDisqualify(item)}
                      className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    />
                    {disqualifyData.userId == item.userId && openDropDown && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenDropDown(false)}
                      >
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{
                            display: "revert",
                            right: "0px",
                            top: "30px",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => onDisqualifyClick()}
                            className="dropdown-item"
                          >
                            Disqualify
                          </button>
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                )}
            </td>
          )}
        </tr>
      ))}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Shortlisted"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {makeAnOfferPopup && (
        <MakeAnOfferPopup
          selectedCandidate={selectedCandidate}
          makeAnOfferPopup={makeAnOfferPopup}
          setMakeAnOfferPopup={setMakeAnOfferPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          onDisqualifyClick={onDisqualifyClick}
          setDisqualifyPopup={setDisqualifyPopup}
          setOpenDropDown={setOpenDropDown}
          openDropDown={openDropDown}
          disqualifyData={disqualifyData}
          makeAnOfferOpen={makeAnOfferOpen}
          openDisqualify={openDisqualify}
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="shortlistTab"
          isFrom="shortlistTab"
          component="jobDisposition"
        />
      )}
    </>
  );
};

export default Shortlisted;
