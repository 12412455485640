import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import {
  getUserRole,
  getCandidateName,
  getCandidateId,
  getUserTenantID,
} from "../../../../../../service/AuthService";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../loader/Loader";
import { domain } from "../../../../../../constants/Constants";

function ShortlistPopUp(props) {
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [talentData, setTalentData] = useState({});
  const [jobData, setJobData] = useState({});
  const [showInterview, setShowInterview] = useState(false);
  const role = getUserRole();

  const close = () => {
    props.setShortlistPopup(false);
  };

  const SMS = async () => {
    let phoneNumber =
      getPhone(
        props.selectedCandidate?.homePhone,
        props.selectedCandidate?.homePhoneCode
      ) ||
      getPhone(
        props.selectedCandidate?.workPhone,
        props.selectedCandidate?.workPhoneCode
      );
    let name = props?.selectedCandidate?.firstName;
    let jobName = jobData?.jobTitle;

    const shortListedReq = {
      event: "Shortlist",
      recipient: '["Candidate"]',
    };

    const res = await post("/msgtwoway/getSmsContent", shortListedReq);
    const shortListedContent = res.status === 200 ? res.data : [];

    if (shortListedContent.length !== 0) {
      let content = shortListedContent[0].smsContent;
      content = content?.replace("[First Name]", name);
      content = content?.replace("[Job Title]", jobName);
      shortListedContent[0].smsContent = content;
    }

    const payload = [
      {
        toUserName: name,
        userId: getCandidateId(),
        recieverNumber: [phoneNumber.replace("+", "")],
        message: `${shortListedContent?.[0]?.smsContent} \n ${shortListedContent?.[0]?.footer}`,
        jobId: jobData?.jobId || null,
        senderRole: getUserRole() || null,
        senderName: getCandidateName() || null,
        userId: getCandidateId() || null,
      },
    ];

    const payloadTelSms = [
      {
        recieverNumber: [phoneNumber],
        message: `${shortListedContent?.[0]?.smsContent} \n ${shortListedContent?.[0]?.footer}`,
        jobId: jobData?.jobId || null,
        senderRole: getUserRole() || null,
        senderName: getCandidateName() || null,
        userId: getCandidateId() || null,
        fk_tenant: getUserTenantID() || null,
      },
    ];

    try {
      if (phoneNumber.includes("+1")) {
        await post("/msgtwoway/telSms", payloadTelSms);
      } else {
        await post("/massages/sentMassageWhatsapp", payload);
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const SMS2 = async () => {
    try {
      const tenant = {
        tenant: JSON.parse(localStorage.getItem("userToken"))?.tenantid,
        createdBy: props?.selectedCandidate?.createdBy,
      };

      const numbers = await post("/msgtwoway/getAllNumbers", tenant);
      const data = numbers.data;

      if (data.length === 0) return;

      let shortListedContentRec;
      let shortListedContentHM;
      let shortListedContentCSM;
      let shortListedContentMPM;
      try {
        const shortListedReq = {
          event: "Shortlist",
          recipient: '["Recruiter"]',
        };
        const res = await post("/msgtwoway/getSmsContent", shortListedReq);
        if (res.status === 200) {
          shortListedContentRec = res.data;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
      try {
        const shortListedReq = {
          event: "Shortlist",
          recipient: '["Hiring Manager"]',
        };
        const res = await post("/msgtwoway/getSmsContent", shortListedReq);
        if (res.status === 200) {
          shortListedContentHM = res.data;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
      try {
        const shortListedReq = {
          event: "Shortlist",
          recipient: '["Customer Success Manager"]',
        };
        const res = await post("/msgtwoway/getSmsContent", shortListedReq);
        if (res.status === 200) {
          shortListedContentCSM = res.data;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
      try {
        const shortListedReq = {
          event: "Shortlist",
          recipient: '["Marketplace Manager"]',
        };
        const res = await post("/msgtwoway/getSmsContent", shortListedReq);
        if (res.status === 200) {
          shortListedContentMPM = res.data;
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      data.forEach((obj) => {
        const recepientDataSms = [];
        const recepientDataWhatsApp = [];

        let home = obj.homePhone ? `+${obj.homePhone}` : "";
        let mobile = obj.mobilePhone ? `+${obj.mobilePhone}` : "";
        let work = obj.workPhone ? `+${obj.workPhone}` : "";

        if (home.charAt(1) != "1" && home.length > 10)
          recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
        if (mobile.charAt(1) != "1" && mobile.length > 10)
          recepientDataWhatsApp.push(mobile.replaceAll(" ", "")?.substring(1));
        if (work.charAt(1) != "1" && work.length > 10)
          recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));

        if (home.charAt(1) == "1")
          recepientDataSms.push(home.replaceAll(" ", ""));
        if (mobile.charAt(1) == "1")
          recepientDataSms.push(mobile.replaceAll(" ", ""));
        if (work.charAt(1) == "1")
          recepientDataSms.push(work.replaceAll(" ", ""));

        if (
          obj.fk_Role === 3 &&
          shortListedContentRec &&
          shortListedContentRec?.length !== 0
        ) {
          let content = shortListedContentRec[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace(
            "[CandidateName]",
            props.selectedCandidate?.firstName
          );
          content = content.replace("[JobTitle]", jobData?.jobTitle);
          content = content.replace(
            "[LinkForDetails]",
            `${domain}/high5Hire/requests/${jobData?.jobId}`
          );
          shortListedContentRec[0].smsContent = content;
          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              message: `${shortListedContentRec?.[0]?.smsContent} \n ${shortListedContentRec?.[0]?.footer}`,
            });
          }
          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: props.selectedCandidate?.firstName,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentRec?.[0]?.smsContent} \n ${shortListedContentRec?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 4 &&
          shortListedContentHM &&
          shortListedContentHM?.length !== 0
        ) {
          let content = shortListedContentHM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace(
            "[CandidateName]",
            props.selectedCandidate?.firstName
          );
          content = content.replace("[JobTitle]", jobData?.jobTitle);
          content = content.replace(
            "[LinkForDetails]",
            `${domain}/high5Hire/requests/${jobData?.jobId}`
          );
          shortListedContentHM[0].smsContent = content;
          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              message: `${shortListedContentHM?.[0]?.smsContent} \n ${shortListedContentHM?.[0]?.footer}`,
            });
          }
          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: props.selectedCandidate?.firstName,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentHM?.[0]?.smsContent} \n ${shortListedContentHM?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 8 &&
          shortListedContentCSM &&
          shortListedContentCSM?.length !== 0
        ) {
          let content = shortListedContentCSM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace(
            "[CandidateName]",
            props.selectedCandidate?.firstName
          );
          content = content.replace("[JobTitle]", jobData?.jobTitle);
          content = content.replace(
            "[LinkForDetails]",
            `${domain}/high5Hire/requests/${jobData?.jobId}`
          );
          shortListedContentCSM[0].smsContent = content;
          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              message: `${shortListedContentCSM?.[0]?.smsContent} \n ${shortListedContentCSM?.[0]?.footer}`,
            });
          }
          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: props.selectedCandidate?.firstName,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentCSM?.[0]?.smsContent} \n ${shortListedContentCSM?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 9 &&
          shortListedContentMPM &&
          shortListedContentMPM?.length !== 0
        ) {
          let content = shortListedContentMPM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace(
            "[CandidateName]",
            props.selectedCandidate?.firstName
          );
          content = content.replace("[JobTitle]", jobData?.jobTitle);
          content = content.replace(
            "[LinkForDetails]",
            `${domain}/high5Hire/requests/${jobData?.jobId}`
          );
          shortListedContentMPM[0].smsContent = content;
          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              senderRole: getUserRole() || null,
              senderName: getCandidateName() || null,
              userId: getCandidateId() || null,
              fk_tenant: getUserTenantID() || null,
              message: `${shortListedContentMPM?.[0]?.smsContent} \n ${shortListedContentMPM?.[0]?.footer}`,
            });
          }
          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: props.selectedCandidate?.firstName,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentMPM?.[0]?.smsContent} \n ${shortListedContentMPM?.[0]?.footer}`,
            });
          }
        }
      });

      if (recepientsDataSms.length > 0) {
        await post("/msgtwoway/telSms", recepientsDataSms);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred:", error);
      toast.error("An error occurred");
    }
  };


  const submitShortList = async (e) => {
    try {
      setLoading(true);
      const payload = {
        status: 8,
        id_disposition: talentData.id_disposition,
        disqualifyComments: null,
        comments: commentText,
        jobId: jobData.jobId,
        jobTitle: jobData.jobTitle,
        firstName: talentData.firstName,
        lastName: talentData.lastName,
        email: talentData.email,
        tenantName: jobData.tenantName,
        role: role,
        vettingAssignment: showInterview,
        recruiterFname: talentData.recruiterFname,
        recruiterId: talentData.recruiterId,
        createdBy: props?.selectedCandidate?.createdBy,
      };

      const res = await post("/disposition/updatedisposition", payload);
      if (res.status === 200) {
        await SMS();
        await SMS2();
        toast.success("Candidate Shortlisted successfully");
        setLoading(false);
        props.reloadDisposition('shortlist');
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  useEffect(() => {
    setJobData(props.jobData);
    setTalentData(props.selectedCandidate);
  }, [props]);

  const getPhone = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <Modal
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
          show={props.shortlistPopup}
        >
          <Modal.Header>
            <div style={{ zIndex: "1000" }}>
              <h6 class="bl-modal-title">{"Shortlist Candidate"}</h6>

              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.setShortlistPopup(false)}
                className="close p-0 bl-modal-close-btn mx-1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="bl-modal-body ">
              <div>
                <div className="text-center">
                  <Avatar
                    size="55"
                    name={talentData.firstName + " " + talentData.lastName}
                    round="80px"
                  />
                </div>
              </div>
              <div>
                <div className="text-center">
                  <h6 className="mt-3">
                    {talentData?.firstName} {talentData?.lastName}
                  </h6>
                  <p>
                    {" "}
                    {jobData?.isRemote ? "Remote" : jobData?.city} /{" "}
                    {jobData?.tenantName} /{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData?.placementCurrency
                        ? `${jobData?.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    }).format(jobData?.maximumPay)}
                  </p>
                </div>
              </div>

              <div className="form-floating mt-3">
                <textarea
                  className="form-control"
                  maxLength={250}
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                ></textarea>
                <label for="floatingTextarea2">Enter Comments</label>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={submitShortList}
              disabled={commentText.length === 0}
            >
              Shortlist
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ShortlistPopUp;
