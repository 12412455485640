import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getUserRole, getActualRole } from "../../service/AuthService";
import MenuToggle from "./topnavbar/MenuToggle";
import SearchBar from "./topnavbar/SearchBar";
import Support from "./topnavbar/Support";
import NotificationBell from "./topnavbar/Notifications";
import ProfileDropdown from "./topnavbar/ProfileDropdown";
// import BulkCommunicationModal from "../communication/modal/BulkCommunicationModal";
import BulkCommunicationModal from "../communication/Index";

function TopNavBar(props) {
  const {
    showSearch,
    isSearchExpanded,
    setIsSearchExpanded,
    searchInput,
    handleSearchType,
    setToggle,
    toggle,
  } = props;

  const history = useHistory();
  const [isAdminUserLoggedIn, setIsAdminUserLoggedIn] = useState();
  const [adminUserDetails, setAdminUserDetails] = useState({});
  const [userRole, setUserRole] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isFrom, setIsFrom] = useState("");

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
    if (
      getUserRole() === "CustomerSuccess" ||
      getUserRole() === "SuperAdmin" ||
      getUserRole() === "CommunityManager"
    ) {
      const adminTokenDetails = localStorage.getItem("adminUserToken");
      if (!adminTokenDetails) {
        const adminUserToken = localStorage.getItem("userToken");
        localStorage.setItem("adminUserToken", adminUserToken);
      }
    }
    const adminTokenDetails = localStorage.getItem("adminUserToken");
    if (adminTokenDetails) {
      setIsAdminUserLoggedIn(true);
      setAdminUserDetails(adminTokenDetails);
    } else {
      setAdminUserDetails("");
      setIsAdminUserLoggedIn(false);
    }
  }, []);

  const ChangeUser = (e) => {
    let actRole = getActualRole();
    localStorage.setItem("userToken", adminUserDetails);
    if (
      actRole === "SuperAdmin" ||
      actRole === "CustomerSuccess" ||
      actRole === "CommunityManager"
    )
      history.push({ pathname: "/high5Hire", state: { tab: "users" } });
    else history.push("/high5Hire");
    window.location.reload(true);
  };

  return (
    <>
      <div id="TopBar">
        <MenuToggle toggle={toggle} setToggle={setToggle} />
        <SearchBar
          showSearch={showSearch}
          isSearchExpanded={isSearchExpanded}
          setIsSearchExpanded={setIsSearchExpanded}
          searchInput={searchInput}
          handleSearchType={handleSearchType}
        />
        {/* search bar div ended */}
        <div
          style={{ maxWidth: 250 }}
          className="extras ml-auto text-right  mr-3"
        >
          {isAdminUserLoggedIn &&
            userRole !== "CommunityManager" &&
            userRole !== "High5Coordinator" &&
            userRole !== "CustomerSuccess" &&
            userRole !== "SuperAdmin" && (
              <button
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Back"
                className="mbtn btn-sm w-auto btn-text-accent dd-sort  "
                onClick={(e) => ChangeUser()}
              >
                Back
              </button>
            )}
          <button
            type="button"
            title="Communication"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => {
              setShowEmailModal(true);
              setIsFrom("topNavbar");
            }}
          >
            <div class="notification-icon">
              <i class="fal fa-fw fa-envelope"></i>
            </div>
          </button>
          <NotificationBell />
          <Support />
          <ProfileDropdown />
        </div>
      </div>
      {showEmailModal && (
        <BulkCommunicationModal
          isModalOpen={showEmailModal}
          setModalClose={setShowEmailModal}
          modalSize={"xl"}
          isFrom={isFrom}
          isOnlyEmail={false}
        />
      )}
    </>
  );
}

export default TopNavBar;
