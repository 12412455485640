import React, { useState, useRef } from "react";
import AddJobNewGoogleMaps from "../../../location/GoogleLocationAPI";
import { toast } from "react-toastify";
import GoogleLocationCountryAPI from "../../../location/GoogleLocationCountryAPI";

const WorkPlaceTypeAndLocation = (props) => {
  const searchInput = useRef(null);
  let preferredLocationData = props?.addJobData?.preferredLocation
    ? props?.addJobData?.preferredLocation
    : [];

  const [preferredLocation, setPreferredLocation] = useState([
    ...preferredLocationData,
  ]);

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  const setLocation = (address, searchInput) => {
    if (preferredLocation.includes(address.country)) {
      toast.error("You Have Already Added This Location");
    } else {
      setPreferredLocation([...preferredLocation, address.country]);
      props?.setAddJobData({
        ...props.addJobData,
        preferredLocation: [...preferredLocation, address.country],
      });
      searchInput.current.value = "";
    }
  };

  const setLocationRegion = (address, searchInput) => {
    const city = address.locality || address.route || address.city;
    props.setAddJobData({
      ...props.addJobData,
      location: {
        address: city
          ? city + ", " + address.state + ", " + address.country
          : "",
        zipcode: address?.zip || "",
        city: city || "",
        state: address?.state || "",
        country: address?.country || "",
      },
    });
    searchInput.current.value = city;
  };

  const handleIsGlobal = (e) => {
    if (e.target.checked) {
      props.setAddJobData({
        ...props.addJobData,
        isGlobal: e.target.checked,
        preferredLocation: [],
      });
      setPreferredLocation([]);
    } else {
      props.setAddJobData({
        ...props.addJobData,
        isGlobal: e.target.checked,
        preferredLocation: [],
      });
      setPreferredLocation([]);
    }
  };

  const handleRadiusChange = (e) => {
    props.setRadius(e.target.value); // Step 3: Update selected radius when dropdown changes
  };

  return (
    <>
      <div className="card card-flat bg-gray4 mx-3">
        <div className="card-body">
          <p className="mb-2">Choose the workplace type</p>
          <hr />
          <div className="row mt-2">
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0">
                    <strong>Remote</strong>
                  </p>
                  <span>
                    <medium>Employee work off-site</medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isRemote"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="RemoteSwitch"
                      checked={props?.addJobData.isRemote}
                      disabled={props?.edit}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            workPlaceType: "Remote",
                            isRemote: true,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        } else {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            workPlaceType: "On-Site",
                            isRemote: false,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0">
                    <strong>Hybrid</strong>
                  </p>
                  <span>
                    <medium>Employee work both on-site & off-site</medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isHybrid"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="HybridSwitch"
                      checked={props?.addJobData.workPlaceType === "Hybrid"}
                      disabled={props?.edit}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            onsiteWorkDays: "3 days",
                            workPlaceType: "Hybrid",
                            isRemote: false,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        } else {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            onsiteWorkDays: "",
                            workPlaceType: "Remote",
                            isRemote: true,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0" style={{ fontWeight: "bold" }}>
                    On-Site
                  </p>
                  <span>
                    <medium>Employee work on-site </medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isRemote"
                      className="form-check-input ml-3"
                      type="checkbox"
                      checked={props?.addJobData.workPlaceType === "On-Site"}
                      disabled={props?.edit}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            workPlaceType: "On-Site",
                            isRemote: false,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        } else {
                          props?.setAddJobData({
                            ...props?.addJobData,
                            workPlaceType: "Remote",
                            isRemote: true,
                            preferredLocation: [],
                            isGlobal: false,
                          });
                          setPreferredLocation([]);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props?.addJobData.workPlaceType === "Hybrid" && (
            <>
              <hr />
              <div className="d-flex align-items-center mt-3">
                <p className="m-0">Number of days for on-site</p>
                <div className="col-lg-2 ml-3">
                  <select
                    className="font-14 form-select"
                    name="onsiteWorkDays"
                    onChange={props?.handleChange}
                    disabled={props?.edit}
                  >
                    {[
                      "Select",
                      "1 day",
                      "2 days",
                      "3 days",
                      "4 days",
                      "5 days",
                    ].map((v, i) => (
                      <option
                        key={i}
                        selected={props?.addJobData.onsiteWorkDays === v}
                        value={v}
                      >
                        {v}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {props?.addJobData.isRemote && (
        <>
          <div className="card card-flat card-borderless bg-gray4 m-3">
            <div className="card-body">
              <div className="row mt-2">
                <div class="row mb-12 mt-2">
                  <div class="col-12">
                    <div class="mb-2 d-flex">
                      <label>Where do you want talent to be located?* </label>
                      <div
                        class="form-floating ml-auto"
                        style={{ margin: "15px 0px 0px 50px" }}
                      >
                        <div className="form-check form-switch d-flex align-items-center">
                          <label
                            className="form-check-label pt-0"
                            htmlFor="remoteSwitch"
                          >
                            Global
                          </label>
                          <input
                            className="form-check-input ml-3"
                            type="checkbox"
                            id="isFresher"
                            disabled={props?.edit}
                            checked={props.addJobData?.isGlobal}
                            onChange={(e) => handleIsGlobal(e)}
                          />
                        </div>
                      </div>
                    </div>
                    {!props.addJobData?.isGlobal && (
                      <div>
                        <div class="form-inline-edit">
                          <div>
                            <div>
                              <div className="m-2">
                                {preferredLocation?.map((item, index) => (
                                  <>
                                    <span className="tag tag-blue3 mb-1 mr-1">
                                      {item}
                                      <button
                                        className="btn btn-text"
                                        onClick={() => deleteLocation(index)}
                                        disabled={props?.edit}
                                      >
                                        <strong className="font-18 font-black">
                                          &times;
                                        </strong>
                                      </button>
                                    </span>
                                  </>
                                ))}
                              </div>

                              {!props?.edit && (
                                <>
                                  <div className="d-flex align-items-center justify-content-center w-100">
                                    <div className="form-floating w-100">
                                      <GoogleLocationCountryAPI
                                        setLocation={setLocation}
                                        searchInput={searchInput}
                                        className={
                                          props?.isFormValid &&
                                          props?.addJobData.preferredLocation
                                            .length === 0
                                            ? "form-control border border-danger"
                                            : "form-control"
                                        }
                                      />
                                    </div>
                                    <br />
                                  </div>
                                  <div>
                                    {props?.isFormValid &&
                                      !(
                                        props?.addJobData.preferredLocation
                                          .length > 0 ||
                                        props?.addJobData.isGlobal
                                      ) && (
                                        <small className="ml-2 validation">
                                          *Required field
                                        </small>
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!props?.addJobData.isRemote && (
        <>
          {!props?.edit && (
            <AddJobNewGoogleMaps
              setLocation={setLocationRegion}
              searchInput={searchInput}
            />
          )}

          <div className="card card-flat card-borderless bg-gray4 p-2">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <div className="d-flex align-items-center">
                      <p className="m-0 mr-3">City*</p>
                    </div>
                    <input
                      type="text"
                      className={
                        props?.isFormValid &&
                        props?.addJobData.location.city == ""
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      name="city"
                      disabled={true}
                      value={props?.addJobData.location.city}
                      placeholder=""
                      onChange={props?.handleChangeLocation}
                    />
                    {/* <label>City*</label> */}
                    {props?.isFormValid &&
                      props?.addJobData.location.city == "" && (
                        <small className="ml-2 validation">
                          *Required field
                        </small>
                      )}
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <div className="d-flex align-items-center">
                      <p className="m-0 mr-3">State*</p>
                    </div>
                    <input
                      type="text"
                      className={
                        props?.isFormValid &&
                        props?.addJobData.location.state == ""
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      name="state"
                      disabled={true}
                      value={props?.addJobData.location.state}
                      placeholder=""
                      onChange={props?.handleChangeLocation}
                    />
                    {/* <label>State*</label> */}
                    {props?.isFormValid &&
                      props?.addJobData.location.state == "" && (
                        <small className="ml-2 validation">
                          *Required field
                        </small>
                      )}
                  </div>
                </div>

                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <div className="d-flex align-items-center">
                      <p className="m-0 mr-3">Country*</p>
                    </div>
                    <input
                      type="text"
                      className={
                        props?.isFormValid &&
                        props?.addJobData.location.country == ""
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      name="country"
                      disabled={true}
                      value={props?.addJobData.location.country}
                      placeholder=""
                      onChange={props?.handleChangeLocation}
                    />
                    {/* <label>Country*</label> */}
                    {props?.isFormValid &&
                      props?.addJobData.location.country == "" && (
                        <small className="ml-2 validation">
                          *Required field
                        </small>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Step 2: Add dropdown for selecting radius */}
      {!props?.addJobData.isRemote && (
        <div className="form-floating mt-3 mx-3">
          <select
            className="form-select"
            name="radius"
            value={props.radius}
            onChange={handleRadiusChange}
          >
            <option value="10">Less than 10 miles</option>
            <option value="20">Less than 20 miles</option>
            <option value="50">Less than 50 miles</option>
          </select>
          <label>Select radius*</label>
        </div>
      )}
    </>
  );
};

export default WorkPlaceTypeAndLocation;
