import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";

export const GET_JOB_DISPOSITION_BEGIN = "GET_JOB_DISPOSITION_BEGIN";
export const GET_JOB_DISPOSITION_SUCCESS = "GET_JOB_DISPOSITION_SUCCESS";
export const GET_JOB_DISPOSITION_FAILURE = "GET_JOB_DISPOSITION_FAILURE";

export const getJobDispositionDetails = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_DISPOSITION_BEGIN,
    });
    const res = await post("/disposition/getjobdispositiondetails", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_DISPOSITION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_DISPOSITION_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const GET_JOB_DISPOSITION_COUNT_BEGIN = "GET_JOB_DISPOSITION_COUNT_BEGIN";
export const GET_JOB_DISPOSITION_COUNT_SUCCESS = "GET_JOB_DISPOSITION_COUNT_SUCCESS";
export const GET_JOB_DISPOSITION_COUNT_FAILURE = "GET_JOB_DISPOSITION_COUNT_FAILURE";

export const getJobDispositionCount = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_DISPOSITION_COUNT_BEGIN,
    });
    const res = await post("/disposition/getjobdispositioncounts", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_DISPOSITION_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_DISPOSITION_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const GET_JOB_DISPOSITION_DISQUALIFY_BEGIN = "GET_JOB_DISPOSITION_DISQUALIFY_BEGIN";
export const GET_JOB_DISPOSITION_DISQUALIFY_SUCCESS = "GET_JOB_DISPOSITION_DISQUALIFY_SUCCESS";
export const GET_JOB_DISPOSITION_DISQUALIFY_FAILURE = "GET_JOB_DISPOSITION_DISQUALIFY_FAILURE";

export const getJobDispositionDisqualify = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_DISPOSITION_DISQUALIFY_BEGIN,
    });
    const res = await post("/disposition/getjobdispositiondetails", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_DISPOSITION_DISQUALIFY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_DISPOSITION_DISQUALIFY_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};