import React, { useState, useEffect } from "react";
import NoRecord from "../../common/NoRecord";
import Loading from "../../../assets/images/button-loader.gif";
import { useHistory } from "react-router-dom";
import { CurrencySymbols } from "../../../constants/CurrencySymbols";
import Avatar from "react-avatar";
import { AsYouType } from "libphonenumber-js";
import moment from "moment";
import CustomProgressBar from "../../common/CustomProgressBar";
const SearchComponent = ({
  isSearchExpanded,
  onClose,
  searchInput,
  isLoading,
  handleSearchType,
  searchData,
}) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(isSearchExpanded);

  console.log(searchData);
  useEffect(() => {
    setIsVisible(isSearchExpanded);
  }, [isSearchExpanded]);

  const containerStyle = {
    overflow: "hidden",
    maxHeight: isVisible ? "800px" : "0",
    transition: "max-height 0.5s, margin 0.5s, opacity 0.5s",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    margin: isVisible ? "20px" : "0",
    opacity: isVisible ? "1" : "0",
    padding: "20px",
  };

  const searchHeadingStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "15px",
    color: "#001a38",
  };

  const closeBtnStyle = {
    cursor: "pointer",
    fontSize: "1.5rem",
    color: "#001a38",
  };

  const searchInputStyle = {
    flex: "1",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #001a38",
    marginRight: "10px",
  };

  return (
    <div className="container mt-4" style={containerStyle}>
      <div className="d-flex justify-content-between">
        <h6>
          Find Talent / Jobs / Community{" "}
          {isLoading && <img height={"25"} src={Loading} />}
        </h6>
        <span style={closeBtnStyle} onClick={() => onClose(false)}>
          &times;
        </span>
      </div>
      <div className="  p-4">
        {searchInput && (
          <div className="d-flex d-block-768 flex-row justify-content-between gap-2">
            {searchData?.jobData && searchData?.jobData.length > 0 && (
              <div className="flex-grow-1 col-md-3">
                <h6>Jobs</h6>
                <div>
                  {searchData?.jobData.map((job, i) => (
                    <>
                      <div
                        className={`card p-0 d-flex w-100 align-items-center 
              justify-content-between job-listview flex-row overflow-hidden mb-1 pointer ${
                job.jobStatus === 13 ? "card-finger-shape" : "" // Add the class 'card-finger-shape' conditionally for the job name element
              }`}
                        onClick={(event) => {
                          if (
                            event.target.className.includes("tag") ||
                            event.target.className.includes("tag-blue3")
                          ) {
                            return;
                          } else {
                            history.push(`/high5Hire/requests/${job.jobId}`);
                          }
                        }}
                      >
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              <h6>
                                <custom>
                                  {" "}
                                  {job.jobTitle.length > 19
                                    ? `${job.jobTitle.substring(0, 20)}..`
                                    : job.jobTitle}
                                </custom>{" "}
                                ({job.jobId})
                              </h6>
                            </span>
                            <div className="d-flex">
                              <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                                <span className="font-regular font-primary font-gray1 mr-1">
                                  Placement Fee:
                                </span>
                                {job.isFeePercentage
                                  ? `${job.placementFee}%`
                                  : `${
                                      CurrencySymbols[job.placementCurrency]
                                        ? CurrencySymbols[job.placementCurrency]
                                        : job.placementCurrency
                                    }${Number(
                                      job.placementFee
                                    ).toLocaleString()}`}
                              </span>
                            </div>
                            {job?.jobDivaId && (
                              <div className="d-flex ml-1">
                                <span className="d-flex align-items-center tag tag-orange1 font-regular font-primary font-gray1 mb-2">
                                  <span className="font-regular font-primary font-gray1 mr-1">
                                    JobDiva ID:
                                  </span>
                                  <strong>{job?.jobDivaId}</strong>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="d-flex align-items-center">
                            {job.jobStatus === 13 && (
                              <span className="tag tag-green1 mr-3">Open</span>
                            )}
                            {job.jobStatus === 21 && (
                              <span className="tag tag-orange1 mr-3">
                                Active
                              </span>
                            )}
                            {job.jobStatus === 39 && (
                              <span className="tag tag-font_accent mr-3">
                                Halted
                              </span>
                            )}
                            {job.jobStatus === 23 && (
                              <span className="tag tag-font_accent mr-3">
                                Closed
                              </span>
                            )}
                            {job.isHotJob && (
                              <span className="tag tag-coral mr-2">HOT</span>
                            )}
                            {job.jobType && (
                              <span className="d-flex align-items-center me-3 w-10">
                                <i className="far fa-suitcase me-2 font-brandBlue"></i>
                                <small className="font-regular font-primary font-gray1">
                                  {job.jobType}
                                </small>
                              </span>
                            )}
                            <span
                              className="d-flex align-items-center me-3"
                              // onClick={() => onJobClick(job)}
                            >
                              {job?.city &&
                              job?.state &&
                              job?.country &&
                              !job.isRemote ? (
                                <>
                                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {job.showLocation
                                      ? `${job?.city}, ${job?.state}, ${job?.country}`
                                      : job?.city + "..."}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    Remote
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )}
            {searchData?.talentData && searchData?.talentData.length > 0 && (
              <div className="flex-grow-1 col-md-3">
                <h6>Talents</h6>
                <div>
                  {searchData?.talentData.map((candidate, i) => {
                    return (
                      <div className="d-flex gap-2" key={candidate.userId}>
                        <div className="d-block card mb-1 w-100">
                          <div className="d-flex flex-row align-items-stretch justify-content-between   p-relative  d-block-768">
                            <div
                              className={
                                "card-body font-regular d-flex align-items-center"
                              }
                            >
                              <div className="mx-2">
                                <span>
                                  <Avatar
                                    size="55"
                                    name={
                                      candidate.firstName +
                                      " " +
                                      candidate.lastName
                                    }
                                    round="80px"
                                  />
                                </span>
                              </div>
                              <div className="w-100 ml-2">
                                <div
                                  className="d-flex align-items-center mb-1"
                                  onClick={() => {
                                    // onProfileClick(candidate);
                                  }}
                                >
                                  <h6
                                    className="mb-0 me-2"
                                    style={{ cursor: "pointer" }}
                                  >
                                    {candidate.firstName
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      candidate.firstName?.slice(1)}{" "}
                                    {candidate.lastName
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      candidate.lastName?.slice(1)}
                                  </h6>
                                </div>
                                <div className="font-regular font-primary font-gray1">
                                  {candidate.designation && (
                                    <span>{candidate.designation}</span>
                                  )}{" "}
                                  {candidate.designation &&
                                    (candidate.city || candidate.state) &&
                                    "/"}{" "}
                                  {candidate?.city ? (
                                    <span>
                                      {candidate.city
                                        ? candidate.city + ", "
                                        : ""}
                                    </span>
                                  ) : (
                                    ""
                                  )}{" "}
                                  {candidate?.country ? (
                                    <span>
                                      {candidate.country
                                        ? candidate.country
                                        : ""}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <div className="mt-1 d-flex">
                                    <span>
                                      {candidate?.experienceLevel ===
                                      "Less than one year"
                                        ? "Fresher"
                                        : candidate?.experienceLevel}
                                    </span>
                                  </div>
                                  {/* <StatusData candidate={candidate} /> */}
                                </div>
                              </div>
                            </div>
                            <div
                              class={`  ${"card-body font-regular d-flex flex-column align-items-end justify-content-center"}`}
                            >
                              {candidate.homePhone ? (
                                <div class="mb-1">
                                  Contact no:
                                  {candidate?.homePhone
                                    ? new AsYouType().input(
                                        candidate.homePhone[0] === "+"
                                          ? candidate.homePhone
                                          : candidate.homePhoneCode &&
                                            candidate.homePhoneCode.includes(
                                              "+"
                                            )
                                          ? `${candidate.homePhoneCode}${candidate.homePhone}`
                                          : `+${candidate.homePhone}`
                                      )
                                    : ""}
                                </div>
                              ) : (
                                candidate?.workPhone && (
                                  <div class="mb-1">
                                    Contact no:{" "}
                                    {candidate?.workPhone
                                      ? new AsYouType().input(
                                          candidate.workPhone[0] === "+"
                                            ? candidate.workPhone
                                            : candidate.workPhoneCode &&
                                              candidate.workPhoneCode.includes(
                                                "+"
                                              )
                                            ? `${candidate.workPhoneCode}${candidate.workPhone}`
                                            : `+${candidate.workPhone}`
                                        )
                                      : ""}
                                  </div>
                                )
                              )}

                              {candidate?.email !== "" && candidate?.email && (
                                <div class="mb-1">
                                  Email:{" "}
                                  <span
                                    onClick={() => {
                                      // handleShowMail(candidate);
                                    }}
                                    className=""
                                    title={candidate?.email}
                                  >
                                    {candidate.email.length > 16 &&
                                    !candidate.showMail
                                      ? candidate?.email.slice(0, 16) + "..."
                                      : candidate?.email}
                                  </span>
                                </div>
                              )}
                              {candidate.preferredSalary !== 0 &&
                                candidate.preferredSalary && (
                                  <div class="mb-1">
                                    Preferred annual salary:{" "}
                                    {CurrencySymbols[
                                      candidate.preferredSalaryCurrency.slice(
                                        0,
                                        3
                                      )
                                    ]
                                      ? CurrencySymbols[
                                          candidate.preferredSalaryCurrency.slice(
                                            0,
                                            3
                                          )
                                        ]
                                      : ""}
                                    {Number(
                                      candidate.preferredSalary
                                    ).toLocaleString()}{" "}
                                    {CurrencySymbols[
                                      candidate.preferredSalaryCurrency.slice(
                                        0,
                                        3
                                      )
                                    ]
                                      ? ""
                                      : candidate.preferredSalaryCurrency.slice(
                                          0,
                                          3
                                        )}
                                  </div>
                                )}
                              {candidate.minContractRate !== 0 &&
                                candidate.minContractRate && (
                                  <div class="mb-1">
                                    Preferred hourly salary:{" "}
                                    {CurrencySymbols[
                                      candidate.minContractRateCurrency.slice(
                                        0,
                                        3
                                      )
                                    ]
                                      ? CurrencySymbols[
                                          candidate.minContractRateCurrency.slice(
                                            0,
                                            3
                                          )
                                        ]
                                      : candidate.minContractRateCurrency.slice(
                                          0,
                                          3
                                        )}
                                    {Number(
                                      candidate.minContractRate
                                    ).toLocaleString()}
                                    {CurrencySymbols[
                                      candidate.minContractRateCurrency.slice(
                                        0,
                                        3
                                      )
                                    ]
                                      ? ""
                                      : candidate.minContractRateCurrency.slice(
                                          0,
                                          3
                                        )}
                                  </div>
                                )}
                              {/* <div class="mb-1">
                            Source channel:{" "}
                            {getUserRole() === "HiringManager"
                              ? getUserTenantID() == candidate.fk_tenant
                                ? "Internal"
                                : "External"
                              : candidate.source === "Crintell"
                              ? "Crintell"
                              : careerPage.includes(candidate.source)
                              ? "Career Page"
                              : "Internal"}
                          </div> */}

                              {candidate.RecruiterName !== "" &&
                                candidate.RecruiterName && (
                                  <div class="mb-1">
                                    Recruiter name:{" "}
                                    {candidate.RecruiterName.length > 1 ? (
                                      !candidate.showAllRecs ? (
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className=" "
                                          // onClick={() => toggleShowRec(candidate)}
                                        >
                                          {candidate.RecruiterName[0] + "..."}{" "}
                                        </span>
                                      ) : (
                                        candidate.RecruiterName.join(", ")
                                      )
                                    ) : (
                                      candidate.RecruiterName
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* <div className="p-2 border-top d-flex justify-content-between">
                        <div className="mt-1 ml-2 d-flex">
                          {getUserRole() === "Recruiter" && (
                            <div className="mt-1 d-block">
                              {JSON.parse(candidate?.candidateTags)?.length > 0
                                ? "Tags: "
                                : ""}
                              {candidate?.candidateTags &&
                                JSON.parse(candidate?.candidateTags)
                                  ?.slice(0, 2)
                                  ?.map((item) => (
                                    <span className="tag mb-1 mr-1">
                                      {item}
                                    </span>
                                  ))}
                              {JSON.parse(candidate.candidateTags)?.length >
                                2 &&
                                candidate.ViewAllTags === true &&
                                JSON.parse(candidate?.candidateTags)
                                  .slice(
                                    2,
                                    JSON.parse(candidate.candidateTags)?.length
                                  )
                                  .map((item) => (
                                    <>
                                      <span class="tag mb-1 mr-1">{item}</span>
                                    </>
                                  ))}

                              {JSON.parse(candidate.candidateTags)?.length >
                                2 && (
                                <button
                                  onClick={(e) => onTagViewClick(e, candidate)}
                                  type="button"
                                  class="btn btn-text"
                                >
                                  {!candidate.ViewAllTags ? (
                                    <span>View all tags</span>
                                  ) : (
                                    <span>Show Less</span>
                                  )}
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          {candidate.updatedDateTime !== "" &&
                            new Date() >
                              new Date(candidate.updatedDateTime) && (
                              <div class="text-muted my-auto p-0">
                                Last updated:{" "}
                                <i className="far fa-clock mr-2"></i>
                                <span>
                                  {moment(
                                    new Date(candidate.updatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .charAt(0) === "a"
                                    ? moment(
                                        new Date(candidate.updatedDateTime),
                                        "MMDDYYYY"
                                      )
                                        .fromNow()
                                        .charAt(0)
                                        .toLocaleUpperCase() +
                                      moment(
                                        new Date(candidate.updatedDateTime),
                                        "MMDDYYYY"
                                      )
                                        .fromNow()
                                        .slice(1)
                                    : moment(
                                        new Date(candidate.updatedDateTime),
                                        "MMDDYYYY"
                                      ).fromNow()}
                                </span>
                                &nbsp;
                              </div>
                            )}

                          <span className=" ">
                            {candidate?.newSletter === "Unsubscribed" ? (
                              <i
                                title="Unsubscribed"
                                class="fas fa-bell-slash"
                                style={{ color: " #b70000" }}
                              />
                            ) : (
                              <i
                                title="Subscribed "
                                class="fas fa-bell"
                                style={{ color: " #007100" }}
                              />
                            )}
                          </span>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {searchData?.communityData &&
              searchData?.communityData.length > 0 && (
                <div className="flex-grow-1 col-md-3">
                  {" "}
                  <h6>Communities</h6>
                  <div>
                    {searchData?.communityData?.map((item, index) => (
                      <div className="col-12 mb-4" key={index}>
                        <div className="card card-tag-bottom">
                          <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <div className="text-left">
                                <p>
                                  <a
                                    className="text-black"
                                    href={`/high5hire/talentcommunity/${item.id_pool}`}
                                  >
                                    <strong>{item?.poolname}</strong>{" "}
                                  </a>

                                  <br />
                                  <span>
                                    <small>{item?.country}</small>
                                  </span>
                                </p>
                                {/* <div>
                              {item?.candidateName?.length === 0 ? (
                                <span className="">
                                  <small>No Candidate pooled</small>
                                </span>
                              ) : (
                                <span className="">
                                  <small>
                                    {item?.candidateName
                                      ?.slice(0, 3)
                                      .map((obj, i) => (
                                        <Avatar
                                          key={i}
                                          size="35"
                                          name={obj.name}
                                          round="80px"
                                        />
                                      ))}
                                    {item?.candidateName?.length > 3 && (
                                      <span className="">
                                        +{item?.candidateName?.length - 3}
                                      </span>
                                    )}
                                  </small>
                                </span>
                              )}
                            </div> */}
                              </div>
                              <div className="d-flex flex-column  gap-4">
                                {/* <div className="d-flex flex-column gap-2">
                            
                              <span>
                                Engaged:{" "}
                                <strong>
                                  {item?.candidateName?.filter(
                                    (obj) => obj.status === "Engaged"
                                  )?.length ?? 0}
                                </strong>
                              </span>
                            </div> */}
                                <CustomProgressBar item={item} />
                              </div>
                            </div>

                            <hr className="" />
                            <div className="d-flex justify-content-between">
                              <div className="text-muted my-auto p-0">
                                <span>{item?.poolowner}</span>
                              </div>

                              <div className="text-muted my-auto p-0">
                                <span>
                                  {moment(item.createddatetime).format(
                                    "MM/DD/YYYY"
                                  )}
                                </span>
                                &nbsp;
                              </div>

                              {/* {item.updateddatetime !== "" &&
                    new Date() > new Date(item.updateddatetime) && (
                      <div className="text-muted my-auto p-0">
                        Last updated: <i className="far fa-clock mr-2"></i>
                        <span>
                          {moment(new Date(item.updateddatetime), "MMDDYYYY")
                            .fromNow()
                            .charAt(0) === "a"
                            ? moment(new Date(item.updateddatetime), "MMDDYYYY")
                                .fromNow()
                                .charAt(0)
                                .toLocaleUpperCase() +
                              moment(new Date(item.updateddatetime), "MMDDYYYY")
                                .fromNow()
                                .slice(1)
                            : moment(
                                new Date(item.updateddatetime),
                                "MMDDYYYY"
                              ).fromNow()}
                        </span>
                        &nbsp;
                      </div>
                    )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        )}
        {(!searchInput ||
          (searchData.jobData.length === 0 &&
            searchData.talentData.length === 0 &&
            searchData.communityData.length === 0)) && <NoRecord />}
      </div>
    </div>
  );
};

export default SearchComponent;
