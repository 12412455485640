import React from "react";
import { Modal } from "react-bootstrap";

const FullViewEmail = ({
  openFullViewEmail,
  setOpenFullViewEmail,
  selectedMail,
  maskSensitiveInformation,
}) => {
  return (
    <Modal
      show={openFullViewEmail}
      size="lg"
      className="modal fade"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-header" id="EditQuestionModal">
        <h6 className="modal-title" id="EditQuestionModalLabel">
          Email Request
        </h6>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setOpenFullViewEmail(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <div className="card-body p-4 disabled-container">
          <h6>To : {selectedMail?.to_email}</h6>
          <h6>Subject : {selectedMail?.subject}</h6>

          <div
            className="mt-4 "
            dangerouslySetInnerHTML={{
              __html: maskSensitiveInformation(selectedMail?.MailContent),
            }}
          ></div>
        </div>
        <div className="modal-footer mt-4 mb-0">
          <button
            type="button"
            className="btn btn-sm btn-secondary mr-2"
            data-dismiss="modal"
            onClick={(e) => {
              setOpenFullViewEmail(false);
            }}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FullViewEmail;
