import {
  FETCH_JOB_TITLE_FILTERS_BEGIN,
  FETCH_JOB_TITLE_FILTERS_SUCCESS,
  FETCH_JOB_TITLE_FILTERS_FAILURE,
  FETCH_JOB_ID_FILTERS_BEGIN,
  FETCH_JOB_ID_FILTERS_SUCCESS,
  FETCH_JOB_ID_FILTERS_FAILURE,
  FETCH_JOB_LOCATION_FILTERS_BEGIN,
  FETCH_JOB_LOCATION_FILTERS_SUCCESS,
  FETCH_JOB_LOCATION_FILTERS_FAILURE,
} from "../../../action/filter/job/JobFilter";

const initialState = {
  loading: false,
  jobTitleFilters: null,
  jobIdFilters: null,
  jobLocationFilters: null,
};

const getJobFilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_JOB_TITLE_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobTitleFilters: null,
      };
    case FETCH_JOB_TITLE_FILTERS_SUCCESS:
      return {
        ...state,
        jobTitleFilters: payload,
        loading: false,
      };
    case FETCH_JOB_TITLE_FILTERS_FAILURE:
      return {
        ...state,
        jobTitleFilters: payload,
        loading: false,
      };
    case FETCH_JOB_ID_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobIdFilters: null,
      };
    case FETCH_JOB_ID_FILTERS_SUCCESS:
      return {
        ...state,
        jobIdFilters: payload,
        loading: false,
      };
    case FETCH_JOB_ID_FILTERS_FAILURE:
      return {
        ...state,
        jobIdFilters: payload,
        loading: false,
      };
    case FETCH_JOB_LOCATION_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobLocationFilters: null,
      };
    case FETCH_JOB_LOCATION_FILTERS_SUCCESS:
      return {
        ...state,
        jobLocationFilters: payload,
        loading: false,
      };
    case FETCH_JOB_LOCATION_FILTERS_FAILURE:
      return {
        ...state,
        jobLocationFilters: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getJobFilterReducer;
