import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Validator from "email-validator";

import {
  testMail,
  updateEmailTemplate,
} from "../../../action/adminconsole/EmailTemplate";
import Loader from "../../common/loader/Loader";
import { getUserId } from "../../../service/AuthService";

import { Footer, Header } from "./EmailPreview";
import TemplateIndex from "./template/TemplateIndex";
import EditTemplate from "./template/EditTemplate";
import PreviewTemplate from "./template/PreviewTemplate";
import TestMailMode from "./template/TestMailMode";
import { post } from "../../../service/ApiService";

function EmailTemplateModalView(props) {
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const dispatch = useDispatch();
  const { emailLoading } = useSelector(
    (state) => ({
      emailLoading: state.emailTemplateReducer.emailLoading,
    }),
    shallowEqual
  );
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [collapse, setCollapse] = useState({
    subject: true,
    content: false,
    footer: false,
    logo: false,
    updateOn: false,
    updatedBy: false,
    history: false,
  });

  const [editMode, setEditMode] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [previewMode, setPreviewMode] = useState(false);
  const [testMailMode, setTestMailMode] = useState(false);
  const [testMailRecipient, setTestMailRecipient] = useState("");
  const [editedValues, setEditedValues] = useState({
    mailSubject: props.selectedEmailData?.mailSubject || "",
    mailContent: props.selectedEmailData?.mailContent || "",
    footer: props.selectedEmailData?.footer || "",
    dynamicValues: props.selectedEmailData?.dynamicValues || [],
    description: props.selectedEmailData?.description || "",
    status: props.selectedEmailData?.status || "",
  });

  useEffect(() => {
    console.log(props.createNew);
    props.createNew && setEditMode(true);
  }, [props.createNew]);

  const handleClose = () => {
    props.setEmailFullView(false);
    props.setCreateNew(false);
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
    if (
      editedValues.mailSubject === "" ||
      sanitizeHTML(editedValues.mailContent) === "" ||
      sanitizeHTML(editedValues.footer) === ""
    ) {
      setIsValid(true);
      toast.error("Please enter all the required fields");
      return;
    }

    const updatedMailSubject = editedValues.mailSubject
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");
    const updatedMailContent = editedValues.mailContent
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");
    const updatedFooter = editedValues.footer
      .replace(/<p>/g, "<div>")
      .replace(/<\/p>/g, "</div>");

    const data = {
      id: props.selectedEmailData.id_emailTemplate,
      subject: updatedMailSubject,
      content: updatedMailContent,
      footer: updatedFooter,
      updatedBy: getUserId(),
    };
    setEditMode(false);

    let tempData = {
      mailSubject: updatedMailSubject,
      mailContent: updatedMailContent,
      footer: updatedFooter,
      id_emailTemplate: props.selectedEmailData.id_emailTemplate,
      unsubscribeContent: props.selectedEmailData.unsubscribeContent,
      recipients: props.selectedEmailData.recipients,
      events: props.selectedEmailData.events,
      status: props.selectedEmailData.status,
      logDetails: props.selectedEmailData.logDetails,
      dynamicValues: props.selectedEmailData.dynamicValues,
      description: props.selectedEmailData.description,
    };

    props.setSelectedEmailData(tempData);
    dispatch(updateEmailTemplate(data));
  };

  const handleCancelClick = () => {
    setIsValid(false);
    setEditedValues({
      mailSubject: props.selectedEmailData?.mailSubject || "",
      mailContent: props.selectedEmailData?.mailContent || "",
      footer: props.selectedEmailData?.footer || "",
      dynamicValues: props.selectedEmailData?.dynamicValues || [],
      description: props.selectedEmailData?.description || "",
      status: props.selectedEmailData?.status,
    });
    setEditMode(false);
    setPreviewMode(false);
    setTestMailMode(false);
  };
  const sendMail = (e) => {
    e.preventDefault();
    if (testMailRecipient === "") {
      setIsValid(false);
      toast.error("Please enter valid Recipient Email ID");
      return;
    } else if (!Validator.validate(testMailRecipient)) {
      setIsValid(false);
      toast.error("Please enter valid Recipient Email ID");
      return;
    } else {
      let data = {
        mailTo: testMailRecipient,
        mailSubject: props?.emailData?.mailSubject,
        mailContent:
          props?.emailData?.mailContent +
          props?.emailData?.Footer +
          props?.emailData?.unsubscribeContent,
      };
      dispatch(testMail(data));
    }
  };

  const createTemplate = async () => {
    if (!recipient) return toast.error("Please select atleast one recipient");

    try {
      setLoading(true);
      await post("/emailTemplate/create", {
        subject: editedValues?.mailSubject,
        content: editedValues?.mailContent,
        footer: editedValues?.footer,
        updatedBy: getUserId(),
        recipients: [recipient.value],
        events: "Communitcation module",
        emailFrequency: "Real Time",
        time: null,
        description: "Some description",
        dynamicVlaues: ["First name", "Job Name", "Click Here"],
      });
      setLoading(false);
      props.setEmailFullView(false);
      toast.success("Email Template Created Successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal show={props.emailFullView} size="xl">
      {loading && <Loader />}
      <Modal.Header className="bg-gray4">
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {emailLoading && <Loader />}
          <div className="pl-3 pr-3 pt-3 pb-0 mb-0 row d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap col-md-8 col-sm-12">
              <i
                className="fas fa-envelope-open-text mr-3"
                style={{ fontSize: 30 }}
              ></i>
              <h6> {props.selectedEmailData?.events} </h6>
            </div>
            {!props.createNew && (
              <div className="d-flex col-md-4 col-sm-12 justify-content-end align-items-center pr-4 gap-1">
                {" "}
                Status :
                {props.selectedEmailData?.status === "Active" ? (
                  <span className="tag tag-green3 mb-1 mr-1 px-2 mt-1">
                    {" "}
                    {props.selectedEmailData?.status}
                  </span>
                ) : (
                  <span className="tag tag-coral mb-1 mr-1 px-2 mt-1">
                    {" "}
                    <v style={{ color: "#fff" }}>
                      {props.selectedEmailData?.status}
                    </v>
                  </span>
                )}
              </div>
            )}
          </div>
          <hr className="m-0 mb-2 mt-2" />
          <div className="pt-0 mt-0 pl-3 pr-3">
            {!editMode && !previewMode && !testMailMode && (
              <TemplateIndex
                emailData={props.selectedEmailData}
                isValid={isValid}
                sanitizeHTML={sanitizeHTML}
                setEditedValues={setEditedValues}
                setEditMode={setEditMode}
                editMode={editMode}
                previewMode={previewMode}
                testMailMode={testMailMode}
                setPreviewMode={setPreviewMode}
                setTestMailMode={setTestMailMode}
              />
            )}
            {editMode && (
              <EditTemplate
                selectedEmailData={props.selectedEmailData}
                setSelectedEmailData={props.setSelectedEmailData}
                handleUpdateClick={handleUpdateClick}
                editedValues={editedValues}
                isValid={isValid}
                editMode={editMode}
                handleCancelClick={handleCancelClick}
                setEditedValues={setEditedValues}
                createNew={props.createNew}
                recipient={recipient}
                setRecipient={setRecipient}
              />
            )}
            {previewMode && (
              <PreviewTemplate
                emailData={props.selectedEmailData}
                Header={Header}
                Footer={Footer}
              />
            )}
            {testMailMode && !previewMode && !editMode && (
              <TestMailMode
                emailData={props.selectedEmailData}
                sendMail={sendMail}
                testMailRecipient={testMailRecipient}
                setTestMailRecipient={setTestMailRecipient}
                isValid={isValid}
                handleCancelClick={handleCancelClick}
                Validator={Validator}
                sanitizeHTML={sanitizeHTML}
                testMailMode={testMailMode}
              />
            )}
          </div>
        </div>{" "}
      </Modal.Body>
      <Modal.Footer>
        <div className="text end">
          <button
            onClick={previewMode ? handleCancelClick : handleClose}
            className="btn btn-md btn-secondary btn-sm"
          >
            {previewMode ? "Back" : "Close"}
          </button>
          {props.createNew && (
            <button
              onClick={createTemplate}
              className=" ml-2 btn btn-md  btn-sm"
            >
              {"Create"}
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EmailTemplateModalView;
