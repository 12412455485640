import Validator from "email-validator";
import { toast } from "react-toastify";

export const addNewCuratorValidate = (
  selectedCuratorClient,
  newCuratorData,
  phoneValidation
) => {
  let newErrors = {};
  if (!selectedCuratorClient || !selectedCuratorClient?.value) {
    toast.error("Please select client");
    newErrors.client = "*Required field";
  }
  if (!newCuratorData.firstName || newCuratorData.firstName === "")
    newErrors.firstName = "*Required field";
  else if (
    !/^[A-Z a-z\s]+$/.test(newCuratorData.firstName) ||
    /^\s*$/.test(newCuratorData.firstName)
  )
    newErrors.firstName = "Invalid First Name ";

  if (!newCuratorData.lastName || newCuratorData.lastName === "")
    newErrors.lastName = "*Required field";
  else if (
    !/^[A-Z a-z\s]+$/.test(newCuratorData.lastName) ||
    /^\s*$/.test(newCuratorData.lastName)
  )
    newErrors.lastName = "Invalid Last Name ";

  if (!newCuratorData.email || newCuratorData.email === "")
    newErrors.email = "*Required field";
  else if (!Validator.validate(newCuratorData.email))
    newErrors.email = "Invalid Email Id !";

  if (!newCuratorData.city || newCuratorData.city === "")
    newErrors.city = "*Required field";
  else if (
    !/^[A-Z a-z\s]+$/.test(newCuratorData.city) ||
    /^\s*$/.test(newCuratorData.city)
  )
    newErrors.city = "Invalid City";

  if (!newCuratorData.state || newCuratorData.state === "")
    newErrors.state = "*Required field";
  else if (
    !/^[A-Z a-z\s]+$/.test(newCuratorData.state) ||
    /^\s*$/.test(newCuratorData.state)
  )
    newErrors.state = "Invalid State";

  if (!newCuratorData.country || newCuratorData.country === "")
    newErrors.country = "*Required field";
  else if (
    !/^[A-Z a-z\s]+$/.test(newCuratorData.country) ||
    /^\s*$/.test(newCuratorData.country)
  )
    newErrors.country = "Invalid Country";
  if (!newCuratorData.phoneNumber || newCuratorData.phoneNumber === "")
    newErrors.phoneNumber = "*Required field";
  else if (!phoneValidation) newErrors.phoneNumber = "Invalid Phone number";

  return newErrors;
};
