import moment from "moment";
import React, { useEffect, useState } from "react";
import { vetStartUrl } from "../../../constants/Constants";
import { toast } from "react-toastify";

const Assessments = ({ onChangeClick, tabType, candidateData }) => {
  const [vettingData, setVettingData] = useState([]);
  useEffect(() => {
    if (
      candidateData !== null &&
      candidateData !== undefined &&
      Object.keys(candidateData)?.length > 0
    ) {
      const unique2 = candidateData?.vettingData.filter((obj, index) => {
        return (
          index ===
          candidateData.vettingData.findIndex(
            (o) =>
              obj.jobTitle === o.jobTitle && obj.uniqueCode === o.uniqueCode
          )
        );
      });
      setVettingData(unique2);
    }
  }, [candidateData]);

  const handleCopy = (eachdata) => {
    navigator.clipboard.writeText(eachdata);
    toast.success("Link Copied Successfully!!!");
  };
  return (
    <>
      {vettingData.length > 0 &&
        vettingData?.map((elem) => (
          <div
            class="card card-flat w-100"
            // style={{ pointerEvents: "none" }}
          >
            <div class="card-body">
              <div>
                <div>
                  <div>
                    <span class="tag tag-orange1 mr-1">
                      <strong>Assessment</strong>
                    </span>
                  </div>{" "}
                  <h6 class="mt-2">{elem?.jobTitle}</h6>
                </div>{" "}
                <div class="d-flex align-items-center">
                  <span class="d-flex align-items-center me-3">
                    <i
                      aria-hidden="true"
                      class="far fa-suitcase me-2 font-brandBlue"
                    ></i>{" "}
                    <span class="font-regular font-primary font-gray1">
                      {JSON.parse(elem.testInfo)?.testCategory}
                    </span>
                  </span>
                </div>{" "}
                <div class="mt-1">
                  <span
                    class={
                      elem?.vettingStatus === "completed"
                        ? "mr-2 tag font-bold tag-green1 mb-2"
                        : elem?.vettingStatus === "underReview"
                        ? "mr-2 tag font-bold tag-lightBlue mb-2"
                        : "mr-2 tag font-bold tag-orange1 mb-2"
                    }
                  >
                    {" "}
                    Status :{" "}
                    {elem?.vettingStatus === "completed"
                      ? "Completed"
                      : elem?.vettingStatus === "underReview"
                      ? "Under Review"
                      : "Invited"}
                  </span>
                </div>
                <div class="">
                  <small class="font-regular">
                    {moment(
                      new Date(elem?.updatedDateTime),
                      "MMDDYYYY"
                    ).fromNow()}
                    {/* 1 day ago By John Smith @TekSystems */}
                  </small>
                </div>{" "}
                {/* <div>
           <p class="mb-1">
             <small>Progress</small>
           </p>
           0%
           <div class="alert-progress alert-progress-static bg-gray3">
             <div class="alert-progress-bar" style={{ width: " 0%" }}></div>
           </div>
         </div> */}
              </div>{" "}
              <div class="mt-2">
                <div className="row">
                  {elem?.vettingStatus !== "completed" &&
                    elem?.vettingStatus !== "underReview" && (
                      <button
                        type="button"
                        className="btn btn-sm mb-1 "
                        style={{ width: "30%", marginLeft: "10px" }}
                        onClick={() =>
                          window.open(`${vetStartUrl}/candidate`, "_blank")
                        }
                      >
                        Start
                      </button>
                    )}
                  {(elem?.vettingStatus == "completed" ||
                    elem?.vettingStatus == "underReview") && (
                    <>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-sm btn-gray mb-1 "
                          disabled={
                            elem?.vettingStatus == "underReview" && true
                          }
                          onClick={
                            () => {
                              navigator.clipboard.writeText(
                                `${vetStartUrl}/result/${
                                  JSON.parse(elem.data)?.candidateId._id
                                }`
                              );
                              toast.success("Link Copied Successfully!!!");
                            }
                            // handleCopy(
                            //   `${vetStartUrl}/result/${sessionStorage.getItem(
                            //     "userId"
                            //   )}`
                            // )
                          }
                        >
                          <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                          Share Result
                        </button>
                      </div>
                      <div className="col-5">
                        <button
                          type="button"
                          className="btn btn-sm btn-gray mb-1"
                          disabled={
                            elem?.vettingStatus == "underReview" && true
                          }
                          onClick={() =>
                            window.open(
                              `${vetStartUrl}/result/${
                                JSON.parse(elem.data)?.candidateId._id
                              }`,
                              "_blank"
                            )
                          }
                        >
                          <i className="fal fa-list-alt mr-2" />
                          View Result
                        </button>
                      </div>
                    </>
                  )}

                  {/* <button type="button" class="btn btn-sm mr-1">
                  <span>Start</span>
                </button>{" "} */}
                  {/* <button
           type="button"
           class="btn btn-secondary btn-sm btn-blue-disabled"
         >
           <span>Reject</span>{" "}
         </button> */}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default Assessments;
