import React from "react";
import high5Logo from "../../../../../../assets/images/high5-logo.jpg";

const JoinTalentPoolFooter = () => {
  return (
    <div className="row mx-auto " style={{ width: "100%" }}>
      <div className="col-md-7">
        <footer>
          <div>
            <span className="text-secondary">Powered by</span>{" "}
            <a href="https://www.high5hire.com/" target="_blank">
              <img
                style={{
                  width: 40,
                  height: 35,
                  paddingBottom: 10,
                }}
                src={high5Logo}
              />
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default JoinTalentPoolFooter;
