import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import moment from "moment";
import { SidebarLogo } from "../../../constants/Constants";
import ProfileImage from "../../common/profileimage/ProfileImage";

const TableView = ({
  pool,
  setCurrentPage,
  currentPage,
  pageSize,
  setPageSize,
}) => {
  const history = useHistory();
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    setPaginatedData(pool);
  }, [currentPage, pageSize, pool]);

  useEffect(() => {
    setPageSize(10);
  }, [pageSize]);

  return (
    <>
      <div className="row d-flex pl-3">
        <table class="table table-sortable">
          <thead>
            <tr>
              <th className="no-hover">Logo</th>
              <th className="d-flex align-items-center no-hover">
                Community Name
              </th>
              <th className="no-hover">Location</th>
              <th className="no-hover">Created By</th>
              <th className="no-hover">Created On</th>
              <th className="no-hover">Automatched</th>
              <th className="no-hover">Engaged</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData &&
              paginatedData?.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <div>
                        <img
                          src={item.logourl || SidebarLogo}
                          width="75"
                          height="75"
                          className="p-2"
                          style={{
                            borderRadius: "50%",
                            objectFit: "contain",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() =>
                          history.push(
                            `/high5hire/talentcommunity/${item.poolId}`
                          )
                        }
                        className="d-flex align-items-center pe-5"
                        style={{ cursor: "pointer" }}
                      >
                        <h6 class="mb-0">{item?.poolname}</h6>
                      </div>
                    </td>
                    <td style={{ cursor: "default" }}>
                      <IoLocationOutline
                        color="#009eff"
                        size={18}
                        className="mr-1"
                      />
                      {item?.country}
                    </td>
                    <td style={{ cursor: "default" }}>{item?.poolowner}</td>
                    <td style={{ cursor: "default" }}>
                      {moment(item.createddatetime).format("MM/DD/YYYY")}
                    </td>
                    {(item?.candidateName?.filter(
                      (obj) => obj.status === "Automatched"
                    )?.length ?? 0) == 0 ? (
                      <td
                        className="p-0 text-center w-15"
                        style={{ cursor: "default", background: "#ebf1f7" }}
                      >
                        No New Automatched
                      </td>
                    ) : (
                      <td style={{ cursor: "default" }}>
                        <small title="Talent(s)">
                          {item?.candidateName
                            ?.filter((obj) => obj.status === "Automatched")
                            ?.slice(0, 3)
                            ?.map((obj, i) => (
                              <div
                                key={i}
                                className="avatar-name-candidatestableview d-inline-flex align-items-center"
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                  }}
                                >
                                  <ProfileImage
                                    imageSrc={obj.profileUrl}
                                    imageWidth={"35"}
                                    imageHeight={"35"}
                                    avatarSize={"35"}
                                    avatarRound={"80px"}
                                    firstName={obj.name.split(" ")[0]}
                                    lastName={obj?.name.split(" ")[1]}
                                  />
                                </span>
                              </div>
                            ))}

                          {item?.CountDetails?.totalCount > 3 && (
                            <span className="ml-1">
                              +
                              {new Intl.NumberFormat().format(
                                item?.CountDetails?.totalCount - 3
                              )}
                            </span>
                          )}
                        </small>
                      </td>
                    )}
                    {(item?.candidateName?.filter(
                      (obj) => obj.status === "Engaged"
                    )?.length ?? 0) == 0 ? (
                      <td
                        className="p-0 bg-gray3 text-center w-15"
                        style={{ cursor: "default" }}
                      >
                        No New Engaged
                      </td>
                    ) : (
                      <td
                        className="p-0 text-center w-15"
                        style={{ background: "#AED6F1", cursor: "default" }}
                      >
                        {new Intl.NumberFormat().format(
                          item?.candidateName?.filter(
                            (obj) => obj.status === "Engaged"
                          )?.length ?? 0
                        )}{" "}
                        New Engaged
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableView;
