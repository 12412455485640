import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { FaPaperPlane } from "react-icons/fa";
import { RiMailCloseFill } from "react-icons/ri";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";

import { getCandidateId } from "../../../service/AuthService";
import Pagination from "../../common/pagination/Pagination";
import { FetchSendEmailsByUser } from "../../../action/communication/Communication";
import Loader from "../../loader/Loader";
// import ModalView from "../../../../commoncomponents/modal/Modal";
import ProfileImage from "../../common/profileimage/ProfileImage";
import { utcToDateString } from "../../functions/utcDateConverter";

const Inbox = ({ selectedRecipients }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [openFullViewEmail, setOpenFullViewEmail] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});

  const renderTooltip = (item) => (
    <Tooltip
      id="question-tooltip"
      style={{ fontSize: "14px", maxWidth: "400px", padding: "5px" }}
    >
      {item}
    </Tooltip>
  );
  const { sendEmailsListLoading, sendEmailsList } = useSelector(
    (state) => ({
      sendEmailsListLoading: state.communicationReducer.sendEmailsListLoading,
      sendEmailsList: state.communicationReducer.sendEmailsList,
    }),
    shallowEqual
  );

  // State for search criteria
  const [searchCriteria, setSearchCriteria] = useState({
    fromDate: null,
    toDate: null,
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const dataSize = 10;

  useEffect(() => {
    fetchData(dataSize, currentPage, searchCriteria);
  }, [currentPage]); // Fetch data when currentPage changes

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchCriteria.name.length === 0 || searchCriteria.name.length >= 2) {
        setCurrentPage(1);
        fetchData(dataSize, 1, {
          fromDate: searchCriteria.fromDate
            ? utcToDateString(
                searchCriteria.fromDate,
                "YYYY-MM-DD,h:mm:ss a",
                "MM/DD/YYYY"
              )
            : searchCriteria.fromDate,
          toDate: searchCriteria.toDate
            ? utcToDateString(
                searchCriteria.toDate,
                "YYYY-MM-DD,h:mm:ss a",
                "MM/DD/YYYY"
              )
            : searchCriteria.toDate,
          name: searchCriteria.name,
        });
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchCriteria.name]);

  const fetchData = (dataSize, currentPage, searchCriteria) => {
    const selectedTalentsEmail = selectedRecipients.map((obj) => obj.email);
    const reqPayload = {
      userId: getCandidateId(),
      pageSize: dataSize,
      page: currentPage,
      fromDate: searchCriteria.fromDate
        ? utcToDateString(
            searchCriteria.fromDate,
            "YYYY-MM-DD,h:mm:ss a",
            "MM/DD/YYYY"
          )
        : searchCriteria.fromDate,
      toDate: searchCriteria.toDate
        ? utcToDateString(
            searchCriteria.toDate,
            "YYYY-MM-DD,h:mm:ss a",
            "MM/DD/YYYY"
          )
        : searchCriteria.toDate,
      name: searchCriteria.name,
      email: selectedTalentsEmail,
    };
    dispatch(FetchSendEmailsByUser(reqPayload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = () => {
    let isValid = true;
    if (!searchCriteria.fromDate && searchCriteria.toDate) {
      toast.error("Please select From date");
      isValid = false;
    }
    if (!searchCriteria.toDate && searchCriteria.fromDate) {
      toast.error("Please select To date");
      isValid = false;
    }
    if (searchCriteria.toDate < searchCriteria.fromDate) {
      toast.error("From date should be greater than To date");
      isValid = false;
    }
    setCurrentPage(1);
    if (isValid) {
      fetchData(dataSize, 1, searchCriteria);
    }
  };

  const handleClear = () => {
    setSearchCriteria({
      fromDate: null,
      toDate: null,
      name: "",
    });
    setCurrentPage(1);
    fetchData(dataSize, 1, {
      fromDate: null,
      toDate: null,
      name: "",
    });
  };

  const handleInputValue = (e) => {
    setSearchCriteria({
      ...searchCriteria,
      name: e.target.value,
    });
  };

  return (
    <>
      {sendEmailsListLoading && <Loader />}
      <div className="container pt-2">
        <h6 className="mx-2 mb-3">
          <FaPaperPlane size={18} className="mr-2" />
          Sent
        </h6>
        <Card className="card mb-2 p-2" style={{ fontSize: "14px" }}>
          <Card.Body>
            <div className="row">
              <div className="col">
                <label className="mr-2">From Date</label>
                <DatePicker
                  className="form-control font-14"
                  selected={searchCriteria.fromDate}
                  onChange={(date) =>
                    setSearchCriteria({ ...searchCriteria, fromDate: date })
                  }
                  placeholderText="From Date"
                  maxDate={searchCriteria.toDate || new Date()}
                />
              </div>
              <div className="col">
                <label className="mr-2">To Date</label>
                <DatePicker
                  className="form-control font-14"
                  selected={searchCriteria.toDate}
                  onChange={(date) =>
                    setSearchCriteria({ ...searchCriteria, toDate: date })
                  }
                  placeholderText="To Date"
                  maxDate={new Date()}
                />
              </div>
              <div className="col">
                <button
                  onClick={handleSearch}
                  className="btn btn-sm btn-primary"
                  style={{ marginTop: "37.5px" }}
                  disabled={
                    !searchCriteria.fromDate &&
                    !searchCriteria.toDate &&
                    !searchCriteria.name
                  }
                >
                  Search
                </button>
              </div>
              <div className="col">
                <label className="mr-2">Recipient Name</label>
                <input
                  type="text"
                  value={searchCriteria.name}
                  onChange={(e) => {
                    handleInputValue(e);
                  }}
                  className="form-control"
                  placeholder="Enter name"
                  style={{ fontSize: "14px" }}
                />
              </div>
              <div className="col">
                <button
                  onClick={handleClear}
                  className="btn btn-sm btn-success ml-3"
                  style={{ marginTop: "37.5px" }}
                  disabled={
                    !searchCriteria.fromDate &&
                    !searchCriteria.toDate &&
                    !searchCriteria.name
                  }
                >
                  Clear All
                </button>
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="p-2 card">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Recipient</th>
                <th scope="col">Email Id</th>
                <th scope="col">Subject</th>
                <th scope="col">Sent Date</th>
              </tr>
            </thead>
            <tbody>
              {sendEmailsList && sendEmailsList?.data?.length > 0 ? (
                sendEmailsList?.data?.map((email, index) => (
                  <tr>
                    <td className="text-start" style={{ cursor: "pointer" }}>
                      <div className="avatar-name-candidatestableview d-flex align-items-center">
                        <div>
                          <span>
                            <ProfileImage
                              imageSrc={email?.profileImagePath}
                              imageWidth={"20"}
                              imageHeight={"20"}
                              avatarSize={"30"}
                              avatarRound={"500px"}
                              firstName={email?.firstName}
                              lastName={email?.lastName}
                            />
                          </span>
                        </div>
                        <div className="ml-3">
                          <strong className="mb-0 me-2">
                            {email?.firstName} {email?.lastName}
                          </strong>
                        </div>
                      </div>
                    </td>
                    <td className="text-start">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={renderTooltip(email?.email)}
                      >
                        <span
                          style={{
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "inline-block",
                          }}
                        >
                          {email?.email.length > 35
                            ? `${email?.email.substring(0, 35)}...`
                            : email?.email}
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td>
                      <td className="p-relative" style={{ cursor: "pointer" }}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(email.MailSubject)}
                        >
                          <span
                            style={{
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                          >
                            {email?.MailSubject.length > 50
                              ? `${email?.MailSubject.substring(0, 50)}...`
                              : email?.MailSubject}
                          </span>
                        </OverlayTrigger>
                      </td>
                    </td>
                    <td>
                      {moment(email?.createdDateTime).format("MM/DD/YYYY")}
                    </td>
                    <td>
                      <span className="font-primary font-regular">
                        <button
                          style={{ color: "#000" }}
                          onClick={() => {
                            setOpenFullViewEmail(true);
                            setSelectedMail(email);
                          }}
                          className="btn btn-text"
                        >
                          <i className="fad fa-eye"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="text-center"
                    style={{
                      color: "grey",
                      border: "1px solid rgb(240 240 240)",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <span
                        className="d-flex justify-content-center align-items-center mb-1"
                        style={{
                          width: "70px",
                          height: "70px",
                          border: "1px solid transparent",
                          borderRadius: "50%",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        }}
                      >
                        <RiMailCloseFill size={35} color="#0073e6" />
                      </span>
                      <span>
                        <b>No Emails!</b>
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {sendEmailsList && sendEmailsList?.totalCount > 0 && (
            <>
              <Pagination
                currentPage={currentPage}
                totalItems={sendEmailsList?.totalCount}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            </>
          )}
        </div>
      </div>
      {/* {openFullViewEmail && (
        <ModalView
          modalSize={"lg"}
          modalTitle={"Email Preview"}
          isModalOpen={openFullViewEmail}
          setModalClose={setOpenFullViewEmail}
          isFrom="EmailOverview"
          modalFor="preview"
          data={{
            subject: selectedMail.MailSubject,
            to: selectedMail.email,
            content: selectedMail.MailContent,
            footer: selectedMail?.footer || "",
          }}
        />
      )} */}
    </>
  );
};

export default Inbox;
