import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../common/loader/Loader";
import GridView from "../../../job/joblist/GridView";
import { jobDetail, template } from "../../../../action/job/AddJob";
import { getJobListHM } from "../../../../action/job/Joblist";
import { getUserRole, loginUserName } from "../../../../service/AuthService";

// import AddJobSuccess from "../../../../modal/addjob/AddJobSuccess";
// import MainAddJob from "../../../job/addjob";
// import Event from "../../../../../googleanalytic/Tracking";

function Index() {
  const dispatch = useDispatch();
  const [requestList, setRequestList] = useState([]);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });
  const [addJobNewModal, setAddJobNewModal] = useState(false);
  const { jobListLoading, jobList } = useSelector(
    (state) => ({
      jobListLoading: state.JobList.jobListHmDashBoardLoading,
      jobList: state.JobList.jobListHmDashboard,
    }),
    shallowEqual
  );

  useEffect(() => {
    let paramObj = {
      currentPage: currentPage,
    };
    dispatch(getJobListHM(paramObj));
  }, []);

  useEffect(() => {
    if (jobList?.result?.length > 0) {
      setRequestList(jobList?.result);
      setTotal(jobList?.total);
    }
  }, [jobList]);

  const closeAddJobModal = () => {
    dispatch(template(false));
    dispatch(jobDetail(null));
    setAddJobNewModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      getJobListHM({
        currentPage: pageNumber,
      })
    );
  };

  return (
    <>
      {jobListLoading && <Loader />}
      <div className="card card-flat card-borderless bg-gray4 p-4 mb-3">
        <div className="d-flex align-items-center mb-3">
          <div className="d-md-flex align-items-center">
            <h6 className="mr-3 mb-0">Jobs</h6>
            <a href="/high5Hire/requests">Go To Full View</a>
          </div>
          <div className="ml-auto mr-4">
            <button
              type="button"
              className="btn btn-sm d-sm-block w-100"
              data-toggle="modal"
              data-target="#NewRequestModal"
              // onClick={() => {
              //   Event(
              //     "New Jobs Clicked",
              //     `${loginUserName()},${getUserRole()}`,
              //     window.location.pathname
              //   );
              //   setAddJobNewModal(true);
              // }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              <span className="d-none d-sm-inline">New Job</span>
            </button>
          </div>
        </div>
        {jobListLoading || requestList.length === 0 ? (
          <div>
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">No data found</div>
            </div>
          </div>
        ) : (
          <div className="row">
            <GridView
              jobList={requestList}
              parent="HMdashboard"
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              total={total}
              handlePageChange={handlePageChange}
            />
          </div>
        )}
        {/* <Modal show={addJobNewModal} size="lg">
          <Modal.Header>
            <h6 class="">New Job</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setAddJobNewModal(false)}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <MainAddJob
              setSuccessData={setSuccessData}
              setSuccessFormOpen={setSuccessFormOpen}
              closePanel={() => closeAddJobModal()}
            ></MainAddJob>
          </Modal.Body>
        </Modal>

        <Modal show={successFormOpen}>
          <div class="modal-content" style={{ width: "500px" }}>
            <div class="bl-modal-header">
              <h6 class="bl-modal-title">{"Jobs Created!"}</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSuccessFormOpen(false)}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <AddJobSuccess
                successData={successData}
                closeModal={() => setSuccessFormOpen(false)}
              />
            </Modal.Body>
          </div>
        </Modal> */}
      </div>
    </>
  );
}

export default Index;
