import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";

export const GET_RECRUITEMENT_PIPELINE_BEGIN =
  "GET_RECRUITEMENT_PIPELINE_BEGIN";
export const GET_RECRUITEMENT_PIPELINE_SUCCESS =
  "GET_RECRUITEMENT_PIPELINE_SUCCESS";
export const GET_RECRUITEMENT_PIPELINE_FAILURE =
  "GET_RECRUITEMENT_PIPELINE_FAILURE";

export const GET_RECRUITEMENT_PIPELINE_COUNT_BEGIN =
  "GET_RECRUITEMENT_PIPELINE_COUNT_BEGIN";
export const GET_RECRUITEMENT_PIPELINE_COUNT_SUCCESS =
  "GET_RECRUITEMENT_PIPELINE_COUNT_SUCCESS";
export const GET_RECRUITEMENT_PIPELINE_COUNT_FAILURE =
  "GET_RECRUITEMENT_PIPELINE_COUNT_FAILURE";

export const GET_RECRUITER_NAME_BEGIN = "GET_RECRUITER_NAME_BEGIN";
export const GET_RECRUITER_NAME_SUCCESS = "GET_RECRUITER_NAME_SUCCESS";
export const GET_RECRUITER_NAME_FAILURE = "GET_RECRUITER_NAME_FAILURE";

export const getRecruitementPipeline = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITEMENT_PIPELINE_BEGIN,
    });
    const res = await post("/dashboard/sa/getrecruitementpipeline", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITEMENT_PIPELINE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITEMENT_PIPELINE_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getRecruitementPipelineCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITEMENT_PIPELINE_COUNT_BEGIN,
    });
    const res = await post(
      "/dashboard/sa/getrecruitementpipelinecount",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITEMENT_PIPELINE_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITEMENT_PIPELINE_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getRecruiterNameData = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_NAME_BEGIN,
    });
    const res = await post("/dashboard/sa/getrecruiternamedata", params);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_NAME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_NAME_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
