import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  screeningQuestionsForSubCommunity,
  readyToHireScreening,
} from "../../../../constants/Constants";

function ScreeningQuestions({
  screeningQuestions,
  setScreeningQuestions,
  addJobData,
  skills,
  setSkills,
  certification,
  setCertification,
  edit,
}) {
  const [screeningList, setScreeningList] = useState([]);
  const [customQuestion, setCustomQuestion] = useState("");
  const [customQuestionValidate, setCustomQuestionValidate] = useState("");
  const [dropDownTextValue, setDropDowntextValue] = useState({});

  useEffect(() => {
    if (addJobData?.skillSet.filter((e) => e == "Healthcare").length > 0) {
      setScreeningList(screeningQuestionsForSubCommunity);
    } else {
      setScreeningList(screeningQuestionsForSubCommunity);
    }
  }, [addJobData]);

  const handleChange = (value, name, indexValue) => {
    let temp = [...screeningQuestions];
    let index;
    if (name === "Custom Question" && indexValue) {
      index = temp.findIndex((i) => i.index === indexValue);
    } else {
      index = temp.findIndex((i) => i.name === name);
    }
    if (value === "Drop-down" || value === "Drop-down(Multiselect)") {
      temp[index] = {
        ...temp[index],
        selectedOption: value,
        dropdownOptions: [],
      };
    } else {
      temp[index] = { ...temp[index], selectedOption: value };
      if (temp[index].hasOwnProperty("dropdownOptions")) {
        const { dropdownOptions, ...rest } = temp[index];
        temp[index] = rest;
      }
    }
    setScreeningQuestions(temp);
  };

  const handleAddDropdownOption = (item) => {
    if (dropDownTextValue[item.index]) {
      const temp = [...screeningQuestions];
      const index = temp.findIndex((i) => i.index === item.index);
      temp[index].dropdownOptions.push(dropDownTextValue[item.index]);
      setDropDowntextValue({ ...dropDownTextValue, [item.index]: "" });
      setScreeningQuestions(temp);
    }
  };

  const handleRemoveDropdownOption = (item, optionIndex) => {
    const temp = [...screeningQuestions];
    const index = temp.findIndex((i) => i.index === item.index);
    temp[index].dropdownOptions.splice(optionIndex, 1);
    setScreeningQuestions(temp);
  };

  const handleAdd = (item) => {
    if (customQuestion) {
      let temp = screeningQuestions?.filter(
        (i) =>
          i.name !== "Custom Question" ||
          (i.name === "Custom Question" && i.question !== "")
      );
      temp.push({
        ...screeningQuestions?.filter(
          (i) => i.name === "Custom Question" && i.question === ""
        )[0],
        question: customQuestion,
      });
      setScreeningQuestions(temp);
      setCustomQuestion("");
    } else {
      setCustomQuestionValidate("*Required Field");
    }
  };
  useEffect(() => {
    if (screeningQuestions && screeningQuestions.length > 5) {
      toast.error("You can only add a maximum of 5 questions.");
    }
  }, [screeningQuestions]);

  const handleRemoveQuestion = (questionIndex) => {
    setScreeningQuestions((prevQuestions) =>
      prevQuestions.filter((_, index) => index !== questionIndex)
    );
  };

  const handleQuestion = (item, index) => {
    if (item.name !== "Custom Question") {
      let question = item.question;
      let questionWithOption;
      if (item.options.length > 0) {
        let questionPartA = item.question.split("[X]");

        const selectElement = (
          <select
            name={item.name}
            id="screeningSelect"
            onChange={(e) => handleChange(e.target.value, item.name, index)}
          >
            {item.options.map((option, optionIndex) => (
              <option key={optionIndex} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
        questionWithOption = (
          <>
            <div className="flex-item">{questionPartA[0]}</div>
            <div className="flex-item">{selectElement}</div>
            <div className="flex-item">{questionPartA[1]}</div>
          </>
        );
      }

      if (item.name === "License/Certification") {
        let questionPartA = item.question.split("[X]");

        const selectElement = (
          <input
            name={item.name}
            id={item.name}
            value={item.selectedOption}
            // disabled={edit}
            onChange={(e) => {
              handleChange(e.target.value, item.name, index);
              setCertification(e.target.value);
            }}
          />
        );
        questionWithOption = (
          <>
            <div className="flex-item mt-2">{questionPartA[0]}</div>
            <div className="flex-item mr-1 ml-1">{selectElement}</div>
            <div className="flex-item mt-2">{questionPartA[1]}</div>
          </>
        );
      }
      if (item.name === "Skills and Proficiency") {
        let questionPartA = item.question.split("[X]");

        const selectElement = (
          <input
            name={item.name}
            id={item.name}
            value={item.selectedOption}
            // disabled={edit}
            onChange={(e) => {
              handleChange(e.target.value, item.name, index);
              setSkills(e.target.value);
            }}
          />
        );
        questionWithOption = (
          <>
            <div className="flex-item mt-2">{questionPartA[0]}</div>
            <div className="flex-item mr-1 ml-1">{selectElement}</div>
            <div className="flex-item mt-2">{questionPartA[1]}</div>
          </>
        );
      }

      // Replace other placeholders in the question string
      question = question
        .replace("[Skills]", `${addJobData.skills}`)
        .replace("[Location]", `${addJobData?.location}`)
        .replace("[Country]", `${addJobData?.country}`)
        .replace("[ExperienceLevel]", `${addJobData?.experienceLevel}`)
        .replace("[Education]", `${addJobData?.education}`)
        .replace("[specificIndustry]", `${addJobData?.skillSet}`)
        .replace("[relevantField]", `${addJobData?.skillSet}`)
        .replace("[Visa]", `${addJobData?.location}`);
      const updatedScreeningQuestions = [...screeningQuestions];
      updatedScreeningQuestions[index].question = question;
      return (
        <div className="card card-flat m-2" key={index}>
          <div className="card-body">
            <div
              className="d-flex p-3 mb-2"
              style={{ backgroundColor: "#dcdcdc" }}
            >
              {item.options.length > 0 ||
              item.name === "License/Certification" ||
              item.name === "Skills and Proficiency" ? (
                <>
                  <div className="d-flex flex-inline flex-wrap">
                    {questionWithOption}
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex flex-inline flex-wrap"
                    dangerouslySetInnerHTML={{ __html: question }}
                  ></div>
                </>
              )}

              <button
                className="ml-auto btn btn-icon"
                onClick={() => handleRemoveQuestion(index)}
              >
                <i className="fa fa-close"></i>
              </button>
            </div>
            <span>Response Type : {item.input}</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className="card card-flat bg-gray4 m-3"
      // style={{ pointerEvents: `${edit ? "none" : ""}` }}
    >
      <div className="card-body">
        <div className="px-3">
          {screeningQuestions?.length > 0 &&
            screeningQuestions?.map((item, index) =>
              handleQuestion(item, index)
            )}
          {screeningQuestions
            ?.map((i) => i.name)
            .includes("Custom Question") && (
            <div className="mt-3">
              <div className="m-1">Custom Questions: </div>
              {screeningQuestions
                ?.filter((i) => i.name === "Custom Question")
                .map((item) => (
                  <div className="card card-flat m-2">
                    <div className={"card-body"}>
                      {item.question ? (
                        <>
                          <div
                            className="d-flex p-3 mb-2"
                            style={{ backgroundColor: "#dcdcdc" }}
                          >
                            <div className="flex-item">
                              <medium>{item.question}</medium>
                            </div>
                            <button className="ml-auto btn btn-icon">
                              <i
                                className="fa fa-close"
                                onClick={() => {
                                  setScreeningQuestions([
                                    ...screeningQuestions?.filter(
                                      (i) => i.index !== item.index
                                    ),
                                  ]);
                                }}
                              ></i>
                            </button>
                          </div>
                        </>
                      ) : (
                        <div className="row">
                          <div
                            className="col-lg-8"
                            style={{ marginLeft: "3px" }}
                          >
                            <div className="form-floating mb-2">
                              <input
                                type="text"
                                className={
                                  customQuestionValidate
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                placeholder="Type Question"
                                onChange={(e) => {
                                  if (customQuestionValidate) {
                                    setCustomQuestionValidate("");
                                  }
                                  setCustomQuestion(e.target.value);
                                }}
                                value={customQuestion}
                              />
                              <label>Question</label>
                              {customQuestionValidate && (
                                <small className="validation ml-2">
                                  {customQuestionValidate}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="d-flex">
                              <button
                                className="btn btn-sm"
                                onClick={() => handleAdd(item)}
                              >
                                Add
                              </button>
                              <button
                                className="btn btn-sm btn-secondary ml-1"
                                onClick={() => {
                                  setCustomQuestion("");
                                  setCustomQuestionValidate("");
                                  setScreeningQuestions(
                                    screeningQuestions?.filter(
                                      (i) =>
                                        i.name !== "Custom Question" ||
                                        (i.name === "Custom Question" &&
                                          i.question !== "")
                                    )
                                  );
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex">
                        <div className="form-floating w-50">
                          <span>Response Type : {item.input}</span>
                        </div>
                      </div>
                      {(item.input === "Drop-down" ||
                        item.input === "Drop-down(Multiselect)") && (
                        <>
                          {item.dropdownOptions.map((option, optionIndex) => (
                            <div className="row ml-1 mt-3">
                              <div className="col-6">
                                <div key={optionIndex} className="mb-1">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={option}
                                    onChange={(e) => {
                                      const temp = [...screeningQuestions];
                                      const index = temp.findIndex(
                                        (i) => i.name === item.name
                                      );
                                      temp[index].dropdownOptions[optionIndex] =
                                        e.target.value;
                                      setScreeningQuestions(temp);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-2">
                                <i
                                  onClick={() =>
                                    handleRemoveDropdownOption(
                                      item,
                                      optionIndex
                                    )
                                  }
                                  className="fal fa-fw fa-trash-alt pointer"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          ))}
                          <div className="row ml-1 mt-3">
                            <div className="col-6">
                              <input
                                className="form-control"
                                type="text"
                                value={dropDownTextValue[item.index]}
                                onChange={(e) =>
                                  setDropDowntextValue({
                                    ...dropDownTextValue,
                                    [item.index]: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-2">
                              <button
                                className="btn btn-sm"
                                onClick={() => handleAddDropdownOption(item)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
          {screeningQuestions?.length > 0 && <hr className="m-2"></hr>}

          <div>
            {screeningList.map((item) => (
              <button
                className="btn btn-sm m-1"
                onClick={() => {
                  if (
                    item.name === "Custom Question" &&
                    screeningQuestions?.filter(
                      (i) => i.name === "Custom Question" && i.question === ""
                    ).length > 0
                  ) {
                    toast.error("Please complete the Custom Question Info");
                  } else {
                    let temp = [...screeningQuestions];
                    let item2 = {};
                    if (item.name === "Custom Question") {
                      let maxIndex =
                        screeningQuestions?.filter(
                          (i) => i.name === "Custom Question"
                        ).length + 1;
                      item2 = { ...item, index: maxIndex };
                      temp.push(item2);
                    } else {
                      item2 = { ...item };
                      temp.push(item2);
                    }
                    setScreeningQuestions(temp);
                  }
                }}
                style={{ background: "none", color: "#333" }}
                disabled={
                  screeningQuestions
                    ?.filter((i) => i.name && i.name !== "Custom Question")
                    .map((val) => val.name)
                    .includes(item.name) || screeningQuestions.length >= 5
                }
              >
                <i className="fas fa-fw mr-2 fa-plus" aria-hidden="true"></i>
                {item.name}
              </button>
            ))}
          </div>
          <div>
            {readyToHireScreening.map((item) => (
              <button
                className="btn btn-sm m-1"
                onClick={() => {
                  if (
                    item.name === "Custom Question" &&
                    screeningQuestions?.filter(
                      (i) => i.name === "Custom Question" && i.question === ""
                    ).length > 0
                  ) {
                    toast.error("Please complete the Custom Question Info");
                  } else {
                    let temp = [...screeningQuestions];
                    let item2 = {};
                    if (item.name === "Custom Question") {
                      let maxIndex =
                        screeningQuestions?.filter(
                          (i) => i.name === "Custom Question"
                        ).length + 1;
                      item2 = { ...item, index: maxIndex };
                      temp.push(item2);
                    } else {
                      item2 = { ...item };
                      temp.push(item2);
                    }
                    setScreeningQuestions(temp);
                  }
                }}
                style={{
                  background: "none",
                  color: "#333",
                  border: "1px solid #1a5d27",
                }}
                disabled={
                  screeningQuestions
                    ?.filter((i) => i.name && i.name !== "Custom Question")
                    .map((val) => val.name)
                    .includes(item.name) || screeningQuestions.length === 5
                }
              >
                <i className="fas fa-fw mr-2 fa-plus" aria-hidden="true"></i>
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreeningQuestions;
