import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import FormSubmitSuccess from "../../../modal/successmodal/FormSubmitSuccess";
import InviteBulkTalent from "./InviteBulkTalent";
import InviteTalent from "./InviteTalent";

const Index = (props) => {
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: "Talent invited successfully!",
    subTitle: `Talent has been invited for this job.`,
    htmlTag: "",
  });
  const [bulkUpload, setBulkUpload] = useState(false);
  const [shareClicked, setShareClicked] = useState(false);
  const [isBulkInvite, setIsBulkInvite] = useState(true);
  const [checkValidation, setCheckValidation] = useState(false);

  const shareClose = (e) => {
    setSuccessFormOpen(false);
    props.setShareButton(false);
    setCheckValidation(false);
  };

  return (
    <div>
      <Modal show={props.shareButton} onHide={shareClose} size="xl">
        <div className="bl-modal-header">
          <h5 className="bl-modal-title">
            {successFormOpen ? "Talent Invited!" : "Invite Talent"}
          </h5>
          <div className="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={shareClose}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            {successFormOpen ? (
              <FormSubmitSuccess
                successData={successData}
                handleSuccess={shareClose}
              />
            ) : (
              <>
                <InviteTalent
                  handleSuccess={shareClose}
                  jobData={props.jobData}
                  setSuccessFormOpen={setSuccessFormOpen}
                  handleSuccessForm={props.handleSuccessForm}
                  setIsBulkInvite={setIsBulkInvite}
                  bulkUpload={bulkUpload}
                  setCheckValidation={setCheckValidation}
                  checkValidation={checkValidation}
                  isFrom={props?.isFrom}
                />
                <div className="row">
                  <div className=" form-switch d-flex align-items-center pl-0">
                    <span
                      style={{
                        marginLeft: 50,
                      }}
                      className="form-check-label pt-0 bold"
                      htmlFor="remoteSwitch"
                    >
                      Bulk Invite Talent by uploading CSV/XLS
                    </span>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      value=""
                      disabled={!isBulkInvite}
                      onChange={() => {
                        setBulkUpload(!bulkUpload);
                        setCheckValidation(false);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {bulkUpload && (
              <InviteBulkTalent
                handleSuccess={props.shareClose}
                jobData={props.jobData}
                isFrom={props?.isFrom}
              />
            )}
            <div className="row text-end">
              <div className="w-75"></div>
              <div className="w-25">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={shareClose}
                  disabled={shareClicked}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
