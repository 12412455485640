import React from "react";

const Clients = ({
  isFrom,
  client,
  clients,
  onCollapsibleClick,
  testId,
  setTestId,
  removeItemFromArray,
}) => {
  return (
    <>
      {isFrom == "High5Admin" && (
        <div>
          {client?.length > 0 && (
            <button
              onClick={() => {
                onCollapsibleClick("clients");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!clients ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Clients</small>
              </span>
            </button>
          )}
          <div className={clients ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              <div>
                {client?.map(
                  (item, index) =>
                    (index < 2 || testId.includes("clients")) && (
                      <div
                        key={index}
                        className="tag tag-blue3"
                        style={{ margin: "2px" }}
                      >
                        <span>{item}</span>
                      </div>
                    )
                )}

                {!testId.includes("clients") ? (
                  client?.length > 2 && (
                    <div
                      className="tag"
                      onClick={() => setTestId([...testId, "clients"])}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      +{client?.length - 2} more
                    </div>
                  )
                ) : (
                  <div
                    className="tag"
                    onClick={() => removeItemFromArray("clients")}
                    style={{ margin: "2px", cursor: "pointer" }}
                  >
                    Show less
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Clients;
