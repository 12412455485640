import React, { useEffect } from "react";
import ScreeningQuestions from "./ScreeningQuestions";
import "react-tagsinput/react-tagsinput.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function InterviewPlan({
  addJobData,
  setAddJobData,
  screeningQuestions,
  setScreeningQuestions,
  selectedLanguages,
  setSelectedLanguages,
  skillSetOption,
  addJobVal,
  skills,
  setSkills,
  certification,
  setCertification,
  edit,
}) {


  useEffect(() => {
    setAddJobData({
      ...addJobData,
    });
  }, []);

  const toolTip = () => {
    return (
      <Tooltip style={{ zIndex: 99999999, maxWidth: "300px" }}>
        <div style={{ textAlign: "left", marginLeft: "12px" }}>
          <h6>What to do:</h6>
          <ol>
            <li>Select whether to include knockout assessment or not.</li>
            <li>
              Choose whether to use system recommended questions or create
              custom questions.
            </li>
            <li>
              If defining custom questions, enter the questions text and
              expected answers.
            </li>
            <li>
              Review system recommended or custom questions to ensure they meet
              your knockout assessment criteria.
            </li>
            <li>
              Once you have reviewed the questions, click next to apply the
              settings and move the next section .
            </li>
          </ol>
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="card-body">
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            <div className="d-flex">
              <div className=""></div>
              <span>
                Consider adding Knockout Assessment to streamline candidate
                selection
              </span>
              <OverlayTrigger placement="bottom" overlay={toolTip()}>
                <label
                  className="ml-auto mr-4"
                  style={{ color: "red", cursor: "pointer", padding: "0" }}
                >
                  What to do...
                </label>
              </OverlayTrigger>
            </div>
            <div>
              <small className="ml-1">
                -Select up to five questions that candidates must answer.
              </small>
            </div>

            {
              <div className={"mb-4"}>
                <ScreeningQuestions
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  skillSetOption={skillSetOption}
                  screeningQuestions={screeningQuestions}
                  setScreeningQuestions={setScreeningQuestions}
                  addJobData={addJobData}
                  addJobVal={addJobVal}
                  setAddJobData={setAddJobData}
                  skills={skills}
                  setSkills={setSkills}
                  certification={certification}
                  setCertification={setCertification}
                  edit={edit}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default InterviewPlan;
