import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import NoRecord from "../common/NoRecord";
import { post } from "../../service/ApiService";
import Loader from "../loader/Loader";
import Pagination from "../common/pagination/Pagination";
import FullViewEmail from "./FullViewEmail";

import {
  Automatched_Recruiter,
  Hired,
  Invited_HM,
  Invited_Recruiter,
  Offered,
  Shortlisted,
  Submitted,
  jobPublished,
  SuperAdminUsers,
  SuperAdminTenants,
  CSM_Users,
  CMRecInvite,
  Automatched_Recruiter_SubComm,
  Invited_Recruiter_SubComm,
} from "./EmailStatusQueries";

const EmailStatus = (props) => {
  const { emailStatus, setEmailStatus, email, isFrom, jobData, user } = props;
  let query = (() => {
    if (isFrom === "Invited") return Invited_Recruiter;
    if (isFrom === "Qualified") return Invited_HM;
    if (isFrom === "autoMatched") return Automatched_Recruiter;
    if (isFrom === "Submitted") return Submitted;
    if (isFrom === "Shortlisted") return Shortlisted;
    if (isFrom === "Offered") return Offered;
    if (isFrom === "Hired") return Hired;
    if (isFrom === "RecruiterList") return jobPublished;
    if (isFrom === "SuperAdminUsers") return SuperAdminUsers;
    if (isFrom === "CSM_Users") return CSM_Users;
    if (isFrom === "SuperAdminTenants") return SuperAdminTenants;
    if (isFrom === "CMRecInvite") return CMRecInvite;
    if (isFrom === "communityAutoMatched") return Automatched_Recruiter_SubComm;
  })();

  const [dataSize, setDataSize] = useState(10);
  const [pageSize, setPageSize] = useState(5);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [toDate, setToDate] = useState(new Date());
  const [openFullViewEmail, setOpenFullViewEmail] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});

  useEffect(() => {
    setCurrentPage(1);
  }, [dataSize]);

  const getEmailStatus = (params) => {
    try {
      setLoading(true);
      post("/sendGrid/filterByRecipient", params)
        .then((response) => {
          let d = response.data;
          setData(d);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const newData = data.slice(startIndex, endIndex);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleDateSearch = () => {
    let params = {};

    params = {
      jobId: jobData?.jobId,
      queryObj: {
        limit: 10000,
        query: `to_email="${email}" AND ${query} AND last_event_time BETWEEN TIMESTAMP "${fromDate.toISOString()}" AND TIMESTAMP "${toDate.toISOString()}"`,
      },
      user: user,
    };
    getEmailStatus(params);
  };

  useEffect(() => {
    let params = {};

    params = {
      queryObj: {
        limit: 10000,
        query: `${query} AND (to_email="${email}")`,
      },
      jobId: jobData?.jobId,
      user: user,
    };

    getEmailStatus(params);
  }, []);

  const messageHandler = (item) => {
    if (
      item.subject.includes("Reminder to Apply job") &&
      (isFrom === "Invited" || isFrom === "Qualified")
    ) {
      return (
        <>
          <span>{"Job invitation reminder at 24 hours"}</span>
        </>
      );
    } else if (
      item.subject.includes("As per our conversation") &&
      (isFrom === "Invited" || isFrom === "Qualified")
    ) {
      return (
        <>
          <span>{"Job invitation reminder at 48 hours"}</span>
        </>
      );
    } else if (
      item.subject.includes("You've been invited to apply for a job!") &&
      (isFrom === "Invited" || isFrom === "Qualified")
    ) {
      return (
        <>
          <span>{"Talent invited"}</span>
          {/* <i class="fa fa-circle ml-2" aria-hidden="true"></i> */}
        </>
      );
    } else if (
      item.subject.includes("You’ve Been Matched to") &&
      isFrom === "autoMatched"
    ) {
      return (
        <>
          <span>{"Auto-Matched Talent Invite"}</span>
        </>
      );
    } else if (
      item.subject.includes("Application Successfully Submitted!") &&
      isFrom === "Submitted"
    ) {
      return (
        <>
          <span>{"Application Successfully Submitted"}</span>
        </>
      );
    } else if (
      item.subject.includes("Your profile has been shortlisted") &&
      isFrom === "Shortlisted"
    ) {
      return (
        <>
          <span>{"Profile Shortlisted"}</span>
        </>
      );
    } else if (
      item.subject.includes("Congratulations! You have been offered") &&
      isFrom === "Offered"
    ) {
      return (
        <>
          <span>{"Job Offered!"}</span>
        </>
      );
    } else if (
      item.subject.includes("Welcome to High5!") &&
      isFrom === "Hired"
    ) {
      return (
        <>
          <span>{"Candidate Hired"}</span>
        </>
      );
    } else if (
      item.subject.includes(
        "Your Exclusive Invitation to Join the High5 Marketplace"
      )
    ) {
      return (
        <>
          <span>{"Invite for registration"}</span>
        </>
      );
    } else if (
      item.subject.includes(
        "Rediscover Success: Unveil Extraordinary Opportunities and Rewards on Our Platform"
      )
    ) {
      return (
        <>
          <span>{"Invite for registration reminder"}</span>
        </>
      );
    } else
      return (
        <>
          <span>{item.subject}</span>
        </>
      );
  };

  const limitHandler = (e) => {
    setDataSize(e);
  };

  const handleChange = (date, dateStr) => {
    if (dateStr === "fromDate") {
      date > toDate
        ? toast.error("From date cannot be greater than To date")
        : setFromDate(date);
    }
    if (dateStr === "toDate") {
      date < fromDate
        ? toast.error("To date cannot be less than From date")
        : setToDate(date);
    }
  };

  const maskSensitiveInformation = (htmlContent) => {
    // Regular expression to match email pattern
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    // Regular expression to match password pattern
    const passwordRegex = /(Password:\s*)(\w+)/gi;
    const passwordRegexWithOptionalSpace = /(Password\s*:\s*)(\w+)/gi;

    // Replace email with masked version
    const maskedEmailContent = htmlContent?.replace(emailRegex, (match) => {
      const [username, domain] = match?.split("@");
      const maskedUsername = username[0] + "*"?.repeat(username.length - 1);
      return `${maskedUsername}@${domain}`;
    });

    // Replace password with masked version
    const maskedContent = maskedEmailContent
      ?.replace(passwordRegex, (match, p1, p2) => {
        const maskedPassword = "*"?.repeat(p2.length);
        return `${p1}${maskedPassword}`;
      })
      ?.replace(passwordRegexWithOptionalSpace, (match, p1, p2) => {
        const maskedPassword = "*"?.repeat(p2.length);
        return `${p1}${maskedPassword}`;
      });

    return maskedContent;
  };

  return (
    <>
      <Modal
        size="xl"
        show={emailStatus}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            {loading && <Loader />}
            <h6 className="bl-modal-title">Email status</h6>
            <button
              type="button"
              onClick={() => setEmailStatus(false)}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row">
            {
              <div class="bl-modal-body">
                <div className="d-md-flex justify-content-between mb-3">
                  <div className="d-flex col-md-8">
                    <div className="p-2 bd-highlight" style={{ width: "10em" }}>
                      <label htmlFor="start-date">
                        <h6 className="m-0">From</h6>
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={fromDate}
                        onChange={(date) => handleChange(date, "fromDate")}
                        // onChangeRaw={(e) => e.preventDefault()}
                        style={{}}
                      />
                    </div>
                    <div className="p-2 bd-highlight" style={{ width: "10em" }}>
                      <label htmlFor="end-date">
                        <h6 className="m-0">To</h6>
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={toDate}
                        onChange={(date) => handleChange(date, "toDate")}
                        // onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="p-2 align-self-end bd-highlight mt-10 ">
                      <button
                        className="btn btn-sm btn-primary"
                        type="submit"
                        onClick={() => handleDateSearch()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  <div className="">
                    <div className="bd-highlight ml-auto mt-4">
                      <h6 className="mt-1">Records</h6>
                      <select
                        className="form-select"
                        // onChange={(e) => setDataSize(e.target.value)}
                        onChange={(e) => limitHandler(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="99999999">All</option>
                      </select>
                    </div>
                  </div>
                </div>
                {data.length === 0 ? (
                  <NoRecord />
                ) : (
                  <div className="table-responsive">
                    <table class="table table-sortable   table-hoverable">
                      <thead>
                        <tr>
                          <th class="no-hover">
                            <div class="d-flex align-items-center">Email</div>
                          </th>
                          <th class="no-hover">
                            <div class="d-flex align-items-center">Status</div>
                          </th>
                          <th class="no-hover">
                            <div class="d-flex align-items-center">
                              Invited on
                            </div>
                          </th>
                          <th class="no-hover">
                            <div class="d-flex align-items-center">
                              Last event received on
                            </div>
                          </th>
                          <th class="no-hover" style={{ textAlign: "center" }}>
                            <div>Opens</div>
                          </th>{" "}
                          <th class="no-hover" style={{ textAlign: "center" }}>
                            <div>Clicks</div>
                          </th>
                          <th class="no-hover"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {newData.map((item) => (
                          <tr>
                            <td>{messageHandler(item)}</td>
                            <td class="p-relative">
                              <div class="avatar-name-candidatestableview d-flex align-items-center">
                                <div>
                                  {item.status === "delivered" ? (
                                    <i className="fas fa-circle text-success" />
                                  ) : item.status === "not_delivered" ? (
                                    <i className="fas fa-circle text-danger" />
                                  ) : (
                                    <i className="fas fa-circle text-warning" />
                                  )}
                                  <span className="ml-2">
                                    {item.status === "delivered"
                                      ? "Delivered"
                                      : item.status === "not_delivered"
                                      ? "Not delivered"
                                      : "Processing"}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td>
                              <div>
                                <span>
                                  {isFrom === "RecruiterList" ||
                                  isFrom === "CMRecInvite" ||
                                  isFrom === "CSM_Users"
                                    ? moment(
                                        new Date(item?.createdDateTime),
                                        "MMDDYYYY"
                                      ).format("MM/DD/YYYY")
                                    : moment(
                                        new Date(props?.invitedDate),
                                        "MMDDYYYY"
                                      ).format("MM/DD/YYYY")}
                                </span>
                                &nbsp;
                              </div>
                            </td>

                            <td>
                              <div>
                                <span>
                                  {moment(
                                    new Date(item.last_event_time),
                                    "MMDDYYYY"
                                  ).format("MM/DD/YYYY, h:mm:ss a")}
                                </span>
                                &nbsp;
                              </div>
                            </td>

                            <td
                              className="align-items-center"
                              style={{ textAlign: "center" }}
                            >
                              <span>{item.opens_count}</span>
                            </td>
                            <td
                              className="align-items-center"
                              style={{ textAlign: "center" }}
                            >
                              <span>{item.clicks_count}</span>
                            </td>
                            <td>
                              {isFrom === "SuperAdminUsers" ||
                                isFrom === "SuperAdminTenants" ||
                                (isFrom !== "RecruiterList" && (
                                  <span className="font-primary font-regular">
                                    <button
                                      style={{ color: "#000" }}
                                      onClick={() => {
                                        setOpenFullViewEmail(true);
                                        setSelectedMail(item);
                                      }}
                                      className="btn btn-text"
                                    >
                                      <i className="fad fa-eye"></i>
                                    </button>
                                  </span>
                                ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {data.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalItems={data.length}
                    itemsPerPage={pageSize}
                    onPageChange={handlePageChange}
                    limit={dataSize}
                  />
                )}
              </div>
            }
          </div>
          <div style={{ padding: "1px 2px 35px 0px" }}></div>
        </Modal.Body>
      </Modal>
      {openFullViewEmail && (
        <FullViewEmail
          openFullViewEmail={openFullViewEmail}
          setOpenFullViewEmail={setOpenFullViewEmail}
          selectedMail={selectedMail}
          maskSensitiveInformation={maskSensitiveInformation}
        />
      )}
    </>
  );
};

export default EmailStatus;
