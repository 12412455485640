import React, { useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { post } from "../../../service/ApiService";
import Loader from "../../common/loader/Loader";
import { getUserTenantID } from "../../../service/AuthService";
import NameAndOwner from "./NameAndOwner";
import Purpose from "./Purpose";
import Pools from "./Pools";
import { validate } from "./Validation";

const AddGroup = (props) => {
  const fk_tenant = getUserTenantID();
  const location = useLocation();
  const [groupData, setGroupData] = useState({
    createdby: fk_tenant,
    id_group: props?.edit ? props?.groupData?.id_group : "",
    groupname: props?.edit ? props?.groupData?.groupname : "",
    ownername: props?.edit ? props?.groupData?.ownername : "",
    purpose: props?.edit ? props?.groupData?.purpose : "",
    poollist: props?.edit ? props?.groupData?.poolId : [],
  });
  const [loading, setLoading] = useState(false);
  const [list, setlist] = useState([]);

  const addGroupToDB = async () => {
    setLoading(true);
    const params = {
      id_group: groupData.id_group,
      groupname: groupData.groupname,
      groupowner: groupData.ownername,
      purpose: groupData.purpose,
      poollist: JSON.stringify(groupData.poollist),
    };
    if (props?.edit) {
      await post("/talent/editgroup", params)
        .then((res) => {
          setLoading(false);
          toast.success("Community successfully updated!");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          setLoading(false);
          props.setShowAddGroup(false);
          props.getGroupsFromDB();
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    } else {
      await post("/talent/addgroup", params)
        .then((res) => {
          toast.success("Community successfully added!");
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          setLoading(false);
          props.setShowAddGroup(false);
          props.getGroupsFromDB();
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  const submit = () => {
    validate(groupData) == true && addGroupToDB();
  };
  return (
    <>
      {loading && <Loader />}
      <div className="d-flex mb-2 align-items-center">
        <h5 className="ml-3">
          {props?.edit ? "Edit Community" : "Create Community"}
        </h5>
        <label className="ml-auto mr-4">*denotes required field</label>
      </div>
      <NameAndOwner
        groupData={groupData}
        list={list}
        setGroupData={setGroupData}
      />
      <Purpose groupData={groupData} setGroupData={setGroupData} />
      <Pools groupData={groupData} setGroupData={setGroupData} />
      <hr />
      <div className="text-right mb-2 mr-2">
        <button
          className="btn btn-sm btn-secondary mr-2"
          onClick={() => {
            props.setShowAddGroup(false);
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={() => {
            submit();
          }}
        >
          {props?.edit ? "Update" : "Submit"}
        </button>
      </div>
    </>
  );
};

export default AddGroup;
