import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import HereMap from "../../../../location/HereMap";
import { post } from "../../../../../service/ApiService";
import { domain } from "../../../../../constants/Constants";
import { getUserRole } from "../../../../../service/AuthService";
import Loader from "../../../../loader/Loader";
import { addUserValidation } from "../../../../validation/AddUser";
import { getHigh5UsersData } from "../../../../../action/dashboard/superadmin/high5users/High5Users";

function AddUser({ closeAdd, userlist, addusersCondition }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    lastname: "",
    email: "",
    name: "",
    User: "",
    Usertype: "",
    stateName: "",
    CountryName: "",
    cityName: "",
    address: "",
    zipCode: "NA",
    phone: "",
    phoneCode: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = addUserValidation(form);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else if (
      !phoneValidation &&
      (form.Usertype === "HC" || form.Usertype === "CM")
    ) {
      toast.error("Please enter a valid phone number");
    } else {
      try {
        setLoading(true);
        const paramters = {
          ...form,
          domainName: domain,
        };
        const res = await post("/dashboard/sa/saveadminuser", paramters);
        if (res.status === 200) {
          setLoading(false);
          const result = res.data;
          if (result.InsertDescription == "EmailId Already Exist") {
            toast.error("User is already present");
          } else if (result.userdata.RowsInserted == 1) {
            dispatch(getHigh5UsersData());
            closeAdd(true);
            toast.success("User added successfully ");
          } else {
            closeAdd(true);
            toast.error("Error Occured while saving User Details ");
          }
        }
      } catch (error) {
        setLoading(false);
        closeAdd(true);
        toast.error("Error Occured While saving  User  Details");
      }
    }
  };

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const close = () => {
    closeAdd(true);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  let type = "";
  if (userlist) {
    type = userlist.type;
  }

  const role = getUserRole();

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                {userlist
                  ? "Add User"
                  : addusersCondition === true
                  ? "Add User"
                  : "Add Tenant"}
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="bl-modal-body pb-0">
              <div class="card card-flat card-borderless bg-gray4 text-center">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <select
                            className={`form-control ${
                              errors.Usertype ? "border border-danger" : ""
                            }`}
                            onChange={(e) =>
                              setField("Usertype", e.target.value)
                            }
                            value={form.Usertype}
                            s
                          >
                            <option value="">Select User Type</option>
                            {role === "SuperAdmin" && (
                              <option value="CS">High5 Admin</option>
                            )}
                            <option value="HC">Customer Success Manager</option>
                            {role !== "SalesAdmin" && (
                              <option value="CM">Marketplace Manager</option>
                            )}
                          </select>

                          <label htmlFor="Usertype">User Type*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.Usertype}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.email && "1px solid #ff0000",
                            }}
                            name="email"
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            placeholder="Enter email"
                          />
                          <label>Email ID*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.email}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.name && "1px solid #ff0000",
                            }}
                            name="firstName"
                            value={form.name}
                            onChange={(e) => setField("name", e.target.value)}
                            placeholder="First Name*"
                          />
                          <label>First Name*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.name}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.lastname && "1px solid #ff0000",
                            }}
                            name="lastname"
                            value={form.lastname}
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            placeholder="Enter Last Name"
                          />
                          <label>Last Name*</label>
                          {errors.lastname && (
                            <small
                              className="validation"
                              style={{ float: "left" }}
                            >
                              {errors.lastname}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>{" "}
                    {(form.Usertype === "HC" ||
                      form.Usertype === "CM" ||
                      form.Usertype === "CS") && (
                      <div className="row mt-4 ">
                        <div className="col-lg-12">
                          <div className="">
                            <PhoneInput
                              inputClass={"form-control font-14 w-100"}
                              required="true"
                              placeholder="mobile"
                              id="homePhone"
                              country={"us"}
                              value={form.phone}
                              name="homePhone"
                              onChange={(value, country, e) => {
                                _onChangeText(value, country);
                                setForm({
                                  ...form,
                                  phone: value,
                                  phoneCode: country.dialCode,
                                });
                              }}
                            />
                          </div>
                          {!phoneValidation && (
                            <small className="validation">Invalid Phone</small>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMap setForm={setForm} form={form} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                            />
                            <label>Address</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control mr-2"
                              id="cityName"
                              style={{
                                border:
                                  errors.cityName &&
                                  form.cityName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="cityName"
                              value={form.cityName}
                              onChange={handleChange}
                              placeholder="Enter City"
                            />
                            <label htmlFor="cityName">City*</label>
                            {errors.cityName && form.cityName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.cityName}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="stateName"
                              style={{
                                border:
                                  errors.stateName &&
                                  form.stateName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="stateName"
                              value={form.stateName}
                              onChange={handleChange}
                              placeholder="Address State"
                            />
                            <label htmlFor="stateName">State*</label>
                            {errors.stateName && form.stateName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.stateName}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="CountryName"
                              style={{
                                border:
                                  errors.CountryName &&
                                  form.stateName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="CountryName"
                              value={form.CountryName}
                              onChange={handleChange}
                              placeholder="Address Country"
                            />
                            <label htmlFor="CountryName">Country*</label>
                            {errors.CountryName && form.stateName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.CountryName}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddUser;
