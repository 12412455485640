import React, { useState, useMemo } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import candidateExcel from "../../../../docs/Candidate_Template.xlsx";
// import Table from 'react-bootstrap/Table'
import {
  getUserTenant,
  getCandidateId,
  getCandidateName,
} from "../../../../service/AuthService";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../loader/Loader";
import buttonLoader from "../../../../images/button-loader.gif";
import FileUpload from "../../../common/fileupload/FileUpload";
import { BulkUploadExcelValidation } from "../validations/ExcelUpload";
import ToolTip from "../../../common/tooltip/toolTip";
import { getTalentList } from "../../../../action/talent/TalentList";
import { useDispatch } from "react-redux";

const ExcelUpload = (props) => {
  const dispatch = useDispatch();

  const {
    existingCandidateList,
    talentEmailsLoading,
    setSuccessTalentFormOpen,
    closeAddTalentModalPopUp,
  } = props;
  const [rows, setRows] = useState();
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [data, setData] = React.useState([]);
  const [errCount, setErrCount] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);

  const fileChange = (file) => {
    setErrCount(0);
    setFileName("");
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file.name)[1]; // "
    if (ext === "xlsx") {
      setFileName(file.name);
      setErrCount(0);
      ExcelRenderer(file, (err, res) => {
        if (err) {
          setUploaded(false);
        } else {
          res.rows.splice(0, 1);
          let tempcount = 0;
          const result = res.rows.map((eachrow, index) => {
            let err = 0;
            let errMessage = "";
            let errorInfo = "";
            const [
              firstName,
              lastName,
              email,
              homePhoneCode,
              homePhone,
              primarySkills,
              jobTitle,
              experienceYear,
              experienceMonth,
              city,
              state,
              country,
              preferredLocations,
            ] = eachrow;

            let validateRes = BulkUploadExcelValidation(eachrow);
            if (validateRes?.length > 0) {
              err = 1;
              errMessage = validateRes.includes(
                "Experience month should be between 0 and 11"
              )
                ? "Experience month should be between 0 and 11"
                : " Data Missing";
              errorInfo = validateRes;
              //setErrCount(tempcount + 1)
              tempcount += 1;
            } else {
              //errMessage ="Already Exists"
              let isExists = existingCandidateList.filter(
                (row) => row.email == email
              );
              if (isExists.length > 0) {
                errMessage = "Already Exists";
                err = 1;
                tempcount += 1;
              } else {
                errMessage = "Passed";
                err = 0;
              }
            }

            return {
              err,
              errMessage,
              errorInfo,
              firstName,
              lastName,
              email,
              homePhoneCode,
              homePhone,
              primarySkills,
              jobTitle,
              experienceYear,
              experienceMonth,
              city,
              state,
              country,
              preferredLocations,
            };
          });

          setErrCount(tempcount);
          setRows(result);
          setData(result);
          setUploaded(true);
        }
      });
    } else {
      toast.error("Please Upload .xlsx Files ");
    }
  };

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const submitAddTalent = async (e) => {
    if (errCount) return toast.error("Please upload again with correct data");
    setSubmitClicked(true);
    const usertenant = getUserTenant();
    const loggedinusername = getCandidateName();
    let today = new Date();
    const userid = getCandidateId();
    let responseData = data
      .filter((eachData) => eachData.err == 0)
      .map((eachData) => {
        const candidateID = Math.floor(Math.random() * 650000000) + 300000000;
        const result = {
          candidateID: `${candidateID}`,
          firstName: eachData.firstName,
          lastName: eachData.lastName,
          email: eachData.email.trim(),
          addressCity: eachData.city ? eachData.city : "",
          addressState: eachData.state ? eachData.state : "",
          country: eachData.country ? eachData.country : "",
          zipCode: eachData.zipCode ? eachData.zipCode.toString() : "",
          homePhoneCode: eachData.homePhoneCode
            ? `+${eachData.homePhoneCode.toString()}`
            : "",
          homePhone: eachData.homePhone ? eachData.homePhone.toString() : "",
          workPhone: "", // new field
          workPhoneCode: "", // new field
          facebook: "", // new field,
          twitter: "", // new field,
          linkedIn: "", // new field,
          preferredSalary: 0, // new field
          preferredSalaryCurrency: "USD/Year", // new field
          minContractRate: 0, // new field
          minContractRateCurrency: "USD/Year", // new field
          description: "", // new field
          positionTypes: "[]",
          base64: "",
          preferedLocations: eachData.preferredLocations
            ? [eachData.preferredLocations]
            : [],
          primarySkills:
            eachData.primarySkills &&
            eachData.primarySkills
              .split(",")
              .map((skill) => skill.toLowerCase().replace(/ /g, "")),
          secondarySkills: "[]",
          skillSet: "[]",
          source: "", // new field
          visaStatus: "", // new field
          designation: eachData.jobTitle ? eachData.jobTitle : "", // new field
          currentEmployer: "", // new field,
          experienceMonth: eachData.experienceMonth,
          experienceYear: eachData.experienceYear,
          experienceLevel: findRange(
            eachData.experienceYear,
            eachData.experienceMonth
          ),
          isFresher: false,
          isRemote: false,
          fileName: "",
          typeName: "tc",
          candidateStatus: "active",
          currentJobTitle: eachData.jobTitle ? eachData.jobTitle : "",
          createdDate: today,
          updatedDate: today,
          createdByTenant: usertenant,
          candidateTenant: usertenant,
          createdByName: loggedinusername,
          createdBy: `${userid}`,
          domainName: domain,
          fullText: {
            firstName: eachData.firstName,
            lastName: eachData.lastName,
            candidatePrimarySkills:
              eachData.primarySkills &&
              eachData.primarySkills
                .split(",")
                .map((skill) => skill.toLowerCase().replace(/ /g, "")),
            //candidateSecondarySkills: eachData.secondarySkills && eachData.secondarySkills.split(',').map(skill => skill.toLowerCase().replace(/ /g, '')),
            createdByTenant: usertenant,
            tenent: usertenant,
          },
        };

        return result;
      });
    // call save api

    const result = await post("/talent/bulkupload", responseData);

    if (result.status == 200) {
      if (result.data.message == "Email Already Exist") {
        toast.error(result.data.message);
        setSubmitClicked(false);
      } else if (result.data.message == "Uploaded Successfully") {
        toast.success("Talents added successfully");
        closeAddTalentModalPopUp();
        setSubmitClicked(false);
        let payload = {
          currentPage: 1,
          limit: 10,
          status: null,
          tab: "All",
          sortParam: [],
        };
        dispatch(getTalentList(payload));
      }
    } else {
      toast.error("Oops! Something went wrong");
    }
  };

  const uploadHandler = () => {
    setUploaded(true);
  };

  const toolTipData = (data) => {
    data.map(function (item, i) {
      return <li>{item}</li>;
    });
  };

  return (
    <>
      {talentEmailsLoading && <Loader />}
      <div>
        <div className="row">
          <div className="col-12">
            <div className="p-5 p-0-600">
              <h6>Step 1</h6>
              <p>
                <a href={candidateExcel} download className="btn">
                  Download the .xlxs template
                </a>
              </p>
              <h6>Step 2</h6>

              <FileUpload
                setFileUpload={fileChange}
                uploadData={{ fileName: fileName }}
                isFrom={"ExcelUploadAddTalent"}
              />

              <div className="col-sm-12">
                {uploaded ? (
                  <div>
                    <div className=" d-flex">
                      <div>
                        {" "}
                        <h6>Step 3</h6>{" "}
                        <p>Preview the uploaded result and add</p>
                      </div>
                      <div className="ml-auto">
                        {" "}
                        <h6>Activity </h6>{" "}
                        <p>
                          Total {data.length - errCount} of {data.length} valid
                          record found{" "}
                        </p>
                      </div>
                    </div>
                    <table class="table  ">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Primary Skills</th>
                          <th scope="col">Home Phone</th>
                          <th scope="col">Job Title</th>
                          <th scope="col">Total Experience</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((eachvalue, id) => (
                          <tr
                            className={
                              eachvalue.err == 0
                                ? "table-primary"
                                : "table-danger"
                            }
                            key={id}
                          >
                            <td>
                              {eachvalue.firstName} {eachvalue.lastName}{" "}
                            </td>

                            <td>{eachvalue.email}</td>
                            <td>{eachvalue.primarySkills}</td>
                            <td>{eachvalue.homePhone}</td>
                            <td>{eachvalue.jobTitle}</td>
                            <td>{`${eachvalue?.experienceYear * 1}.${
                              eachvalue?.experienceMonth * 1
                            } `}</td>
                            <td>
                              {eachvalue.errMessage}{" "}
                              <ToolTip
                                errorInfo={eachvalue.errorInfo}
                                id={id}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        disabled={submitClicked}
                        onClick={props.closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        disabled={data.length - errCount === 0}
                      >
                        {submitClicked === false && (
                          <span onClick={submitAddTalent}>Add</span>
                        )}
                        {submitClicked === true && (
                          <span v-if="submittingForm" disabled={submitClicked}>
                            Adding
                            <img width="20px" alt="" src={buttonLoader} />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelUpload;
