import React from "react";

const General = ({ details, generalDetails, onCollapsibleClick }) => {
  return (
    <>
      <button
        onClick={() => {
          onCollapsibleClick("generalDetails");
        }}
        type="button"
        className="btn btn-collapsible mb-2"
      >
        {!generalDetails ? (
          <i className="fal fa-angle-up fa-fw mt-1"></i>
        ) : (
          <i className="fal fa-angle-down fa-fw"></i>
        )}
        <span>
          <small>General</small>
        </span>
      </button>
      <div className={generalDetails ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <div class="d-flex mb-2 pl-1 ml-2">
          <div>
            <i className="fa fa-envelope"></i>
          </div>
          <div class="ml-2">
            <p class=" mb-0">{details.email}</p>
            <p class="mb-0">
              <small>Email</small>
            </p>
          </div>
        </div>
        {details.country && (
          <div class="d-flex mb-2 pl-1 ml-2">
            <div>
              <i className="fad fa-fw fa-map-marked-alt"></i>
            </div>
            <div class="ml-2">
              <p class=" mb-0">
                {details.city ? details.city : ""}
                {details.state
                  ? (details.city ? ", " : "") + details.state
                  : ""}
                {details.country
                  ? (details.city || details.state ? ", " : "") +
                    details.country
                  : ""}
              </p>
              <p class="mb-0">
                <small>User Location</small>
              </p>
            </div>
          </div>
        )}
        {details.phoneNumber && (
          <div class="d-flex mb-2 pl-1 ml-2">
            <div>
              <i class="fas fa-phone"></i>
            </div>
            <div class="ml-2">
              <p class="mb-0">
                {details.phoneCode
                  ? `${details.phoneCode} ${details.phoneNumber}`
                  : `+${details.phoneNumber}`}
              </p>
              <p class="mb-0">
                <small>Phone Number</small>
              </p>
            </div>
          </div>
        )}
        {details?.homePhone && (
          <div class="d-flex mb-2 pl-1 ml-2">
            <div>
              <i class="fas fa-phone"></i>
            </div>
            <div class="ml-2">
              <p class="mb-0">
                {details.homePhoneCode
                  ? `${details.homePhoneCode} ${details.homePhone}`
                  : `+${details.homePhone}`}
              </p>
              <p class="mb-0">
                <small>Home Phone</small>
              </p>
            </div>
          </div>
        )}
        {details.companyName && (
          <div class="d-flex mb-2 pl-1 ml-2">
            <div>
              <i class="fas fa-building"></i>
            </div>
            <div class="ml-2">
              <p class="mb-0">{details.companyName}</p>
              <p class="mb-0">
                <small>Company Name</small>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default General;
