import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import buttonLoader from "../../assets/images/button-loader.gif";
import { post } from "../../service/ApiService";
import Loader from "../common/loader/Loader";

const ResumeParsed = ({
  resumeFile,
  setResumeFile,
  parsed,
  setParsedData,
  setParsed,
}) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [hideResumeBtn, setHideResumeBtn] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      let doc = { fileName: "", base64: "" };
      reader.onload = (event) => {
        doc.fileName = name;
        doc.base64 = event.target.result;
        setResumeFile(doc);

        setHideResumeBtn(true);
        if (parsed) {
          toast.success("Your resume uploaded successfully");
        } else {
          uploadResume(doc);
        }
      };
    } else {
      toast.error("Invalid file type");
    }
  };
  const updateParsed = (data, value) => {
    setParsed(value);
    setParsedData(data);
  };
  const uploadResume = async (doc) => {
    setLoading(true);
    try {
      setSubmitClicked(true);
      // QA/Dev parsing Issue
      const payload = {
        filename: doc.fileName,
        base64: doc.base64,
      };
      const resultPy = await post("/talent/parseuploadresume", payload);
      if (resultPy.status == 200) {
        const modifiedData = {
          name: {
            raw: resultPy?.data?.name ?? "",
            first: resultPy?.data?.name
              ? resultPy?.data?.name?.split(" ")[0]
              : "",
            last: resultPy?.data?.name
              ? resultPy?.data?.name?.split(" ")[1]
              : "",
            middle: "",
            title: "",
          },
          phoneNumbers: [resultPy?.data?.phone ?? ""],
          websites: [],
          emails: [resultPy?.data?.email ?? ""],
          location: {
            formatted: resultPy?.data?.location?.address ?? "",
            postalCode: resultPy?.data?.location?.zipCode
              ? resultPy?.data?.location?.zipCode[0]
              : "",
            state: resultPy?.data?.location?.state ?? "",
            country: resultPy?.data?.location?.country ?? "",
            rawInput: "Sector 55, Noida, India",
            city: resultPy?.data?.location?.city ?? "",
          },
          education: resultPy?.data?.education
            ? resultPy?.data?.education?.map((x, i) => ({
                organization: x[i]?.school,
                accreditation: {
                  educationLevel: x[i]?.program,
                  education: x[i]?.major,
                },
              }))
            : [],
          profession: resultPy?.data?.designation ?? "",
          skills: resultPy?.data?.primary_skills
            ? resultPy?.data?.primary_skills?.map((x, i) => ({
                name: x,
                id: i,
              }))
            : [],
        };

        const finalresultPy = {
          ...modifiedData,
          ...resumeFile,
          h5h: true,
        };
        updateParsed(finalresultPy, true);
        toast.success("Resume uploaded successfully");
        setSubmitClicked(false);
        setLoading(false);
      } else {
        toast.error(
          "Something went wrong while uploading resume, please try again."
        );
        setHideResumeBtn(false);
        setResumeFile({ fileName: "", base64: "" });
        setSubmitClicked(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error(
        "Something went wrong while uploading resume, please try again."
      );
      setHideResumeBtn(false);
      setResumeFile({ fileName: "", base64: "" });
      setSubmitClicked(false);
      setLoading(false);
    }
  };
  const onRemove = (e) => {
    setResumeFile({ fileName: "", base64: "" });
    setHideResumeBtn(false);
    setShowConfirmation(false);
    toast.success("Resume deleted successfully ");
  };

  return (
    <div className="w-100">
      {loading && <Loader />}
      <div
        className={`d-flex d-block-768 w-100  ${
          resumeFile && resumeFile.fileName ? "" : ""
        }`}
      >
        {" "}
        {parsed && (
          <div className="">
            <h6 className={`my-auto mr-2 `}>
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={resumeFile?.fileName}
              >
                {resumeFile?.fileName?.length > 25
                  ? resumeFile?.fileName.slice(0, 25) + "..."
                  : resumeFile?.fileName}
              </span>
            </h6>
          </div>
        )}
        {parsed && resumeFile && resumeFile.fileName && (
          <div className="d-flex gap-1 mr-1 justify-content-left">
            <button
              type="button"
              className="btn btn-icon "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i class="fa fa-check-circle" style={{ color: "green" }}></i>
              </span>
            </button>

            <button
              type="button"
              className="btn btn-icon  "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i
                  class="fal fa-fw fa-trash-alt"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                ></i>
              </span>
            </button>
          </div>
        )}
        {/* {!parsed && (
          <h6 className="my-auto mr-2">Please upload your resume.</h6>
        )}{" "} */}
        <div className="">
          {submitClicked ? (
            <button type="button" class="btn btn-secondary btn-sm ">
              <span v-if="submittingForm">
                Uploading
                <img width="20px" alt="" src={buttonLoader} />
              </span>
            </button>
          ) : (
            <Dropzone
              onDrop={(file) => onDragDropChangeHandler(file)}
              multiple={false}
              disabled={resumeFile && resumeFile.fileName}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="">
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary ml-3"
                    disabled={hideResumeBtn}
                  >
                    <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                    Upload Resume*
                  </button>

                  <input
                    type="file"
                    {...getInputProps()}
                    accept=".docx, .pdf"
                  />
                </div>
              )}
            </Dropzone>
          )}
        </div>
      </div>
      <Modal show={showConfirmation} size="md" animation={true}>
        <Modal.Header>
          <button
            type="button"
            onClick={() => setShowConfirmation(false)}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="m-5">
            <div class="text-center">
              <div class="avatar avatar-lg avatar-red2">
                <i
                  class="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              <p class="text-center lead mt-3">
                Are you sure you want to delete resume?
                <br />
                {resumeFile.fileName}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div class="d-flex align-items-center justify-content-center">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemove} className="ml-2">
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResumeParsed;
