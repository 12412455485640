import {
  GET_CURATORS_BEGIN,
  GET_CURATORS_SUCCESS,
  GET_CURATORS_FAILURE,
  GET_CURATORS_DATA_COUNT_BEGIN,
  GET_CURATORS_DATA_COUNT_SUCCESS,
  GET_CURATORS_DATA_COUNT_FAILURE,
} from "../../action/curator/Curator";

const initialState = {
  loading: false,
  curatorDetailsLoading: false,
  curatorDetails: [],
  curatorDataCount: [],
  countLoading: false,
};

const getCuratorDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURATORS_BEGIN:
      return {
        ...state,
        curatorDetailsLoading: true,
        curatorDetails: [],
      };
    case GET_CURATORS_SUCCESS:
      return {
        ...state,
        curatorDetails: payload,
        curatorDetailsLoading: false,
      };
    case GET_CURATORS_FAILURE:
      return {
        ...state,
        curatorDetails: [],
        curatorDetailsLoading: false,
      };
    case GET_CURATORS_DATA_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        curatorDataCount: [],
      };
    case GET_CURATORS_DATA_COUNT_SUCCESS:
      return {
        ...state,
        curatorDataCount: payload,
        countLoading: false,
      };
    case GET_CURATORS_DATA_COUNT_FAILURE:
      return {
        ...state,
        curatorDataCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getCuratorDetailsReducer;
