import React from "react";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import Location from "./Location";
import AdditionalQuestions from "./AdditionalInfo";

const ApplyPageProfile = ({
  formData,
  setFormData,
  setPhoneValidation,
  errorData,
  job,
  screeningQuestions,
  setScreeningQuestions,
  salaryValues,
  setSalaryValues,
}) => {
  const experienceLevel = [
    "Intern",
    "Less than one year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];
  const _onChangeText = (value, code, name) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      const isValid = asYouType.isValid();
      setPhoneValidation(isValid);
    } else {
      setPhoneValidation(true);
    }
  };
  const handlePhoneChange = (value, country) => {
    const mobile = value.replace(country.dialCode, "");
    let tempData = { ...formData };
    tempData["mobile"] = mobile;
    tempData["mobilePhoneCode"] = `+${country.dialCode}`;
    setFormData(tempData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let tempData = { ...formData };
    tempData[name] = value;
    setFormData(tempData);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h6>Contact Information</h6>
        <hr />
        <div className="row mb-4">
          <div className="col-md-4">
            <label>First Name*</label>
            <div className="form-floating">
              <input
                type="text"
                className={
                  errorData["firstName"] && formData.firstName == ""
                    ? "form-control border border-danger w-100"
                    : "form-control"
                }
                id="firstName"
                name="firstName"
                onChange={(e) => handleChange(e)}
                value={formData?.firstName}
              />
            </div>
            {errorData["firstName"] && formData.firstName == "" && (
              <div className="validation">{errorData["firstName"]}</div>
            )}
          </div>
          <div className="col-md-4">
            <label>Last Name*</label>
            <div className="form-floating">
              <input
                type="text"
                className={
                  errorData["lastName"] && formData.lastName == ""
                    ? "form-control border border-danger w-100"
                    : "form-control"
                }
                id="lastName"
                name="lastName"
                onChange={(e) => handleChange(e)}
                value={formData?.lastName}
              />
            </div>
            {errorData["lastName"] && formData.lastName == "" && (
              <div className="validation">{errorData["lastName"]}</div>
            )}
          </div>{" "}
          <div className="col-md-4 ">
            <label>Email*</label>
            <div className="form-floating ">
              <input
                type="text"
                className={
                  errorData["email"] && formData.email == ""
                    ? "form-control border border-danger w-100"
                    : "form-control"
                }
                id="emailId"
                name="email"
                onChange={(e) => handleChange(e)}
                value={formData?.email}
              />
            </div>
            {errorData["email"] && formData.email == "" && (
              <div className="validation">{errorData["email"]}</div>
            )}
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-md-4">
            <label>Phone Number</label>
            <div className="form-floating" style={{ zIndex: "999" }}>
              <PhoneInput
                value={
                  formData?.mobilePhoneCode
                    ? `${formData?.mobilePhoneCode}${formData?.mobile}`
                    : formData?.mobile
                }
                name="mobile"
                country={"us"}
                inputStyle={{ height: "57px" }}
                onChange={(value, country) => {
                  _onChangeText(
                    value.replace(country.dialCode, ""),
                    country.countryCode.toUpperCase(),
                    "mobile"
                  );
                  handlePhoneChange(value, country);
                }}
                placeholder="Phone Number"
                inputClass={
                  "form-control w-100"
                }
                specialLabel="Phone Number"
                specialLabelStyle={{ display: "block" }}
                enableSearch={true}
              />
            </div>
          </div>

          <div className="col-md-4">
            <label>Experience Level*</label>
            <div className="form-floating">
              <select
                className={
                  errorData["experienceLevel"] && formData.experienceLevel == ""
                    ? "form-control border border-danger w-100"
                    : "font-14 form-select"
                }
                name="experienceLevel"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select</option>
                {experienceLevel.map((e, i) => (
                  <option
                    key={i}
                    value={e}
                  >
                    {e}
                  </option>
                ))}
              </select>
            </div>
            {errorData["experienceLevel"] && formData.experienceLevel == "" && (
              <div className="validation">{errorData["experienceLevel"]}</div>
            )}
          </div>

          <div className="col-md-4">
            <Location
              formData={formData}
              setFormData={setFormData}
              errorData={errorData}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-md-4 ">
            <label>Linkedin Profile URL</label>
            <div className="form-floating">
              <input type="text" className="form-control" id="lastName" />
            </div>
          </div>{" "}
        </div>
        {/* Additional questions */}
        {Object.keys(job).length > 0 && job?.additionalInfo && (
          <AdditionalQuestions
            job={job}
            screeningQuestions={screeningQuestions}
            setScreeningQuestions={setScreeningQuestions}
            screeningData={job?.additionalInfo}
            errorData={errorData}
            salaryValues={salaryValues}
            setSalaryValues={setSalaryValues}
          />
        )}
      </div>
    </div>
  );
};

export default ApplyPageProfile;
