import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../loader/Loader";
import Event from "../../../../../../googleanalytic/Tracking";
import Login from "./Login";
import ApplyJob from "./ApplyJob";
import {
  candidateAuditLog,
  candidateAuditLogAsync,
  insertTalent,
  disposition,
  automatch,
  InviteTalentWithVet,
  login,
  sendSmsforSubmission,
} from "./utils/ApplyJobFunctions";

const Index = ({
  job,
  setModalSize,
  setApply,
  loggedIn,
  isFrom,
  loginData,
  component,
  socialMediaApply,
  setSocialMediaApply,
}) => {
  const { tenant, recruiterid } = useParams();
  const [createdBy, setCreatedBy] = useState("");
  const [ip, setIp] = useState("");
  const [tenantType, setTenantType] = useState();
  const [tenantId, setTenantId] = useState();
  const [radiusValue, setRadiusValue] = useState(1000);

  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);
  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);
  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const history = useHistory();
  const data = {
    longitude: "",
    latitude: "",
    firstName: isFrom == "socialMediaApply" ? loginData?.firstName : "",
    lastName: isFrom == "socialMediaApply" ? loginData?.lastName : "",
    email: isFrom == "socialMediaApply" ? loginData?.email : "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    homePhoneCode: "",
    homePhone: "",
    phoneValidation: true,
    workPhone: "",
    workPhoneCode: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: 0,
    preferredSalaryCurrency: "",
    minContractRate: 0,
    minContractRateCurrency: "",
    description: "",
    positionTypes: [],
    preferredLocation: [],
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    subCategory: [],
    source: "",
    visaStatus: "",
    designation: "",
    currentEmployer: "",
    experienceMonth: "",
    experienceYear: "",
    experienceLevel: "",
    isFresher: false,
    isRemote: false,
    isHybrid: false,
    isOnsite: false,
    legallyAuthorized: false,
    requireSponsorship: false,
    fileName: "",
    filePath: "",
    experiences: [],
    educations: [],
    certifications: [],
    licenses: [],
    skillMatrix: [],
    highestEducation: "Select",
    base64: "",
    newsLetter: "",
    location: {},
  };
  const [candidateStatus, setCandidateStatus] = useState({});
  const [candidateDetails, setCandidateDetails] = useState(data);
  const [candidateData, setCandidateData] = useState(data);
  const [newsLetterStatus, setNewsLetterStatus] = useState("");
  const [email, setEmail] = useState(
    isFrom == "socialMediaApply" ? loginData?.email : ""
  );
  const [emailExist, setEmailExist] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [isScreening, setIsScreening] = useState(false);
  const [screeningAnswers, setScreeningAnswers] = useState([]);
  const [isVetting, setIsVetting] = useState(false);
  const [vettingNow, setVettingNow] = useState(false);
  const [applyNewTalent, setApplyNewTalent] = useState(
    isFrom == "socialMediaApply" ? false : true
  );
  const [applyWithNewTalent, setApplyWithNewTalent] = useState(
    isFrom == "socialMediaApply" ? true : false
  );
  const [editTalent, setEditTalent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCandidateStatus, setLoadingCandidateStatus] = useState(false);
  const [addTalent, setAddTalent] = useState(false);

  const [trackedData, setTrackedData] = useState({
    jobId: job.jobId,
    poolId: job.poolId,
    recruiterId: recruiterid,
    tenantId: tenant,
    userType: "Candidate",
    navigatedPages: window.location.href, //+ "apply" + "screening" + "success",
    action: "applyJob",
    automatched: false,
    firstName: "",
    lastName: "",
    emailId: sessionStorage.getItem("userName"),
    data: { ...candidateDetails, resumeFileName: "" },
  });
  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [weightageLocation] = useState(
    job?.weightage ? JSON.parse(job?.weightage).location : false
  );

  useEffect(() => {
    if (loggedIn) setApplyNewTalent(false);
    // getCreatedBy();
  }, []);

  useEffect(() => {
    if (resumeFile?.fileName) {
      candidateAuditLog(trackedData, setTrackedData, job, candidateDetails);
    }
  }, [resumeFile]);

  useEffect(() => {
    const fun = async () => {
      if (addTalent) {
        addTalentFunction();
      }
    };
    fun();
  }, [addTalent]);

  useEffect(() => {
    const fun = async () => {
      if (loggedIn) {
        setLoading(true);
        let email = { email: sessionStorage.getItem("userName") };
        await post("/candidate/getcandidatebyemail", email).then((data) => {
          setCandidateDetails(data?.data);
          setCandidateData(data?.data);
          setLoading(false);
        });
      }
    };
    fun();
  }, []);

  useEffect(() => {
    if (editTalent) {
      let edu =
        candidateStatus?.educations !== null
          ? JSON.parse(candidateStatus.educations)
          : educations;
      let eduval = edu.map((e) => educationVal);
      let exp =
        candidateStatus?.experiences !== null
          ? JSON.parse(candidateStatus.experiences)
          : experiences;
      let expval = exp.map((e) => experienceVal);
      setCandidateDetails({
        ...candidateStatus,
        positionTypes:
          candidateStatus?.positionTypes !== null
            ? JSON.parse(candidateStatus.positionTypes)
            : [],
        primarySkills:
          candidateStatus?.primarySkills !== null
            ? JSON.parse(candidateStatus?.primarySkills)
            : [],
        preferredLocation:
          candidateStatus?.preferredLocation !== null
            ? JSON.parse(candidateStatus?.preferredLocation)
            : [],
        secondarySkills:
          candidateStatus?.secondarySkills !== null
            ? JSON.parse(candidateStatus.secondarySkills)
            : [],
        skillSet:
          candidateStatus?.skillSet !== null
            ? JSON.parse(candidateStatus.skillSet)
            : [],
        experiences:
          candidateStatus?.experiences !== null
            ? JSON.parse(candidateStatus.experiences)
            : [],
        educations:
          candidateStatus?.educations !== null
            ? JSON.parse(candidateStatus.educations)
            : [],
        certifications:
          candidateStatus?.certifications !== null
            ? JSON.parse(candidateStatus.certifications)
            : [],
        licenses:
          candidateStatus?.licenses !== null
            ? JSON.parse(candidateStatus.licenses)
            : [],
        location:
          candidateStatus?.location !== null
            ? JSON.parse(candidateStatus.location)
            : {},

        preferredSalary:
          candidateStatus?.preferredSalary !== null
            ? candidateStatus?.preferredSalary
            : 0,

        preferredSalaryCurrency:
          candidateStatus?.preferredSalaryCurrency !== null
            ? candidateStatus?.preferredSalaryCurrency
            : "USD",

        minContractRate:
          candidateStatus?.minContractRate !== null
            ? candidateStatus?.minContractRate
            : 0,
        minContractRateCurrency:
          candidateStatus?.minContractRateCurrency !== null
            ? candidateStatus?.minContractRateCurrency
            : "USD",
        salaryType:
          candidateStatus?.salaryType !== null
            ? candidateStatus?.salaryType
            : "Hourly",
      });

      setEducations(edu);
      setEducationVal(eduval);
      setExperiences(exp);
      setExperienceVal(expval);
    }
  }, [editTalent]);

  useEffect(() => {
    getIpData();
  }, []);

  useEffect(() => {
    (isFrom == "socialMediaApply" ||
      sessionStorage.getItem("token") !== null) &&
      getCandidateStatus();
  }, []);

  let getCreatedBy = async () => {
    try {
      await post("/msgtwoway/getrecruiterId", {
        recruiterId: recruiterid,
      }).then((res) => {
        let id = Number(res.data[0]?.FK_useraccessdetails);
        setCreatedBy(id);
        setTenantType(res.data[0]?.fk_tenanttype);
        setTenantId(res?.data[0]?.id_tenant);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addTalentFunction = async () => {
    setLoading(true);
    const talentData = { ...candidateDetails };

    if (newsLetterStatus === "Unsubscribed") {
      await post("/disposition/applyjob/updatesubscriptionstatus", {
        email: talentData.email,
      });
    }

    try {
      let response = await insertTalent(
        talentData,
        candidateStatus,
        job,
        candidateData,
        recruiterid,
        candidateDetails,
        data,
        resumeFile,
        weightageLocation,
        radiusValue
      );
      if (response.status === 200) {
        let res = await disposition(
          talentData,
          candidateStatus,
          job,
          recruiterid,
          weightageLocation,
          tenant,
          screeningAnswers,
          ip,
          radiusValue,
          response
        );
        if (res.status === 200) {
          let candidateId =
            candidateStatus.candidateStatus === "New Talent"
              ? response.data.candidateId
              : candidateStatus.userId;
          Event(
            "Job Appiled",
            `${talentData.firstName} ${talentData.lastName},${job.jobTitle}`,
            window.location.pathname
          );
          setApply(false);
          toast.success("Job applied successfully");
          automatch(talentData, candidateId, response, tenantType, tenantId);
          if (job?.vettingRequired) {
            if (weightageLocation) {
              if (job?.isRadius && radiusValue <= job?.radiusValue) {
                try {
                  InviteTalentWithVet(
                    talentData,
                    job,
                    recruiterid,
                    candidateId,
                    tenant
                  );
                } catch (err) {
                  setLoading(false);
                }
              }
            } else {
              try {
                InviteTalentWithVet(
                  talentData,
                  job,
                  recruiterid,
                  candidateId,
                  tenant
                );
              } catch (err) {
                setLoading(false);
              }
            }
          }
          await candidateAuditLogAsync(
            trackedData,
            setTrackedData,
            job,
            candidateDetails,
            resumeFile
          );

          if (sessionStorage.getItem("token") == null) {
            try {
              let result = await login(talentData, socialMediaApply, response);
              sessionStorage.setItem("token", result.data.token);
              sessionStorage.setItem("name", result.data.name);
              sessionStorage.setItem("userId", result.data.userId);
              sessionStorage.setItem("userName", result.data.userName);
              setEmailExist(false);
              setTimeout(() => {
                setLoading(false);
                history.push(`/career/home`);
              }, 5000);
            } catch (err) {
              setLoading(false);
            }
          } else {
            setTimeout(() => {
              history.push(`/career/home`);
            }, 5000);
          }
          await sendSmsforSubmission(
            candidateDetails,
            job,
            response.data.password,
            createdBy
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Something went wrong, please try again ");
      }
    } catch {
      setLoading(false);
      toast.error("Something went wrong, please try again ");
    }
  };

  const getCandidateStatus = async () => {
    const params = {
      jobId: job?.jobId,
      email:
        sessionStorage.getItem("token") !== null
          ? sessionStorage.getItem("userName")
          : email?.trim(),
      recruiterId: recruiterid,
    };
    setLoadingCandidateStatus(true);
    const url = component == "/applyjob/isemailexist";
    let res = await post(url, params);
    setCandidateStatus(res.data);
    if (res.data.candidateStatus === "Login Talent") {
      setEditTalent(true);
    }
    if (res.data.message === "Already applied for this job ") {
      toast.success("Already applied for this job");
      setApply(false);
      setSocialMediaApply(false);
    }
    setLoadingCandidateStatus(false);
  };

  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };
  
  return (
    <>
      {(loading || loadingCandidateStatus) && <Loader />}
      {applyNewTalent && (
        <Login
          job={job}
          email={email}
          setEmail={setEmail}
          emailExist={emailExist}
          setEmailExist={setEmailExist}
          setApplyNewTalent={setApplyNewTalent}
          setModalSize={setModalSize}
          setEditTalent={setEditTalent}
          setApplyWithNewTalent={setApplyWithNewTalent}
          setCandidateDetails={setCandidateDetails}
          candidateDetails={candidateDetails}
          setCandidateStatus={setCandidateStatus}
          setTrackedData={setTrackedData}
          trackedData={trackedData}
          setNewsLetterStatus={setNewsLetterStatus}
        />
      )}
      {(!applyNewTalent || loggedIn || editTalent) && (
        <ApplyJob
          job={job}
          setModalSize={setModalSize}
          parsed={parsed}
          parsedData={parsedData}
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
          resumeFile={resumeFile}
          setResumeFile={setResumeFile}
          setTrackedData={setTrackedData}
          trackedData={trackedData}
          isFrom={isFrom}
          isScreening={isScreening}
          setIsScreening={setIsScreening}
          setScreeningAnswers={setScreeningAnswers}
          setIsVetting={setIsVetting}
          setAddTalent={setAddTalent}
          vettingNow={vettingNow}
          setVettingNow={setVettingNow}
          setApply={setApply}
          loadding={loading}
          setRadiusValue={setRadiusValue}
          setSocialMediaApply={setSocialMediaApply}
          editTalent={editTalent}
          educations={educations}
          setEducations={setEducations}
          educationVal={educationVal}
          setEducationVal={setEducationVal}
          experiences={experiences}
          setExperiences={setExperiences}
          experienceVal={experienceVal}
          setExperienceVal={setExperienceVal}
        />
      )}
    </>
  );
};

export default Index;
