import React, { useState } from "react";
import { toast } from "react-toastify";

import moment from "moment";
import { ButtonLoader, monthNames } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";

const Certifications = ({ data, updateDetails, setUpdateDetails }) => {
  var today = new Date();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [certifications, setCertifications] = useState(
    data?.certifications == null ? [] : JSON.parse(data?.certifications)
  );
  const [tempCertifications, setTempCertifications] = useState(
    data && data.certifications ? JSON.parse(data?.certifications) : []
  );

  const addCertifications = () => {
    let ex = [...certifications];
    let addCondition = true;

    if (true) {
      ex.push({
        certificationName: "",
        certificationId: "",
        issueDate: "",
        expiryDate: "",

        isSelect: false,
      });
      setCertifications(ex);
    }
  };

  const postExperienceInfo = async () => {
    setTempCertifications(certifications);
    let emptyFieldsCondition = true;
    let dateCondition = true;

    certifications?.forEach((e) => {
      if (e.isSelect) {
        if (
          !e.certificationName ||
          !e.certificationId ||
          !e.issueDate ||
          !e.expiryDate
        ) {
          emptyFieldsCondition = false;
          return false;
        }
      } else {
        if (
          !e.certificationName ||
          !e.certificationId ||
          !e.issueDate ||
          !e.expiryDate
        ) {
          emptyFieldsCondition = false;
          return false;
        }
      }
    });

    certifications?.forEach((e) => {
      let from = new Date(e.startDate);
      let to = new Date(e.endDate);
      if (!e.isSelect && from > to) {
        dateCondition = false;
        return false;
      }
    });

    if (!emptyFieldsCondition) {
      toast.error("Fields Marked with *(Asterisk) are mandatory");
    } else if (!dateCondition) {
      toast.error(`'Duration From' should not be greater than 'Duration To'`);
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        console.log(certifications);
        const parameters = {
          ...data,
          // zipcode: data.zipcode,
          // educations: data.educations,
          certifications: JSON.stringify(certifications),
        };
        // console.log('Parameters', parameters)
        const res = await post("/talent/updatetalentprofile", parameters);
        // console.log(res)
        if (res.status === 200) {
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          // getData();
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const deleteExperience = (index) => {
    let ex = [...certifications];
    ex.splice(index, 1);
    setCertifications(ex);
  };

  const editExperience = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelExperience = () => {
    setCertifications(
      data?.certifications == null ? [] : JSON.parse(data?.certifications)
    );
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const handleChange = (name, value, index, issueDate) => {
    let ex = [...certifications];
    if (name === "expiryDate" && value < issueDate) {
      toast.error("Expiry Date Cannot Be Less Than Issue Date");
      return;
    }
    if (name === "isSelect" && value) {
      ex = certifications.map((e, i) => {
        return index === i
          ? { ...e, isSelect: true }
          : { ...e, isSelect: false };
      });
    } else {
      ex[index] = { ...ex[index], [name]: value };
    }
    setCertifications(ex);
  };

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Certification</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editExperience}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelExperience}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={postExperienceInfo}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              {readOnlyMode && certifications[0]?.certificationName == "" ? (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-comment-slash"></i>
                    </div>
                    <div className="mt-2">No Record Found</div>
                  </div>
                </div>
              ) : (
                certifications?.map((e) => (
                  <div class="d-flex">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i
                        class="fad fa-fw fa-briefcase mt-1"
                        aria-hidden="true"
                      ></i>
                      <div class="view-panel-spacer mt-1"></div>
                    </div>
                    <div class="pb-3">
                      <p class="mb-1">
                        <strong>
                          {e.certificationName}
                          {/* <span class="font-regular ml-1">
                          {e.industry ? `(${e.industry})` : null}
                        </span> */}
                        </strong>
                      </p>
                      <p class="mb-0">
                        <small>
                          <strong>{e.jobTitle}</strong>
                        </small>
                      </p>
                      <p>
                        <small>
                          From:{" "}
                          {monthNames[new Date(e.issueDate).getMonth()] +
                            " " +
                            new Date(e.issueDate).getFullYear()}
                          {!e.isSelect
                            ? `   To: ${
                                monthNames[new Date(e.expiryDate).getMonth()]
                              } ${new Date(e.expiryDate).getFullYear()}`
                            : null}
                        </small>
                      </p>
                      <p>{e.description}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div class="work-experience">
              {certifications?.map((e, j) => (
                <div class="d-flex">
                  <div class="mr-3">
                    <div class="avatar avatar-sm">{j + 1}</div>
                  </div>
                  <div className="col-10">
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="certificationName"
                            placeholder="Certification Name"
                            value={e.certificationName}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                          <label>Certification Name*</label>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <div class="form-floating w-100">
                          <input
                            type="text"
                            class="form-control"
                            name="certificationId"
                            placeholder="Certification Name"
                            value={e.certificationId}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                          <label>Certification id*</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      {/* <div class="col-lg-12 mb-2">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="jobTitle"
                            value={e.jobTitle}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                            placeholder="Designation"
                          />
                          <label>Designation*</label>
                        </div>
                      </div> */}
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Issue Date*</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="Start Date"
                            name="issueDate"
                            value={e.issueDate}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Expiry Date {e.isSelect ? null : "*"}</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="End Date"
                            name="expiryDate"
                            value={e.expiryDate}
                            //max={moment().format("YYYY-MM-DD")}
                            disabled={e.isSelect}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j,
                                e.issueDate
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div class="row">
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Experience Description"
                            value={e.description}
                            name="description"
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          ></textarea>
                          <label>Experience Description</label>
                        </div>
                      </div>
                    </div> */}
                    <div class="row mt-2">
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="ml-3 col-1">
                    <button
                      type="button"
                      class="btn btn-icon"
                      onClick={() => deleteExperience(j)}
                    >
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              ))}
              <div className="ml-5">
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addCertifications}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Certification</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Certifications;
