import React, { useState } from "react";

const JobDetailsTitle = ({ jobData }) => {
  const [countryClick, setCountryClick] = useState(false);

  return (
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="text-center mb-4 font-white text-capitalize">
          <h4>{jobData.jobTitle}</h4>
          <p>
            {jobData?.jobType ? jobData?.jobType + "/" : ""}
            {jobData?.city &&
            jobData?.state &&
            jobData?.country &&
            !jobData?.isRemote ? (
              <>
                <span>{` ${jobData?.city}, ${jobData?.state}, ${jobData?.country} `}</span>
                <br />
                <p className="mt-1">
                  Placement Fee:{" "}
                  {jobData?.isFeePercentage
                    ? `${jobData?.placementFee}%`
                    : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: jobData?.placementCurrency
                          ? `${jobData?.placementCurrency}`
                          : "USD",
                        maximumSignificantDigits: 3,
                      }).format(jobData?.placementFee)}
                </p>
              </>
            ) : (
              <>
                {!countryClick ? (
                  <span
                    onClick={() => setCountryClick(true)}
                    style={{ cursor: "pointer" }}
                  >
                    {jobData.isRemote
                      ? `Remote 
                    (${
                      jobData?.isGlobal
                        ? "Global"
                        : jobData?.preferredLocation &&
                          JSON.parse(jobData.preferredLocation)?.length > 1
                        ? `${JSON.parse(jobData?.preferredLocation)[0]}...`
                        : JSON.parse(jobData.preferredLocation)
                        ? `${JSON.parse(jobData?.preferredLocation)[0]}`
                        : ""
                    })`
                      : ""}
                  </span>
                ) : (
                  <span
                    onClick={() => setCountryClick(false)}
                    style={{ cursor: "pointer" }}
                  >
                    {jobData.isRemote
                      ? `Remote 
                    (${
                      jobData?.isGlobal
                        ? "Global"
                        : jobData?.preferredLocation &&
                          JSON.parse(jobData.preferredLocation)?.join(", ")
                    })`
                      : ""}
                  </span>
                )}
                <p>
                  Placement Fee:{" "}
                  {jobData.isFeePercentage
                    ? `${jobData.placementFee}%`
                    : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: jobData.placementCurrency
                          ? `${jobData.placementCurrency}`
                          : "USD",
                        maximumSignificantDigits: 3,
                      }).format(jobData.placementFee)}
                </p>
              </>
            )}
          </p>

          <span class="tag tag-lg tag-font_accent d-inline-flex text-capitalize">
            <span class="mx-2">
              {jobData.jobStatusName == "Expired"
                ? "Halted"
                : jobData.jobStatusName == "Deactivated"
                ? "Closed"
                : jobData.jobStatusName}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsTitle;
