import {
  GET_TAG_FILTER_BEGIN,
  GET_TAG_FILTER_SUCCESS,
  GET_TAG_FILTER_FAILURE,
} from "../../../action/filter/talent/TalentTagFilter";

const initialState = {
  tagLoading: false,
  tagData: null,
};

const getTalentTagReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TAG_FILTER_BEGIN:
      return {
        ...state,
        tagLoading: true,
        tagData: null,
      };
    case GET_TAG_FILTER_SUCCESS:
      return {
        ...state,
        tagData: payload,
        tagLoading: false,
      };
    case GET_TAG_FILTER_FAILURE:
      return {
        ...state,
        tagData: payload,
        tagLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentTagReducer;
