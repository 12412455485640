import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import {
  BsCheckCircleFill,
  BsFillPauseCircleFill,
  BsEye,
} from "react-icons/bs";

import SideNavBar from "../../layout/SideNavbar";
import TopNavBar from "../../layout/TopNavbar";
import Footer from "../../layout/Footer";
import Loader from "../../common/loader/Loader";
import {
  getEmailData,
  activateEmailTemplate,
} from "../../../action/adminconsole/EmailTemplate";
import Pagination from "../../common/pagination/Pagination";
import NoRecord from "../../common/NoRecord";
import EmailTemplateModalView from "./EmailTemplateModalView";
import { getTenantType, getUserId } from "../../../service/AuthService";

function EmailTemplate() {
  const dispatch = useDispatch();
  const [emailFullView, setEmailFullView] = useState(false);
  const [selectedEmailData, setSelectedEmailData] = useState({});
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempData, setTempData] = useState([]);
  const [emailEvents, setEmailEvents] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [activateTemp, setActivateTemp] = useState({
    id: "",
    status: "",
  });

  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(20);

  const [createNew, setCreateNew] = useState(false);

  const { emailLoading, emailData, emailUpdated } = useSelector(
    (state) => ({
      emailLoading: state.emailTemplateReducer.emailLoading,
      emailData: state.emailTemplateReducer.emailData,
      emailUpdated: state.emailTemplateReducer.emailUpdated,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEmailData());
  }, []);
  useEffect(() => {
    dispatch(getEmailData());
  }, [emailUpdated]);
  useEffect(() => {
    setTempData(emailData);
    const removeDuplicate = (arr) => {
      const uniqueArray = [...new Set(arr)];

      const sortedArray = uniqueArray.sort((a, b) => a - b);
      return sortedArray;
    };
    const groupFilters = () => {
      if (emailData && emailData.length > 0) {
        let recipients = [];
        let events = [];
        let senderEmail = [];
        let frequency = [];
        emailData?.map((item) => {
          recipients.push(item?.recipients[0]);
          events.push(item?.events);
          senderEmail.push(item?.senderMail);
          frequency.push(item?.emailFrequency);
        });
        recipients = removeDuplicate(recipients);
        events = removeDuplicate(events);
        senderEmail = removeDuplicate(senderEmail);
        frequency = removeDuplicate(frequency);
      }
    };
    const groupEmailTemplates = () => {
      const sortedData = emailData
        .slice()
        .sort((a, b) => a.recipients[0].localeCompare(b.recipients[0]));
      const startIndex = (currentPage - 1) * limit;
      const slicedData = sortedData.slice(startIndex, startIndex + limit);
      const groupedData = {};

      slicedData.forEach((template) => {
        const recipients = template.recipients || [];

        if (recipients.length > 0) {
          const recipientKey = recipients[0];
          if (!groupedData[recipientKey]) {
            groupedData[recipientKey] = [];
          }

          groupedData[recipientKey].push(template);
        }
      });
      for (const key in groupedData) {
        if (groupedData.hasOwnProperty(key)) {
          groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
        }
      }

      setEmailEvents(groupedData);
    };
    groupEmailTemplates();
    groupFilters();

    let latestUpdate;

    if (
      emailData &&
      emailData.length > 0 &&
      selectedEmailData &&
      Object.keys(selectedEmailData).length > 0
    ) {
      latestUpdate = emailData.find(
        (item) => item.id_emailTemplate === selectedEmailData.id_emailTemplate
      );
    }

    if (latestUpdate) {
      setSelectedEmailData(latestUpdate);
    }
  }, [emailData]);
  useEffect(() => {
    setLoading(true);
    const sortedData = tempData.sort((a, b) =>
      a.recipients[0].localeCompare(b.recipients[0])
    );
    const startIndex = (currentPage - 1) * limit;
    const slicedData = sortedData.slice(startIndex, startIndex + limit);

    const groupedData = {};

    slicedData.forEach((template) => {
      const recipients = template.recipients || [];

      if (recipients.length > 0) {
        const recipientKey = recipients[0];
        if (!groupedData[recipientKey]) {
          groupedData[recipientKey] = [];
        }

        groupedData[recipientKey].push(template);
      }
    });
    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
      }
    }
    setEmailEvents(groupedData);
    setLoading(false);
  }, [tempData, currentPage, limit]);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const handleClearInput = () => {
    setCurrentPage(1);
    setSearchValue("");
    setTempData(emailData);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(parseInt(event.target.value));
  };
  const sortData = (data, sortOptions) => {
    const sortedData = data.slice().sort((a, b) => {
      let result = 0;

      for (const sortOption of sortOptions) {
        const { sortValue, order } = sortOption;

        switch (sortValue) {
          // case "Recipients":
          //   result = a.recipients[0].localeCompare(b.recipients[0]);
          //   break;
          case "events":
            result =
              order === "asc"
                ? a.events.localeCompare(b.events)
                : b.events.localeCompare(a.events);
            break;
          case "senderEmail":
            result =
              order === "asc"
                ? a.senderMail.localeCompare(b.senderMail)
                : b.senderMail.localeCompare(a.senderMail);
            break;
          case "emailFrequency":
            result =
              order === "asc"
                ? a.emailFrequency.localeCompare(b.emailFrequency)
                : b.emailFrequency.localeCompare(a.emailFrequency);
            break;
          case "updatedDateTime":
            result =
              order === "asc"
                ? new Date(a.updatedDateTime).getTime() -
                  new Date(b.updatedDateTime).getTime()
                : new Date(b.updatedDateTime).getTime() -
                  new Date(a.updatedDateTime).getTime();
            break;
          default:
            break;
        }

        if (result !== 0) {
          break;
        }
      }
      return result;
    });

    return sortedData;
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    let filteredData = tempData;
    setSearchValue(e.target.value);
    const searchTermLower = e.target.value.toLowerCase();
    filteredData = emailData.filter((template) => {
      const recipientsMatch = template.recipients.some((recipient) =>
        recipient.toLowerCase().includes(searchTermLower)
      );
      const eventsMatch = template.events
        .toLowerCase()
        .includes(searchTermLower);
      return recipientsMatch || eventsMatch;
    });
    setTempData(filteredData);
  };
  const handleConfirmModalShow = (status, id) => {
    console.log("@431 :", status, id);
    if (status === "Active") {
      setActivateTemp({
        id: id,
        status: "Paused",
      });
      setConfirmShow(true);
    } else {
      setActivateTemp({
        id: id,
        status: "Active",
      });
      setConfirmShow(true);
    }
  };
  const handleActivate = () => {
    let data = {
      id: activateTemp.id,
      status: activateTemp.status,
      updatedBy: getUserId(),
    };
    dispatch(activateEmailTemplate(data));
    setConfirmShow(false);
  };
  return (
    <>
      {emailLoading && <Loader />}
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 px-5">
                  <div className="mt-3">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-4 col-sm-12">
                        <h5 className="text-left">
                          Email Templates ({`${tempData?.length}`})
                        </h5>
                      </div>

                      <div className="col-md-2"></div>
                      <div className="col-md-3 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                        <form className="d-flex w-100">
                          <div
                            className="input-group"
                            style={{ border: "0.5px solid #ddd" }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by Recipient or Template"
                              aria-label="Search"
                              value={searchValue}
                              style={{ fontSize: "14px" }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>

                      <div className="col-md-2 col-sm-12 d-flex justify-content-md-end align-items-center mb-2">
                        <div className="d-flex align-items-center text-left text-nowrap me-2">
                          <span>Show Records:</span>
                          <select
                            className="form-select form-select-sm mx-2"
                            value={limit}
                            onChange={handleLimitChange}
                            style={{ width: "80px" }}
                          >
                            <option value={20}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={emailData && emailData?.length}>
                              All
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  {Object.keys(emailEvents).length > 0 ? (
                    <>
                      <div className="col mt-3 ">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Recipient Persona
                                </div>
                              </th>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Template Name
                                </div>
                              </th>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Sender Email ID
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Frequency
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Scheduled Send Time
                                </div>
                              </th>{" "}
                              <th>
                                <div className="d-flex align-items-center">
                                  Modified Status
                                </div>
                              </th>
                              <th>Activated / Paused Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {Object.keys(emailEvents).map((item, m) => (
                            <tbody
                              key={m}
                              className="table-bordered table responsiveTable"
                            >
                              {emailEvents[item].map((x, i) => (
                                <>
                                  <tr
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        m % 2 === 0 ? "#F6F6F6" : "",
                                      border: "1px solid #E5E5E5",
                                    }}
                                  >
                                    {i === 0 ? (
                                      <td
                                        rowSpan={emailEvents[item].length}
                                        className="text-start"
                                      >
                                        <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                          {item}
                                        </span>
                                      </td>
                                    ) : null}

                                    <td className="p-relative">{x.events}</td>
                                    <td>{x.senderMail}</td>
                                    <td>{x.emailFrequency}</td>
                                    <td>{x.time}</td>

                                    <td>
                                      {x.updatedByEmail &&
                                      x.updatedDateTime !== ""
                                        ? "Updated by " +
                                          x.updatedByEmail +
                                          " on " +
                                          moment(x.updatedDateTime).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </td>

                                    <td>
                                      {x.status === "Active"
                                        ? "Activated By " +
                                          x.activatedOrPausedByEmail
                                        : "Paused By " +
                                          x.activatedOrPausedByEmail}
                                      {x.activatedPausedDateTime &&
                                        " on " +
                                          moment(
                                            x.activatedPausedDateTime
                                          ).format("MM/DD/YYYY")}
                                    </td>
                                    <td className="d-flex justify-content-center align-items-center mv-auto">
                                      <button
                                        type="button"
                                        style={{
                                          width: "80px",
                                          fontSize: "12px",
                                          textAlign: "center",
                                          display: "inline-flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                        }}
                                        className={
                                          x.status === "Active"
                                            ? "btn btn-sm btn-outline-danger p-1"
                                            : "btn btn-sm btn-primary p-1"
                                        }
                                        onClick={() =>
                                          handleConfirmModalShow(
                                            x.status,
                                            x.id_emailTemplate
                                          )
                                        }
                                      >
                                        {x.status === "Active" ? (
                                          <>
                                            Pause{" "}
                                            <i className="ml-2">
                                              <BsFillPauseCircleFill />
                                            </i>
                                          </>
                                        ) : (
                                          <>
                                            Activate{" "}
                                            <i className="ml-1">
                                              <BsCheckCircleFill />
                                            </i>
                                          </>
                                        )}
                                      </button>

                                      <button
                                        type="button"
                                        style={{
                                          width: "70px",
                                          fontSize: "12px",
                                          textAlign: "center",
                                          display: "inline-flex",
                                          justifyContent: "center",
                                          alignItems: "baseline",
                                        }}
                                        className="btn btn-sm btn-secondary ml-2 p-1"
                                        onClick={() => {
                                          console.log(x);
                                          setEmailFullView(true);
                                          setSelectedEmailData(x);
                                        }}
                                      >
                                        <>
                                          View{" "}
                                          <i className="ml-1">
                                            <BsEye />
                                          </i>
                                        </>
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                      {emailData && emailData?.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalItems={tempData?.length}
                          itemsPerPage={limit}
                          onPageChange={handlePageChange}
                          limit={limit}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <NoRecord />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>

        {emailFullView && (
          <EmailTemplateModalView
            emailFullView={emailFullView}
            setEmailFullView={setEmailFullView}
            selectedEmailData={selectedEmailData}
            setSelectedEmailData={setSelectedEmailData}
            createNew={createNew}
            setCreateNew={setCreateNew}
          />
        )}

        {confirmShow && (
          <>
            <Modal show={confirmShow} onHide={() => setConfirmShow(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                    {activateTemp.status === "Paused"
                      ? "Activate Event Confirmation"
                      : "Pause Event Confirmation"}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <hr />
              <Modal.Body className="p-3">
                <p>
                  {activateTemp.status === "Paused"
                    ? "If you activate this event, notification emails will be sent to all users. Do you want to proceed?"
                    : "The event would be paused, and all users will not receive emails for that event. Do you want to proceed?"}
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setConfirmShow(false)}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleActivate()}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </body>
    </>
  );
}

export default EmailTemplate;
