import React, { useState } from "react";

const CommunityTitle=({jobData})=>{
    const [countryClick, setCountryClick] = useState(false);
    return (
      <div className="row justify-content-center">
        <div className="col-xl-12">
          <div className="text-center mb-4 font-white text-capitalize">
            <h4>{jobData?.jobTitle}</h4>
            <p>
              {/* {props?.jobData?.workPlaceType} /{" "} */}
              {jobData?.isCountry
                ? jobData?.country
                : jobData?.city + ", " + jobData?.country}
            </p>
          </div>
        </div>
      </div>
    );
}

export default CommunityTitle