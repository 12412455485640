import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useHistory } from "react-router-dom";
import Event from "../../common/googleanalytic/Tracking";
// import GigReport from "../components_v3/performancereport/index";
import {
  loginUserName,
  getUserRole,
  isCurator,
} from "../../../service/AuthService";

const SidebarMenu = ({
  path,
  role,
  setAddJobNewModal,
  setShowAddTalentModal,
}) => {
  let history = useHistory();

  const routeChange = () => {
    Event(
      "Master Report Clicked",
      `${loginUserName()},${getUserRole()}`,
      window.location.pathname
    );
    history.push(`/high5Hire/masterreport`);
  };

  const changeRoute = () => {
    Event(
      "Hotlist Report Clicked",
      `${loginUserName()},${getUserRole()}`,
      window.location.pathname
    );
    history.push(`/high5Hire/hotlistreport`);
  };

  return (
    <div className="body">
      {(role === "HiringManager" || role === "Recruiter") && (
        <button
          type="button"
          className="btn w-100 px-0"
          onClick={
            role === "HiringManager"
              ? () => {
                  setAddJobNewModal(true);
                }
              : role === "Recruiter"
              ? () => {
                  setShowAddTalentModal(true);
                }
              : ""
          }
        >
          <i className="fas fa-plus fa-fw-dropdown  "></i>
          <span
            className="nav-text ml-2"
            onClick={() =>
              role === "HiringManager"
                ? Event(
                    "New Job Clicked",
                    `${loginUserName()},${getUserRole()}`,
                    window.location.pathname
                  )
                : role == "Recruiter"
                ? Event(
                    "Add Talent Clicked",
                    `${loginUserName()},${getUserRole()}`,
                    window.location.pathname
                  )
                : ""
            }
          >
            {role === "HiringManager"
              ? "New Job"
              : role === "Recruiter"
              ? "Add Talent"
              : ""}
          </span>
        </button>
      )}
      <ul className="menu">
        <li
          onClick={() =>
            Event(
              "Dashboard Clicked",
              `${loginUserName()},${getUserRole()}`,
              window.location.pathname
            )
          }
        >
          <a
            href="/high5Hire"
            className={path === "high5Hire" && "active"}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Dashboard"
          >
            <i className="fad fa-fw fa-home-lg-alt mr-3"></i>
            <span className="nav-text">Dashboard</span>
          </a>
        </li>{" "}
        {role !== "High5Coordinator" &&
          role !== "CustomerSuccess" &&
          role !== "CommunityManager" &&
          role !== "SuperAdmin" && (
            <li
              onClick={() =>
                Event(
                  "Talent Pool Clicked",
                  `${loginUserName()},${getUserRole()}`,
                  window.location.pathname
                )
              }
            >
              <a
                href="/high5Hire/talents"
                className={path === "talents" && "active"}
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Talent Pool"
              >
                <i className="fad fa-fw fa-users mr-3"></i>
                <span className="nav-text">Talent Pool</span>
              </a>
            </li>
          )}
        {role !== "High5Coordinator" && (
          <li
            onClick={() =>
              Event(
                "Requests Clicked",
                `${loginUserName()},${getUserRole()}`,
                window.location.pathname
              )
            }
          >
            <a
              href="/high5Hire/requests"
              className={path === "requests" && "active"}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Jobs"
            >
              <i className="fad fa-fw fa-briefcase mr-3"></i>
              <span className="nav-text">Jobs</span>
            </a>
          </li>
        )}
        {(role === "SuperAdmin" || role === "CustomerSuccess") && (
          <li>
            <a
              href="/high5Hire/auditLog"
              className={path === "auditLog" && "active"}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Analytics"
            >
              <i className="fad fa-fw fa-clipboard-list mr-3"></i>
              <span className="nav-text">Analytics</span>
            </a>
          </li>
        )}{" "}
        {(role == "SuperAdmin" || role === "CustomerSuccess") && (
          <li
            onClick={() =>
              Event(
                "Performances Testing Clicked",
                `${loginUserName()},${getUserRole()}`,
                window.location.pathname
              )
            }
          >
            <a
              href="/high5Hire/performancetesting"
              className={path === "performancetesting" && "active"}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Performance Testing"
            >
              <i className="fad fa-fw fa-clock mr-3"></i>
              <span className="nav-text">Performance Testing</span>
            </a>
          </li>
        )}
        {(role === "HiringManager" ||
          (role === "Recruiter" && isCurator())) && (
          <li>
            <a
              href="/high5Hire/talentcommunity/pools"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Talent Community"
            >
              <i class="fad fa-layer-group mr-3"></i>
              <span className="nav-text">Talent Community</span>
            </a>
          </li>
        )}
        {/* Generic Reporting Section */}
        {role !== "CommunityManager" &&
          role !== "High5Coordinator" &&
          role !== "Recruiter" &&
          role !== "CustomerSuccess" &&
          role !== "SuperAdmin" &&
          role !== "Admin" && (
            <li>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom-end"
                overlay={
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="requestMoreMenu"
                  >
                    <button
                      type="button"
                      onClick={() => routeChange()}
                      className="dropdown-item"
                    >
                      <i className="fa fa-file-excel-o mr-2"></i>Master Report
                    </button>

                    {role !== "HiringManager" && (
                      <button
                        type="button"
                        onClick={() => changeRoute()}
                        className="dropdown-item"
                      >
                        <i className="fa fa-file-excel-o mr-2"></i>Hotlist
                        Report
                      </button>
                    )}
                    {role === "CustomerSuccess" && (
                      <button
                        type="button"
                        onClick={() => history.push(`/high5Hire/unsubUsers`)}
                        className="dropdown-item"
                      >
                        <i className="fa fa-file-excel-o mr-2"></i>
                        Unsusbscribed Users
                      </button>
                    )}
                    {role === "CustomerSuccess" && (
                      <button
                        type="button"
                        onClick={() => history.push(`/high5Hire/unsubTalents`)}
                        className="dropdown-item"
                      >
                        <i className="fa fa-file-excel-o mr-2"></i>
                        Unsusbscribed Talents
                      </button>
                    )}
                  </div>
                }
              >
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Report"
                  onClick={() =>
                    Event(
                      "Report Clicked",
                      `${loginUserName()},${getUserRole()}`,
                      window.location.pathname
                    )
                  }
                >
                  <i className="fad fa-fw fa-file mr-3"></i>
                  <span className="nav-text">Report</span>
                </a>
              </OverlayTrigger>
            </li>
          )}
        {role === "CommunityManager" && (
          <li>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom-end"
              overlay={
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="requestMoreMenu"
                >
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      window.open("/high5Hire/cmmasterreport", "_self");
                    }}
                  >
                    <i className="fa fa-file-excel-o mr-2"></i>Master Report
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      window.open("/high5Hire/cmhotlistreport", "_self");
                    }}
                  >
                    <i className="fa fa-file-spreadsheet mr-2"></i>Hotlist
                    Report
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      window.open("/high5Hire/cmcandidatereport", "_self");
                    }}
                  >
                    <i className="fa fa-file-spreadsheet mr-2"></i>
                    Candidate Report
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      window.open("/high5Hire/cmrecruiterreport", "_self");
                    }}
                  >
                    <i className="fa fa-file-spreadsheet mr-2"></i>
                    Recruiter Report
                  </button>
                  <button
                    type="button"
                    className="dropdown-item"
                    onClick={() => {
                      window.open("/high5Hire/jobreport", "_self");
                    }}
                  >
                    <i className="fa fa-file-spreadsheet mr-2"></i>
                    Job Report
                  </button>
                </div>
              }
            >
              <a
                href="#"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Report"
                onClick={() =>
                  Event(
                    "Report Clicked",
                    `${loginUserName()},${getUserRole()}`,
                    window.location.pathname
                  )
                }
              >
                <i className="fad fa-fw fa-file mr-3"></i>
                <span className="nav-text">Report</span>
              </a>
            </OverlayTrigger>
          </li>
        )}
        {role === "HiringManager" && (
          <li>
            <a
              href="/high5Hire/marketoverview"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Marketplace Overview"
            >
              <i class="fad  fa-fw fa-chart-line mr-3"></i>
              <span className="nav-text">Marketplace Overview</span>
            </a>
          </li>
        )}
        {(role == "SuperAdmin" || role == "CustomerSuccess") && (
          <li>
            <a
              href="/high5Hire/adminConsole"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Admin Console"
              onClick={() =>
                Event(
                  "Report Clicked",
                  `${loginUserName()},${getUserRole()}`,
                  window.location.pathname
                )
              }
            >
              <i class="fa fa-envelope mr-3"></i>
              <span className="nav-text">Admin Console</span>
            </a>
          </li>
        )}
        {role == "SuperAdmin" && (
          <li>
            <a
              href="/high5Hire/tagsTemplate"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Tags"
              onClick={() =>
                Event(
                  "Report Clicked",
                  `${loginUserName()},${getUserRole()}`,
                  window.location.pathname
                )
              }
            >
              <i class="fa fa-tags mr-3"></i>
              <span className="nav-text">Tags</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SidebarMenu;
