import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import _ from "lodash";
import { post } from "../../../../service/ApiService";
import { ButtonLoader } from "../../../../constants/Constants";
import { dropDownData } from "../../../../constants/DropDownData";

// import HereMap from "../../../location/HereMapAddtalent";

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function AdditionalInfo({
  data,
  getData,
  ip,
  browserName,
  updateDetails,
  setUpdateDetails,
}) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [additionalInfo, setAdditionalInfo] = useState({
    currentJobTitle: data.designation,
    currentEmployer: data.currentEmployer,
    experienceMonth: data.experienceMonth,
    experienceYear: data.experienceYear,
    legallyAuthorized: data.legallyAuthorized,
    requireSponsorship: data.requireSponsorship,
  });
  const [tempAdditionalInfo, setTempAdditionalInfo] = useState({
    currentJobTitle: data.designation,
    currentEmployer: data.currentEmployer,
    experienceMonth: data.experienceMonth,
    experienceYear: data.experienceYear,
    legallyAuthorized: data.legallyAuthorized,
    requireSponsorship: data.requireSponsorship,
  });

  const handleChange = (e) => {
    if (e.target.name === "experienceMonth" && Number(e.target.value) > 12)
      return;
    if (e.target.type === "checkbox")
      setAdditionalInfo({
        ...additionalInfo,
        [e.target.name]: e.target.checked,
      });
    else if (
      e.target.name === "experienceYear" ||
      e.target.name === "experienceMonth"
    )
      setAdditionalInfo({
        ...additionalInfo,
        [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
      });
    else
      setAdditionalInfo({ ...additionalInfo, [e.target.name]: e.target.value });
  };

  const editContactInfo = () => {
    setReadOnlyMode(false);
  };

  const cancelContactInfo = () => {
    setAdditionalInfo(tempAdditionalInfo);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const saveContactInfo = async () => {
    if (!additionalInfo.currentJobTitle) {
      toast.error("Fields marked with *(Asterisk) are Mandatory");
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        const parameters = {
          ...data,
          zipcode: data.zipcode,
          experiences: data.experiences,
          educations: data.educations,
          designation: additionalInfo.currentJobTitle,
          currentEmployer: additionalInfo.currentEmployer,
          experienceMonth: parseInt(additionalInfo.experienceMonth),
          experienceYear: parseInt(additionalInfo.experienceYear),
          legallyAuthorized: additionalInfo.legallyAuthorized,
          requireSponsorship: additionalInfo.requireSponsorship,
          activity: "Additional info updated",
          candidateId: data.userId,
          ipAddress: ip,
          browser: browserName,
        };
        const res = await post("/talent/updatetalentprofile", parameters);
        if (res.status === 200) {
          setTempAdditionalInfo(additionalInfo);
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
            setSaveClicked(true);
          }, 1000);
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Additional Info</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    className="btn btn-text"
                    onClick={editContactInfo}
                  >
                    <i className="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelContactInfo}
                      className="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveContactInfo}
                      className="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />

              <div className="row mb-2">
                <div className="col-lg-6 ">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control "
                      name="currentJobTitle"
                      value={additionalInfo.currentJobTitle}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>What is your current job title?*</label>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="currentEmployer"
                      value={additionalInfo.currentEmployer}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>Who is your current employer?</label>
                  </div>
                </div>
              </div>

              <div className="mt-3 d-flex col-lg-12 gap-3 px-2">
                <div className="mb-2 col-6">
                  <label>How many years of experience do you have?*</label>
                </div>
                <div className="form-floating col-6">
                  <div className="d-flex">
                    <div className="mr-3 form-floating col-lg-2">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="100"
                        name="experienceYear"
                        placeholder="Year"
                        value={additionalInfo.experienceYear}
                        disabled={readOnlyMode}
                        onChange={handleChange}
                      />
                      <label>Years</label>
                    </div>
                    <div className="form-floating col-lg-2">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        name="experienceMonth"
                        placeholder="Months"
                        value={additionalInfo.experienceMonth}
                        disabled={readOnlyMode}
                        onChange={handleChange}
                      />
                      <label>Months</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdditionalInfo;
