import {
  GET_TALENT_LIST_BEGIN,
  GET_TALENT_LIST_SUCCESS,
  GET_TALENT_LIST_FAILURE,
} from "../../action/talent/TalentList";

const initialState = {
  loading: false,
  talentData: null,
  ongoingRequests: 0, // Add a counter for ongoing requests
};

const getTalentListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        talentData: null,
        ongoingRequests: state.ongoingRequests + 1, // Increment ongoing requests counter
      };
    case GET_TALENT_LIST_SUCCESS:
      return {
        ...state,
        talentData: payload,
        loading: state.ongoingRequests === 1 ? false : state.loading, // Set loading to false if it's the last ongoing request
        ongoingRequests: state.ongoingRequests - 1, // Decrement ongoing requests counter
      };
    case GET_TALENT_LIST_FAILURE:
      return {
        ...state,
        talentData: payload,
        loading: state.ongoingRequests === 1 ? false : state.loading, // Set loading to false if it's the last ongoing request
        ongoingRequests: state.ongoingRequests - 1, // Decrement ongoing requests counter
      };
    default:
      return state;
  }
};

export default getTalentListReducer;
