import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../common/loader/Loader";
import CommunityTitle from "./CommunityTitle";
import { get } from "../../../service/ApiService";
import { getUserRole } from "../../../service/AuthService";
import SidePanelHeader from "./sidepanelheader/Index";
import SidepanelDetails from "./sidepaneldetails/Index";
import DispositionHeader from "../../disposition/talentcommunity/dispositionheader/Index";
import { getCommunityDispositionCount } from "../../../action/disposition/talentcommunity/CommunityDispositionCount";

const CommunityDetails = ({ showDetails, setShowDetails }) => {
  const { communityId, Tab } = useParams();
  const [communityData, setCommunityData] = useState({});
  const [sideView, setSideView] = useState("overview");
  const [loading, setLoading] = useState(false);
  const [dispositionView, setDispositionView] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.communityDisposition.dispositionDetails,
      dispositionLoading: state.communityDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.communityDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.communityDispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.communityDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.communityDispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentPage(1);
    getPoolById();
    const payload = {
      jobId: communityId,
      // filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      // searchText: value,
    };
    dispatch(getCommunityDispositionCount(payload));
  }, []);
  const getPoolById = async () => {
    setLoading(true);
    await get(`/community/getCommunityById?communityId=${communityId}`).then(
      (res) => {
        let setData = res.data;
        const payload = {
          communityId: setData?.communityId,
        };
        setCommunityData(setData);
        setLoading(false);
      }
    );
  };

  const renderFilter = () => {
    return (
      <div className="hero-card-header ">
        {/* <div className=" card card-flat card-lg ">
          <div className="card-body pb-0 d-flex flex-column h-100">
            <Filter
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  applyFilter={applyFilter}
                  inputList={inputList}
                  setInputList={setInputList}
                  skillInputList={skillInputList}
                  setSkillInputList={setSkillInputList}
                  isFilterApplied={false}
                  filterParams={filterParams}
                  additionalInfo={poolData?.additionalInfo}
                  poolData={poolData}
                  questions={questions}
                  setQuestions={setQuestions}
                  dispositionView={dispositionView}
                />
          </div>
        </div> */}
        <div className="row">
          <SidePanelHeader
            jobData={communityData}
            setSideView={setSideView}
            sideView={sideView}
          />
          <SidepanelDetails
            jobData={communityData}
            sideView={sideView}
            setLoading={setLoading}
            setPoolData={setCommunityData}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {/* {(loading ||
        dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />} */}
      <div id="RequestView">
        <div className="hero">
          <div className="section-100 pb-0">
            <div className=" container-fluid">
              {communityData && Object.keys(communityData)?.length > 0 && (
                <CommunityTitle jobData={communityData} />
              )}

              {
                // getUserRole() == "Recruiter" &&
                <>
                  <div className="row">
                    <div
                      className="col-6 d-flex"
                      style={{ justifyContent: "start" }}
                    >
                      {/* <BreadCrumbs /> */}
                    </div>
                    <div
                      className="col-6 mb-1 d-flex"
                      style={{ justifyContent: "end" }}
                    >
                      {/* <Search
                        dispositionDetails={dispositionDetails}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        view={dispositionView}
                        value={value}
                        setValue={setValue}
                      /> */}
                      <button
                        className="btn btn-info ml-1"
                        // onClick={() => setInvite(true)}
                      >
                        <i
                          aria-hidden="true"
                          class="far fa-plus fa-fw mr-1"
                        ></i>
                        Invite Talent
                      </button>
                    </div>
                  </div>
                </>
              }
              <div class="row justify-content-center">
                <div class="col-xl-12">
                  <div className="row d-flex justify-content-between">
                    <div className="col-xl-4 d-none-1200">
                      {<div className="">{renderFilter()}</div>}
                    </div>
                    <DispositionHeader
                      view={dispositionView}
                      setView={setDispositionView}
                      jobData={communityData}
                      // dispositionDetails={dispositionDetails}
                      isFrom="TalentPool"
                      filterData={filterData}
                      setFilterData={setFilterData}
                      // dispositionCount={dispositionCount}
                      // dispositionCountLoading={dispositionCountLoading}
                      // fetchData={fetchData}
                      // filterLength={filterLength}
                      // selectedTalents={selectedTalents}
                      // setSelectedTalents={setSelectedTalents}
                      Tab={Tab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="pb-5">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-12 ">
                <div class="row d-flex justify-content-between">
                  <div class="col-xl-3">
                    
                  </div>
                  {
                    <DispositionDetails
                      view={dispositionView}
                      jobData={communityData}
                      dispositionDetails={dispositionDetails}
                      dispositionDisqualify={dispositionDisqualify}
                      filterData={filterData}
                      setFilterData={setFilterData}
                      filterParams={filterParams}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      filterLength={filterLength}
                      selectedTalents={selectedTalents}
                      setSelectedTalents={setSelectedTalents}
                    />
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* {invite && (
          <InviteTalent
            shareButton={invite}
            setShareButton={setInvite}
            jobData={communityData}
            isFrom="TalentPool"
          />
        )}{" "}
        {showDetails && (
          <Modal show={showDetails} onHide={() => {}} size="lg">
            <div className="bl-modal-header">
              <h5 className="bl-modal-title">{"Job detail"}</h5>
              <div className="bl-modal-header">
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDetails(false)}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div className="row ">
                  <SidePanelHeader
                    jobData={communityData}
                    setSideView={setSideView}
                    sideView={sideView}
                  />
                  <SidepanelDetails
                    jobData={communityData}
                    sideView={sideView}
                    setLoading={setLoading}
                    setPoolData={setPoolData}
                  />
                </div>
                <hr />
                <div className="row text-end">
                  <div className="w-75"></div>
                  <div className="w-25">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setShowDetails(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        )} */}
      </div>
    </>
  );
};

export default CommunityDetails;
