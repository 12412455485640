import React, { useRef } from "react";
import GoogleLocationCityAPI from "../../location/GoogleLocationCityAPI";

const PreferredLocation = ({
  handleSubmit,
  topLoactionEdit,
  toggleEdit,
  handleLocationCancel,
  preferredLocation,
  setPreferredLocation,
  handleDelete,
}) => {
  const searchInput = useRef(null);
  const setLocation = (address, searchInput) => {
    if (!preferredLocation.includes(address.city)) {
      setPreferredLocation([...preferredLocation, address.city]);
      searchInput.current.value = "";
    }
  };
  const handleAddLocation = () => {
    if (
      !preferredLocation.includes(searchInput.current.value) &&
      searchInput.current.value != ""
    ) {
      setPreferredLocation([...preferredLocation, searchInput.current.value]);
      searchInput.current.value = "";
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <label className="p-0 mt-2">
          Preferred Locations (<small>Note: Type a country and hit Enter</small>
          )
        </label>
        {!topLoactionEdit ? (
          <button
            type="button"
            className="btn btn-text"
            style={{ fontSize: "12px" }}
            onClick={() => {
              toggleEdit("location");
            }}
          >
            <i className="fas fa-pencil-alt mr-1"></i>
            Edit
          </button>
        ) : (
          <div>
            <button
              type="button"
              onClick={handleLocationCancel}
              className="btn btn-sm btn-secondary mr-3"
              style={{ fontSize: "12px" }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ fontSize: "12px" }}
              onClick={() => {
                handleSubmit("location");
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="">
          <div className="form-inline-edit">
            {topLoactionEdit === false ? (
              <div>
                <div className="toggle justify-content-start">
                  <div>
                    {preferredLocation.length > 0 ? (
                      preferredLocation.map((item) => (
                        <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                      ))
                    ) : (
                      <span className="tag tag-red2 mb-1 mr-1">
                        No data found
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <ul>
                    {preferredLocation.map((item) => (
                      <li
                        className="tag tag-blue3 mr-2 mt-1"
                        style={{ height: "22px" }}
                      >
                        {item}
                        <i
                          class="fa fa-close ml-1"
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                          onClick={() => handleDelete(item)}
                        ></i>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="d-flex align-items-center">
                  <GoogleLocationCityAPI
                    setLocation={setLocation}
                    searchInput={searchInput}
                    className="countryPreferred"
                  />
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ml-1"
                    style={{ fontSize: "12px" }}
                    onClick={handleAddLocation}
                  >
                    Add
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferredLocation;
