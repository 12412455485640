import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCuratorsSideView } from "../../../../action/curator/CuratorSideView";
import Loader from "../../../common/loader/Loader";
import Pagination from "../../../common/pagination/Pagination";
import ProfileImage from "../../../common/profileimage/ProfileImage";
import NoRecord from "../../../common/NoRecord";

const Curator = () => {
  const location = useLocation();
  const poolId = location.pathname.split("/").pop();

  const dispatch = useDispatch();

  const [limit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    curatorSideViewLoading,
    curatorSideViewDetails,
    curatorSideViewDetailsCount,
  } = useSelector(
    (state) => ({
      curatorSideViewLoading:
        state.curatorSideViewDetailsReducer.curatorSideViewLoading,
      curatorSideViewDetails:
        state.curatorSideViewDetailsReducer.curatorSideViewDetails?.data,
      curatorSideViewDetailsCount:
        state.curatorSideViewDetailsReducer.curatorSideViewDetails?.total,
    }),
    shallowEqual
  );

  useEffect(() => {
    const payload = {
      poolId: poolId,
      limit: limit,
      currentPage: currentPage,
    };
    dispatch(getCuratorsSideView(payload));
  }, [poolId, limit, currentPage, dispatch]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusString = (status) => {
    switch (status) {
      case 21:
        return "Accepted";
      case 22:
        return "Declined";
      case 32:
        return "Invited";
      case 44:
        return "Removed";
      default:
        return "";
    }
  };

  const formatDateTime = (datetime) => {
    const dateObj = new Date(datetime);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  if (curatorSideViewLoading) {
    return <Loader />;
  }

  return (
    <div className="pt-0 mb-3 overflow-auto">
      {curatorSideViewDetails.length === 0 ? (
        <NoRecord />
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Curator Name</th>
                <th scope="col">Invited on</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {curatorSideViewDetails.map((curator) => (
                <tr key={curator.id}>
                  <td>
                    <div className="avatar-name-candidatestableview d-flex align-items-center">
                      <div>
                        <span>
                          <ProfileImage
                            imageSrc={curator.profileImagePath}
                            imageWidth={"30"}
                            imageHeight={"30"}
                            avatarSize={"30"}
                            avatarRound={"75px"}
                            firstName={curator.firstName}
                            lastName={curator.lastName}
                          />
                        </span>
                      </div>
                      <div className="ml-2" style={{ marginLeft: "-0.5em" }}>
                        <strong className="mb-0">
                          {curator.firstName} {curator.lastName}
                        </strong>
                      </div>
                    </div>
                  </td>
                  <td>{formatDateTime(curator.createddatetime)}</td>
                  <td>
                    <span
                      className={`tag ${
                        curator.status === 21
                          ? "tag-green4"
                          : curator.status === 22
                          ? "tag-red2"
                          : curator.status === 44
                          ? "tag-red1"
                          : "tag-blue2"
                      }`}
                    >
                      {getStatusString(curator.status)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalItems={curatorSideViewDetailsCount}
            itemsPerPage={limit}
            onPageChange={handlePageChange}
            limit={10}
          />
        </>
      )}
    </div>
  );
};

export default Curator;
