import React from "react";

const PreviewContent = ({ isFrom, closeModal, data }) => {
  const handleTogglePreview = () => {
    closeModal();
  };

  const maskSensitiveInformation = (htmlContent) => {
    // Regular expression to match email pattern
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    // Regular expression to match password pattern
    const passwordRegex = /(Password:\s*)(\w+)/gi;
    const passwordRegexWithOptionalSpace = /(Password\s*:\s*)(\w+)/gi;

    // Replace email with masked version
    const maskedEmailContent = htmlContent?.replace(emailRegex, (match) => {
      const [username, domain] = match?.split("@");
      const maskedUsername = username[0] + "*"?.repeat(username.length - 1);
      return `${maskedUsername}@${domain}`;
    });

    // Replace password with masked version
    const maskedContent = maskedEmailContent
      ?.replace(passwordRegex, (match, p1, p2) => {
        const maskedPassword = "*"?.repeat(p2.length);
        return `${p1}${maskedPassword}`;
      })
      ?.replace(passwordRegexWithOptionalSpace, (match, p1, p2) => {
        const maskedPassword = "*"?.repeat(p2.length);
        return `${p1}${maskedPassword}`;
      });

    return maskedContent;
  };

  return (
    <div className="email-preview-container">
      <div className="email-preview-header">
        {isFrom === "EmailBroadcast" ? (
          <p>
            <strong>To:</strong>{" "}
            {[
              ...(data?.selectedCandidates.length > 0
                ? data?.selectedCandidates
                : []),
            ].join(", ")}
          </p>
        ) : (
          <p>
            <strong>To:</strong> {data?.to}
          </p>
        )}

        <p>
          <strong>Subject:</strong> {data?.subject}
        </p>
      </div>

      <div className="email-preview-body disabled-container">
        <div
          className="email-content"
          dangerouslySetInnerHTML={{
            __html: maskSensitiveInformation(data?.content),
          }}
        />
        <div className="d-flex gap-3">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.footer,
            }}
          />
        </div>
      </div>
      <div
        className="email-preview-footer"
        style={{ textAlign: "right", marginTop: "20px" }}
      >
        <button onClick={handleTogglePreview} className="btn btn-secondary">
          Close{" "}
        </button>
      </div>
    </div>
  );
};

export default PreviewContent;
