import React from "react";
import {
  Container,
  Header,
  Subtitle,
  HeaderContainer,
  HeaderDetails,
  Entries,
  EntryContainer,
  Date,
  EntryContent,
  Actions,
  Editor,
  Action,
} from "./AuditTrailStyleComp";
import moment from "moment";
import { Modal } from "react-bootstrap";

const auditTrailData = {
  jobTitle: "Customer Service Executive - New York",
  communityName: "Community Name",
  createdOn: "Sep 23, 2024",
  createdBy: "Ritesh Srivastava",
  entries: [
    {
      date: "Dec 12, 2023",
      editor: "Ritesh Srivastava",
      email: "ritesh@yopmail.com",
      actions: [
        {
          type: "Deleted",
          question:
            "Would you like to join in 2 weeks from the date of offer letter ?",
        },
        { type: "Added", question: "What is Current Salary?" },
      ],
    },
    {
      date: "Nov 23, 2023",
      editor: "Shrashti Singh",
      email: "shrashti@yopmail.com",
      actions: [{ type: "Added", question: "What is your current location?" }],
    },
  ],
};

const AuditTrail = ({ jobData, showAuditTrail, setShowAuditTrail }) => {
  return (
    <>
      <Modal size="lg" show={showAuditTrail}>
        <Modal.Header>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setShowAuditTrail(false);
            }}
            class="close p-0 bl-modal-close-btn mb-2"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Header>Audit Trail</Header>
            <Subtitle>
              Audit trail enables you to track the actions that the end user
              performs
            </Subtitle>

            <HeaderContainer>
              <div>
                {jobData?.jobTitle} - {jobData?.country}
              </div>
              <HeaderDetails>
                <span>
                  Created on:{" "}
                  {moment(jobData?.createddatetime).format("MMM D, YYYY")}
                </span>
                <br></br>
                <span>Created by: {jobData.poolowner}</span>
              </HeaderDetails>
            </HeaderContainer>
            <Entries>
              {jobData?.aat.map(
                (entry, index) =>
                  (entry?.deleted?.length > 0 ||
                    entry?.edited?.length > 0 ||
                    entry?.added?.length > 0) && (
                    <EntryContainer>
                      <Date>
                        {moment(entry.createdDate).format("MMM D, YYYY")}
                      </Date>
                      <EntryContent>
                        <Editor>
                          Edited by: {entry.createdByName} ({entry.email})
                        </Editor>
                        <Actions>
                          {entry?.deleted.length > 0 &&
                            entry?.deleted.map((action, index) => (
                              <Action key={index}>
                                <span>Deleted Question:</span> "{action}"
                              </Action>
                            ))}
                          {entry?.added.length > 0 &&
                            entry?.added.map((action, index) => (
                              <Action key={index}>
                                <span>Added Question:</span> "{action?.question}
                                "
                              </Action>
                            ))}
                          {entry?.edited.length > 0 &&
                            entry?.edited.map((action, index) => (
                              <Action key={index}>
                                <span>Edited Question:</span> "
                                {action?.oldQuestion}{" "}
                                {action?.newQuestion
                                  ? "to" + " " + action?.newQuestion
                                  : ""}
                                " <br></br>
                                {action?.newInput
                                  ? "Options :" +
                                    " (" +
                                    action?.oldInput?.join(", ") +
                                    ") " +
                                    "to" +
                                    " (" +
                                    action?.newInput.join(", ") +
                                    ")"
                                  : ""}
                                <br></br>
                                {action?.newInputType
                                  ? "Response Type :" +
                                    " " +
                                    action?.oldInputType +
                                    " " +
                                    "to" +
                                    " " +
                                    action?.newInputType
                                  : ""}
                              </Action>
                            ))}
                        </Actions>
                      </EntryContent>
                    </EntryContainer>
                  )
              )}
            </Entries>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            onClick={() => {
              setShowAuditTrail(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuditTrail;
