import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const BooleanFilter = ({
  handleFilterToggle,
  filterEnabled,
  filterName,
  filterStatus,
  inputList,
  setInputList,
  applyFilter,
}) => {
  const [showAddTextBox, setShowAddTextBox] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);

  useEffect(() => {
    if (
      inputList?.term1?.length > 0 ||
      inputList?.term2?.length > 0 ||
      inputList?.term3?.length > 0
    ) {
      applyFilter(inputList, filterStatus);
    }
  }, [inputList]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      const inputValue = e.target.value.trim();
      const isExisting = Object.values(inputList).some((keywords) =>
        keywords.includes(inputValue)
      );
      if (isExisting) {
        toast.error(`${inputValue} already selected`);
      } else {
        const termKeys = Object.keys(inputList);
        const emptyTermIndex = termKeys.findIndex(
          (key) => inputList[key].length === 0
        );

        if (emptyTermIndex !== -1) {
          const currentTerm = termKeys[emptyTermIndex];
          const updatedInputList = {
            ...inputList,
            [currentTerm]: [...inputList[currentTerm], inputValue],
          };

          setInputList(updatedInputList);

          e.target.value = "";
        }
      }
    }
  };

  const handleRemoveTag = (term, index) => {
    const updatedInputList = {
      ...inputList,
      [term]: inputList[term].filter((_, i) => i !== index),
    };
    setInputList(updatedInputList);
    applyFilter(updatedInputList, filterStatus);
  };

  const handleUpdateSubArray = (e, term, index, newValue) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const existingValues = inputList[term];
      if (existingValues.includes(newValue)) {
        toast.error(`${newValue} already exists`);
      } else {
        existingValues.push(newValue);
        const updatedInputList = {
          ...inputList,
          [term]: existingValues,
        };
        setInputList(updatedInputList);
        setShowAddTextBox(false);
      }
    }
  };

  const termKeys = Object.keys(inputList);

  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-collapsible"
        onClick={() => handleFilterToggle(filterStatus)}
      >
        <i
          className={
            filterEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
          }
        />
        <span className="p-relative">
          <small>{filterName}</small>
          {(inputList?.term1?.length > 0 ||
            inputList?.term2?.length > 0 ||
            inputList?.term3?.length > 0) && <span className="jewel"></span>}
        </span>
      </button>
      {filterEnabled && (
        <div className="d-flex flex-column gap-2 p-2">
          <div>
            <input
              type="text"
              name={`skill-text-box`}
              className="form-control"
              placeholder={
                filterName === "Keywords"
                  ? "Type keyword and hit enter to add"
                  : "Type skill and hit enter to add"
              }
              onKeyDown={(e) => handleKeyPress(e)}
              disabled={
                inputList.term1.length > 0 &&
                inputList.term2.length > 0 &&
                inputList.term3.length > 0
              }
              style={{ fontSize: "14px" }}
            />
          </div>
          {termKeys.map(
            (term) =>
              inputList[term].length > 0 && (
                <div key={term} className="mb-2 array-container">
                  <div className="row w-100">
                    <div className="col-10">
                      <div className="tag-row">
                        {inputList[term].map((value, index) => (
                          <div>
                            <span key={index} className="tag mr-1">
                              {value}
                              <button
                                className="btn btn-text"
                                onClick={() => handleRemoveTag(term, index)}
                              >
                                <strong className="font-18">&times;</strong>
                              </button>
                            </span>
                            {index < inputList[term].length - 1 && (
                              <span className="mr-2">OR</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-2 d-flex align-items-center">
                      <button
                        className="btn btn-text"
                        onClick={() => {
                          setShowAddTextBox(!showAddTextBox);
                          setSelectedTerm(term);
                        }}
                      >
                        {showAddTextBox && selectedTerm === term ? (
                          <i className="fas fa-minus-circle"></i>
                        ) : (
                          <i className="fas fa-plus-circle"></i>
                        )}
                      </button>
                    </div>
                  </div>

                  {showAddTextBox && selectedTerm === term && (
                    <div className="popup mt-2" key={term}>
                      <input
                        type="text"
                        onKeyDown={(e) =>
                          handleUpdateSubArray(e, term, 0, e.target.value)
                        }
                      />
                      {/* Additional styling for the popup can be added */}
                    </div>
                  )}
                </div>
              )
          )}

          <div className="d-flex justify-content-start mb-2 mt-2">
            <button
              className="btn btn-sm btn-secondary"
              onClick={() => {
                setInputList({
                  term1: [],
                  term2: [],
                  term3: [],
                });
                applyFilter(
                  {
                    term1: [],
                    term2: [],
                    term3: [],
                  },
                  filterStatus
                );
              }}
              disabled={
                inputList.term1.length === 0 &&
                inputList.term2.length === 0 &&
                inputList.term3.length === 0
              }
            >
              Clear All
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BooleanFilter;
