import moment from "moment";
import React, { useState, useEffect } from "react";
import { domain } from "../../../constants/Constants";
import Loader from "../../loader/Loader";
import { get, post } from "../../../service/ApiService";
import JobSidePanel from "./JobSidePanel";
import { format } from "libphonenumber-js";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesome
// import {
//   faAngleDoubleLeft,
//   faAngleDoubleRight,
// } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome icons
// import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const JobCard = ({
  job,
  isMatched,
  isJobCard,
  setIsJobCard,
  isFrom,
  candidateData,
  isFav,
  setIsFav,
  key,
}) => {
  const [jobList, setJobList] = useState(job);
  const [jobData, setJobData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5; // Number of jobs to display per page

  useEffect(() => {
    setJobList(job);
  }, [job]);

  const getJobDetails = async (data) => {
    setLoading(true);
    if (data?.jobId) {
      const params = { domain: domain, jobId: data.jobId };
      const res = await post("/job/getjobdetails", params).then((res) => {
        setJobData(res.data[0]);
        setLoading(false);
        setIsJobCard(true);
      });
    } else {
      const res = await get(
        `/talent/getPoolByIdForCP?poolId=${data?.fk_talentPool}`
      ).then((res) => {
        setJobData(res.data[0]);
        setLoading(false);
        setIsJobCard(true);
      });
    }
  };
  function formatDate(inputDate) {
    let date, month, year;
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
    date = date.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");
    return `${month}-${date}-${year}`;
  }

  // Calculate the index range of jobs to display on the current page
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = job.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div key={key}>
      {loading && <Loader />}
      {currentJobs?.map((job, i) => (
        <div class="card card-hoverable">
          <div key={i} class="card-body">
            {" "}
            {isMatched && (
              <span class="tag tag-coral d-inline-flex mr-2">
                <img width="16px" src="/images/high5-mark-circle-dark.svg" />
                <span class="mx-2">Matched</span>
              </span>
            )}
            {isFrom == "favJobs" && (
              <div>
                <span class="tag tag-purple1 mr-1">Favorite</span>
              </div>
            )}
            {isFrom == "applied" && (
              <div>
                <span class="tag tag-coral mr-1">Applied</span>
              </div>
            )}
            <h6
              class="mt-1"
              style={{ cursor: "pointer" }}
              onClick={() => getJobDetails(job)}
            >
              {job?.jobTitle}
            </h6>{" "}
            <div class="d-flex flex-wrap align-items-center">
              {job?.jobType && (
                <span class="d-flex align-items-center">
                  <i
                    aria-hidden="true"
                    class="far fa-suitcase me-2 font-brandBlue"
                  ></i>{" "}
                  <span class="font-regular font-primary font-gray1">
                    {job?.jobType}
                  </span>
                </span>
              )}{" "}
              {(job?.workplaceType || job?.country) && (
                <span class="d-flex align-items-center ml-3">
                  <i
                    aria-hidden="true"
                    class="far fa-map-marker-alt me-2 font-brandBlue"
                  ></i>{" "}
                  <span class="font-regular font-primary font-gray1">
                    {job?.country === "" ? job?.workplaceType : job?.country}
                  </span>
                </span>
              )}{" "}
              {job?.minimumPay && (
                <span class="d-flex align-items-center ml-3">
                  <i
                    aria-hidden="true"
                    class="far fa-piggy-bank me-2 font-brandBlue"
                  ></i>{" "}
                  <span class="font-regular font-primary font-gray1">
                    {/* {`${job?.minimumPay} - ${job?.maximumPay}`}
                {job?.minMaxCurrency}{" "} */}
                    {Number(job?.minimumPay).toLocaleString() +
                      " - " +
                      Number(job?.maximumPay).toLocaleString() +
                      " " +
                      (job?.jobType === "Full Time"
                        ? job?.minMaxCurrency + "/Year"
                        : job?.minMaxCurrency + "/Hour")}
                  </span>
                </span>
              )}
            </div>{" "}
            <div>
              <span class="d-flex align-items-center mt-2">
                {isFrom == "careerPage" ? (
                  <span className="font-regular">
                    {moment(new Date()).isSame(
                      moment(job?.createdDateTime),
                      "day"
                    )
                      ? "Today"
                      : `${moment(new Date()).diff(
                          moment(job?.createdDateTime),
                          "days"
                        )} days ago`}
                  </span>
                ) : isFrom == "favJobs" ? (
                  ""
                ) : isFrom == "matchedJobs" ? (
                  <span className="font-regular">
                    {moment(new Date()).isSame(
                      moment(job?.automatchDate),
                      "day"
                    )
                      ? "Today"
                      : `${moment(new Date()).diff(
                          moment(job?.automatchDate),
                          "days"
                        )} days ago`}
                  </span>
                ) : (
                  <span className="font-regular">
                    {moment(new Date()).isSame(
                      moment(job?.submittedDate),
                      "day"
                    )
                      ? "Today"
                      : `${moment(new Date()).diff(
                          moment(job?.submittedDate),
                          "days"
                        )} days ago`}
                  </span>
                )}
              </span>
              {/* )} */}
            </div>
          </div>
        </div>
      ))}

      {job.length > 0 && (
        <div className="pagination-container">
          <Pagination
            currentPage={currentPage}
            totalSize={job.length}
            sizePerPage={jobsPerPage}
            changeCurrentPage={handlePageChange}
            showFirstLastPages={true}
            theme="border-bottom"
            firstPageText="|⟨"
            lastPageText="⟩|"
            // firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            // lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            // prevPageText={<FontAwesomeIcon icon={faAngleLeft} />} // Use FontAwesome icon for previous button
            // nextPageText={<FontAwesomeIcon icon={faAngleRight} />} // Use FontAwesome icon for next button
          />
        </div>
      )}

      {isJobCard && (
        <JobSidePanel
          setIsJobCard={setIsJobCard}
          jobData={jobData}
          candidateData={candidateData}
          isFrom={isFrom}
          isFav={isFav}
          setIsFav={setIsFav}
        />
      )}
    </div>
  );
};

export default JobCard;
