import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { post, get } from "../../../../service/ApiService";
import { Col, Row } from "react-bootstrap";
import { roleNames } from "../../../../constants/Constants";
import { getCandidateName, getUserRole, getTenantType,
  isCurator, getOwner } from "../../../../service/AuthService";

const Notes = (props) => {
  const { jobData, setLoading, setPoolData } = props;
  const [notes, setNotes] = useState("");
  const [noteList, setNoteList] = useState([]);
  const [editNotes, setEditNotes] = useState(false); // Whether the notes are being edited
  const [editIndex, setEditIndex] = useState(null); // The index of the note being edited

  const loggedInUserName = getCandidateName(); // The name of the logged in user
  const loggedInUserRole = getUserRole(); // The role of the logged in user
  const loggedInUserTenantType = getTenantType(); // The type of tenant (Client or Employee)

  useEffect(() => {
    if (jobData?.notes) {
      const parsedNotes = JSON.parse(jobData.notes);
      setNoteList(parsedNotes);
    }
    setNotes("");
    setEditNotes(false);
    setEditIndex(null);
  }, [jobData]);

  /**
   * @description Gets the role name of the logged in user
   * @returns {string} - The role name
   */
  const getUserRoleName = async () => {
    // assign the role names based on the logged in user's role
    let userRole = roleNames[loggedInUserRole];

    // If the tenant type is 1 (Client), the role is Client Curator
    if (loggedInUserTenantType === 1 && isCurator()) {
      userRole = "Client Curator";
    }

    // If the tenant type is 2 (Employee) and the user is a Curator, the role is Gig Curator
    if (loggedInUserTenantType === 2 && isCurator()) {
      userRole = "Gig Curator";
    }

    // If the tenant type is 2 (Employee) and the user is not a Curator, the role is Gig Recruiter
    if (loggedInUserTenantType === 2 && !isCurator()) {
      userRole = "Gig Recruiter";
    }

    if (getOwner() && loggedInUserRole === "Admin") {
      userRole = "Client Admin";
    }

    // Return the role name
    return userRole;
  };

  const UpdateNotes = async (payload) => {
    try {
      setNotes("");
      await post("/community/createnotes", payload);
      let res = await get(`/community/getCommunityById?communityId=${jobData.poolId}`);
      let setData = res.data;
      setPoolData(setData);
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
      setLoading(false);
    }
  };

  /**
   * Handles the save button click. If the user is editing a note,
   * it will update the note in the database and in the component state.
   * If the user is creating a new note, it will create a new note in
   * the database and in the component state.
   */
  const handleSave = async () => {
    if (!notes.trim()) {
      toast.error("Please enter some notes before saving.");
      return;
    }

    setLoading(true);
    const role = await getUserRoleName();
    const obj = { 
      // Create a new note object
      note: notes,
      createdBy: loggedInUserName,
      createdAt: new Date(),
      createdByRole: role,
      updatedBy: null,
      updatedAt: null,
      updatedByRole: null,
    };
    setNoteList([...noteList, obj]);
    const updatedNotes = [...noteList, obj].filter((e) => e.note !== "");
    const payload = {
      poolId: jobData?.poolId,
      notes: updatedNotes,
    };
    try {
        // If the user is editing a note
        if (editNotes) {
          // Update the note in the component state
          const reverseList = [...noteList].reverse();
          reverseList[editIndex].note = notes;
          reverseList[editIndex].updatedBy = loggedInUserName;
          reverseList[editIndex].updatedAt = new Date();
          reverseList[editIndex].updatedByRole = role;
          const notesUpdated = [...reverseList].reverse();
          const payload = {
            poolId: jobData?.poolId,
            notes: notesUpdated,
          };
          // Update the note in the database
          UpdateNotes(payload);
          // Update the component state
          setNoteList(notesUpdated);
        } else {
          // If the user is creating a new note, update the component state
          UpdateNotes(payload);
          setNoteList(updatedNotes);
        }
      // Reset the state
      setLoading(false);
      editNotes ? toast.success("Note updated!") : toast.success("Note saved!");
      setEditNotes(false);
      setEditIndex(null);
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
      setLoading(false);
    }
  };

  /**
   * @description Handles the delete button click
   * @param {number} index - The index of the note to delete
   */
  const handleDelete = async (index) => {
    setLoading(true);
    const reverseList = [...noteList].reverse();
    const deletedNotes = reverseList.filter((_, i) => i !== index);
    const updatedNotes = [...deletedNotes].reverse();
    const payload = {
      poolId: jobData.poolId,
      notes: updatedNotes,
    };
    // Update the note in the database
    UpdateNotes(payload);
    setNoteList(updatedNotes);
    toast.success("Note deleted!");
    setLoading(false);
  };

  /**
   * @description Handles the edit button click
   * @param {number} index - The index of the note to edit
   */
  const handleEdit = async (index) => {
    setEditNotes(true);
    setEditIndex(index);
    const reverseList = [...noteList].reverse();
    setNotes(reverseList[index].note);
  };

  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const viewModules = {
    toolbar: false, // Disable the toolbar
  };

  const handleQuillChange = (value) => {
    // If the value is <p><br></p>, set it to an empty string
    if (value === "<p><br></p>") {
      value = "";
    }
    setNotes(value);
  };

  /**
   * @description Handles the cancel button click
   */
  const handleCancel = () => {
    setNotes("");
    setEditNotes(false);
    setEditIndex(null);
  }

  /**
   * @description Formats the date in dd-mm-yyyy
   * @param {string} dateString - The date string
   * @returns {string} - The formatted date
   */
  const getDate = (dateString) => {
    const date = new Date(dateString);

    // Extract the date from the date string
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = date.getFullYear();

    // Format the date in dd-mm-yyyy
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  /**
   * @description Formats the time in HH:MM:SS
   * @param {string} dateString - The date string
   * @returns {string} - The formatted time
   */
  const getTime = (dateString) => {
    const date = new Date(dateString);

    // Extract hours, minutes, and seconds
    const hours = String(date.getHours()).padStart(2, '0');   // Converts to 2 digits if necessary
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the time in HH:MM:SS
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime ;
  }

  const saveButtonText = editNotes ? "Update Note" : "Add Note";

  return (
    <>
      <Row style={{ minHeight: "200px" }}>
        <Col md={12} sm={12}>
          <ReactQuill
            value={notes}
            onChange={handleQuillChange}
            style={{
              fontWeight: "normal",
              height: "100px",
              borderRadius: "2px",
            }}
            theme="snow"
            row={6}
            className="bordered mb-2 mt-3"
            modules={modules}
          />
        </Col>
        <Col md={12} sm={12}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleSave}
            style={{ float: "right", marginTop: "3rem" }}
          >
            <i class="fal fa-fw fa-check mr-1" aria-hidden="true"></i>
            {saveButtonText}
          </button>
          {editNotes && <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={handleCancel}
            style={{ float: "left", marginTop: "3rem" }}
          >
            <i class="fal fa-fw fa-ban mr-1" aria-hidden="true"></i>
            Cancel
          </button>}
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} className="mt-4" style={{ height: "400px", overflowY: "auto" }}>
        {noteList &&
          [...noteList].reverse()?.map((e, index) => (
            <div key={index}>
              <div
                className="card flex-d card-flat bg-gray4 mb-2"
                style={{ flexDirection: "row", justifyContent: "space-between" }}
              >
                <ReactQuill
                  value={e.note}
                  disable
                  readOnly
                  style={{
                    fontWeight: "normal",
                    borderRadius: "2px",
                    border: "none",
                    width: "90%",
                  }}
                  theme="snow"
                  row={6}
                  className="no-border mb-2 mt-3"
                  modules={viewModules}
                />
                {index !== editIndex && 
                  <button
                    type="button"
                    style={{ textAlign: "end" }}
                    onClick={() => handleEdit(index)}
                    className="btn btn-text-accent mr-2"
                    title="edit"
                  >
                    <i
                      className="fal fa-edit fa-fw"
                      aria-hidden="true"
                      style={{ color: "#2600ff" }}
                    ></i>
                  </button>
                }
                { e?.createdBy === loggedInUserName && 
                <button
                  type="button"
                  style={{ textAlign: "end" }}
                  onClick={() => handleDelete(index)}
                  className="btn btn-text-accent mr-2"
                  title="Delete"
                >
                  <i
                    className="fal fa-trash-alt fa-fw"
                    aria-hidden="true"
                    style={{ color: "#ff2600" }}
                  ></i>
                </button>
                }
                
              </div>
              <div style={{ marginBottom: "1rem", marginLeft: "7px", fontSize: "9px" }}>
                <Row>
                  <Col md={3} sm={3}>
                    {e?.createdBy && <span>Added by: {e?.createdBy}</span>}
                    <br></br>
                    {e?.createdByRole && <span>Role: {e?.createdByRole}</span>}
                  </Col>
                  <Col md={3} sm={3}>
                    {e?.createdAt && <span>Added on: {getDate(e?.createdAt)}</span>}
                    <br></br>
                    {e?.createdAt && <span>Time: {getTime(e?.createdAt)}</span>}
                  </Col>
                  <Col md={3} sm={3}>
                    {e?.updatedBy && <span>Updated by: {e?.updatedBy}</span>}
                    <br></br>
                    {e?.updatedByRole && <span>Role: {e?.updatedByRole}</span>}
                  </Col>
                  <Col md={3} sm={3}>
                    {e?.updatedAt && <span>Updated on: {getDate(e?.updatedAt)}</span>}
                    <br></br>
                    {e?.updatedAt && <span>Time: {getTime(e?.updatedAt)}</span>}
                  </Col>
                </Row>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default Notes;
