import { browserName } from "react-device-detect";
import ShortUniqueId from "short-unique-id";
import { post } from "../../../../../../../service/ApiService";
import { getCandidateId } from "../../../../../../../service/AuthService";
import { domain } from "../../../../../../../constants/Constants";

export const candidateAuditLog = (
  trackedData,
  setTrackedData,
  job,
  candidateDetails
) => {
  post("/auditLog/candidateData", {
    ...trackedData,
    navigatedPages: trackedData.navigatedPages + "/apply",
    jobId: job.jobId,
    poolId: job.poolId,
    formType: "A",
    data: JSON.stringify({
      ...candidateDetails,
      resume: true,
    }),
    resume: true,
    firstName: candidateDetails?.firstName,
    lastName: candidateDetails?.lastName,
  });

  setTrackedData({
    ...trackedData,
    navigatedPages: trackedData.navigatedPages + "/apply",
    jobId: job.jobId,
    poolId: job.poolId,
    data: JSON.stringify({
      ...candidateDetails,
      resume: true,
    }),
    resume: true,
    firstName: candidateDetails?.firstName,
    lastName: candidateDetails?.lastName,
  });
};

export const candidateAuditLogAsync = async (
  trackedData,
  setTrackedData,
  job,
  candidateDetails,
  resumeFile
) => {
  await post("/auditLog/candidateData", {
    ...trackedData,
    jobId: job.jobId,
    formType: "A",
    poolId: job.poolId,
    navigatedPages: trackedData.navigatedPages + "/success",
    resume: resumeFile.fileName ? true : false,
    data: JSON.stringify({
      ...candidateDetails,
    }),
    status: "success",
  });
  setTrackedData({
    ...trackedData,
    jobId: job.jobId,
    poolId: job.poolId,
    navigatedPages: trackedData.navigatedPages + "/success",
    resume: resumeFile.fileName ? true : false,
    data: JSON.stringify({
      ...candidateDetails,
    }),
    status: "success",
  });
};

export const insertTalent = async (
  talentData,
  candidateStatus,
  job,
  candidateData,
  recruiterid,
  candidateDetails,
  data,
  resumeFile,
  weightageLocation,
  radiusValue
) => {
  const applyURL =
    candidateStatus.candidateStatus === "New Talent"
      ? "/disposition/applyjob/addtalent"
      : "/disposition/applyjob/updatetalent";
  let paramObj = {
    longitude: talentData?.longitude ?? "",
    latitude: talentData?.latitude ?? "",
    workAuthCountry: talentData.workAuthCountry,
    isPoolCandidate: false,
    userId:
      sessionStorage.getItem("userId") !== null
        ? sessionStorage.getItem("userId")
        : candidateStatus?.userId,
    firstName: talentData.firstName,
    lastName: talentData.lastName,
    FK_tenant: job.fk_tenant,
    jobTitle: job.jobTitle,
    jobId: job.jobId,
    createdBy: talentData.createdBy,
    email: talentData.email,
    address: talentData.city,
    city: talentData.city,
    state: talentData.state,
    country: talentData.country,
    zipcode: talentData.zipcode ?? "",
    homePhoneCode: talentData.homePhoneCode,
    homePhone: talentData.homePhone,
    workPhone: talentData.mobilePhone,
    workPhoneCode: talentData.mobilePhoneCode,
    facebook: talentData.facebook,
    twitter: talentData.twitter,
    linkedIn: talentData.linkedIn,
    preferredSalary:
      talentData.salaryType === "Hourly"
        ? candidateData?.preferredSalary
        : talentData.preferredSalary,

    preferredSalaryCurrency: talentData.preferredSalaryCurrency,
    minContractRate:
      talentData.salaryType === "Hourly"
        ? talentData.preferredSalary
        : talentData.minContractRate,
    minContractRateCurrency: talentData.minContractRateCurrency,
    description: talentData.description,
    positionTypes: JSON.stringify(talentData.positionTypes),
    preferredLocation: JSON.stringify(
      talentData.isRemote
        ? [...talentData.preferredLocation, "Remote"]
        : talentData.preferredLocation
    ),
    primarySkills: JSON.stringify(talentData.primarySkills),
    secondarySkills: JSON.stringify(talentData.secondarySkills),
    skillSet: JSON.stringify(talentData.skillSet),
    source: talentData.source,
    visaStatus: talentData.visaStatus,
    designation: talentData.designation,
    currentEmployer: talentData.currentEmployer,
    experienceMonth: talentData.experienceMonth?.toString(),
    experienceYear: Math.max(
      Math.max(...talentData.skillMatrix.map((obj) => Number(obj.experience))),
      talentData.experienceYear
    ).toString(),
    isFresher: talentData.isFresher,
    isRemote: talentData.isRemote,
    experiences: JSON.stringify(talentData.experiences),
    educations: JSON.stringify(talentData.educations),
    certifications: JSON.stringify(talentData.certifications),
    licenses: JSON.stringify(talentData.licenses),
    legallyAuthorized: talentData.legallyAuthorized,
    requireSponsorship: talentData.requireSponsorship,
    experienceLevel: talentData.experienceLevel,
    recruiterId: recruiterid,
    candidateId: candidateStatus?.id_user,
    tenantName: job.tenantName,
    highestEducation: talentData.highestEducation,
    oldFileName: data.fileName,
    fileName:
      resumeFile.fileName == ""
        ? candidateDetails.fileName
        : resumeFile.fileName,
    base64: resumeFile.base64,
    filePath: candidateDetails.filePath,
    skillMatrix: JSON.stringify(talentData.skillMatrix),
    salaryType: talentData.salaryType,
    location: JSON.stringify(talentData.location),
    dispositionStatus:
      weightageLocation && job?.isRadius
        ? radiusValue <= job?.radiusValue
          ? 6
          : 12
        : 6,
  };
  let result = await post(applyURL, paramObj);
  return result;
};

export const disposition = async (
  talentData,
  candidateStatus,
  job,
  recruiterid,
  weightageLocation,
  tenant,
  screeningAnswers,
  ip,
  radiusValue,
  response
) => {
  const dispositionURL =
    candidateStatus.candidateStatus === "New Talent"
      ? "/disposition/applyjob/insertsubmit"
      : candidateStatus.candidateStatus === "Existing Talent" &&
        candidateStatus.id_disposition == null
      ? "/disposition/applyjob/insertsubmit"
      : candidateStatus.candidateStatus === "Login Talent" &&
        candidateStatus.id_disposition == null
      ? "/disposition/applyjob/insertsubmit"
      : "/disposition/applyjob/updatesubmit";
  const payload = {
    workAuthCountry: talentData.workAuthCountry,
    userId:
      sessionStorage.getItem("userId") !== null
        ? sessionStorage.getItem("userId")
        : candidateStatus.status === 21
        ? candidateStatus.userId
        : response.data.candidateId,
    candidateStatus: candidateStatus?.candidateStatus,
    jobId: job?.jobId,
    poolId: job?.poolId,
    jobTitle: job.jobTitle,
    recruiterId: recruiterid,
    candidateStatus: candidateStatus.candidateStatus,
    status:
      weightageLocation && job?.isRadius
        ? radiusValue <= job?.radiusValue
          ? 6
          : 12
        : 6,
    candidateStatus: candidateStatus.candidateStatus,
    id_disposition: candidateStatus?.id_disposition,
    screeningQuestions: JSON.stringify(screeningAnswers),
    disqualifyComments:
      weightageLocation && job?.isRadius
        ? radiusValue <= job?.radiusValue
          ? null
          : "Talent's location not within radius limit"
        : null,
    fk_tenant: tenant,
    tenantName: job.tenantName,
    firstName: talentData.firstName,
    lastName: talentData.lastName,
    email: talentData.email.trim(),
    browser: browserName,
    ipAddress: ip,
    activity: `${
      weightageLocation && job?.isRadius
        ? radiusValue <= job?.radiusValue
          ? "Applied"
          : "Disqualify"
        : "Applied"
    } for job ${job.jobTitle}(${job?.jobId})`,
    applyJob:
      weightageLocation && job?.isRadius
        ? radiusValue <= job?.radiusValue
          ? false
          : true
        : false,
  };
  let result = await post(dispositionURL, payload);
  return result;
};

export const automatch = async (
  talentData,
  candidateId,
  response,
  tenantType,
  tenantId
) => {
  const automatchedTalent = {
    primarySkills: talentData.primarySkills,
    keywords: talentData.keywords,
    educations: [talentData.highestEducation],
    city: talentData.city,
    preferredLocation: talentData.preferredLocation,
    designation: talentData.currentJobTitle,
    experienceLevel: talentData.experienceLevel,
    userId: candidateId,
    createdBy: response.data.createdBy,
    tenantType: tenantType,
    tenantId: tenantId,
  };
  post("/automatch/automatchedTalentForJobs", automatchedTalent);
};

export const InviteTalentWithVet = async (
  talentData,
  job,
  recruiterid,
  userId,
  tenant
) => {
  const uid = new ShortUniqueId({ length: 10 });

  var date = new Date();
  let vettingDetails = JSON.parse(job.vettingDetails);

  let paramObj = {
    isPoolCandidate: false,
    tenantName: job.tenantName,
    jobTitle: job.jobTitle,
    candidateInfo: {
      firstName: talentData.firstName,
      lastName: talentData.lastName,
      email: talentData.email,
      phone: talentData.homePhone,
    },
    expiryDate: date.setDate(date.getDate() + 7),
    createdFrom: "High5hire",
    testAssign: vettingDetails._id,
    vettingDetails: vettingDetails,
    uniqueCode: uid(),
    testStatus: "Invited",
    recruiterId: recruiterid,
    candidateId: userId,
    jobId: job?.jobId,
    fk_tenant: tenant,
    reviewerFirstName: job?.reviewerFirstName,
    reviewerLastName: job?.reviewerLastName,
    reviewerEmail: job?.reviewerEmail,
    poolowner: job.poolowner,
  };
  const vetRes = await post("/vetIntegration/candidate/Invite", paramObj);
};

export const login = async (talentData, socialMediaApply, response) => {
  let method = sessionStorage.getItem("method");
  let code = socialMediaApply ? sessionStorage.getItem("code") : "";
  let baseLoginUrl = "/candidatelogin";
  let loginUrl = socialMediaApply ? `${baseLoginUrl}${method}` : baseLoginUrl;
  let result = await post(loginUrl, {
    password: response.data.password,
    code: code,
    userName: talentData.email,
  });
  return result;
};

export const sendSmsforSubmission = async (
  candidateDetails,
  job,
  pass,
  createdBY
) => {
  try {
    const name = candidateDetails.firstName;
    const jobName = job.jobTitle;
    const jobId = job.jobId;

    const getSmsContent = async (event, recipient) => {
      try {
        const response = await post("/msgtwoway/getSmsContent", {
          event,
          recipient: JSON.stringify(recipient),
        });

        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        console.error("Error fetching SMS content:", error);
      }

      return null;
    };

    const formatPhoneNumbers = (obj) => {
      const formatNumber = (number) =>
        number.charAt(0) !== "+" && number.length > 10 ? `+${number}` : number;

      const home = formatNumber(obj.homePhone || "");
      const mobile = formatNumber(obj.mobilePhone || "");
      const work = formatNumber(obj.workPhone || "");

      const addPhoneNumber = (number, array) => {
        if (number.charAt(0) === "+" && number.charAt(1) !== "1") {
          array.push(number.replace(/ /g, "").substring(1));
        }

        if (number.charAt(0) === "+" && number.charAt(1) === "1") {
          array.push(number.replace(/ /g, ""));
        }
      };

      const recepientDataSms = [];
      const recepientDataWhatsApp = [];

      addPhoneNumber(home, recepientDataWhatsApp);
      addPhoneNumber(mobile, recepientDataWhatsApp);
      addPhoneNumber(work, recepientDataWhatsApp);
      addPhoneNumber(home, recepientDataSms);
      addPhoneNumber(mobile, recepientDataSms);
      addPhoneNumber(work, recepientDataSms);

      return { recepientDataSms, recepientDataWhatsApp };
    };

    const getSmsContentAndReplace = async (event, role, recipientData) => {
      const submitContent = await getSmsContent("Submit", [role]);

      if (submitContent && submitContent.length !== 0) {
        let content = submitContent[0].smsContent;
        content = content.replace("[FirstName]", recipientData.firstName);
        content = content.replace("[CandidateName]", name);
        content = content.replace("[JobTitle]", jobName);
        content = content.replace(
          "[LinkForDetails]",
          `${domain}/high5Hire/requests/${jobId}`
        );

        submitContent[0].smsContent = content;

        return {
          recieverNumber: recipientData.recepientDataSms,
          userId: Math.floor(Math.random() * 1000000),
          message: `${submitContent?.[0]?.smsContent} \n ${submitContent?.[0]?.footer}`,
        };
      }

      return null;
    };

    const getSmsContentAndReplace2 = async (event, role, recipientData) => {
      const submitContent = await getSmsContent("Submit", [role]);

      if (submitContent && submitContent.length !== 0) {
        let content = submitContent[0].smsContent;

        content = content.replace("[Candidate Name]", recipientData.firstName);
        content = content.replace("[Job Title]", jobName);
        content = content.replace("[Username]", candidateDetails.email);
        content = content.replace("[Password]", pass);
        content = content.replace(
          "[CarrierPageLinkToLogin]",
          recipientData.CarrierPageLinkToLogin
        );

        submitContent[0].smsContent = content;

        return {
          recieverNumber: recipientData.recepientDataSms,
          userId: getCandidateId(),
          message: `${submitContent?.[0]?.smsContent} \n ${submitContent?.[0]?.footer}`,
        };
      }

      return null;
    };
    const payloadTelSms = [];

    const smsContentCand = await getSmsContentAndReplace2(
      "Submit",
      "Candidate",
      {
        firstName: candidateDetails.firstName,
        CarrierPageLinkToLogin: `${domain}/careers/jobList`,
        recepientDataSms: [
          candidateDetails.homePhone
            ? `${
                candidateDetails.homePhoneCode
              }${candidateDetails.homePhone.replace(/ /g, "")}`
            : "",
        ],
      }
    );

    if (smsContentCand && Object.keys(smsContentCand).length > 0) {
      payloadTelSms.push({
        recieverNumber: smsContentCand.recieverNumber,
        message: smsContentCand.message,
      });
    }

    const numbers = await post("/msgtwoway/getNumbersForOffer", {
      createdBy: createdBY,
      jobId: job.jobId,
    });

    const data = numbers.data;

    if (data.length === 0) {
      return;
    }

    const recepientsDataSms = [];
    const recepientsDataWhatsApp = [];

    const roles = {
      3: "Recruiter",
      4: "Hiring Manager",
      8: "Customer Success Manager",
      9: "Marketplace Manager",
    };

    await Promise.all(
      data.map(async (obj) => {
        const { recepientDataSms, recepientDataWhatsApp } =
          formatPhoneNumbers(obj);

        let submitContent = await getSmsContentAndReplace(
          "Submit",
          roles[obj.fk_Role],
          {
            firstName: obj.firstName,
            recepientDataSms,
          }
        );

        if (submitContent) {
          if (recepientDataSms.length > 0) {
            recepientsDataSms.push(submitContent);
          }

          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              recieverNumber: recepientDataWhatsApp,
              message: submitContent.message,
            });
          }
        }
      })
    );

    if (payloadTelSms.length > 0) {
      await post("/msgtwoway/telSms", payloadTelSms);
    }

    if (recepientsDataSms.length > 0) {
      await post("/msgtwoway/telSms", recepientsDataSms);
    }

    if (recepientsDataWhatsApp.length > 0) {
      await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
    }
  } catch (error) {
    console.error("An error occurred", error);
  }
};
