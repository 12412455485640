import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getUserRole,
  getUserId,
  getCandidateName,
  getUserTenant,
  isCurator,
  getOwner,
  getActualRole,
  getTenantType,
  loginUserName,
  getTenantLogo,
} from "../../service/AuthService";
// import { template, jobDetail } from "../../actions/job/AddJob";
import SidebarTop from "./sidenavbar/SidebarTop";
import SidebarMenu from "./sidenavbar/SidebarMenu";
import ModalWrapper from "./sidenavbar/ModalWrapper";

function SideNavBar(props) {
  let path = window.location.href.split("/").slice(-1)[0];

  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tenant: "",
    userid: "",
  });
  const { user, role, tenant } = userdetails;
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [showAddTalentModal, setShowAddTalentModal] = useState(false);
  const [talentPoolModal, setTalentPoolModal] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });
  // let history = useHistory();
  const [addJobNewModal, setAddJobNewModal] = useState(false);
  // const tenantType = getTenantType();
  const dispatch = useDispatch();

  const closeAddJobSucces = () => {
    setSuccessFormOpen(false);
  };

  const closeAddJobModal = () => {
    // dispatch(template(false));
    // dispatch(jobDetail(null));
    setAddJobNewModal(false);
  };

  useEffect(() => {
    const username = getCandidateName();
    const userrole = getUserRole();
    const iduser = getUserId();
    const userTenant = getUserTenant();

    if (username && iduser && userrole) {
      setUser({
        user: username,
        role: userrole,
        userid: props.userid,
        tenant: userTenant,
      });
    }
  }, []);

  return (
    <>
      <div id="Nav">
        <SidebarTop user={user} role={role} tenant={tenant} />
        <SidebarMenu
          path={path}
          role={role}
          setShowAddTalentModal={setShowAddTalentModal}
          setAddJobNewModal={setAddJobNewModal}
        />
      </div>
      <ModalWrapper
        successFormOpen={successFormOpen}
        closeAddJobSucces={closeAddJobSucces}
        successData={successData}
        addJobNewModal={addJobNewModal}
        closeAddJobModal={closeAddJobModal}
        showAddTalentModal={showAddTalentModal}
        setShowAddTalentModal={setShowAddTalentModal}
        talentPoolModal={talentPoolModal}
        setTalentPoolModal={setTalentPoolModal}
        setSuccessData={setSuccessData}
        setSuccessFormOpen={setSuccessFormOpen}
      />
    </>
  );
}

export default SideNavBar;
