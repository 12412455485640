import React, { useState, useEffect } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { post } from "../../service/ApiService";
import { googleApiKey } from "../../constants/Constants";

const hereMapAPIKey = "DZm9Ia3wXJqJ5I34OnYcH4p_r1G3uOuvnHfTb5QsQwo";
const hereMapAutoCompleteURL =
  "https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json";
//const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";
function PreferredLocation(props) {
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestObj, setSuggestObj] = useState([]);

  const fetchCountries = async (inputValue) => {
    try {
      const response = await post(`/jobfilterData/googleCities`, {
        text: inputValue ?? "",
        key: googleApiKey,
      });

      const suggestData = response.data.map((val) => {
        return val.cityName;
      });
      setSuggestObj(suggestData);
      setAutoCompleteData(suggestData.map((item) => ({ name: item })));

      // const cities = response.data.map((prediction) => ({
      //   label: prediction,
      //   value: prediction,
      // }));
      // setOptions(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
    }
  };

  const theme = {
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      width: "100%",
      border: "1px solid #aaa",
      listStyle: "none",
      zIndex: 10,
      backgroundColor: "rgb(255, 255, 255)",
      fontSize: 14,
      fontFamily: "sans-serif",
      maxHeight: "250px",
      overflow: "auto",
      padding: "5px 15px",
    },
    suggestionsList: {
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "5px 0px",
    },
    suggestionHighlighted: {
      backgroundColor: "rgba(114, 112, 112, 0.125)",
    },
    input: {
      border: "1px solid #ced4da",
      padding: "1rem .75rem",
      height: "calc(3.5rem + 2px)",
      borderRadius: "5px",
    },
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const suggestValues =
      inputLength === 0
        ? []
        : autoCompleteData.filter((item) => {
            return item?.name?.toLowerCase().includes(inputValue);
          });

    return suggestValues;
  };

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const inputProps = {
    placeholder: "Enter location",
    value,
    onChange,
  };

  const params = {
    apiKey: hereMapAPIKey,
    query: value,
    maxresults: 10,
  };

  useEffect(() => {
    if (value.length >= 3) {
      fetchCountries(value);

      // axios.get(hereMapAutoCompleteURL, { params }).then(function(response) {
      //   const suggestData = response.data.suggestions.filter(
      //     (i) => i.address.city
      //   );
      //   setSuggestObj(suggestData);
      //   setAutoCompleteData(suggestData.map((item) => ({ name: item.label })));
      // });
      fetchCountries(value);

      // axios.get(hereMapAutoCompleteURL, { params }).then(function(response) {
      //   const suggestData = response.data.suggestions.filter(
      //     (i) => i.address.city
      //   );
      //   setSuggestObj(suggestData);
      //   setAutoCompleteData(suggestData.map((item) => ({ name: item.label })));
      // });
    }
  }, [value]);

  const setSuggestFinalValue = (data, { suggestionIndex }) => {
    // let obj = suggestObj[suggestionIndex].address.country;
    let obj = suggestObj[suggestionIndex];
    props.setLocation(obj);
    setValue("");
  };

  return (
    <>
      <div className="card card-flat card-borderless bg-gray4 mb-0">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="form-floating"
                style={{
                  fontSize: "15px",
                  border:
                    props.isFrom === "JoinCommunity"
                      ? props.isFormValid &&
                        props.preferredLocation == "" &&
                        "2px solid #ff0000"
                      : props.validation && "2px solid #ff0000",
                  borderRadius: "5px",
                }}
              >
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  theme={theme}
                  onSuggestionSelected={setSuggestFinalValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreferredLocation;
