import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import Loader from "../../loader/Loader";
import EmailPreference from "../../common/preferences/EmailPreference";

const Communication = ({ data, ip, browserName }) => {
  const curDate = new Date();
  const [loading, setLoading] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  //email preference

  const REASON_LIST = {
    noWish: "I no longer wish to receive these emails",
    notRelavent: "These emails are not relavent to me",
    neverSigned: "I never signed up for this mailing list",
  };

  const initialReason = {
    noWish: false,
    notRelavent: false,
    neverSigned: false,
    other: false,
  };
  const [reason, setReason] = useState({
    noWish: true,
    notRelavent: false,
    neverSigned: false,
    other: false,
  });
  const [commentText, setCommentText] = useState("");
  const handleReason = (e) => {
    let p = { ...initialReason, [e.target.name]: true };
    setReason(p);
  };
  const initialPref = {
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  };
  const [preference, setPreference] = useState({
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  });

  const [notificationType, setNotificationType] = useState({
    //realTime: true,
    daily: true,
    weekly: false,
  });

  const [selectedNotificationType, setSelectedNotificationType] =
    useState("daily");

  const [scheduleSubDate, setScheduleSubDate] = useState(
    moment(new Date(curDate.setDate(curDate.getDate() + 1))).format(
      "YYYY-MM-DD"
    )
  );
  const [status, setStatus] = useState(false);

  const findStatus = async () => {
    try {
      setLoading(true);
      const res = await post("/inviteunsubscribed/fetchStatus", {
        email: data?.email,
      });

      if (res.data.status === "Unsubscribed") {
        setStatus(false);
        setPreference({ ...preference, unsubscribe: true });
      } else {
        setStatus(true);
        if (data?.notificationType) {
          let tempType = { ...notificationType };

          if (data?.notificationType === "weekly") {
            tempType["weekly"] = true;
            //tempType["realTime"] = false;
            tempType["daily"] = false;
          }
          if (data?.notificationType === "daily") {
            tempType["weekly"] = false;
            //tempType["realTime"] = false;
            tempType["daily"] = true;
          }

          setNotificationType(tempType);
        }
        let temp = { ...preference };
        temp["subscribe"] = true;
        temp["unsubscribe"] = false;
        temp["scheduleSub"] = false;
        setPreference(temp);
      }

      res.data.status === "Unsubscribed" &&
        setPreference({ ...preference, subscribe: false });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    findStatus();
  }, []);

  const done = async (type) => {
    if (type === "emailPreference") {
      if (
        !notificationType.daily &&
        !notificationType.weekly &&
        preference.subscribe
      ) {
        toast.error("Select a notification type");
      } else if (preference.scheduleSub && !scheduleSubDate) {
        // Check if scheduleSub is true but scheduleSubDate is not set
        toast.error("Please select a date");
      } else {
        setLoading(true);
        let payload = preference.subscribe
          ? {
              email: data?.email,
              notificationType: selectedNotificationType,
              subscribe: preference.subscribe,
            }
          : {
              email: data?.email,
              unsubscribedReason: reason.other
                ? commentText
                : reason.noWish
                ? REASON_LIST["noWish"]
                : reason.notRelavent
                ? REASON_LIST["notRelavent"]
                : REASON_LIST["neverSigned"],
            };

        if (preference.scheduleSub) {
          payload["subscriptionDate"] = scheduleSubDate;
          payload["scheduleSub"] = preference.scheduleSub;
        }
        const res = await post(
          "/inviteunsubscribed/updateEmailPreference",
          payload
        );
        setLoading(false);
        setEditEmail(false);
        toast.success(`Preference updated successfully`);
      }
    }
  };

  const onClickEmailEdit = () => {
    setEditEmail(true);
  };

  //Email Preference
  const handlePreference = (e) => {
    let p = { ...initialPref, [e.target.name]: true };
    setPreference(p);
  };

  const handleNotificationType = (e) => {
    let temp = { ...notificationType };

    if (e.target.name === "daily") {
      temp["daily"] = true;
      temp["weekly"] = false;
    }
    if (e.target.name === "weekly") {
      temp["daily"] = false;
      temp["weekly"] = true;
    }
    setNotificationType(temp);
    setSelectedNotificationType(e.target.name);
  };
  return (
    <div>
      {loading && <Loader />}
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Communication Preference</h6>
            {!editEmail ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={onClickEmailEdit}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={() => setEditEmail(false)}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => done("emailPreference")}
                  class="btn btn-sm btn-primary"
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <hr />
          <EmailPreference
            preference={preference}
            handlePreference={handlePreference}
            scheduleSubDate={scheduleSubDate}
            setScheduleSubDate={setScheduleSubDate}
            status={status}
            notificationType={notificationType}
            handleNotificationType={handleNotificationType}
            editEmail={editEmail}
            handleReason={handleReason}
            reason={reason}
            commentText={commentText}
            setCommentText={setCommentText}
          />
        </div>
      </div>
    </div>
  );
};

export default Communication;
