import React, { useEffect, useState } from "react";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";

function ReadyToHireQA({
  edit,
  readyToHireQAList,
  setReadyToHireQAList,
}) {
  const [displayScreening, setDisplayScreening] = useState(true);

  const handleAddQuestion = (event) => {
    if (
      readyToHireQAList.length === 0 ||
      (readyToHireQAList.length < 5 &&
        readyToHireQAList[readyToHireQAList.length - 1].question.trim() !== "")
    ) {
      setReadyToHireQAList((prevState) => [...prevState, { question: "", responseType: "Yes/No" }]);
    } else {
      toast.error("Please enter a question");
    }
  };

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...readyToHireQAList];
    onChangeValue[index] = { ...onChangeValue[index], [name]: value };
    setReadyToHireQAList(onChangeValue);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = [...readyToHireQAList];
    updatedQuestions.splice(index, 1);
    setReadyToHireQAList(updatedQuestions);
  };

  return (
    <>
      <div className="card-body" style={{ pointerEvents: `${edit?'none':''}` }}>
        <div className="mx-4">Add question(s) for Ready To Hire Screening.</div>
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            {
              <div className={displayScreening ? "ml-2 mb-4" : "d-none"}>
                <div className=" bg-gray4 mt-3">
                  <div className="">
                    <div className="px-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="mb-1">
                          <h6>Ready To Hire Screening Question(s)</h6>
                          <small className="mb-2">
                            - Select up to five questions that candidates must
                            answer.
                          </small>
                          {/* <small>Only</small> */}
                        </div>
                        <hr />
                      </div>

                      <div className="mt-3">
                        <div className="m-1">Question(s)*: </div>
                        {readyToHireQAList?.map((item, index) => (
                          <div className="card card-flat m-2" key={index}>
                            <div className={"card-body"}>
                              {
                                <div
                                  className="row"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="col-lg-8">
                                    <div className="form-floating mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Type Question"
                                        name="question"
                                        value={item.question}
                                        onChange={(e) => handleChange(e, index)}
                                      />
                                      <label>Question</label>
                                    </div>
                                  </div>
                                  <div className="col-lg-3 ">
                                    <div className="form-floating mb-2">
                                      <select
                                        className="form-select font-14"
                                        name="responseType"
                                        value={item.responseType}
                                        onChange={(e) => handleChange(e, index)}
                                      >
                                        <option value="Yes/No">Yes/No</option>
                                        <option value="Text">Text</option>
                                        <option value="Numeric">Numeric</option>
                                      </select>
                                      <label>Response Type</label>
                                    </div>
                                  </div>
                                  <div className="col-lg-1">
                                    <div className="d-flex">
                                      <i
                                        onClick={() => {
                                          handleDeleteQuestion(index);
                                        }}
                                        className="fal fa-fw fa-trash-alt"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        ))}
                      </div>

                      {readyToHireQAList?.length > 0 && (
                        <hr className="m-2"></hr>
                      )}

                      <div>
                        <button
                          className="btn btn-sm btn-primary m-1"
                          onClick={(e) => handleAddQuestion(e)}
                          disabled={
                            readyToHireQAList?.length === 5 ? true : false
                          }
                        >
                          <i className="fas fa-plus mr-2"></i>
                          Add Question
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default ReadyToHireQA;
