import React from "react";

const JobTitles = ({ jobTitle, jobTitles, onCollapsibleClick }) => {
  return (
    <>
      {jobTitles &&
        ((typeof jobTitles.title1 === "string" &&
          jobTitles.title1.trim().length > 0) ||
          (typeof jobTitles.title2 === "string" &&
            jobTitles.title2.trim().length > 0) ||
          (typeof jobTitles.title3 === "string" &&
            jobTitles.title3.trim().length > 0)) && (
          <button
            onClick={() => {
              onCollapsibleClick("jobTitle");
            }}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            {!jobTitle ? (
              <i className="fal fa-angle-up fa-fw mt-1"></i>
            ) : (
              <i className="fal fa-angle-down fa-fw"></i>
            )}
            <span>
              <small>Job Title</small>
            </span>
          </button>
        )}
      {jobTitles &&
        ((typeof jobTitles.title1 === "string" &&
          jobTitles.title1.trim().length > 0) ||
          (typeof jobTitles.title2 === "string" &&
            jobTitles.title2.trim().length > 0) ||
          (typeof jobTitles.title3 === "string" &&
            jobTitles.title3.trim().length > 0)) && (
          <div className={jobTitle ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div className="d-flex mb-2 pl-1 ml-4">
              <div>
                {typeof jobTitles.title1 === "string" &&
                  jobTitles.title1.trim().length > 0 && (
                    <div className="mb-1">
                      <span>
                        {((typeof jobTitles.title2 === "string" &&
                          jobTitles.title2.trim().length > 0) ||
                          (typeof jobTitles.title3 === "string" &&
                            jobTitles.title3.trim().length > 0)) &&
                          "1: "}
                        {jobTitles.title1.trim()}
                      </span>
                    </div>
                  )}
                {typeof jobTitles.title2 === "string" &&
                  jobTitles.title2.trim().length > 0 && (
                    <div className="mb-1">
                      <span>2: {jobTitles.title2.trim()}</span>
                    </div>
                  )}
                {typeof jobTitles.title3 === "string" &&
                  jobTitles.title3.trim().length > 0 && (
                    <div className="mb-1">
                      <span>3: {jobTitles.title3.trim()}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default JobTitles;
