import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../loader/Loader";

const SkillMatrix = ({ job, skillMatrix, setSkillMatrix, isFormValid }) => {
  const theme = {
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      width: "100%",
      border: "1px solid #aaa",
      listStyle: "none",
      zIndex: 999,
      backgroundColor: "rgb(255, 255, 255)",
      fontSize: 14,
      fontFamily: "sans-serif",
      maxHeight: "180px",
      overflow: "scroll",
      padding: "5px 15px",
    },
    suggestionsList: {
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "5px 0px",
    },
    suggestionHighlighted: {
      backgroundColor: "rgba(114, 112, 112, 0.125)",
    },
  };
  const [loading, setLoading] = useState(false);
  const [apiCounter, setApiCounter] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchSuggestions = async (skillinput) => {
    try {
      setLoading(true);
      setApiCounter((prev) => prev + 1);
      let searchResp = await post("/searchSkills/search", { text: skillinput });
      if (searchResp.status === 200) {
        let allSkills = searchResp?.data;
        setSuggestions(allSkills);
        setApiCounter((prev) => prev - 1);
      }
    } catch (error) {
      setApiCounter((prev) => prev - 1);
      setSuggestions([]);
    }
  };
  useEffect(() => {
    if (apiCounter === 0) setLoading(false);
  }, [apiCounter]);

  const onSuggestionSelected = (index, { suggestion }) => {
    const updatedMatrix = [...skillMatrix];
    updatedMatrix[index] = {
      ...updatedMatrix[index],
      skill: suggestion,
      selected: true,
    };
    setSkillMatrix(updatedMatrix);
  };

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (inputValue?.length > 1) fetchSuggestions(inputValue);
    }, delay);

    return () => clearTimeout(debounceTimer);
  }, [inputValue]);

  useEffect(() => {
    if (job?.weightage) {
      let x = JSON.parse(job?.weightage);
      let s = x?.primarySkills.flat(1);
      let sm = s?.map((sk) => ({
        skill: sk,
        competency: "Beginner",
        experience: 0,
        custom: false,
        selected: false,
      }));
      setSkillMatrix(sm);
    }
  }, [job]);

  const addSkill = () => {
    setInputValue("");
    if (skillMatrix)
      setSkillMatrix([
        ...skillMatrix,
        {
          skill: " none ",
          competency: "Beginner",
          experience: 0,
          custom: true,
        },
      ]);
  };

  const handleChange = (e, index) => {
    if (e.target.value > 99) return toast.error("Invalid value");
    if (isNaN(e.target.value)) return;
    const updatedMatrix = [...skillMatrix];
    updatedMatrix[index][e.target.name] = e.target.value;
    setSkillMatrix(updatedMatrix);
  };
  const handleChangeSkill = (e, index) => {
    setInputValue(e.target.value);
  };

  const removeSkill = (index) => {
    const updatedMatrix = skillMatrix.filter((x, i) => index !== i);
    setSkillMatrix(updatedMatrix);
  };

  return (
    <div class="pt-0 mb-3 ">
      {loading && <Loader />}
      <table class="table">
        <thead>
          <tr>
            <th className=" " scope="col">
              <span>Skill</span>
            </th>
            <th className="text-center" scope="col">
              <span>Years of experience</span>
            </th>
            <th className="text-center" scope="col">
              <span></span>
            </th>
          </tr>
        </thead>

        <tbody>
          {skillMatrix.map((item, index) => (
            <tr key={index}>
              <td className="col-6">
                {item.custom && !item.selected ? (
                  <div
                    className="form-floating"
                    style={{ position: "relative" }}
                  >
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={() => {}}
                      onSuggestionsClearRequested={() => {}}
                      getSuggestionValue={(suggestion) => suggestion}
                      renderSuggestion={(suggestion) => <div>{suggestion}</div>}
                      inputProps={{
                        placeholder: "Select a skill",
                        value: inputValue,
                        name: "skill",
                        onChange: (e) => handleChangeSkill(e, index),
                        className: "form-search",
                        style: { borderColor: " #ced4da" },
                      }}
                      theme={theme}
                      onSuggestionSelected={(e, { suggestion }) =>
                        onSuggestionSelected(index, { suggestion })
                      }
                    />
                  </div>
                ) : (
                  <span>{item.skill}</span>
                )}
              </td>
              <td className="col-5 pl-4 text-center">
                <input
                  style={{
                    width: "130px",
                    margin: "auto",
                    border:
                      isFormValid &&
                      skillMatrix[index].experience === "" &&
                      "2px solid #ff0000",
                  }}
                  value={skillMatrix[index].experience}
                  type="number"
                  max={60}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    const numericValue = parseInt(inputValue, 10);
                    const sanitizedValue = isNaN(numericValue)
                      ? 0
                      : Math.min(numericValue, 60);
                    e.target.value = sanitizedValue;
                    handleChange(e, index);
                  }}
                  name="experience"
                  min={0}
                  className="form-control"
                />
              </td>
              <td className="col-1 pl-4 text-center">
                {item.custom && (
                  <button
                    type="button"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete Skill"
                    class="btn btn-sm btn-text ml-0 font-22 font-black text-black"
                    onClick={(e) => removeSkill(index)}
                  >
                    <i
                      class="fal fa-fw fa-trash-alt"
                      style={{ color: "red" }}
                    ></i>
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-2">
        <button
          type="button"
          className="btn btn-sm btn-text ml-0"
          onClick={addSkill}
          disabled={
            skillMatrix[skillMatrix?.length - 1]?.skill?.trim() === "none"
          }
        >
          <i className="fal fa-plus mr-1" aria-hidden="true"></i>
          <strong>Add Skill</strong>
        </button>
      </div>
    </div>
  );
};

export default SkillMatrix;
