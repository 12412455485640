import React, { useEffect, useState } from "react";
import { AiOutlineLine } from "react-icons/ai";
import { BsFillCircleFill } from "react-icons/bs";
import { domain } from "../../../../constants/Constants";
import InterviewPlan from "../vettingplans/index";
import JobDetails from "../jobinfo/index";
import Note from "../notes/index";
import SubmittalDetailsInfo from "../submittalsdetails/index";
import UploadDocument from "../documents/index";
import { toast } from "react-toastify";
import { get, post } from "../../../../service/ApiService";
import { getJobList } from "../../../../action/job/Joblist";
import { getJobListCount } from "../../../../action/job/JoblistHeaderCounts";
import moment from "moment";
import {
  getUserTenant,
  getUserTenantID,
  getUserRole,
  getCandidateName,
} from "../../../../service/AuthService";
import RichTextEditor from "react-rte";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EraseDataModal from "./EraseDataModal";
import Loader from "../../../common/loader/Loader";
import ShortUniqueId from "short-unique-id";
import FetchJobFromJobDiva from "./FetchJobFromJobDiva";
import { googleApiKey } from "../../../../constants/Constants";

//const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";
const uid = new ShortUniqueId({ length: 10 });

const difficultyData = [
  { exp: "Intern", val: "Simple" },
  { exp: "Less than one year", val: "Simple" },
  { exp: "1-3 years", val: "Medium" },
  { exp: "3-5 years", val: "Medium" },
  { exp: "5-10 years", val: "Advanced" },
  { exp: "10+ years", val: "Advanced" },
];

const getLongLatFromCity = async (city, state, country) => {
  if (city || state || country) {
    try {
      const address = `${city}, ${state}, ${country}`;
      const encodedAddress = encodeURIComponent(address);

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleApiKey}`
      );

      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { longitude: location.lng, latitude: location.lat };
      } else {
        throw new Error(
          "Unable to retrieve longitude and latitude for the given address"
        );
      }
    } catch (error) {
      console.error("Error fetching longitude and latitude:", error);
      throw error;
    }
  } else {
    return { longitude: "", latitude: "" };
  }
};

const AddJobDetails = ({
  closePanel,
  setSuccessData,
  setSuccessFormOpen,
  edit,
  editJobData,
  addTemplate,
  fetchTemplate,
  editTemplate,
  tempDetails,
  templateSelected,
  refreshJobDetails,
  isFrom,
  getAllJobs,
  setLoadingPC,
  setSelectedJobId,
  chooseTemplateDetails,
  reopen,
}) => {
  const { chooseJobDetails, template, templateDetail } = useSelector(
    (state) => ({
      chooseJobDetails: state.AddJob?.JobData,
      template: state.AddJob?.templateSelected,
      templateDetail: state.AddJob?.templateDetails,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const today = new Date();
  const getTodayDate = moment();
  let todayDate = getTodayDate.format("MM/DD/YYYY");
  const [tabList, setTabList] = useState({
    "Job Info": false,
    Screening: false,
    "Submittal Details": false,
    Documents: false,
    Notes: false,
  });
  const [radius, setRadius] = useState("10"); // Step 1: State variable to hold selected radius
  const [primarySkillInput, setPrimarySkillInput] = useState("");
  const [secondarySkillInput, setSecondarySkillInput] = useState("");
  const [checkedAssessments, setCheckedAssessments] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [jobDivaId, setJobDivaId] = useState("");
  const [jobType, setJobType] = useState("");
  const [templates, setTemplates] = useState([]);
  const [showConfirmJobType, setShowConfirmJobType] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({});
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [typeSelected, setTypeSelected] = useState(false);
  const [documentName, setDocumentName] = useState([]);
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [overWrite, setOverWrite] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [notesDetails, setNotesDetails] = useState([]);
  const [isRequired, setIsRequired] = useState(false);
  // const [vettingToSend, setVettingToSend] = useState([]);
  const [screeningQuestions, setScreeningQuestions] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [addJobType, setAddJobType] = useState({
    typeSelected: false,
    fulltimeSelected: false,
    contractSelected: false,
    partTimeSelected: false,
    tempToHireSelected: false,
    volunteerSelected: false,
    disableSubmit: true,
  });
  const [templateCategory, setTemplateCategory] = useState(
    "Information Technology"
  );
  const skillSetOption = [
    { value: "Accounting/Finance", label: "Accounting/Finance" },
    {
      value: "Administrative/Clerical",
      label: "Administrative/Clerical",
    },
    { value: "Business Professional", label: "Business Professional" },
    { value: "Call Center", label: "Call Center" },
    { value: "Clinical", label: "Clinical" },
    { value: "Communications", label: "Communications" },
    { value: "Creative", label: "Creative" },
    { value: "Engineering", label: "Engineering" },
    { value: "Hardware", label: "Hardware" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Legal", label: "Legal" },
    { value: "Marketing", label: "Marketing" },
    { value: "Medical", label: "Medical" },
    { value: "Operations", label: "Operations" },
    { value: "Procurement", label: "Procurement" },
    { value: "Professional", label: "Professional" },
    { value: "R&D", label: "R&D" },
    { value: "Sales", label: "Sales" },
    { value: "Scientific", label: "Scientific" },
    { value: "Technical (Non-IT)", label: "Technical (Non-IT)" },
    { value: "Technical Support", label: "Technical Support" },
  ];
  const initialJobData = {
    type: "",
    title: "",
    startDate: new Date(today.setDate(today.getDate() + 14)),
    companyName: "",
    companyJobId: "",
    hiringManagerId: "",
    isRemote: true,
    isHotJob: false,
    isFeePercentage: false,
    isPublic: true,
    isFexible: true,
    location: {
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
    numberOfPositions: 1,
    allowedSubmittals: 5,
    salary: "",
    minMaxCurrency: "USD",
    activeFrom: new Date(),
    expiresOn: new Date(new Date().setMonth(new Date().getMonth() + 2)),
    placementFee: "",
    placementCurrency: "USD",
    openForReferral: false,
    editPreferred: "",
    completionDate: "",
    durationMonths: 1,
    durationDays: 0,
    hourlyRate: "",
    hourlyRateCurrency: "USD",
    budget: "",
    referralFee: "",
    referralCurrency: "USD",
    submittal: "",
    description: "",
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    subCategory: [],
    visaStatus: [],
    education: [],
    certifications: [],
    industries: [],
    tierData: {},
    workStart: "",
    workEnd: "",
    travel: "",
    drugTest: false,
    backgroundCheck: false,
    securityClearance: "",
    fileName: "",
    domainName: domain,
    tenantName: "",
    visaRequirement: [],
    licenceRequirement: [],
    experienceLevel: "",
    minimumPay: "",
    maximumPay: "",
    minMaxPaymentType: "Annual",
    workPlaceType: "Remote",
    tenantId: getUserTenantID(),
    onsiteWorkDays: "",
    vettingRequired: false,
    screeningRequired: false,
    vettingDetails: [],
    preferredLocation: [],
    isGlobal: false,
    publishJob: "public",
    weightage: {
      primarySkills: [],
      secondarySkills: [],
      jobTitle: false,
      location: false,
      experienceLevel: false,
      education: [],
      industries: [],
    },
  };

  const [addJobData, setAddJobData] = useState(initialJobData);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [notesValue, setNotesValue] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({
    template: {},
    isTemplate: false,
  });
  const [dropdownList, setDropDown] = useState({});
  const [preVettingSkills, setPrevettingSkills] = useState([]);
  const [preVettingRequired, setPrevettingRequired] = useState(false);
  const [screeningRequired, setScreeningRequired] = useState(false);
  const [vettingCheck, setVettingCheck] = useState([]);

  let { Documents, Notes } = tabList;

  let jobCategoryOptions =
    Object.keys(dropdownList).length > 0
      ? dropdownList.skillSet.map((item) => {
          const skillSetObj = {
            value: item.PositionName,
            label: item.PositionName,
          };
          return skillSetObj;
        })
      : [];

  const [addJobVal, setAddJobVal] = useState({
    template: "",
    minimumPay: "",
    maximumPay: "",
    title: "",
    primary: "",
    preferredLocation: "",
    placement: "",
    activeFromError: "",
    startDateError: "",
    expiresOnError: "",
    company: "",
    skillSet: "",
    location: {
      city: "",
      state: "",
      country: "",
      address: "",
      zipCode: "",
    },
    description: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  //VettingRequirement States
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedTestCategory, setSelectedTestCategory] = useState({});
  const [selectedDifficulty, setSelectedDifficulty] = useState({});
  const [selectedDuration, setSelectedDuration] = useState({});
  const [assessmentList, setAssessmentList] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [difficulty, setDifficulty] = useState("");

  const QualificationType =
    Object.keys(dropdownList).length > 0
      ? dropdownList.QualificationType.filter(
          (item) => item.PositionName !== "Select"
        )?.map((item) => {
          const QualificationObj = {
            value: item.PositionName,
            label: item.PositionName,
          };
          return QualificationObj;
        })
      : [];

  useEffect(() => {
    if (edit || reopen) {
      setEditJobDetails();
    } else if (chooseJobDetails !== null) {
      // getDetailsForExistingJob();
    } else if (templateDetail !== null) {
      getDetailsForJobTemplate(templateDetail);
    } else if (editTemplate) {
      getDetailsForJobTemplate(tempDetails);
    }
    fetchData();
  }, [chooseJobDetails]);

  useEffect(() => {
    let skills = addJobData?.vettingDetails?.skills || [];
    checkedAssessments.forEach((s) => {
      if (!skills.includes(s)) skills.push(s);
    });
    setCheckedAssessments(skills);
  }, [addJobData]);

  useEffect(() => {
    if (addTemplate) {
      setTabList({
        "Job Info": false,
        Screening: false,
        "Submittal Details": false,
        Documents: false,
        Notes: false,
      });
    } else if (editTemplate) {
      setTabList({
        "Job Info": true,
        Screening: false,
        "Submittal Details": false,
        Documents: false,
        Notes: false,
      });
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await get(`/job/dropdownvalues`, { domainName: domain });
      setDropDown(res.data[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setEditJobDetails = () => {
    let {
      jobType,
      jobTitle,
      contractDurationDays,
      contractDurationMonths,
      screeningQuestions,
      documents,
      industries,
      notes,
      jobDescription,
      prefferedStartDate,
      positionCount,
      isFeePercentage,
      experienceLevel,
      placementFee,
      referralBonusFee,
      referralBonusCurrency,
      weightage,
      vettingRequired,
      screeningRequired,
      licenceRequirement,
      visaRequirement,
    } = editJobData;
    // setTemplateSelected(true);
    let primarySkills = editJobData.primarySkills
      ? JSON.parse(editJobData.primarySkills)
      : [];
    let preferredLocation = editJobData.preferredLocation
      ? JSON.parse(editJobData.preferredLocation)
      : [];
    let visaStatus = editJobData.visaStatus
      ? JSON.parse(editJobData.visaStatus)
      : [];
    let secondarySkills = editJobData.goodToHaveSkills
      ? JSON.parse(editJobData?.goodToHaveSkills)
      : [];

    let education = editJobData.education
      ? JSON.parse(editJobData?.education)
      : [];
    let skillSet = editJobData.skillSet
      ? JSON.parse(editJobData?.skillSet)
      : [];
    let subCategory = editJobData.subCategory
      ? JSON.parse(editJobData?.subCategory)
      : [];
    let vettingDetails = editJobData.vettingDetails
      ? JSON.parse(editJobData?.vettingDetails)
      : [];
    let location = {
      address: editJobData?.addJobData,
      city: editJobData?.city,
      state: editJobData?.state,
      country: editJobData?.country,
      zipCode: editJobData?.zipCode,
    };
    onTypeClick(jobType);
    setAddJobData({
      ...editJobData,
      title: jobTitle,
      type: jobType,
      secondarySkills,
      skillSet,
      subCategory,
      primarySkills,
      preferredLocation,
      visaStatus,
      education,
      experienceLevel,
      startDate: prefferedStartDate,
      numberOfPositions: positionCount,
      durationDays: contractDurationDays,
      durationMonths: contractDurationMonths,
      placementFee: placementFee,
      referralFee: referralBonusFee,
      referralCurrency: referralBonusCurrency,
      description: jobDescription,
      vettingRequired: vettingRequired ? true : false,
      screeningRequired: screeningRequired ? true : false,
      vettingDetails: vettingDetails,
      publishJob: editJobData.isPublishedForRecruiter ? "internal" : "public",
      weightage:
        weightage === null
          ? {
              primarySkills,
              jobTitle: false,
              location: false,
              experienceLevel: false,
              education,
              industries,
            }
          : JSON.parse(weightage),
      location,
      licenceRequirement: licenceRequirement
        ? licenceRequirement.charAt(0) === "["
          ? JSON.parse(licenceRequirement)
          : [licenceRequirement]
        : [],
      visaRequirement: visaRequirement
        ? visaRequirement.charAt(0) === "["
          ? JSON.parse(visaRequirement)
          : [visaRequirement]
        : [],
    });
    if (screeningQuestions) {
      let QnA = JSON.parse(screeningQuestions);
      if (QnA?.map((i) => i.name).includes("Language")) {
        let language = QnA.filter((i) => i.name === "Language")[0];
        setSelectedLanguages(JSON.parse(language.input));
      }
      setScreeningQuestions(QnA);
    }
    if (editJobData?.documentName) {
      setDocumentName([
        {
          documentName: editJobData.documentName,
          base64: "",
          category: "Job Description",
          uploadedDate: todayDate,
          uploadedBy: getCandidateName(),
          role: getUserRole(),
        },
      ]);
    } else {
      setDocumentName([]);
    }

    if (jobDescription) {
      setValue(RichTextEditor.createValueFromString(jobDescription, "html"));
    }
    if (notes) {
      setNotesDetails(JSON.parse(notes));
    }
  };

  const getDetailsForExistingJob = () => {
    let {
      jobType,
      jobTitle,
      screeningQuestions,
      jobDescription,
      notes,
      positionCount,
      placementFee,
      weightage,
      vettingRequired,
      screeningRequired,
      licenceRequirement,
      visaRequirement,
    } = chooseJobDetails;
    let education = JSON.parse(chooseJobDetails?.education);
    let primarySkills = JSON.parse(chooseJobDetails?.primarySkills);
    let preferredLocation = chooseJobDetails?.preferredLocation
      ? JSON.parse(chooseJobDetails?.preferredLocation)
      : null;

    let visaStatus = chooseJobDetails?.visaStatus
      ? JSON.parse(chooseJobDetails?.visaStatus)
      : [];
    let skillSet =
      chooseJobDetails?.skillSet == null
        ? []
        : JSON.parse(chooseJobDetails?.skillSet);
    let vettingDetails =
      Array.isArray(chooseJobDetails?.vettingDetails) ||
      !chooseJobDetails?.vettingDetails
        ? []
        : JSON.parse(chooseJobDetails?.vettingDetails);
    let subCategory =
      chooseJobDetails?.subCategory == null
        ? []
        : JSON.parse(chooseJobDetails?.subCategory);
    let secondarySkills =
      chooseJobDetails?.goodToHaveSkills == (null || "[]")
        ? []
        : JSON.parse(chooseJobDetails?.goodToHaveSkills);
    let publishJob = chooseJobDetails?.isPublishedForRecruiter
      ? "internal"
      : "public";
    let location = {
      address: chooseJobDetails?.addJobData,
      city: chooseJobDetails?.city,
      state: chooseJobDetails?.state,
      country: chooseJobDetails?.country,
      zipCode: chooseJobDetails?.zipCode,
    };
    let industries =
      chooseJobDetails?.industries === null
        ? []
        : JSON.parse(chooseJobDetails.industries);
    onTypeClick(jobType);
    const finalData = {
      ...chooseJobDetails,
      education,
      primarySkills,
      preferredLocation,
      visaStatus,
      secondarySkills,
      skillSet,
      subCategory,
      openForReferral: chooseJobDetails?.openForReferral,
      location,
      industries,
      title: jobTitle,
      type: jobType,
      startDate: initialJobData.startDate,
      expiresOn: initialJobData.expiresOn,
      activeFrom: initialJobData.activeFrom,
      numberOfPositions: positionCount,
      durationDays: chooseJobDetails?.contractDurationDays,
      durationMonths: chooseJobDetails?.contractDurationMonths,
      placementFee: placementFee,
      referralFee: chooseJobDetails?.referralBonusFee,
      referralCurrency: chooseJobDetails?.referralBonusCurrency,
      description: jobDescription,
      drugTest: false,
      backgroundCheck: false,
      vettingRequired: vettingRequired ? true : false,
      screeningRequired: screeningRequired ? true : false,
      vettingDetails: vettingDetails,
      publishJob: publishJob,
      weightage:
        weightage === null
          ? {
              primarySkills,
              jobTitle: false,
              location: false,
              experienceLevel: false,
              education,
              industries,
            }
          : JSON.parse(weightage),
      companyJobId: "",
      companyName: "",
      location,
      licenceRequirement: licenceRequirement
        ? licenceRequirement.charAt(0) === "["
          ? JSON.parse(licenceRequirement)
          : [licenceRequirement]
        : [],
      visaRequirement: visaRequirement
        ? visaRequirement.charAt(0) === "["
          ? JSON.parse(visaRequirement)
          : [visaRequirement]
        : [],
    };
    setAddJobData(finalData);
    if (screeningQuestions) {
      let QnA = JSON.parse(screeningQuestions);
      if (QnA?.map((i) => i.name).includes("Language")) {
        let language = QnA.filter((i) => i.name === "Language")[0];
        setSelectedLanguages(JSON.parse(language.input));
      }
      setScreeningQuestions(QnA);
    }
    if (jobDescription) {
      setValue(RichTextEditor.createValueFromString(jobDescription, "html"));
    }
    if (notes) {
      setNotesDetails(JSON.parse(notes));
    }
  };

  const getDetailsForJobTemplate = (templateDetails) => {
    if (!editTemplate && Object.keys(templateDetails).length === 0) {
      toast.error("Please Choose a Template");
    } else {
      setTabList({ ...tabList, "Job Info": true });
      setTypeSelected(true);
      setTemplateName(templateDetails.templateName);
      let QnA = templateDetails?.QandA
        ? [...JSON.parse(templateDetails?.QandA)]
        : [];
      if (QnA?.map((i) => i.name).includes("Language")) {
        let language = QnA?.filter((i) => i.name === "Language")[0];
        setSelectedLanguages(JSON.parse(language.input));
      }
      if (templateDetails.jobType) {
        onTypeClick(templateDetails.jobType);
      } else {
        setAddJobType({
          ...addJobType,
          typeSelected: true,
          volunteerSelected: false,
          tempToHireSelected: false,
          partTimeSelected: false,
          fulltimeSelected: true,
          contractSelected: false,
        });
      }
      let primarySkills = templateDetails.primarySkills
        ? JSON.parse(templateDetails.primarySkills)
        : [];
      let preferredLocation = templateDetails.preferredLocation
        ? JSON.parse(templateDetails.preferredLocation)
        : [];
      let visaStatus = templateDetails?.visaStatus
        ? JSON.parse(templateDetails?.visaStatus)
        : [];
      let education = templateDetails.education
        ? JSON.parse(templateDetails.education)
        : [];
      let skillSet = templateDetails.skillSet
        ? JSON.parse(templateDetails.skillSet)
        : [];
      let subCategory =
        templateDetails?.subCategory == null
          ? []
          : JSON.parse(templateDetails?.subCategory);
      let jobData = {
        ...addJobData,
        ...templateDetails,
        primarySkills,
        preferredLocation,
        visaStatus,
        education,
        skillSet,
        subCategory,
        durationMonths: "1",
        companyName: getUserTenant(),
        startDate: initialJobData?.startDate,
        expiresOn: initialJobData?.expiresOn,
        activeFrom: initialJobData?.activeFrom,
        weightage: templateDetails?.weightage
          ? JSON.parse(templateDetails.weightage)
          : {
              primarySkills,
              secondarySkills,
              jobTitle: false,
              location: false,
              experienceLevel: false,
              education,
            },
      };
      setAddJobData(jobData);
      setTemplateCategory(templateDetails?.category);
      setScreeningQuestions(QnA);
      if (templateDetails.jobDescription) {
        setValue(
          RichTextEditor.createValueFromString(
            templateDetails?.jobDescription,
            "html"
          )
        );
      }
      if (templateDetails?.documentName) {
        setDocumentName([
          {
            documentName: templateDetails.documentName,
            base64: "",
            category: "Job Description",
            uploadedDate: todayDate,
            uploadedBy: getCandidateName(),
            role: getUserRole(),
          },
        ]);
      } else {
        setDocumentName([]);
      }
      //setTemplateSelected(true);
      let lastUsed = new Date();
      setSelectedTemplate({
        template: { ...templateDetails, lastUsed: lastUsed },
        isTemplate: true,
      });

      if (templateDetails?.notes) {
        setNotesDetails(JSON.parse(templateDetails?.notes));
      }
    }
  };

  const classes = (dynamic, classes = "") => {
    return Object.entries(dynamic)
      .filter((entry) => entry[1])
      ?.map((entry) => entry[0])
      .join(" ")
      .concat(" ")
      .concat(classes);
  };
  function containsAlphabet(str) {
    return /[a-zA-Z]/.test(str);
  }

  function isInteger(value) {
    if (typeof value === "string" && /^\d+$/.test(value)) {
      return true;
    }
    if (typeof value === "number" && Number.isInteger(value)) {
      return true;
    }
    return false;
  }

  const handleBackNext = (value) => {
    let val = false;
    if (value === "Next") {
      setIsFormValid(true);

      if (
        addJobData.title === "" &&
        addJobData.location.zipCode === "" &&
        addJobData.location.address === "" &&
        addJobData.location.city === "" &&
        addJobData.location.country === "" &&
        addJobData.location.state === "" &&
        addJobData.minimumPay === "" &&
        addJobData.maximumPay === "" &&
        addJobData.skillSet.length === 0 &&
        addJobData.industries.length === 0 &&
        addJobData.subCategory.length === 0 &&
        addJobData.experienceLevel === "" &&
        addJobData.education.length === 0 &&
        addJobData.description === "" &&
        // addJobData.expiresOn === "" &&
        addJobData.placementFee === ""
      ) {
        toast.error("Please fill all the mandatory fields");
        setIsFormValid(true);
        return;
      }
      // else if (addJobData.expiresOn === "") {
      //   toast.error("Please enter an expiry date");
      //   setIsFormValid(false);
      //   return;
      // }
      else if (addJobData.title === "") {
        toast.error("Please enter job title");
        setIsFormValid(true);
        return;
      } else if (!containsAlphabet(addJobData.title)) {
        toast.error(
          "Job title should include at least one alphabetical character."
        );
        setIsFormValid(true);
        return;
      } else if (addJobData.minimumPay === 0) {
        toast.error("Please enter minimum pay");
        setIsFormValid(true);
        return;
      } else if (
        addJobData.durationMonths > 99 ||
        addJobData.durationDays > 365
      ) {
        toast.error("Please enter valid contract duration");
        setIsFormValid(true);
        return;
      } else if (
        !isInteger(addJobData.durationMonths) ||
        !isInteger(addJobData.durationDays)
      ) {
        toast.error("Please enter valid contract duration");
        setIsFormValid(true);
        return;
      } else if (addJobData.education === "") {
        toast.error("Please enter education");
        setIsFormValid(true);
        return;
      } else if (addJobData.experienceLevel === "") {
        toast.error("Please enter experience level");
        setIsFormValid(true);
        return;
      } else if (addJobData.maximumPay === 0) {
        toast.error("Please enter maximum pay");
        setIsFormValid(true);
        return;
      } else if (addJobData.primarySkills.length === 0) {
        toast.error("Please enter primary skills");
        setIsFormValid(true);
        return;
      } else if (addJobData.visaStatus.length === 0) {
        toast.error("Please enter visa status");
        setIsFormValid(true);
        return;
      } else if (addJobData.skillSet.length === 0) {
        toast.error("Please enter industries");
        setIsFormValid(true);
        return;
      } else if (addJobData.subCategory.length === 0) {
        toast.error("Please enter subcategory");
        setIsFormValid(true);
        return;
      } else if (addJobData.description === "") {
        toast.error("Please enter the description for job");
        setIsFormValid(true);
        return;
      } else if (addJobData.placementFee === "") {
        toast.error("Please enter the placement fee for job");
        setIsFormValid(true);
        return;
      } else if (
        (addJobData.workPlaceType === "Hybrid" ||
          addJobData.workPlaceType === "On-Site") &&
        addJobData.location.city === ""
      ) {
        toast.error("Please enter the city name");
        setIsFormValid(true);
        return;
      } else if (
        (addJobData.workPlaceType === "Hybrid" ||
          addJobData.workPlaceType === "On-Site") &&
        addJobData.location.state === ""
      ) {
        toast.error("Please enter the state name");
        setIsFormValid(true);
        return;
      } else if (
        (addJobData.workPlaceType === "Hybrid" ||
          addJobData.workPlaceType === "On-Site") &&
        addJobData.location.country === ""
      ) {
        setIsFormValid(true);
        return;
        //toast.error("Please enter the country name");
      } else if (
        addJobData.isRemote &&
        !(addJobData.preferredLocation.length > 0 || addJobData.isGlobal)
      ) {
        toast.error("Please enter the preferred location");
        setIsFormValid(true);
        return;
      }
    }
    if (value === "Next" && tabList.Screening) {
      if (screeningQuestions.length === 0 && addJobData.screeningRequired) {
        toast.error("Please add at least one screening requirement to proceed");
        setIsFormValid(true);
        return;
      }
      if (addJobData.vettingRequired && checkedAssessments.length === 0) {
        toast.error("Please select any skills for vetting");
        setIsFormValid(true);
        return;
      }

      if (screeningQuestions.length > 0) {
        let isQuestionEmpty = screeningQuestions.filter(
          (item) => item.name === "Custom Question" && item.question === ""
        );
        if (isQuestionEmpty.length > 0) {
          toast.error("Please add the question for Custom Question type");
          setIsFormValid(false);
          return;
        }
      }
    }
    if (!val) {
      setIsFormValid(false);
      let tabs = Object.entries(tabList);
      let currentTabIndex = tabs.findIndex((item) => item[1]);
      let index = value === "Back" ? currentTabIndex - 1 : currentTabIndex + 1;
      changeTab(tabs[index][0]);
    }
  };

  const changeTab = (value) => {
    let intialTabList = {
      "Job Info": false,
      Screening: false,
      "Submittal Details": false,
      Documents: false,
      Notes: false,
    };
    intialTabList[value] = true;
    setTabList(intialTabList);
  };

  const onTypeClick = (value) => {
    if (!typeSelected) {
      setTabList({ ...tabList, "Job Info": true });
      setTypeSelected(true);
    }

    if (value === "Full Time") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: false,
        fulltimeSelected: true,
        contractSelected: false,
      });
      if (jobDivaId == "") {
        setAddJobData({
          ...initialJobData,
          type: value,
          minMaxPaymentType: "Annual",
        });
      } else {
        setAddJobData({
          ...addJobData,
          type: value,
          minMaxPaymentType: "Annual",
        });
      }
    } else if (value === "Contract") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: false,
        fulltimeSelected: false,
        contractSelected: true,
      });
      if (jobDivaId == "") {
        setAddJobData({
          ...initialJobData,
          minMaxPaymentType: "Per hour",
          isFeePercentage: true,
          placementFee: 5,
          type: value,
        });
      } else {
        setAddJobData({
          ...addJobData,
          minMaxPaymentType: "Per hour",
          isFeePercentage: true,
          placementFee: 5,
          type: value,
        });
      }
    } else if (value === "Part Time") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: true,
        fulltimeSelected: false,
        contractSelected: false,
      });

      if (jobDivaId == "") {
        setAddJobData({
          ...initialJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      } else {
        setAddJobData({
          ...addJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      }
    } else if (value === "Temp to Hire") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: true,
        partTimeSelected: false,
        fulltimeSelected: false,
        contractSelected: false,
      });

      if (jobDivaId == "") {
        setAddJobData({
          ...initialJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      } else {
        setAddJobData({
          ...addJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      }
    } else {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: true,
        tempToHireSelected: false,
        partTimeSelected: false,
        fulltimeSelected: false,
        contractSelected: false,
      });

      if (jobDivaId == "") {
        setAddJobData({
          ...initialJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      } else {
        setAddJobData({
          ...addJobData,
          minMaxPaymentType: "Per hour",
          type: value,
        });
      }
    }
  };

  const validate = () => {
    let detailsErr = false;
    let templateErr = false;
    let intakeErr = false;
    let vetErr = false;
    const validationArray = [];
    let validation = {
      template: "",
      minimumPay: "",
      maximumPay: "",
      title: "",
      primary: "",
      visaStatus: "",
      skillSet: "",
      subCategory: "",
      placement: "",
      preferredLocationError: "",
      activeFromError: "",
      startDateError: "",
      expiresOnError: "",
      company: "",
      location: {
        city: "",
        state: "",
        country: "",
        address: "",
        zipCode: "",
      },
      description: "",
    };
    if (saveTemplate && templateName === "") {
      validation = { ...validation, template: "Required Field" };
      validationArray.push("Please Enter Template Name");
      templateErr = true;
    }
    if (
      templates?.map((i) => i.templateName).includes(templateName) &&
      !overWrite
    ) {
      validation = { ...validation, template: "This name already exists" };
      validationArray.push(
        "Template Name Already Exists. Please Try Another Name"
      );
      templateErr = true;
    }
    if (addJobData.title === "") {
      validation = { ...validation, title: "Required Field" };
      validationArray.push("Please Enter Job Title");
      detailsErr = true;
    }
    if (new Date(addJobData.startDate) < new Date(addJobData.activeFrom)) {
      validation = {
        ...validation,
        startDateError:
          "Preffered Start Date should be greater than Active from date",
      };
      validationArray.push(
        "Preffered Start Date should be greater than Active from date"
      );
      detailsErr = true;
    }
    if (
      !addJobData.isRemote &&
      addJobData.location.zipCode &&
      !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(addJobData.location.zipCode)
    ) {
      validation = { ...validation, zipCode: "Invalid Zip Code" };
      validationArray.push("Invalid Zip Code");
      detailsErr = true;
    }
    let locationInfo = Object.entries(addJobData.location).filter((i) =>
      ["city", "state", "country"].includes(i[0])
    );
    let locationValidation = {};
    let zipValidation = {};
    locationInfo.forEach((i) => {
      locationValidation[i[0]] = i[1] ? "" : "Required Field";
    });
    if (!addJobData.isRemote && locationInfo.some((i) => i[1] === "")) {
      validation = { ...validation, location: locationValidation };
      validationArray.push("Please Complete the Location Info");
      detailsErr = true;
    }

    if (addJobData.minimumPay === "") {
      validation = { ...validation, minimumPay: "Required Field" };
      validationArray.push("Please Enter Minimum Pay");
      detailsErr = true;
    }
    if (addJobData.maximumPay === "") {
      validation = { ...validation, maximumPay: "Required Field" };
      validationArray.push("Please Enter Maximum Pay");
      detailsErr = true;
    }
    if (+addJobData.minimumPay > +addJobData.maximumPay) {
      validation = {
        ...validation,
        minimumPay: "Minimum Pay should be less than Maximum Pay",
      };
      validationArray.push("Minimum Pay should be less than Maximum Pay");
      detailsErr = true;
    }
    if (addJobData.primarySkills.length === 0) {
      validation = { ...validation, primary: "Required Field" };
      validationArray.push("Please Enter Mandatory Skills");
      detailsErr = true;
    }
    if (addJobData.visaStatus.length === 0) {
      validation = { ...validation, visaStatus: "Required Field" };
      validationArray.push("Please Enter Work Authorization Requirement");
      detailsErr = true;
    }
    let regex = /(<([^>]+)>)/gi;
    if (!addJobData.description.replace(regex, "")) {
      validation = { ...validation, description: "Required Field" };
      validationArray.push("Please Enter Job Description");
      detailsErr = true;
    }
    if (addJobData.placementFee === "") {
      validation = { ...validation, placement: "Required Field" };
      validationArray.push("Please Enter Placement Fee");
      detailsErr = true;
    }
    if (addJobData.isFeePercentage && addJobData.placementFee > 100) {
      validation = {
        ...validation,
        placement: "Placement fee should not be greater than 100",
      };
      validationArray.push("Placement fee should not be greater than 100");
      detailsErr = true;
    }

    if (addJobData.vettingRequired && checkedAssessments.length === 0) {
      validationArray.push("Please select any skills for vetting");
      vetErr = true;
    }
    if (
      addJobData.isRemote &&
      !(addJobData.preferredLocation.length > 0 || addJobData.isGlobal)
    ) {
      validation = { ...validation, preferredLocationError: "Required Field" };
      validationArray.push("Please select Location");
      detailsErr = true;
    }
    if (addJobData.skillSet.length === 0) {
      validation = { ...validation, skillSet: "Required Field" };
      validationArray.push("Please Enter Industry");
      detailsErr = true;
    }
    if (addJobData.subCategory.length === 0) {
      validation = { ...validation, subCategory: "Required Field" };
      validationArray.push("Please Enter Sub Category");
      detailsErr = true;
    }
    screeningQuestions.forEach((element) => {
      if (element.name == "Custom Question") {
        if (
          element.hasOwnProperty("dropdownOptions") &&
          element.dropdownOptions.length == 0
        ) {
          validationArray.push("Please add atleast one Drop-down option");
        }
      }
      if (element.name === "Language") {
        if (selectedLanguages?.length === 0) {
          validationArray.push("Please add atleast one language");
          vetErr = true;
        }
      }
    });
    return {
      validation,
      validationArray,
      templateErr,
      detailsErr,
      intakeErr,
      vetErr,
    };
  };

  const submitJob = async () => {
    let result = validate();

    if (isFrom === "parsingComparison") setLoadingPC(true);
    if (result.validationArray.length > 0) {
      toast.error(result.validationArray[0]);
      if (result.templateErr) {
        addTemplate || editTemplate
          ? changeTab("Job Info")
          : changeTab("Notes");
      } else if (result.detailsErr) {
        changeTab("Job Info");
      } else if (result.vetErr) {
        changeTab("Screening");
      }
      setAddJobVal(result.validation);
    } else {
      if (isFrom === "parsingComparison") setLoadingPC(true);
      // toast.success('Validation Success')
      setLoading(true);
      setAddJobVal({
        template: "",
        minimumPay: "",
        maximumPay: "",
        title: "",
        primary: "",
        preferredLocation: "",
        placement: "",
        activeFromError: "",
        startDateError: "",
        expiresOnError: "",
        company: "",
        location: {
          city: "",
          state: "",
          country: "",
          address: "",
          zipCode: "",
        },
        description: "",
      });
      try {
        let finalQandA = [...screeningQuestions];
        if (finalQandA?.map((i) => i.name).includes("Language")) {
          let que = [...finalQandA.filter((i) => i.name !== "Language")];
          que.push({
            ...finalQandA.filter((i) => i.name === "Language")[0],
            input: JSON.stringify(selectedLanguages),
          });
          finalQandA = [...que];
        }
        let templateInfo = "";
        if (saveTemplate) {
          let {
            title,
            type,
            isFeePercentage,
            minimumPay,
            maximumPay,
            minMaxPaymentType,
            minMaxCurrency,
            submittal,
            description,
            primarySkills,
            preferredLocation,
            isGlobal,
            visaStatus,
            secondarySkills,
            skillSet,
            education,
            isFexible,
            workStart,
            workEnd,
            travel,
            drugTest,
            backgroundCheck,
            securityClearance,
            visaRequirement,
            licenceRequirement,
            experienceLevel,
          } = addJobData;
          templateInfo = JSON.stringify({
            title,
            isFeePercentage,
            minimumPay,
            maximumPay,
            minMaxPaymentType,
            minMaxCurrency,
            submittal,
            description,
            primarySkills,
            preferredLocation,
            isGlobal,
            visaStatus,
            secondarySkills,
            skillSet,
            education,
            isFexible,
            workStart,
            workEnd,
            travel,
            drugTest,
            backgroundCheck,
            securityClearance,
            visaRequirement,
            licenceRequirement,
            experienceLevel,
            type,
          });
        }

        let finalNotes =
          notesDetails.length > 0 ? JSON.stringify(notesDetails) : "";

        let uniquePrimarySkills = [
          ...new Set(
            addJobData?.primarySkills?.map((element) => element?.trim())
          ),
        ];

        const finalJobDetails = {
          ...addJobData,
          jobPostDate: new Date(),
          prefferedStartDate: addJobData?.startDate,
          contractDurationMonths: addJobData.durationMonths,
          contractDurationDays: addJobData.durationDays,
          openForReferral: addJobData?.openForReferral,
          referralBonusFee: addJobData.referralFee,
          referralBonusCurrency: addJobData.referralCurrency,
          weightage: JSON.stringify(addJobData.weightage),
          education: JSON.stringify(addJobData.education),
          visaStatus: JSON.stringify(addJobData.visaStatus),
          primarySkills: JSON.stringify(uniquePrimarySkills),
          preferredLocation: JSON.stringify(addJobData.preferredLocation),
          goodToHaveSkills: JSON.stringify(addJobData.secondarySkills),
          industries: JSON.stringify(addJobData.skillSet),
          skillSet: JSON.stringify(addJobData.skillSet),
          subCategory: JSON.stringify(addJobData.subCategory),
          jobDescription: addJobData.description,
          jobTitle: addJobData.title,
          jobType: addJobData.type,
          positionCount: addJobData.numberOfPositions,
          address: addJobData.location.address,
          city: addJobData.location.city,
          state: addJobData.location.state,
          zipCode: addJobData.location.zipCode,
          country: addJobData.location.country,
          notes: finalNotes,
          templateName: templateName,
          placementFee: edit
            ? addJobData.placementFee
            : !addJobData.isFeePercentage
            ? addJobData.placementFee
            : addJobData.placementFee,
          Submittal: addJobData.submittal,
          tenantName: addJobData.companyName || getUserTenant(),
          vettingDetails: JSON.stringify(addJobData.vettingDetails),
          licenceRequirement:
            addJobData.licenceRequirement.length > 0
              ? JSON.stringify(addJobData.licenceRequirement)
              : "",
          visaRequirement:
            addJobData.visaRequirement.length > 0
              ? JSON.stringify(addJobData.visaRequirement)
              : "",
          // please don't remove document and base64 from here, This is required to store document in templates
          documentName:
            documentName.length > 0 ? documentName[0]?.documentName : "",
          base64: documentName.length > 0 ? documentName[0]?.base64 : "",
          isPublish: addJobData.publishJob == "internal" ? true : false,
        };
        let params = {
          ...finalJobDetails,
          jobDetailsForTemplate: templateInfo,
          screeningQuestions: JSON.stringify(finalQandA),
          templateCategory: templateCategory,
          saveTemplate,
          templateName,
          documentName:
            documentName.length > 0 ? documentName[0]?.documentName : "",
          base64: documentName.length > 0 ? documentName[0]?.base64 : "",
          overWrite,
          templateDetails: templateDetails.templateDetails,
          templateID: templateDetails.templateID,
          templateSelected: edit ? false : templateSelected,
          oldFileName: edit ? editJobData.documentName : "",
          jobDivaId: jobDivaId,
        };

        let params1 = {
          jobDetails: JSON.stringify(finalJobDetails),
          screeningQuestions: JSON.stringify(finalQandA),
          category: templateCategory,
          templateName,
          templateID: tempDetails?.templateID,
          documentName:
            documentName.length > 0 ? documentName[0]?.documentName : "",
          base64: documentName.length > 0 ? documentName[0]?.base64 : "",
          oldFileName: edit ? editJobData.documentName : "",
        };

        if (addTemplate || editTemplate) {
          let url = addTemplate ? "/job/addTemplate" : "/job/editTemplate";
          const res = await post(url, params1);
          if (res.status === 200) {
            let msg = addTemplate
              ? "Job Template Added Successfully"
              : "Updated Successfully";
            toast.success(msg);
            closePanel();
            setTimeout(() => {
              fetchTemplate();
            }, 5000);
          }
        } else if (edit) {
          if (addJobData.vettingRequired) {
            let difficulty = "";
            difficultyData.forEach((obj) => {
              if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
            });

            let skillsObj = {
              Skills: checkedAssessments,
              Duration: 20,
              Difficulty: difficulty,
              AssessmentType: "General",
              JobTitle: `${addJobData.title}_high5_${uid()}`,
              PassingScore: 45,
            };

            const vetRes = await post(
              "/vetIntegration/createAssessment",
              skillsObj
            );

            params.vettingDetails = JSON.stringify(vetRes.data); //update the param of add job api
          } else {
            params.vettingDetails = [];
          }
          params.filePath = documentName?.length > 0 ? params?.filePath : "";
          const res = await post("/job/editjob", params);
          if (res.status === 200) {
            toast.success("Job updated successfully");
            refreshJobDetails();
          }
        } else {
          if (addJobData.vettingRequired) {
            let difficulty = "";
            difficultyData.forEach((obj) => {
              if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
            });

            let skillsObj = {
              Skills: checkedAssessments,
              Duration: 20,
              Difficulty: difficulty,
              AssessmentType: "General",
              JobTitle: `${addJobData.title}_high5_${uid()}`,
              PassingScore: 45,
            };

            const vetRes = await post(
              "/vetIntegration/createAssessment",
              skillsObj
            );
            params.vettingDetails = JSON.stringify(vetRes.data); //update the param of add job api
            finalJobDetails.vettingDetails = JSON.stringify(vetRes.data);
            params1.jobDetails = JSON.stringify(finalJobDetails);
          }

          const { longitude, latitude } = await getLongLatFromCity(
            addJobData.location.city,
            addJobData.location.state,
            addJobData.location.country
          );
          params = { ...params, longitude: longitude, latitude: latitude };
          const res = await post("/job/addJob", params);
          if (res.status === 200) {
            let jobId = res.data?.jobId.toString();
            let { city, state, country, zipCode } = addJobData.location;

            params = { ...params, jobId: res.data.jobId };
            let url = "/job/addTemplate";
            saveTemplate && (await post(url, params1));
            await post("/crintell", { jobData: params });
            closePanel();
            setSuccessFormOpen(true);
            setSuccessData({
              jobId,
              title: addJobData.title,
              jobType: addJobData.type,
              position: addJobData.numberOfPositions,
              startDate: addJobData.startDate,
              isRemote: addJobData.isRemote,
              salary: addJobData.maximumPay + " " + addJobData.minMaxCurrency,
              skills: addJobData.primarySkills,
              city,
              state,
              country,
              zipCode,
            });
            let automatchedData = {
              jobId: res.data.jobId,
              jobTitle: addJobData.jobTitle,
              primarySkills: addJobData?.primarySkills?.map((element) =>
                element.trim()
              ),
              education: addJobData.education,
              experienceLevel: addJobData.experienceLevel,
              JprimarySkills: addJobData.weightage.primarySkills?.map(
                (element) => element.trim()
              ),
              JjobTitle: addJobData.weightage.jobTitle,
              Jlocation: addJobData.weightage.location,
              JexperienceLevel: addJobData.weightage.experienceLevel,
              Jeducation: addJobData.weightage.education,
              city: addJobData.location.city,
              isRemote: addJobData.isRemote,
              radiusValue: radius,
            };
            if (template) {
              let resp = await post(`/job/editTemplate`, {
                ...chooseTemplateDetails,
                lastUsed: new Date(),
              });
            }

            automatchedData = {
              ...automatchedData,
              longitude: longitude,
              latitude: latitude,
            };
            await post("/automatch/automatchJobForTalents", automatchedData);

            if (isFrom === "parsingComparison") {
              await getAllJobs();
              toast.success("Job created successfully");
              setLoadingPC(false);
              setSelectedJobId(jobId);
            } else {
              let paramObj = {
                sortObject: [],
                currentPage: 1,
                tab: "all",
                filterQuery: {
                  address: [],
                  isHotJob: 0,
                  jobId: [],
                  jobTitle: [],
                  jobType: [],
                  visaStatus: [],
                },
              };
              dispatch(getJobList(paramObj));
              dispatch(getJobListCount(paramObj));
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }
  };

  const fetchJobDivaJob = () => {
    if (jobDivaId == "") {
      toast.error("Job Id required");
    } else {
      setLoading(true);
      post("/job/addJobDivaJob", { jobDivaId: jobDivaId }).then((data) => {
        if (data.status === 200) {
          if (data.data.length > 0) {
            let jobDivaData = data.data[0];
            let array = jobDivaData.SKILLS.replace(/[\(\)&]+/g, " ");
            let arr = array.replaceAll(" OR ", " ");
            let a = arr.replaceAll(" AND ", " ").split(" ");
            let b = a.filter((e) => e.length > 0);
            onTypeClick(jobDivaData?.POSITIONTYPE);
            setAddJobData({
              ...addJobData,
              type: jobDivaData?.POSITIONTYPE,
              jobDivaId: jobDivaId,
              title: jobDivaData?.JOBTITLE,
              startDate: new Date(today.setMonth(today.getMonth() + 2)),
              companyName: "",
              companyJobId: "",
              hiringManagerId: "",
              isRemote: false,
              isHotJob: false,
              isFeePercentage: jobDivaData?.FEE_TYPE == "%" ? true : false,
              isPublic: true,
              isFexible: true,
              location: {
                address: "",
                city: jobDivaData.POSTING_CITY,
                state: jobDivaData.POSTING_STATE,
                zipCode: jobDivaData.POSTING_ZIPCODE,
                country: jobDivaData.POSTING_COUNTRY,
              },
              numberOfPositions: jobDivaData.POSITIONS,
              allowedSubmittals:
                jobDivaData.MAXALLOWEDSUBMITTALS == 0
                  ? jobDivaData.POSITIONS * 5
                  : jobDivaData.MAXALLOWEDSUBMITTALS,
              salary: "",
              minMaxCurrency: "USD",
              activeFrom: new Date(),
              expiresOn: new Date(
                new Date().setMonth(new Date().getMonth() + 2)
              ),
              placementFee: jobDivaData?.FEE_TYPE == "%" ? 5 : "",
              placementCurrency: "USD",
              openForReferral: false,
              editPreferred: "",
              completionDate: "",
              durationMonths: 1,
              durationDays: 0,
              hourlyRate: "",
              hourlyRateCurrency: "USD",
              budget: "",
              referralFee: "",
              referralCurrency: "USD",
              submittal: "",
              description: jobDivaData.JOBDESCRIPTION,
              primarySkills: b,
              secondarySkills: [],
              skillSet: [],
              visaStatus: [],
              education: [],
              certifications: [],
              industries: [],
              tierData: {},
              workStart: "",
              workEnd: "",
              travel: "",
              drugTest: false,
              backgroundCheck: false,
              securityClearance: "",
              fileName: "",
              domainName: domain,
              tenantName: "",
              visaRequirement: [],
              licenceRequirement: [],
              experienceLevel: "",
              minimumPay: jobDivaData.PAYRATEMIN,
              maximumPay: jobDivaData.PAYRATEMAX,
              minMaxPaymentType:
                jobDivaData.PAYRATEPER == "Hour" ? "Per hour" : "Annual",
              workPlaceType: "On-Site",
              tenantId: getUserTenantID(),
              onsiteWorkDays: "",
              vettingRequired: false,
              screeningRequired: false,
              vettingDetails: [],
              preferredLocation: [],
              isGlobal: false,
              weightage: {
                primarySkills: b,
                secondarySkills: [],
                jobTitle: false,
                location: false,
                experienceLevel: false,
                education: [],
                industries: [],
              },
            });
            setValue(
              RichTextEditor.createValueFromString(
                jobDivaData?.JOBDESCRIPTION,
                "html"
              )
            );
          } else {
            toast.error("No Records found for this Job ID");
          }
        } else {
          toast.error("Something went wrong");
        }
        setLoading(false);
      });
    }
  };

  return (
    <>
      {(loading || categoryLoading) && <Loader />}
      {/*Job Diva Job Id*/}

      {!edit &&
        chooseJobDetails == null &&
        templateDetail == null &&
        Object.values(tabList)
          .slice(1)
          .every((i) => i === false) && (
          <FetchJobFromJobDiva
            fetchJobDivaJob={fetchJobDivaJob}
            setJobDivaId={setJobDivaId}
            jobDivaId={jobDivaId}
            isRequired={isRequired}
            setIsRequired={setIsRequired}
          />
        )}

      {/* JOB TYPE SELECTION */}
      {Object.values(tabList)
        .slice(1)
        .every((i) => i === false) && (
        <div className="m-3">
          <div className="m-1">
            {!edit && <label>Choose Job Type</label>}
            <div className="row mt-2">
              <div className="col-lg-4">
                <button
                  onClick={() => {
                    if (addJobType.typeSelected) {
                      setJobType("Full Time");
                      if (jobDivaId == "") {
                        setShowConfirmJobType(true);
                      } else {
                        onTypeClick("Full Time");
                      }
                    } else {
                      onTypeClick("Full Time");
                    }
                    setPrimarySkillInput("");
                    setSecondarySkillInput("");
                  }}
                  style={{
                    background: addJobType.fulltimeSelected
                      ? "#fcfcfe"
                      : "white",
                    color: "black",
                  }}
                  type="button"
                  className={classes(
                    { selected: addJobType.fulltimeSelected || edit },
                    "col-lg-4 card card-flat p-0 mr-2 mb-2 mb-lg-0"
                  )}
                  disabled={addJobType.fulltimeSelected || edit}
                >
                  <div className="card-body text-left text-left">
                    <div className="d-flex">
                      {addJobType.fulltimeSelected === true ? (
                        <div className="mr-3 font-green font-16">
                          <i className="fad fa-check-circle"></i>
                        </div>
                      ) : (
                        <div className="mr-3 font-muted font-16">
                          <i className="fad fa-circle"></i>
                        </div>
                      )}

                      <div>
                        <div>
                          <strong className="font-blue_primary">
                            Full Time
                          </strong>
                        </div>
                        <p className="mb-0">Hire for a full time</p>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="col-lg-4">
                <button
                  onClick={() => {
                    if (addJobType.typeSelected) {
                      setJobType("Contract");
                      if (jobDivaId == "") {
                        setShowConfirmJobType(true);
                      } else {
                        onTypeClick("Contract");
                      }
                    } else {
                      onTypeClick("Contract");
                    }
                    setPrimarySkillInput("");
                    setSecondarySkillInput("");
                  }}
                  style={{
                    background: addJobType.contractSelected
                      ? "#fcfcfe"
                      : "white",
                    color: "black",
                  }}
                  type="button"
                  className={classes(
                    { selected: addJobType.contractSelected || edit },
                    "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                  )}
                  disabled={addJobType.contractSelected || edit}
                >
                  <div className="card-body text-left text-left">
                    <div className="d-flex">
                      {addJobType.contractSelected === true ? (
                        <div className="mr-3 font-green font-16">
                          <i className="fad fa-check-circle" />
                        </div>
                      ) : (
                        <div className="mr-3 font-muted font-16">
                          <i className="fad fa-circle" />
                        </div>
                      )}
                      <div>
                        <div>
                          <strong className="font-blue_primary">
                            Contract
                          </strong>
                        </div>
                        <p className="mb-0">Hire for a contract</p>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <h6 className="col-lg-1 pt-3">or</h6>
              <div className="col-lg-3 d-flex flex-column flex-lg-row form-floating p-0">
                <select
                  onChange={(e) => {
                    if (addJobType.typeSelected) {
                      setJobType(e.target.value);
                      if (jobDivaId == "") {
                        setShowConfirmJobType(true);
                      } else {
                        onTypeClick(e.target.value);
                      }
                    } else {
                      onTypeClick(e.target.value);
                    }
                    setPrimarySkillInput("");
                    setSecondarySkillInput("");
                  }}
                  disabled={edit}
                  className="font-14 form-select"
                  name="jobSelection"
                  style={{
                    boxSizing: "border-box",
                    height: "61px",
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <option disabled selected>
                    Select
                  </option>
                  <option
                    disabled={addJobType.partTimeSelected}
                    selected={addJobType.partTimeSelected}
                    value="Part Time"
                  >
                    Part Time
                  </option>
                  <option
                    disabled={addJobType.tempToHireSelected}
                    selected={addJobType.tempToHireSelected}
                    value="Temp to Hire"
                  >
                    Temp to Hire
                  </option>
                  <option
                    disabled={addJobType.volunteerSelected}
                    selected={addJobType.volunteerSelected}
                    value="Volunteer"
                  >
                    Volunteer
                  </option>
                </select>
                <label style={{ paddingLeft: "13px", fontSize: "1rem" }}>
                  <strong className="font-inherit font-blue_primary">
                    Job Type
                  </strong>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* TABS */}
      {addJobType.typeSelected && (
        <div className="mt-3">
          <div>
            {false && (
              <table className="ml-4">
                <tr>
                  <td className="bg-gra">
                    <div className="d-flex flex-column bg-gra">
                      <div className="d-flex justify-content-center align-items-center bg-gra pl-3">
                        <BsFillCircleFill style={{ color: "green" }} />
                        <span>
                          <AiOutlineLine
                            style={{
                              color: "green",
                              width: "7rem",
                              height: "3rem",
                            }}
                          />
                        </span>
                      </div>
                      <div>Job Info</div>
                    </div>
                  </td>
                </tr>
              </table>
            )}
            <ul className="nav nav-tabs pt-1 ml-3">
              {Object.keys(tabList)?.map((item, index) => (
                <li className="nav-item" key={index}>
                  <span
                    // onClick={() => {
                    //   console.log(item, index);
                    //   handleBackNext("Next");
                    //   isFormValid && changeTab(item);
                    // }}

                    disabled={true}
                    className={
                      tabList[item] ? "nav-link pb-3 active" : "nav-link pb-3"
                    }
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "initial",
                      color: "initial",
                    }}
                  >
                    {item === "Notes" ? "Notes" : item}
                  </span>
                </li>
              ))}
            </ul>
            <hr className="mt-0" />
          </div>
        </div>
      )}

      {/* JOB DETAILS */}
      {(tabList["Job Info"] || tabList["Preview"]) && (
        <JobDetails
          primarySkillInput={primarySkillInput}
          radius={radius}
          setRadius={setRadius}
          setPrimarySkillInput={setPrimarySkillInput}
          secondarySkillInput={secondarySkillInput}
          setSecondarySkillInput={setSecondarySkillInput}
          addJobData={addJobData}
          setAddJobData={setAddJobData}
          addJobVal={addJobVal}
          setAddJobVal={setAddJobVal}
          value={value}
          setValue={setValue}
          addJobType={addJobType}
          dropdownList={dropdownList}
          documents={documentName}
          setDocuments={setDocumentName}
          preview={tabList["Preview"]}
          addTemplate={addTemplate}
          templateName={templateName}
          setTemplateName={setTemplateName}
          templateCategory={templateCategory}
          setTemplateCategory={setTemplateCategory}
          editTemplate={editTemplate}
          isFormValid={isFormValid}
          edit={edit}
          setCategoryLoading={setCategoryLoading}
        />
      )}

      {/* Screening */}
      {tabList["Screening"] && (
        <InterviewPlan
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          skillSetOption={skillSetOption}
          QualificationType={QualificationType}
          screeningQuestions={screeningQuestions}
          setScreeningQuestions={setScreeningQuestions}
          mandatorySkills={addJobData.primarySkills}
          preVettingSkills={preVettingSkills}
          setPrevettingSkills={setPrevettingSkills}
          dropdownList={dropdownList}
          preVettingRequired={preVettingRequired}
          setPrevettingRequired={setPrevettingRequired}
          addJobData={addJobData}
          setAddJobData={setAddJobData}
          screeningRequired={screeningRequired}
          setScreeningRequired={setScreeningRequired}
          vettingCheck={vettingCheck}
          //vettingData={vettingDB}
          // setVettingToSend={setVettingToSend}
          jobCategoryOptions={jobCategoryOptions}
          checkedAssessments={checkedAssessments}
          setCheckedAssessments={setCheckedAssessments}
          setAssessmentDetails={setAssessmentDetails}
          assessmentDetails={assessmentDetails}
          selectedSkill={selectedSkill}
          setSelectedSkill={setSelectedSkill}
          selectedTestCategory={selectedTestCategory}
          setSelectedTestCategory={setSelectedTestCategory}
          selectedDifficulty={selectedDifficulty}
          setSelectedDifficulty={setSelectedDifficulty}
          assessmentList={assessmentList}
          setAssessmentList={setAssessmentList}
          secondarySkills={secondarySkills}
          setSecondarySkills={setSecondarySkills}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
          difficulty={difficulty}
          setDifficulty={setDifficulty}
          addJobVal={addJobVal}
        />
      )}

      {tabList["Submittal Details"] && (
        <SubmittalDetailsInfo
          addJobData={addJobData}
          weightage={addJobData.weightage}
          setWeightage={(data) =>
            setAddJobData({ ...addJobData, weightage: data })
          }
          edit={edit}
        />
      )}

      {/* DOCUMENT TAB */}
      {Documents && (
        <UploadDocument
          documents={documentName}
          setDocuments={setDocumentName}
          edit={edit}
          editJobData={editJobData}
          chooseJobDetails={chooseJobDetails}
          setLoading={setLoading}
        />
      )}

      {/* NOTES TAB */}
      {Notes && (
        <Note
          notesDetails={notesDetails}
          notesValue={notesValue}
          setNotesValue={setNotesValue}
          setNotesDetails={setNotesDetails}
          saveTemplate={saveTemplate}
          setSaveTemplate={setSaveTemplate}
          setOverWrite={setOverWrite}
          setTemplateName={setTemplateName}
          setTemplateCategory={setTemplateCategory}
          addJobVal={addJobVal}
          setAddJobVal={setAddJobVal}
          skillSetOption={skillSetOption}
          templateDetails={templateDetails}
          overWrite={overWrite}
          edit={edit}
          jobCategoryOptions={jobCategoryOptions}
          addTemplate={addTemplate}
          editTemplate={editTemplate}
          templateCategory={templateCategory}
          templateName={templateName}
        />
      )}

      {/* FOOTER - Back, Next, Submit */}
      {addJobType.typeSelected && (
        <>
          <hr />
          <div className="d-flex align-items-center justify-content-end m-3">
            {/* BACK Button */}
            {!tabList["Job Info"] && (
              <button
                className="btn btn-sm"
                onClick={() => handleBackNext("Back")}
              >
                Back
              </button>
            )}

            {/* NEXT Button */}
            {!Notes && (
              <button
                className="btn btn-sm btn-primary ml-2"
                onClick={() => handleBackNext("Next")}
              >
                Next
              </button>
            )}
            {/* CANCEL Button */}
            {Notes && (
              <button
                className="btn btn-sm btn-secondary ml-2"
                onClick={closePanel}
              >
                Cancel
              </button>
            )}

            {/* SUBMIT/UPDATE Button */}
            {Notes && (
              <button
                disabled={false}
                className="btn btn-sm btn-primary ml-2"
                onClick={submitJob}
              >
                {(edit && !reopen) || editTemplate
                  ? "Update"
                  : reopen
                  ? "Reopen"
                  : "Submit"}
              </button>
            )}
          </div>
        </>
      )}

      {showConfirmJobType && (
        <EraseDataModal
          setShowConfirmJobType={setShowConfirmJobType}
          setJobType={setJobType}
          onTypeClick={onTypeClick}
          jobType={jobType}
          initialJobData={initialJobData}
        />
      )}
    </>
  );
};

export default AddJobDetails;
