import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaHeart } from "react-icons/fa";
import OutsideClickHandler from "react-outside-click-handler";
import Avatar from "react-avatar";
import ReactGA from "react-ga";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { browserName } from "react-device-detect";
import axios from "axios";
import logo from "../../../assets/images/High5Logo.png";
import { getUserName, getUserRole } from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
import LoginUser from "./LoginUser";
import Event from "../../../googleanalytic/Tracking";
import { industryCategories } from "../../../constants/Constants";

function TopNavBar({
  savedJobs,
  isLoginModal,
  setIsLoginModal,
  setSavedJobs,
  isFrom,
}) {
  const { recruiterid } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const TRACKING_ID = "UA-223748254-1";
  ReactGA.initialize(TRACKING_ID);
  const history = useHistory();
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(false);

  const [login, setLogin] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);

  const isLoggedIn = () => localStorage.getItem("isLoggedIn");
  const getURL = () => sessionStorage.getItem("url");

  const handleCategoryClick = (category) => {
    history.push(`/jobs/${category}`, { category: category });
  };
  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIp(res.data.ip);
  };
  useEffect(() => {
    getIpData();
  }, []);
  const handleLogout = async () => {
    Event(
      "Logout",
      `${sessionStorage.getItem("name")},Candidate`,
      window.location.pathname
    );
    // setToggle(false);
    setLoginToggle(false);
    setLogin(false);
    ReactGA.event({
      user: getUserName(),
      role: getUserRole(),
      time: new Date(),
    });
    const params = {
      candidateId: sessionStorage.getItem("userId"),
      activity: "Logged Out",
      browser: browserName,
      ipAddress: ip,
    };
    setLoading(true);
    const res = await post("/favourites/candidateLogout", params)
      .then((res) => {
        if (isFrom === "AllJobs") {
          setSavedJobs([]);
        }
        setLoading(false);
        sessionStorage.clear();
        localStorage.clear();
        history.push(`/jobs/all-jobs`);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary shadow navSec w-100"
      >
        <div className="d-flex  justify-content-between  w-100 px-4">
          <Navbar.Brand as={Link} to="/career/home">
            <div className="">
              <img
                className="ml-2 my-auto"
                style={{ height: "40px", width: "75px" }}
                src={logo}
                alt="High5 Logo"
              />
            </div>
          </Navbar.Brand>
          <div className="d-flex  gap-2 align-items-center">
            {windowWidth < 992 && (
              <div className="extras ml-auto text-right mr-3">
                <div className="">
                  {sessionStorage.getItem("token") === null &&
                  localStorage.getItem("url") === null ? (
                    <a
                      href="#login"
                      data-rb-event-key="#login"
                      class="text-muted"
                    >
                      <div class="extras ml-auto text-right mr-3">
                        <div class="">
                          <span
                            onClick={() => {
                              setLogin(true);
                              setIsLoginModal(true);
                            }}
                          >
                            Log In
                          </span>
                        </div>
                      </div>
                    </a>
                  ) : (
                    <div>
                      <button
                        className="btn btn-icon dropdown-toggle"
                        type="button"
                        onClick={() => {
                          console.log(isLoggedIn());

                          setLoginToggle(!loginToggle);
                        }}
                        style={{ fontSize: "10px" }}
                      >
                        <Avatar
                          // className="avatar avatar-sm"
                          name={
                            sessionStorage.getItem("token") == null
                              ? localStorage.getItem("name")?.trim()
                              : sessionStorage.getItem("name")?.trim()
                          }
                          round="80px"
                          color="green"
                          size="30"
                          src={getURL()}
                        />
                      </button>
                      {loginToggle && (
                        <OutsideClickHandler
                          onOutsideClick={() => setLoginToggle(false)}
                        >
                          <div
                            className="dropdown-menu"
                            style={{
                              display: "revert",
                            }}
                          >
                            <>
                              <Link
                                to={`/careers/profile/edittalent`}
                                className="dropdown-item"
                              >
                                <i className="fal fa-user mr-3"></i> Profile{" "}
                              </Link>
                              <hr />
                            </>

                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                handleLogout();
                              }}
                              href="/careers/edittalent"
                              className="dropdown-item"
                            >
                              <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                            </a>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title="Browse jobs" id="collapsible-nav-dropdown">
                  {industryCategories.map((item, index) => (
                    <NavDropdown.Item
                      key={index}
                      onClick={() => handleCategoryClick(item.path)}
                    >
                      {item.jobName}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
                {sessionStorage.getItem("token") === null &&
                  localStorage.getItem("url") === null && (
                    <Nav.Link
                      href="https://www.high5hire.com/about-us/"
                      target="_blank"
                    >
                      About
                    </Nav.Link>
                  )}
                {!(
                  sessionStorage.getItem("token") === null &&
                  localStorage.getItem("url") === null
                ) && (
                  <Nav.Link href="/careers/profile" target="_self">
                    Dashboard
                  </Nav.Link>
                )}
                {/* <Nav.Link disabled href="#blogs">
                Blogs
              </Nav.Link> */}
                {sessionStorage.getItem("token") === null &&
                  localStorage.getItem("url") === null && (
                    <Nav.Link
                      href="#talent-community"
                      onClick={() => {
                        window.open(
                          `/careers/8/recruiter/${recruiterid}/jointalentpool`,
                          "_blank"
                        );
                      }}
                    >
                      Talent community
                    </Nav.Link>
                  )}
              </Nav>
              {windowWidth >= 992 && (
                <Nav>
                  <Nav.Link href="#login">
                    <div className="extras ml-auto text-right mr-3">
                      <div className="">
                        {sessionStorage.getItem("token") === null &&
                        localStorage.getItem("url") === null ? (
                          <span
                            onClick={() => {
                              setLogin(true);
                              setIsLoginModal(true);
                            }}
                          >
                            Log In
                          </span>
                        ) : (
                          <div>
                            <button
                              className="btn btn-icon dropdown-toggle"
                              type="button"
                              onClick={() => {
                                console.log(isLoggedIn());

                                setLoginToggle(!loginToggle);
                              }}
                              style={{ fontSize: "10px" }}
                            >
                              {sessionStorage.getItem("profileImagePath") &&
                              sessionStorage.getItem("profileImagePath") !=
                                "null" ? (
                                <img
                                  src={sessionStorage.getItem(
                                    "profileImagePath"
                                  )}
                                  alt=""
                                  width="30"
                                  height="30"
                                  style={{
                                    borderRadius: "50%",
                                    objectFit: "contain",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  name={
                                    sessionStorage.getItem("token") == null
                                      ? localStorage.getItem("name")?.trim()
                                      : sessionStorage.getItem("name")?.trim()
                                  }
                                  round="80px"
                                  color="green"
                                  size="30"
                                  src={getURL()}
                                />
                              )}
                            </button>
                            {loginToggle && (
                              <OutsideClickHandler
                                onOutsideClick={() => setLoginToggle(false)}
                              >
                                <div
                                  className="dropdown-menu"
                                  style={{
                                    display: "revert",
                                  }}
                                >
                                  <>
                                    <Link
                                      to={`/careers/profile/edittalent`}
                                      className="dropdown-item"
                                    >
                                      <i className="fal fa-user mr-3"></i>{" "}
                                      Profile{" "}
                                    </Link>
                                    <hr />
                                  </>

                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleLogout();
                                    }}
                                    href="/careers/edittalent"
                                    className="dropdown-item"
                                  >
                                    <i className="fal fa-sign-out mr-3"></i>{" "}
                                    Logout{" "}
                                  </a>
                                </div>
                              </OutsideClickHandler>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Nav.Link>
                </Nav>
              )}
              <Nav>
                <Nav.Link
                  disabled={savedJobs?.length > 0 ? false : true}
                  href="/career/savedjobs"
                >
                  {" "}
                  <FaHeart
                    className="heart-icon mr-1"
                    style={{
                      color: savedJobs?.length > 0 ? "#cd2a2a" : "gray",
                    }}
                  />
                  {`Saved Jobs (${savedJobs?.length})`}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
      <LoginUser
        login={login}
        setLogin={setLogin}
        isLoginModal={isLoginModal}
        setIsLoginModal={setIsLoginModal}
        isFrom="NewApplyPage"
      />
    </>
  );
}

export default TopNavBar;
