import React, { useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import GoogleLocationCityAPI from "../../../../../location/GoogleLocationCityAPI";

const ContactInfo = (props) => {
  const {
    contactInfo,
    setContactInfo,
    contactInfoValidation,
    isFormValid,
    editTalent,
  } = props;
  const searchInput = useRef(null);

  const handleChange = (e) => {
    setContactInfo({
      ...contactInfo,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (contactInfo?.city) {
      searchInput.current.value = contactInfo.city;
    }
  }, [contactInfo]);

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    const isValid = asYouType.isValid() || value !== "";
    setContactInfo({
      ...contactInfo,
      homePhone: value.slice(country?.dialCode?.length),
      homePhoneValidation: isValid,
      homePhoneCode: `+${country?.dialCode}`,
    });
  };

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setContactInfo({
      ...contactInfo,
      city: city,
      state: address.state,
      country: address.country,
      longitude: address.longitude,
      latitude: address.latitude,
    });
    searchInput.current.value = city;
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name*"
              value={contactInfo.firstName}
              style={{
                border:
                  isFormValid &&
                  contactInfo.firstName == "" &&
                  contactInfoValidation.firstName &&
                  "2px solid #ff0000",
              }}
              onChange={(e) =>
                setContactInfo({
                  ...contactInfo,
                  firstName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                })
              }
              disabled={editTalent}
            />
            <label>First Name*</label>
          </div>
          {isFormValid &&
            contactInfo.firstName == "" &&
            contactInfoValidation.firstName && (
              <small className="validation">
                {contactInfoValidation.firstName}
              </small>
            )}
        </div>
        <div className="col-lg-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name*"
              value={contactInfo.lastName}
              style={{
                border:
                  isFormValid &&
                  contactInfo.lastName == "" &&
                  contactInfoValidation.lastName &&
                  "2px solid #ff0000",
              }}
              onChange={(e) =>
                setContactInfo({
                  ...contactInfo,
                  lastName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                })
              }
              disabled={editTalent}
            />
            <label>Last Name*</label>
          </div>
          {isFormValid &&
            contactInfo.lastName == "" &&
            contactInfoValidation.lastName && (
              <small className="validation">
                {contactInfoValidation.lastName}
              </small>
            )}
        </div>{" "}
        <div className="col-lg-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Email*"
              value={contactInfo.email}
              disabled
              onChange={handleChange}
            />
            <label>Email*</label>
          </div>
          {isFormValid && contactInfo.email == "" && (
            <small className="validation">*Required Field</small>
          )}
        </div>
      </div>

      <div className="row ">
        <div className="col-lg-4">
          <div className="form-floating">
            <GoogleLocationCityAPI
              isFormValid={isFormValid}
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
              contactInfoValidation={contactInfoValidation}
              setLocation={setLocation}
              searchInput={searchInput}
            />
            <label>City*</label>
          </div>
          {isFormValid &&
            contactInfo.city == "" &&
            contactInfoValidation.city && (
              <small className="validation">*Required Field</small>
            )}
        </div>

        <div className="col-lg-4">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="country"
              placeholder="Address Country"
              value={contactInfo.country}
              style={{
                border:
                  isFormValid &&
                  contactInfo.country == "" &&
                  "2px solid #ff0000",
              }}
              onChange={handleChange}
              disabled
            />
            <label>Country*</label>
          </div>
          {isFormValid && contactInfo.country == "" && (
            <small className="validation">*Required Field</small>
          )}
        </div>
        <div className="col-lg-4">
          <div
            className=" form-floating"
            style={{
              border:
                ((isFormValid &&
                  (contactInfo.homePhone === "" ||
                    contactInfo.homePhone === undefined)) ||
                  (!contactInfo.phoneValidation &&
                    contactInfo.homePhone.length < 1)) &&
                "2px solid #ff0000",
              borderRadius: "4px",
            }}
          >
            <PhoneInput
              containerClass="w-100"
              inputClass="w-100 form-control"
              placeholder="Phone*"
              country={"us"}
              value={`${contactInfo.homePhoneCode}${contactInfo.homePhone}`}
              name="homePhone"
              inputStyle={{ height: "57px" }}
              onChange={(value, country) => {
                _onChangeText(value, country);
              }}
            />
          </div>
          {(isFormValid &&
            (contactInfo.homePhone === "" ||
              contactInfo.homePhone === undefined)) ||
          !contactInfo.phoneValidation ? (
            <small className="validation">*Required Field</small>
          ) : (
            !contactInfo.phoneValidation &&
            contactInfo.homePhone.length > 1 && (
              <small className="validation">Invalid Phone</small>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
