import axios from "axios";
import React, { useEffect, useState } from "react";
import { countryAPIurl } from "../../constants/Constants";

const CountrySelector = ({ setSelectedCountry, setCountryCode }) => {
  const [showItems, setShowItems] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [selected, setSelected] = useState({
    name: {
      common: "United States",
      official: "United States of America",
      nativeName: {
        eng: {
          official: "United States of America",
          common: "United States",
        },
      },
    },
    altSpellings: ["US", "USA", "United States of America"],
    flags: {
      png: "https://flagcdn.com/w320/us.png",
      svg: "https://flagcdn.com/us.svg",
    },
  });

  const onSelect = (e) => {
    if (setCountryCode) {
      setCountryCode(e.altSpellings[0]);
    }
    if (setSelectedCountry) {
      setSelectedCountry(e);
    }
    setSelected(e);
    setShowItems(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataUrl = countryAPIurl;
        const response = await axios.get(dataUrl);
        const resArr = Object.values(response.data);
        setCountryData(resArr);
        setFilteredData(resArr);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
    if (setCountryCode) {
      setCountryCode("us");
    }
  }, []);

  const onCLick = (e) => {
    setShowItems(!showItems);
  };

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = countryData.filter((data) => {
      return data.name.common.toLowerCase().search(value) !== -1;
    });
    setFilteredData(result);
  };

  return (
    <div className="">
      <div
        className="form-control dropdown-toggle"
        onClick={onCLick}
        style={{ cursor: "pointer", height: 40 }}
      >
        {selected ? (
          <img
            src={selected?.flags?.png}
            alt="flage"
            width={30}
            className="me-2"
          />
        ) : (
          <img
            src="https://mainfacts.com/media/images/coats_of_arms/us.png"
            alt=""
            width={30}
            className="me-2"
          />
        )}
      </div>
      {showItems && (
        <div
          className="overflow-auto border shadow bg-body rounded"
          style={{ width: 250, zIndex: 1000000, position: "absolute" }}
        >
          <div className="sticky-top p-1">
            <div
              id="SiteSearchField"
              className="search-field sitesearchresults"
            >
              <div className="field-wrap">
                <input
                  type="text"
                  placeholder="Search Country Name"
                  className="sitesearch"
                  onChange={(event) => handleSearch(event)}
                />
                <span className="icon">
                  <i aria-hidden="true" className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div
            className="card-body overflow-auto d-grid gap-2"
            style={{ maxHeight: 300 }}
          >
            {filteredData.map((x, i) => {
              return (
                <div
                  key={i}
                  className="border-bottom pb-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => onSelect(x)}
                >
                  {" "}
                  <img src={x.flags.png} alt="flage" width={30} />{" "}
                  {x.name.common}{" "}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountrySelector;
