import {
  GET_TALENT_NAME_FILTER_BEGIN,
  GET_TALENT_NAME_FILTER_SUCCESS,
  GET_TALENT_NAME_FILTER_FAILURE,
} from "../../../action/filter/talent/TalentNameFilter";

const initialState = {
  talentNameLoading: false,
  talentNameData: null,
};

const getTalentNameReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_NAME_FILTER_BEGIN:
      return {
        ...state,
        talentNameLoading: true,
        talentNameData: null,
      };
    case GET_TALENT_NAME_FILTER_SUCCESS:
      return {
        ...state,
        talentNameData: payload,
        talentNameLoading: false,
      };
    case GET_TALENT_NAME_FILTER_FAILURE:
      return {
        ...state,
        talentNameData: payload,
        talentNameLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentNameReducer;
