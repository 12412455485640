import React, { useRef, useState } from "react";

const TalentBulkActions = ({
  filterElements,
  filterSkills,
  filterKeyword,
  isFilterEnabled,
  selectedTalents,
  getUserRole,
  areAllUnsubbed,
  handleAction,
  selectedData,
}) => {
  const [toggleActions, setToggleActions] = useState(false);
  const dropdownRef = useRef(null);

  const toggleActionsDropdown = () => {
    setToggleActions((prev) => !prev);
  };

  return (
    <div className="d-flex justify-content-between mb-2">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-block">
          {filterElements} {filterSkills} {filterKeyword}
        </div>
      </div>

      {isFilterEnabled() &&
        selectedTalents.length > 0 &&
        (getUserRole() === "Recruiter" ||
          getUserRole() === "CustomerSuccess" ||
          getUserRole() === "SuperAdmin") && ( //remove super admin condition. added for test purpose
          <div className="d-block" ref={dropdownRef}>
            <div className="dropdown">
              <button
                className="btn btn-sm dropdown-toggle"
                type="button"
                onClick={toggleActionsDropdown}
                style={{ zIndex: 90 }}
                disabled={areAllUnsubbed()}
              >
                Actions
              </button>
              <div>
                {toggleActions && (
                  <div
                    className="dropdown-menu"
                    style={{
                      display: "block",
                      right: "-4px",
                      top: "32px",
                      zIndex: 89,
                    }}
                  >
                    <div>
                      <button
                        onClick={() => handleAction("inviteToJob")}
                        className="dropdown-item btn-xs"
                      >
                        Invite to Job
                      </button>
                      <hr className="p-0 m-1" />
                    </div>
                    <div>
                      <button
                        onClick={() => handleAction("inviteToSubComm")}
                        className="dropdown-item btn-xs"
                      >
                        Invite to Community
                      </button>
                      <hr className="p-0 m-1" />
                    </div>
                    <div>
                      <button
                        onClick={() => handleAction("bulkTag")}
                        className="dropdown-item btn-xs"
                      >
                        Bulk Tag
                      </button>
                      <hr className="p-0 m-1" />
                    </div>
                    <div>
                      <button
                        onClick={() => handleAction("email")}
                        disabled={
                          selectedData?.subscriptionStatus[0]?.value ===
                          "Unsubscribed"
                        }
                        className="dropdown-item btn-xs"
                      >
                        Email/SMS
                      </button>
                      <hr className="p-0 m-1" />
                    </div>
                    <div>
                      <button
                        onClick={() => handleAction("unsubscribe")}
                        className="dropdown-item btn-xs"
                      >
                        Move to Unsubscribed
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default TalentBulkActions;
