import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { getUserRole } from "../../../../../service/AuthService";

const DispositionHeader = ({ view, setView, Tab }) => {
  const dispositionCount = useSelector(
    (state) => state.jobDispositionReducer.dispositionCount,
    shallowEqual
  );

  const role = getUserRole();
  const isRecruiter = role === "Recruiter";
  const isCM = role === "CommunityManager";

  const statusViewMap = {
    38: isRecruiter || isCM ? "automatched" : "qualified",
    33: "invite",
    6: "submitted",
    8: "shortlist",
    32: "offered",
    11: "hired",
  };

  const handleTab = (status) => {
    setView(statusViewMap[status] || (isRecruiter || isCM ? "automatched" : "qualified"));
  };

  useEffect(() => {
    if (Tab) {
      handleTab(Number(Tab));
    } else {
      setView(isRecruiter || isCM ? "automatched" : "qualified");
    }
  }, [Tab]);

  const countHandler = (type) => {
    const countData = dispositionCount.find((e) => e.Status.toLowerCase() === type.toLowerCase());
    return countData?.Count || 0;
  };

  const tabs = [
    { key: "automatched", label: "Automatched", visible: isRecruiter || isCM },
    { key: "qualified", label: "Invited", visible: !isRecruiter && !isCM },
    { key: "invite", label: "Invited", visible: isRecruiter || isCM },
    { key: "submitted", label: "Submitted", visible: true },
    { key: "shortlisted", label: "Shortlisted", visible: true },
    { key: "offered", label: "Offered", visible: true },
    { key: "hired", label: "Hired", visible: true },
  ];

  return (
    <div className="card-body pb-0 d-flex flex-column justify-content-between">
      <div className="d-flex">
        <p className="px-1">Active Candidates</p>
      </div>
      <ul className="nav d-flex gap-5 nav-tabs justify-content-between" style={{ overflowX: "auto" }}>
        {tabs.map(
          (tab) =>
            tab.visible && (
              <li key={tab.key} className="nav-item w-25 d-flex flex-column justify-content-center">
                <button
                  type="button"
                  className={`nav-link pb-3 ${view === tab.key ? "active" : ""}`}
                  onClick={() => setView(tab.key)}
                >
                  <span className="tag mb-1">{countHandler(tab.key)}</span>
                  <div>{tab.label}</div>
                </button>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default DispositionHeader;
