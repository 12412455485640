import React from "react";
import { getCandidateName } from "../../../service/AuthService";
import "bootstrap/dist/css/bootstrap.min.css";

const NameAndOwner = ({ setAddJobData, addJobData, addJobVal }) => {
  const name = getCandidateName();
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-floating">
                <input
                  type="text"
                  className={
                    addJobVal.poolName && addJobData.poolName.trim() === "" 
                      ? "form-control border border-danger w-100"
                      : "form-control"
                  }
                  name="poolName"
                  placeholder="Enter a owner name"
                  onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      poolName: e.target.value,
                    })
                  }
                  value={addJobData.poolName}
                />
                <label>Community Name*</label>
              </div>
              {addJobVal.poolName && addJobData.poolName.trim() === "" && (
                <small className="validation ml-2">*Required field</small>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default NameAndOwner;
