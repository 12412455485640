import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import { ButtonLoader } from "../../../constants/Constants";
import { contactInfoValidation } from "../../validation/Profile";
import phoneCode from "../../../constants/PhoneCode";
import { checkEmptyObject } from "../../functions/CommonFunction";
import { getCandidateId } from "../../../service/AuthService";
import GoogleLocationCountryAPI from "../../location/GoogleLocationCountryAPI";

function ContactInfo(props) {
  const { setLoading } = props;
  const searchInput = useRef(null);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [contactInfoView, setContactInfoView] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    workTelephoneNo: "",
    homeTelephoneNo: "",
    homeTelephoneCode: "",
    mobile: "",
    mobilePhoneCode: "",
  });
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    country: "",
    workTelephoneNo: "",
    homeTelephoneNo: "",
    homeTelephoneCode: "",
    mobile: "",
    mobilePhoneCode: "",
  });

  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
  });

  const [contactValView, setContactValView] = useState({
    firstName: "",
    lastName: "",
    email: "",
    CountryName: "",
  });

  const [phoneValidation, setPhoneValidation] = useState({
    homeTelephoneNo: true,
    mobile: true,
  });

  const { contactInfoData } = useSelector(
    (state) => ({
      contactInfoData: state.profileInfoReducer.profileDate,
    }),
    shallowEqual
  );

  const phCode = Object.entries(phoneCode).map((item) => item[1].code);

  const handleChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.id]: e.target.value });
  };

  const handlePhoneChange = (mobileId, codeId, value, country) => {
    const mobile = value.replace(country.dialCode, "");
    setContactInfo({
      ...contactInfo,
      [mobileId]: mobile,
      [codeId]: `+${country.dialCode}`,
    });
  };
  //  get contact info from API
  useEffect(() => {
    if (contactInfoData && contactInfoData.length > 0) {
      const result = contactInfoData[0];
      const {
        firstName,
        lastName,
        email,
        CountryName,
        homeTelephoneNo,
        mobile,
        workTelephoneNo,
        updatedby,
        candidateId,
        mobilePhoneCode,
        homeTelephoneCode,
      } = result;
      setContactInfo({
        ...contactInfo,
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: CountryName,
        homeTelephoneNo: homeTelephoneNo,
        mobile: mobile,
        workTelephoneNo: workTelephoneNo,
        mobilePhoneCode: mobilePhoneCode,
        homeTelephoneCode: homeTelephoneCode,
        updatedby: updatedby,
        candidateId,
      });
      setContactInfoView({
        ...contactInfoView,
        firstName: firstName,
        lastName: lastName,
        email: email,
        country: CountryName,
        homeTelephoneNo: homeTelephoneNo,
        mobile: mobile,
        workTelephoneNo: workTelephoneNo,
        mobilePhoneCode: mobilePhoneCode,
        homeTelephoneCode: homeTelephoneCode,
        updatedby: updatedby,
        candidateId,
      });
    }
  }, [contactInfoData]);

  const postContactInfo = async (params) => {
    try {
      const candidateId = getCandidateId();
      const paramters = {
        ...params,
        country: params.country,
        homeTelephoneNo: params.homeTelephoneNo,
        homeTelephoneCode: params.homeTelephoneCode,
        mobile: params.mobile,
        mobilePhoneCode: params.mobilePhoneCode,
        workTelephoneNo: params.workTelephoneNo,
        updatedby: params.updatedby,
        candidateId,
      };
      setLoading(true);
      const res = await post("/profile/savecontactinfo", paramters);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Contact Info has been  successfully updated .");
        const LSData = JSON.parse(localStorage.getItem("userToken"));
        LSData.candidateName = params.firstName + " " + params.lastName;
        localStorage.setItem("userToken", JSON.stringify(LSData));
        setContactInfoView({
          ...contactInfoView,
          firstName: params.firstName,
          lastName: params.lastName,
          email: params.email,
          country: params.country,
          homeTelephoneNo: params.homeTelephoneNo,
          homeTelephoneCode: params.homeTelephoneCode,
          mobile: params.mobile,
          mobilePhoneCode: params.mobilePhoneCode,
          workTelephoneNo: params.workTelephoneNo,
          updatedby: params.updatedby,
          candidateId,
        });
        props.getProfile();
      }
    } catch (error) {
      toast.error("Error occured ");
    }
  };

  const editContactInfo = () => {
    setReadOnlyMode(false);
  };

  const cancelContactInfo = () => {
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setContactInfo(contactInfoView);
    setContactVal(contactValView);
  };

  const saveContactInfo = () => {
    const contactInfoVal = contactInfoValidation(contactInfo);
    if (!checkEmptyObject(contactInfoVal)) {
      setContactVal(contactInfoVal);
    } else if (Object.values(phoneValidation).some((i) => i === false)) {
      toast.error("Please enter valid phone");
    } else if (contactInfo?.mobile == "") {
      toast.error("Please enter mobile phone");
    } else {
      setSaveClicked(false);
      if (!submittingForm) {
        setSubmittingFrom(true);
        // save data
        setContactVal(contactInfoVal);
        setSubmittingFrom(true);
        setTimeout(() => {
          setReadOnlyMode(true);
          setSubmittingFrom(false);
          setSaveClicked(true);
          postContactInfo(contactInfo);
        }, 1000);
      } else {
        setSubmittingFrom(false);
      }
    }
  };

  const _onChangeText = (value, code, name) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      setPhoneValidation({ ...phoneValidation, [name]: asYouType.isValid() });
    } else {
      setPhoneValidation({ ...phoneValidation, [name]: true });
    }
  };

  const setLocation = (address, searchInput) => {
    setContactInfo({ ...contactInfo, country: address.country });
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Contact Info</h6>

                {readOnlyMode ? (
                  <button
                    type="button"
                    v-if="form.contact.readOnly"
                    className="btn btn-text"
                    onClick={editContactInfo}
                  >
                    <i className="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelContactInfo}
                      className="btn btn-sm btn-secondary mr-3"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveContactInfo}
                      className="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />

              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={
                          contactVal.firstName
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="firstName"
                        placeholder="First Name*"
                        style={{
                          border:
                            contactInfoValidation.firstName &&
                            "2px solid #ff0000",
                        }}
                        value={contactInfo.firstName}
                        onChange={handleChange}
                        disabled={readOnlyMode}
                      />
                      <label>First Name*</label>
                    </div>
                    {contactVal.firstName && (
                      <small className="validation">
                        {contactVal.firstName}
                      </small>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={
                          contactInfo.lastName == ""
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="lastName"
                        placeholder="Last Name*"
                        style={{
                          border:
                            contactInfoValidation.lastName &&
                            "2px solid #ff0000",
                        }}
                        value={contactInfo.lastName}
                        onChange={handleChange}
                        disabled={readOnlyMode}
                      />
                      <label >Last Name*</label>
                    </div>
                    {contactInfo.lastName == "" && (
                      <small className="validation">
                        {contactVal.lastName}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email*"
                        style={{
                          border:
                            contactInfoValidation.email && "2px solid #ff0000",
                        }}
                        value={contactInfo.email}
                        onChange={handleChange}
                        disabled={readOnlyMode}
                      />
                      <label>Email*</label>
                    </div>
                    <small className="validation">{contactVal.email}</small>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating">
                      {!readOnlyMode ? (
                        <GoogleLocationCountryAPI
                          setLocation={setLocation}
                          searchInput={searchInput}
                          contactInfo={contactInfo}
                          setContactInfo={setContactInfo}
                          className={
                            contactVal.country
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          className={
                            contactVal.country
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          id="country"
                          placeholder="country"
                          style={{
                            border:
                              contactInfoValidation.country &&
                              "2px solid #ff0000",
                          }}
                          value={contactInfo.country}
                          disabled={readOnlyMode}
                        />
                      )}
                      <label>Country*</label>
                      {contactVal.country && (
                        <small className="validation">
                          {contactVal.country}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-6">
                    <div className="mb-2">
                      <label>Mobile Phone*</label>
                    </div>
                    <div className="d-flex">
                      <PhoneInput
                        className={
                          contactInfo?.mobile == ""
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        required="true"
                        placeholder="mobile"
                        id="homePhone"
                        country={"us"}
                        value={
                          contactInfo.mobilePhoneCode
                            ? `${contactInfo.mobilePhoneCode}${contactInfo.mobile}`
                            : contactInfo.mobile
                        }
                        name="homePhone"
                        onChange={(value, country) => {
                          _onChangeText(
                            value.replace(country.dialCode, ""),
                            country.countryCode.toUpperCase(),
                            "mobile"
                          );
                          handlePhoneChange(
                            "mobile",
                            "mobilePhoneCode",
                            value,
                            country
                          );
                        }}
                        disabled={readOnlyMode}
                      />
                    </div>
                    {!phoneValidation.mobile && (
                      <small className="validation">Invalid Phone</small>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-2">
                      <label>Home Phone</label>
                    </div>
                    <div className="d-flex">
                      <PhoneInput
                        className="form-control"
                        placeholder="Home Phone"
                        id="homeTelephoneNo"
                        country={"us"}
                        value={
                          contactInfo.homeTelephoneNo ??
                          contactInfo.homeTelephoneCode
                            ? `${contactInfo.homeTelephoneCode}${contactInfo.homeTelephoneNo}`
                            : contactInfo.homeTelephoneNo
                        }
                        name="homeTelephoneNo"
                        onChange={(value, country) => {
                          _onChangeText(
                            value.replace(country.dialCode, ""),
                            country.countryCode.toUpperCase(),
                            "homeTelephoneNo"
                          );
                          handlePhoneChange(
                            "homeTelephoneNo",
                            "homeTelephoneCode",
                            value,
                            country
                          );
                        }}
                        disabled={readOnlyMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactInfo;
