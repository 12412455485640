import React from "react";

const Failure = ({ message }) => {
  return (
    <div className="bl-modal reveal-overlay reveal-card">
      <div className="bl-modal-overlay"></div>
      <div className="bl-modal-container">
        <div className="bl-modal-card bl-modal-card-md">
          <div className="bl-modal-body text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3 mb-3">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <div className="mb-3">
              <h4>{message}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Failure;
