import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import Invite from "./Invite";
import NoRecord from "../../../../../common/NoRecord";
import { getUserRole } from "../../../../../../service/AuthService";
import Pagination from "../../../../../common/pagination/Pagination";
import { getJobDispositionDetails } from "../../../../../../action/disposition/job/jobdetails/JobDetails";

const Index = (props) => {
  const { jobData, value, view } = props;
  const [invitedData, setInvitedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();

  const { dispositionDetails, dispositionCount } =
    useSelector(
      (state) => ({
        dispositionDetails: state.jobDispositionReducer.dispositionDetails,
        dispositionCount: state.jobDispositionReducer.dispositionCount,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (dispositionDetails.length > 0) {
      let filter = dispositionDetails
        .filter(
          (item) =>
            item.dispositionStatus === 33 &&
            new RegExp(value, "i").test(item.name)
        )
        .sort((a, b) => b.invitedDate.localeCompare(a.invitedDate));

      setInvitedData(filter);
    } else {
      let sortData = dispositionDetails
        .filter((item) => item.dispositionStatus === 33)
        .sort((a, b) => b.invitedDate.localeCompare(a.invitedDate));
      setInvitedData(sortData);
    }
  }, [dispositionDetails, value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payload));
  };

  return (
    <div>
      {invitedData.length > 0 ? (
        <>
          <table className="table table-hoverable table-sortable table-cards-1024">
            <thead>
              <tr>
                <th className="d-flex align-items-start">Candidate</th>
                <th>Invited on</th>
                <th className="no-hover">Resumes </th>
                {getUserRole() === "Recruiter" && (
                  <th className="no-hover">Email status </th>
                )}
                <th className="no-hover" />
              </tr>
            </thead>
            <tbody>
              <Invite jobData={jobData} invitedData={invitedData} view={view} />
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Invite")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
