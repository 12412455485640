import React from "react";
import SkillsSearchable from "../../../common/skills/SkillsSearchable";
import { toast } from "react-toastify";

const SecondarySkills = (props) => {
  function containsAlphabet(str) {
    return /[a-zA-Z]/.test(str);
  }
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <div className="form-floating" style={{ zIndex: "998" }}>
            <div className="mb-2">
              <label>Nice to have skills</label>{" "}
              <SkillsSearchable
                onChange={(skills) => {
                  if (!containsAlphabet(skills[skills?.length - 1])) {
                    return toast.error("Please enter a valid skill");
                  }
                  props?.setAddJobData({
                    ...props?.addJobData,
                    secondarySkills: skills,
                  });
                }}
                inputArr={props?.addJobData.secondarySkills}
                skillinput={props?.secondarySkillInput}
                onChangeInput={(skill) => {
                  props?.setSecondarySkillInput(skill);
                }}
                inputProps={{
                  placeholder: props?.secondarySkillsInput
                    ? ""
                    : "Enter skills",
                }}
              />
              {/* <TagsInput
                renderInput={props?.autocompleteRenderInput}
                value={props?.addJobData.secondarySkills}
                onChange={(skills) =>
                  props?.setAddJobData({
                    ...props?.addJobData,
                    secondarySkills: skills,
                  })
                }
                inputValue={props?.secondarySkillsInput}
                onChangeInput={(skills) =>
                  props?.setSecondarySkillSInput(skills)
                }
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: props?.secondarySkillsInput
                    ? ""
                    : "Enter skills",
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondarySkills;
