import {
  GET_TALENT_HISTORY_BEGIN,
  GET_TALENT_HISTORY_SUCCESS,
  GET_TALENT_HISTORY_FAILURE,
} from "../../action/talent/GetTalentHistory";

const initialState = {
  talentHistoryLoading: true,
  talentHistoryData: null,
};

const talentHistoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_HISTORY_BEGIN:
      return {
        ...state,
        talentHistoryLoading: true,
        talentHistoryData: null,
      };
    case GET_TALENT_HISTORY_SUCCESS:
      return {
        ...state,
        talentHistoryData: payload,
        talentHistoryLoading: false,
      };
    case GET_TALENT_HISTORY_FAILURE:
      return {
        ...state,
        talentHistoryData: null,
        talentHistoryLoading: false,
      };
    default:
      return state;
  }
};

export default talentHistoryReducer;
