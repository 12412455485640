import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import ReactGa from "react-ga";
import { createBrowserHistory } from "history";
import { isAuthenticatedUser } from "./service/AuthService";
import PublicRoutes from "./routes/PublicRoute";
import PrivateRoutes from "./routes/PrivateRoute";
import { ga_tracking_id } from "./constants/Constants";
import { getUserName } from "./service/AuthService";

const authentication = () =>
  isAuthenticatedUser() ? <Redirect to="/high5Hire" /> : <PublicRoutes />;

const customHistory = createBrowserHistory();
function initializeAnalytics() {
  ReactGa.initialize(ga_tracking_id, {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123234324234,
    },
  });
  ReactGa.pageview(window.location.pathname + window.location.search);
  customHistory.listen((location) => {
    const idVal = getUserName();
    ReactGa.set({ page: `userId:${idVal}/${location.pathname}` }); // Update the user's current page
    ReactGa.pageview(`userId:${idVal}/${location.pathname} hi22`); // Record a pageview for the given page
  });
}
initializeAnalytics();

function App() {
  return (
    <Router>
      <ToastContainer position="top-center" autoClose={"5000"} />
      <Switch>
        <Route path="/high5Hire" component={PrivateRoutes} />
        <Route path="" render={authentication} />
      </Switch>
    </Router>
  );
}

export default App;
