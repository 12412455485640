import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ShortUniqueId from "short-unique-id";
import moment from "moment";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

import { post } from "../../../../service/ApiService";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../loader/Loader";
import PaginationComponent from "../../../common/pagination/Pagination";
import ConfirmModal from "./ConfirmModal";

const uid = new ShortUniqueId({ length: 10 });

const SubCommModal = ({
  showjobModal,
  setShowJobModal,
  selectedTalents,
  setSelectedTalents,
  tenantId,
  jobData,
}) => {
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [paginatedPools, setPaginatedPools] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    getPoolFromDB(1);
  }, [pageSize]);

  const getPoolFromDB = async (pageNumber) => {
    setLoading(true);
    const payload = {
      currentPage: pageNumber ? pageNumber : currentPage,
      sortQuery: [],
      pageSize: pageSize,
      searchText: "",
      type: "",
      clientId: tenantId,
    };

    try {
      const res = await post("/community/getcommunitylist", payload);
      let poolArray = res.data.data.sort((a, b) => +b.id_pool - +a.id_pool);
      setTotalCount(res.data.total);
      setPools(poolArray);
      setFilteredPools(poolArray);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    let searchPoolName = e.target.value;
    setSearchQuery(searchPoolName);
    const filteredArray = pools.filter(
      (pool) =>
        pool.poolname.toLowerCase().includes(searchPoolName.toLowerCase()) ||
        (pool.poolId + "").includes(searchPoolName.toLowerCase())
    );

    setFilteredPools(filteredArray);
  };

  const handleSelectAll = () => {
    let data = pools.map((x) => ({ poolId: x.poolId, poolname: x.poolname }));
    setSelectedJobs(data);
  };

  const handleConfirmMove = () => {
    // setShowJobModal(false);
    setShowConfirmModal(true);
  };

  const handleInvite = () => {
    try {
      setLoading(true);

      let responseData = selectedTalents.map((eachData) => {
        eachData.InviteURLId = uid.rnd();
        return eachData;
      });

      let payload = {
        candidates: responseData,
        pools: selectedJobs,
        tenantId: tenantId,
        communityName: jobData?.poolname,
        clientName: jobData?.tenantname,
      };

      post("/talent/bulkTalentSubCommunity", payload).then((res) => {
        setLoading(false);
        setShowJobModal(false);
        setSelectedTalents([]);
        if (res.data.message === "Talents Exists") {
          toast.success(
            `Talent${
              selectedTalents.length > 1 ? "s" : ""
            } already exist in this community.`
          );
        } else {
          toast.success(
            `Talent${
              selectedTalents.length > 1 ? "s" : ""
            } invited successfully.`
          );
        }
      });
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const togglePoolSelect = (item) => {
    let poolId = item?.poolId;
    let poolname = item?.poolname;

    let data = {
      poolId: poolId,
      poolname: poolname,
    };

    setSelectedJobs([data]);
    // setSelectedJobs((prev) =>
    //   prev.some((x) => x.poolId === poolId)
    //     ? prev.filter((c) => c.poolId != poolId)
    //     : [...prev, data]
    // );
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    getPoolFromDB(selectedPage);
  };
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };
  const clearSearch = () => {
    setSearchQuery("");
    setFilteredPools(pools);
  };
  return (
    <>
      {loading && <Loader />}
      {!showConfirmModal && (
        <Modal show={showjobModal} size="lg">
          <div className="modal-header" id="NewCandidateModal">
            <h6>Select Community</h6>
            {showjobModal && (
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowJobModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <Modal.Body>
            <div className="row d-block-force  p-4">
              <div className=" d-flex gap-2  align-items-center justify-content-end mb-2">
                {" "}
                <div className="">
                  <select
                    className="form-select font-14"
                    onChange={handlePageSizeChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
                <div
                  className="input-group"
                  style={{ border: "1px solid #ddd", width: "200px" }}
                >
                  <input
                    type="text"
                    className="form-control ml-1"
                    placeholder="Search by Title, Id"
                    style={{ border: "1px solid transparent" }}
                    value={searchQuery}
                    onChange={handleSearch}
                  />

                  {searchQuery && (
                    <div className="input-group-append">
                      <button
                        className="btn"
                        type="button"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid transparent",
                          boxShadow: "none",
                        }}
                        onClick={clearSearch}
                      >
                        <FaTimes color={"grey"} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {pools?.map((item, index) => (
                <div className="mb-2" key={index}>
                  <div className="card ">
                    <div className="card-body p-3">
                      <div className=" d-flex align-items-start justify-content-between">
                        <div className="d-flex align-items-center justify-content-between  w-100">
                          <div className="text-left">
                            <p>
                              <strong>
                                {item?.poolname} ({item.poolId})
                              </strong>{" "}
                              <br />
                              <span>
                                <small>
                                  <i className="far fa-map-marker-alt mr-2" />
                                  {item?.country}
                                </small>
                              </span>
                            </p>
                          </div>
                          <div className=""></div>
                        </div>
                        {/* <div className="d-flex  align-items-center align-self-end justify-content-end mb-1"> */}
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem", borderRadius: "100" }}
                          checked={selectedJobs.some(
                            (x) => x.poolId == item.poolId
                          )}
                          onClick={() => {
                            togglePoolSelect(item);
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="text-muted my-auto p-0">
                          <span>Created by: {item?.poolowner}</span>
                        </div>

                        <div className="text-muted my-auto p-0">
                          Created on:{" "}
                          <span>
                            {moment(item.createddatetime).format("MM/DD/YYYY")}
                          </span>
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {filteredPools.length > pageSize && (
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={totalCount}
                  itemsPerPage={pageSize}
                  onPageChange={handlePageChange}
                  limit={5}
                />
              )}
            </div>
            {pools?.length === 0 && <NoRecord />}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowJobModal(false)}
            >
              Cancel{" "}
            </button>
            <button disabled={selectedJobs.length === 0} onClick={handleInvite}>
              Invite
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {showConfirmModal && (
        <ConfirmModal
          showConfirmModal={showConfirmModal}
          setShowConfirmModal={setShowConfirmModal}
          handleInvite={handleInvite}
          selectedTalents={selectedTalents}
          selectedJobs={selectedJobs}
          setShowJobModal={setShowJobModal}
          setSelectedTalents={setSelectedTalents}
          isFrom="moveToCommunity"
        />
      )}
    </>
  );
};

export default SubCommModal;
