import React from "react";
import moment from "moment";
import { Card, Row, Col } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { MdSupervisorAccount } from "react-icons/md";
import ProfileImage from "../common/profileimage/ProfileImage";
import { useHistory } from "react-router-dom";
import { SidebarLogo } from "../../constants/Constants";

const ClientCard = ({ client, handleClient }) => {
  const history = useHistory();
  return (
    <Card
      style={{
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        history.push(`/high5hire/talentcommunity/client/${client?.id_tenant}`);
      }}
    >
      <Card.Body>
        <Row className="d-flex justify-content-between">
          <Col md={2}>
            <Card.Title>
              <img
                src={client?.logourl || SidebarLogo}
                width="60"
                height="60"
                className="p-2 ml-2"
                style={{
                  borderRadius: "50%",
                  objectFit: "contain",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                alt={`${client?.tenantname} logo`}
              />
            </Card.Title>
          </Col>
          <Col md={9} sm={9}>
            <Card.Title>
              <span
                style={{ fontSize: "15px", cursor: "pointer" }}
                onClick={() => handleClient(client?.tenantname)}
              >
                <b>
                  <a className="text-black">
                    <strong title="Client Name">{client?.tenantname}</strong>
                  </a>
                </b>
              </span>
            </Card.Title>
            <Card.Text>
              <span className="text-center" style={{ fontSize: "13px" }}>
                <IoLocationOutline color="#009eff" size={18} className="mr-1" />
                {client?.country}
              </span>
              <br />
              <span className="text-center" style={{ fontSize: "13px" }}>
                <MdSupervisorAccount
                  color="#009eff"
                  size={18}
                  className="mr-1"
                />
                Community Count:{" "}
                {JSON.parse(client?.communitycount)[0]?.communitycount}
              </span>
            </Card.Text>
            <Card.Text>
              <div>
                {JSON.parse(client?.CountDetails)?.totalCount == 0 ? (
                  <span className="">
                    <small>No talent pooled</small>
                  </span>
                ) : (
                  <span className="" style={{ cursor: "default" }}>
                    <div class="font-small font-primary mb-2">Talent(s)</div>
                    <small title="Talent(s)">
                      {JSON.parse(client?.candidateName)
                        ?.slice(0, 3)
                        ?.map((obj, i) => (
                          <div
                            key={i}
                            className="avatar-name-candidatestableview d-inline-flex align-items-center"
                          >
                            <span
                              style={{
                                display: "inline-block",
                              }}
                            >
                              <ProfileImage
                                imageSrc={obj?.profileImagePath}
                                imageWidth={"35"}
                                imageHeight={"35"}
                                avatarSize={"35"}
                                avatarRound={"80px"}
                                firstName={obj.name.split(" ")[0]}
                                lastName={obj?.name.split(" ")[1]}
                              />
                            </span>
                          </div>
                        ))}
                      {JSON.parse(client?.CountDetails)?.totalCount > 3 && (
                        <span className="ml-1">
                          +
                          {new Intl.NumberFormat().format(
                            JSON.parse(client?.CountDetails)?.totalCount - 3
                          )}
                        </span>
                      )}
                    </small>
                  </span>
                )}
              </div>
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-white font-gray1">
        <div className="row">
          <div className="col-md-12">
            <span style={{ fontSize: "13px" }}>
              Created on: {moment(client.createddatetime).format("MM/DD/YYYY")}
            </span>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ClientCard;
