import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Notifications from "./Notifications";

const NotificationBell = () => {
  const [toggleNotification, setToggleNotification] = useState(false);
  //   const { notificationData, notificationLoading } = useSelector(
  //     (state) => ({
  //       notificationData: state.getNotificationsReducer.notificationData,
  //     }),
  //     shallowEqual
  //   );
  const notificationData = { data: [] };
  const notificationLoading = false;
  return (
    <>
      <button
        type="button"
        title="Notifications"
        className="btn btn-action-panel btn-icon btn-nav ml-3"
        onClick={() => setToggleNotification(!toggleNotification)}
      >
        <div class="notification-icon">
          {" "}
          <i class="fal fa-fw fa-bell"></i>{" "}
          {notificationData?.data?.filter(
            (n) => n.notificationStatus === "unread"
          )?.length > 0 && (
            <span class="notification-count">
              {
                notificationData?.data?.filter(
                  (n) => n.notificationStatus === "unread"
                )?.length
              }
            </span>
          )}
        </div>
      </button>
      {toggleNotification && (
        <div
          className="dropdown-menu"
          style={{ display: "revert", right: "0px", top: "50px" }}
        >
          <Notifications
            notificationData={notificationData}
            notificationLoading={notificationLoading}
            setToggleNotification={setToggleNotification}
          />
        </div>
      )}
    </>
  );
};

export default NotificationBell;
