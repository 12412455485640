import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import AdditionalInfo from "../../../addcommunity/AdditionalInfo";
import { toast } from "react-toastify";
import {
  getCandidateName,
  getTenantType,
  getUserRole,
  isCurator,
} from "../../../../../service/AuthService";
import { get, post } from "../../../../../service/ApiService";
import Loader from "../../../../common/loader/Loader";
import AdditionalInfoReminderEmail from "./AdditionalInfoRemiderEmail";
import AuditTrail from "./AuditTrail";

const AdditionalQuestions = (props) => {
  const { jobData, setQNA, qna } = props;
  const [updateQuestion, setUpdateQuestion] = useState(false);
  const [showInvitation, setShowInvitation] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState(
    jobData?.additionalInfo
  );
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    let finalAdditionalQuestions = [];
    additionalQuestions?.forEach((item) => {
      if (
        item.name === "Custom Question" &&
        (item.inputType === "Drop-down" ||
          item.inputType === "Drop-down(Multiselect)")
      ) {
        finalAdditionalQuestions.push({
          question_id: item?.question_id ?? null,
          question: item?.question.replace("[X]", item?.selectedOption),
          inputType: item?.inputType,
          name: item?.name,
          input: item?.dropdownOptions,
          id_pool: item?.id_pool ?? null,
          selectedOption: item?.selectedOption ?? "",
          status: "AdditionalInfo",
          createdDateTime: item?.createdDateTime ?? null,
          updatedDateTime: item?.updatedDateTime ?? null,
        });
      } else if (
        item.name === "Custom Question" &&
        !(
          item.inputType === "Drop-down" ||
          item.inputType === "Drop-down(Multiselect)"
        )
      ) {
        finalAdditionalQuestions.push({
          question_id: item?.question_id ?? null,
          question: item?.question.replace("[X]", item?.selectedOption),
          inputType: item?.inputType,
          name: item?.name,
          input: [],
          id_pool: item?.id_pool ?? null,
          selectedOption: item?.selectedOption ?? "",
          status: "AdditionalInfo",
          createdDateTime: item?.createdDateTime ?? null,
          updatedDateTime: item?.updatedDateTime ?? null,
        });
      } else {
        finalAdditionalQuestions.push({
          question_id: item?.question_id ?? null,
          question: item?.question.replace("[X]", item?.selectedOption),
          inputType: item?.inputType,
          name: item?.name,
          input: [],
          id_pool: item?.id_pool ?? null,
          selectedOption: item?.selectedOption ?? "",
          status: "AdditionalInfo",
          createdDateTime: item?.createdDateTime ?? null,
          updatedDateTime: item?.updatedDateTime ?? null,
        });
      }
    });

    let params = {
      additionalInfo: JSON.stringify(finalAdditionalQuestions),
      additionalInfoData: finalAdditionalQuestions,
      ai: JSON.stringify(jobData?.ai),
      poolId: jobData ? jobData?.poolId : "",
      id_pool: jobData ? jobData?.id_pool : "",
      jobTitle: jobData?.jobTitle,
      poolowner:
        getUserRole() === "CustomerSuccess"
          ? `High5 Admin-${getCandidateName()}`
          : `Client Curator-${getCandidateName()}`,
    };

    if (validateFunction().flag) {
      setLoading(true);
      await post("/community/editAdditionalInfo", params)
        .then(async (res) => {
          toast.success("Additional Info Questions updated successfully!");
          setLoading(false);
          setUpdateQuestion(false);
          setQNA(false);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    } else {
      toast.error(validateFunction().error);
    }
  };

  const validateFunction = () => {
    let flag = true;
    let error = "";
    if (additionalQuestions.length > 0) {
      additionalQuestions.forEach((element) => {
        if (element?.options?.length > 0) {
          if (element.selectedOption === "") {
            // toast.error("Please enter the value for Additional Info.");

            flag = false;
            error = "Please select the value for Additional Info.";
          }
        }
        if (element.name === "Custom Question" && element.question === "") {
          // toast.error("Please enter the value for Additional Info.");
          flag = false;
          error = "Please add the custom question for Additional Info.";
        }
        if (
          (element.name === "Custom Question" &&
            element.inputType === "Drop-down") ||
          element.inputType === "Drop-down(Multiselect)"
        ) {
          if (element.dropdownOptions?.length === 0) {
            flag = false;
            error =
              "Please add the options for Additional Info Custom Question.";
          }
        }
      });
    }
    return { flag: flag, error: error };
  };

  const handleUpdateModal = async () => {
    setUpdateQuestion(true);
    setShowInvitation(false);
    setLoading(true);
    await get(`/community/getCommunityById?communityId=${jobData?.poolId}`).then((resp) => {
      const screening = resp?.data?.additionalInfo;
      setAdditionalQuestions(screening);
      setLoading(false);
    });
  };

  const handleInvitationModal = () => {
    setShowInvitation(!showInvitation);
  };
  return (
    <>
      {loading && <Loader />}
      <Modal size="lg" show={qna}>
        <Modal.Header>
          <div className="w-95 d-flex justify-content-between">
            <h6 class="">
              {showInvitation ? "Invitation Overview" : "Additional Info"}{" "}
              {showInvitation && (
                <i
                  className="fa fa-info-circle ml-2"
                  title="All talents who have already filled out the additional info will receive a new invitation to update the modified Additional info."
                ></i>
              )}
            </h6>
            {!updateQuestion &&
              !showInvitation &&
              (getUserRole() === "CustomerSuccess" ||
                (getTenantType() == 1 && isCurator())) &&
              jobData?.isAIEdited && (
                <span>
                  <a
                    style={{ color: "#0d6efd" }}
                    href="#"
                    onClick={handleInvitationModal}
                  >
                    Click Here
                  </a>{" "}
                  to see Invitation Details
                </span>
              )}
          </div>

          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setQNA(false);
              setUpdateQuestion(false);
              setShowInvitation(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {jobData?.additionalInfo.length > 0 ? (
            <div>
              {!showInvitation && (
                <div class="d-flex mb-2 pl-1 ml-4">
                  {!updateQuestion ? (
                    <ul>
                      {(jobData?.additionalInfo).map((item, index) => (
                        <li className="d-flex m-3">
                          <div className="">
                            <span className="col-lg-1">{index + 1}</span>
                          </div>
                          <div className="ml-3 mb-2">
                            <span className="col-lg-11 mb-1">
                              {item.question.replace(
                                "[X]",
                                item?.selectedOption
                              )}
                              {item?.name === "Custom Question" ? "*" : ""}
                            </span>
                            <br />
                            <small>
                              Response Type:{" "}
                              {item?.name === "Salary Requirements" ? (
                                <strong> Range</strong>
                              ) : item?.inputType === "Drop-down" ||
                                item?.inputType === "Drop-down(Multiselect)" ? (
                                <strong> Selective </strong>
                              ) : (
                                <strong> {item?.inputType}</strong>
                              )}
                            </small>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <AdditionalInfo
                      screeningQuestions={additionalQuestions}
                      setScreeningQuestions={setAdditionalQuestions}
                    />
                  )}
                </div>
              )}
              {showInvitation && (
                <AdditionalInfoReminderEmail poolId={jobData?.poolId} />
              )}
            </div>
          ) : (
            <div className="mb-3">
              <div class="text-center p-3">
                <div class="avatar avatar-lg">
                  <i class="fad fa-users-slash"></i>
                </div>
                <div class="mt-2">No Additional Info</div>
              </div>
            </div>
          )}
          {!updateQuestion &&
            !showInvitation &&
            (getUserRole() === "CustomerSuccess" ||
              (getTenantType() == 1 && isCurator())) &&
            jobData?.isAIEdited && (
              <span className="ml-5">
                <a
                  style={{ color: "#0d6efd" }}
                  href="#"
                  onClick={() => setShowAuditTrail(true)}
                >
                  Click Here
                </a>{" "}
                to see Audit Trail
              </span>
            )}
        </Modal.Body>
        <Modal.Footer>
          {updateQuestion ? (
            <>
              <button className="btn btn-secondary" onClick={handleUpdate}>
                Update
              </button>
              <button
                variant="secondary"
                onClick={() => {
                  setQNA(false);
                  setUpdateQuestion(false);
                  setShowInvitation(false);
                }}
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              {!updateQuestion &&
                !showInvitation &&
                (getUserRole() === "CustomerSuccess" ||
                  (getTenantType() == 1 && isCurator())) && (
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateModal}
                  >
                    Edit{" "}
                    {/* <i
                      className="fal fa-pencil fa-fw btn btn-icon"
                      title="Edit"
                    ></i> */}
                  </button>
                )}
              {!updateQuestion &&
                showInvitation &&
                (getUserRole() === "CustomerSuccess" ||
                  (getTenantType() == 1 && isCurator())) && (
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowInvitation(false)}
                  >
                    Back{" "}
                    {/* <i
                      className="fal fa-pencil fa-fw btn btn-icon"
                      title="Edit"
                    ></i> */}
                  </button>
                )}
              <button
                variant="secondary"
                onClick={() => {
                  setQNA(false);
                  setUpdateQuestion(false);
                  setShowInvitation(false);
                }}
              >
                Close
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {showAuditTrail && (
        <AuditTrail jobData={jobData} showAuditTrail={showAuditTrail} setShowAuditTrail={setShowAuditTrail} />
      )}
    </>
  );
};

export default AdditionalQuestions;
