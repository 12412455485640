import React, { useState, useEffect, useRef } from "react";
import GenaralDetails from "../../job/jobdetails/sidepaneldetails/overview/GenaralDetails";
import SkillDetails from "./Skills";
import DescriptionDetails from "../../job/jobdetails/sidepaneldetails/overview/DescriptionDetails";
import DescriptionPopUp from "../../job/jobdetails/sidepaneldetails/overview/DescriptionPopUp";
import EducationDetails from "../../job/jobdetails/sidepaneldetails/details/EducationDetails";
import IndustriesDetails from "../../job/jobdetails/sidepaneldetails/details/IndustriesDetails";
import ShareJob from "../../job/jobdetails/sidepanelheader/sharejob/ShareJob";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import Loader from "../../loader/Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCandidateDetails } from "../../../action/candidate/getCandidateDetails";
import { Modal } from "react-bootstrap";
import { emailDomain } from "../../../constants/Constants";

function JobSidePanel(props) {
  const { tenantData, candidateDetailsLoading, candidateData } = useSelector(
    (state) => ({
      tenantLoading: state.getTenantReducer.tenantLoading,
      tenantData: state.getTenantReducer.tenantData,
      candidateDetailsLoading:
        state.candidateDetailsReducer.candidateDetailsLoading,
      candidateData: state.candidateDetailsReducer.candidateData,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const { jobData } = props;
  const tenantId = jobData?.fk_tenant;
  const [generalCollapse, setGeneralCollapse] = useState(true);
  const [skillCollapse, setSkillCollapse] = useState(false);
  const [descriptionCollapse, setDescriptionCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [share, setShare] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState("");
  const [lgShow, setLgShow] = useState(false);
  const [description, setDescription] = useState(false);
  const [jobDetails, setJobDetails] = useState(true);
  const [enableValue, setenable] = useState(false);
  const [isJobCard, setIsJobCard] = useState(false);
  // const [jobData, setJobData] = useState({});
  // const [loading, setLoading] = useState(false);

  const addFavouriteJob = async () => {
    let jobArray = [];

    if (candidateData?.favouriteJobs == null) {
      let idString = jobData?.jobId.toString();
      jobArray = [jobData?.jobId];
      // props.setIsFav(true);
    } else {
      let favJobArray = JSON.parse(candidateData?.favouriteJobs);
      if (favJobArray.includes(jobData?.jobId)) {
        jobArray = favJobArray.filter((f) => f != jobData?.jobId);
        // props.setIsFav(false);
      } else {
        jobArray = [...favJobArray, jobData?.jobId];
        // props.setIsFav(true);
      }
    }
    const params = {
      candidateId: candidateData?.userId,
      favouriteJobs: JSON.stringify(jobArray),
    };
    setLoading(true);
    await post("/talent/favouritejobs", params)
      .then((res) => {
        const payload = {
          candidateId: sessionStorage.getItem("userId"),
        };

        dispatch(getCandidateDetails(payload));
        if (props.isFrom == "favJobs") {
          toast.success("Job removed as favourite!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setTimeout(() => {
            setLoading(false);

            candidateData?.favouriteJobs.includes(jobData?.jobId)
              ? toast.success("Job added as favourite!")
              : toast.success("Job removed as favourite!");
          }, 5000);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };

    !candidateData && dispatch(getCandidateDetails(payload));
  }, [candidateData]);
  const handleModel = () => {
    setModal(false);
    setConfirmModal(false);
  };
  const handleOfferUpdate = async (e) => {
    if (e.target.name === "reject" && reason === "")
      return toast.error("Please select a reason.");

    setLoader(true);
    let params = {};

    if (e.target.name === "reject") {
      params = {
        status: 29,
        disqualifyComments: `Offer Rejected : ${reason}`,
        disqualifiedDate: new Date(),
        id_disposition: props.offer.id_disposition,
        jobTitle: props.offer.jobTitle,
        jobTenant: props.offer.jobTenant,
        jobId: props.offer.jobId
          ? props.offer.jobId
          : props.offer?.fk_talentPool,
        candidateEmail: candidateData.email,
        candidateFname: candidateData.firstName,
        candidateLname: candidateData.lastName,
        recruiterId: candidateData.recruiterId,
        createdBy: props.offer.createdBy,
        offeredBy: props.offer.offeredBy,
      };
    } else {
      params = {
        status: 11,
        id_disposition: props.offer.id_disposition,
        jobTitle: props.offer.jobTitle,
        jobTenant: props.offer.jobTenant,
        jobId: props.offer.jobId
          ? props.offer.jobId
          : props.offer?.fk_talentPool,
        candidateEmail: candidateData.email,
        candidateFname: candidateData.firstName,
        candidateLname: candidateData.lastName,
        createdBy: props.offer.createdBy,
        joiningDate: props.offer.joiningDate,
        recruiterId: candidateData.recruiterId,
        offeredBy: props.offer.offeredBy,
      };
    }

    try {
      setLoading(true);
      let response = await post("/disposition/updatedisposition", params);
      if (response.status === 200) {
        if (e.target.name !== "reject") {
          toast.success("Offer accepted");
          await SMS("accept");
          await SMS2("accept");
          window.location.reload();
        } else {
          toast.success("Offer declined");
          await SMS("reject");
          await SMS2("reject");
          window.location.reload();
        }
        handleModel();
        setLoader(false);
        setenable(true);
        // getOffers();
      }
    } catch (err) {
      setLoader(false);
      toast.error("Something went wrong");
    }
  };

  const SMS = async (str) => {
    try {
      let phoneNumber =
        candidateData?.workPhone?.replaceAll(" ", "") ||
        `${candidateData?.homePhoneCode}${candidateData?.homePhone?.replaceAll(
          " ",
          ""
        )}`;

      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
      }

      const name = candidateData?.firstName;
      const jobName = props.offer.jobTitle;

      const getContent = async (event) => {
        const req = { event, recipient: '["Candidate"]' };
        const res = await post("/msgtwoway/getSmsContent", req);
        return res.status === 200 ? res.data[0] : null;
      };

      const hiringContent = await getContent("Hired");
      const rejectedContent = await getContent("Offer Declined");

      const contentText = (content) => {
        let messageContent = content?.smsContent || "";
        messageContent = messageContent.replace("[First Name]", name);
        messageContent = messageContent.replace("[Job Title]", jobName);
        messageContent = messageContent.replace(
          "[CareerPageLinkToLogin]",
          `${emailDomain}/careers/jobList`
        );
        return messageContent;
      };

      const payload = [
        {
          recieverNumber: [phoneNumber],
          //   message: `Hi ${name}, Congratulations! You are hired for the ${jobName} job.\nPlease click below to login into the application and officially get started.
          //   Click Here ${emailDomain}/careers/jobList\nThe High5 Team `,
          //   message: `Hi ${name}, You have rejected the ${jobName} job offer.\nPlease click below to log in for more details.
          //   Click Here ${emailDomain}/careers/jobList\nThe High5 Team `,
          message:
            contentText(str === "accept" ? hiringContent : rejectedContent) +
            `\n ${hiringContent?.footer}`,
        },
      ];

      if (phoneNumber.startsWith("+1")) {
        await post("/msgtwoway/telSms", payload);
      } else {
        await post("/massages/sentMassageWhatsapp", payload);
      }
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const SMS2 = async (str) => {
    let name = candidateData?.firstName;
    let jobName = props.offer.jobTitle;
    let tenant = {
      tenant: props.offer.fk_tenant,
      createdBy: props.offer.createdBy,
      // tenant: offer.jobTenant,
    };

    // const numbers = await post("/msgtwoway/getAllNumbers", tenant);
    const numbers = await post("/msgtwoway/getNumbersForOffer", {
      jobId: props.offer.jobId,
      createdBy: props.offer.createdBy,
    });
    const numberData = numbers.data;

    if (numberData.length === 0) return;

    const recepientsDataSms = [];
    const recepientsDataWhatsApp = [];

    await numberData.map((obj) => {
      const recepientDataSms = [];
      const recepientDataWhatsApp = [];

      let home = obj.homePhone ? obj.homePhone : "";
      let mobile = obj.mobilePhone ? obj.mobilePhone : "";
      let work = obj.workPhone ? obj.workPhone : "";

      if (home.charAt(0) != "+") home = `+${obj.homePhone}`;
      if (mobile.charAt(0) != "+") mobile = `+${obj.mobilePhone}`;
      if (work.charAt(0) != "+") work = `+${obj.workPhone}`;

      if (home.charAt(0) == "+" && home.charAt(1) != "1" && home.length > 10)
        recepientDataWhatsApp.push(home.replaceAll(" ", ""));
      if (
        mobile.charAt(0) == "+" &&
        mobile.charAt(1) != "1" &&
        mobile.length > 10
      )
        recepientDataWhatsApp.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) != "1" && work.length > 10)
        recepientDataWhatsApp.push(work.replaceAll(" ", ""));

      if (home.charAt(0) == "+" && home.charAt(1) == "1")
        recepientDataSms.push(home.replaceAll(" ", ""));
      if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
        recepientDataSms.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) == "1")
        recepientDataSms.push(work.replaceAll(" ", ""));

      if (recepientDataSms.length > 0) {
        recepientsDataSms.push({
          recieverNumber: recepientDataSms,
          userId: Math.floor(Math.random() * 1000000),
          message: `Hi ${
            obj.firstName
          }, Just letting you know ${name} has been ${
            str === "accept" ? "hired" : "rejected"
          } for the ${jobName} job.\nClick below to see details.\n${emailDomain}\nThe High5 Team`,
        });
      }

      if (recepientDataWhatsApp.length > 0) {
        recepientsDataWhatsApp.push({
          recieverNumber: recepientDataWhatsApp,
          message: `Hi ${
            obj.firstName
          }, Just letting you know ${name} has been ${
            str === "accept" ? "hired" : "rejected"
          } for the ${jobName} job.\nClick below to see details.\n${emailDomain}\nThe High5 Team`,
        });
      }
    });

    try {
      if (recepientsDataSms.length > 0)
        await post("/msgtwoway/telSms", recepientsDataSms);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
    }
    try {
      if (recepientsDataWhatsApp.length > 0)
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
    }
  };
  const getReasons = async () => {
    setLoader(true);
    try {
      let response = await get("/makeanoffer/getRejectReasons");
      if (response.status === 200) {
        setReasons(response.data);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    getReasons();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className="view-panel show overflow-scroll">
        <div className="view-panel-header mb-3" style={{ height: "150px" }}>
          <div className="px-3 py-2">
            <button
              type="button"
              onClick={() => props.setIsJobCard(false)}
              className="btn btn-icon"
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="px-3">
            <div className=" align-items-center  justify-content-between">
              <div className="d-flex">
                <div className=" mb-1">
                  <h3>
                    <span>{jobData?.jobTitle}</span>
                  </h3>
                </div>
              </div>
              {(props.isFrom == "careerPage" || props.isFrom == "favJobs") && (
                <div
                  className="row d-flex  justify-content-end"
                  style={{ marginTop: "-6px" }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "170px" }}
                  >
                    <div
                      className="dropdown px-1 tag tag-blue p-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Share"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => setShare(true)}
                      style={{ cursor: "pointer", fontSize: "large" }}
                    >
                      <i className="fal fa-share-alt fa-fw-dropdown" />
                    </div>
                    {share && (
                      <ShareJob
                        jobData={props.jobData}
                        share={share}
                        setShare={setShare}
                        isFrom={props.isFrom}
                      />
                    )}
                    <div
                      className="dropdown px-1 tag tag-blue p-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Favourite"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => addFavouriteJob()}
                      style={{ cursor: "pointer", fontSize: "large" }}
                    >
                      {candidateData?.favouriteJobs !== null &&
                      candidateData?.favouriteJobs.includes(jobData?.jobId) ? (
                        <i class="fas fa-heart" style={{ color: "#e1630e" }}>
                          {" "}
                        </i>
                      ) : (
                        <i
                          class="far fa-heart"
                          style={{ color: "#e1630e" }}
                        ></i>
                      )}
                    </div>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() =>
                        window.open(
                          `${emailDomain}/careers/${tenantId}/joblist/jobdescription/${jobData?.jobId}/recruiter/698131846`,
                          "_self"
                        )
                      }
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )}
              {props.isFrom == "offers" && (
                <div className="">
                  <button
                    type="button"
                    class="btn btn-sm btn-blue-disabled mr-1"
                    onClick={() => {
                      setConfirmModal(true);
                    }}
                    // disabled={
                    //   offer?.statusname === "Hired" ||
                    //   offer?.statusname === "Offer Rejected"
                    // }
                  >
                    <span>Accept</span>{" "}
                  </button>{" "}
                  <button
                    type="button"
                    class="btn btn-danger btn-sm btn-blue-disabled"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    <span>Decline</span>{" "}
                  </button>
                </div>
              )}
            </div>
            {/* <div className="row ">
              <span>{`${jobData?.jobType}/ ${jobData?.country}`}</span>
            </div> */}
          </div>
          {/* sdfsgd */}
        </div>
        <div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setGeneralCollapse(!generalCollapse)}
            >
              <i
                aria-hidden="true"
                className={
                  generalCollapse
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
              <span>
                <small>General</small>
              </span>
            </button>
          </div>
          {generalCollapse === true && (
            <GenaralDetails jobData={jobData} isFrom="offerSidepanel" />
          )}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setSkillCollapse(!skillCollapse)}
            >
              <i
                aria-hidden="true"
                className={
                  skillCollapse
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
              <span>
                <small>Required Skills</small>
              </span>
            </button>
          </div>
          {skillCollapse && <SkillDetails jobData={jobData} />}
          {jobData?.jobDescription && (
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => setDescriptionCollapse(!descriptionCollapse)}
              >
                <i
                  aria-hidden="true"
                  className={
                    descriptionCollapse
                      ? "fal fa-angle-up fa-fw"
                      : "fal fa-angle-down fa-fw"
                  }
                />
                <span>
                  <small>Description</small>
                  <span className="ml-1">
                    <i
                      className="fas fa-external-link-square"
                      style={{ fontSize: "small", color: "#FF5F57" }}
                      onClick={() => setShow(true)}
                    />
                  </span>
                </span>
              </button>
            </div>
          )}
          <Modal
            size="lg"
            show={confirmModal}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="interviewPopUpModal"
          >
            <div class="bl-modal-header">
              <h6 class="bl-modal-title">{"Accept offer"}</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleModel}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <>
                <div>
                  <div className="text-center mt-4">
                    <p className=" mb-1">
                      Great! We are glad that you are excited to proceed with
                      this offer.
                    </p>
                    <p className=" ">Would you like to accept the offer?</p>{" "}
                  </div>
                </div>

                <div v-if="!formComplete" className="modal-footer ">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    onClick={handleModel}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleOfferUpdate}
                    name="accept"
                  >
                    Accept
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={modal}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="interviewPopUpModal"
          >
            <div class="bl-modal-header">
              <h6 class="bl-modal-title">{"Decline offer"}</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleModel}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <>
                <div>
                  <div className="text-center">
                    <h6 className="mt-3">{props?.offer?.jobTitle}</h6>
                    <p>
                      {props?.offer?.workplaceType} / {props?.offer?.jobTenant}{" "}
                      / {props?.offer?.jobType}
                    </p>
                  </div>
                </div>
                <div className="mt-3 mb-0 text-center">
                  <p className="mb-0">
                    Are you sure you want to decline this offer?
                  </p>
                </div>
                <div className="card-body px-5 ">
                  <div className="form-floating  ">
                    <select
                      className={`form-select font-14`}
                      id="category"
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {reasons.map((item) => (
                        <option key={item.Id} value={item.ReasonForRejection}>
                          {item.ReasonForRejection}
                        </option>
                      ))}
                    </select>
                    <label for="category">
                      Please select your reason for declining this offer.*
                    </label>
                  </div>
                </div>

                <div v-if="!formComplete" className="modal-footer ">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    onClick={handleModel}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={handleOfferUpdate}
                    name="reject"
                  >
                    Decline
                  </button>
                </div>
              </>
            </Modal.Body>
          </Modal>{" "}
          {descriptionCollapse && <DescriptionDetails jobData={jobData} />}
          {show && (
            <DescriptionPopUp jobData={jobData} setShow={setShow} show={show} />
          )}
          <EducationDetails jobData={jobData} />
          <IndustriesDetails jobData={jobData} />
        </div>
      </div>
    </>
  );
}

export default JobSidePanel;
