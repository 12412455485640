import React from "react";
import { Row, Col } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { IoGridOutline } from "react-icons/io5";
import { AiOutlineFolder } from "react-icons/ai";

const Landing = ({ job, category }) => {
  const getCategoryClass = () => {
    return `landingSec_${category.split(" ")[0]}`;
  };

  return (
    <div
      className={` ${getCategoryClass()} container-fluid p-0 d-flex justify-content-center align-items-center`}
    >
      <Row
        className={`landingContent text-center d-flex justify-content-center align-items-center`}
      >
        {job && Object.keys(job).length !== 0 && (
          <Col md={12}>
            <h2>{job.jobTitle}</h2>
            <p style={{ fontSize: "16px" }}>
              <>
                {job.city || job.country ? (
                  <>
                    <strong className="m-2">{`${job.city}${
                      job.city && job.country ? ", " : ""
                    } ${job.country}`}</strong>
                  </>
                ) : (
                  <>
                    <IoLocationOutline color="white" size={20} />
                    <strong className="m-2">Remote</strong>
                  </>
                )}
              </>{" "}
              {job.industries && JSON.parse(job.industries).length > 0 && (
                <>
                  <IoGridOutline color="white" size={16} />
                  <strong className="m-2">
                    {JSON.parse(job.industries).join(", ")}
                  </strong>
                </>
              )}
              <AiOutlineFolder color="white" size={20} />
              <strong className="m-2">{job.jobType}</strong>
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Landing;
