import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal, OverlayTrigger } from "react-bootstrap";
import SideNavBar from "../layout/SideNavbar";
import TopNavBar from "../layout/TopNavbar";
import Loader from "../loader/Loader";
import Pagination from "../common/pagination/Pagination";
import NoRecord from "../common/NoRecord";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import { getCandidateId, getTenantType } from "../../service/AuthService";
import { AdminFetchAllTags } from "../../action/tagmanagement/adminFetchAllTags";
import EditTag from "./EditTag";
import ActivateDeactivateTag from "./ActivateDeactivateTag";
import Footer from "../layout/Footer";

function TagsTemplate() {
  const dispatch = useDispatch();
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagEvents, setTagsEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [editTag, setEditTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [dataSize, setDataSize] = useState(10);

  const { tagLoading, tagsData } = useSelector(
    (state) => ({
      tagLoading: state.adminTagReducer.tagLoading,
      tagsData: state.adminTagReducer.tagsData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(AdminFetchAllTags());
  }, []);

  useEffect(() => {
    if (tagsData && tagsData?.length > 0) {
      setTagsEvents(tagsData);
    }
  }, [tagsData]);

  const indexOfLastTags = currentPage * dataSize;
  const indexOfFirstTags = indexOfLastTags - dataSize;
  const currentTags = tagEvents?.slice(indexOfFirstTags, indexOfLastTags);

  const openEditModal = (e, x) => {
    setSelectedTag(x);
    setEditTag(true);
  };

  const closeEditPopUp = () => {
    setEditTag(false);
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
  };

  const tagHandler = async (e) => {
    setLoading(true);
    const trimmedTagInput = tagInput.trim();
    if (!trimmedTagInput) {
      toast.error("Tag name cannot be empty");
      setLoading(false);
      return;
    }
    let tagObj = {
      tagName: trimmedTagInput,
      createdBy: getCandidateId(),
      createdDateTime: new Date(),
      tagStatus: 21,
      fk_tenant: 0,
    };
    try {
      let res = await post("/tags/createAdminTags", tagObj);
      if (res.status === 200) {
        if (res.data.message === "tagName Inserted Successfully") {
          toast.success("Tag created successfully");
          setTagInput("");
          dispatch(AdminFetchAllTags());
        } else {
          toast.error("Tag Already Exists");
        }
      }
    } catch (error) {
      toast.error("Something happened. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const handleChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
  };

  const handleSearch = (e) => {
    setCurrentPage(1);
    if (e.target.value) {
      const filterData = tagsData.filter((item) =>
        item.tagName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setTagsEvents(filterData);
    } else {
      setTagsEvents(tagsData);
    }
  };

  return (
    <>
      {tagLoading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 px-5">
                  <h5 className="mb-4">Tags</h5>
                  <div className="d-flex justify-content-between">
                    <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                      <select class="form-select" onChange={handleChange}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="All">All</option>
                      </select>
                    </div>
                    <div className="d-flex">
                      <input
                        type="text"
                        style={{ width: 380 }}
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        placeholder="Enter a tag"
                      />
                      <button
                        type="button"
                        class="btn btn-primary btn-sm ml-1 mr-2"
                        onClick={tagHandler}
                        disabled={tagInput?.length === 0}
                      >
                        Add
                      </button>
                    </div>
                    <div className="d-flex">
                      <form className="d-flex">
                        <input
                          className="form-control me-2"
                          type="search"
                          placeholder="Search Tags"
                          aria-label="Search"
                          onChange={handleSearch}
                        />
                      </form>
                    </div>
                  </div>
                  <hr />
                  {tagEvents.length > 0 ? (
                    <div className="col">
                      <table className="table table-sortable table-hoverable">
                        <thead>
                          <tr>
                            <th className="no-hover pt-0">
                              <div className="d-flex align-items-center">
                                Tag Name
                              </div>
                            </th>
                            <th className="no-hover pt-0">
                              <div className="d-flex align-items-center">
                                Created by
                              </div>
                            </th>
                            <th className="no-hover pt-0">
                              <div className="d-flex align-items-center">
                                Created on
                              </div>
                            </th>
                            {/* <th>
                              <div className="d-flex align-items-center">
                                Status
                              </div>
                            </th> */}
                            <th></th>
                          </tr>
                        </thead>
                        {currentTags &&
                          currentTags.length > 0 &&
                          currentTags.map((x, i) => (
                            <tbody key={i} className="table-bordered">
                              <tr>
                                <td className="p-relative">{x.tagName}</td>
                                <td>{x.createdBy}</td>
                                <td>
                                  {moment(new Date(x.createdDateTime)).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                {/* <td>
                                {x.tagStatus === 21 ? (
                                  <span class="tag tag-green4">Active</span>
                                ) : (
                                  <span class="tag tag-red2">Deactivated</span>
                                )}
                              </td> */}
                                <td class="text-right no-hover">
                                  <div class="d-flex align-items-center justify-content-end">
                                    <OverlayTrigger
                                      trigger="click"
                                      rootClose
                                      placement="right"
                                      overlay={
                                        <div class="dropdown">
                                          <div
                                            class="dropdown-menu dropdown-menu-right"
                                            style={{
                                              display: "revert",
                                              right: "0px",
                                              top: "10px",
                                            }}
                                          >
                                            {/* {x.tagStatus === 21 && ( */}
                                            <button
                                              onClick={(e) => {
                                                openEditModal(e, x);
                                              }}
                                              type="button"
                                              class="dropdown-item"
                                            >
                                              <i class="fas fa-edit mr-2"></i>{" "}
                                              Edit
                                              <a />
                                            </button>
                                          </div>
                                        </div>
                                      }
                                    >
                                      <button
                                        type="button"
                                        class="btn btn-icon dropdown-toggle"
                                      >
                                        <i class="far fa-ellipsis-v"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                      <div class="d-flex align-items-center mt-3 mb-2 flex-wrap-370">
                        <div class="text-center-370 w-100-370 mb-point5-370">
                          Showing{" "}
                          {currentTags?.length === dataSize
                            ? dataSize * currentPage
                            : dataSize * (currentPage - 1) +
                              currentTags?.length}{" "}
                          of {tagEvents?.length}
                        </div>
                        <div class="ml-auto mx-auto-370 d-flex align-items-center">
                          <Pagination
                            currentPage={currentPage}
                            // setCurrentPage={setCurrentPage}
                            // setPaginationArray={setPaginationArray}
                            // data={tagEvents}
                            // dataSize={dataSize}
                            // getTab=""
                            // pageSize={5}
                            totalItems={tagEvents.length}
                            itemsPerPage={dataSize}
                            onPageChange={setCurrentPage}
                            limit={dataSize}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoRecord />
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
          <Modal
            show={DeleteRequestValue}
            onHide={DeleteRequestClose}
            size="active"
          >
            <div class="bl-modal-header pb-4">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close p-0 bl-modal-close-btn"
                style={{ marginTop: "-6px" }}
                onClick={DeleteRequestClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <ActivateDeactivateTag
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
                DeleteRequestClose={DeleteRequestClose}
                getTalentList={AdminFetchAllTags}
              />
            </Modal.Body>
          </Modal>
          <Modal show={editTag} onHide={closeEditPopUp} size="lg">
            <div class="bl-modal-header pb-4">
              <h6>Edit Tag</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close p-0 bl-modal-close-btn"
                style={{ marginTop: "-6px" }}
                onClick={closeEditPopUp}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <EditTag
              setSelectedTag={setSelectedTag}
              selectedFile={selectedTag}
              setShowEditDateModal={setEditTag}
              talentData={tagsData}
              getTalentList={AdminFetchAllTags}
            />
          </Modal>
        </div>
      </body>
    </>
  );
}

export default TagsTemplate;
