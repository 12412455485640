import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import "react-tagsinput/react-tagsinput.css";
import { post } from "../../../service/ApiService";
import { getCandidateId, getUserName } from "../../../service/AuthService";
import { toast } from "react-toastify";
import CommunicationPreference from "./CommunicationPreference";
import PreferredSkills from "./PreferredSkills";
import PreferredLocation from "./PreferredLocation";

const Index = (props) => {
  const curDate = new Date();
  const [topSkillEdit, setTopSkillEdit] = useState(false);
  const [topLoactionEdit, setTopLocationEdit] = useState(false);
  const [topContactEdit, setTopContactEdit] = useState(false);

  const [skillSetList, setSkillSetList] = useState([]);
  const [skillSetTemp, setSkillSetTemp] = useState([]);

  const [preferredLocation, setPreferredLocation] = useState([]);
  const [locationTemp, setLocationTemp] = useState([]);

  //email preference

  const REASON_LIST = {
    noWish: "I no longer wish to receive these emails",
    notRelavent: "These emails are not relavent to me",
    neverSigned: "I never signed up for this mailing list",
  };
  
  const [reason, setReason] = useState({
    noWish: true,
    notRelavent: false,
    neverSigned: false,
    other: false,
  });
  const [commentText, setCommentText] = useState("");

  const [preference, setPreference] = useState({
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  });

  const [notificationType, setNotificationType] = useState({
    daily: true,
    weekly: false,
  });

  const [selectedNotificationType, setSelectedNotificationType] =
    useState("daily");

  const [scheduleSubDate, setScheduleSubDate] = useState(
    moment(new Date(curDate.setDate(curDate.getDate() + 1))).format(
      "YYYY-MM-DD"
    )
  );

  useEffect(() => {
    const params = {
      userId: getCandidateId(),
    };
    getList(params);
    findStatus();
  }, []);

  const findStatus = async () => {
    try {
      props.setLoading(true);
      const res = await post("/unsubscribe/getsubscriptiondetails", {
        email: getUserName(),
      });

      if (res.data.status === "Unsubscribed") {
        setPreference({ ...preference, unsubscribe: true });
      } else {
        if (props?.recData?.notificationType) {
          let tempType = { ...notificationType };

          if (props.recData?.notificationType === "weekly") {
            tempType["weekly"] = true;
            //tempType["realTime"] = false;
            tempType["daily"] = false;
          }
          if (props.recData?.notificationType === "daily") {
            tempType["weekly"] = false;
            //tempType["realTime"] = false;
            tempType["daily"] = true;
          }

          setNotificationType(tempType);
        }
        let temp = { ...preference };
        temp["subscribe"] = true;
        temp["unsubscribe"] = false;
        temp["scheduleSub"] = false;
        setPreference(temp);
      }
      res.data.status === "Unsubscribed" &&
        setPreference({ ...preference, subscribe: false });
      props.setLoading(false);
    } catch (error) {
      props.setLoading(false);
    }
  };

  const toggleEdit = (type) => {
    if (type === "skillSet") {
      if (topSkillEdit === false) {
        setTopSkillEdit(true);
      } else {
        setTopSkillEdit(false);
      }
    }

    if (type === "location") {
      if (topLoactionEdit === false) {
        setTopLocationEdit(true);
      } else {
        setTopLocationEdit(false);
      }
    }
    if (type === "contact") {
      if (topContactEdit === false) {
        setTopContactEdit(true);
      } else {
        setTopContactEdit(false);
      }
    }
  };

  const getList = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/profile/preferences/getpreference", params);
      if (res.status === 200) {
        const result = res.data[0];
        result.primarySkills &&
          setSkillSetList(JSON.parse(result.primarySkills));
        result.primarySkills &&
          setSkillSetTemp(JSON.parse(result.primarySkills));
        result.preferredLocation &&
          setPreferredLocation(JSON.parse(result.preferredLocation));
        result.preferredLocation &&
          setLocationTemp(JSON.parse(result.preferredLocation));
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while accessing Skills and Locations");
    }
  };

  const UpdateSkill = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/profile/preferences/updateskills", params);
      if (res.status === 200) {
        setSkillSetTemp(skillSetList);
        props.setLoading(false);
        toast.success(`Skills updated successfully`);
        setTopSkillEdit(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while saving Skills");
    }
  };

  const UpdateLocation = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/profile/preferences/updatelocation", params);
      if (res.status === 200) {
        setLocationTemp(preferredLocation);
        props.setLoading(false);
        toast.success(`Location updated successfully`);
        setTopLocationEdit(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while saving  Location");
    }
  };

  const handleSubmit = async (type) => {
    const userId = getCandidateId();
    const params = {
      preferredLocation,
      skillSetList,
      userId,
    };

    if (type === "skillSet") {
      if (skillSetList.length === 0) {
        toast.error("No Skills Added");
      } else {
        UpdateSkill(params, type);
      }
    }

    if (type === "location") {
      if (preferredLocation.length === 0) {
        toast.error("No Locations Added");
      } else {
        UpdateLocation(params, type);
      }
    }
    if (type === "emailPreference") {
      if (
        !notificationType.daily &&
        !notificationType.weekly &&
        preference.subscribe
      ) {
        toast.error("Select a notification type");
      } else {
        props.setLoading(true);
        let payload = preference.subscribe
          ? {
              email: getUserName(),
              notificationType: selectedNotificationType,
              subscribe: preference.subscribe,
            }
          : {
              email: getUserName(),
              unsubscribedReason: reason.other
                ? commentText
                : reason.noWish
                ? REASON_LIST["noWish"]
                : reason.notRelavent
                ? REASON_LIST["notRelavent"]
                : REASON_LIST["neverSigned"],
            };

        if (preference.scheduleSub) {
          payload["subscriptionDate"] = scheduleSubDate;
          payload["scheduleSub"] = preference.scheduleSub;
        }
        await post(
          "/profile/preferences/updatecommunicationpreference",
          payload
        );
        props.setLoading(false);
        setEditEmail(false);
        toast.success(`Preference updated successfully`);
      }
    }
  };

  const handleSkillCancel = () => {
    setTopSkillEdit(false);
    setSkillSetList(skillSetTemp);
  };

  const handleLocationCancel = () => {
    setTopLocationEdit(false);
    setPreferredLocation(locationTemp);
  };

  const handleDelete = (val) => {
    let tempArray = [...preferredLocation];
    const index = tempArray.indexOf(val);
    if (index > -1) {
      tempArray.splice(index, 1);
    }
    setPreferredLocation(tempArray);
  };

  const [checked, setChecked] = useState(false);

  const [editEmail, setEditEmail] = useState(false);

  return (
    <>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div className="card card-lg mb-2">
              <div className="card-body">
                <PreferredSkills
                  handleSubmit={handleSubmit}
                  toggleEdit={toggleEdit}
                  setSkillSetList={setSkillSetList}
                  skillSetList={skillSetList}
                  topSkillEdit={topSkillEdit}
                  handleSkillCancel={handleSkillCancel}
                />
                <hr className="mb-2" />
                <PreferredLocation
                  handleSubmit={handleSubmit}
                  topLoactionEdit={topLoactionEdit}
                  toggleEdit={toggleEdit}
                  handleLocationCancel={handleLocationCancel}
                  preferredLocation={preferredLocation}
                  setPreferredLocation={setPreferredLocation}
                  handleDelete={handleDelete}
                />
                <hr className="mb-2" />
                <CommunicationPreference
                  editEmail={editEmail}
                  setEditEmail={setEditEmail}
                  handleSubmit={handleSubmit}
                  reason={reason}
                  setReason={setReason}
                  preference={preference}
                  setPreference={setPreference}
                  setScheduleSubDate={setScheduleSubDate}
                  scheduleSubDate={scheduleSubDate}
                  notificationType={notificationType}
                  setNotificationType={setNotificationType}
                  setSelectedNotificationType={setSelectedNotificationType}
                  setCommentText={setCommentText}
                  commentText={commentText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
