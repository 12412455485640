import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { FaPaperPlane } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import { FcAbout } from "react-icons/fc";
import { Card } from "react-bootstrap";

import { getCandidateId } from "../../../service/AuthService";
import Pagination from "../../common/pagination/Pagination";
import { FetchSendSmsByUser } from "../../../action/communication/Communication";
import Loader from "../../loader/Loader";
import AddRecipients from "../recipients/AddRecipients";
import ProfileImage from "../../common/profileimage/ProfileImage";

const SendSMS = ({ selectedRecipients }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const { sendSMSListLoading, sendSMSList } = useSelector(
    (state) => ({
      sendSMSListLoading: state.communicationReducer.sendSMSListLoading,
      sendSMSList: state.communicationReducer.sendSMSList,
    }),
    shallowEqual
  );
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [name, setName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addRecipientsModal, setAddRecipientsModal] = useState(false);
  const dataSize = 10;

  useEffect(() => {
    fetchData(
      dataSize,
      currentPage,
      fromDate,
      toDate,
      name,
      selectedRecipients
    );
  }, [currentPage]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (name.length === 0 || name.length >= 2) {
        setCurrentPage(1);
        fetchData(dataSize, 1, fromDate, toDate, name, selectedRecipients);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [name]);

  const fetchData = (
    dataSize,
    currentPage,
    fromDate,
    toDate,
    name,
    selectedRecipients
  ) => {
    let selectedTalentsEmail = [];
    selectedTalentsEmail = selectedRecipients?.map((obj) => obj.email);
    const reqPayload = {
      userId: getCandidateId(),
      pageSize: dataSize,
      page: currentPage,
      fromDate: fromDate,
      toDate: toDate,
      name: name,
      direction: "outbound",
      email: selectedTalentsEmail,
    };
    dispatch(FetchSendSmsByUser(reqPayload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = () => {
    let isValid = true;
    if (!fromDate && toDate) {
      toast.error("Please select From date");
      isValid = false;
    }
    if (!toDate && fromDate) {
      toast.error("Please select To date");
      isValid = false;
    }
    if (toDate < fromDate) {
      toast.error("From date should be greater than To date");
      isValid = false;
    }
    setCurrentPage(1);
    if (isValid) {
      fetchData(dataSize, 1, fromDate, toDate, name, selectedRecipients);
    }
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };
  const handleClear = () => {
    setFromDate("");
    setToDate("");
    setName("");
    setCurrentPage(1);
    fetchData(dataSize, currentPage, "", "", "", []);
  };
  const handleInputValue = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      {sendSMSListLoading && <Loader />}
      {!addRecipientsModal && (
        <div
          className="container-fluid pt-2"
          style={{ fontFamily: "sans-serif" }}
        >
          <h6 className="mx-2 mb-3">
            <FaPaperPlane size={18} className="mr-2" />
            Sent
          </h6>
          <Card
            className="card mb-2 p-2"
            style={{
              fontSize: "14px",
              // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <Card.Body>
              <div className="row">
                <div className="col">
                  <label className="mr-2">From Date</label>
                  <DatePicker
                    className="form-control font-14"
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    placeholderText="From Date"
                    maxDate={toDate || new Date()}
                    style={{ fontSize: "12px" }}
                  />
                </div>

                <div className="col">
                  <label className="mr-2">To Date</label>
                  <DatePicker
                    className="form-control font-14"
                    selected={toDate}
                    onChange={handleToDateChange}
                    placeholderText="To Date"
                    maxDate={new Date()}
                    style={{ fontSize: "12px" }}
                  />
                </div>
                <div className="col">
                  <button
                    onClick={handleSearch}
                    className="btn btn-sm btn-primary"
                    style={{
                      marginTop: "37.5px",
                    }}
                    disabled={fromDate === "" && toDate === "" && name === ""}
                  >
                    Search
                  </button>
                </div>
                <div className="col">
                  <label className="mr-2">Recipient Name</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                      handleInputValue(e);
                    }}
                    className="form-control"
                    placeholder="Enter name"
                    style={{ fontSize: "14px" }}
                  />
                </div>
                <div className="col">
                  <button
                    onClick={handleClear}
                    className="btn btn-sm btn-success ml-3"
                    style={{
                      marginTop: "37.5px",
                    }}
                    disabled={fromDate === "" && toDate === "" && name === ""}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
          <div className="p-2 card">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Recipient</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Sent Date</th>
                </tr>
              </thead>
              <tbody>
                {sendSMSList && sendSMSList?.data?.length > 0 ? (
                  sendSMSList?.data?.map((sms) => (
                    <tr key={sms?.Id_Message}>
                      <td className="text-start" style={{ width: "150px" }}>
                        <div className="avatar-name-candidatestableview d-flex align-items-center">
                          <div>
                            <span>
                              <ProfileImage
                                imageSrc={sms?.profileImagePath}
                                imageWidth={"20"}
                                imageHeight={"20"}
                                avatarSize={"30"}
                                avatarRound={"500px"}
                                firstName={sms?.firstName}
                                lastName={sms?.lastName}
                              />
                            </span>
                          </div>
                          <div className="ml-3">
                            <strong className="mb-0 me-2">
                              {sms?.firstName} {sms?.lastName}
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "150px" }}>{sms?.messageTo}</td>
                      <td>{sms?.messageContent}</td>
                      <td style={{ width: "180px" }}>
                        {moment(sms?.createdDateTime).format(
                          "MM/DD/YYYY hh:mm A"
                        )}
                      </td>
                      {/* <td>{new Date(sms?.createdDateTime).toLocaleString()}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="text-center"
                      style={{
                        color: "grey",
                        border: "1px solid rgb(240 240 240)",
                      }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span
                          className="d-flex justify-content-center align-items-center mb-1"
                          style={{
                            width: "70px",
                            height: "70px",
                            border: "1px solid transparent",
                            borderRadius: "50%",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          }}
                        >
                          <FcAbout size={35} />
                        </span>
                        <span>
                          <b>No Messages!</b>
                        </span>

                        {/* <button
                          type="button"
                          className="btn btn-sm btn-primary mt-2"
                          onClick={() => {
                            setAddRecipientsModal(true);
                            setShowModal(true);
                          }}
                        >
                          <FaUserPlus className="mr-2" /> Find Recipients
                        </button> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {!addRecipientsModal &&
              sendSMSList &&
              sendSMSList?.totalCount > 0 && (
                <>
                  <Pagination
                    currentPage={currentPage}
                    totalItems={sendSMSList?.totalCount}
                    itemsPerPage={dataSize}
                    onPageChange={handlePageChange}
                    limit={10}
                  />
                </>
              )}
          </div>
        </div>
      )}

      {addRecipientsModal && (
        <AddRecipients setAddRecipientsModal={setAddRecipientsModal} />
      )}
    </>
  );
};

export default SendSMS;
