import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Validator from "email-validator";
import { toast } from "react-toastify";
import LoginAppLogo from "../../images/high5-std.svg";
import PageNotFound from "../error/404";
import { login } from "../../action/auth/Login";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });

  const { loading, loginData, error } = useSelector((state) => ({
    loading: state.loginReducer.loading,
    loginData: state.loginReducer.loginData,
    error: state.loginReducer.error,
  }));

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!Validator.validate(formData.userName)) {
      toast.error("Please Enter Valid Email");
    } else {
      dispatch(login(formData));
    }
  };

  useEffect(() => {
    if (!error && loginData.length !== 0) {
      localStorage.setItem("userToken", JSON.stringify(loginData));
      history.push("/high5Hire");
    } else {
      toast.error(error.message);
    }
  }, [loginData, error]);

  return (
    <ErrorBoundary FallbackComponent={PageNotFound}>
      <div id="Login" className="login-layout">
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <a className="logo" href="/">
                  <img src={LoginAppLogo} alt="Login logo" />
                </a>
                <div className="section section-sm">
                  <h1>Sign In.</h1>
                  <p>Welcome back! Sign In to get started using High5</p>
                  <form className="login-form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        className="form-control"
                        name="userName"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={formData.userName}
                        onChange={(e) => onChange(e)}
                        autoComplete="new-userName"
                      />
                      <label>Email Address</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(e) => onChange(e)}
                        autoComplete="new-password"
                      />
                      <label>Password</label>
                    </div>
                    <p className="text-right mb-4">
                      <a href="#">Forgot Password?</a>
                    </p>
                    <button
                      type="submit"
                      className="btn btn-dark w-100"
                      disabled={loading}
                    >
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-right"></div>
      </div>
    </ErrorBoundary>
  );
};

export default Login;
