import React, { useState } from "react";
import moment from "moment";

const GenaralDetails = (props) => {
  const [countryClick, setCountryClick] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const { jobData } = props;
  return (
    <div className="mb-4 ml-4">
      {/* {jobData?.jobDivaId && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-fw fa-id-card"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.jobDivaId}</p>
            <p className="mb-0">
              <small>JobDiva Id</small>
            </p>
          </div>
        </div>
      )} */}
      {jobData?.poolname && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-layer-group" />
          </div>
          <div className="ml-2">
            <p className="mb-0">{jobData?.poolname}</p>
            <p className="mb-0">
              <small>Company Name</small>
            </p>
          </div>
        </div>
      )}

      {props.isFrom !== "offerSidepanel" && (
        <>
          {jobData?.jobType && (
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-suitcase" />
              </div>
              <div className="ml-2">
                <p className=" mb-0">{jobData?.jobType}</p>
                <p className="mb-0">
                  <small>Job Type</small>
                </p>
              </div>
            </div>
          )}
          {jobData?.prefferedStartDate && (
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-calendar-day" />
              </div>
              <div className="ml-2">
                <p className="mb-0">
                  {moment(jobData?.prefferedStartDate).format("MM/DD/YYYY")}
                </p>
                <p className="mb-0">
                  <small>Target Start</small>
                </p>
              </div>
            </div>
          )}
          {jobData?.activeFrom && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="far fa-calendar-check"></i>
              </div>
              <div class="ml-2">
                <p className="mb-0">
                  {moment(jobData?.activeFrom).format("MM/DD/YYYY")}
                </p>
                <p class="mb-0">
                  <small>Active From</small>
                </p>
              </div>
            </div>
          )}
          {jobData?.expiresOn && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="far fa-calendar-times"></i>
              </div>
              <div class="ml-2">
                <p className="mb-0">
                  {moment(props.jobData?.expiresOn).format("MM/DD/YYYY")}
                </p>
                <p class="mb-0">
                  <small>Expire On</small>
                </p>
              </div>
            </div>
          )}{" "}
        </>
      )}

      <div className="d-flex mb-2 pl-1">
        <div>
          <i className="fad fa-fw fa-map-marked-alt" />
        </div>
        <div className="ml-2">
          {!countryClick ? (
            <p
              className=" mb-0"
              onClick={() => setCountryClick(true)}
              style={{ cursor: "pointer" }}
            >
              {jobData?.isRemote
                ? `Remote 
                 (${
                   jobData?.isGlobal && jobData?.isGlobal
                     ? "Global"
                     : jobData?.preferredLocation &&
                       JSON.parse(jobData.preferredLocation)?.length > 1
                     ? `${JSON.parse(jobData?.preferredLocation)[0]}...`
                     : JSON.parse(jobData.preferredLocation)
                     ? `${JSON.parse(jobData?.preferredLocation)[0]}`
                     : ""
                 })`
                : jobData?.city +
                  ", " +
                  jobData?.state +
                  ", " +
                  jobData?.country}
            </p>
          ) : (
            <p className=" mb-0" onClick={() => setCountryClick(true)}>
              {jobData?.isRemote
                ? `Remote 
                 (${
                   jobData?.isGlobal
                     ? "Global"
                     : jobData?.preferredLocation &&
                       JSON.parse(jobData?.preferredLocation)?.join(", ")
                 })`
                : jobData?.city +
                  ", " +
                  jobData?.state +
                  ", " +
                  jobData?.country}
            </p>
          )}
          <p className="mb-0">
            <small>Location</small>
          </p>
        </div>
      </div>

      {jobData?.workPlaceType && jobData?.workPlaceType !== "Remote" && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-fw fa-map-marked-alt" />
          </div>
          <div className="ml-2">
            <p className="mb-0">
              {jobData?.workPlaceType}{" "}
              {jobData?.workPlaceType === "Hybrid" &&
                " - " + jobData?.onsiteWorkDays}
            </p>
            <p className="mb-0">
              <small>Work Place Type</small>
            </p>
          </div>
        </div>
      )}
      {jobData?.minimumPay && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-fw fa-piggy-bank" />
          </div>
          <div className="ml-2">
            <p className="mb-0">
              {Number(jobData?.minimumPay).toLocaleString() +
                " - " +
                Number(jobData?.maximumPay).toLocaleString() +
                " " +
                jobData?.minMaxCurrency}
            </p>
            <p className="mb-0">
              <small>{jobData?.minMaxPaymentType} Salary</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.jobType == "Contract" && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-users fa-fw"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">
              {jobData?.contractDurationMonths} Months{" "}
              {jobData?.contractDurationDays > 0 &&
                `${jobData?.contractDurationDays} Days`}
            </p>
            <p className="mb-0">
              <small>Contract Duration</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.positionCount && jobData?.positionCount !== 0 && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-users fa-fw"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.positionCount}</p>
            <p className="mb-0">
              <small>Openings</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.experienceLevel !== 0 && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-file-user"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{props.jobData?.experienceLevel}</p>
            <p className="mb-0">
              <small>Experience</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.tenantName && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-building fa-fw"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.tenantName}</p>
            <p className="mb-0">
              <small>Client</small>
            </p>
          </div>
        </div>
      )}
      {jobData?.visaStatus && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-plane-departure"></i>
          </div>
          <div className="ml-2">
            {jobData?.visaStatus && (
              <>
                <span className="d-flex align-items-center">
                  <ul style={{ marginBottom: "5px" }}>
                    {JSON.parse(jobData?.visaStatus).map(
                      (item, index) =>
                        (index < 2 || viewAll) && (
                          <li className="tag tag-blue3 mr-1 mt-1 pt-1 px-2">
                            {item}
                          </li>
                        )
                    )}
                    {jobData?.visaStatus &&
                      (viewAll ? (
                        <button
                          className="btn btn-text"
                          onClick={() => setViewAll(false)}
                          style={{ margin: "5px", cursor: "pointer" }}
                        >
                          View Less
                        </button>
                      ) : (
                        JSON.parse(jobData?.visaStatus).length > 2 && (
                          <button
                            className="btn btn-text"
                            onClick={() => setViewAll(true)}
                            style={{ margin: "5px", cursor: "pointer" }}
                          >
                            {/* +{JSON.parse(jobData?.visaStatus).length - 2} more */}
                            View All
                          </button>
                        )
                      ))}
                  </ul>
                </span>
                <p className="mb-0">
                  <small>Work Authorization Requirement</small>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenaralDetails;
