import React, { useEffect } from "react";
import "react-tagsinput/react-tagsinput.css";
import {
  countryList,
  visaStatus,
  additionalVisaStatus,
  experienceLevel,
  educationTypes,
} from "../../../../../../constants/Constants";

const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
const uniqueVisaStatus = Array.from(
  new Set(combinedVisaStatus.map(JSON.stringify))
).map(JSON.parse);

function ProfileInfo(props) {
  const {
    profileInfo,
    setProfileInfo,
    profileInfoValidation,
    isFormValid,
    dropDownData,
  } = props;

  const visaStatusList = uniqueVisaStatus;

  const handleChange = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  let yearArr = [];
  let dayArr = [];

  const filterCurrencyType = () => {
    dropDownData?.CurrencyTime?.forEach((x) => {
      let ans = x.SourceName.split("/")[1];

      if (ans === "Year") {
        yearArr.push(x);
      } else {
        dayArr.push(x);
      }
    });
  };
  filterCurrencyType();

  useEffect(() => {
    filterCurrencyType();
  }, [dropDownData]);

  return (
    <>
      <div className="row mb-1 mt-3">
        <div className="col-lg-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{
                border:
                  isFormValid &&
                  profileInfo.experienceLevel == "" &&
                  profileInfoValidation.experienceLevel &&
                  "2px solid #ff0000",
              }}
              name="experienceLevel"
              id="experienceLevel"
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select</option>
              {experienceLevel.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </select>
            <label>Experience Level*</label>
          </div>
          {isFormValid &&
            profileInfo.experienceLevel == "" &&
            profileInfoValidation.experienceLevel && (
              <small className="validation">*Required Field</small>
            )}
        </div>
        <div className="col-lg-3">
          <div className="form-floating">
            <select
              className="form-select"
              id="highestEducation"
              name="highestEducation"
              onChange={handleChange}
              value={profileInfo.highestEducation}
            >
              {educationTypes.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <label>Highest Level of Education</label>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="form-floating">
            <select
              id="visaStatus"
              className="form-select"
              onChange={handleChange}
              value={profileInfo.visaStatus}
              style={{
                border:
                  isFormValid &&
                  profileInfo.visaStatus == "" &&
                  profileInfoValidation.visaStatus &&
                  "2px solid #ff0000",
              }}
            >
              <option value="" selected>
                Select Status
              </option>
              {visaStatusList?.map((item, i) => (
                <option value={item.value} key={i}>
                  {item.label}
                </option>
              ))}
            </select>
            <label>Immigration Status*</label>
          </div>
          {isFormValid &&
            profileInfo.visaStatus == "" &&
            profileInfoValidation.visaStatus && (
              <small className="validation">*Required Field</small>
            )}
        </div>{" "}
        <div className="col-lg-3">
          <div className="form-floating">
            <select
              id="workAuthCountry"
              className="form-select"
              disabled={
                profileInfo.visaStatus !== "Permanent Resident" &&
                profileInfo.visaStatus !== "Citizen" &&
                profileInfo.visaStatus !== "Authorised to work"
              }
              onChange={handleChange}
              value={profileInfo.workAuthCountry}
              style={{
                border:
                  isFormValid &&
                  profileInfo.visaStatus == "" &&
                  profileInfoValidation.visaStatus &&
                  "2px solid #ff0000",
              }}
            >
              <option value="" selected>
                Select Country
              </option>
              {countryList?.map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
            </select>
            <label>Country</label>
          </div>
          {isFormValid &&
            profileInfo.visaStatus == "" &&
            profileInfoValidation.visaStatus && (
              <small className="validation">*Required Field</small>
            )}
        </div>
      </div>
    </>
  );
}

export default ProfileInfo;
