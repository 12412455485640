import {
  FETCH_SEND_SMS_BY_USER_BEGIN,
  FETCH_SEND_SMS_BY_USER_SUCCESS,
  FETCH_SEND_SMS_BY_USER_FAILURE,
  FETCH_SEND_EMAILS_BY_USER_BEGIN,
  FETCH_SEND_EMAILS_BY_USER_SUCCESS,
  FETCH_SEND_EMAILS_BY_USER_FAILURE,
  SEND_SMS_BEGIN,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  CREATE_SMS_TEMP_BEGIN,
  CREATE_SMS_TEMP_SUCCESS,
  CREATE_SMS_TEMP_FAILURE,
  CREATE_EMAIL_TEMP_BEGIN,
  CREATE_EMAIL_TEMP_SUCCESS,
  CREATE_EMAIL_TEMP_FAILURE,
  FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_BEGIN,
  FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_SUCCESS,
  FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_FAILURE,
  FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_BEGIN,
  FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_SUCCESS,
  FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_FAILURE,
  GET_RECIPIENTS_LIST_BEGIN,
  GET_RECIPIENTS_LIST_SUCCESS,
  GET_RECIPIENTS_LIST_FAILURE,
  FETCH_LAST_SMS_BY_USER_BEGIN,
  FETCH_LAST_SMS_BY_USER_SUCCESS,
  FETCH_LAST_SMS_BY_USER_FAILURE,
  FETCH_LAST_MAIL_BY_USER_BEGIN,
  FETCH_LAST_MAIL_BY_USER_SUCCESS,
  FETCH_LAST_MAIL_BY_USER_FAILURE,
  WHATSAPP_TEMPLATES_BEGIN,
  WHATSAPP_TEMPLATES_SUCCESS,
  WHATSAPP_TEMPLATES_FAILURE,
  SEND_WHATSAPP_MESSAGE_BEGIN,
  SEND_WHATSAPP_MESSAGE_SUCCESS,
  SEND_WHATSAPP_MESSAGE_FAILURE,
  WHATSAPP_RECENT_MESSAGES_BEGIN,
  WHATSAPP_RECENT_MESSAGES_SUCCESS,
  WHATSAPP_RECENT_MESSAGES_FAILURE,
  GET_WHATSAPP_MESSAGES_BEGIN,
  GET_WHATSAPP_MESSAGES_SUCCESS,
  GET_WHATSAPP_MESSAGES_FAILURE,
  WHATSAPP_MESSAGE_COUNT_BEGIN,
  WHATSAPP_MESSAGE_COUNT_SUCCESS,
  WHATSAPP_MESSAGE_COUNT_FAILURE,
  UPDATE_MESSAGE_READ_STATUS_BEGIN,
  UPDATE_MESSAGE_READ_STATUS_SUCCESS,
  UPDATE_MESSAGE_READ_STATUS_FAILURE,
} from "../../action/communication/Communication";

const initialState = {
  sendSMSListLoading: false,
  sendSMSList: [],
  sendEmailsListLoading: false,
  sendEmailsList: [],
  sendSMSLoading: false,
  sendSMS: "",
  createSMSTempLoading: false,
  createEmailTempLoading: false,
  sendEmailCandidateLoading: false,
  sendEmailCandidateList: [],
  sendSMSCandidateLoading: false,
  sendSMSCandidateList: [],
  recipientsLoading: false,
  recipients: [],
  totalRecipients: 0,
  lastMessageLoading: false,
  lastMessage: [],
  lastMailLoading: false,
  lastMail: [],
  whatsAppTemplateLoading: false,
  whatsAppTemplates: [],
  whatsAppSendLoading: false,
  whatsAppSend: "",
  whatsAppRecentMessagesLoading: false,
  whatsAppRecentMessages: [],
  whatsAppMessagesLoading: false,
  whatsAppMessages: [],
  whatsAppMessageCountLoading: false,
  whatsAppMessageCount: 0,
  readStatusLoading: false,
};

const communicationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SEND_SMS_BY_USER_BEGIN:
      return {
        ...state,
        sendSMSListLoading: true,
        sendSMSList: [],
      };
    case FETCH_SEND_SMS_BY_USER_SUCCESS:
      return {
        ...state,
        sendSMSList: payload,
        sendSMSListLoading: false,
      };
    case FETCH_SEND_SMS_BY_USER_FAILURE:
      return {
        ...state,
        sendSMSList: [],
        sendSMSListLoading: false,
      };
    case FETCH_SEND_EMAILS_BY_USER_BEGIN:
      return {
        ...state,
        sendEmailsListLoading: true,
        sendEmailsList: [],
      };
    case FETCH_SEND_EMAILS_BY_USER_SUCCESS:
      return {
        ...state,
        sendEmailsListLoading: false,
        sendEmailsList: payload,
      };
    case FETCH_SEND_EMAILS_BY_USER_FAILURE:
      return {
        ...state,
        sendEmailsListLoading: false,
        sendEmailsList: [],
      };
    case UPDATE_MESSAGE_READ_STATUS_BEGIN:
      return {
        ...state,
        readStatusLoading: true,
      };
    case UPDATE_MESSAGE_READ_STATUS_SUCCESS:
      return {
        ...state,
        readStatusLoading: false,
      };
    case UPDATE_MESSAGE_READ_STATUS_FAILURE:
      return {
        ...state,
        readStatusLoading: false,
      };
    case SEND_SMS_BEGIN:
      return {
        ...state,
        sendSMSLoading: true,
        sendSMS: "",
      };
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        sendSMSLoading: false,
        sendSMS: "Success",
      };
    case SEND_SMS_FAILURE:
      return {
        ...state,
        sendSMSLoading: false,
        sendSMS: "",
      };
    case CREATE_SMS_TEMP_BEGIN:
      return {
        ...state,
        createSMSTempLoading: true,
      };
    case CREATE_SMS_TEMP_SUCCESS:
      return {
        ...state,
        createSMSTempLoading: false,
      };
    case CREATE_SMS_TEMP_FAILURE:
      return {
        ...state,
        createSMSTempLoading: false,
      };
    case CREATE_EMAIL_TEMP_BEGIN:
      return {
        ...state,
        createEmailTempLoading: true,
      };
    case CREATE_EMAIL_TEMP_SUCCESS:
      return {
        ...state,
        createEmailTempLoading: false,
      };
    case CREATE_EMAIL_TEMP_FAILURE:
      return {
        ...state,
        createEmailTempLoading: false,
      };
    case FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_BEGIN:
      return {
        ...state,
        sendEmailCandidateLoading: true,
        sendEmailCandidateList: [],
      };
    case FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_SUCCESS:
      return {
        ...state,
        sendEmailCandidateLoading: false,
        sendEmailCandidateList: payload,
      };
    case FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_FAILURE:
      return {
        ...state,
        sendEmailCandidateLoading: false,
        sendEmailCandidateList: [],
      };
    case FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_BEGIN:
      return {
        ...state,
        sendSMSCandidateLoading: true,
        sendSMSCandidateList: [],
      };
    case FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_SUCCESS:
      return {
        ...state,
        sendSMSCandidateLoading: false,
        sendSMSCandidateList: payload,
        sendSMS: "",
      };
    case FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_FAILURE:
      return {
        ...state,
        sendSMSCandidateLoading: false,
        sendSMSCandidateList: [],
      };
    case GET_RECIPIENTS_LIST_BEGIN:
      return {
        ...state,
        recipientsLoading: true,
        recipients: [],
      };
    case GET_RECIPIENTS_LIST_SUCCESS:
      return {
        ...state,
        recipients: payload,
        recipientsLoading: false,
      };
    case GET_RECIPIENTS_LIST_FAILURE:
      return {
        ...state,
        recipients: [],
        recipientsLoading: false,
      };

    case FETCH_LAST_SMS_BY_USER_BEGIN:
      return {
        ...state,
        lastMessageLoading: true,
        lastMessage: [],
      };
    case FETCH_LAST_SMS_BY_USER_SUCCESS:
      return {
        ...state,
        lastMessageLoading: false,
        lastMessage: payload,
      };
    case FETCH_LAST_SMS_BY_USER_FAILURE:
      return {
        ...state,
        lastMessageLoading: false,
        lastMessage: [],
      };
    case FETCH_LAST_MAIL_BY_USER_BEGIN:
      return {
        ...state,
        lastMailLoading: true,
        lastMail: [],
      };
    case FETCH_LAST_MAIL_BY_USER_SUCCESS:
      return {
        ...state,
        lastMailLoading: false,
        lastMail: payload,
      };
    case FETCH_LAST_MAIL_BY_USER_FAILURE:
      return {
        ...state,
        lastMailLoading: false,
        lastMail: [],
      };
    case WHATSAPP_TEMPLATES_BEGIN:
      return {
        ...state,
        whatsAppTemplateLoading: true,
        whatsAppTemplates: [],
      };
    case WHATSAPP_TEMPLATES_SUCCESS:
      return {
        ...state,
        whatsAppTemplateLoading: false,
        whatsAppTemplates: payload,
      };
    case WHATSAPP_TEMPLATES_FAILURE:
      return {
        ...state,
        whatsAppTemplateLoading: false,
        whatsAppTemplates: [],
      };
    case SEND_WHATSAPP_MESSAGE_BEGIN:
      return {
        ...state,
        whatsAppSendLoading: true,
        whatsAppSend: "",
      };
    case SEND_WHATSAPP_MESSAGE_SUCCESS:
      return {
        ...state,
        whatsAppSendLoading: false,
        whatsAppSend: "success",
      };
    case SEND_WHATSAPP_MESSAGE_FAILURE:
      return {
        ...state,
        whatsAppSendLoading: false,
        whatsAppSend: "",
      };
    case WHATSAPP_RECENT_MESSAGES_BEGIN:
      return {
        ...state,
        whatsAppRecentMessagesLoading: true,
        whatsAppRecentMessages: [],
      };
    case WHATSAPP_RECENT_MESSAGES_SUCCESS:
      return {
        ...state,
        whatsAppRecentMessagesLoading: false,
        whatsAppRecentMessages: payload,
      };
    case WHATSAPP_RECENT_MESSAGES_FAILURE:
      return {
        ...state,
        whatsAppRecentMessagesLoading: false,
        whatsAppRecentMessages: [],
      };
    case GET_WHATSAPP_MESSAGES_BEGIN:
      return {
        ...state,
        whatsAppMessagesLoading: true,
        whatsAppMessages: [],
      };
    case GET_WHATSAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        whatsAppMessagesLoading: false,
        whatsAppMessages: payload,
      };
    case GET_WHATSAPP_MESSAGES_FAILURE:
      return {
        ...state,
        whatsAppMessagesLoading: false,
        whatsAppMessages: [],
      };
    case WHATSAPP_MESSAGE_COUNT_BEGIN:
      return {
        ...state,
        whatsAppMessageCountLoading: true,
        whatsAppMessageCount: 0,
      };
    case WHATSAPP_MESSAGE_COUNT_SUCCESS:
      return {
        ...state,
        whatsAppMessageCountLoading: false,
        whatsAppMessageCount: payload,
      };
    case WHATSAPP_MESSAGE_COUNT_FAILURE:
      return {
        ...state,
        whatsAppMessageCountLoading: false,
        whatsAppMessageCount: 0,
      };
    default:
      return state;
  }
};

export default communicationReducer;
