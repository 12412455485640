import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_TALENT_LIST_BEGIN = "GET_TALENT_LIST_BEGIN";
export const GET_TALENT_LIST_SUCCESS = "GET_TALENT_LIST_SUCCESS";
export const GET_TALENT_LIST_FAILURE = "GET_TALENT_LIST_FAILURE";

export const GET_TALENT_COUNT_BEGIN = "GET_TALENT_COUNT_BEGIN";
export const GET_TALENT_COUNT_SUCCESS = "GET_TALENT_COUNT_SUCCESS";
export const GET_TALENT_COUNT_FAILURE = "GET_TALENT_COUNT_FAILURE";

export const getTalentList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TALENT_LIST_BEGIN,
    });
    const res = await post("/talent/gettalentlist", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_TALENT_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TALENT_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getTalentListCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TALENT_COUNT_BEGIN,
    });
    const res = await post("/talent/talentcount", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_TALENT_COUNT_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TALENT_COUNT_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
