import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // Import the CSS file
import Select from "react-dropdown-select";
import { FaSearch } from "react-icons/fa";

import Loader from "../../loader/Loader";
import Pagination from "../../common/pagination/Pagination";
import { getRecipientsListForSms } from "../../../action/communication/Communication";
import { experienceOptions } from "../../../constants/Constants";
import GoogleLocationCountryAPI from "../../location/GoogleLocationCountryAPI";

const AddRecipientsForSms = ({
  setAddRecipientsModal,
  setSelectedRecipients,
  selectedRecipients,
}) => {
  const dispatch = useDispatch();
  const {
    recipientsLoading,
    recipients,
    totalRecipients,
    stateLoading,
    stateData,
    countryLoading,
    countryData,
  } = useSelector(
    (state) => ({
      recipientsLoading: state.communicationReducer.recipientsLoading,
      recipients: state.communicationReducer.recipients,
      totalRecipients: state.communicationReducer.totalRecipients,
      stateLoading: state.stateData.loading,
      stateData: state.stateData.stateData,
      countryLoading: state.countryData.loading,
      countryData: state.countryData.countryData,
    }),
    shallowEqual
  );
  const [dropdownList, setDropDown] = useState({});
  const [primarySkillInput, setPrimarySkillInput] = useState("");
  const [recipientsList, setRecipientsList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterQuery, setFilterQuery] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    country: "",
    experience: "",
    skills: [],
  });
  const [location, setLocation] = useState("");
  const searchInput = useRef(null);
  const handleCheckboxChange = (id) => {
    const updatedRecipients = recipientsList.map((recipient) =>
      recipient.userId === id
        ? { ...recipient, selected: !recipient.selected }
        : recipient
    );
    setRecipientsList(updatedRecipients);
  };
  const startIndex = (currentPage - 1) * 10;
  const tempData = recipientsList.slice(startIndex, startIndex + 10);
  const [skills, setSkills] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectAllRecipients, setSelectAllRecipients] = useState(false);
  const handleCloseModal = () => {
    setAddRecipientsModal(false);
  };
  useEffect(() => {
    // let emailList = [];
    // if (selectedRecipients && selectedRecipients?.length > 0) {
    //   emailList = selectedRecipients.map((obj) => obj.email);
    // }
    let reqPayload = {
      name: "",
      email: "",
      city: "",
      country: "",
      experience: "",
      skills: [],
      page: 1,
      pageSize: 10,
    };
    dispatch(getRecipientsListForSms(reqPayload));
    handleClearFilter();
  }, []);
  useEffect(() => {
    if (recipients && recipients?.length > 0) {
      setRecipientsList([...recipients]);
    } else {
      setRecipientsList([]);
    }
  }, [recipients]);
  const onSkillClick = (e, val) => {
    setRecipientsList(
      recipientsList.map((item) => {
        if (item?.email === val?.email) {
          item.ViewAllSkill = !item.ViewAllSkill;
        }
        return item;
      })
    );
  };
  const handleSelectRecipients = () => {
    let selectedData =
      recipientsList && recipientsList?.length > 0
        ? recipientsList?.filter((item) => item?.selected)
        : [];

    // Filter out duplicates based on email
    selectedData = selectedData.filter((newItem) =>
      selectedRecipients.every(
        (existingItem) => existingItem.email !== newItem.email
      )
    );

    setSelectedRecipients([...selectedRecipients, ...selectedData]);
    setAddRecipientsModal(false);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleChangePrimarySkills = (skills) => {
    setFilterQuery({ ...filterQuery, skills: skills });
  };
  const handleChangeInputPrimarySkills = (tags) => {
    setPrimarySkillInput(tags);
  };
  const handleChangeExperience = (selectedOptions) => {
    // Extracting the values from the selected options
    const selectedValues = selectedOptions.map((option) => option.value);

    setFilterQuery({
      ...filterQuery,
      experience: selectedValues,
    });
  };
  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = location;
    }
  }, [location]);

  const setLocations = (address) => {
    setFilterQuery({
      ...filterQuery,
      city: address.city,
      country: address.country,
    });
    setLocation(address.city + (address.city ? ", " : "") + address.country);
  };
  const handleClearFilter = () => {
    setLocation("");
    setFilterQuery({
      name: "",
      email: "",
      city: "",
      country: "",
      experience: [],
      skills: [],
    });
    setShowFilter(false);
    let reqPayload = {
      name: "",
      email: "",
      city: "",
      country: "",
      experience: [],
      skills: [],
    };
    dispatch(getRecipientsListForSms(reqPayload));
  };
  const isFilterValid = () => {
    // Perform validation here
    if (
      filterQuery?.name === "" &&
      filterQuery?.email === "" &&
      filterQuery?.city === "" &&
      filterQuery?.country === "" &&
      filterQuery?.experience?.length === 0 &&
      filterQuery?.skills?.length === 0
    ) {
      // If validation fails, return false
      return false;
    }
    // If validation passes, return true
    return true;
  };
  const handleFilter = () => {
    if (
      filterQuery?.name === "" &&
      filterQuery?.email === "" &&
      filterQuery?.city === "" &&
      filterQuery?.country === "" &&
      filterQuery?.experience?.length === 0 &&
      filterQuery?.skills?.length === 0
    ) {
      toast.warning("Please select any filters");
    } else {
      let reqPayload = {
        name: filterQuery?.name,
        email: filterQuery?.email,
        city: filterQuery?.city,
        country: filterQuery?.country,
        experience: filterQuery?.experience,
        skills: filterQuery?.skills,
      };
      dispatch(getRecipientsListForSms(reqPayload));
    }
  };
  const handleSelectAll = () => {
    if (!selectAllRecipients) {
      const updatedRecipientsList = recipientsList.map((recipient) => ({
        ...recipient,
        selected: true,
      }));
      setRecipientsList(updatedRecipientsList);
      setSelectAllRecipients(true);
    } else {
      const updatedRecipientsList = recipientsList.map((recipient) => ({
        ...recipient,
        selected: false,
      }));
      setRecipientsList(updatedRecipientsList);
      setSelectAllRecipients(false);
    }
  };
  return (
    <>
      {recipientsLoading && <Loader />}
      <Col md={12} sm={12}>
        <div className="mb-3 mt-2">
          <Row>
            <Col md={6} sm={6}>
              <h6>
                <FaUserPlus className="mr-2 mt-0" />
                Select Recipients
              </h6>
            </Col>
            <Col md={6} sm={6} className="text-right">
              <span
                onClick={() => {
                  setShowFilter(true);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <FaSearch size={16} className="mr-2" />
                <b>Advance Search</b>
              </span>
            </Col>
          </Row>

          <hr />
          {showFilter && (
            <Card className="p-3" style={{ minHeight: "auto" }}>
              <Row>
                <Col md={4} sm={12}>
                  <div>
                    <label>Candidate Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Enter candidate name"
                      value={filterQuery?.name}
                      onChange={(e) =>
                        setFilterQuery({
                          ...filterQuery,
                          [e.target.name]: e.target.value,
                        })
                      }
                      style={{
                        fontSize: "14px",
                        height: "45px",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div>
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter email"
                      value={filterQuery?.email}
                      onChange={(e) =>
                        setFilterQuery({
                          ...filterQuery,
                          [e.target.name]: e.target.value,
                        })
                      }
                      style={{
                        fontSize: "14px",
                        height: "45px",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div>
                    <label>Experience Level</label>
                    <Select
                      style={{
                        fontSize: "14px",
                        height: "45px",
                        borderRadius: "4px",
                        padding: "15px",
                      }}
                      options={experienceOptions}
                      onChange={handleChangeExperience}
                      searchable={false}
                      values={filterQuery.experience || []}
                      clearOnSelect={false} // Optional: If you want to keep the dropdown open after selection
                    />
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <label>Location</label>
                  <GoogleLocationCountryAPI
                    setLocation={setLocations}
                    searchInput={searchInput}
                    onClear={() => {
                      setFilterQuery({
                        ...filterQuery,
                        city: "",
                        country: "",
                      });
                      setLocation("");
                    }}
                    className="custom-google-location"
                  />
                </Col>
                <Col md={6} sm={12}>
                  <label>Primary Skills</label>
                  <div className="form-floating" style={{ height: "45px" }}>
                    <TagsInput
                      value={filterQuery.skills}
                      onChange={handleChangePrimarySkills}
                      inputValue={primarySkillInput}
                      onChangeInput={handleChangeInputPrimarySkills}
                      addOnBlur={true}
                      placeholder="Add a skill"
                    />
                  </div>
                </Col>
                <Col md={12} sm={12} className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-sm btn-success mt-2"
                    onClick={() => {
                      handleClearFilter();
                      setSelectAllRecipients(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ml-2 mt-2"
                    onClick={() => handleFilter()}
                    // disabled={
                    //   filterQuery?.name === "" &&
                    //   filterQuery?.skills?.length === 0
                    // }
                    disabled={!isFilterValid()}
                  >
                    Search
                  </button>
                </Col>
              </Row>
            </Card>
          )}
          <Row>
            <Col md={12} sm={12}>
              <button
                type="button"
                className="btn btn-sm btn-success mt-2"
                onClick={() => handleSelectAll()}
                disabled={recipients.length === 0}
              >
                {selectAllRecipients
                  ? "Deselect All Recipients"
                  : "Select All Recipients"}
              </button>
              <br />
              <div className="mt-4">
                {recipientsList?.filter((item) => item?.selected)?.length >
                  0 && (
                  <strong className="mt-4">
                    Selected
                    {recipientsList?.filter((item) => item?.selected)?.length >
                    1
                      ? " Recipients"
                      : " Recipient"}
                    {" : "}
                    {recipientsList?.filter((item) => item?.selected)?.length}
                  </strong>
                )}
              </div>
            </Col>
          </Row>

          <Row className="messageForm mb-5 mt-2">
            <Col sm={12} md={12}>
              <table className="table table-striped table-responsive-sm">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th style={{ width: "150px" }}>Candidate Name</th>
                    <th>Phone Number</th>
                    {/* <th>Skills</th> */}
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {tempData && tempData?.length > 0 ? (
                    tempData?.map((recipient) => (
                      <tr key={recipient?.userId}>
                        <td>
                          <input
                            type="checkbox"
                            style={{
                              boxShadow: "none",
                              width: "18px",
                              height: "18px",
                              marginLeft: "10px",
                              color: "#3ab549",
                            }}
                            checked={recipient.selected}
                            onChange={() =>
                              handleCheckboxChange(recipient?.userId)
                            }
                          />
                        </td>
                        <td>
                          {recipient?.firstName + " " + recipient?.lastName}
                        </td>
                        <td>
                          {recipient?.homePhone && recipient?.homePhoneCode
                            ? `${recipient.homePhoneCode} ${recipient.homePhone}`
                            : recipient?.homePhoneCode ||
                              recipient?.homePhone ||
                              ""}
                        </td>

                        {/* <td>
                          {!recipient?.primarySkills && (
                            <span className="mb-1 mr-1">NA</span>
                          )}
                          {recipient?.primarySkills &&
                            JSON.parse(recipient?.primarySkills)
                              .slice(0, 2)
                              .map((item) => (
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              ))}
                          {JSON.parse(recipient?.primarySkills)?.length > 2 &&
                            recipient?.ViewAllSkill === true &&
                            JSON.parse(recipient?.primarySkills)
                              .slice(
                                2,
                                JSON.parse(recipient?.primarySkills)?.length
                              )
                              .map((item) => (
                                <>
                                  <span class="tag tag-blue3 mb-1 mr-1">
                                    {item}
                                  </span>
                                </>
                              ))}
                          {JSON.parse(recipient?.primarySkills)?.length > 2 && (
                            <button
                              onClick={(e) => onSkillClick(e, recipient)}
                              type="button"
                              class="btn btn-text"
                            >
                              {!recipient?.ViewAllSkill ? (
                                <span>View all skills</span>
                              ) : (
                                <span>Show Less</span>
                              )}
                            </button>
                          )}
                        </td> */}
                        <td>
                          {recipient?.city && recipient?.country
                            ? recipient?.city + "," + recipient?.country
                            : "NA"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div className="p-auto text-center p-2">
                          <div className="avatar avatar-lg ">
                            <i
                              aria-hidden="true"
                              className="fad fa-users-slash"
                            ></i>
                          </div>
                          <div className="mt-2">No records found</div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {recipientsList && recipientsList?.length !== 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={recipientsList?.length}
                  itemsPerPage={10}
                  onPageChange={handlePageChange}
                  limit={10}
                />
              )}
              <Row>
                <Col
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end align-content-end mt-3"
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={() => handleCloseModal()}
                  >
                    <IoArrowBackCircleSharp className="mr-2" size={18} />
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => handleSelectRecipients()}
                    disabled={
                      recipientsList?.filter((item) => item?.selected)
                        ?.length === 0
                    }
                  >
                    Select
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default AddRecipientsForSms;
