import moment from "moment";

export const dateToUtcString = (
  dateTime,
  inputFormat,
  outputFormat = inputFormat
) =>
  dateTime
    ? new moment(dateTime, inputFormat).utc().format(outputFormat)
    : new moment(dateTime, inputFormat).utc().format(outputFormat);

export const utcToDateString = (
  dateTime,
  inputFormat,
  outputFormat = inputFormat
) => {
  let date = JSON.stringify(dateTime);
  const UTCToDateTime = moment
    .utc(
      dateTime,
      date.includes("Time") ? "ddd MMM DD YYYY HH:mm:ss" : inputFormat
    )
    .local()
    .format(outputFormat);
  return UTCToDateTime;
};

export const utcToGlobalDateString = (
  dateTime,
  inputFormat,
  outputFormat = inputFormat
) =>
  moment
    .utc(
      dateTime,
      dateTime.includes("Time") ? "ddd MMM DD YYYY HH:mm:ss" : inputFormat
    )
    .format(outputFormat);
