import { post } from "../../service/ApiService";
import { toast } from "react-toastify";
import { domain } from "../../constants/Constants";

export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAILURE = "GET_JOBS_FAILURE";
export const GET_JOBS_BEGIN = "GET_JOBS_BEGIN";

export const jobList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBS_BEGIN,
    });
    const params = {
      skip: 0,
      domain: domain,
    };
    const res = await post("/requestlist/getrequestslist", params);
    if (res.status === 200) {
      dispatch({
        type: GET_JOBS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOBS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const templateDetails = (payload) => {
  return (dispatch) => {
    // SUPPORTS API CALLS WE CAN MAKE API CALLS THEN SET VALUE
    dispatch({
      type: "TEMPLATE_DETAILS",
      payload: payload,
    });
  };
};

export const template = (payload) => {
  return (dispatch) => {
    // SUPPORTS API CALLS WE CAN MAKE API CALLS THEN SET VALUE
    dispatch({
      type: "TEMPLATE_SELECTED",
      payload: payload,
    });
  };
};

export const jobDetail = (payload) => {
  return (dispatch) => {
    // SUPPORTS API CALLS WE CAN MAKE API CALLS THEN SET VALUE
    dispatch({
      type: "JOB_DETAILS",
      payload: payload,
    });
  };
};
