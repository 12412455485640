import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import axios from "axios";
import { get, post } from "../../../../../../service/ApiService";
import Loader from "../../../../../loader/Loader";
import {
  getCandidateName,
  getTenantType,
  getCandidateId,
} from "../../../../../../service/AuthService";
import { domain } from "../../../../../../constants/Constants";

const DisqualifyPopUp = (props) => {
  const {
    disqualifyPopup,
    setDisqualifyPopup,
    disqualifyData,
    jobData,
    reloadDisposition,
  } = props;

  const [loading, setLoading] = useState(false);
  const [dropDown, setDropdown] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");

  const tenantType = getTenantType();
  useEffect(() => {
    GetDropdownList();
  }, []);

  // let getCreatedBy = async () => {
  //   try {
  //     await post("/msgtwoway/getrecruiterId", {
  //       recruiterId: disqualifyData.recruiterId,
  //     }).then((res) => {
  //       let Iddd = Number(res.data[0].FK_useraccessdetails);
  //       setCreatedBy(Iddd);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getCreatedBy();
  // }, []);

  const GetDropdownList = async () => {
    setLoading(true);
    try {
      const url =
        props.isFrom === "invite"
          ? "/disposition/getinvitedisqualifyreasonlist"
          : "/disposition/getdisqualifyreasonlist";
      const res = await get(url);
      if (res.status === 200) {
        const result = res.data;
        setDropdown(result);
      }
    } catch (error) {
      toast.error("Error Occured While fetching dropdown list");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const selectedItem = dropDown.find(
      (item) => item.ReasonForDisqualification === value
    );
    setSelectedDescription(selectedItem?.description || "");
    setSelectedValue(value);
  };

  const sendSmsforDisqualification = async () => {
    let name = disqualifyData?.firstName;
    let jobName = jobData?.jobTitle;
    let jobId = jobData.jobId;
    let tenant = {
      tenant: disqualifyData?.fk_tenant,
      createdBy: createdBy,
    };
    setLoading(true);
    // Function to make a request to the URL shortening API
    async function shortenUrl(longUrl) {
      const headers = {
        Accept: "application/json",
        Authorization:
          "Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv",
        "Content-Type": "application/json",
      };
      const requestBody = {
        url: longUrl,
        domain: "high5hire.live",
      };

      try {
        const response = await axios.post(
          "https://api.tinyurl.com/create",
          requestBody,
          { headers }
        );
        if (
          response.data &&
          response.data.data &&
          response.data.data.tiny_url
        ) {
          const shortenedUrl = response.data.data.tiny_url;
          return shortenedUrl;
        } else {
          console.log("Response does not contain a valid shortened URL.");
          return "";
        }
      } catch (error) {
        console.error("Error shortening URL:", error);
      }
    }
    const numbers = await post("/msgtwoway/getAllNumbers", tenant);
    const link = `${domain}/high5Hire/requests/${jobId}`;
    const shortenedUrl = await shortenUrl(link);
    const data = numbers.data?.filter((x) => x.fk_Role !== 4);
    if (data.length === 0) return;
    const recepientsDataSms = [];
    const recepientsDataWhatsApp = [];
    await data.map((obj) => {
      const recepientDataSms = [];
      const recepientDataWhatsApp = [];

      let home = obj.homePhone ? obj.homePhone : "";
      let mobile = obj.mobilePhone ? obj.mobilePhone : "";
      let work = obj.workPhone ? obj.workPhone : "";

      if (home.charAt(0) != "+") home = `+${obj.homePhone}`;
      if (mobile.charAt(0) != "+") mobile = `+${obj.mobilePhone}`;
      if (work.charAt(0) != "+") work = `+${obj.workPhone}`;

      if (home.length > 10)
        if (home.charAt(0) == "+" && home.charAt(1) != "1")
          recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
      if (mobile.length > 10)
        if (mobile.charAt(0) == "+" && mobile.charAt(1) != "1")
          recepientDataWhatsApp.push(mobile.replaceAll(" ", "")?.substring(1));
      if (work.length > 10)
        if (work.charAt(0) == "+" && work.charAt(1) != "1")
          recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));

      if (home.charAt(0) == "+" && home.charAt(1) == "1")
        recepientDataSms.push(home.replaceAll(" ", ""));
      if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
        recepientDataSms.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) == "1")
        recepientDataSms.push(work.replaceAll(" ", ""));

      if (recepientDataSms.length > 0)
        recepientsDataSms.push({
          recieverNumber: recepientDataSms,
          userId: getCandidateId() || null,
          message: `Hi ${obj.firstName}, Just letting you know ${name} has been disqualified for the ${jobName} role.\nClick below to see details.\n${shortenedUrl}\nThe High5 Team `,
        });

      if (recepientDataWhatsApp.length > 0)
        recepientsDataWhatsApp.push({
          recieverNumber: recepientDataWhatsApp,
          message: `Hi ${obj.firstName}, Just letting you know ${name} has been disqualified for the ${jobName} role.\nClick below to see details.\n${domain}/high5Hire/requests/${jobId}\nThe High5 Team `,
        });
    });

    try {
      if (recepientsDataSms.length > 0)
        await post("/msgtwoway/telSms", recepientsDataSms);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred");
    }
  };

  const handleDisqualify = async () => {
    setLoading(true);
    try {
      const payload = {
        status: 12,
        id_disposition: disqualifyData.id_disposition,
        disqualifyComments: selectedValue,
        disqualifyDescription: selectedDescription,
        comments: commentText.trim(),
        jobId: jobData.jobId,
        jobTitle: jobData.jobTitle,
        candidateEmail: disqualifyData?.email,
        candidateFname: disqualifyData?.firstName,
        candidateLname: disqualifyData?.lastName,
        recruiterEmail: disqualifyData.recruiterEmail,
        recruiterId: disqualifyData.recruiterId,
        recruiterName: disqualifyData.recruiterName,
        tenantName: disqualifyData.tenantName,
        tenantname: jobData.tenantName,
        userName: getCandidateName(),
        tenantType: tenantType,
        createdBy: disqualifyData.createdBy,
        withdraw:
          props.isFrom == "invite" || props.isFrom == "automatch"
            ? true
            : false,
      };

      const res = await post("/disposition/updatedisposition", payload);
      if (res.status === 200) {
        // await sendSmsforDisqualification();
        toast.success(
          `Candidate ${
            props.isFrom == "invite" || props.isFrom == "automatch"
              ? "Withdrawn"
              : "Disqualified"
          } Successfully!!!`
        );
        if (props.isFrom == "invite" || props.isFrom == "automatch") {
          props.setCandidateProfile(false);
        }
        reloadDisposition();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        size="lg"
        show={disqualifyPopup}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">
              {disqualifyData.status == 32 ||
              disqualifyData.dispositionStatus == 38 ||
              disqualifyData.dispositionStatus == 33
                ? "Withdraw Candidate "
                : "Disqualify Candidate"}
            </h6>
            <button
              type="button"
              onClick={() => setDisqualifyPopup(false)}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="text-center">
              {
                <Avatar
                  size="35"
                  name={
                    disqualifyData?.firstName + " " + disqualifyData?.lastName
                  }
                  round="80px"
                />
              }
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {disqualifyData.firstName} {disqualifyData.lastName}
              </h6>
              <p>
                {jobData.isRemote ? (
                  <span className="mb-1 mr-1">Remote</span>
                ) : (
                  <span className="mb-1 mr-1">{jobData.city}</span>
                )}{" "}
                /{<span className="mb-1 mr-1">{jobData.tenantName}</span>} /
                {jobData.jobType === "Full Time"
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData?.placementCurrency
                        ? `${jobData?.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    })?.format(jobData?.maximumPay)
                  : new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData?.placementCurrency
                        ? `${jobData?.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    }).format(jobData?.maximumPay)}
              </p>
            </div>
          </div>

          <div style={{ padding: "1px 2px 35px 0px" }}></div>

          <div>
            <div className="bl-modal-body pt-0">
              <div className="text-right mb-2"> *Denotes mandatory field</div>
              <div className="form-floating">
                <select
                  onChange={handleChange}
                  placeholder={
                    props.isFrom == "invite" || props.isFrom == "automatch"
                      ? "Please provide the reason for withdrawal*"
                      : "Please provide the reason for disqualification*"
                  }
                >
                  <option value={""} selected>
                    {props.isFrom == "invite" || props.isFrom == "automatch"
                      ? "Please provide the reason for withdrawal*"
                      : "Please provide the reason for disqualification*"}
                  </option>
                  {dropDown.map((data) => (
                    <option
                      key={data.Id}
                      value={data.ReasonForDisqualification}
                    >
                      {data.ReasonForDisqualification}
                    </option>
                  ))}
                </select>
                {selectedDescription && (
                  <div
                    className="description-box mt-3 p-2"
                    style={{
                      backgroundColor: "#f8f9fa",
                      borderRadius: "5px",
                      color: "#555",
                      transition: "opacity 0.3s",
                    }}
                  >
                    {selectedDescription}
                  </div>
                )}
              </div>
              <div className="form-floating mt-3 position-relative">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  maxLength={250}
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                />
                <label htmlFor="floatingTextarea2">
                  Additional Comments or Feedback for Candidate.*
                </label>
                <div className="text-right">
                  <small className="text-muted">
                    {250 - commentText.length} characters remaining
                  </small>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setDisqualifyPopup(false)}
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={!selectedValue || commentText.trim().length === 0}
                className="btn btn-primary btn-sm"
                onClick={handleDisqualify}
              >
                <span>
                  {props.isFrom == "invite" || props.isFrom == "automatch"
                    ? "Withdraw"
                    : "Disqualify"}
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisqualifyPopUp;
