import React, { useState, useEffect, useRef } from "react";
import CandidateProfileOverview from "./CandidateProfileOverview";
import { Modal, OverlayTrigger } from "react-bootstrap";

// import TalentResume from "./TalentResume";
// import TalentTags from "./TalentTags";
import CandidateAssessments from "./sidepaneltabs/CandidateAssessments";
import {
  getUserRole,
  getActualRole,
  getTenantType,
  getCandidateId,
} from "../../../service/AuthService";
import AutoMatchScoreCard from "./sidepaneltabs/AutomatchScoreCard";
import QandAResponse from "./sidepaneltabs/ScreeningQandA";
import TalentProfileEdit from "./talentprofileeditmodal/TalentProfileEdit";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
// import ResendMail from "../../disposition/jobdetails/dispositiondetails/invite/ResendMail";
// import Request from "./Request";
// import ReviewReminder from "../../disposition/jobdetails/dispositiondetails/shortlisted/ReviewReminder";
import Emails from "./talentsidepanel/Emails";
import Messages from "./talentsidepanel/Messages";
import ResendMail from "../../disposition/job/jobdetails/dispositiondetails/invite/ResendMail";
import Request from "./sidepaneltabs/Request";
import ReviewReminder from "../../disposition/job/jobdetails/dispositiondetails/shortlisted/ReviewReminder";
// import Emails from "./Emails";
// import Messages from "./Messages";
// import ReadyToHireQA from "./ReadyToHireQA";
import BulkCommunicationModal from "../../communication/Index";
import Loader from "../../loader/Loader";
import ShortUniqueId from "short-unique-id";
import {
  FetchSmsByUserToCandidate,
  SendSms,
} from "../../../action/communication/Communication";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import History from "./talentsidepanel/History";
// import ActivityHistory from "./sidepaneltabs/ActivityHistory";
import ProfileImage from "../../common/profileimage/ProfileImage";
// import AdditionalInfo from "./AdditionalInfo";
import AddTalentManualEntry from "../addtalent/manualentry/Index";
import TalentHistory from "./talentsidepanel/TalentHistory";

const uid = new ShortUniqueId({ length: 10 });

function TalentProfile(props) {
  const dispatch = useDispatch();
  const { sendSMSCandidateLoading, sendSMS } = useSelector(
    (state) => ({
      sendSMSCandidateLoading:
        state.communicationReducer.sendSMSCandidateLoading,
      sendSMS: state.communicationReducer.sendSMS,
    }),
    shallowEqual
  );
  const [isCandidateFrom, setIsCandidateFrom] = useState("");
  const [overview, setOverview] = useState(props?.assessment ? false : true);
  const [matchingScore, setMatchingScore] = useState(false);
  const [screening, setScreening] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [readyToHireQA, setReadyToHireQA] = useState(false);
  const [requests, setRequests] = useState(false);
  const [emails, setEmails] = useState(false);
  const [messages, setMessages] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [history, setHistory] = useState(false);
  const [talentHistory, setTalentHistory] = useState(false);

  const [resume, setResume] = useState(false);
  const [message, setMessage] = useState("");
  const [assessments, setAssessments] = useState(
    props?.assessment ? true : false
  );
  const [editTalent, setEditTalent] = useState(false);
  const [addTags, setAddTags] = useState(false);
  const [reviewerReminder, setReviewerReminderPopup] = useState(false);

  const [userRole, setUserRole] = useState();
  const [dropDownButton, setDropDownButton] = useState(false);
  const tabWhenClicked = useRef(props?.getTab);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [whatsAppModal, setWhatsAppModal] = useState(false);
  useEffect(() => {
    if (props?.isCandidateFrom && props?.isCandidateFrom === "sms") {
      setOverview(false);
      setResume(false);
      setRequests(false);
      setAssessments(false);
      setMatchingScore(false);
      setScreening(false);
      setAddTags(false);
      setEmails(false);
      setReadyToHireQA(false);
      setMessages(true);
      setHistory(false);
      setAdditionalInfo(false);
      setWhatsApp(false);
    } else if (props?.isCandidateFrom && props?.isCandidateFrom === "email") {
      setOverview(false);
      setResume(false);
      setRequests(false);
      setAssessments(false);
      setMatchingScore(false);
      setScreening(false);
      setAddTags(false);
      setEmails(true);
      setReadyToHireQA(false);
      setMessages(false);
      setHistory(false);
      setAdditionalInfo(false);
      setWhatsApp(false);
    } else if (props?.assessment) {
      setOverview(false);
      setMatchingScore(false);
      setScreening(false);
      setRequests(false);
      setAssessments(true);
      setResume(false);
    } else {
      setOverview(true);
      setMatchingScore(false);
      setScreening(false);
      setRequests(false);
      setAssessments(false);
      setResume(false);
    }
  }, [props?.candidateData, props?.isCandidateFrom]);

  const shortlistCandidate = async (item) => {
    props?.setSelectedCandidate(item);

    let vettingDetails = item?.vettingDetails;

    if (vettingDetails?.length > 0 && vettingDetails[0]?.status == "Invited") {
      props?.setVettingResendPopup(true);
    } else if (
      vettingDetails?.length > 0 &&
      vettingDetails[0]?.status === "underReview"
    ) {
      setReviewerReminderPopup(true);
    } else {
      props?.setShortlistPopup(true);
    }
  };

  const buttonHandler = (item) => {
    if (
      item?.prevStatus == 6 &&
      props?.jobData?.jobStatus !== 23 &&
      props?.jobData?.jobStatus !== 26 &&
      props?.jobData?.jobStatus !== 39 &&
      (getActualRole() == "High5Coordinator" ||
        getUserRole() == "HiringManager")
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm ms-3"
          onClick={() => handleButton(item, "Shortlist")}
        >
          Shortlist
        </button>
      );
    } else if (
      item?.prevStatus === 33 &&
      props?.jobData?.jobStatus !== 23 &&
      props?.jobData?.jobStatus !== 26 &&
      props?.jobData?.jobStatus !== 39 &&
      getUserRole() === "Recruiter"
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm ms-3"
          onClick={() => handleButton(item, "Invite")}
        >
          Invite
        </button>
      );
    } else if (
      (item?.prevStatus === 8 ||
        item?.prevStatus === 29 ||
        item?.prevStatus === 37) &&
      props?.jobData?.jobStatus !== 23 &&
      props?.jobData?.jobStatus !== 26 &&
      props?.jobData?.jobStatus !== 39 &&
      getUserRole() === "HiringManager"
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm ms-3"
          onClick={() => handleButton(item, "offer")}
        >
          {item?.disqualifyComments?.includes("Offer Rejected")
            ? "Re-Offer"
            : item?.prevStatus == "37"
            ? "Re-Offer"
            : "Offer"}
        </button>
      );
    }
  };

  const handleButton = async (item, status) => {
    if (status == "Shortlist") {
      props?.setSelectedCandidate(item);
      props?.setShowShortlistPopup(true);
    } else if (status == "Invite") {
      props?.inviteTalent(item);
    } else if (status == "offer") {
      props?.setSelectedCandidate(item);
      props?.setMakeAnOfferPopup(true);
    }
  };

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
  }, []);

  const handleClose = () => {
    !editTalent && !showEmailModal && props?.handleSuccess(true);
    if (props?.isCandidateFrom) {
      props?.setIsCandidateFrom("");
      props?.setCandidateData && props?.setCandidateData({});
    }
  };
  const diffDate = (someday) => {
    const today = moment();
    return today?.diff(someday, "hours");
  };
  const onTabClick = (type) => {
    setOverview(false);
    setRequests(false);
    setResume(false);
    setAssessments(false);
    setMatchingScore(false);
    setScreening(false);
    setAddTags(false);
    setEmails(false);
    setMessages(false);
    setReadyToHireQA(false);
    setHistory(false);
    setAdditionalInfo(false);
    setWhatsApp(false);
    setTalentHistory(false);

    switch (type) {
      case "overview":
        setOverview(true);
        break;
      case "requests":
        setRequests(true);
        break;
      case "resume":
        setResume(true);
        break;
      case "assessments":
        setAssessments(true);
        break;
      case "score":
        setMatchingScore(true);
        break;
      case "screening":
        setScreening(true);
        break;
      case "tags":
        setAddTags(true);
        break;
      case "emails":
        setEmails(true);
        break;
      case "messages":
        setMessages(true);
        break;
      case "readyToHireQA":
        setReadyToHireQA(true);
        break;
      case "history":
        setHistory(true);
        break;
      case "additionalInfo":
        setAdditionalInfo(true);
        break;
      case "whatsApp":
        setWhatsApp(true);
        break;
      case "talentHistory":
        setTalentHistory(true);
        break;
      default:
        setRequests(true);
        break;
    }
  };
  const messageIndicatorStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "2px",
  };

  const indicatorBoxStyle = {
    width: "12px",
    height: "12px",
  };

  const indicatorTextStyle = {
    fontSize: "16px",
    marginRight: "5px",
  };
  const handleCommunicationModal = () => {
    setShowEmailModal(true);
  };
  const handleSendMessage = () => {
    const updatedSmsContent = message.replace(/<[^>]*>/g, "");
    let recipientDetails = [
      {
        phone:
          props?.candidateData?.homePhoneCode + props?.candidateData?.homePhone,
        email: props?.candidateData?.email,
      },
    ];
    let reqPayload = {
      recipients: recipientDetails,
      messageContent: updatedSmsContent,
      userId: getCandidateId(),
      broadCastId: uid.rnd(),
      messageSubject: "SMS Notification",
      userType: getUserRole(),
      poolId: props?.poolId ? props?.poolId : null,
    };

    dispatch(SendSms(reqPayload, "chatbox"));

    setMessage("");

    // Call useEffect dispatch after 10 seconds
    setTimeout(() => {
      const reqPayload2 = {
        userId: getCandidateId(),
        candidateNumber:
          props?.candidateData?.homePhoneCode + props?.candidateData?.homePhone,
        currentPage: 1,
      };
      dispatch(FetchSmsByUserToCandidate(reqPayload2));
    }, 10000); // 10 seconds delay
  };

  return (
    <>
      {sendSMSCandidateLoading && <Loader />}
      <OutsideClickHandler onOutsideClick={() => handleClose()}>
        <div className="view-panel show">
          <div className="mb-3">
            <div className="px-3 py-2">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-icon"
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="px-3 pt-2">
              <div className="d-flex align-items-center flex-wrap-reverse">
                <div className="d-flex">
                  <ProfileImage
                    imageSrc={props?.candidateData?.profileImagePath}
                    imageWidth={"55"}
                    imageHeight={"55"}
                    avatarSize={"55"}
                    avatarRound={"80px"}
                    firstName={props?.candidateData?.firstName}
                    lastName={props?.candidateData?.lastName}
                  />

                  <div className="ml-3 mb-1 mt-2" style={{ minHeight: "65px" }}>
                    <h6 style={{ minHeight: "35px" }}>
                      <span>
                        {props?.candidateData?.firstName
                          ?.charAt(0)
                          ?.toUpperCase() +
                          props?.candidateData?.firstName?.slice(1)}{" "}
                        {props?.candidateData?.lastName
                          ?.charAt(0)
                          ?.toUpperCase() +
                          props?.candidateData?.lastName?.slice(1)}{" "}
                      </span>
                      {props?.parent === "disqualified" &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 &&
                        props.buttonHandler(props?.candidateData)}
                      {props?.parent === "submitTab" &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 &&
                        (getActualRole() == "High5Coordinator" ||
                          getUserRole() == "HiringManager") && (
                          <div className="btn-group btn-group-sm btn-split ms-3">
                            <button
                              type="button"
                              onClick={() => {
                                shortlistCandidate(props?.candidateData);
                              }}
                              className="btn btn-sm"
                              // disabled={props?.candidateData?.vettingDetails?.some(
                              //   (x) => x.status === "underReview"
                              // )}
                            >
                              Shortlist
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setDropDownButton(true);
                                props?.openDisqualify(props?.candidateData);
                              }}
                              className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            {props?.disqualifyData?.userId ==
                              props?.candidateData?.userId &&
                              dropDownButton && (
                                <OutsideClickHandler
                                  onOutsideClick={() =>
                                    setDropDownButton(false)
                                  }
                                >
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{
                                      display: "revert",
                                      right: "0px",
                                      top: "30px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => {
                                        props?.onDisqualifyClick(
                                          props?.candidateData
                                        );
                                      }}
                                      className="dropdown-item"
                                    >
                                      Disqualify
                                    </button>
                                  </div>
                                </OutsideClickHandler>
                              )}
                          </div>
                        )}
                      {props?.parent === "shortlistTab" &&
                        getUserRole() !== "SuperAdmin" &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 &&
                        getTenantType() == 1 && (
                          <div
                            className={"btn-group btn-group-sm btn-split ms-3"}
                          >
                            <button
                              type="button"
                              onClick={() =>
                                props?.makeAnOfferOpen(props?.candidateData)
                              }
                              className="btn btn-sm"
                            >
                              Make an Offer
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                setDropDownButton(true);
                                props?.openDisqualify(props?.candidateData);
                              }}
                              className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            />
                            {props?.disqualifyData?.userId ==
                              props?.candidateData?.userId &&
                              dropDownButton && (
                                <OutsideClickHandler
                                  onOutsideClick={() =>
                                    setDropDownButton(false)
                                  }
                                >
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{
                                      display: "revert",
                                      right: "0px",
                                      top: "30px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        props?.onDisqualifyClick(
                                          props?.candidateData
                                        )
                                      }
                                      className="dropdown-item"
                                    >
                                      Disqualify
                                    </button>
                                  </div>
                                </OutsideClickHandler>
                              )}
                          </div>
                        )}
                      {props?.isFrom === "inviteTab" &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 &&
                        props?.isFrom === "inviteTab" &&
                        (diffDate(props?.candidateData?.invitedDate) > 48 &&
                        getUserRole() === "Recruiter" ? (
                          <div className="btn-group btn-group-sm btn-split ms-3">
                            <ResendMail
                              talentData={props?.candidateData}
                              jobData={props?.jobData}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setDropDownButton(true);
                                props?.openDisqualify(props?.candidateData);
                              }}
                              className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            />
                            {props?.disqualifyData?.userId ==
                              props?.candidateData?.userId &&
                              dropDownButton && (
                                <OutsideClickHandler
                                  onOutsideClick={() =>
                                    setDropDownButton(false)
                                  }
                                >
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    style={{
                                      display: "revert",
                                      right: "0px",
                                      top: "30px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => {
                                        props?.onDisqualifyClick(
                                          props?.candidateData
                                        );
                                      }}
                                      className="dropdown-item"
                                    >
                                      {getUserRole() == "Recruiter" ||
                                      getUserRole() == "CommunityManager"
                                        ? "Withdraw"
                                        : "Disqualify"}
                                    </button>
                                  </div>
                                </OutsideClickHandler>
                              )}
                          </div>
                        ) : (
                          getUserRole() === "Recruiter" && (
                            <button
                              type="button"
                              onClick={() =>
                                props?.onDisqualifyClick(props?.candidateData)
                              }
                              className="btn btn-sm"
                            >
                              {getUserRole() == "Recruiter" ||
                              getUserRole() == "CommunityManager"
                                ? "Withdraw"
                                : "Disqualify"}
                            </button>
                          )
                        ))}
                      {props?.isFrom == "autoTab" &&
                        (getUserRole() == "Recruiter" ||
                          getUserRole() == "CommunityManager") &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 && (
                          <button
                            type="button"
                            onClick={() =>
                              props?.onDisqualifyClick(props?.candidateData)
                            }
                            className="btn btn-sm ms-3"
                          >
                            Withdraw
                          </button>
                        )}
                      {props?.isFrom === "offerTab" &&
                        (getUserRole() === "High5Coordinator" ||
                          getUserRole() === "HiringManager") &&
                        props?.jobData?.jobStatus !== 23 &&
                        props?.jobData?.jobStatus !== 26 &&
                        props?.jobData?.jobStatus !== 39 && (
                          <button
                            type="button"
                            onClick={() =>
                              props?.onDisqualifyClick(props?.candidateData)
                            }
                            className="btn btn-sm ms-3"
                          >
                            Disqualify
                          </button>
                        )}
                    </h6>
                    <div className="mb-1"></div>
                    {props?.parent !== "TalentListView" && (
                      <div className="d-flex align-items-start mb-1">
                        {props?.component !== "SourceTalent" && (
                          <span>
                            {props?.candidateData?.dispositionStatusName && (
                              <span className="tag tag-lg">
                                {props?.candidateData?.dispositionStatusName ===
                                "Sourced"
                                  ? "Approval Pending"
                                  : props?.candidateData
                                      ?.dispositionStatusName == "Pending"
                                  ? "Offer Pending"
                                  : props?.candidateData
                                      ?.dispositionStatusName === "Rejected"
                                  ? "Ineligible"
                                  : props?.candidateData
                                      ?.dispositionStatusName ===
                                    "Offer Rejected"
                                  ? "Offer Declined"
                                  : props?.candidateData
                                      ?.dispositionStatusName === "Submitted" &&
                                    props?.isFrom === "submitPooled"
                                  ? "Engaged"
                                  : props?.candidateData
                                      ?.dispositionStatusName === "Offered" &&
                                    props?.isFrom === "hiredPooled"
                                  ? "Ready To Hire"
                                  : props?.candidateData
                                      ?.dispositionStatusName === "Vetting"
                                  ? "Vetted"
                                  : props?.candidateData?.dispositionStatusName}
                              </span>
                            )}
                          </span>
                        )}
                      </div>
                    )}
                    <div class="d-flex align-items-center">
                      {
                        <span>
                          {props?.candidateData?.designation ? (
                            <span>{props?.candidateData?.designation}</span>
                          ) : (
                            props?.candidateData?.experiences?.length > 0 &&
                            props?.candidateData?.experiences[0]?.jobTitle
                          )}
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div class="ml-auto d-flex align-items-center">
                {props?.parent === "TalentListView" && (
                  <div class="mr-1">
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title=""
                      data-original-title="Download Resume"
                      class="d-inline-block"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-gray"
                        onClick={() => onTabClick("resume")}
                      >
                        <i class="fas fa-file-download" aria-hidden="true"></i>
                        <span class="d-none-600 ml-1">Resume</span>
                      </button>
                    </div>
                  </div>
                )}
                {props?.parent === "TalentListView" &&
                  getUserRole() === "Recruiter" && (
                    <div class="mr-2">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        onClick={() => onTabClick("tags")}
                      >
                        <i class="fa fa-tags" aria-hidden="true"></i>
                        Tags
                      </button>
                    </div>
                  )}
                {userRole === "Recruiter" &&
                  props?.parent === "TalentListView" && (
                    <div>
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        onClick={() => setEditTalent(true)}
                      >
                        <i class="fas fa-pencil-alt mr-1"></i>
                        Edit Profile
                      </button>
                    </div>
                  )}
              </div>
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={overview ? " nav-link active" : "nav-link"}
                    onClick={() => onTabClick("overview")}
                    type="button"
                    // style={{ padding: "20.5px" }}
                  >
                    Profile
                  </button>
                </li>
                {props?.candidateData?.additionalInfo &&
                  JSON?.parse(props?.candidateData?.additionalInfo)?.length >
                    0 && (
                    <li className="nav-item">
                      <button
                        className={
                          additionalInfo ? "nav-link active" : "nav-link"
                        }
                        onClick={() => onTabClick("additionalInfo")}
                        type="button"
                      >
                        {props?.component === "TalentPool" && "Additional Info"}
                      </button>
                    </li>
                  )}
                {props?.candidateData?.screeningQuestions &&
                  JSON?.parse(props?.candidateData?.screeningQuestions)
                    ?.length > 0 && (
                    <li className="nav-item">
                      <button
                        className={screening ? "nav-link active" : "nav-link"}
                        onClick={() => onTabClick("screening")}
                        type="button"
                      >
                        {props?.component === "TalentPool"
                          ? "Knockout Assessment"
                          : "Screening"}
                      </button>
                    </li>
                  )}
                {props?.candidateData?.vettingDetails?.length > 0 &&
                  props?.isFrom !== "Invited" && (
                    <li className="nav-item">
                      <button
                        onClick={() => onTabClick("assessments")}
                        type="button"
                        className={
                          assessments ? " nav-link active" : "nav-link"
                        }
                      >
                        Assessments
                      </button>
                    </li>
                  )}
                {props?.candidateData?.readyToHireQA &&
                  props?.isFrom !== "Invited" && (
                    <li className="nav-item">
                      <button
                        onClick={() => onTabClick("readyToHireQA")}
                        type="button"
                        className={
                          readyToHireQA ? " nav-link active" : "nav-link"
                        }
                      >
                        Ready To Hire
                      </button>
                    </li>
                  )}
                {(userRole === "Recruiter" || userRole === "HiringManager") &&
                  props?.parent === "TalentListView" && (
                    <li className="nav-item">
                      <button
                        className={requests ? "nav-link active" : "nav-link"}
                        onClick={() => onTabClick("requests")}
                        type="button"
                      >
                        Requests
                      </button>
                    </li>
                  )}
                {(props?.parent === "TalentListView" ||
                  props?.isFor === "TalentPool") &&
                  (userRole === "Recruiter" ||
                    userRole === "CustomerSuccess") && (
                    <li className="nav-item">
                      <button
                        className={
                          talentHistory ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          onTabClick("talentHistory");
                        }}
                        type="button"
                      >
                        History
                      </button>
                    </li>
                  )}
                {
                  <li className="nav-item">
                    <button
                      className={emails ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setIsCandidateFrom("email");
                        onTabClick("emails");
                      }}
                      type="button"
                    >
                      Emails
                    </button>
                  </li>
                }
                {
                  <li className="nav-item">
                    <button
                      className={messages ? "nav-link active" : "nav-link"}
                      onClick={() => {
                        setIsCandidateFrom("sms");
                        onTabClick("messages");
                      }}
                      type="button"
                    >
                      Messages
                    </button>
                  </li>
                }
                {!(
                  (props?.candidateData?.prevStatus == 33 &&
                    props?.candidateData?.dispositionStatus == 12) ||
                  props?.candidateData?.dispositionStatus == 33
                ) &&
                  getUserRole() !== "High5Coordinator" &&
                  getUserRole() !== "SuperAdmin" &&
                  getUserRole() !== "CustomerSuccess" &&
                  props?.component !== "TalentPool" &&
                  props?.parent !== "RecPipe" &&
                  props?.parent !== "TalentListView" &&
                  props?.parent !== "inviteTab" &&
                  props?.parent !== "autoTab" &&
                  props?.parent !== "Invited" && (
                    <li className="nav-item">
                      <button
                        className={requests ? "nav-link active" : "nav-link"}
                        onClick={() => onTabClick("score")}
                        type="button"
                      >
                        Matching Score
                      </button>
                    </li>
                  )}
              </ul>
              {messages && (
                <div style={messageIndicatorStyle}>
                  <div
                    style={{ ...indicatorBoxStyle, backgroundColor: "#e1e1e1" }}
                  ></div>
                  <span style={indicatorTextStyle}>Received</span>{" "}
                  <div
                    style={{ ...indicatorBoxStyle, backgroundColor: "#007bff" }}
                  ></div>
                  <span style={indicatorTextStyle}>Sent</span>
                </div>
              )}

              {whatsApp && (
                <div style={messageIndicatorStyle}>
                  <div
                    style={{ ...indicatorBoxStyle, backgroundColor: "#007bff" }}
                  ></div>
                  <span style={indicatorTextStyle}>Sent</span>
                </div>
              )}
            </div>
          </div>
          {messages && (
            <div className="px-4 mt-2 d-flex gap-2">
              <input
                type="text"
                className="form-control"
                placeholder="Type your message"
                style={{
                  fontSize: "14px",
                }}
                // "Type your message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <button
                className="btn btn btn-md"
                title="Send"
                disabled={message === ""}
                onClick={handleSendMessage}
              >
                <i class="far fa-paper-plane"></i>{" "}
              </button>
            </div>
          )}
          <div className="view-panel-body tagManagementScrollHeight">
            {overview && (
              <CandidateProfileOverview
                fullText={props?.candidateData}
                candidateData={props?.candidateData}
                getTab={tabWhenClicked?.current}
                isFrom={props.parent}
                jobData={props?.jobData}
              />
            )}

            {talentHistory && (
              <TalentHistory talentData={props?.candidateData} />
            )}
            {/* {assessments && (
              <CandidateAssessments
                candidateData={props?.candidateData}
                component={props?.component}
                jobData={props?.jobData}
              />
            )}
            {matchingScore && (
              <AutoMatchScoreCard
                candidateData={props?.candidateData}
                jobData={props?.jobData}
                isFrom={props?.isFrom}
                parent={props?.parent}
              />
            )}
            {screening && (
              <QandAResponse
                QandA={
                  props?.candidateData?.screeningQuestions
                    ? JSON?.parse(props?.candidateData?.screeningQuestions)
                    : []
                }
              />
            )}
            {/* {additionalInfo && (
              <AdditionalInfo
                QandA={
                  props?.candidateData?.additionalInfo
                    ? JSON?.parse(props?.candidateData?.additionalInfo)
                    : []
                }
              />
            )} */}
            {/* {readyToHireQA && (
              <ReadyToHireQA
                QandA={
                  props?.candidateData?.readyToHireQA
                    ? JSON?.parse(props?.candidateData?.readyToHireQA)
                    : []
                }
              />
            )} */}
            {/* {resume && <TalentResume talentData={props?.candidateData} />} */}
            {history && (
              <History
                talentData={props?.candidateData}
                isFrom={props.component}
              />
            )}
            {/* {requests && (
              <Request statusData={props?.candidateData?.statusData} />
            )} */}
            {emails && (
              <Emails
                handleCommunicationModal={handleCommunicationModal}
                candidateData={props?.candidateData}
                poolId={props?.poolId}
                isCandidateFrom={isCandidateFrom}
                setIsCandidateFrom={setIsCandidateFrom}
              />
            )}
            {messages && <Messages candidateData={props?.candidateData} />}
            {/* {whatsApp && (
              <WhatsApp
                candidateData={props?.candidateData}
                handleCommunicationModal={handleCommunicationModal}
              />
            )} */}
            {/* <div className="">
              {addTags && (
                <TalentTags
                  talentData={props?.candidateData}
                  getTalentList={props?.getTalentList}
                  handleClose={handleClose}
                  parentTab={props.parentTab}
                />
              )}
            </div> */}
          </div>

          {reviewerReminder && (
            <ReviewReminder
              candidateData={props?.candidateData}
              jobData={props?.jobData}
              vettingResendPopup={reviewerReminder}
              setVettingResendPopup={setReviewerReminderPopup}
            />
          )}

          {/* <ModalView
               modalSize={"lg"}
               modalTitle={"Edit Talent"}
               isModalOpen={editTalent}
               setModalClose={setEditTalent}
               parentModalClose={props?.setShowCandidateProfile}
              data={props?.candidateData}
              handleClear={props.handleClear}
              isFrom={"TalentProfileEdit"}
              isFor={props?.isFor}
               fetchData={props?.fetchData}
             /> */}
          {editTalent && (
            <TalentProfileEdit
              closeModal={props?.setShowCandidateProfile}
              data={props?.candidateData}
              handleClear={props.handleClear}
              fetchData={props?.fetchData}
              editTalent={editTalent}
            />
          )}
          {/* <Modal
            show={editTalent}
            size="lg"
            onHide={props?.setShowCandidateProfile}
          >
            <AddTalentManualEntry
              isFrom={"TalentProfileEdit"}
              isFor={"TalentPool"}
              closeModal={props?.setShowCandidateProfile}
              data={props?.candidateData}
              handleClear={props.handleClear}
              fetchData={props?.fetchData}
            />
          </Modal> */}
        </div>
        {showEmailModal && (
          <BulkCommunicationModal
            isModalOpen={showEmailModal}
            setModalClose={setShowEmailModal}
            selectedTalents={[props?.candidateData]}
            talentProfile={"talentProfile"}
            isFrom={"TalentProfileEdit"}
            parentModalClose={props?.setShowCandidateProfile}
            modalSize={"xl"}
            poolId={props?.jobData?.poolId}
            isCandidateFrom={isCandidateFrom}
          />
        )}
        {/* {whatsAppModal && (
          <BulkCommunicationModal
            isModalOpen={showEmailModal}
            setModalClose={setShowEmailModal}
            selectedTalents={[props?.candidateData]}
            talentProfile={"talentProfile"}
            isFrom={"TalentProfileEdit"}
            parentModalClose={props?.setShowCandidateProfile}
            modalSize={"xl"}
          />
        )} */}
      </OutsideClickHandler>
    </>
  );
}

export default TalentProfile;
