import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_ADDITIONAL_INFO_OVERVIEW_DATA_BEGIN =
  "GET_ADDITIONAL_INFO_OVERVIEW_DATA_BEGIN";
export const GET_ADDITIONAL_INFO_OVERVIEW_DATA_SUCCESS =
  "GET_ADDITIONAL_INFO_OVERVIEW_DATA_SUCCESS";
export const GET_ADDITIONAL_INFO_OVERVIEW_DATA_FAILURE =
  "GET_ADDITIONAL_INFO_OVERVIEW_DATA_FAILURE";

export const getAdditionalInfoOverView = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADDITIONAL_INFO_OVERVIEW_DATA_BEGIN,
    });
    const res = await post("/community/getAdditionalOverview", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_ADDITIONAL_INFO_OVERVIEW_DATA_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ADDITIONAL_INFO_OVERVIEW_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
