import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";

const RecruiterInfo = ({
  data,
  setData,
  validation,
  setValidation,
  regionOption,
  setRegionOption,
  languageOptions,
  skillOptions,
  setSkillsOption,
  experienceOptions,
  industriesOptions,
  setIndustriesOptions,
  industriesOptionsAll,
  skillsOptions,
  dropdownData,
  setCategoryLoading,
}) => {
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryOptionsAll, setSubCategoryOptionsAll] = useState([]);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleChangeMultiSelect = (id, value) => {
    if (id === "yearsOfExperience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "education" && validation.education) {
      setValidation({ ...validation, education: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skills") {
      if (value.includes("All")) {
        setData({
          ...data,
          skills: skillOptions
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSkillsOption([]);
      } else {
        setSkillsOption(
          skillOptions.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else if (id === "regions") {
      if (value.includes("Global - GL")) {
        setData({ ...data, [id]: ["Global - GL"] });
        let temp = regionOption.map((item) => {
          item.disabled = true;
          return item;
        });
        setRegionOption(temp);
      } else if (value.length == 0) {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          item.disabled = false;
          return item;
        });
        setRegionOption(temp);
      } else {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          if (item.value == "Global - GL") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        setRegionOption(temp);
      }
    } else if (id === "subCategory") {
      if (value.includes("All")) {
        setData({
          ...data,
          subCategory: subCategoryOptionsAll
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSubCategoryOptions([]);
      } else {
        setSubCategoryOptions(
          subCategoryOptionsAll.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleChangeIndustry = async (val) => {
    if (val.length > 0) {
      let industry = [];
      if (val.includes("All")) {
        industry = dropdownData.filter((item) => item.typeName == "skillSet");
        industry = industry.map((item) => item.id);
        setIndustriesOptions([]);
      } else {
        industry = dropdownData.filter(
          (item) => val.includes(item.name) && item.typeName == "skillSet"
        );
        industry = industry.map((item) => item.id);
        setIndustriesOptions(
          industriesOptionsAll.filter((item) => !val.includes(item.value))
        );
        setData({ ...data, industries: val, subCategory: [] });
      }
      const payload = {
        industry,
      };
      try {
        setCategoryLoading(true);
        const res = await post("/common/subcategory", payload);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
          if (val.includes("All")) {
            setData({
              ...data,
              industries: industriesOptionsAll
                .filter((item) => item.value !== "All")
                .map((i) => i.label),
              subCategory: res.data.map((item) => item.categoryName),
            });
          }
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    } else {
      setIndustriesOptions(industriesOptionsAll);
      setSubCategoryOptions([]);
      setSubCategoryOptionsAll([]);
      setData({ ...data, subCategory: val, industries: val });
    }
  };
  return (
    <>
      <Row>
        <Col>
          <label>
            How many years of experience do you have in recruiting?*
          </label>
          <div
            className="form-floating Hover-Dropdown"
            style={{ zIndex: "998" }}
          >
            <Select
              options={experienceOptions}
              name="Education"
              className={validation.experience && "border border-danger"}
              isSearchable={true}
              onChange={(val) =>
                handleChangeMultiSelect("yearsOfExperience", val.value)
              }
            ></Select>
            <small className="validation ml-2">{validation.experience}</small>
          </div>
        </Col>
        <Col>
          <label htmlFor="industrySelect">
            In which job category do you recruit?*
          </label>
          <div
            className="form-floating hover-Dropdown"
            style={{ zIndex: "998" }}
          >
            <Select
              id="industrySelect"
              isMulti
              options={industriesOptions}
              value={
                data.industries
                  ? industriesOptionsAll.length - 1 === data.industries.length
                    ? [{ label: "All", value: "All" }]
                    : industriesOptionsAll.filter((f) =>
                        data.industries.includes(f.value)
                      )
                  : []
              }
              name="Industry"
              isSearchable={true}
              className={validation.industry && "border border-danger"}
              onKeyDown={handleKeyDown} // Attach the key down handler
              onChange={(value) =>
                handleChangeIndustry(value?.map((i) => i.value))
              }
            />
            <small className="validation ml-2">{validation.industry}</small>
          </div>
        </Col>

        <Col>
          <label style={{ paddingTop: "38px" }}>Sub category?*</label>
          <div
            className="form-floating hover-Dropdown"
            style={{ zIndex: "998" }}
            disabled={true}
          >
            <Select
              isMulti
              options={subCategoryOptions.sort((a, b) => b.label - a.label)}
              name="subCategory"
              isSearchable={true}
              className={validation.subCategory && "border border-danger"}
              value={
                data.subCategory
                  ? subCategoryOptionsAll.length - 1 == data.subCategory.length
                    ? [{ label: "All", value: "All" }]
                    : subCategoryOptionsAll.filter((f) =>
                        data.subCategory.includes(f.value)
                      )
                  : []
              }
              onChange={(value) =>
                handleChangeMultiSelect(
                  "subCategory",
                  value?.map((i) => i.value)
                )
              }
              isDisabled={
                data.industries.length == 0 ||
                industriesOptionsAll.length - 1 == data.industries.length
              }
            ></Select>
            <small className="validation ml-2">{validation.subCategory}</small>
          </div>
        </Col>
      </Row>
      <Row style={{ fontSize: 13 }}>
        <Col>
          <label>Which types of roles do you recruit?*</label>
          <div className="form-floating Hover-Dropdown">
            <Select
              isMulti
              options={skillsOptions}
              value={
                data.skills
                  ? skillOptions.length - 1 == data.skills.length
                    ? [{ label: "All", value: "All" }]
                    : skillOptions.filter((f) => data.skills.includes(f.value))
                  : []
              }
              name="placementSkill"
              isSearchable={true}
              className={validation.skill && "border border-danger"}
              onChange={(value) =>
                handleChangeMultiSelect(
                  "skills",
                  value?.map((i) => i.value)
                )
              }
            ></Select>
            <small className="validation ml-2">{validation.skill}</small>
          </div>
        </Col>
        <Col>
          <label>What regions have you recruited?*</label>
          <div className="form-floating Hover-Dropdown">
            <Select
              isMulti
              options={regionOption}
              isOptionDisabled={(regionOption) => regionOption.disabled}
              name="regionsRecruited"
              isSearchable={true}
              className={validation.region && "border border-danger"}
              onChange={(value) =>
                handleChangeMultiSelect(
                  "regions",
                  value?.map((i) => i.value)
                )
              }
            ></Select>
            <small className="validation ml-2">{validation.region}</small>
          </div>
        </Col>
        <Col>
          <label>What languages can you speak?*</label>
          <div className="form-floating Hover-Dropdown">
            <Select
              isMulti
              options={languageOptions}
              name="LanguageSkill"
              isSearchable={true}
              className={validation.language && "border border-danger"}
              onChange={(value) =>
                handleChangeMultiSelect(
                  "languages",
                  value?.map((i) => i.value)
                )
              }
            ></Select>
            <small className="validation ml-2">{validation.language}</small>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            List the most common job titles you've filled in the last 12 months:
          </label>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="form-floating">
            <input
              type="text"
              className={
                validation.title
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="Job Title 1*"
              value={data.jobTitle.title1}
              onChange={(e) => {
                validation.title && setValidation({ ...validation, title: "" });
                setData({
                  ...data,
                  jobTitle: {
                    ...data.jobTitle,
                    title1: e.target.value,
                  },
                });
              }}
            />
            <label>Job Title 1*</label>
          </div>
          {validation.title && (
            <small className="validation ml-2">{validation.title}</small>
          )}
        </Col>
        <Col>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Job Title 2*"
              value={data.jobTitle.title2}
              onChange={(e) =>
                setData({
                  ...data,
                  jobTitle: {
                    ...data.jobTitle,
                    title2: e.target.value,
                  },
                })
              }
            />
            <label>Job Title 2</label>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-floating mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Job Title 3*"
              value={data.jobTitle.title3}
              onChange={(e) =>
                setData({
                  ...data,
                  jobTitle: {
                    ...data.jobTitle,
                    title3: e.target.value,
                  },
                })
              }
            />
            <label>Job Title 3</label>
          </div>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default RecruiterInfo;
