import React, { useState } from "react";
import { getCandidateName, getTenantType } from "../../../service/AuthService";

import SideNavBar from "../../layout/SideNavbar";
import TopNavBar from "../../layout/TopNavbar";
import Footer from "../../layout/Footer";

import RequestList from "../hiringmanager/requestlist/Index";
import CandidateList from "./candidatelist/Index";
import TalentCommunityList from "./talentCommunityList/Index";

const Index = () => {
  const [toggle, setToggle] = useState(true);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar />
        <TopNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-sm pt-0-768">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div class="mb-5 text-center">
                    <h3>
                      <span className="wave">👋</span>Hi,{" "}
                      {getCandidateName().split(" ")[0]}. Welcome!{" "}
                    </h3>
                  </div>
                  <RequestList />
                  <CandidateList />
                  <TalentCommunityList />
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default Index;
