import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import {
  getJobDispositionDetails,
  getJobDispositionCount,
  getJobDispositionDisqualify,
} from "../../../../../../action/disposition/job/jobdetails/JobDetails";
import ResumeDownload from "../resume/Index";
import TalentProfile from "../../../../../talentpool/talentprofile/TalentProfile.js";
import EmailStatus from "../../../../../emailstatus/EmailStatus.js";
import { getUserRole } from "../../../../../../service/AuthService";
// import { getDispositionCount } from "../../../../../../actions/disposition/DispositionCount";
import ProfileImage from "../../../../../profileimage/ProfileImage.js";

const Automatch = (props) => {
  const { jobData, automatchData, view } = props;
  const dispatch = useDispatch();
  const { Id } = useParams();
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState(false);
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [selectedTalent, setSelectedTalent] = useState({});
  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const handleDisqualify = (data) => {
    setDisqualifyData(data);
    setDisqualifyPopup(true);
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payloadDisposition));
    dispatch(getJobDispositionCount(payload));
    dispatch(getJobDispositionDisqualify(payloadDisqualify));
    setDisqualifyPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {automatchData.map((item, i) => (
        <tr key={i}>
          {/* <td className="no-hover" onClick={() => onProfileClick(item)}> */}
          <td
            onClick={() => onProfileClick(item)}
            className="no-hover"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"30"}
              imageHeight={"30"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />

            <span className="ml-2 font-16 p-4">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase() + item?.lastName.slice(1)}
            </span>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {moment(item.automatchDate).format("MM/DD/YYYY")}
            </span>
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                data-toggle="modal"
                onClick={() => handleResume(item)}
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                // disabled={
                //   getCandidateName() !== item.createdByName &&
                //   getUserRole() !== "CommunityManager"
                // }
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>{" "}
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="View Email Status"
            >
              <div className="btn-container">
                <button
                  type="button"
                  className="btn btn-square btn-gray"
                  onClick={() => {
                    setEmailData(item?.email);
                    setEmailStatus(true);
                    setSelectedTalent(item);
                  }}
                >
                  <i className="fa fa-envelope-o" />
                </button>

                {item?.latestEmailStatus &&
                  item?.latestEmailStatus !== "noMail" && (
                    <span
                      className={`${
                        item?.latestEmailStatus === "delivered"
                          ? "dot-success"
                          : "dot-failure"
                      }`}
                    />
                  )}
              </div>
            </div>
          </td>
          <td className="no-hover">
            <span className="">
              {props?.calculateScore(jobData, item) + "%"}
            </span>
          </td>
          <td className={"text-right"}>
            {(getUserRole() == "Recruiter" ||
              getUserRole() == "CommunityManager") &&
              jobData?.jobStatus !== 23 &&
              jobData?.jobStatus !== 26 &&
              jobData?.jobStatus !== 39 && (
                <button
                  type="button"
                  onClick={() => handleDisqualify(item)}
                  className="btn btn-sm"
                >
                  Withdraw
                </button>
              )}
          </td>
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          setCandidateProfile={setCandidateProfile}
          reloadDisposition={reloadDisposition}
          isFrom="automatch"
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="autoMatched"
          invitedDate={selectedTalent?.automatchDate}
          jobData={jobData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          onDisqualifyClick={handleDisqualify}
          jobData={jobData}
          parent="autoTab"
          isFrom="autoTab"
          component="jobDisposition"
        />
      )}
    </>
  );
};

export default Automatch;
