import React from "react";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";

function ReviewReminder(props) {
  const {
    candidateData,
    jobData,
    vettingResendPopup,
    setVettingResendPopup,
  } = props;

  return (
    <>
      <Modal
        size="lg"
        show={vettingResendPopup}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title"></h6>
            <button
              type="button"
              onClick={() => setVettingResendPopup(false)}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="text-center">
              {candidateData.ImageURL ? (
                <span>
                  <span className="ml-2">
                    {candidateData.firstName} {candidateData.lastName}{" "}
                  </span>
                </span>
              ) : (
                <span>
                  <Avatar
                    className="mb-1"
                    size="55"
                    name={
                      candidateData.firstName + " " + candidateData.lastName
                    }
                    round="90px"
                  />
                </span>
              )}
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {candidateData.firstName} {candidateData.lastName}{" "}
              </h6>
              <p>
                {jobData?.isRemote === true ? (
                  <span className="mb-1 mr-1">Remote</span>
                ) : (
                  <span className="mb-1 mr-1">{jobData?.city}</span>
                )}{" "}
                /
                {
                  <span className="mb-1 mr-1">
                    {} {jobData.tenantName}
                  </span>
                }
                /<span className="mb-1 mr-1">{} </span>
                  <span className="mb-1 mr-1">
                    {" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData.placementCurrency
                        ? `${jobData.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    }).format(jobData.maximumPay)}
                  </span>
              </p>
            </div>
          </div>
          <div style={{ padding: "1px 2px 35px 0px" }}></div>
          <div v-if="showMakeAnOfferForm">
            <div className="bl-modal-body pt-0">
              <p className="text-center">
                <strong>Oops! Requested assessment is incomplete.</strong>
              </p>
              <p className="text-center">
                <p>This candidate can't be shortlisted as review is pending.</p>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={() => setVettingResendPopup(false)}
              >
                Close
              </button>
            </div>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ReviewReminder;
