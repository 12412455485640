import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getUserRole } from "../../../../service/AuthService";
import DDMS from "../../../filter/DDMSFilter";
import {
  visaStatus,
  jobTypeOptions,
  additionalVisaStatus,
} from "../../../../constants/Constants";
import HotJobFilter from "../../../filter/HotJobFilter";
import {
  fetchJobIdFilterData,
  fetchJobLocationFilterData,
  fetchJobTitleFilters,
} from "../../../../action/filter/job/JobFilter";

const JobFilter = (props) => {
  const dispatch = useDispatch();
  const { selectedData, setSelectedData, applyFilter } = props;

  const [collapsible, setCollapsible] = useState({
    jobTitle: false,
    jobId: false,
    jobLocation: false,
    workAuthorization: false,
    hotJob: false,
    workType: false,
  });

  const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
  const uniqueVisaStatus = Array.from(
    new Set(combinedVisaStatus.map(JSON.stringify))
  ).map(JSON.parse);

  const visaStatusOptions = uniqueVisaStatus;

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [jobLocationOptions, setJobLocationOptions] = useState([]);
  const [jobIdOptions, setJobIdOptions] = useState([]);

  const { jobTitleFilters, jobLocationFilters, jobIdFilters, loading } =
    useSelector(
      (state) => ({
        jobTitleFilters: state.getJobFilterReducer.jobTitleFilters,
        jobLocationFilters: state.getJobFilterReducer.jobLocationFilters,
        jobIdFilters: state.getJobFilterReducer.jobIdFilters,
        loading: state.getJobFilterReducer.loading,
      }),
      shallowEqual
    );

  const handleFilterToggle = (filter) => {
    setCollapsible({
      jobTitle: false,
      jobId: false,
      jobLocation: false,
      workAuthorization: false,
      hotJob: false,
      workType: false,
      [filter]: !collapsible[filter],
    });
  };

  const handleAPICalls = (filter) => {
    const payload = {
      role: getUserRole(),
      pageSize: 10,
      pageNumber: 1,
      searchText: "",
    };
    if (filter == "jobTitle") {
      dispatch(fetchJobTitleFilters(payload));
    }
    if (filter == "jobLocation") {
      dispatch(fetchJobLocationFilterData(payload));
    }
    if (filter == "jobId") {
      dispatch(fetchJobIdFilterData(payload));
    }
  };

  const fetchJobTitles = async (searchValue, filter) => {
    if (filter == "jobTitle" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobTitleFilters(payload));
    }
  };

  const fetchJobIds = async (searchValue, filter) => {
    if (filter == "jobId" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobIdFilterData(payload));
    }
  };

  const fetchJobLocations = async (searchValue, filter) => {
    if (filter == "jobLocation" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobLocationFilterData(payload));
    }
  };

  useEffect(() => {
    if (jobTitleFilters && jobTitleFilters?.data?.length > 0) {
      setJobTitleOptions(
        jobTitleFilters?.data?.map((item) => {
          return {
            value: item.jobTitle,
            label: item.jobTitle,
          };
        })
      );
    }
    if (jobIdFilters && jobIdFilters?.data?.length > 0) {
      setJobIdOptions(
        jobIdFilters?.data?.map((item) => {
          return {
            value: item.jobId,
            label: item.jobId,
          };
        })
      );
    }
    if (jobLocationFilters && jobLocationFilters?.data?.length > 0) {
      let locationOptions =
        jobLocationFilters?.data?.map((item) => ({
          value: item.address,
          label: item.address,
        })) || [];

      // Find the index of the "Remote" option
      const remoteIndex = locationOptions.findIndex(
        (option) => option.value === "Remote"
      );

      // If "Remote" option is found, move it to the front
      if (remoteIndex !== -1) {
        const remoteOption = locationOptions.splice(remoteIndex, 1)[0];
        locationOptions = [remoteOption, ...locationOptions];
      }

      setJobLocationOptions(locationOptions);
    }
  }, [jobTitleFilters, jobLocationFilters, jobIdFilters]);

  const handleClear = () => {
    setSelectedData({
      jobTitle: [],
      jobLocation: [],
      jobId: [],
      workAuthorization: [],
      hotJob: false,
      workType: [],
    });
    // setIsFilterApplied(false);
    applyFilter("", "");
  };

  const filterLength =
    selectedData?.jobTitle.length +
    selectedData?.jobLocation.length +
    selectedData?.jobId.length +
    selectedData?.workAuthorization.length +
    (selectedData?.hotJob ? 1 : 0) +
    selectedData?.workType.length;

  return (
    <>
      <div className="card-flat">
        <div className="card-body">
          <div className="view-panel-header view-panel-header-sm">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="px-3"></div>
              <div className="px-3">
                <button
                  disabled={filterLength == 0}
                  type="button"
                  className="btn btn-text"
                  onClick={handleClear}
                >
                  <span className="font-bold">Clear All</span>
                </button>
              </div>
            </div>
          </div>
          <div className="view-panel-body view-panel-body-sm">
            {/* Job Title */}
            <DDMS
              // fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobTitle}
              filterStatus={"jobTitle"}
              filterName={"Job Title"}
              options={jobTitleOptions}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              isMulti={true}
              fetchOptions={fetchJobTitles}
              applyFilter={applyFilter}
              hideDropdown={true}
              isLoading={loading}
            />

            {/* Job Location */}
            <DDMS
              fetchOptions={fetchJobLocations}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobLocation}
              filterStatus={"jobLocation"}
              filterName={"Job Location"}
              options={jobLocationOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={loading}
            />

            {/* Job ID */}
            <DDMS
              fetchOptions={fetchJobIds}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobId}
              filterStatus={"jobId"}
              filterName={"Job ID"}
              options={jobIdOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={loading}
            />
            {/* Work Authorisation */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.workAuthorization}
              filterStatus={"workAuthorization"}
              filterName={"Work Authorization"}
              options={visaStatusOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            />

            {/* Hot Job */}
            <HotJobFilter
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              handleFilterToggle={handleFilterToggle}
              applyFilter={applyFilter}
              collapsible={collapsible}
            />

            {/* Work Type */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.workType}
              filterStatus={"workType"}
              filterName={"Work Type"}
              options={jobTypeOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobFilter;
