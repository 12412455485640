import React from "react";
import high5people from "../../../../../../assets/images/high5people.jpg";

const JoinTalentPoolHeader = () => {
  return (
    <div className="hero-overlay" style={{ height: "370px" }}>
      <img
        src={high5people}
        alt="high5people"
        style={{ height: "650px", marginTop: "225px" }}
      />
      <div className="section pt-7 hero-content">
        <div className="row">
          <div className="col-12">
            <div className=" mb-5">
              <h5 className="font-22 font-white">
                Join High5 talent community!
              </h5>
              <p className="font-14 font-white">
                We're passionate about connecting professionals with incredible
                opportunities!
              </p>
              <p className="font-14 font-white">
                Sign up for our talent community to receive updates on job
                opportunities, news, and recruiting events at High5.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinTalentPoolHeader;
