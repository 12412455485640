import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";

import PaginationComponent from "../../../common/pagination/Pagination";
import NoRecord from "../../../common/NoRecord";
import { CurrencySymbols } from "../../../../constants/CurrencySymbols";
import Loader from "../../../loader/Loader";
import { get, post } from "../../../../service/ApiService";

const JobModal = ({
  showjobModal,
  setShowJobModal,
  selectedTalents,
  setSelectedTalents,
}) => {
  const [jobList, setJobList] = useState([]);
  const [jobListCounts, setJobListCounts] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobs, setSelectedJobs] = useState([]);

  useEffect(() => {
    setCurrentPage(1);
    getjobs(1);
  }, [searchQuery, pageSize]);

  const getjobs = async (pageNumber) => {
    setLoading(true);
    try {
      let isText = isNaN(searchQuery * 1);
      await post("/talent/fetchJobListFilterData", {
        searchText: isText ? searchQuery : "",
        searchJobId: !isText ? searchQuery : "",
        pageSize: pageSize,
        pageNumber: pageNumber ? pageNumber : currentPage,
      }).then((res) => {
        setJobListCounts(res?.data?.totalCount);
        setJobList(res?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const [selectedData, setSelectedData] = useState({
    jobTitle: [],
    jobLocation: [],
    jobId: [],
    workAuthorization: [],
    hotJob: false,
    workType: [],
  });
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getjobs(pageNumber);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const handleSelectAll = () => {
    try {
      setLoading(true);
      get("/bulkTalentAction/fetchJobListArray").then((res) => {
        let data = Array.isArray(res.data) ? res.data.map((x) => x) : [];
        setSelectedJobs(data);
        setLoading(false);
      });
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };
  const handleInvite = () => {
    try {
      setLoading(true);
      let payload = { candidates: selectedTalents, jobs: selectedJobs };
      post("/talent/bulkTalentInvite", payload).then((res) => {
        setLoading(false);
        setShowJobModal(false);
        setSelectedTalents([]);
        if (res.data.message === "Talents Exists") {
          toast.success(
            `Talent${selectedTalents.length > 1 ? "s" : ""} already invited`
          );
        } else {
          toast.success(
            `Talent${
              selectedTalents.length > 1 ? "s" : ""
            } invited successfully`
          );
        }
      });
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const toggleSelectJob = (job) => {
    let jobId = job?.jobId;
    let jobTitle = job?.jobTitle;

    let data = {
      jobId: jobId,
      jobTitle: jobTitle,
    };

    // setSelectedJobs((prev) =>
    //   prev.includes(job.jobId)
    //     ? prev.filter((x) => x != job.jobId)
    //     : [...prev, job.jobId]
    // );
    setSelectedJobs((prev) =>
      prev.some((x) => x.jobId === jobId)
        ? prev.filter((c) => c.jobId != jobId)
        : [...prev, data]
    );
  };
  const clearSearch = () => {
    setSearchQuery("");
  };
  return (
    <Modal show={showjobModal} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        <h6>Select Job</h6>
        {showjobModal && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body>
        <div className="px-4">
          {loading && <Loader />}
          <div className=" d-flex gap-2 align-items-center justify-content-end mb-2">
            {" "}
            {jobList.length > 0 && (
              <>
                {" "}
                <div className="">
                  <button
                    type="button"
                    disabled={selectedJobs.length === jobListCounts}
                    onClick={handleSelectAll}
                    className="btn btn-text"
                  >
                    <span className="font-bold font-14">Select All</span>
                  </button>
                </div>
                {selectedJobs.length > 0 && (
                  <div className="">
                    <button
                      type="button"
                      disabled={selectedJobs.length === 0}
                      onClick={() => setSelectedJobs([])}
                      className="btn btn-text"
                    >
                      <span className="font-bold font-14">Deselect All</span>
                    </button>
                  </div>
                )}
              </>
            )}
            <div className="">
              <select
                className="form-select font-14"
                onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <div
              className="input-group"
              style={{ border: "1px solid #ddd", width: "200px" }}
            >
              <input
                type="text"
                className="form-control ml-1"
                placeholder="Search by Title, Id"
                style={{ border: "1px solid transparent" }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              {searchQuery && (
                <div className="input-group-append">
                  <button
                    className="btn"
                    type="button"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid transparent",
                      boxShadow: "none",
                    }}
                    onClick={clearSearch}
                  >
                    <FaTimes color={"grey"} />
                  </button>
                </div>
              )}
            </div>
          </div>
          {jobList.length > 0 && (
            <div className="row d-block-force ">
              <div className="d-flex flex-column gap-2">
                {jobList?.map((job, i) => (
                  <>
                    <div
                      className={`card p-0 d-flex w-100 align-items-center 
              justify-content-between job-listview flex-row overflow-hidden mb-1 pointer ${
                job.jobStatus === 13 ? "card-finger-shape" : "" // Add the class 'card-finger-shape' conditionally for the job name element
              }`}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              <h6>
                                <custom>
                                  {" "}
                                  {job.jobTitle.length > 19
                                    ? `${job.jobTitle.substring(0, 20)}..`
                                    : job.jobTitle}
                                </custom>{" "}
                                ({job.jobId})
                              </h6>
                            </span>
                            <div className="d-flex">
                              <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                                <span className="font-regular font-primary font-gray1 mr-1">
                                  Placement Fee:
                                </span>
                                {job.isFeePercentage
                                  ? `${job.placementFee}%`
                                  : `${
                                      CurrencySymbols[job.placementCurrency]
                                        ? CurrencySymbols[job.placementCurrency]
                                        : job.placementCurrency
                                    }${Number(
                                      job.placementFee
                                    ).toLocaleString()}`}
                              </span>
                            </div>
                            {job?.jobDivaId && (
                              <div className="d-flex ml-1">
                                <span className="d-flex align-items-center tag tag-orange1 font-regular font-primary font-gray1 mb-2">
                                  <span className="font-regular font-primary font-gray1 mr-1">
                                    JobDiva ID:
                                  </span>
                                  <strong>{job?.jobDivaId}</strong>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="d-flex  align-items-center align-self-end justify-content-end mb-1">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ width: "1.5rem", borderRadius: "100" }}
                              checked={selectedJobs.some(
                                (c) => c.jobId == job?.jobId
                              )}
                              onClick={() => toggleSelectJob(job)}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {job.jobStatus === 13 && (
                            <span className="tag tag-green1 mr-3">Open</span>
                          )}
                          {job.jobStatus === 21 && (
                            <span className="tag tag-orange1 mr-3">Active</span>
                          )}
                          {job.jobStatus === 39 && (
                            <span className="tag tag-font_accent mr-3">
                              Halted
                            </span>
                          )}
                          {job.jobStatus === 23 && (
                            <span className="tag tag-font_accent mr-3">
                              Closed
                            </span>
                          )}
                          {job.isHotJob && (
                            <span className="tag tag-coral mr-2">HOT</span>
                          )}
                          {job.jobType && (
                            <span className="d-flex align-items-center me-3 w-10">
                              <i className="far fa-suitcase me-2 font-brandBlue"></i>
                              <small className="font-regular font-primary font-gray1">
                                {job.jobType}
                              </small>
                            </span>
                          )}
                          <span
                            className="d-flex align-items-center me-3"
                            // onClick={() => onJobClick(job)}
                          >
                            {job?.city &&
                            job?.state &&
                            job?.country &&
                            !job.isRemote ? (
                              <>
                                <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                <span className="font-regular font-primary font-gray1">
                                  {job.showLocation
                                    ? `${job?.city}, ${job?.state}, ${job?.country}`
                                    : job?.city + "..."}
                                </span>
                              </>
                            ) : (
                              <>
                                <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                <span className="font-regular font-primary font-gray1">
                                  Remote
                                </span>
                              </>
                            )}
                          </span>
                          {job?.visaStatus && (
                            <div
                              className="mt-1 d-block"
                              style={{ width: "35%" }}
                            >
                              <i class="far fa-plane-departure mr-1"></i>{" "}
                              {/* {JSON.parse(job?.visaStatus).map(
                                (item, index) =>
                                  (index < 1 || testId[i] == "visastatus") && (
                                    <span className="tag tag-blue3 mr-1 pt-1 px-2 mt-1">
                                      {item}
                                    </span>
                                  )
                              )} */}
                              {/* {job?.visaStatus &&
                                (testId[i] == "visastatus" ? (
                                  <div
                                    className="tag"
                                    onClick={() => removeItemFromArray(i)}
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    Show less
                                  </div>
                                ) : (
                                  JSON.parse(job?.visaStatus).length > 2 && (
                                    <div
                                      className="tag"
                                      onClick={() => {
                                        setTestId({
                                          ...testId,
                                          [i]: "visastatus",
                                        });
                                      }}
                                      style={{
                                        margin: "2px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      +{JSON.parse(job?.visaStatus).length - 2}{" "}
                                      more
                                    </div>
                                  )
                                ))} */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>

              {jobList.length > 5 && (
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={jobListCounts}
                  itemsPerPage={pageSize}
                  onPageChange={handlePageChange}
                  limit={5}
                />
              )}
            </div>
          )}
          {jobList.length === 0 && <NoRecord />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowJobModal(false)}
        >
          Cancel{" "}
        </button>
        <button disabled={selectedJobs.length === 0} onClick={handleInvite}>
          Invite
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobModal;
