import React from "react";
import Modal from "react-bootstrap/Modal";

const DeactivateModal = ({
  deactivate,
  setDeactivate,
  removeChecked,
  handleCheck,
  updateStatus,
}) => {
  return (
    <Modal show={deactivate}>
      <Modal.Header>
        <h6>Deactivate</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setDeactivate(false);
          }}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="bl-modal-body  text-center">
          <div className="avatar avatar-lg avatar-red2 mr-3">
            <i class="fas fa-exclamation-triangle fa-fw"></i>
          </div>
          <p className="text-center lead mt-3">
            You're about to deactivate this user, and all of its data.
          </p>
          <div className="col-lg-12 d-flex align-items-center  mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
            <div className="form-check form-switch d-flex align-items-center text-center">
              <label className="form-check-label pt-3" htmlFor="removeSwitch">
                <p>Do you want to remove this recruiter from the list?</p>
              </label>
              <input
                name="recruiterrEMOVE"
                className="form-check-input ml-3"
                type="checkbox"
                onChange={(e) => handleCheck(e)}
                checked={removeChecked === "Accepted" ? true : false}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="d-flex align-items-center justify-content-center m-3">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => {
            setDeactivate(false);
          }}
        >
          Cancel
        </button>

        <button
          className="btn btn-sm btn-primary ml-2"
          onClick={() => {
            updateStatus("Deactivate");
          }}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default DeactivateModal;
