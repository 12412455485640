import React, { useState } from "react";
import { toast } from "react-toastify";
import { currency_list } from "../../constants/DropDownData";

const PayRateFilter = ({
  handleFilterToggle,
  filterStatus,
  filterEnabled,
  filterName,
  selectedData,
  setSelectedData,
  applyFilter,
  isFilterApplied,
  setIsFilterApplied,
  min,
  setMin,
  setMax,
  max,
}) => {

  const initialPref = {
    hourly: false,
    annually: false,
    weekly: false,
  };

  const [preference, setPreference] = useState({
    hourly: true,
    annually: false,
    weekly: false,
  });
  const [currency, setCurrency] = useState("USD");

  const handlePreference = (e) => {
    let p = { ...initialPref, [e.target.name]: true };
    setSelectedData({
      ...selectedData,
      [filterStatus]: { payType: e.target.name, min: min, max: max },
    });
    setPreference(p);
  };

  const handlePayRate = () => {
    if (/^[0-9]+$/.test(min) && /^[0-9]+$/.test(max)) {
      if (+min <= +max) {
        function filterTrueKeys(obj) {
          return Object.keys(obj).filter((key) => obj[key] === true);
        }
        const result = filterTrueKeys(preference).join(", ");
        let obj = { payType: result, min: min, max: max, currency: currency };
        setSelectedData({
          ...selectedData,
          [filterStatus]: obj,
        });
        applyFilter(obj, filterStatus);
        setIsFilterApplied(true);
      } else {
        toast.error("Maximum salary should be greater than minimum salary");
      }
    } else {
      toast.error(
        "Please enter valid positive integers for minimum and maximum salary"
      );
    }
  };

  const handleClear = () => {
    setSelectedData({
      ...selectedData,
      [filterStatus]: {},
    });
    setMax("");
    setMin("");
    applyFilter({}, filterStatus);
    setIsFilterApplied(false);
  };

  const handleSalary = (e, type) => {
    const value = e.target.value;
    if (value > 9999999999) return toast.error(`${type} value is too large.`);
    if (/^[0-9]*$/.test(value)) {
      if (value === "" || value === "0" || (value[0] !== "0" && +value > 0)) {
        if (type === "min") {
          setMin(value);
          setSelectedData({
            ...selectedData,
            [filterStatus]: { ...selectedData[filterStatus], min: value },
          });
        } else if (type === "max") {
          setMax(value);
          setSelectedData({
            ...selectedData,
            [filterStatus]: { ...selectedData[filterStatus], max: value },
          });
        }
        return;
      }
    }
    toast.error("Please enter valid positive integers");
  };

  const handleCurrency = (e) => {
    setCurrency(e.target.value);
  };

  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-collapsible"
        onClick={() => handleFilterToggle(filterStatus)}
      >
        <i
          className={
            filterEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
          }
        />
        <span className="p-relative">
          <small>{filterName}</small>
          {Object.keys(selectedData?.payRate).length !== 0 &&
            isFilterApplied && <span className="jewel"></span>}
        </span>
      </button>
      {filterEnabled && (
        <div className=" d-flex flex-column gap-2  p-2">
          <div className="d-flex gap-2 ">
            <div>
              <input
                type="radio"
                style={{ width: "15px" }}
                className="d-inline-block mr-1"
                onClick={handlePreference}
                name="hourly"
                checked={preference.hourly}
              />
              <a
                href="#"
                style={{ color: "black" }}
                className="font-regular"
                onClick={handlePreference}
                name="hourly"
                for=""
              >
                Hourly
              </a>
            </div>{" "}
            <div>
              <input
                type="radio"
                style={{ width: "15px" }}
                className="d-inline-block mr-1"
                onClick={handlePreference}
                name="annually"
                checked={preference.annually}
              />
              <a
                href="#"
                style={{ color: "black" }}
                className="font-regular"
                onClick={handlePreference}
                name="annually"
                for=""
              >
                Annually
              </a>
            </div>
            <div>
              <input
                type="radio"
                style={{ width: "15px" }}
                className="d-inline-block mr-1"
                onClick={handlePreference}
                name="weekly"
                checked={preference.weekly}
              />
              <a
                href="#"
                style={{ color: "black" }}
                className="font-regular"
                onClick={handlePreference}
                name="weekly"
                for=""
              >
                Weekly
              </a>
            </div>
          </div>
          <div className="d-flex gap-2 ">
            <div className=" col-4">
              <input
                type="text" // Change to text type
                className="form-control"
                placeholder="Min"
                id="Minimum"
                onChange={(e) => handleSalary(e, "min")}
                value={min}
              />
            </div>
            <div className=" col-4">
              <input
                type="text" // Change to text type
                className="form-control"
                placeholder="Max"
                id="Maximum"
                onChange={(e) => handleSalary(e, "max")}
                value={max}
              />
            </div>

            <div class="col-4">
              <select
                class="form-select"
                id="preferredSalaryCurrency"
                onChange={handleCurrency}
                value={currency}
              >
                <option value="" selected disabled>
                  Select Type
                </option>
                {currency_list.map((item) => (
                  <option value={item.code}>{item.code}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <button
              className="btn btn-sm btn-secondary"
              onClick={handleClear}
              disabled={!(min && max)}
            >
              Clear
            </button>{" "}
            <button
              className="btn btn-sm btn-primary"
              onClick={handlePayRate}
              disabled={!(min && max)}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayRateFilter;
