import React from "react";
import Avatar from "react-avatar";
import moment from "moment";
import { getUserRole } from "../../../service/AuthService";

const SidePanelHeader = ({
  details,
  industries,
  prefix,
  status,
  isFrom,
  setApproveModal,
  setDelete,
  setDeactivate,
  setActivate,
  setReject,
}) => {
  const checkLastLogin = (lastLoginDate) => {
    const currentDate = new Date();
    const threeWeeksAgo = new Date();
    threeWeeksAgo.setDate(currentDate.getDate() - 21);

    const lastLogin = new Date(lastLoginDate);

    if (lastLogin >= threeWeeksAgo && lastLogin <= currentDate) {
      return "active";
    } else {
      return "inActive";
    }
  };

  return (
    <div class="px-3 mt-2 mb-3">
      <div class="d-flex align-items-start flex-wrap-reverse">
        <div class="d-flex">
          <Avatar
            size="40"
            name={details.firstName + " " + details.LastName}
            round="80px"
          />
          <div class="ml-3">
            <h5 class="mr-2 mt-2 mb-2">
              {details.firstName + " " + details.LastName}
            </h5>
            {industries.length !== 0 && (
              <>
                {" "}
                {isFrom == "LoginOverview" ? (
                  <div className="my-2">
                    {prefix === "Hyperactive" ? (
                      <span class="tag tag-green4">Hyperactive</span>
                    ) : prefix === "Active" ? (
                      <span class="tag tag-blue2">Active</span>
                    ) : prefix === "Inactive" ? (
                      <span class="tag tag-orange1">Inactive</span>
                    ) : prefix === "Unsubscribed" ? (
                      status === "Deactivate" ? (
                        <span class="tag tag-red2">Deactivated</span>
                      ) : (
                        <span class="tag tag-red2">Unsubscribed</span>
                      )
                    ) : prefix === "Others" ? (
                      <span class="tag tag-red2">Never Logged In</span>
                    ) : prefix === "Total" ? (
                      status === "Deactivate" ? (
                        <span class="tag tag-red2">Deactivated</span>
                      ) : status === "Invited" ? (
                        <span class="tag tag-blue3">Invited</span>
                      ) : status === "Pending" ? (
                        <span class="tag tag-orange1">Pending</span>
                      ) : status === "Approved" && details.LastLogin ? (
                        checkLastLogin(details.LastLogin) == "active" ? (
                          <span class="tag tag-blue2">Active</span>
                        ) : (
                          <span class="tag tag-orange1">Inactive</span>
                        )
                      ) : status === "Approved" ? (
                        <span class="tag tag-green4">Approved</span>
                      ) : (
                        <span class="tag">{status}</span>
                      )
                    ) : (
                      <span class="tag">{status}</span>
                    )}
                  </div>
                ) : isFrom == "HighAdmin" ? (
                  status === "Deactivate" ? (
                    <span className="tag tag-red2">Deactivated</span>
                  ) : status === "Invited" ? (
                    <span className="tag tag-blue3">Invited</span>
                  ) : status === "Pending" ? (
                    <span className="tag tag-orange1">Pending</span>
                  ) : status === "Approved" ? (
                    details.recruiterStatus == "Hyperactive" ? (
                      <span class="tag tag-green4">Hyperactive</span>
                    ) : details.recruiterStatus == "Active" ? (
                      <span class="tag tag-blue2">Active</span>
                    ) : details.recruiterStatus == "Inactive" ? (
                      <span class="tag tag-orange1">Inactive</span>
                    ) : details.recruiterStatus == "Never Logged In" ? (
                      <span class="tag tag-red2">Never Logged In</span>
                    ) : (
                      <span className="tag tag-green4">Approved</span>
                    )
                  ) : (
                    <span className="tag">{status}</span>
                  )
                ) : (
                  <div>
                    {status === "Deactivate" ? (
                      <span class="tag tag-red2">Deactivated</span>
                    ) : status === "Invited" ? (
                      <span class="tag tag-blue3">Invited</span>
                    ) : status === "Pending" ? (
                      <span class="tag tag-orange1">Pending</span>
                    ) : status === "Approved" && details.LastLogin ? (
                      checkLastLogin(details.LastLogin) == "active" ? (
                        <span class="tag tag-blue2">Active</span>
                      ) : (
                        <span class="tag tag-orange1">Inactive</span>
                      )
                    ) : status === "Approved" ? (
                      <span class="tag tag-green4">Approved</span>
                    ) : (
                      <span class="tag ">{status}</span>
                    )}
                  </div>
                )}
                <div>
                  {status === "Invited" && (
                    <span className="mt-2">
                      Invited on{" "}
                      {moment(details.createddatetime).format("MM/DD/YYYY")}
                    </span>
                  )}
                  {status === "Pending" && (
                    <span className="mt-2">
                      Form Filled on{" "}
                      {moment(details.updateddatetime).format("MM/DD/YYYY")}
                    </span>
                  )}
                  {status === "Rejected" && (
                    <span className="mt-2">
                      Rejected on{" "}
                      {moment(details.updateddatetime).format("MM/DD/YYYY")}
                    </span>
                  )}
                  {status === "Deactivate" && (
                    <span className="mt-2">
                      Deactivated on{" "}
                      {moment(details.updateddatetime).format("MM/DD/YYYY")}
                    </span>
                  )}
                  {status === "Approved" && details.LastLogin && (
                    <span className="mt-2">
                      Last logged in:{" "}
                      {moment(details.LastLogin).format("MM/DD/YYYY")}
                    </span>
                  )}{" "}
                </div>
              </>
            )}
          </div>
        </div>

        {getUserRole() === "CommunityManager" && (
          <div className="ml-auto">
            {status === "Pending" && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => setApproveModal(true)}
                >
                  Approve
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger ml-1"
                  onClick={() => setReject(true)}
                >
                  Reject
                </button>
              </div>
            )}
            {status === "Invited" && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => setDelete(true)}
                >
                  Delete Invitation
                </button>
              </div>
            )}
            {status === "Approved" && !details.isFromRegRec && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => setDeactivate(true)}
                >
                  Deactivate
                </button>
              </div>
            )}
            {status === "Deactivate" && (
              <div className="d-flex">
                <button
                  type="button"
                  className="btn btn-sm "
                  onClick={() => setActivate(true)}
                >
                  <i class="fa fa-key mr-2" aria-hidden="true"></i> Activate
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SidePanelHeader;
