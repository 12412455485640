import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NoRecord from "../../common/NoRecord";
import { CurrencySymbols } from "../../../constants/CurrencySymbols";
import StatusData from "./StatusData";
import Pagination from "../../common/pagination/Pagination";
import { getUserRole } from "../../../service/AuthService";
import { SidebarLogo } from "../../../constants/Constants";

const TableView = (props) => {
  const { currentPage } = props;
  const history = useHistory();
  let dataSize = props.parent == "HMdashboard" ? 4 : 10;
  const [testId, setTestId] = useState({});

  const removeItemFromArray = (item) => {
    const temp = { ...testId };
    delete temp[item];
    setTestId(temp);
  };

  useEffect(() => {
    setTestId({});
  }, [currentPage]);

  return (
    <div className="row">
      <div className="col">
        <table className="table table-sortable  table-hoverable">
          <thead>
            <tr>
              <th></th>
              <th>Job Title</th>
              <th>Job type </th>
              <th>Location </th>
              <th>Visa status </th>
              {getUserRole() != "Recruiter" && <th className="no-hover"></th>}
              {getUserRole() != "Recruiter" && <th>New Candidates </th>}
            </tr>
          </thead>
          <tbody>
            {props.jobList?.map((job, i) => (
              <tr>
                <td>
                  <div className="mr-3 m-1 ml-0">
                    <img
                      src={job.logourl || SidebarLogo}
                      width="75"
                      height="75"
                      className="p-2"
                      style={{
                        borderRadius: "50%",
                        objectFit: "contain",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div
                    onClick={() =>
                      history.push(`/high5Hire/requests/${job.jobId}`)
                    }
                    className="d-flex align-items-center pe-5"
                  >
                    <h6 className="mr-2">
                      {job.jobTitle} (Job Id: {job.jobId})
                    </h6>
                    {job.isHotJob && (
                      <span className="tag tag-coral mr-9">HOT</span>
                    )}
                  </div>
                  <div className="d-flex mt-1">
                    {job.jobStatus === 13 && (
                      <span className="tag tag-green1 mb-2">Open</span>
                    )}
                    {job.jobStatus === 21 && (
                      <span className="tag tag-orange1 mb-2">Active</span>
                    )}
                    {job.jobStatus === 39 && (
                      <span className="tag tag-font_accent mb-2">Halted</span>
                    )}
                    {job.jobStatus === 23 && (
                      <span className="tag tag-font_accent mb-2t">Closed</span>
                    )}
                    <div className="d-flex ml-1">
                      <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                        <span className="font-regular font-primary font-gray1 mr-1">
                          Placement Fee:
                        </span>
                        {"  "}{" "}
                        {job.isFeePercentage
                          ? `${job.placementFee}%`
                          : `${
                              CurrencySymbols[job.placementCurrency]
                                ? CurrencySymbols[job.placementCurrency]
                                : job.placementCurrency
                            }${Number(job.placementFee).toLocaleString()}`}
                        {/* </small> */}
                      </span>
                    </div>
                  </div>
                  {job?.jobDivaId && (
                    <div className="d-flex">
                      <span className="d-flex align-items-center tag tag-orange1 font-regular font-primary font-gray1 mb-2">
                        <span className="font-regular font-primary font-gray1 mr-1">
                          JobDiva Id:
                        </span>
                        {"  "} <strong>{job?.jobDivaId}</strong>
                      </span>
                    </div>
                  )}
                </td>
                <td>{job.jobType ? job.jobType : "NA"}</td>
                <td>
                  {job?.city && job?.state && job?.country && !job.isRemote ? (
                    <>
                      <span className="font-regular font-primary font-gray1">
                        {job?.city}, {job?.state},{job?.country}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="font-regular font-primary font-gray1">
                        Remote
                      </span>
                    </>
                  )}
                </td>
                <td>
                  {job?.visaStatus && (
                    <span className="d-flex align-items-center">
                      <i class="far fa-plane-departure mr-1"></i>{" "}
                      {JSON.parse(job?.visaStatus).map(
                        (item, index) =>
                          (index < 2 || testId[i] == "visastatus") && (
                            <span className="tag tag-blue3 mr-1 pt-1 px-2">
                              {item}
                            </span>
                          )
                      )}
                      {job?.visaStatus &&
                        (testId[i] == "visastatus" ? (
                          <div
                            className="tag"
                            onClick={() => removeItemFromArray(i)}
                            style={{ margin: "2px", cursor: "pointer" }}
                          >
                            more
                          </div>
                        ) : (
                          JSON.parse(job?.visaStatus).length > 2 && (
                            <div
                              className="tag"
                              onClick={() => {
                                setTestId({ ...testId, [i]: "visastatus" });
                              }}
                              style={{ margin: "2px", cursor: "pointer" }}
                            >
                              +{JSON.parse(job?.visaStatus).length - 2} Show
                              less
                            </div>
                          )
                        ))}
                    </span>
                  )}
                </td>
                {getUserRole() != "Recruiter" && (
                  <>
                    <td>
                      <StatusData job={job} />
                    </td>
                    {job.totalSubmisions === 0 ? (
                      <td className="p-0 bg-gray3 text-center">
                        No Candidate Available
                      </td>
                    ) : (
                      <td
                        className="p-0 bg-green_primary font-white text-center"
                        style={{ "background-color": "#3AB549" }}
                      >
                        {job.totalSubmisions === 1
                          ? `${job.totalSubmisions} New Candidate`
                          : `${job.totalSubmisions}  New Candidates`}
                      </td>
                    )}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {props.jobList.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={props.dataTotal}
            itemsPerPage={dataSize}
            onPageChange={props.handlePageChange}
            limit={dataSize}
          />
        )}
        {props.jobList.length === 0 && <NoRecord />}
      </div>
    </div>
  );
};

export default TableView;
