import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import GitHubLogin from "react-github-login";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { apiDomain } from "../../../../../constants/Constants";
import Loader from "../../../../loader/Loader";
import {
  githubClientId,
  githubClientSecret,
  linkedInClientId,
  linkedInClientSecret,
} from "../../../../../constants/Constants";

const SignUp = ({ setSocialMediaApply, setLoginData, isLoginModal }) => {
  const client_id = githubClientId;
  const client_secret = githubClientSecret;
  const redirect_uri = `${window.location.origin}/github`;

  const [loading, setLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const { access_token } = response;
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        sessionStorage.setItem("method", "google");
        sessionStorage.setItem("code", access_token);
        setLoginData({
          firstName: res?.data?.given_name,
          lastName: res?.data?.family_name,
          email: res.data?.email,
        });
        setSocialMediaApply(true);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const { linkedInLogin: LinkedinApply } = useLinkedIn({
    clientId: linkedInClientId,
    scope: "openid profile email",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      if (!isLoginModal) {
        let params = {
          grant_type: "authorization_code",
          code: code,
          client_id: linkedInClientId,
          client_secret: linkedInClientSecret,
          redirect_uri: `${window.location.origin}/linkedin`,
        };
        setLoading(true);
        axios
          .post(`${apiDomain}/linkedInLogin/linkedin`, params)
          .then((data) => {
            sessionStorage.setItem("method", "linkedin");
            sessionStorage.setItem("code", data.data.access_token);
            setLoginData({
              firstName: data?.data?.given_name,
              lastName: data?.data?.family_name,
              email: data?.data?.email,
            });
            setSocialMediaApply(true);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    },
    onError: (error) => {
      toast.error("Something went wrong. please try again later");
    },
  });

  const onSuccessGit = (response) => {
    const { code } = response;

    if (code) {
      exchangeCodeForToken(code);
    }
  };

  const onFailureGit = (err) => {
    toast.error("Something went wrong. please try again later");
  };

  const exchangeCodeForToken = async (code) => {
    try {
      setLoading(true);
      await axios
        .post(`${apiDomain}/githubLogin/github`, {
          client_id: client_id,
          client_secret: client_secret,
          code: code,
          redirect_uri: redirect_uri,
        })
        .then((response) => {
          const data = response.data;
          const accessToken = data.access_token;
          const email = data?.email;
          setLoading(false);
          if (email) {
            sessionStorage.setItem("method", "github");
            sessionStorage.setItem("code", accessToken);

            setLoginData({
              firstName: data.name || "",
              email: data?.email,
            });
            setSocialMediaApply(true);
          } else {
            toast.error("Please try other methods to apply");
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="main_signup_container">
      {loading && <Loader />}
      <div className="main_apply_container_apply main_apply_container_signup">
        <p style={{ fontWeight: "bold", fontSize: "10px" }}>
          Unlock future Possibilities - Sign up Now!
        </p>
        <Row className="d-flex justify-content-around w-100 mx-auto ">
          <Col className="text-center">
            <button className="btn btn-sm btn-secondary font-18 border-0">
              {" "}
              <FaLinkedin
                onClick={() => LinkedinApply()}
                className="pointer"
                size={20}
                style={{ color: "#0077b5" }}
              />
            </button>
          </Col>

          <Col className="text-center">
            <button className="btn btn-sm btn-secondary font-18  border-0">
              <img
                onClick={() => googleLogin()}
                className="pointer"
                src="https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg"
                alt="Gmail Icon"
                style={{ width: 20, height: 20 }}
              />
            </button>
          </Col>
          <Col className="text-center ">
            <GitHubLogin
              className="text-black btn btn-sm btn-secondary font-18 border-0"
              clientId={client_id}
              redirectUri={redirect_uri}
              onSuccess={onSuccessGit}
              onFailure={onFailureGit}
            >
              <FaGithub />
            </GitHubLogin>
          </Col>
        </Row>

        <hr className="line"></hr>
      </div>
    </div>
  );
};

export default SignUp;
