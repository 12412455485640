import Validator from "email-validator";

export const addClientValidation = (form, phoneValidation) => {
  const {
    lastname,
    email,
    name,
    tenant,
    tenanttype,
    zipCode,
    cityName,
    stateName,
    CountryName,
    phone,
  } = form;
  const newErrors = {};
  // name errors
  if (!tenant || tenant === "") newErrors.tenant = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(tenant) || /^\s*$/.test(tenant))
    newErrors.tenant = "Invalid Tenant Name ";
  if (!name || name === "") newErrors.name = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(name) || /^\s*$/.test(name))
    newErrors.name = "Invalid First Name ";
  if (!tenanttype || tenanttype === "")
    newErrors.tenanttype = "*Required Field";
  if (!phone || phone === "") newErrors.phone = "*Required Field";
  else if (!phoneValidation) newErrors.phone = "Invalid Phone number";
  if (!lastname || lastname === "") newErrors.lastname = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(lastname) || /^\s*$/.test(lastname))
    newErrors.lastname = "Invalid Last Name ";
  if (!zipCode || zipCode === "") newErrors.zipCode = "*Required Field";
  else if (!/^[0-9a-zA-Z ]+$/.test(zipCode) || /^\s*$/.test(zipCode))
    newErrors.zipCode = "Invalid ZipCode";
  if (!cityName || cityName === "") newErrors.cityName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(cityName) || /^\s*$/.test(cityName))
    newErrors.cityName = "Invalid City Name ";
  if (!stateName || stateName === "") newErrors.stateName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(stateName) || /^\s*$/.test(stateName))
    newErrors.stateName = "Invalid State Name ";
  if (!CountryName || CountryName === "")
    newErrors.CountryName = "*Required Field";
  else if (!/^[A-Z a-z\s]+$/.test(CountryName) || /^\s*$/.test(CountryName))
    newErrors.CountryName = "Invalid Country Name ";
  if (!email || email === "") newErrors.email = "*Required Field";
  else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";

  return newErrors;
};
