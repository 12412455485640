import React, { useState } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
const { Anime } = ReactAnime;

function FormSubmitSuccess(props) {
  const [loop] = useState(false);
  const closeMdal = () => {
    props.closeModal();
  };

  const showMoreSkills = async () => {
    props.successData?.primarySkills.slice(5, 7).map((item) => (
      <>
        <span class="tag tag-blue3 mb-1 mr-1">{item}</span>
      </>
    ));
  };

  return (
    <>
      <div class="section section-sm pb-0">
        <div class="success-animation">
          <div class="checkmark">
            <Lottie loop={loop} animationData={checkMark} play></Lottie>
          </div>
          <Anime
            initial={[
              {
                targets: "#LottieSparkles",
                opacity: 0,
                delay: 1000,
              },
            ]}
          >
            <div class="sparkles">
              <Lottie
                loop={loop}
                animationData={sparkles}
                id="LottieSparkles"
                play
              ></Lottie>
            </div>
          </Anime>
        </div>
        <Anime
          initial={[
            {
              targets: ".success-message",
              opacity: 1,
              delay: 800,
              easing: "linear",
            },
          ]}
        >
          <div class="success-message" style={{ opacity: "0" }}>
            <div class="container">
              <div class="col-lg-12">
                <>
                  <h4 class="text-center">{props.successData.title}</h4>
                  <div
                    class="text-center"
                    dangerouslySetInnerHTML={{
                      __html: props.successData.subTitle,
                    }}
                  ></div>
                  <div style={{ padding: "10px" }}></div>
                </>
                <div className="card card-flat card-lg">
                  <div className="card-body">
                    <div className="mb-2">
                      <label className="d-block font-bold pt-0">
                        Name of the talent{" "}
                      </label>
                      {props.successData.name}
                    </div>
                    <div className="mb-2">
                      <label className="d-block font-bold pt-0">
                        Experience{" "}
                      </label>
                      {props.successData.experienceYear
                        ? props.successData.experienceYear
                        : "0"}
                      {"."}
                      {props.successData.experienceMonth !== 0
                        ? props.successData.experienceMonth
                        : "0"}
                      {" Years"}
                    </div>
                    <div className="mb-2">
                      <label className="d-block font-bold pt-0">
                        Preferred location{" "}
                      </label>
                      {props.successData?.preferredLocation
                        .slice(0, 4)
                        .map((item, id) => {
                          if (
                            id <
                            props.successData.preferredLocation.length - 1
                          ) {
                            return `${item}, `;
                          } else {
                            return item;
                          }
                        })}
                    </div>
                    {props?.successData?.primarySkills && (
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          Primary skills{" "}
                        </label>
                        <div className="mt-1">
                          {props.successData?.primarySkills
                            .slice(0, 4)
                            .map((item) => (
                              <>
                                <span class="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              </>
                            ))}
                          <span>...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div class="text-center py-4">
                  <button
                    type="button"
                    class="btn btn-secondary mr-2"
                    data-dismiss="modal"
                    onClick={closeMdal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Anime>
      </div>
    </>
  );
}
export default FormSubmitSuccess;
