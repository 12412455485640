import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Pagination from "../../common/pagination/Pagination";
import {
  getCandidateName,
  getUserId,
  getUserName,
  getUserRole,
} from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
import Loader from "../../loader/Loader";
import RecSidePanel from "../../recruiter/sidepanel/Index";
import ProfileImage from "../../common/profileimage/ProfileImage";
// import Sort from "../../../commoncomponents/sort/Index";
// import { recruiterListObj, orderList } from "../../../pagedata/PageData";
import Filter from "./filter/Index";

const RecruiterOverview = ({
  userData,
  isFrom,
  prefix,
  getRecruiters,
  handlePageChange,
  currentPage,
  dataTotal,
  dataSize,
  handleSizeChange,
  handleSearch,
  searchData,
  fetchRecruiterData,
  sortArray,
  setSortArray,
  applySort,
  setSelectedData,
  selectedData,
  applyFilter,
  openTenantPopUP,
  setopenTenantPopUP,
}) => {
  const [loading, setLoading] = useState(false);

  const [tenantObj, settenantObj] = useState({});

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  //   useEffect(() => {
  //     const tempArray = recruiterListObj.map((item) => item.value);
  //     setSorttype(tempArray);
  //   }, []);

  useEffect(() => {
    setopenTenantPopUP(false);
    // resetSort();
  }, [prefix]);

  const login = async (selectedUser) => {
    const payload = {
      userName: selectedUser.email,
      //   domain: domain,
      role: getUserRole(),
      actualUserName: getUserName(),
      candidateName: getCandidateName(),
      userId: getUserId(),
    };
    try {
      setLoading(true);
      let res = await post("/login/dashboardlogin", payload);
      if (res.status === 200) {
        localStorage.setItem("userToken", JSON.stringify(res.data));
        window.location.reload(true);
      }
      setLoading(false);
    } catch {
      toast.error("Error!!");
      setLoading(false);
    }
  };

  const openTenantPopUPFun = (item) => {
    setopenTenantPopUP(true);
    settenantObj(item);
  };

  //   const resetSort = () => {
  //     setSortArray([]);
  //     const tempArray = recruiterListObj?.map((item) => item.value);
  //     setSorttype(tempArray);
  //     setSortCount(0);
  //     setSortApplied(false);
  //     setSort(false);
  //   };

  //   const sortClearAll = () => {
  //     resetSort();
  //     if (isFrom === "Bar" || isFrom === "clients") {
  //       fetchRecruiterData(1, dataSize, searchData, []);
  //     } else {
  //       fetchRecruiterData(prefix, 1, dataSize, searchData, []);
  //     }
  //   };

  return (
    <div className="card mt-3">
      {loading && <Loader />}
      <div className="card-body">
        <div class=" p-3 ">
          <div class="mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{prefix ? prefix : "Gig Recruiters"}</h6>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap d-block-768">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  value={dataSize}
                  onChange={handleSizeChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <div class="mb-2 mr-1 d-flex align-items-center justify-content-end col-md-8">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Recruiters"
                      className="form-control small font-14"
                      value={searchData}
                      onChange={handleSearch}
                    />
                  </div>
                  {/* <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={userData}
                    orderArray={orderList}
                    sortObjects={recruiterListObj}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Name, Email, and Created On"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <Filter
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                applyFilter={applyFilter}
                closePanel={() => setopenTenantPopUP(false)}
              />
            </div>
            <div className="col-8">
              <div class="pt-0 mb-3 overflow-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Status</th>
                      {/* <th scope="col" style={{ width: 100 }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.length > 0 &&
                      userData?.map((item) => (
                        <tr>
                          <td
                            onClick={() => openTenantPopUPFun(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <div class="avatar-name-candidatestableview d-flex align-items-center">
                              <div>
                                <span>
                                  <ProfileImage
                                    imageSrc={item?.profileImagePath}
                                    imageWidth={"35"}
                                    imageHeight={"35"}
                                    avatarSize={"35"}
                                    avatarRound={"80px"}
                                    firstName={item?.firstName}
                                    lastName={item?.LastName}
                                  />
                                </span>
                              </div>
                              <div class="ml-3">
                                <strong class="mb-0 me-2">
                                  {item.firstName} {item.LastName}
                                </strong>
                              </div>
                            </div>
                          </td>
                          <td>{item.email}</td>
                          <td>
                            {item.status === "Deactivate" ? (
                              <span className="tag tag-red2">Deactivated</span>
                            ) : item.status === "Invited" ? (
                              <span className="tag tag-blue3">Invited</span>
                            ) : item.status === "Pending" ? (
                              <span className="tag tag-orange1">Pending</span>
                            ) : item.status === "Approved" ? (
                              item.recruiterStatus == "Hyperactive" ? (
                                <span class="tag tag-green4">Hyperactive</span>
                              ) : item.recruiterStatus == "Active" ? (
                                <span class="tag tag-blue2">Active</span>
                              ) : item.recruiterStatus == "Inactive" ? (
                                <span class="tag tag-orange1">Inactive</span>
                              ) : item.recruiterStatus == "Never Logged In" ? (
                                <span class="tag tag-red2">
                                  Never Logged In
                                </span>
                              ) : (
                                <span className="tag tag-green4">Approved</span>
                              )
                            ) : (
                              <span className="tag">{item.status}</span>
                            )}
                          </td>
                          {getUserRole() === "CommunityManager" && (
                            <td>
                              <button
                                onClick={() => login(item)}
                                disabled={item.status !== "Approved"}
                                className="btn btn-sm "
                              >
                                Login
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {(!userData || userData?.length === 0) && (
                  <div>
                    <div class="text-center p-3 mt-5 mb-5">
                      <div class="avatar avatar-lg">
                        <i aria-hidden="true" class="fad fa-folder-open"></i>
                      </div>{" "}
                      <div class="mt-6">No records found</div>
                    </div>
                  </div>
                )}
                <hr class="m-0" />
                {userData?.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalItems={dataTotal}
                    itemsPerPage={dataSize}
                    onPageChange={handlePageChange}
                    limit={10}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openTenantPopUP && (
        <RecSidePanel
          details={tenantObj}
          closePanel={() => setopenTenantPopUP(false)}
          prefix={prefix}
          isFrom={isFrom}
        />
      )}
    </div>
  );
};

export default RecruiterOverview;
