import {
    GET_DISPOSITION_COUNT_BEGIN,
    GET_DISPOSITION_COUNT_SUCCESS,
    GET_DISPOSITION_COUNT_FAILURE,
  } from "../../../action/disposition/talentcommunity/CommunityDispositionCount";
  
  const initialState = {
    dispositionCountLoading: true,
    dispositionCount: [],
  };
  
  const communityDispositionCountReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_DISPOSITION_COUNT_BEGIN:
        return {
          ...state,
          dispositionCountLoading: true,
          dispositionCount: [],
        };
      case GET_DISPOSITION_COUNT_SUCCESS:
        return {
          ...state,
          dispositionCount: payload,
          dispositionCountLoading: false,
        };
      case GET_DISPOSITION_COUNT_FAILURE:
        return {
          ...state,
          dispositionCount: [],
          dispositionCountLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default communityDispositionCountReducer;
  