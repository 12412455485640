import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_CURATOR_INVITE_DETAILS_BEGIN =
  "GET_CURATOR_INVITE_DETAILS_BEGIN";
export const GET_CURATOR_INVITE_DETAILS_SUCCESS =
  "GET_CURATOR_INVITE_DETAILS_SUCCESS";
export const GET_CURATOR_INVITE_DETAILS_FAILURE =
  "GET_CURATOR_INVITE_DETAILS_FAILURE";

  export const getCuratorInviteDetails = (data) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CURATOR_INVITE_DETAILS_BEGIN,
      });
      const res = await post("/curator/curatorinvitedetails", data);
      if (res.status === 200) {
        dispatch({
          type: GET_CURATOR_INVITE_DETAILS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CURATOR_INVITE_DETAILS_FAILURE,
        payload: error?.response?.data,
      });
      toast.error(error?.response?.data?.errMessage);
    }
  };

export const GET_CURATOR_INVITE_DATA_BEGIN = "GET_CURATOR_INVITE_DATA_BEGIN";
export const GET_CURATOR_INVITE_DATA_SUCCESS = "GET_CURATOR_INVITE_DATA_SUCCESS";
export const GET_CURATOR_INVITE_DATA_FAILURE = "GET_CURATOR_INVITE_DATA_FAILURE";

  export const getCuratorInviteStatus = (data) => async (dispatch) => {
    try {
      dispatch({
        type: GET_CURATOR_INVITE_DATA_BEGIN,
      });
      const res = await post("/curator/curatorinvitestatus", data);
      if (res.status === 200) {
        dispatch({
          type: GET_CURATOR_INVITE_DATA_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CURATOR_INVITE_DATA_FAILURE,
        payload: error?.response?.data,
      });
      toast.error(error?.response?.data?.errMessage);
    }
  };