import React from "react";
import PhoneInput from "react-phone-input-2";
import { Row, Col } from "react-bootstrap";
import { AsYouType } from "libphonenumber-js";

const ContactInfo = ({
  data,
  setData,
  validation,
  setValidation,
  handleChange,
  phoneValidation,
  setPhoneValidation,
}) => {
  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    if (validation.phone && isValid) {
      setValidation({ ...validation, phone: "" });
    }
    setPhoneValidation(isValid);
  };
  return (
    <>
      <Row>
        <Col>
          <div className="form-floating">
            <input
              type="text"
              className={
                validation.firstName
                  ? "form-control border border-danger"
                  : "form-control"
              }
              id="firstName"
              placeholder="First Name*"
              value={data.firstName}
              required
              onChange={handleChange}
            />
            <label>First Name*</label>
          </div>
          {validation.firstName && (
            <small className="validation ml-2">{validation.firstName}</small>
          )}
        </Col>
        <Col>
          <div className="form-floating">
            <input
              type="text"
              className={
                validation.lastName
                  ? "form-control border border-danger"
                  : "form-control"
              }
              id="lastName"
              placeholder="Last Name*"
              value={data.lastName}
              onChange={handleChange}
              required
            />
            <label>Last Name*</label>
          </div>
          {validation.lastName && (
            <small className="validation ml-2">{validation.lastName}</small>
          )}
        </Col>
        <Col>
          <div className="form-floating ">
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="Email*"
              value={data.email}
              disabled
            />
            <label>Email*</label>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="">
          {" "}
          <label>Phone*</label>
          <div className="form-floating" style={{ zIndex: "999" }}>
            <PhoneInput
              value={data.phone}
              name="mobilePhone"
              country={"us"}
              onChange={(value, country, e) => {
                setData({
                  ...data,
                  phone: value,
                  phoneCode: country.dialCode,
                  country: country?.name,
                });
                _onChangeText(value, country);
              }}
              placeholder="Phone Number"
              inputClass={
                validation.phone
                  ? "form-control border border-danger w-100"
                  : "form-control w-100"
              }
              enableSearch={true}
            />
          </div>
          {validation.phone ? (
            <small className="validation ml-2">{validation.phone}</small>
          ) : !phoneValidation ? (
            <small className="validation">Invalid Phone</small>
          ) : (
            <></>
          )}
        </div>
      </Row>
    </>
  );
};

export default ContactInfo;
