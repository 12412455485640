import Validator from "email-validator";
import { isValidUrl, isValidFname } from "../functions/CommonFunction";

export const publicRegistrationValidation = (data, phoneValidation) => {
  let validationObj = {
    companyName: "",
    firstName: "",
    lastName: "",
    title: "",
    experience: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    linkedIn: "",
    recEmail: "",
    recPhoneNumber: "",
    recFirstName: "",
    recLastName: "",
    education: "",
    certification: "",
  };
  let errorMsg = [];
  if (data.firstName.trim() === "") {
    errorMsg.push("Please Enter First Name");
    validationObj = { ...validationObj, firstName: "*Required Field" };
  } else if (data.firstName && !isValidFname(data.firstName)) {
    errorMsg.push("Please Enter Valid Name");
    validationObj = { ...validationObj, firstName: "*Invalid First Name" };
  }

  if (data.lastName.trim() === "") {
    errorMsg.push("Please Enter Last Name");
    validationObj = { ...validationObj, lastName: "*Required Field" };
  } else if (data.lastName && !isValidFname(data.lastName)) {
    errorMsg.push("Please Enter Valid Name");
    validationObj = { ...validationObj, lastName: "*Invalid Last Name" };
  }
  if (data.email === "") {
    errorMsg.push("Please Enter Email");
    validationObj = { ...validationObj, email: "*Required Field" };
  }
  if (data.email && Validator.validate(data.email) === false) {
    errorMsg.push("Please Enter Valid Email");
    validationObj = { ...validationObj, email: "*Invalid Email" };
  }
  if (!data.phone) {
    errorMsg.push("Please Enter Phone No");
    validationObj = { ...validationObj, phone: "*Required Field" };
  }
  if (!phoneValidation) {
    errorMsg.push("Please Enter Valid Phone No");
    validationObj = { ...validationObj, phone: "*Invalid Phone" };
  }
  if (data.linkedIn && !isValidUrl(data.linkedIn)) {
    errorMsg.push("Please Enter Valid LinkedIn URL");
    validationObj = { ...validationObj, linkedIn: "*Invalid URL" };
  }
  if (!data.location.city || data.location.city.trim() === "") {
    errorMsg.push("Please Enter City");
    validationObj = { ...validationObj, city: "*Required Field" };
  }
  if (!data.location.state || data.location.state.trim() === "") {
    errorMsg.push("Please Enter State");
    validationObj = { ...validationObj, state: "*Required Field" };
  }
  if (!data.location.country || data.location.country.trim() === "") {
    errorMsg.push("Please Enter Country");
    validationObj = { ...validationObj, country: "*Required Field" };
  }
  if (data.yearsOfExperience === "") {
    errorMsg.push("Please Enter Years Of Experience");
    validationObj = { ...validationObj, experience: "*Required Field" };
  }
  if (data.education.length === 0) {
    errorMsg.push("Please enter Education");
    validationObj = { ...validationObj, education: "*Required Field" };
  }
  if (data.certification.length === 0) {
    errorMsg.push("Please enter Certifications");
    validationObj = { ...validationObj, certification: "*Required Field" };
  }
  if (data.industries.length === 0) {
    errorMsg.push("Please enter in which Industry do you recruit");
    validationObj = { ...validationObj, industry: "*Required Field" };
  }
  if (data.subCategory.length === 0) {
    errorMsg.push("Please enter in which category do you recruit");
    validationObj = { ...validationObj, subCategory: "*Required Field" };
  }
  if (data.skills.length === 0) {
    errorMsg.push("Please enter types of skills ");
    validationObj = { ...validationObj, skill: "*Required Field" };
  }
  if (data.regions.length === 0) {
    errorMsg.push("Please enter regions ");
    validationObj = { ...validationObj, region: "*Required Field" };
  }
  if (data.languages.length === 0) {
    errorMsg.push("Please enter languages");
    validationObj = { ...validationObj, language: "*Required Field" };
  }
  if (data.jobTitle.title1.trim() === "") {
    errorMsg.push("Please Enter Job Title 1");
    validationObj = { ...validationObj, title: "*Required Field" };
  }

  if (data.email.toLocaleLowerCase() == data.recEmail.toLocaleLowerCase()) {
    errorMsg.push("Please Enter Another Email");
    validationObj = {
      ...validationObj,
      recEmail: "*Same Email Can not Be Added",
    };
  }
  if (data.recEmail && Validator.validate(data.recEmail) === false) {
    errorMsg.push("Please Enter Valid Email");
    validationObj = { ...validationObj, recEmail: "*Invalid Email" };
  }

  return { validationObj, errorMsg };
};

export const inviteRegistrationValidation = (data, phoneValidation) => {
  let validationObj = {
    companyName: "",
    firstName: "",
    lastName: "",
    title: "",
    experience: "",
    phone: "",
    linkedIn: "",
    recEmail: "",
    recPhoneNumber: "",
    recFirstName: "",
    recLastName: "",
    education: "",
    certification: "",
  };
  let errorMsg = [];
  if (data.firstName.trim() === "") {
    errorMsg.push("Please Enter First Name");
    validationObj = { ...validationObj, firstName: "*Required Field" };
  } else if (data.firstName && !isValidFname(data.firstName)) {
    errorMsg.push("Please Enter Valid Name");
    validationObj = { ...validationObj, firstName: "*Invalid First Name" };
  }
  if (data.lastName.trim() === "") {
    errorMsg.push("Please Enter Last Name");
    validationObj = { ...validationObj, lastName: "*Required Field" };
  } else if (data.lastName && !isValidFname(data.lastName)) {
    errorMsg.push("Please Enter Valid Name");
    validationObj = { ...validationObj, lastName: "*Invalid Last Name" };
  }
  if (!data.phone) {
    errorMsg.push("Please Enter Phone No");
    validationObj = { ...validationObj, phone: "*Required Field" };
  }
  if (!phoneValidation) {
    errorMsg.push("Please Enter Valid Phone No");
    validationObj = { ...validationObj, phone: "*Invalid Phone" };
  }
  if (!data.location.city || data.location.city.trim() === "") {
    errorMsg.push("Please Enter City");
    validationObj = { ...validationObj, city: "*Required Field" };
  }
  if (!data.location.state || data.location.state.trim() === "") {
    errorMsg.push("Please Enter State");
    validationObj = { ...validationObj, state: "*Required Field" };
  }
  if (!data.location.country || data.location.country.trim() === "") {
    errorMsg.push("Please Enter Country");
    validationObj = { ...validationObj, country: "*Required Field" };
  }
  if (data.linkedIn && !isValidUrl(data.linkedIn)) {
    errorMsg.push("Please Enter Valid LinkedIn URL");
    validationObj = { ...validationObj, linkedIn: "*Invalid URL" };
  }

  if (data.recEmail && Validator.validate(data.recEmail) === false) {
    errorMsg.push("Please Enter Valid Email");
    validationObj = { ...validationObj, recEmail: "*Invalid Email" };
  }
  if (data.yearsOfExperience === "") {
    errorMsg.push("Please enter Years Of Experience");
    validationObj = { ...validationObj, experience: "*Required Field" };
  }
  if (data.education.length === 0) {
    errorMsg.push("Please enter Education");
    validationObj = { ...validationObj, education: "*Required Field" };
  }
  if (data.certification.length === 0) {
    errorMsg.push("Please enter Certifications");
    validationObj = { ...validationObj, certification: "*Required Field" };
  }
  if (data.industries.length === 0) {
    errorMsg.push("Please enter in which Industry do you recruit");
    validationObj = { ...validationObj, industry: "*Required Field" };
  }
  if (data.subCategory.length === 0) {
    errorMsg.push("Please enter in which category do you recruit");
    validationObj = { ...validationObj, subCategory: "*Required Field" };
  }
  if (data.skills.length === 0) {
    errorMsg.push("Please enter types of skills ");
    validationObj = { ...validationObj, skill: "*Required Field" };
  }
  if (data.regions.length === 0) {
    errorMsg.push("Please enter regions ");
    validationObj = { ...validationObj, region: "*Required Field" };
  }
  if (data.languages.length === 0) {
    errorMsg.push("Please enter languages");
    validationObj = { ...validationObj, language: "*Required Field" };
  }
  if (data.jobTitle.title1.trim() === "") {
    errorMsg.push("Please Enter Job Title 1");
    validationObj = { ...validationObj, title: "*Required Field" };
  }

  return { validationObj, errorMsg };
};
