import {
  GET_SMS_DATA_BEGIN,
  GET_SMS_DATA_SUCCESS,
  GET_SMS_DATA_FAILURE,
  GET_SMS_CONTENT_BEGIN,
  GET_SMS_CONTENT_SUCCESS,
  GET_SMS_CONTENT_FAILURE,
  UPDATE_SMS_DATA_BEGIN,
  UPDATE_SMS_DATA_SUCCESS,
  UPDATE_SMS_DATA_FAILURE,
} from "../../action/adminconsole/SMSTemplate";

const initialState = {
  SMSLoading: true,
  SMSContentLoading: true,
  SMSData: [],
  SMSUpdated: false,
  SMSContent: [],
};

const smsTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SMS_DATA_BEGIN:
      return {
        ...state,
        SMSLoading: true,
        SMSData: [],
      };
    case GET_SMS_DATA_SUCCESS:
      return {
        ...state,
        SMSData: payload,
        SMSLoading: false,
      };
    case GET_SMS_DATA_FAILURE:
      return {
        ...state,
        SMSData: [],
        SMSLoading: false,
      };
    case GET_SMS_CONTENT_BEGIN:
      return {
        ...state,
        SMSLoading: true,
        SMSContentContent: [],
      };
    case GET_SMS_CONTENT_SUCCESS:
      return {
        ...state,
        SMSContent: payload,
        SMSContentLoading: false,
      };
    case GET_SMS_CONTENT_FAILURE:
      return {
        ...state,
        SMSContent: [],
        SMSContentLoading: false,
      };
    case UPDATE_SMS_DATA_BEGIN:
      return {
        ...state,
        SMSLoading: true,
        SMSUpdated: false,
      };
    case UPDATE_SMS_DATA_SUCCESS:
      return {
        ...state,
        SMSUpdated: true,
        SMSLoading: false,
      };
    case UPDATE_SMS_DATA_FAILURE:
      return {
        ...state,
        SMSUpdated: false,
        SMSLoading: false,
      };
    default:
      return state;
  }
};

export default smsTemplateReducer;
