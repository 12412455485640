import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import Loader from "../../../loader/Loader";
import { skillOptions } from "../../../../constants/Constants";
import Validator from "email-validator";
import { getDropdownValues } from "../../../../action/common/Dropdown";
import GoogleLocationAPI from "../../../location/GoogleLocationAPI";
import ContactInfo from "./ContactInfo";
import FormHeader from "./FormHeader";
import RecruiterInfo from "./RecruiterInfo";
import ProfileInfo from "./ProfileInfo";
import FormFooter from "./FormFooter";
import Success from "./Success";
import { publicRegistrationValidation } from "../../../validation/RecruiterRegistration";

const PublicRecruiterRegistartion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchInput = useRef(null);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [data, setData] = useState({
    phoneCode: "",
    yearsOfExperience: "",
    isreferred: "",
    jobTitle: { title1: "", title2: "", title3: "" },
    skills: [],
    firstName: "",
    lastName: "",
    email: "",
    linkedIn: "",
    phone: "",
    industries: [],
    subCategory: [],
    regions: [],
    languages: [],
    companyName: "",
    recFirstName: "",
    recLastName: "",
    recEmail: "",
    recPhoneCode: "",
    recPhoneNumber: "",
    country: "",
    recCountry: "",
    location: {
      city: "",
      state: "",
      country: "",
    },
    education: [],
    certification: [],
  });
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [agree, setAgree] = useState(true);
  const [validation, setValidation] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    title: "",
    experience: "",
    phone: "",
    linkedIn: "",
    recEmail: "",
    recPhoneNumber: "",
    recFirstName: "",
    recLastName: "",
    city: "",
    state: "",
    country: "",
    experience: "",
    industry: "",
    subCategory: "",
    skill: "",
    region: "",
    language: "",
    education: "",
    certification: "",
  });
  const [success, setSuccess] = useState(false);
  const [skillsOptions, setSkillsOption] = useState(skillOptions);
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [industriesOptionsAll, setIndustriesOptionsAll] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [educationsOptions, setEducationsOption] = useState([]);
  const [certificationOptions, setCertificationOptions] = useState([]);

  const { dropdownLoading, dropdownData } = useSelector(
    (state) => ({
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDropdownValues());
  }, []);

  useEffect(() => {
    if (dropdownData.length > 0) {
      let industriesData = [];
      let regionData = [];
      let languageData = [];
      let experienceData = [];
      let educationData = [];
      let certificationData = [];
      dropdownData.forEach((element) => {
        if (element.typeName == "skillSet") {
          industriesData = [
            ...industriesData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "certification") {
          certificationData = [
            ...certificationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "languages") {
          languageData = [
            ...languageData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "regions") {
          regionData = [
            ...regionData,
            {
              value: element.name,
              label: element.name,
              disabled: false,
            },
          ];
        }
        if (element.typeName == "experienceYear") {
          experienceData = [
            ...experienceData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "qualification") {
          educationData = [
            ...educationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "certifications") {
          certificationData = [
            ...certificationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
      });

      setIndustriesOptionsAll([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setIndustriesOptions([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setLanguageOptions(languageData);
      setRegionOption(regionData);
      setExperienceOptions(experienceData);
      setEducationsOption(educationData);
      setCertificationOptions(certificationData);
    }
  }, [dropdownData]);

  const handleChange = (e) => {
    let id = e.target.id;
    if (id === "firstName" && validation.firstName) {
      setValidation({ ...validation, firstName: "" });
    }
    if (id === "lastName" && validation.lastName) {
      setValidation({ ...validation, lastName: "" });
    }
    if (id === "experience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "education" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skill" && validation.skill) {
      setValidation({ ...validation, skill: "" });
    }
    if (id === "region" && validation.region) {
      setValidation({ ...validation, region: "" });
    }
    if (id === "language" && validation.language) {
      setValidation({ ...validation, language: "" });
    }
    if (id === "email") {
      if (validation.email === "*Required Field") {
        setValidation({ ...validation, email: "" });
      }
      if (validation.email === "*Invalid Email") {
        Validator.validate(e.target.value) &&
          setValidation({ ...validation, email: "" });
      }
    }
    if (id === "recEmail") {
      if (validation.recEmail === "*Same Email Can not Be Added") {
        setValidation({ ...validation, recEmail: "" });
      }
      if (validation.recEmail === "*Invalid Email") {
        Validator.validate(e.target.value) &&
          setValidation({ ...validation, recEmail: "" });
      }
    }
    setData({ ...data, [id]: e.target.value });
  };

  const handleLocation = (e) => {
    let id = e.target.id;
    setData({
      ...data,
      location: {
        ...data.location,
        [id]: e.target.value,
      },
    });
  };

  const submit = async () => {
    let validation = publicRegistrationValidation(data, phoneValidation);
    if (validation?.errorMsg.length > 0) {
      if (validation?.errorMsg.length > 1) {
        toast.error("Please fill all the mandatory fields");
      } else {
        toast.error(validation?.errorMsg[0]);
      }
      setValidation(validation?.validationObj);
    } else {
      setValidation({
        companyName: "",
        firstName: "",
        lastName: "",
        title: "",
        experience: "",
        phone: "",
        linkedIn: "",
        recEmail: "",
        recPhoneNumber: "",
        recFirstName: "",
        recLastName: "",
        city: "",
        state: "",
        country: "",
        education: "",
        certification: "",
      });
      try {
        setLoading(true);
        let userID = window.location.pathname.split("/").reverse()[0];
        const res = await post("/recruiter/registerpublic", {
          ...data,
          companyName: "",
          createdby: userID,
        });
        if (res.data.ReturnText === "Registered Successfully") {
          setSuccess(true);
          setTimeout(() => {
            history.push("/login");
          }, 5000);
        } else if (res.data.ReturnText === "Email ID Already Exists") {
          setValidation({ ...validation, email: "This Email Already Exists" });
          toast.error("Email ID Already Exists. Please Try Another Email");
        } else {
          setValidation({
            ...validation,
            recEmail: "This Email Already Exists",
          });
          toast.error(
            "Recruiter Email ID Already Exists. Please Try Another Email"
          );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Some Error Occurred while Saving Data. Please Try Again");
      }
    }
  };

  const setLocationRegion = (address, searchInput) => {
    const city = address.locality || address.city;
    setData({
      ...data,
      location: {
        ...data.location,
        city: city || "",
        state: address?.state || "",
        country: address?.country || "",
      },
    });
  };

  return (
    <>
      {!success ? (
        <>
          {(loading || dropdownLoading || categoryLoading) && <Loader />}
          <FormHeader />
          <div className="form-floating my-2">
            <Container fluid>
              <ContactInfo
                data={data}
                setData={setData}
                validation={validation}
                setValidation={setValidation}
                handleChange={handleChange}
                phoneValidation={phoneValidation}
                setPhoneValidation={setPhoneValidation}
              />
              <Row>
                <div className="mt-4">
                  <GoogleLocationAPI
                    setLocation={setLocationRegion}
                    searchInput={searchInput}
                  />
                </div>
              </Row>
              <ProfileInfo
                data={data}
                setData={setData}
                validation={validation}
                setValidation={setValidation}
                handleChange={handleChange}
                handleLocation={handleLocation}
                educationsOptions={educationsOptions}
                certificationOptions={certificationOptions}
              />
              <RecruiterInfo
                data={data}
                setData={setData}
                validation={validation}
                setValidation={setValidation}
                regionOption={regionOption}
                setRegionOption={setRegionOption}
                languageOptions={languageOptions}
                skillOptions={skillOptions}
                setSkillsOption={setSkillsOption}
                experienceOptions={experienceOptions}
                industriesOptions={industriesOptions}
                setIndustriesOptions={setIndustriesOptions}
                industriesOptionsAll={industriesOptionsAll}
                skillsOptions={skillsOptions}
                dropdownData={dropdownData}
                setCategoryLoading={setCategoryLoading}
              />
              <FormFooter
                check={check}
                setCheck={setCheck}
                handleChange={handleChange}
                agree={agree}
                setAgree={setAgree}
              />
              <Row>
                <Col className="mt-5 mb-5">
                  <button
                    type="button"
                    className="btn btn-danger mt-50"
                    style={{ backgroundColor: "#ff8f73" }}
                    onClick={submit}
                    disabled={!agree}
                  >
                    Send Now
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <Success />
      )}
    </>
  );
};

export default PublicRecruiterRegistartion;
