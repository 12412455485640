import {
  GET_RECRUITER_FOR_CURATOR_BEGIN,
  GET_RECRUITER_FOR_CURATOR_SUCCESS,
  GET_RECRUITER_FOR_CURATOR_FAILURE,
  GET_RECRUITER_FOR_CURATOR_COUNT_BEGIN,
  GET_RECRUITER_FOR_CURATOR_COUNT_SUCCESS,
  GET_RECRUITER_FOR_CURATOR_COUNT_FAILURE,
  GET_CLIENT_TALENT_COMMUNITY_BEGIN,
  GET_CLIENT_TALENT_COMMUNITY_SUCCESS,
  GET_CLIENT_TALENT_COMMUNITY_FAILURE,
} from "../../action/curator/AssignCurator";

const initialState = {
  loading: false,
  countLoading: false,
  communityLoading: false,
  communityData: [],
  recruiterData: [],
  recruiterCount: [],
};

const getRecruiterForCuratorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_FOR_CURATOR_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterData: [],
      };
    case GET_RECRUITER_FOR_CURATOR_SUCCESS:
      return {
        ...state,
        recruiterData: payload,
        loading: false,
      };
    case GET_RECRUITER_FOR_CURATOR_FAILURE:
      return {
        ...state,
        recruiterData: [],
        loading: false,
      };
    case GET_RECRUITER_FOR_CURATOR_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        recruiterCount: [],
      };
    case GET_RECRUITER_FOR_CURATOR_COUNT_SUCCESS:
      return {
        ...state,
        recruiterCount: payload,
        countLoading: false,
      };
    case GET_RECRUITER_FOR_CURATOR_COUNT_FAILURE:
      return {
        ...state,
        recruiterCount: [],
        countLoading: false,
      };
    case GET_CLIENT_TALENT_COMMUNITY_BEGIN:
      return {
        ...state,
        communityLoading: true,
        communityData: [],
      };
    case GET_CLIENT_TALENT_COMMUNITY_SUCCESS:
      return {
        ...state,
        communityData: payload,
        communityLoading: false,
      };
    case GET_CLIENT_TALENT_COMMUNITY_FAILURE:
      return {
        ...state,
        communityData: [],
        communityLoading: false,
      };
    default:
      return state;
  }
};

export default getRecruiterForCuratorReducer;
