import React, { useState } from "react";
import moment from "moment";
import TalentProfile from "../../../../../talentpool/talentprofile/TalentProfile.js";
import { getUserRole } from "../../../../../../service/AuthService";
import EmailStatus from "../../../../../emailstatus/EmailStatus.js";
import ProfileImage from "../../../../../profileimage/ProfileImage.js";

const Qualified = (props) => {
  const { jobData, qualifiedData } = props;
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedTalent, setSelectedTalent] = useState({});

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  const checkDuplicate = (item) => {
    let data = qualifiedData.filter((f) => f.email == item.email);
    return data.length > 1 ? true : false;
  };
  return (
    <>
      {qualifiedData.map((item, i) => (
        <tr key={i} className="mb-2">
          <td onClick={() => onProfileClick(item)}>
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />
              <span className="ml-2 font-16 p-2">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase()}
                {checkDuplicate(item) ? (
                  <span class="ml-2 tag font-bold tag-red1">D</span>
                ) : (
                  ""
                )}
              </span>
            </div>
          </td>
          <td className="no-hover">
            <span className="ml-2">{item?.city || "NA"}</span>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {item.invitedDate
                ? moment(item.invitedDate).format("MM/DD/YYYY")
                : moment(item.automatchDate).format("MM/DD/YYYY")}
            </span>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {`${item?.recruiterFname} ${item?.recruiterLname}`}
            </span>
          </td>
          {getUserRole() === "HiringManager" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </button>

                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          <td className="no-hover">
            <span>
              {item.dispositionStatus == 33
                ? "NA"
                : props?.calculateScore(jobData, item) + "%"}
            </span>
          </td>
          <td className="text-left p-1">
            {item.dispositionStatus == 33 && <span>Recruiter Invited</span>}
            {item.dispositionStatus == 38 && <span>Automatched-Invited</span>}
          </td>
        </tr>
      ))}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Qualified"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          // parent="inviteTab"
          isFrom="Invited"
          component="jobDisposition"
        />
      )}
    </>
  );
};

export default Qualified;
