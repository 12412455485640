import React, { useEffect, useState } from "react";
import moment from "moment";

const Filter = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [requestTitleEnabled, setRequestTitleEnabled] = useState(false);
  const [requestTypeEnabled, setRequestTypeEnabled] = useState(false);
  const [requestTenantEnabled, setRequestTenantEnabled] = useState(false);
  const [requestJobDateEnabled, setRequestJobDateEnabled] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTenant, setRequestTenant] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [requestJobDate, setRequestJobDate] = useState([]);
  const [selectedJobDate, setSelectedJobDate] = useState([]);
  const [requestJobLocation, setRequestJobLocation] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  useEffect(() => {
    setRequestTitles(props.requestTitles);
    setRequestStatus(props.requestStatus);
    setRequestTenant(props.requestTenant);
    setSelectedTitles(props.selectedTitles);
    setSelectedTypes(props.selectedTypes);
    setSelectedTenant(props.selectedTenant);
    setSelectedJobDate(props.selectedJobDate);
    setRequestJobDate(props.requestJobDate);
    setRequestJobLocation(props.requestJobLocation);
    setSelectedJobLocation(props.selectedJobLocation);
    setDaysLeft(props.daysLeft);
    setDaysLeftValue(props.daysLeftValue);
  }, []);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };
  const tenantHandler = (checked, tenant) => {
    if (checked) {
      setSelectedTenant([...selectedTenant, tenant]);
    } else {
      setSelectedTenant(selectedTenant.filter((t) => t !== tenant));
    }
  };
  const typeHandler = (checked, type) => {
    if (checked) {
      setSelectedTypes([...selectedTypes, type]);
    } else {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    }
  };

  const dateHandler = (checked, jDate) => {
    if (checked) {
      setSelectedJobLocation([...selectedJobDate, jDate]);
    } else {
      setSelectedJobLocation(selectedJobDate.filter((t) => t !== jDate));
    }
  };

  const locationHandler = (checked, location) => {
    if (checked) {
      setSelectedJobLocation([...selectedJobLocation, location]);
    } else {
      setSelectedJobLocation(selectedJobLocation.filter((t) => t !== location));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : requestTitles.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [requestTitles, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      requestTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const clearAll = () => {
    setSelectedTypes([]);
    setSelectedTenant([]);
    setSelectedTitles([]);
    setSelectedJobDate([]);
    setSelectedJobLocation([]);
    setDaysLeftValue("");
    setDaysLeft("");
    // titleArray(job.jobTitle) &&
    //     jobType(job.jobType) &&
    //     jobLocation(job.workplaceType) &&
    //     // typeArray(job.jobStatus) &&
    //     jobClient(job.jobTenant)
  };

  const titleArray = (title) => {
    return selectedTitles.length > 0 ? selectedTitles.includes(title) : true;
  };
  const jobType = (title) => {
    return selectedTypes.length > 0 ? selectedTypes.includes(title) : true;
  };

  const typeArray = (type) => {
    return selectedTypes.length > 0 ? selectedTypes.includes(type) : true;
  };
  const jobClient = (tenant) => {
    return selectedTenant.length > 0 ? selectedTenant.includes(tenant) : true;
  };
  const jobDate = (jDate) => {
    return selectedJobDate.length > 0 ? selectedJobDate.includes(jDate) : true;
  };
  const jobLocation = (jLocation) => {
    return selectedJobLocation.length > 0
      ? selectedJobLocation.includes(jLocation)
      : true;
  };

  const daysLeftArray = (title, day) => {
    // let days = Number(
    //   moment(new Date(day), "YYYYMMDD")
    //     .fromNow()
    //     .match(/\d+/g)
    // );

    let days = Number(moment(day).diff(new Date(), "days"));

    if (daysLeftValue !== "") {
      let value = +daysLeftValue;

      if (days > 0) {
        switch (daysLeft) {
          case "equals":
            return days === value;
          case "lessthan":
            return days < value;
          case "greaterthan":
            return days > value;
          case "lessthanequalto":
            return days <= value;
          default:
            return days >= value;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const applyFilter = () => {
    // candidateData?.dispositionData?.filter((f) => f.statusname == "Offered")
    const filteredData = props?.jobData.filter((job) => {
      return (
        titleArray(job.jobTitle) &&
        jobType(job.jobType) &&
        jobLocation(job.workplaceType) &&
        // typeArray(job.jobStatus) &&
        jobClient(job.jobTenant)
        // jobDate(job.jobPostedDate) &&
        // daysLeftArray(job.jobTitle, job.expiresOn)
      );
    });
    let filteredData2 = [];

    if (props?.isFrom == "myjobs") {
      filteredData2 = props?.jobData2.filter((job) => {
        return (
          titleArray(job.jobTitle) &&
          jobType(job.jobType) &&
          jobLocation(job.workplaceType) &&
          // typeArray(job.jobStatus) &&
          jobClient(job.jobTenant)
          // jobDate(job.jobPostedDate) &&
          // daysLeftArray(job.jobTitle, job.expiresOn)
        );
      });
    }
    props.applyFilter(
      filteredData,
      selectedTitles,
      selectedTypes,
      selectedTenant,
      selectedJobDate,
      selectedJobLocation,
      daysLeft,
      daysLeftValue,
      filteredData2
    );

    // if (props?.isFrom == "myJobs") {
    //   const filteredData2 = props?.jobData2.filter((job) => {
    //     return (
    //       titleArray(job.jobTitle) &&
    //       jobType(job.jobType) &&
    //       jobLocation(job.workplaceType) &&
    //       // typeArray(job.jobStatus) &&
    //       jobClient(job.jobTenant)
    //       // jobDate(job.jobPostedDate) &&
    //       // daysLeftArray(job.jobTitle, job.expiresOn)
    //     );
    //   });

    //   props.applyFilter(
    //     filteredData,
    //     selectedTitles,
    //     selectedTypes,
    //     selectedTenant,
    //     selectedJobDate,
    //     selectedJobLocation,
    //     daysLeft,
    //     daysLeftValue,
    //     filteredData2
    //   );
    // }

    // props.setJobData(filteredData);
    // props.setRequestStatus(filteredData);
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={() => applyFilter()}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedTitles.length +
                  selectedTypes.length +
                  selectedTenant.length +
                  selectedJobDate.length +
                  selectedJobLocation.length}
                {/* selectedTypes.length + */}
                {/* +
                  selectedJobDate.length +
                  selectedJobLocation.length +
                  (daysLeftValue ? 1 : 0)  */}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTitleEnabled
                  ? setRequestTitleEnabled(false)
                  : setRequestTitleEnabled(true)
              }
            >
              <i
                className={
                  requestTitleEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {selectedTitles.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTitleEnabled &&
              (requestTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {requestTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-secondary text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTypeEnabled(!requestTypeEnabled)}
            >
              <i
                className={
                  requestTypeEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Type</small>
                {selectedTypes.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTypeEnabled &&
              (requestStatus.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestStatus.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTypes.includes(i)}
                            onChange={(e) => typeHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTypes([])}
                    >
                      <small>Clear Job Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTenantEnabled(!requestTenantEnabled)}
            >
              <i
                className={
                  requestTenantEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Client</small>
                {selectedTenant.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTenantEnabled &&
              (requestTenant.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestTenant.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTenant.includes(i)}
                            onChange={(e) => tenantHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTenant([])}
                    >
                      <small>Clear Job Client</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestJobDateEnabled(!requestJobDateEnabled)}
            >
              <i
                className={
                  requestJobDateEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Location</small>
                {selectedJobLocation.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {requestJobDateEnabled &&
              (requestJobLocation.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestJobLocation.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedJobLocation.includes(i)}
                            onChange={(e) => dateHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedJobLocation([])}
                    >
                      <small>Clear Job location</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
