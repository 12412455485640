import React, { useState } from "react";

const AssessmentPreview = ({questionList}) => {
  const [answers, setAnswers] = useState([
    {
      question:
        "Which keyword is used to create a new instance of a class in Java?",
      option: ["new", "this", "class", "void"],
      answer: "new",
    },
    {
      question: "What is the correct syntax for a static method in Java?",
      option: [
        "static int methodName() {}",
        "int static methodName() {}",
        "int methodName(static) {}",
        "int methodName() static {}",
      ],
      answer: "static int methodName() {}",
    },
  ]);
  return (
    <>
    
        {questionList?.questions?.length > 0 && (
          <>
            <div className="row m-2">
              <div className="col-6">
                <strong>Question</strong>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center" }}
              >
                <strong>Type</strong>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center" }}
              >
                <strong>Difficulty</strong>
              </div>
              <div
                className="col-2"
                style={{ textAlign: "center" }}
              >
                <strong>Skills</strong>
              </div>
            </div>
            {questionList?.questions?.map((item, index) => (
              <div className="row m-2">
                <div className="col-6">
                  {index + 1}. {item.question}
                </div>
                <div
                  className="col-2"
                  style={{ textAlign: "center" }}
                >
                  <span className="tag tag-blue2">{item.type}</span>
                </div>
                <div
                  className="col-2"
                  style={{ textAlign: "center" }}
                >
                  {item.difficulty}
                </div>
                <div
                  className="col-2"
                  style={{ textAlign: "center" }}
                >
                  {item.skill}
                </div>
              </div>
            ))}
          </>
        )}

    </>
  );
};

export default AssessmentPreview;
