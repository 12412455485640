import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Pagination from "../../../common/pagination/Pagination";
import {
  getCandidateName,
  getUserId,
  getUserName,
  getUserRole,
} from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";
import Loader from "../../../loader/Loader";
import RecSidePanel from "../../../recruiter/sidepanel/Index";
import EmailStatus from "../../../emailstatus/EmailStatus";
import ProfileImage from "../../../common/profileimage/ProfileImage";
// import Sort from "../../../commoncomponents/sort/Index";
// import { recruiterListObj, orderList } from "../../../pagedata/PageData";

const RecruiterTable = ({
  userData,
  isFrom,
  prefix,
  handlePageChange,
  currentPage,
  dataTotal,
  dataSize,
  handleSizeChange,
  handleSearch,
  searchData,
  fetchRecruiterData,
  sortArray,
  setSortArray,
  applySort,
}) => {
  const [loading, setLoading] = useState(false);
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);
  const [tenantObj, settenantObj] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  //   useEffect(() => {
  //     const tempArray = recruiterListObj.map((item) => item.value);
  //     setSorttype(tempArray);
  //   }, []);

  useEffect(() => {
    setopenTenantPopUP(false);
    // resetSort();
  }, [prefix]);

  const login = async (selectedUser) => {
    const payload = {
      userName: selectedUser.email,
      role: getUserRole(),
      actualUserName: getUserName(),
      candidateName: getCandidateName(),
      userId: getUserId(),
    };
    try {
      setLoading(true);
      let res = await post("/login/dashboardlogin", payload);
      if (res.status === 200) {
        localStorage.setItem("userToken", JSON.stringify(res.data));
        window.location.reload(true);
      }
      setLoading(false);
    } catch {
      toast.error("Error!!");
      setLoading(false);
    }
  };

  const openTenantPopUPFun = (item) => {
    setopenTenantPopUP(true);
    settenantObj(item);
  };

  const sendReminderMail = async (body) => {
    try {
      setLoading(true);

      const recData = await post("/dashboard/sa/recruiterreminder", [body]);
      if (recData.status === 200) {
        toast.success("Reminder email sent successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sendReminderMailRecruiter = async (item) => {
    try {
      setLoading(true);
      const params = {
        email: item.email,
        name: item.firstName,
        ID_user: item.createdby,
      };
      const recData = await post(
        "/dashboard/sa/sendremindertorecruiter",
        params
      );
      if (recData.status === 200) {
        toast.success("Reminder email sent successfully");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const checkLastLogin = (lastLoginDate) => {
    const currentDate = new Date();
    const threeWeeksAgo = new Date();
    threeWeeksAgo.setDate(currentDate.getDate() - 21);

    const lastLogin = new Date(lastLoginDate);

    if (lastLogin >= threeWeeksAgo && lastLogin <= currentDate) {
      return "active";
    } else {
      return "inActive";
    }
  };

  function downloadCSV() {
    const csvContent =
      "Name,Email\n" +
      userData
        .map((data) => `${data.firstName} ${data.LastName},${data.email}`)
        .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "NeverLoggedIn.csv"; // Specify the file name
    a.textContent = "Download CSV";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  }

  //   const resetSort = () => {
  //     setSortArray([]);
  //     const tempArray = recruiterListObj?.map((item) => item.value);
  //     setSorttype(tempArray);
  //     setSortCount(0);
  //     setSortApplied(false);
  //     setSort(false);
  //   };

  //   const sortClearAll = () => {
  //     resetSort();
  //     if (isFrom === "Bar" || isFrom === "clients") {
  //       fetchRecruiterData(1, dataSize, searchData, []);
  //     } else {
  //       fetchRecruiterData(prefix, 1, dataSize, searchData, []);
  //     }
  //   };
  console.log("tenantObj", tenantObj);
  return (
    <div className="card mt-3">
      {loading && <Loader />}
      <div className="card-body">
        <div class=" p-3 ">
          <div class="mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{prefix ? prefix : "Gig Recruiters"}</h6>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap d-block-768">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  value={dataSize}
                  onChange={handleSizeChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <div class="mb-2 mr-1 d-flex align-items-center justify-content-end col-md-8">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Recruiters"
                      className="form-control small font-14"
                      value={searchData}
                      onChange={handleSearch}
                    />
                  </div>
                  {/* <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={userData}
                    orderArray={orderList}
                    sortObjects={recruiterListObj}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Name, Email, and Created On"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  /> */}
                </div>
                {isFrom === "LoginOverview" && prefix == "Never LoggedIn" && (
                  <div class="mb-2">
                    <button
                      class="btn btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title="Download CSV"
                      onClick={() => downloadCSV()}
                    >
                      <i class="fa fa-download"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3  overflow-auto">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Recruiter Name</th>
                  <th scope="col">Email</th>
                  {(isFrom === "RegistrationOverview" ||
                    isFrom === "LoginOverview") && (
                    <th scope="col">Email Status</th>
                  )}
                  <th scope="col">Last logged in</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Status</th>
                  {(isFrom === "Bar" || prefix === "Inactive") && (
                    <th scope="col" style={{ width: 150 }}>
                      Action
                    </th>
                  )}
                  {isFrom === "RegistrationOverview" && <th scope="col"></th>}
                </tr>
              </thead>
              <tbody>
                {userData?.length > 0 &&
                  userData?.map((item) => (
                    <tr>
                      <td
                        onClick={() => openTenantPopUPFun(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <div class="avatar-name-candidatestableview d-flex align-items-center">
                          <div>
                            <span>
                              <ProfileImage
                                imageSrc={item?.profileImagePath}
                                imageWidth={"35"}
                                imageHeight={"35"}
                                avatarSize={"35"}
                                avatarRound={"80px"}
                                firstName={item?.firstName}
                                lastName={item?.LastName}
                              />
                            </span>
                          </div>
                          <div class="ml-3">
                            <strong class="mb-0 me-2">
                              {item.firstName} {item.LastName}
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>{item.email}</td>
                      {(isFrom === "RegistrationOverview" ||
                        isFrom === "LoginOverview") && (
                        <td className="no-hover">
                          <div
                            className="d-inline-block"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="View Email Status"
                          >
                            <button
                              type="button"
                              className="btn btn-square btn-gray"
                              onClick={() => {
                                setEmailData(item?.email);
                                setEmailStatus(true);
                                // setSelectedTalent(item);
                              }}
                            >
                              <i class="fa fa-envelope-o" />
                            </button>
                          </div>
                        </td>
                      )}
                      <td>
                        {item.LastLogin ? (
                          <span>
                            {moment(new Date(item.LastLogin)).format(
                              "MM/DD/YYYY"
                            )}
                          </span>
                        ) : (
                          "Never"
                        )}
                      </td>
                      <td>
                        <span>
                          {moment(new Date(item.createddatetime)).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </td>
                      {isFrom == "LoginOverview" ? (
                        <td>
                          {prefix === "Hyperactive" ? (
                            <span class="tag tag-green4">Hyperactive</span>
                          ) : prefix === "Active" ? (
                            <span class="tag tag-blue2">Active</span>
                          ) : prefix === "Inactive" ? (
                            <span class="tag tag-orange1">Inactive</span>
                          ) : prefix === "Unsubscribed" ? (
                            item.status === "Deactivate" ? (
                              <span class="tag tag-red2">Deactivated</span>
                            ) : (
                              <span class="tag tag-red2">Unsubscribed</span>
                            )
                          ) : prefix === "Others" ? (
                            <span class="tag tag-red2">Never Logged In</span>
                          ) : prefix === "Total" ? (
                            item.status === "Deactivate" ? (
                              <span class="tag tag-red2">Deactivated</span>
                            ) : item.status === "Invited" ? (
                              <span class="tag tag-blue3">Invited</span>
                            ) : item.status === "Pending" ? (
                              <span class="tag tag-orange1">Pending</span>
                            ) : item.status === "Approved" && item.LastLogin ? (
                              checkLastLogin(item.LastLogin) == "active" ? (
                                <span class="tag tag-blue2">Active</span>
                              ) : (
                                <span class="tag tag-orange1">Inactive</span>
                              )
                            ) : item.status === "Approved" ? (
                              <span class="tag tag-green4">Approved</span>
                            ) : (
                              <span class="tag">{item.status}</span>
                            )
                          ) : (
                            <span class="tag">{item.status}</span>
                          )}
                        </td>
                      ) : (
                        <td>
                          {item.status === "Deactivate" ? (
                            <span class="tag tag-red2">Deactivated</span>
                          ) : item.status === "Invited" ? (
                            <span class="tag tag-blue3">Invited</span>
                          ) : item.status === "Pending" ? (
                            <span class="tag tag-orange1">Pending</span>
                          ) : item.status === "Approved" && item.LastLogin ? (
                            checkLastLogin(item.LastLogin) == "active" ? (
                              <span class="tag tag-blue2">Active</span>
                            ) : (
                              <span class="tag tag-orange1">Inactive</span>
                            )
                          ) : item.status === "Approved" ? (
                            <span class="tag tag-green4">Approved</span>
                          ) : (
                            <span class="tag ">{item.status}</span>
                          )}
                        </td>
                      )}
                      {isFrom === "Bar" && (
                        <td>
                          <button
                            onClick={() => login(item)}
                            disabled={item.status !== "Approved"}
                            className="btn btn-sm "
                          >
                            Login
                          </button>
                        </td>
                      )}
                      {prefix === "Inactive" && (
                        <td>
                          <button
                            onClick={() => sendReminderMail(item)}
                            className="btn btn-sm "
                          >
                            Send Reminder
                          </button>
                        </td>
                      )}
                      {isFrom === "RegistrationOverview" &&
                        item.status === "Invited" && (
                          <td
                          // onClick={() => openTenantPopUPFun(item)}
                          >
                            {Math.round(
                              (new Date() - new Date(item.createddatetime)) /
                                (1000 * 60 * 60 * 24)
                            ) > 2 && (
                              <button
                                onClick={() => sendReminderMailRecruiter(item)}
                                className="btn btn-sm "
                              >
                                Resend
                              </button>
                            )}
                          </td>
                        )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {(!userData || userData?.length === 0) && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
            {userData?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            )}
          </div>
        </div>
      </div>
      {openTenantPopUP && (
        <RecSidePanel
          details={tenantObj}
          closePanel={() => setopenTenantPopUP(false)}
          prefix={prefix}
          isFrom={isFrom}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="CMRecInvite"
          user="CM"
        />
      )}
    </div>
  );
};

export default RecruiterTable;
