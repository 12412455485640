import React, { useState } from "react";

const FetchJobFromJobDiva = ({jobDivaId, setJobDivaId,fetchJobDivaJob,setIsRequired,isRequired}) => {
  

  const handleIsJobId = (e) => {
    if (e.target.checked) {
      setIsRequired(true);
    } else {
      setIsRequired(false);
    }
  };
  return (
    <>
      <div className="form-check form-switch d-flex align-items-center px-3">
        <label className="form-check-label pt-0" htmlFor="remoteSwitch">
          Want to create job from JobDiva?
        </label>
        <input
          className="form-check-input ml-3"
          type="checkbox"
          id="isFresher"
          checked={isRequired}
          onChange={(e) => handleIsJobId(e)}
        />
      </div>

      {isRequired && (
      <div className="card card-flat bg-gray4 mb-3 mx-3 mt-3" style={{marginTop:"1px !important"}}>
          <div className="card-body py-0">
            <div className="row my-3">
              <div className="col-lg-5">
                <div className="form-floating">
                  <input
                    type="text"
                    className={"form-control"
                    }
                    name="jobDivaId"
                    placeholder="Enter a job id"
                    onChange={(e)=>setJobDivaId(e.target.value)}
                    value={jobDivaId}
                  />
                  <label>JobDiva ID*</label>
                </div>
              </div>
              <div className="col-lg-3 mt-2">
                <button className="btn" onClick={fetchJobDivaJob}>Search</button>
              </div>
              <div className="col-lg-4">
                <div className="form-floating">
                  
                </div>
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
};

export default FetchJobFromJobDiva;
