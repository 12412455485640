import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";
import {
  getUserRole,
  getActualRole,
} from "../../../../../../service/AuthService";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import ShortlistPopUp from "../shortlisted/ShortlistPopUp";
import {
  getJobDispositionDetails,
  getJobDispositionCount,
  getJobDispositionDisqualify,
} from "../../../../../../action/disposition/job/jobdetails/JobDetails";
import TalentProfile from "../../../../../talentpool/talentprofile/TalentProfile.js";
import VettingResendMail from "../shortlisted/VettingResendMail";
import EmailStatus from "../../../../../emailstatus/EmailStatus.js";
import ReviewReminder from "../shortlisted/ReviewReminder";
import ProfileImage from "../../../../../profileimage/ProfileImage.js";

const Submitted = (props) => {
  const { jobData, submittedData, view } = props;

  const dispatch = useDispatch();
  const { Id } = useParams();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState(false);
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [shortlistPopup, setShortlistPopup] = useState(false);
  const [vettingResendPopup, setVettingResendPopup] = useState(false);
  const [reviewerReminder, setReviewerReminderPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  // const [isVettingPending, setIsVettingPending] = useState(true);
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  const onDisqualifyClick = () => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const shortlistCandidate = async (item) => {
    setSelectedCandidate(item);

    let vettingDetails = item?.vettingDetails;

    if (vettingDetails?.length > 0 && vettingDetails[0]?.status === "Invited") {
      setVettingResendPopup(true);
    } else if (
      vettingDetails?.length > 0 &&
      vettingDetails[0]?.status === "underReview"
    ) {
      setReviewerReminderPopup(true);
    } else {
      setShortlistPopup(true);
    }
  };

  const reloadDisposition = (type) => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    dispatch(getJobDispositionDetails(payloadDisposition));
    dispatch(getJobDispositionCount(payload));
    if (type == "disqualify") {
      const payloadDisqualify = {
        jobId: Id,
        status: "disqualified",
        limit: 15,
        currentPage: 1,
      };
      dispatch(getJobDispositionDisqualify(payloadDisqualify));
    }
    setShortlistPopup(false);
    setDisqualifyPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {submittedData.map((item, index) => (
        <tr key={index}>
          <td
            className="no-hover"
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"35"}
              imageHeight={"35"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />

            <span className="ml-2 font-16 p-4">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase() + item?.lastName.slice(1)}
            </span>
          </td>
          <td className="no-hover">
            {moment(new Date(item.submittedDate)).format("MM/DD/YYYY")}
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                onClick={() => handleResume(item)}
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>{" "}
          {getUserRole() !== "HiringManager" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                {" "}
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <span>
                      <i class="fa fa-envelope-o" />
                    </span>
                  </button>
                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          <td className="no-hover">
            <span className="no-hover">
              {/* {props.calculateScore(jobData, item) + "%"} */}
            </span>
          </td>
          <td className="no-hover text-right">
            {(getActualRole() == "High5Coordinator" ||
              getUserRole() == "HiringManager") &&
              jobData.jobStatus !== 23 &&
              jobData.jobStatus !== 26 &&
              jobData.jobStatus !== 39 && (
                <div className="btn-group btn-group-sm btn-split">
                  <button
                    type="button"
                    onClick={() => {
                      shortlistCandidate(item);
                    }}
                    className="btn btn-sm"
                  >
                    Shortlist
                  </button>
                  <button
                    type="button"
                    onClick={() => openDisqualify(item)}
                    className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  {disqualifyData.userId == item.userId &&
                    openDropDown &&
                    jobData.jobStatus !== 23 &&
                    jobData.jobStatus !== 26 &&
                    jobData.jobStatus !== 39 && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenDropDown(false)}
                      >
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{
                            display: "revert",
                            right: "0px",
                            top: "30px",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => onDisqualifyClick()}
                            className="dropdown-item"
                          >
                            Disqualify
                          </button>
                        </div>
                      </OutsideClickHandler>
                    )}
                </div>
              )}
          </td>
        </tr>
      ))}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Submitted"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          submittedData={submittedData}
          setDisqualifyPopup={setDisqualifyPopup}
          setOpenDropDown={setOpenDropDown}
          setSelectedCandidate={setSelectedCandidate}
          setShortlistPopup={setShortlistPopup}
          openDisqualify={openDisqualify}
          disqualifyData={disqualifyData}
          openDropDown={openDropDown}
          onDisqualifyClick={onDisqualifyClick}
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="submitTab"
          isFrom="submitTab"
          component="jobDisposition"
          disqualifyPopup={disqualifyPopup}
          reloadDisposition={reloadDisposition}
          setVettingResendPopup={setVettingResendPopup}
        />
      )}
      {shortlistPopup && (
        <ShortlistPopUp
          selectedCandidate={selectedCandidate}
          shortlistPopup={shortlistPopup}
          setShortlistPopup={setShortlistPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {vettingResendPopup && (
        <VettingResendMail
          candidateData={selectedCandidate}
          jobData={jobData}
          vettingResendPopup={vettingResendPopup}
          setVettingResendPopup={setVettingResendPopup}
        />
      )}
      {reviewerReminder && (
        <ReviewReminder
          candidateData={selectedCandidate}
          jobData={jobData}
          vettingResendPopup={reviewerReminder}
          setVettingResendPopup={setReviewerReminderPopup}
        />
      )}
    </>
  );
};

export default Submitted;
