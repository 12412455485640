import React, { useState } from "react";
import TopNavbar from "../../layout/TopNavbar";
import SideNavBar from "../../layout/SideNavbar";
import Footer from "../../layout/Footer";
import { getTenantType } from "../../../service/AuthService";
import JobDetails from "./JobDetails";

const Index = () => {
  const [toggle, setToggle] = useState(true);

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
      id="body"
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavbar setToggle={setToggle} toggle={toggle} />
      <SideNavBar />
      <div id="Content">
        <JobDetails />
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
