import React from "react";

const Contract = (props) => {
  return (
    <>
      <div className="card card-flat card-borderless bg-gray4 m-3">
        <div className="card-body">
          <p className="mb-1">Contract Duration*</p>
          <div className="row">
            <div className="col-lg-3 mb-2 mb-lg-0">
              <div className="form-floating">
                <input
                  name="durationMonths"
                  type="number"
                  min="0"
                  className={
                    "" ? "form-control border border-danger" : "form-control"
                  }
                  placeholder="Months"
                  onChange={props?.handleChange}
                  value={props?.addJobData.durationMonths}
                />
                <label>Months</label>
              </div>
              {"" && <small className="validation ml-1">{""}</small>}
            </div>
            <div className="col-lg-3">
              <div className="form-floating">
                <input
                  name="durationDays"
                  type="number"
                  min="0"
                  className={
                    "" ? "form-control border border-danger" : "form-control"
                  }
                  placeholder="Months"
                  onChange={props?.handleChange}
                  value={props?.addJobData.durationDays}
                />
                <label>Days</label>
              </div>
              {"" && <small className="validation ml-1">{""}</small>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contract;
