import React from "react";
//import AddJobNew from "../components_v3/job/newjob/addJob";
//import AddJobSuccess from "../components_v3/job/newjob/successJob";
import AddTalent from "../../talentpool/addtalent/Index";
//import TalentPool from "../components_v3/talent/TalentPool";

const ModalWrapper = ({
  successFormOpen,
  closeAddJobSucces,
  successData,
  addJobNewModal,
  closeAddJobModal,
  showAddTalentModal,
  setShowAddTalentModal,
  talentPoolModal,
  setTalentPoolModal,
  setSuccessData,
  setSuccessFormOpen,
}) => {
  return (
    <>
      {/* {addJobNewModal && (
        <AddJobNew
          show={addJobNewModal}
          onHide={closeAddJobModal}
          setSuccessData={setSuccessData}
          setSuccessFormOpen={setSuccessFormOpen}
        />
      )} */}
      {/* {successFormOpen && (
        <AddJobSuccess
          show={successFormOpen}
          onHide={closeAddJobSucces}
          data={successData}
        />
      )} */}
      {showAddTalentModal && (
        <AddTalent
          showAddTalentModal={showAddTalentModal}
          onHide={() => setShowAddTalentModal(false)}
        />
      )}
      {/* {talentPoolModal && (
        <TalentPool
          show={talentPoolModal}
          onHide={() => setTalentPoolModal(false)}
        />
      )} */}
    </>
  );
};

export default ModalWrapper;
