import { toast } from "react-toastify";

export const validate = (questionData) => {
  let validationArray = [];
  let validation = {
    question: "",
    type: "",
    answer: [],
    options: ["", ""],
    skill: "",
  };

  if (questionData.type === "") {
    validation = { ...validation, type: "*Required Field" };
    validationArray.push("Please Select Question Type");
  }
  if (questionData.skill == "") {
    validation = { ...validation, skill: "*Required Field" };
    validationArray.push("Please Enter Mandatory Skill");
  }
  if (questionData.question === "") {
    validation = { ...validation, question: "*Required Field" };
    validationArray.push("Please Enter Question");
  }
  if (questionData.type === "MCQ") {
    if (questionData?.answer?.length === 0) {
      validation = { ...validation, answer: "*Required Field" };
      validationArray.push("Please Select Answer");
    } else if (questionData?.answer?.length > 0) {
      if (questionData?.answer[0] === "") {
        validation = { ...validation, answer: "*Required Field" };
        validationArray.push("Please Select Answer");
      }
    }
    questionData?.options?.forEach((element) => {
      if (element.trim() === "") {
        validation = { ...validation, options: "*Required Field" };
        validationArray.push("Please Enter Option");
      }
    });
  }
  return { validation, validationArray };
};
