import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import Loader from "../loader/Loader";

function ActivateDeactivateTag(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const changeStatus = async (text) => {
    setLoading(true);
    let paramObj = { tagId: props.selectedTag.Id_Tags };
    if (text === "activate") {
      paramObj.tagStatus = 21;
    } else {
      paramObj.tagStatus = 22;
    }
    let resp = await post("/tags/changeStatus", paramObj);
    if (resp.status === 200) {
      props.DeleteRequestClose(false);
      let payload = {
        currentPage: 1,
        limit: 10,
        status: null,
        tab: "All",
        sortParam: [],
      };
      dispatch(props.getTalentList(payload));
      setLoading(false);
      JSON.parse(resp.config.data).tagStatus === 21
        ? toast.success("Tag activated successfully")
        : toast.success("Tag deactivated successfully");
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body  text-center">
          <div className="avatar avatar-lg avatar-red2 mr-3">
            <i className="fas fa-exclamation-triangle fa-fw"></i>
          </div>
          <p className="text-center lead mt-3">
            Are you sure you want to{" "}
            {props.selectedTag.tagStatus == 21 ? "deactivate" : "activate"}{" "}
            {props.selectedTag.tagName} tag?
          </p>
          <div className="text-center my-4">
            <button
              type="button"
              className="btn btn-secondary mr-3"
              data-dismiss="modal"
              onClick={props.DeleteRequestClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                changeStatus(
                  props.selectedTag.tagStatus == 21 ? "deactivate" : "activate"
                )
              }
            >
              Confirm{" "}
              {props.selectedTag.tagStatus == 21 ? "deactivate" : "activate"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActivateDeactivateTag;
