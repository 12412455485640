import {
  GET_CLIENT_USER_DATA_BEGIN,
  GET_CLIENT_USER_DATA_SUCCESS,
  GET_CLIENT_USER_DATA_FAILURE,
  GET_CLIENT_USER_DATA_COUNT_BEGIN,
  GET_CLIENT_USER_DATA_COUNT_SUCCESS,
  GET_CLIENT_USER_DATA_COUNT_FAILURE,
} from "../../../../action/dashboard/superadmin/client/ClientUserData";

const initialState = {
  loading: true,
  countLoading: true,
  clientData: [],
  userDataCount: [],
};

const getClientUserDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CLIENT_USER_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        clientData: [],
      };
    case GET_CLIENT_USER_DATA_SUCCESS:
      return {
        ...state,
        clientData: payload,
        loading: false,
      };
    case GET_CLIENT_USER_DATA_FAILURE:
      return {
        ...state,
        clientData: [],
        loading: false,
      };
    case GET_CLIENT_USER_DATA_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        userDataCount: [],
      };
    case GET_CLIENT_USER_DATA_COUNT_SUCCESS:
      return {
        ...state,
        userDataCount: payload,
        countLoading: false,
      };
    case GET_CLIENT_USER_DATA_COUNT_FAILURE:
      return {
        ...state,
        userDataCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getClientUserDataReducer;
