import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { ButtonLoader, monthNames } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";

const Licenses = ({ data, updateDetails, setUpdateDetails }) => {
  var today = new Date();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [experience, setExperience] = useState(
    data?.experiences == null
      ? []
      : JSON.parse(data?.experiences)?.filter(
          (experience) => experience.employerName !== ""
        )
  );
  const [licenses, setLicenses] = useState(
    data?.licenses == null ? [] : JSON.parse(data?.licenses)
  );
  const [tempLicense, setTempLicense] = useState(
    JSON.parse(data?.licenses)?.filter((license) => license.licenseName !== "")
  );

  const addLicense = () => {
    let ex = [...licenses];

    if (true) {
      ex.push({
        licenseName: "",
        license: "",
        state: "",
        expiryDate: "",
      });
      setLicenses(ex);
    }
  };

  const postExperienceInfo = async () => {
    setTempLicense(licenses);
    let emptyFieldsCondition = true;
    let dateCondition = true;

    licenses?.forEach((e) => {
      if (e.isSelect) {
        if (!e.licenseName || !e.license || !e.state) {
          emptyFieldsCondition = false;
          return false;
        }
      } else {
        if (!e.licenseName || !e.license || !e.state || !e.expiryDate) {
          emptyFieldsCondition = false;
          return false;
        }
      }
    });

    if (!emptyFieldsCondition) {
      toast.error("Fields Marked with *(Asterisk) are mandatory");
    } else if (!dateCondition) {
      toast.error(`'Duration From' should not be greater than 'Duration To'`);
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        console.log(licenses);
        const parameters = {
          ...data,
          licenses: JSON.stringify(licenses),
        };
        const res = await post("/talent/updatetalentprofile", parameters);
        if (res.status === 200) {
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  const deleteLicense = (index) => {
    let ex = [...licenses];
    ex.splice(index, 1);
    setLicenses(ex);
  };

  // const deleteExperience = (index) => {
  //   let ex = [...experience];
  //   ex.splice(index, 1);
  //   setExperience(ex);
  // };

  const editLicense = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelExperience = () => {
    // setExperience(tempExperience);
    setLicenses(tempLicense);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const handleChange = (name, value, index) => {
    let ex = [...licenses];
    if (name === "isSelect" && value) {
      ex = licenses.map((e, i) => {
        return index === i
          ? { ...e, isSelect: true }
          : { ...e, isSelect: false };
      });
    } else {
      ex[index] = { ...ex[index], [name]: value };
    }
    setLicenses(ex);
  };

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Licenses</h6>
            {readOnlyMode === true ? (
              <button type="button" class="btn btn-text" onClick={editLicense}>
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelExperience}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={postExperienceInfo}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {console.log("licenses", licenses)}
          {readOnlyMode ? (
            <div>
              {readOnlyMode &&
              (licenses.length == 0 || licenses[0].licenseName == "") ? (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-comment-slash"></i>
                    </div>
                    <div className="mt-2">No Record Found</div>
                  </div>
                </div>
              ) : (
                licenses?.map((e) => (
                  <div class="d-flex">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i
                        class="fad fa-fw fa-briefcase mt-1"
                        aria-hidden="true"
                      ></i>
                      <div class="view-panel-spacer mt-1"></div>
                    </div>
                    <div class="pb-3">
                      <p class="mb-1">
                        <strong>License Name :</strong>
                        {e.licenseName}
                      </p>
                      <p class="mb-0">
                        <small>
                          <strong> License Id : </strong>
                          {e.license}
                        </small>
                      </p>
                      <p>
                        <small>
                          Expiry Date:{" "}
                          {moment(e?.expiryDate).format("MM/DD/YYYY")}
                        </small>
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div class="work-experience">
              {licenses?.map((e, j) => (
                <div class="d-flex">
                  <div class="mr-3">
                    <div class="avatar avatar-sm">{j + 1}</div>
                  </div>
                  <div className="col-10">
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="licenseName"
                            placeholder="Certification Name"
                            value={e.licenseName}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                          <label>License Name*</label>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <div class="form-floating" style={{ width: "100%" }}>
                          <input
                            type="text"
                            class="form-control"
                            name="license"
                            placeholder="Certification Name"
                            value={e.license}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                          <label>License id*</label>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-lg-6 ">
                        <div class="form-floating ">
                          <input
                            type="text"
                            class="form-control "
                            name="state"
                            value={e.state}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />{" "}
                          <label>State*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Expiry Date {e.isSelect ? null : "*"}</label>
                          <input
                            style={{ marginTop: "0px" }}
                            type="date"
                            class="form-control date"
                            placeholder="End Date"
                            name="expiryDate"
                            value={e.expiryDate}
                            disabled={e.isSelect}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="ml-3 col-1">
                    <button
                      type="button"
                      class="btn btn-icon"
                      onClick={() => deleteLicense(j)}
                    >
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              ))}
              <div className="ml-5">
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addLicense}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add License</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Licenses;
