import React from "react";
import TagsInput from "react-tagsinput";
import SkillsSearchable from "../../../common/skills/SkillsSearchable";
import { toast } from "react-toastify";

const PrimarySkills = (props) => {
  function containsAlphabet(str) {
    return /[a-zA-Z]/.test(str);
  }

  function getUniqueSkills(param) {
    let uniqueArr = [...new Set(param)];
    return uniqueArr;
  }
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <div className="form-floating mb-2" style={{ zIndex: "999" }}>
            <div>
              <label className="mb-1">
                Mandatory skills* <small>(Suggested to add top 3 skills)</small>
              </label>
              <SkillsSearchable
                onChange={(skills) => {
                  if (!containsAlphabet(skills[skills?.length - 1])) {
                    return toast.error("Please enter a valid skill");
                  }
                  props?.setAddJobData({
                    ...props?.addJobData,
                    weightage: {
                      ...props?.addJobData.weightage,
                      primarySkills: skills,
                    },
                    primarySkills: skills,
                  });
                }}
                disabled={props?.edit}
                // inputArr={props?.addJobData.primarySkills}
                inputArr={getUniqueSkills(props?.addJobData.primarySkills)}
                skillinput={props?.primarySkillInput}
                onChangeInput={(skill) => {
                  props?.setPrimarySkillInput(skill);
                }}
                inputProps={{
                  placeholder: props?.primarySkillinput ? "" : "Enter skills*",
                }}
                isFrom={"addJob"}
                isValid={props?.isFormValid}
                addJobData={props?.addJobData}
              />

              {/* <TagsInput
                renderInput={props?.autocompleteRenderInput}
                value={props?.addJobData.primarySkills}
                onChange={(skills) => {
                  props?.setAddJobData({
                    ...props?.addJobData,
                    weightage: {
                      ...props?.addJobData.weightage,
                      primarySkills: skills,
                    },
                    primarySkills: skills,
                  });
                }}
                disabled ={props?.edit}
                inputValue={props?.primarySkillinput}
                onChangeInput={(skills) => props?.setPrimarySkillInput(skills)}
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: props?.primarySkillinput ? "" : "Enter skills*",
                }}
              /> */}
            </div>
            {props?.isFormValid &&
              props?.addJobData.primarySkills.length == 0 && (
                <small className="validation ml-2">*Required field</small>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimarySkills;
