import React, { useState } from "react";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import sparkles from "../../../../assets/lottie/sparkles.json";
import checkMark from "../../../../assets/lottie/checkmark.json";

const { Anime } = ReactAnime;
const Success = () => {
  const [loop] = useState(false);
  return (
    <>
      <div className="bl-modal reveal-overlay reveal-card">
        <div className="bl-modal-overlay"></div>
        <div className="bl-modal-container">
          <div className="bl-modal-card bl-modal-card-md">
            <div className="bl-modal-body">
              <div className="success-animation">
                <div className="checkmark mt-3">
                  <Lottie loop={loop} animationData={checkMark} play></Lottie>
                </div>
                <Anime
                  initial={[
                    {
                      targets: "#LottieSparkles",
                      opacity: 0,
                      delay: 1000,
                    },
                  ]}
                >
                  <div className="sparkles">
                    <Lottie
                      loop={loop}
                      animationData={sparkles}
                      id="LottieSparkles"
                      play
                    ></Lottie>
                  </div>
                </Anime>
              </div>
              <div className="mb-3">
                <h4 className="text-center">Registered Successfully</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
