import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import HereMap from "../../../location/HereMapAddtalent";

function ContactInfo(props) {
  console.log("props", props);
  const [isRemote, setIsRemote] = useState(true);

  const handleChange = (e) => {
    props.setContactInfo({
      ...props.contactInfo,
      [e.target.id]: e.target.value,
    });
  };

  const _onChangeText_Work = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    asYouType.input(value.replace(country.dialCode, ""));
    const isValid =
      value === country.dialCode || value === "" ? true : asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      workPhone: value.slice(country.dialCode.length),
      workPhoneValidation: isValid,
      workPhoneCode: `+${country.dialCode}`,
    });
  };

  const _onChangeText_Home = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    asYouType.input(value.replace(country.dialCode, ""));
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      homePhone: value.slice(country.dialCode.length),
      homePhoneValidation: isValid,
      homePhoneCode: `+${country.dialCode}`,
    });
  };

  return (
    <>
      <div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder="First Name*"
                  style={{
                    border:
                      props.contactInfoValidation?.firstName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo?.firstName}
                  onChange={handleChange}
                />
                <label>First Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation?.firstName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder="Last Name*"
                  style={{
                    border:
                      props.contactInfoValidation?.lastName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo?.lastName}
                  onChange={handleChange}
                />
                <label>Last Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation?.lastName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Email*"
                  style={{
                    border:
                      props.contactInfoValidation?.email && "2px solid #ff0000",
                  }}
                  value={props.contactInfo?.email}
                  onChange={handleChange}
                  disabled={props?.isFrom === "TalentProfileEdit"}
                />
                <label>Email*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation?.email}
              </small>
            </div>
          </div>
          <div class="row mb-2"></div>
          <HereMap
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            contactInfo={props.contactInfo}
            setContactInfo={props.setContactInfo}
            profileInfo={props.profileInfo}
            setProfileInfo={props.setProfileInfo}
          />
          {isRemote && (
            <>
              {/* <div class="row mb-2">
                <div class="col-lg-12">
                  <label>Primary Address</label>
                </div>
              </div> */}
              <div class="row mb-2">
                {/* <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="zipcode"
                      placeholder="Zipcode"
                      style={{
                        border:
                          props.contactInfoValidation.zipcode &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.zipcode}
                      onChange={handleChange}
                    />
                    <label>Zipcode*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.zipcode}
                  </small>
                </div> */}
                {/* <div class="col-lg-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      placeholder="Address"
                      style={{
                        border:
                          props.contactInfoValidation?.address &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo?.address}
                      onChange={handleChange}
                    />
                    <label>Address</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation?.address}
                  </small>
                </div> */}
              </div>
              <div class="row mb-2">
                {/* <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressCity"
                      placeholder="Address City"
                      style={{
                        border:
                          props.contactInfoValidation?.addressCity &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo?.addressCity}
                      onChange={handleChange}
                    />
                    <label>City*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation?.addressCity}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressState"
                      placeholder="Address State"
                      style={{
                        border:
                          props.contactInfoValidation?.addressState &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo?.addressState}
                      onChange={handleChange}
                    />
                    <label>State*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation?.addressState}
                  </small>
                </div> */}
                <div class="col-lg-12">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      placeholder="Address Country"
                      style={{
                        border:
                          props.contactInfoValidation?.country &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo?.country}
                      onChange={handleChange}
                      disabled={true}
                    />
                    <label>Country*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation?.country}
                  </small>
                </div>

                {/* <div class="col-lg-4">
                                        <div class="form-floating">
                                            <select class="form-select" id="country" onChange={handleChange}>
                                                <option value="" selected>Select Country</option>
                                                <option value="US">US</option>
                                            </select>
                                            <label >Address Country</label>
                                        </div>
                                    </div> */}
              </div>
            </>
          )}
          <div className="row mb-2">
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Primary Phone*</label>
              </div>

              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  name="homePhone"
                  country={"us"}
                  value={
                    props.contactInfo.homePhoneCode +
                    props.contactInfo.homePhone
                  }
                  onChange={(value, country) => {
                    _onChangeText_Home(value, country);
                  }}
                  style={{
                    border:
                      props.isFormValid &&
                      (props.contactInfo.homePhone == "" ||
                        !props.contactInfo.homePhone) &&
                      "2px solid #ff0000",
                    width: "304px",
                  }}
                />
              </div>
              {props.isFormValid &&
                (props.contactInfo.homePhone == "" ||
                  !props.contactInfo.homePhone) && (
                  <small className="validation">*Required Field</small>
                )}
              {!props?.contactInfo?.homePhoneValidation &&
                props.contactInfo.homePhone && (
                  <small className="validation">Invalid Phone</small>
                )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  value={
                    props.contactInfo.workPhoneCode +
                    props.contactInfo.workPhone
                  }
                  name="workPhone"
                  country={"us"}
                  onChange={(value, country) => {
                    _onChangeText_Work(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.workPhoneValidation && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactInfo;
