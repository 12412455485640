import React from "react";
import ReactQuill from "react-quill";

const EditTemplate = (props) => {
  const {
    emailData,
    sendMail,
    testMailRecipient,
    setTestMailRecipient,
    isValid,
    handleCancelClick,
    Validator,
    sanitizeHTML,
    testMailMode,
  } = props;

  return (
    <>
      <form onSubmit={sendMail}>
        <div className="d-flex justify-content-end pr-4 gap-1">
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            // disabled={testMailRecipient === ""}
          >
            Send Email
          </button>
          <button
            type="button"
            className="btn btn-secondary ms-2 btn-sm"
            onClick={() => {
              handleCancelClick();
            }}
          >
            Cancel
          </button>
        </div>
        <div className="mb-3">
          <label htmlFor="testMailRecipient" className="form-label">
            <b>
              Recipient Email ID
              <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          {/* <input
                      type="email"
                      className="form-control"
                      id="testMailRecipient"
                      value={testMailRecipient}
                      style={{
                        border:
                          isValid &&
                          testMailRecipient === "" &&
                          "2px solid #ff0000",
                        backgroundColor: "#F6F6F6",
                      }}
                      // required={true}
                      onChange={(e) => setTestMailRecipient(e.target.value)}
                      disabled={!testMailMode}
                    /> */}
          <input
            type="email"
            className="form-control"
            id="testMailRecipient"
            value={testMailRecipient}
            style={{
              border:
                isValid &&
                (testMailRecipient === "" ||
                  !Validator.validate(testMailRecipient)) &&
                "2px solid #ff0000",
              backgroundColor: "#F6F6F6",
            }}
            onChange={(e) => setTestMailRecipient(e.target.value)}
            disabled={!testMailMode}
          />

          {isValid && testMailRecipient === "" && (
            <small className="validation">*Required Field</small>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="editSubject" className="form-label">
            <b>
              Subject <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <input
            type="text"
            className="form-control"
            id="editSubject"
            style={{
              border:
                isValid && emailData?.mailSubject === "" && "2px solid #ff0000",
              backgroundColor: "#F6F6F6",
              borderRadius: "0px",
              fontSize: "0.9em",
            }}
            value={emailData?.mailSubject}
            disabled={true}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="editContent" className="form-label">
            <b>
              Content <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <ReactQuill
            value={emailData?.mailContent}
            style={{
              border:
                isValid &&
                sanitizeHTML(emailData?.mailContent) === "" &&
                "2px solid #ff0000",
              backgroundColor: "#F6F6F6",
            }}
            theme="snow"
            readOnly={true}
            // style={{ lineHeight: "0" }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="editFooter" className="form-label">
            <b>
              Footer <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <ReactQuill
            value={emailData?.footer + emailData?.unsubscribeContent}
            style={{
              border:
                isValid &&
                sanitizeHTML(
                  emailData?.footer + emailData?.unsubscribeContent
                ) === "" &&
                "2px solid #ff0000",
              backgroundColor: "#F6F6F6",
            }}
            readOnly={true}
            theme="snow"
            // style={{ lineHeight: "0" }}
          />
        </div>
      </form>
    </>
  );
};

export default EditTemplate;
