import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/hand-loader.json";

function Loader() {
  const style = {
    height: 200,
    width: 200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <div className="loaderpopup">
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={style}
      />
    </div>
  );
}

export default Loader;
