import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import Submitted from "./Submitted";
import NoRecord from "../../../../../common/NoRecord";
import { getUserRole } from "../../../../../../service/AuthService";
import Pagination from "../../../../../common/pagination/Pagination";
import { getJobDispositionDetails } from "../../../../../../action/disposition/job/jobdetails/JobDetails";

const Index = (props) => {
  const { jobData, value, view } = props;
  const [submittedData, setSubmittedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const { dispositionDetails, dispositionCount } = useSelector(
    (state) => ({
      dispositionDetails: state.jobDispositionReducer.dispositionDetails,
      dispositionCount: state.jobDispositionReducer.dispositionCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (dispositionDetails.length > 0) {
      if (value !== "" && value !== null) {
        let data = dispositionDetails.map((e) => ({
          ...e,
          name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
        }));
        const regex = new RegExp(value, "i");
        let filter = data
          .filter(
            (item) => item.dispositionStatus == 6 && regex.test(item.name)
          )
          .sort((a, b) => b.submittedDate.localeCompare(a.submittedDate));
        setSubmittedData(filter);
      } else {
        setSubmittedData(
          dispositionDetails
            .filter((item) => item.dispositionStatus == 6)
            .sort((a, b) => b.submittedDate.localeCompare(a.submittedDate))
        );
      }
    } else {
      setSubmittedData(
        dispositionDetails
          .filter((item) => item.dispositionStatus == 6)
          .sort((a, b) => b.submittedDate.localeCompare(a.submittedDate))
      );
    }
  }, [dispositionDetails, value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getJobDispositionDetails(payload));
  };

  return (
    <div>
      {submittedData.length > 0 ? (
        <>
          <table className="table table-hoverable-cells table-sortable table-cards-1024">
            <thead>
              <tr>
                <th className="no-hover">Candidate </th>
                <th className="no-hover">Submitted on</th>
                <th className="no-hover">Resumes </th>
                {getUserRole() === "HiringManager" && (
                  <th className="no-hover">Email Status </th>
                )}
                <th className="no-hover">Score </th>
                <th className="no-hover"></th>
              </tr>
            </thead>
            <tbody>
              <Submitted
                jobData={jobData}
                submittedData={submittedData}
                calculateScore={props.calculateScore}
                view={view}
              />
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Submitted")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
