import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import TalentsTable from "./TalentsTable";
import {
  getRecruitementPipeline,
  getRecruitementPipelineCount,
  getRecruiterNameData,
} from "../../../../action/dashboard/superadmin/gigrecruiter/RecruitmentPipeline";
import Loader from "../../../loader/Loader";

const RecruitmentPipeline = () => {
  const numberFormatter = new Intl.NumberFormat("en-US");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("Total");
  const [options, setOptions] = useState([{ value: "0", label: "All" }]);
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "All",
  });
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [percentage, setPercentage] = useState(0);
  const [selectedRecruiter, setSelectedRecruiter] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);

  const [sortArray, setSortArray] = useState([]);

  const {
    recruitementPipelineData,
    recruitementPipelineCount,
    recruiterName,
    loading,
    countLoading,
    nameLoading,
  } = useSelector(
    (state) => ({
      recruitementPipelineData: state.getRecruitementPipelineReducer.talentData,
      recruitementPipelineCount:
        state.getRecruitementPipelineReducer.recruitementPipelineCount,
      recruiterName: state.getRecruitementPipelineReducer.recruiterName,
      loading: state.getRecruitementPipelineReducer.loading,
      countLoading: state.getRecruitementPipelineReducer.countLoading,
      nameLoading: state.getRecruitementPipelineReducer.nameLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchText.length === 0 || searchText.length >= 2) {
        let payload = {
          searchText: searchText,
        };
        dispatch(getRecruiterNameData(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchText]);

  useEffect(() => {
    fetchRecruiterData(null, 1, 10, 0, "", sortArray);
    const payload = {
      tenantId: 0,
      searchText: "",
    };
    let namePayload = {
      searchText: searchText,
    };
    dispatch(getRecruiterNameData(namePayload));
    dispatch(getRecruitementPipelineCount(payload));
  }, []);

  const fetchRecruiterData = (
    status,
    pageNumber,
    limit,
    tenantId,
    searchVal,
    sortArray
  ) => {
    const payload = {
      status: status,
      currentPage: pageNumber,
      limit: limit,
      tenantId: tenantId,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getRecruitementPipeline(payload));
  };

  useEffect(() => {
    setCurrentPage(1);
    if (recruitementPipelineCount.length > 0) {
      let perc = recruitementPipelineCount[0]["Percentage"];
      setPercentage(perc);
    }
    if (active == "Total") {
      recruitementPipelineCount.length > 0 && setDataTotal(getCount("Total"));
      setDataSize(dataSize);
    } else if (active == "Invited") {
      recruitementPipelineCount.length > 0 && setDataTotal(getCount("Invited"));
      setDataSize(dataSize);
    } else if (active == "Automatched") {
      recruitementPipelineCount.length > 0 &&
        setDataTotal(getCount("Automatched"));
      setDataSize(dataSize);
    } else if (active == "Submitted") {
      recruitementPipelineCount.length > 0 &&
        setDataTotal(getCount("Submitted"));
      setDataSize(dataSize);
    } else if (active == "Shortlisted") {
      recruitementPipelineCount.length > 0 &&
        setDataTotal(getCount("Shortlisted"));
      setDataSize(dataSize);
    } else if (active == "Offered") {
      recruitementPipelineCount.length > 0 && setDataTotal(getCount("Offered"));
      setDataSize(dataSize);
    } else if (active == "Hired") {
      recruitementPipelineCount.length > 0 && setDataTotal(getCount("Hired"));
      setDataSize(dataSize);
    } else {
      recruitementPipelineCount.length > 0 &&
        setDataTotal(getCount("Disqualified"));
      setDataSize(dataSize);
    }
  }, [recruitementPipelineCount, active]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        getFetchRecruiterApi(
          active,
          1,
          dataSize,
          selectedRecruiter,
          searchData
        );
        const payload = {
          tenantId: selectedRecruiter == 0 ? 0 : selectedRecruiter,
          searchText: searchData,
        };
        dispatch(getRecruitementPipelineCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  useEffect(() => {
    if (recruiterName.length > 0) {
      let op = recruiterName?.map((item, index) => ({
        value: item.id,
        label: item?.RecruiterName.replace("GIG - ", ""),
      }));
      setOptions([{ value: 0, label: "All" }, ...op]);
    }
  }, [recruiterName]);

  const getFetchRecruiterApi = (
    status,
    page,
    limit,
    tenant,
    search,
    sortArray
  ) => {
    if (status == "Total") {
      fetchRecruiterData(null, page, limit, tenant, search, sortArray);
    } else if (status == "Invited") {
      fetchRecruiterData(33, page, limit, tenant, search, sortArray);
    } else if (status == "Automatched") {
      fetchRecruiterData(38, page, limit, tenant, search, sortArray);
    } else if (status == "Submitted") {
      fetchRecruiterData(6, page, limit, tenant, search, sortArray);
    } else if (status == "Shortlisted") {
      fetchRecruiterData(8, page, limit, tenant, search, sortArray);
    } else if (status == "Offered") {
      fetchRecruiterData(37, page, limit, tenant, search, sortArray);
    } else if (status == "Hired") {
      fetchRecruiterData(11, page, limit, tenant, search, sortArray);
    } else {
      fetchRecruiterData(12, page, limit, tenant, search, sortArray);
    }
  };

  const tabChange = (status) => {
    setCurrentPage(1);
    setActive(status);
    getFetchRecruiterApi(status, 1, 10, selectedRecruiter, searchData, []);
    setDataSize(10);
  };

  const getCount = (status) => {
    let count = 0;
    if (recruitementPipelineCount.length > 0) {
      count = recruitementPipelineCount[0][status];
    }
    return count;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getFetchRecruiterApi(
      active,
      pageNumber,
      dataSize,
      selectedRecruiter,
      searchData
    );
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    getFetchRecruiterApi(
      active,
      1,
      ds,
      selectedRecruiter,
      searchData,
      sortArray
    );
  };

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  const handleChange = (selected) => {
    isFirstRender.current = true;
    setSelectedOption(selected);
    setSearchData("");
    const tenantId = selected.value;
    getFetchRecruiterApi(active, 1, 10, tenantId, "");
    const payload = {
      tenantId: tenantId == 0 ? null : tenantId,
      searchText: "",
    };
    setDataSize(10);
    dispatch(getRecruitementPipelineCount(payload));
    setSelectedRecruiter(tenantId);
  };

  const handleChangeInput = (e) => {
    setSearchText(e);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchRecruiterData(
      null,
      1,
      dataSize,
      selectedRecruiter,
      searchData,
      sortedData
    );
  };

  return (
    <div>
      {(loading || countLoading || nameLoading) && <Loader />}
      <div className=" card">
        <div className="card-body  px-4 pb-4 ">
          <div className="d-flex justify-content-between mb-4 border-bottom align-items-center pb-2">
            <h6>Recruitment Pipeline</h6>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ width: 200 }}>
                <Select
                  options={options}
                  isSearchable={true}
                  onChange={handleChange}
                  styles={styleSheet}
                  value={selectedOption}
                  onInputChange={handleChangeInput}
                />
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto gap-5 justify-content-between">
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Overall count of talents in the recruitment pipeline, including newly invited. This doesn't represent the TOTAL count of Talent registered in the High5 / Recruiters community."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Total" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Total");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Total"))}
                </span>
              </div>
              <span className="">Total Talents</span>
            </a>
            <a
              title="No. of invited talents for any jobs."
              href="#"
              data-bs-toggle="tooltip"
              data-bs-html="true"
              className={`d-flex flex-column gap-1 ${
                active === "Invited" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Invited");
              }}
            >
              <div>
                <div>
                  <span className="tag">
                    {numberFormatter.format(getCount("Invited"))}
                  </span>
                </div>
              </div>
              <span className="">Invited</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No.of talents automatically matched and invited 
                to apply for a job based on predefined criteria in the Job Description."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Automatched" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Automatched");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Automatched"))}
                </span>
              </div>
              <span className="">Automatched</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of talents who have submitted their applications for job positions. "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Submitted" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Submitted");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Submitted"))}
                </span>
              </div>
              <span className="">Submitted</span>{" "}
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of talents shortlisted for jobs in High5Hire."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Shortlisted" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Shortlisted");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Shortlisted"))}
                </span>
              </div>
              <span className="">Shortlisted</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of Talents who got Job offers from High5Hire"
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Offered" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Offered");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Offered"))}
                </span>
              </div>
              <span className="">Offered</span>
            </a>

            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of talents accepting job offers and awaiting the formal onboarding process."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Hired" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Hired");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Hired"))}
                </span>
              </div>
              <span className="">Hired</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Total no. of disqualified talents. "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Disqualified" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Disqualified");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Disqualified"))}
                </span>
              </div>
              <span className="">Disqualified</span>
            </a>
            <div
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="
                (total count of hired / (total count of shortlisted + total count of hired)) * 100"
              className="d-flex flex-column gap-1"
              style={{ textAlign: "center" }}
            >
              <div>
                <span className="tag">{percentage}%</span>
              </div>
              <span className="">Success %</span>
            </div>
          </div>
        </div>
      </div>
      <TalentsTable
        talents={recruitementPipelineData}
        isFrom={"RecPipe"}
        prefix={active}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        currentPage={currentPage}
        handleSearch={handleSearch}
        searchData={searchData}
        tenantId={selectedRecruiter == 0 ? 0 : selectedRecruiter}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        fetchData={getFetchRecruiterApi}
      />
    </div>
  );
};

export default RecruitmentPipeline;
