import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { post } from "../../../../service/ApiService";
import Loader from "../../../common/loader/Loader";
import Pagination from "../../../common/pagination/Pagination";
import ProfileImage from "../../../common/profileimage/ProfileImage";
// import TalentProfile from "../../../talent/talentprofile/TalentProfile";
// import Sort from "../../../commoncomponents/sort/Index";
// import { recruiterPipelineObj, orderList } from "../../../pagedata/PageData";

const TalentTable = ({
  isFrom,
  prefix,
  talents,
  dataTotal,
  dataSize,
  handleSizeChange,
  handlePageChange,
  currentPage,
  handleSearch,
  searchData,
  tenantId,
  fetchData,
  sortArray,
  setSortArray,
  applySort,
}) => {
  const history = useHistory();

  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  //   useEffect(() => {
  //     const tempArray = recruiterPipelineObj.map((item) => item.value);
  //     setSorttype(tempArray);
  //   }, []);

  //   const handleModel = () => {
  //     setShowCandidateProfile(false);
  //   };

  const onProfileClick = async (candidate) => {
    try {
      if (isFrom === "TalentPool") setCandidateData(candidate);
      else {
        setLoading(true);
        let x = await post("/talent/getcandidateprofile", candidate);
        setLoading(false);
        x?.data?.message === "No data"
          ? setCandidateData(candidate)
          : setCandidateData({
              ...x?.data,
              RecruiterName: candidate.RecruiterName,
              statusname: candidate.statusname,
            });
      }
      setShowCandidateProfile(true);
      setCandidateId(candidate.userId);
    } catch (error) {
      setLoading(false);
    }
    setShowCandidateProfile(true);
    setCandidateId(candidate.userId);
  };

  const onJobClick = async (jobId) => {
    history.push(`/high5Hire/requests/${jobId}`);
  };

  useEffect(() => {
    setCandidateData({});
    setShowCandidateProfile(false);
    setCandidateId("");
    // resetSort();
  }, [prefix]);

  //   const resetSort = () => {
  //     setSortArray([]);
  //     const tempArray = recruiterPipelineObj?.map((item) => item.value);
  //     setSorttype(tempArray);
  //     setSortCount(0);
  //     setSortApplied(false);
  //     setSort(false);
  //   };

  //   const sortClearAll = () => {
  //     resetSort();
  //     fetchData(prefix, 1, dataSize, tenantId, searchData, []);
  //   };

  return (
    <div className="card mt-3">
      {loading && <Loader />}
      <div className="card-body">
        <div class="p-3">
          <div class=" mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{prefix ? prefix : "Talents"} </h6>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  onChange={handleSizeChange}
                  value={dataSize}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <div class="mb-2 mr-1 d-flex align-items-center justify-content-end col-md-8">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Talents"
                      className="form-control small font-14"
                      value={searchData}
                      onChange={handleSearch}
                    />
                  </div>
                  {/* <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={talents}
                    orderArray={orderList}
                    sortObjects={recruiterPipelineObj}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Name, Email, and Job Title"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3 table-cards-1024">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Job Id</th>
                  <th scope="col">Created by</th>
                  <th scope="col">
                    {prefix === "Total"
                      ? "Last updated"
                      : prefix === "Hired"
                      ? "Acceptance date"
                      : prefix === "Offered"
                      ? "Offer date"
                      : prefix === "Shortlisted"
                      ? "Last updated"
                      : prefix === "Submitted"
                      ? "Submitted on"
                      : prefix === "Invited"
                      ? "Invited on"
                      : "Last updated"}
                  </th>
                  {isFrom !== "TalentPool" && prefix === "Total" && (
                    <th scope="col">Status</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {talents &&
                  talents?.length > 0 &&
                  talents.map((item) => (
                    <tr>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => onProfileClick(item)}
                      >
                        <div class="avatar-name-candidatestableview d-flex align-items-center">
                          <div>
                            <span>
                              <ProfileImage
                                imageSrc={item?.profileImagePath}
                                imageWidth={"35"}
                                imageHeight={"35"}
                                avatarSize={"35"}
                                avatarRound={"80px"}
                                firstName={item?.firstName}
                                lastName={item?.lastName}
                              />
                            </span>
                          </div>
                          <div class="ml-3">
                            <strong class="mb-0 me-2">
                              {item.firstName?.charAt(0)?.toUpperCase() +
                                item.firstName?.slice(1)}{" "}
                              {item.lastName?.charAt(0)?.toUpperCase() +
                                item.lastName?.slice(1)}{" "}
                            </strong>
                          </div>
                        </div>
                        {/* <span>{item.firstName + " " + item.lastName}</span> */}
                      </td>

                      <td>{item?.email}</td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => onJobClick(item.jobId)}
                      >
                        {item?.jobTitle}
                      </td>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => onJobClick(item.jobId)}
                      >
                        {item?.jobId}
                      </td>
                      <td>
                        {item.RecruiterName === "High Five"
                          ? "High5"
                          : item.RecruiterName}
                      </td>

                      <td>
                        <span>
                          {moment(new Date(item.updatedDateTime)).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </td>
                      {isFrom !== "TalentPool" && prefix === "Total" && (
                        <td>
                          <strong>
                            {item.statusname === "Invited" ? (
                              <span className="tag  tag-blue3">Invited</span>
                            ) : item.statusname === "Automatched" ? (
                              <span className="tag  tag-green1">
                                Automatched
                              </span>
                            ) : item.statusname === "Submitted" ? (
                              <span className="tag  tag-green3">Submitted</span>
                            ) : item.statusname === "Shortlisted" ? (
                              <span className="tag  tag-blue1">
                                Shortlisted
                              </span>
                            ) : item.statusname === "Offered" ? (
                              <span className="tag  tag-green2">Offered</span>
                            ) : item.statusname === "Hired" ? (
                              <span className="tag  tag-green4">Hired</span>
                            ) : item.statusname === "Rejected" ? (
                              <span className="tag  tag-red2">
                                Disqualified
                              </span>
                            ) : item.statusname === "Offer Rejected" ? (
                              <span className="tag  tag-red2">
                                Offer Declined
                              </span>
                            ) : (
                              <span className="tag  ">{item.statusname}</span>
                            )}
                          </strong>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {(!talents || talents?.length === 0) && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
            {talents && talents?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            )}
          </div>
        </div>
      </div>
      {/* {showCandidateProfile && (
        <TalentProfile
          candidateId={candidateId}
          candidateData={candidateData}
          handleSuccess={handleModel}
          parent={isFrom}
          setShowCandidateProfile={setShowCandidateProfile}
        />
      )} */}
    </div>
  );
};

export default TalentTable;
