import React from "react";
import moment from "moment";

const TitleAndStartDate = (props) => {
  return (
    <>
      {props?.addTemplate || props?.editTemplate ? (
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className={
                      props?.isFormValid && props?.addJobData.title === ""
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    name="title"
                    placeholder="Enter a job title"
                    onChange={props?.handleChange}
                    value={props?.addJobData.title}
                    disabled={props?.edit}
                  />
                  <label>Job Title*</label>
                </div>
                {props?.isFormValid && props?.addJobData.title === "" && (
                  <small className="ml-2 validation">*Required field</small>
                )}
              </div>
              <div className="col-lg-6">
                <div className="form-floating ml-1">
                  <select
                    className="form-select"
                    onChange={(e) => props?.setTemplateCategory(e.target.value)}
                  >
                    <option disabled>Select</option>
                    {props?.skillSetOption
                      .map((i) => i.value)
                      .map((item, index) => (
                        <option
                          key={index}
                          selected={item === props?.templateCategory}
                          value={item}
                        >
                          {item}
                        </option>
                      ))}
                  </select>
                  <label>Template Category</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card card-flat bg-gray4 mb-3 mx-3">
          <div className="card-body py-0">
            <div className="row my-3">
              <div className="col-lg-5">
                <div className="form-floating">
                  <input
                    type="text"
                    className={
                      props?.isFormValid && props?.addJobData.title === ""
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    name="title"
                    placeholder="Enter a job title"
                    onChange={props?.handleChange}
                    value={props?.addJobData.title}
                    disabled={props?.preview || props?.edit}
                  />
                  <label>Job title*</label>
                </div>
                {props?.isFormValid && props?.addJobData.title === "" && (
                  <small className="validation ml-2">*Required field</small>
                )}
              </div>
              <div className="col-lg-3 mt-2">
                <div className="form-check form-switch d-flex align-items-center">
                  <label className="form-check-label pt-0">Hot job?</label>
                  <input
                    name="isHotJob"
                    className="form-check-input ml-2"
                    type="checkbox"
                    id="hotJobSwitch"
                    checked={props?.addJobData.isHotJob}
                    onChange={(e) =>
                      !props?.preview &&
                      props?.setAddJobData({
                        ...props?.addJobData,
                        isHotJob: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating">
                  <input
                    type="date"
                    name="startDate"
                    value={moment(new Date(props?.addJobData.startDate)).format(
                      "YYYY-MM-DD"
                    )}
                    //value={addJobData.startDate}
                    onChange={props?.handleChange}
                    className={
                      props?.addJobVal.startDateError
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    min={moment(new Date(props?.addJobData.activeFrom)).format(
                      "YYYY-MM-DD"
                    )}
                    max="9999-12-31"
                    // pattern="\d{4}-\d{2}-\d{2}"
                  />
                  <label>Preferred start date*</label>
                </div>
                {props?.addJobVal.startDateError && (
                  <small className="validation">
                    {props?.addJobVal.startDateError}
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TitleAndStartDate;
