import React, { useEffect, useState } from "react";
import AdditionalInfo from "./AdditionalInfo";
import { useHistory, useParams } from "react-router-dom";
import logo from "../../assets/images/High5Logo.png";
import { post } from "../../service/ApiService";
import Loader from "../common/loader/Loader";
import { toast } from "react-toastify";

const AdditionalInfoReminder = () => {
  const { id, userId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [screeningData, setScreeningData] = useState([]);
  const [screeningQuestions, setScreeningQuestions] = useState([
    {
      name: "",
      question: "",
      answer: "",
    },
  ]);
  const [salaryValues, setSalaryValues] = useState({ min: "", max: "" });
  const [errors, setError] = useState({});

  useEffect(() => {
    getPoolById();
  }, []);

  const getPoolById = async () => {
    const params = { poolId: id, userId: userId };
    setLoading(true);
    await post("/talent/getAdditionalInfo", params).then(async (res) => {
      if (res?.data[0]?.isAICompleted) {
        toast.success("You have already completed Additional Info!");
        history.push(`/career/home`);
        setScreeningData([]);
        setLoading(false);
      } else {
        const screening = res?.data[0]?.additionalInfo;
        setScreeningData(screening);
        setLoading(false);
      }
    });
  };

  const validationHandler = () => {
    let formIsValid = true;
    let errorData = {};

    if (screeningQuestions.some((x) => x.answer === "")) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    } else if (
      screeningQuestions.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    }
    if (
      screeningQuestions.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter minimum salary");
    }
    if (
      screeningQuestions.some(
        (x) =>
          x.name === "Salary Requirements" &&
          x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter maximum salary");
    }
    if (parseInt(salaryValues.min) >= parseInt(salaryValues.max)) {
      formIsValid = false;
      toast.error("Minimum value must be smaller than maximum value.");
    }

    setError(errorData);
    let resultObj = {
      errorData: errorData,
      isValid: formIsValid,
    };
    return resultObj;
  };

  const screeningSubmit = async () => {
    const validObj = validationHandler();
    if (validObj.isValid) {
      const data = {
        screeningQuestions: JSON.stringify(screeningQuestions),
        poolId: id,
        candidateId: userId,
      };
      setLoading(true);
      await post("/careerspool/updateAdditionalInfo", data);
      setLoading(false);
      history.push(`/career/home`);
    }
  };

  return (
    <>
      <div className="mx-4 mt-5">
        {loading && <Loader />}
        <div className="my-2">
          <img
            className="mb-2 my-auto"
            style={{ height: "40px", width: "75px" }}
            src={logo}
            alt="logo"
          />
        </div>
        <hr />
        {screeningData?.length>0 && (
          <div>
            <h6 className="m-2">
              Please answer the following Additional Info Screening question(s)
              and complete your application.
            </h6>
            <AdditionalInfo
              screeningQuestions={screeningQuestions}
              setScreeningQuestions={setScreeningQuestions}
              salaryValues={salaryValues}
              setSalaryValues={setSalaryValues}
              screeningData={screeningData}
            />
            <div className="d-flex justify-content-end mt-5 mb-5 mr-5  px-3">
              <button onClick={screeningSubmit} className="btn btn-md">
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdditionalInfoReminder;
