import React from "react";

const MenuToggle = ({ toggle, setToggle }) => {
  return (
    <div className="nav-toggle-wrap">
      <button
        id="MainMenuToggle"
        onClick={() => setToggle(!toggle)}
        type="button"
        className="btn btn-icon nav-toggle btn-nav"
      >
        <i className="fal fa-angle-left" aria-hidden="true"></i>
      </button>
      <div className="divider"></div>
    </div>
  );
};

export default MenuToggle;
