import {
  GET_DASBOARD_COUNT_BEGIN,
  GET_DASBOARD_COUNT_SUCCESS,
  GET_DASBOARD_COUNT_FAILURE,
} from "../../../action/dashboard/superadmin/Dashboard";

const initialState = {
  loading: true,
  dashboardCount: null,
};

const getDashboardCountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DASBOARD_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        dashboardCount: null,
      };
    case GET_DASBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardCount: payload,
        loading: false,
      };
    case GET_DASBOARD_COUNT_FAILURE:
      return {
        ...state,
        dashboardCount: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default getDashboardCountReducer;
