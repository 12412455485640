import React from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteResume = ({
  showConfirmation,
  setShowConfirmation,
  resumeFile,
  onRemove,
}) => {
  return (
    <Modal show={showConfirmation} size="md" animation={true}>
      <Modal.Header>
        <button
          type="button"
          onClick={() => setShowConfirmation(false)}
          className="close p-0 bl-modal-close-btn closable"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="fal fa-times closable"></i>
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="m-5">
          <div class="text-center">
            <div class="avatar avatar-lg avatar-red2">
              <i
                class="fas fa-exclamation-triangle fa-fw"
                aria-hidden="true"
              ></i>
            </div>
            <p class="text-center lead mt-3">
              Are you sure you want to delete resume?
              <br />
              {resumeFile.fileName}
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div class="d-flex align-items-center justify-content-center">
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onRemove} className="ml-2">
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteResume;
