import React from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import { recipients } from "../../../../constants/Constants";

const EditTemplate = (props) => {
  const {
    selectedEmailData,
    editedValues,
    handleUpdateClick,
    isValid,
    setEditedValues,
    editMode,
    handleCancelClick,
    createNew,
    recipient,
    setRecipient,
  } = props;

  const handleInputChange = (field, value) => {
    setEditedValues({ ...editedValues, [field]: value });
  };
  const handleEditorChange = (value, field) => {
    if (editedValues[field] !== value) {
      setEditedValues({ ...editedValues, [field]: value });
    }
  };
  return (
    <>
      <form onSubmit={handleUpdateClick} className="mt-0">
        {!props.createNew && (
          <>
            <label htmlFor="editSubject" className="form-label mt-0">
              <b>
                Recipient Persona <start style={{ color: "red" }}>*</start>
              </b>
            </label>
            <input
              type="text"
              className="form-control"
              id="editSubject"
              style={{
                border:
                  isValid &&
                  selectedEmailData?.recipients[0] === "" &&
                  "2px solid #ff0000",
                // backgroundColor: "#F6F6F6",
                borderRadius: "0px",
                fontSize: "0.9em",
              }}
              value={selectedEmailData?.recipients[0]}
              disabled={createNew ? false : true}
            />
          </>
        )}{" "}
        {props.createNew && (
          <>
            <label htmlFor="editSubject" className="form-label mt-0">
              <b>
                Recipient Persona <start style={{ color: "red" }}>*</start>
              </b>
            </label>
            <Select
              options={recipients}
              onChange={setRecipient}
              value={recipient}
              // isMulti={true}
            />
          </>
        )}
        <label htmlFor="editSubject" className="form-label">
          <b>
            Subject <start style={{ color: "red" }}>*</start>
          </b>
        </label>
        <input
          type="text"
          className="form-control"
          id="editSubject"
          style={{
            border:
              isValid && editedValues.mailSubject === "" && "2px solid #ff0000",
            fontSize: "0.9em",
          }}
          value={editedValues.mailSubject}
          onChange={(e) => handleInputChange("mailSubject", e.target.value)}
          disabled={!editMode}
        />
        {isValid && editedValues.mailSubject === "" && (
          <small className="validation">*Required Field</small>
        )}
        <div className="row d-flex justify-content-between p-2">
          <label htmlFor="editContent" className="col-md-4 form-label">
            <b>
              Content <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          {/* <select
            className="col-md-4 mr-2 form-select w-25"
            style={{
              fontSize: "14px",
              border: "1px solid #ddd",
              height: "40px",
              boxShadow: "none",
            }}
          >
            <option value="">Insert Dynamic Fields</option>
            <option value="mcq">Multiple Choice Questionaire</option>
          </select> */}
        </div>
        <ReactQuill
          value={editedValues.mailContent}
          style={{
            border:
              isValid && editedValues.mailContent === "" && "2px solid #ff0000",
          }}
          onChange={(value) => handleEditorChange(value, "mailContent")}
          theme="snow"
          readOnly={!editMode}
        />
        {isValid && editedValues.mailContent === "" && (
          <small className="validation">*Required Field</small>
        )}
        <div className="mb-2">
          <label htmlFor="editFooter" className="form-label">
            <b>
              Footer <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <ReactQuill
            value={editedValues.footer}
            style={{
              border:
                isValid && editedValues.footer === "" && "2px solid #ff0000",
            }}
            onChange={(value) => handleEditorChange(value, "footer")}
            readOnly={!editMode}
            theme="snow"
            // style={{ lineHeight: "0" }}
          />
          {isValid && editedValues.footer === "" && (
            <small className="validation">*Required Field</small>
          )}
        </div>
        {!createNew && (
          <div className="mb-2">
            <button type="submit" className="btn btn-primary btn-sm">
              Update
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2 btn-sm"
              onClick={() => handleCancelClick()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default EditTemplate;
