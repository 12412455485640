import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import NoRecord from "../../../common/NoRecord";
import Pagination from "../../../common/pagination/Pagination";

const Community = ({ communities, pools }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 5;
  const endIndex = startIndex + 5;
  const currentData = pools.slice(startIndex, endIndex);

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  return (
    <>
      {communities && (
        <>
          <div className="mt-3 p-2">
            {pools.length > 0 ? (
              <Table hover>
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Community Name</th>
                    <th>Community Id</th>
                    <th>Assigned on</th>
                  </tr>
                </thead>
                <tbody style={{ overflow: "auto" }}>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.tenantname}</td>
                      <td>
                        <span className="tag tag-blue3">{item?.poolname}</span>
                      </td>
                      <td>{item?.poolId}</td>
                      <td>
                        {moment(item?.createddatetime).format("MM/DD/YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoRecord />
            )}
            <Pagination
              currentPage={currentPage}
              totalItems={pools.length}
              itemsPerPage={5}
              onPageChange={handlePageChange}
              limit={5}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Community;
