import React from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";

const ProfileInfo = ({
  data,
  setData,
  validation,
  setValidation,
  handleChange,
  handleLocation,
  educationsOptions,
  certificationOptions,
}) => {
  const handleChangeMultiSelect = (id, value) => {
    if (id === "education" && validation.education) {
      setValidation({ ...validation, education: "" });
    } else {
      setData({ ...data, [id]: value });
    }
  };

  return (
    <>
      <Row>
        <Col className="mt-3">
          <div className="form-floating">
            <input
              type="url"
              className={
                validation.city
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="City*"
              id="city"
              onChange={handleLocation}
              value={data.location.city}
            />
            <label>City*</label>
          </div>
          {validation.city && (
            <small className="validation ml-2">{validation.city}</small>
          )}
        </Col>
        <Col className="mt-3">
          <div className="form-floating">
            <input
              type="url"
              className={
                validation.state
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="State*"
              id="state"
              onChange={handleLocation}
              value={data.location.state}
            />
            <label>State*</label>
          </div>
          {validation.state && (
            <small className="validation ml-2">{validation.state}</small>
          )}
        </Col>
        <Col className="mt-3">
          <div className="form-floating">
            <input
              type="text"
              className={
                validation.country
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="Country*"
              id="country"
              onChange={handleLocation}
              value={data.location.country}
            />
            <label>Country*</label>
          </div>
          {validation.country && (
            <small className="validation ml-2">{validation.country}</small>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <label>Education*</label>
          <div
            className="form-floating Hover-Dropdown"
            style={{ zIndex: "999" }}
          >
            <Select
              options={educationsOptions}
              name="Education"
              className={validation.education && "border border-danger"}
              isSearchable={true}
              onChange={(val) =>
                handleChangeMultiSelect("education", val.value)
              }
            ></Select>
            <small className="validation ml-2">{validation.education}</small>
          </div>
        </Col>
        <Col>
          <label>Certification*</label>
          <div
            className="form-floating Hover-Dropdown"
            style={{ zIndex: "999" }}
          >
            <Select
              isMulti
              options={certificationOptions}
              name="Certification"
              className={validation.certification && "border border-danger"}
              isSearchable={true}
              onChange={(value) =>
                handleChangeMultiSelect(
                  "certification",
                  value?.map((i) => i.value)
                )
              }
            ></Select>

            <small className="validation ml-2">
              {validation.certification}
            </small>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="form-floating mt-3">
            <input
              type="url"
              className={
                validation.linkedIn
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="Linkedin URL*"
              id="linkedIn"
              onChange={handleChange}
              value={data.linkedIn}
            />
            <label>Linkedin URL</label>
          </div>
          {validation.linkedIn && (
            <small className="validation ml-2">{validation.linkedIn}</small>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileInfo;
