import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";

export const FETCH_CAREER_FILTER_JOB_LIST_BEGIN =
  "FETCH_CAREER_FILTER_JOB_LIST_BEGIN";
export const FETCH_CAREER_FILTER_JOB_LIST_SUCCESS =
  "FETCH_CAREER_FILTER_JOB_LIST_SUCCESS";
export const FETCH_CAREER_FILTER_JOB_LIST_FAILURE =
  "FETCH_CAREER_FILTER_JOB_LIST_FAILURE";

export const fetchCareerFilterJobList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CAREER_FILTER_JOB_LIST_BEGIN,
    });
    const res = await post("/disposition/career/getfilteredjobs", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_CAREER_FILTER_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CAREER_FILTER_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const GET_CAREER_JOB_COUNT_BEGIN = "GET_CAREER_JOB_COUNT_BEGIN";
export const GET_CAREER_JOB_COUNT_SUCCESS = "GET_CAREER_JOB_COUNT_SUCCESS";
export const GET_CAREER_JOB_COUNT_FAILURE = "GET_CAREER_JOB_COUNT_FAILURE";

export const getCareerJobCount = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CAREER_JOB_COUNT_BEGIN,
    });
    const res = await post(
      "/disposition/career/getfilteredjobsCount",
      payloadObj
    );
    if (res.status === 200) {
      dispatch({
        type: GET_CAREER_JOB_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CAREER_JOB_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN =
  "FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN";
export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS =
  "FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS";
export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE =
  "FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE";

export const fetchCareerFilterSavedJobList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN,
    });
    const res = await post("/disposition/career/getfilteredsavedjobs", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const GET_SAVED_CAREER_JOB_COUNT_BEGIN =
  "GET_SAVED_CAREER_JOB_COUNT_BEGIN";
export const GET_SAVED_CAREER_JOB_COUNT_SUCCESS =
  "GET_SAVED_CAREER_JOB_COUNT_SUCCESS";
export const GET_SAVED_CAREER_JOB_COUNT_FAILURE =
  "GET_SAVED_CAREER_JOB_COUNT_FAILURE";

export const getSavedJobCareerCount = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SAVED_CAREER_JOB_COUNT_BEGIN,
    });
    const res = await post(
      "/disposition/career/getfilteredsavedjobsCount",
      payloadObj
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SAVED_CAREER_JOB_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SAVED_CAREER_JOB_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
