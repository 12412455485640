import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_JOBS_HEADER_COUNT_BEGIN = "GET_JOBS_HEADER_COUNT_BEGIN";
export const GET_JOBS_HEADER_COUNT_SUCCESS = "GET_JOBS_HEADER_COUNT_SUCCESS";
export const GET_JOBS_HEADER_COUNT_FAILURE = "GET_JOBS_HEADER_COUNT_FAILURE";

export const GET_COPY_CLONE_JOBS_HEADER_COUNT_BEGIN =
  "GET_COPY_CLONE_JOBS_HEADER_COUNT_BEGIN";
export const GET_COPY_CLONE_JOBS_HEADER_COUNT_SUCCESS =
  "GET_COPY_CLONE_JOBS_HEADER_COUNT_SUCCESS";
export const GET_COPY_CLONE_JOBS_HEADER_COUNT_FAILURE =
  "GET_COPY_CLONE_JOBS_HEADER_COUNT_FAILURE";

export const getJobListCount = (param) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBS_HEADER_COUNT_BEGIN,
    });
    const res = await post("/job/getJobListCounts", param);
    if (res.status === 200) {
      dispatch({
        type: GET_JOBS_HEADER_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOBS_HEADER_COUNT_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCopyCloneJobListCount = (param) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COPY_CLONE_JOBS_HEADER_COUNT_BEGIN,
    });
    const res = await post("/job/getJobListCounts", param);
    if (res.status === 200) {
      dispatch({
        type: GET_COPY_CLONE_JOBS_HEADER_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COPY_CLONE_JOBS_HEADER_COUNT_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
