import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  experienceOptions,
  jobTypeOptions,
  educationOptions,
  visaStatus,
  additionalVisaStatus,
  countryList,
  subscriptionStatusOption,
  dispositionOptions,
} from "../../../../constants/Constants";
import { getTenantType, getUserRole } from "../../../../service/AuthService";
import DDMS from "../../../filter/DDMSFilter";
import BooleanFilter from "../../../filter/BooleanFilter";
import LocationFilter from "../../../filter/LocationFilter";
import PayRateFilter from "../../../filter/PayRateFilter";
import { getTalentNameFilterData } from "../../../../action/filter/talent/TalentNameFilter";
import { getTalentStateFilter } from "../../../../action/filter/talent/TalentStateFilter";
import { getTalentCountryFilter } from "../../../../action/filter/talent/TalentCountryFilter";
import { getTalentJobTitleFilter } from "../../../../action/filter/talent/TalentJobTitleFilter";
import { getTalentSourceFilter } from "../../../../action/filter/talent/TalentSourceFilter";
import { getTalentTagFilter } from "../../../../action/filter/talent/TalentTagFilter";
import { getTalentRecruiterFilter } from "../../../../action/filter/talent/TalentRecruiterFilter";

const Index = ({
  selectedData,
  setSelectedData,
  filterLength,
  filterParams,
  setFilterParams,
  isFrom,
  keywordList,
  setKeywordList,
  skillInputList,
  setSkillInputList,
  parentTab,
  isFilterApplied,
  setIsFilterApplied,
  applyFilter,
  handleClear
}) => {
  const dispatch = useDispatch();
  const [collapsible, setCollapsible] = useState({
    talentName: false,
    skills: false,
    city: false,
    state: false,
    country: false,
    experienceLevel: false,
    educations: false,
    keywordSearch: false,
    jobTitle: false,
    jobType: false,
    payRate: false,
    workAuthorisation: false,
    subscriptionStatus: false,
    tags: false,
    sourceChannel: false,
    recruiterName: false,
    dispositionStatus: false,
  });
  const [talentNameOptions, setTalentNameOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [sourceChannelsOptions, setSourceChannelsOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [internationalPlacementOption, setInternationalPlacementOption] =
    useState([]);
  const [recruiterOptions, setRecruiterOptions] = useState([]);

  const tagFilterRole = [
    "Recruiter",
    "SuperAdmin",
    "HiringManager",
    "Admin",
    "CustomerSuccess",
  ];
  const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
  const workAuthorisationOptions = Array.from(
    new Set(combinedVisaStatus.map(JSON.stringify))
  ).map(JSON.parse);
  const recruiterFilter =
    getUserRole() !== "Recruiter"
      ? getTenantType() === 1
        ? ["38", "33", "42", "40", "43"]
        : ["42", "40"]
      : [];

  const {
    talentNameLoading,
    talentNameData,
    talentStateData,
    talentStateLoading,
    talentCountryData,
    talentCountryLoading,
    talentJobTitleData,
    talentJobTitleLoading,
    talentSourceData,
    talentSourceLoading,
    talentTagData,
    talentTagLoading,
    talentRecruiterData,
    talentRecruiterLoading,
  } = useSelector(
    (state) => ({
      talentNameData: state.getTalentNameReducer.talentNameData,
      talentNameLoading: state.getTalentNameReducer.talentNameLoading,
      talentStateData: state.getTalentStateReducer.stateData,
      talentStateLoading: state.getTalentStateReducer.stateLoading,
      talentCountryData: state.getTalentCountryReducer.countryData,
      talentCountryLoading: state.getTalentCountryReducer.countryLoading,
      talentJobTitleData: state.getTalentJobTitleReducer.jobTitleData,
      talentJobTitleLoading: state.getTalentJobTitleReducer.jobTitleLoading,
      talentSourceData: state.getTalentSourceReducer.sourceData,
      talentSourceLoading: state.getTalentSourceReducer.sourceLoading,
      talentTagData: state.getTalentTagReducer.tagData,
      talentTagLoading: state.getTalentTagReducer.tagLoading,
      talentRecruiterData: state.getTalentRecruiterReducer.recruiterData,
      talentRecruiterLoading: state.getTalentRecruiterReducer.recruiterLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setInternationalPlacementOption(
      countryList.map((e) => ({ value: e, label: e }))
    );
  }, []);

  useEffect(() => {
    if (talentNameData && talentNameData.length > 0) {
      const formattedOptions = talentNameData?.map((option) => ({
        label: option.firstName.trim() + " " + option.lastName.trim(),
        value: option.firstName.trim() + " " + option.lastName.trim(),
      }));
      setTalentNameOptions(formattedOptions);
    }
    if (talentStateData && talentStateData.length > 0) {
      const formattedStates = talentStateData?.map((item) => ({
        label: item.state + " " + `(${item.talentCount})`,
        value: item.state,
        count: item.talentCount,
      }));
      setStateOptions(formattedStates);
    }
    if (talentCountryData && talentCountryData.length > 0) {
      const formattedCountries = talentCountryData?.map((item) => ({
        label: item.country + " " + `(${item.talentCount})`,
        value: item.country,
        count: item.talentCount,
      }));
      setCountryOptions(formattedCountries);
    }
    if (talentJobTitleData && talentJobTitleData?.length > 0) {
      setJobTitleOptions(
        talentJobTitleData?.map((item) => ({
          value: item.designation,
          label: item.designation + " " + `(${item.TotalCount})`,
        }))
      );
    }
    if (talentSourceData && Object.keys(talentSourceData).length > 0) {
      let sourceArray = [];

      for (const key in talentSourceData) {
        if (Object.hasOwnProperty.call(talentSourceData, key)) {
          const value = talentSourceData[key];
          sourceArray = [
            ...sourceArray,
            {
              label: `${key} (${value})`,
              value: key,
            },
          ];
        }
      }
      setSourceChannelsOptions(sourceArray);
    }
    if (talentTagData && talentTagData.length > 0) {
      setTagOptions(
        talentTagData?.map((item) => {
          return { value: item, label: item };
        })
      );
    }
    if (talentRecruiterData && talentRecruiterData.length > 0) {
      const formattedOptions = talentRecruiterData?.map((option) => ({
        label: option,
        value: option,
      }));
      setRecruiterOptions(formattedOptions);
    }
  }, [
    talentNameData,
    talentStateData,
    talentCountryData,
    talentJobTitleData,
    talentSourceData,
    talentTagData,
    talentRecruiterData,
  ]);

  const handleFilterToggle = (filter) => {
    setCollapsible({
      talentName: false,
      skills: false,
      city: false,
      state: false,
      country: false,
      experienceLevel: false,
      educations: false,
      keywordSearch: false,
      jobTitle: false,
      jobType: false,
      payRate: false,
      workAuthorisation: false,
      subscriptionStatus: false,
      tags: false,
      sourceChannel: false,
      recruiterName: false,
      dispositionStatus: false,
      [filter]: !collapsible[filter],
    });
  };

  const handleAPICalls = (filter) => {
    if (filter == "talentName") {
      const payload = {
        text: "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentNameFilterData(payload));
    }
    if (filter == "state") {
      const payload = {
        text: "",
        filterParams: { ...filterParams, country: "" },
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentStateFilter(payload));
    }
    if (filter == "country") {
      const payload = {
        text: "",
        filterParams: { ...filterParams, state: "" },
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentCountryFilter(payload));
    }
    if (filter == "jobTitle") {
      const payload = {
        searchText: "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentJobTitleFilter(payload));
    }
    if (filter == "sourceChannel") {
      let payload = {
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentSourceFilter(payload));
    }
    if (filter == "tags") {
      const payload = {
        currentPage: 1,
        limit: 10,
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentTagFilter(payload));
    }
    if (filter == "recruiterName") {
      const payload = {
        currentPage: 1,
        limit: 5,
        searchText: "",
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentRecruiterFilter(payload));
    }
  };

  const fetchTalentNameData = async (searchValue) => {
    if (collapsible.talentNameSearch) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentNameFilterData(payload));
    }
  };

  const fetchStates = async (searchValue) => {
    if (collapsible.state) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentStateFilter(payload));
    }
  };

  const fetchCountries = async (searchValue) => {
    if (collapsible.country) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentCountryFilter(payload));
    }
  };

  const fetchJobTitles = async (searchValue, filter) => {
    if (collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        tab: parentTab,
      };
      dispatch(getTalentJobTitleFilter(payload));
    }
  };

  const fetchRecruiter = async (searchValue, filter) => {
    if (collapsible[filter]) {
      const payload = {
        currentPage: 1,
        limit: 5,
        searchText: searchValue ?? "",
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentRecruiterFilter(payload));
    }
  };

  return (
    <div className="card-flat">
      <div className="card-body">
        <div className="view-panel-header view-panel-header-sm">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="">
              <strong>Common Filter</strong>
            </div>
            <div className="px-3">
              <button
                disabled={filterLength == 0}
                type="button"
                className="btn btn-text"
                onClick={handleClear}
              >
                <span className="font-bold">Clear All</span>
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <DDMS
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.talentName}
            filterStatus={"talentName"}
            filterName={"Talent By Name"}
            options={talentNameOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={false}
            fetchOptions={fetchTalentNameData}
            applyFilter={applyFilter}
            hideDropdown={true}
            isLoading={talentNameLoading}
          />

          <BooleanFilter
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.skills}
            filterName={"Skills"}
            selectedData={selectedData}
            filterStatus={"skills"}
            inputList={skillInputList}
            setInputList={setSkillInputList}
            applyFilter={applyFilter}
          />

          <LocationFilter
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            cityEnabled={collapsible.city}
            stateEnabled={collapsible.state}
            countryEnabled={collapsible.country}
            filterStatus={"candidateLocation"}
            stateArr={stateOptions}
            countryArr={countryOptions}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            fetchCountries={fetchCountries}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            fetchStates={fetchStates}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoadingCountry={talentCountryLoading}
            isLoadingState={talentStateLoading}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />

          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.experienceLevel}
            filterName={"Experience Level"}
            filterStatus={"experienceLevel"}
            options={experienceOptions}
            isMulti={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            selected={selectedExperience}
            setSelected={setSelectedExperience}
            applyFilter={applyFilter}
            isLoading={false}
          />
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.jobType}
            filterStatus={"jobType"}
            filterName={"Job Type"}
            options={jobTypeOptions}
            isMulti={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoading={false}
          />
          <DDMS
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.jobTitle}
            filterStatus={"jobTitle"}
            filterName={"Job Title"}
            options={jobTitleOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            fetchOptions={fetchJobTitles}
            applyFilter={applyFilter}
            hideDropdown={true}
            isLoading={talentJobTitleLoading}
          />
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.educations}
            filterStatus={"educations"}
            filterName={"Highest Education Level"}
            options={educationOptions}
            isMulti={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoading={false}
          />
          <PayRateFilter
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.payRate}
            filterStatus={"payRate"}
            filterName={"Pay Rate"}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoading={false}
            isFilterApplied={isFilterApplied}
            setIsFilterApplied={setIsFilterApplied}
            min={min}
            max={max}
            setMax={setMax}
            setMin={setMin}
          />
          {!(
            parentTab == "External Talent Pool" && getUserRole() == "Recruiter"
          ) && (
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.sourceChannel}
              filterName={"Source Channel"}
              options={sourceChannelsOptions}
              filterStatus={"sourceChannel"}
              isMulti={false}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={talentSourceLoading}
              hideDropdown={true}
            />
          )}
          {tagFilterRole.includes(getUserRole()) && (
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.tags}
              filterStatus={"tags"}
              filterName={"Tags"}
              options={tagOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={talentTagLoading}
              hideDropdown={true}
            />
          )}
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.workAuthorisation}
            filterStatus={"workAuthorisation"}
            filterName={"Work Authorisation"}
            options={workAuthorisationOptions}
            countries={internationalPlacementOption}
            isMulti={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoading={false}
            askCountry={true}
          />
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.subscriptionStatus}
            filterStatus={"subscriptionStatus"}
            filterName={"Subscription Status"}
            options={subscriptionStatusOption}
            isMulti={false}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            isLoading={false}
            hideDropdown={true}
          />
          <hr />
          <div className="view-panel-header view-panel-header-sm">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="">
                <strong>Additional Filter</strong>
              </div>
            </div>
            <hr />
          </div>
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={() => {}}
            filterEnabled={collapsible.dispositionStatus}
            filterName={"Disposition Status"}
            filterStatus={"dispositionStatus"}
            options={dispositionOptions.filter(
              (item) => !recruiterFilter.includes(item.value)
            )}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            applyFilter={applyFilter}
            isLoading={false}
          />
          {!(
            (parentTab === "External Talent Pool" &&
              getUserRole() === "Recruiter") ||
            isFrom == "high5talentpool"
          ) && (
            <DDMS
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.recruiterName}
              filterStatus={"recruiterName"}
              filterName={"Recruiter Name"}
              options={recruiterOptions}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              isMulti={true}
              fetchOptions={fetchRecruiter}
              applyFilter={applyFilter}
              hideDropdown={true}
              isLoading={talentRecruiterLoading}
            />
          )}
          <BooleanFilter
              handleFilterToggle={handleFilterToggle}
              filterEnabled={collapsible.keywordSearch}
              filterName={"Keywords"}
              selectedData={selectedData}
              filterStatus={"keywordSearch"}
              inputList={keywordList}
              setInputList={setKeywordList}
              applyFilter={applyFilter}
            />
        </div>
      </div>
    </div>
  );
};

export default Index;
