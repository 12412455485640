import {
    GET_CLIENT_NAME_BEGIN,
    GET_CLIENT_NAME_SUCCESS,
    GET_CLIENT_NAME_FAILURE,
  } from "../../action/common/ClientName";
  
  const initialState = {
    clientName: [],
    nameLoading: false,
  };
  
  const getClientNameReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_CLIENT_NAME_BEGIN:
        return {
          ...state,
          nameLoading: true,
          clientName: [],
        };
      case GET_CLIENT_NAME_SUCCESS:
        return {
          ...state,
          clientName: payload,
          nameLoading: false,
        };
      case GET_CLIENT_NAME_FAILURE:
        return {
          ...state,
          clientName: [],
          nameLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default getClientNameReducer;
  