import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { uniqBy } from "lodash";
import { dashboard, common } from "./PrivateRoles";
import { getUserToken } from "../service/AuthService";
import * as components from "./PrivateComponentList";

class PrivateRoutes extends Component {
  state = { allowedRoutes: [] };
  componentDidMount() {
    let allowedRoutes = [];
    const roles = getUserToken().role;
    if (roles) {
      let routes = common?.filter((item) => item?.role?.includes(roles));
      allowedRoutes.push(dashboard[roles]);
      allowedRoutes = [...allowedRoutes, ...routes];
      allowedRoutes = uniqBy(allowedRoutes, "url");
      this.setState({ allowedRoutes });
    } else {
      if (window.location.href.includes("/requests")) {
        const jobId = window.location.href.split("/requests/")[1];
        localStorage.setItem("jobId", jobId);
      }
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <Switch>
        {this?.state?.allowedRoutes?.map((route, index) => (
          <Route
            exact
            key={index}
            component={components[route?.component]}
            path={`${this?.props?.match?.path}${route?.url}`}
          />
        ))}
        <Route />
      </Switch>
    );
  }
}

export default PrivateRoutes;
