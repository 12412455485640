import React from "react";

const NoRecord = () => {
  return (
    <div>
      <div className="text-center p-3">
        <div className="avatar avatar-lg">
          <i aria-hidden="true" className="fad fa-folder-open"></i>
        </div>{" "}
        <div className="mt-6">No records found</div>
      </div>
    </div>
  );
};

export default NoRecord;
