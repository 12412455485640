import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { browserName } from "react-device-detect";
import axios from "axios";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import GitHubLogin from "react-github-login";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import { post } from "../../service/ApiService";
import Loader from "../loader/Loader.js";
import Event from "../googleanalytics/Tracking";

import { apiDomain } from "../../constants/Constants";

// import FacebookLoginButton from "../NewApplyPage/ApplyNow/Facebooklogin.js";

const LoginUser = (props) => {
  const client_id = "Iv1.ea6c9e6f3188d421";
  const client_secret = "a13e4fd12ef38ebb81f59ec12f8afd0cd041d80d";
  const redirect_uri = `${window.location.origin}/github`;

  const { isLoginModal, setIsLoginModal, isFrom } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [ip, setIp] = useState("");
  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const forgetPassword = async (e) => {
    e.preventDefault();
    window.open(`/careers/forgot-passwordDS`, "_blank");
  };

  const loginUser = async (socialLogin, method, emailAddress, code) => {
    const params = {
      userName: socialLogin ? emailAddress : email,
      password: password,
      activity: "Logged In",
      browser: browserName,
      ipAddress: ip,
      code: code,
    };
    let baseLoginUrl = "/candidateLogin/";

    let loginUrl = !!socialLogin ? `${baseLoginUrl}${method}` : baseLoginUrl;

    setLoading(true);
    const res = await post(loginUrl, params)
      .then(async (res) => {
        Event("Login", `${res.data.name},Candidate`, window.location.pathname);
        sessionStorage.setItem("token", res.data.token);
        localStorage.setItem("token", res.data.token);
        sessionStorage.setItem("profileImagePath", res.data.profileImagePath);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("id", res.data.id);
        sessionStorage.setItem("userName", res.data.userName);
        sessionStorage.setItem("lastLogInTime", res.data.loginTime);
        props?.parent === "CandidateProfile" && props.setIsLoggedIn(true);
        props.setLogin(false);
        isFrom === "NewApplyPage" && setIsLoginModal(false);
        setEmail("");
        setPassword("");
        try {
          await post("/inviteunsubscribed/isSubscribed", {
            email: res.data.userName,
          })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
        } catch (err) {
          console.log(err);
        }
        if (props.getActiveTab === "Offers") {
          window.open(`/careers/profile/?tab=Offers`, "_self");
        } else {
          window.open(`/careers/profile`, "_self");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        toast.error("Please provide correct email and password");
      });
  };
  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };
  useEffect(() => {
    getIpData();
  }, []);

  const googleLoginFun = useGoogleLogin({
    onSuccess: async (response) => {
      const { access_token } = response;

      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        loginUser(true, "google", res.data?.email, access_token);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const { linkedInLogin: loginLinkedin } = useLinkedIn({
    clientId: "77uhcseceaakxp",
    scope: "openid profile email",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      if (isLoginModal) {
        setLoading(true);
        let params = {
          grant_type: "authorization_code",
          code: code,
          client_id: "77uhcseceaakxp",
          client_secret: "a71lZaOZdDapw1Vg",
          redirect_uri: `${window.location.origin}/linkedin`,
        };
        axios
          .post(`${apiDomain}/linkedInLogin/linkedin`, params)
          .then((data) => {
            loginUser(
              true,
              "linkedin",
              data?.data?.email,
              data.data.access_token
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log("error", err);
            setLoading(false);
          });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const facebookLoginFun = (data) => {
    loginUser(true, "facebook", data?.email, data?.accessToken);
    setLoading(false);
  };
  const githubLoginFun = (response) => {
    const { code } = response;

    if (code) {
      exchangeCodeForToken(code);
    } else {
      console.error(
        "GitHub login failed. Authorization code not found in response:",
        response
      );
    }
  };
  const onFailureGit = (err) => {
    console.error("GitHub login failed", err);
  };

  const exchangeCodeForToken = async (code) => {
    try {
      setLoading(true);
      await axios
        .post(`${apiDomain}/githubLogin/github`, {
          client_id: client_id,
          client_secret: client_secret,
          code: code,
          redirect_uri: redirect_uri,
        })
        .then((response) => {
          const data = response.data;
          const accessToken = data.access_token;
          const email = data?.email;
          // Now, you can use the accessToken for authenticated requests or save it as needed
          // Handle the access token as needed (e.g., save it to sessionStorage)
          setLoading(false);
          if (email) {
            loginUser(true, "github", email, accessToken);
          } else {
            toast.error("Please try other methods to login");
          }
        });
    } catch (error) {
      console.error("Error exchanging GitHub code for access token:", error);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div>
        <Modal show={props.login}>
          <Modal.Header>
            <div>
              <h6>Login</h6>
            </div>
            <div>
              <button
                type='button'
                data-dismiss='modal'
                aria-label='Close'
                onClick={() => {
                  props.setLogin(false);
                  isFrom === "NewApplyPage" && setIsLoginModal(false);
                  setEmail("");
                  setPassword("");
                }}
                class='close p-0 bl-modal-close-btn mx-1'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex flex-column mx-5 mb-3 '></div>

            <div>
              <div className='d-flex flex-column mx-5 my-3'>
                <div className='form-floating ml-1'>
                  <input
                    type='text'
                    className={"form-control"}
                    name='groupName'
                    placeholder='Enter a group name'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Email*</label>
                </div>
              </div>

              <div className='d-flex flex-column mx-5 my-3'>
                <div className='form-floating ml-1  form-password '>
                  <input
                    type={showPassword ? "text" : "password"}
                    className={"form-control"}
                    name='groupName'
                    placeholder='Enter a group name'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label>Password*</label>

                  {password.length > 0 && (
                    <button
                      onClick={showPasswordClick}
                      type='button'
                      className='btn btn-sm btn-text btn-text-accent h-100'
                    >
                      <i
                        className={
                          showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        aria-hidden='true'
                      ></i>
                    </button>
                  )}
                </div>
                <p className='text-right my-1'>
                  <a href='' onClick={forgetPassword}>
                    Forgot Password?
                  </a>
                </p>
              </div>

              <div className='row mx-5 mb-2 text-center'>
                <button
                  className='btn btn-secondary m-2 w-100'
                  onClick={() => loginUser(false)}
                >
                  Login
                </button>
                <div className='mt-2 card card-flat p-3'>
                  <strong className='mb-3'>Or login with</strong>
                  <Row className='d-flex justify-content-around w-100 mx-auto '>
                    <Col className='text-center'>
                      <button className='btn btn-sm btn-secondary font-18  border-0'>
                        {" "}
                        <FaLinkedin
                          onClick={() => loginLinkedin()}
                          className='pointer'
                          size={20}
                          style={{ color: "#0077b5" }}
                        />
                      </button>
                    </Col>

                    <Col className='text-center'>
                      <button className='btn btn-sm btn-secondary font-18  border-0'>
                        {" "}
                        <img
                          onClick={() => googleLoginFun()}
                          className='pointer'
                          src='https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg'
                          alt='Gmail Icon'
                          style={{ width: 20, height: 20 }}
                        />
                      </button>
                    </Col>
                    {/* <Col className="text-center">
                      <FacebookLoginButton facebookLogin={facebookLoginFun} />
                    </Col> */}

                    <Col className='text-center '>
                      <GitHubLogin
                        className='text-black btn btn-sm btn-secondary font-18 border-0'
                        clientId={client_id} // GitHub App
                        // clientId="03e704e88953067267af"    // GitHub OAuth App
                        redirectUri={redirect_uri}
                        onSuccess={githubLoginFun}
                        onFailure={onFailureGit}
                      >
                        {" "}
                        <FaGithub />
                      </GitHubLogin>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className='d-flex align-items-center mx-5 my-3'></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default LoginUser;
