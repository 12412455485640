import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { ExcelRenderer } from "react-excel-renderer";
import candidateExcel from "../../../../docs/Invite-Candidate.xlsx";
import {
  getUserTenant,
  getCandidateId,
  getUserEmail,
  getUserId,
} from "../../../../service/AuthService";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import {
  getUserTenantID,
  getCandidateName,
} from "../../../../service/AuthService";
import validator from "validator";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../loader/Loader";
import buttonLoader from "../../../../images/button-loader.gif";
import { getAllTalentEmails } from "../../../../action/talent/GetTalentEmail";

function InviteBulkTalent(props) {
  const dispatch = useDispatch();
  const [uploaded, setUploaded] = useState(false);
  const [data, setData] = React.useState([]);
  const [errCount, setErrCount] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [existingCandidateList, setExistingCandidateList] = useState([]);

  const { talentEmails, talentEmailsLoading } = useSelector(
    (state) => ({
      talentEmailsLoading: state.getTalentEmailReducer.talentEmailsLoading,
      talentEmails: state.getTalentEmailReducer.talentEmails,
    }),
    shallowEqual
  );

  useEffect(() => {
    const payload = {
      jobId: props.jobData.jobId,
      invitedBy: getUserId(),
    };
    dispatch(getAllTalentEmails(payload));
  }, []);

  useEffect(() => {
    if (talentEmails) {
      setExistingCandidateList(talentEmails);
    }
  }, [talentEmails]);

  const fileChange = (file) => {
    setErrCount(0);
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file.name)[1];

    if (ext === "xlsx") {
      setErrCount(0);
      ExcelRenderer(file, (err, res) => {
        if (err) {
          setUploaded(false);
        } else {
          res.rows.splice(0, 1);
          let tempcount = 0;
          const result = res.rows.map((eachrow, index) => {
            let err = 0;
            let errMessage = "";
            const [firstName, lastName, email] = eachrow;

            if (
              firstName === undefined ||
              lastName === undefined ||
              email === undefined
            ) {
              err = 1;
              errMessage = " Data Missing";
              //setErrCount(tempcount + 1)
              tempcount += 1;
            } else {
              //errMessage ="Already Exists"
              let isExists = existingCandidateList.filter(
                (row) => row.email == email
              );
              if (isExists.length > 0) {
                errMessage = "Already Exists";
                err = 1;
                tempcount += 1;
              } else if (
                !validator.isEmpty(email + "") &&
                !validator.isEmail(email + "")
              ) {
                errMessage = "Please enter a valid Email ID";
                err = 1;
                tempcount += 1;
              } else {
                errMessage = "Passed";
                err = 0;
              }
            }

            return {
              err,
              errMessage,
              firstName,
              lastName,
              email,
            };
          });
          setErrCount(tempcount);
          setData(result);
          setUploaded(true);
        }
      });
    } else {
      toast.error("Please Upload .xlsx Files ");
    }
  };
  // const [data,setData] = useState ()

  const submitAddTalent = async (e) => {
    setSubmitClicked(true);
    const usertenant = getUserTenant();
    const loggedinusername = getCandidateName();
    let tenant_id = getUserTenantID();
    let jobId = props.jobData.jobId;
    let jobTitle = props.jobData.jobTitle;
    const userid = getCandidateId();
    let sendMailLink = `${domain}/career/jobs/${jobId}/recruiter/${userid}`;
    let responseData = data
      .filter((eachData) => eachData.err == 0)
      .map((eachData) => {
        const result = {
          candidateFname: eachData.firstName,
          candidateLname: eachData.lastName,
          candidateEmail: eachData.email,
          sendMailLink: sendMailLink,
          candidateStatus: "active",
          jobId: jobId,
          jobTitle: jobTitle,
          candidateTenant: usertenant,
          recruiterName: loggedinusername,
          recruiterId: `${userid}`,
          tenant_id: `${tenant_id}`,
          recruiterEmail: getUserEmail(),
          bulk: "bulk",
        };
        return result;
      });
    await post("/disposition/invitetalent", responseData).then((data) => {
      if (data.status == 200) {
        if (data.data?.candidateUnsubscribedList?.length > 0) {
          let unSubCandidates = data.data?.candidateUnsubscribedList?.map(
            (x) => x.email
          );
          let unSubStr = unSubCandidates.toString().replaceAll(",", ", ");
          unSubCandidates.length == 1
            ? toast.error(`${unSubStr} has unsubscribed from high5`)
            : toast.error(
                `${unSubCandidates.length} talents have unsubscribed from high5`
              );
        }
        toast.success("Talent invited successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Oops! Something went wrong");
      }
    });
  };

  const closeHandle = () => {
    setUploaded(false);
    setData([]);
    setErrCount(0);
  };

  return (
    <>
      {talentEmailsLoading && <Loader />}
      <div>
        <div className="row">
          <div className="col-12">
            <div className="pt-5 pl-5 pb-0 pr-5 p-0-600">
              <h6>Step 1</h6>
              <p>
                <a href={candidateExcel} download className="btn">
                  Download the .xlxs template
                </a>
              </p>
              <h6>Step 2</h6>
              <div className="d-flex align-items-center">
                <p className="m-0 mr-2">
                  Upload excel/CSV file for invite talent.
                </p>
                <Dropzone
                  Dropzone
                  onDrop={(file) => fileChange(file[0])}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="">
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary"
                      >
                        <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                        Upload
                      </button>
                      <input type="file" {...getInputProps()} accept=".xlsx" />
                    </div>
                  )}
                </Dropzone>
              </div>
              <br />
              <div className="col-sm-12">
                {uploaded && (
                  <div>
                    <div className=" d-flex">
                      <div>
                        {" "}
                        <h6>Step 3</h6> <p>Preview uploaded data. </p>
                      </div>
                      <div className="ml-auto">
                        {" "}
                        <h6>Activity </h6>{" "}
                        <p>
                          Total {data.length - errCount} of {data.length} valid
                          record found{" "}
                        </p>
                      </div>
                    </div>
                    <table class="table table-hoverable ">
                      <thead>
                        <tr>
                          <th scope="col"> Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((eachvalue) => (
                          <tr
                            className={
                              eachvalue.err == 0
                                ? "table-primary"
                                : "table-danger"
                            }
                          >
                            <td>
                              {eachvalue.firstName} {eachvalue.lastName}{" "}
                            </td>
                            <td>{eachvalue.email}</td>

                            <td>{eachvalue.errMessage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        disabled={submitClicked}
                        onClick={() => closeHandle()}
                      >
                        Clear
                      </button>
                      {submitClicked === false ? (
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          disabled={data.length - errCount === 0}
                          onClick={submitAddTalent}
                        >
                          <span>Invite</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          disabled={data.length - errCount === 0}
                        >
                          <span v-if="submittingForm">
                            Inviting
                            <img width="20px" alt="" src={buttonLoader} />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InviteBulkTalent;
