import React, { useState } from "react";
import Select from "react-select";
import { ButtonLoader } from "../../../../constants/Constants";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import { visaStatus } from "../../../../constants/Constants";

function VisaStatus({
  data,
  ip,
  browserName,
  updateDetails,
  setUpdateDetails,
}) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [workAuthorization, setVisaStatus] = useState(data.visaStatus || "");

  const editVisaStatus = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelVisaStatus = () => {
    setVisaStatus(data.visaStatus || "");
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingForm(false);
    setSaveClicked(true);
  };

  const saveVisaStatus = async () => {
    setSaveClicked(false);
    setSubmittingForm(true);
    try {
      const parameters = {
        ...data,
        visaStatus: workAuthorization,
        activity: "VisaStatus updated",
        candidateId: data.userId,
        ipAddress: ip,
        browser: browserName,
      };
      const res = await post("/talent/updatetalentprofile", parameters);
      if (res.status === 200) {
        setSubmittingForm(false);
        setShowSuccessMessage(true);
        setUpdateDetails(!updateDetails);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
        toast.success("Updated successfully!");
      }
    } catch (error) {
      toast.error("Error Occurred");
      setSubmittingForm(false);
    }
  };

  return (
    <div className={`mb-2 ${readOnlyMode ? "read-only" : ""}`}>
      <div className="card card-lg">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="mb-0">VisaStatus*</h6>
            {readOnlyMode ? (
              <button
                type="button"
                className="btn btn-text"
                onClick={editVisaStatus}
              >
                <i className="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelVisaStatus}
                  className="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveVisaStatus}
                  className="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}
                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="Loading" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="col-lg-12">
              {readOnlyMode ? (
                <div className="form-floating mb-4">
                  <label>VisaStatus</label>
                  <div class="pt-5 px-3">
                    <span class="tag tag-blue3 mr-1 mt-1">
                      {workAuthorization}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-100 mb-4">
                  <label className="mb-1">VisaStatus*</label>
                  <div
                    className="form-floating Hover-Dropdown mb-2"
                    style={{ zIndex: "1000" }}
                  >
                    <Select
                      options={visaStatus}
                      name="visaStatus"
                      onChange={(selectedOption) => {
                        setVisaStatus(
                          selectedOption ? selectedOption.value : ""
                        );
                      }}
                      isSearchable={true}
                      value={visaStatus.find(
                        (visa) => visa.value === workAuthorization
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisaStatus;
