import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { areAllKeysEmpty } from "../../../../../validation/ApplyJob";
import { educationTypes } from "../../../../../../constants/Constants";

function Education(props) {
  const {
    education,
    educations,
    setEducations,
    preferenceTab,
    setEducation,
    educationValidation,
    setEducationValidation,
  } = props;

  const inputDataObj = {
    graduatedYear: "",
    educationType: "",
    educationProgram: "",
    school: "",
    major: "",
  };

  const [temp, setTemp] = useState([inputDataObj]);

  useEffect(() => {
    setTemp(educations);
  }, [educations]);

  const inputValObj = {
    graduatedYear: "",
    educationType: "",
    educationProgram: "",
    school: "",
    major: "",
  };

  const newDate = new Date();
  let currentYear = newDate.getFullYear();
  const min = currentYear - 50;
  const max = currentYear;
  const allYears = [];
  for (let x = min; x <= max; x++) {
    allYears.push(x);
  }

  const handleChange = (e, i) => {
    const newArray = [...temp];
    newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
    setTemp(newArray);
  };

  // Delete Education
  const handleDelete = (e, i) => {
    setTemp(temp.filter((item, index) => index !== i));
    setEducationValidation(
      educationValidation.filter((item, index) => index !== i)
    );
  };

  // Add Education
  const handleAdd = () => {
    setTemp([...temp, inputDataObj]);
    setEducationValidation([...educationValidation, inputValObj]);
  };
  const handleDone = () => {
    let ed = temp.filter((x) => !areAllKeysEmpty(x));

    setEducations(ed.length > 0 ? ed : [inputDataObj]);
    setEducation(false);
  };

  return (
    <Modal size={"xl"} show={education} backdrop="static">
      <div class="bl-modal-header">
        <h6 class="bl-modal-title">Add Education</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setEducation(false);
          }}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <div>
          <hr />
          <div class="education">
            {temp?.map((education, i) => (
              <div class={"d-flex  gap-2 px-3"} v-for="item in list">
                <div class=" ">
                  <div class="avatar avatar-sm">{i + 1}</div>
                </div>
                <div className="w-100 ">
                  <div class="row mb-3">
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <select
                          class="form-select"
                          id="graduatedYear"
                          style={{
                            border:
                              educationValidation[i]?.graduatedYear &&
                              "2px solid #ff0000",
                          }}
                          value={education?.graduatedYear}
                          onChange={(e) => handleChange(e, i)}
                        >
                          <option value="" selected>
                            Select Year
                          </option>
                          {allYears.map((x) => (
                            <option value={x}>{x}</option>
                          ))}
                        </select>
                        <label>Year of graduation</label>
                      </div>
                      <small className="validation">
                        {educationValidation[i]?.graduatedYear}
                      </small>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <select
                          class="form-select"
                          id="educationType"
                          value={education?.educationType}
                          style={{
                            border:
                              educationValidation[i]?.educationType &&
                              "2px solid #ff0000",
                          }}
                          onChange={(e) => handleChange(e, i)}
                        >
                          <option value="" selected>
                            Select Type
                          </option>
                          {educationTypes
                            .filter((item) => item != "Select")
                            .map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                        </select>
                        <label>Type of Education</label>
                      </div>
                      <small className="validation">
                        {educationValidation[i]?.educationType}
                      </small>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Education Program"
                          id="educationProgram"
                          style={{
                            border:
                              educationValidation[i]?.educationProgram &&
                              "2px solid #ff0000",
                          }}
                          value={education?.educationProgram}
                          onChange={(e) => handleChange(e, i)}
                        />
                        <label>Education Program</label>
                      </div>
                      <small className="validation">
                        {educationValidation[i]?.educationProgram}
                      </small>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="School"
                          id="school"
                          style={{
                            border:
                              educationValidation[i]?.school &&
                              "2px solid #ff0000",
                          }}
                          value={education?.school}
                          onChange={(e) => handleChange(e, i)}
                        />
                        <label>School</label>
                      </div>
                      <small className="validation">
                        {educationValidation[i]?.school}
                      </small>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Major"
                          id="major"
                          style={{
                            border:
                              educationValidation[i]?.major &&
                              "2px solid #ff0000",
                          }}
                          value={education?.major}
                          onChange={(e) => handleChange(e, i)}
                        />
                        <label>Major</label>
                      </div>
                      <small className="validation">
                        {educationValidation[i]?.major}
                      </small>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                </div>
                <div class=" ">
                  {temp.length !== 1 && (
                    <button
                      type="button"
                      class="btn btn-icon"
                      onClick={(e) => handleDelete(e, i)}
                    >
                      <i
                        style={{ color: "red" }}
                        class="fal fa-fw fa-trash-alt"
                      ></i>
                    </button>
                  )}
                </div>
              </div>
            ))}

            <div>
              <button
                type="button"
                onClick={handleAdd}
                class="btn btn-sm btn-text"
              >
                <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                <strong>Add Education</strong>
              </button>
            </div>
          </div>
          <hr />
          <div className="row mx-auto my-3 ">
            <div className="col-md-12 d-flex gap-2 justify-content-end">
              <button
                className={`btn btn-sm ${
                  preferenceTab ? "btn-secondary" : ""
                } `}
                onClick={() => {
                  setEducation(false);
                }}
              >
                Cancel
              </button>
              <button
                className={`btn btn-sm ${preferenceTab ? "btn-primary" : ""} `}
                onClick={handleDone}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default Education;
