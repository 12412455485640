import React, { useState, useEffect } from "react";
import Qualified from "./qualified/Index";
import Invite from "./invite/Index";
import Automatch from "./automatch/Index";
import Submitted from "./submitted/Index";
import Shortlisted from "./shortlisted/Index";
import Offered from "./offered/Index";
import Hired from "./hired/Index";
import Disqualified from "./disqualified/Index";
// import {
//   Automatched_Recruiter,
//   Invited_HM,
//   Invited_Recruiter,
//   Submitted as Submitted_Q,
//   Shortlisted as Shortlisted_Q,
//   Offered as Offered_Q,
//   Hired as Hired_Q,
// } from "../../../EmailStatus/EmailStatusQueries";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../loader/Loader";

const Index = (props) => {
  const { jobData, view, value } = props;
  const weightage = JSON.parse(jobData?.weightage);
  const [jobDetailsData, setJobDetailsData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(jobData).length !== 0) {
      setJobDetailsData({
        ...jobData,
        primarySkills: jobData.primarySkills
          ? JSON.parse(jobData.primarySkills)
          : [],
        education: jobData.education ? JSON.parse(jobData.education) : [],
      });
    }
  }, [jobData]);

  // const calculateScore = (jobData, candidateData) => {
  //   let primary = 0;
  //   let location = 0;
  //   let education = 0;
  //   let jobTitle = 0;
  //   let experience = 0;
  //   let sum = 1;
  //   jobData?.primarySkills?.forEach((skill) => {
  //     if (
  //       candidateData?.primarySkills?.some(
  //         (candidateSkill) =>
  //           candidateSkill.toLowerCase() === skill.toLowerCase()
  //       )
  //     ) {
  //       primary++;
  //     }
  //   });
  //   if (weightage?.education?.length > 0) {
  //     if (candidateData?.highestEducation !== null) {
  //       if (
  //         candidateData?.highestEducation?.toLowerCase() ==
  //         jobData?.education[0]?.toLowerCase()
  //       ) {
  //         education++;
  //       }
  //     }
  //     if (
  //       candidateData.highestEducation == "Master's Degree" ||
  //       candidateData.highestEducation == "Doctorate"
  //     ) {
  //       education++;
  //     }
  //     sum++;
  //   }
  //   if (weightage?.jobTitle) {
  //     if (
  //       candidateData?.designation?.toLowerCase().trim() ===
  //       jobData?.jobTitle.toLowerCase().trim()
  //     ) {
  //       jobTitle++;
  //     }
  //     sum++;
  //   }

  //   const candidateCountry = [
  //     candidateData?.country,
  //     candidateData?.city,
  //     candidateData?.state,
  //   ];
  //   if (weightage?.location) {
  //     if (jobData?.isGlobal) {
  //       location++;
  //     } else if (
  //       jobData?.isRemote &&
  //       (jobData?.preferredLocation
  //         ? JSON.parse(jobData?.preferredLocation)
  //         : []
  //       )
  //         ?.map((item) => item?.replace(/\s+/g, "").toLowerCase())
  //         .some((r) =>
  //           candidateCountry
  //             ?.map((c) => c?.replace(/\s+/g, "").toLowerCase())
  //             ?.includes(r)
  //         )
  //     ) {
  //       location++;
  //     } else if (
  //       jobData?.country.replace(/\s+/g, "").toLowerCase() ==
  //       candidateData?.country?.replace(/\s+/g, "").toLowerCase()
  //     ) {
  //       location++;
  //     }
  //   }
  //   if (weightage?.experienceLevel) {
  //     if (candidateData?.experienceLevel === jobData?.experienceLevel) {
  //       experience++;
  //     } else if (
  //       candidateData?.experienceYear >= jobData?.experienceLevel?.charAt(0)
  //     ) {
  //       experience++;
  //     }
  //     sum++;
  //   }
  //   let primaryAverage =
  //     (jobData?.primarySkills ? jobData?.primarySkills : [])?.length > 0
  //       ? Math.round(
  //           (primary * 100) /
  //             (jobData?.primarySkills ? jobData?.primarySkills : []).length
  //         )
  //       : 0;
  //   let locationAverage = location ? 100 : 0;
  //   let jobTitleAverage = jobTitle ? 100 : 0;
  //   let experienceAvg = experience ? 100 : 0;
  //   let educationAvg = education ? 100 : 0;
  //   let total = Math.round(
  //     (primaryAverage + jobTitleAverage + experienceAvg + educationAvg) / sum
  //   );
  //   // let total = 0;
  //   // total = Math.round((sum * 100) / count);

  //   return total;
  // };

  return (
    <div class="col-xl-8">
      {loading && <Loader />}
      <div
        className="card card-flat card-lg card-top-straight"
        style={{ borderTop: "none" }}
      >
        <div className="card-body">
          {view == "automatched" && (
            <Automatch
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
          {view == "invite" && (
            <Invite jobData={jobDetailsData} value={value} view={view} />
          )}
          {view == "qualified" && (
            <Qualified
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
          {view == "submitted" && (
            <Submitted
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
          {view == "shortlist" && (
            <Shortlisted
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
          {view == "offered" && (
            <Offered
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
          {view == "hired" && (
            <Hired
              jobData={jobDetailsData}
              // calculateScore={calculateScore}
              value={value}
              view={view}
            />
          )}
        </div>
      </div>
      <Disqualified
        jobData={jobDetailsData}
        // calculateScore={calculateScore}
        value={value}
        view={view}
      />
    </div>
  );
};

export default Index;
