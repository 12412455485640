import React from "react";
import Modal from "react-bootstrap/Modal";

const RejectionModal = ({
  reject,
  rejectionReason,
  setRejectionReason,
  setReject,
  updateStatus,
}) => {
  return (
    <Modal show={reject}>
      <Modal.Header>
        <h6>Reject</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setRejectionReason("");
            setReject(false);
          }}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="mx-3">
          <label>Reason for Rejection</label>
          <div className="form-floating mt-2">
            <textarea
              className="form-control"
              value={rejectionReason}
              onChange={(e) => {
                setRejectionReason(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="d-flex align-items-center justify-content-end m-3">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => {
            setRejectionReason("");
            setReject(false);
          }}
        >
          Cancel
        </button>

        <button
          disabled={rejectionReason === ""}
          className="btn btn-sm btn-primary ml-2"
          onClick={() => {
            updateStatus("Rejected");
          }}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export default RejectionModal;
