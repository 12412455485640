import React from "react";

const DateEvent = ({
  name,
  text,
  value,
  setValue,
  customStyle,
  onChange,
  onClick,
  className,
  minRange,
  maxRange,
}) => {
  let style = {};

  if (customStyle) {
    style = { ...style, ...customStyle };
  }
  return (
    <>
      <label>{text || "label"}</label>
      <input
        type="date"
        name={name}
        format="MM/dd/yyyy"
        style={style}
        value={value}
        className={className}
        onClick={onClick}
        min={minRange}
        max={maxRange}
        onChange={onChange ? onChange : (e) => setValue(e.target.value)}
      />
    </>
  );
};

export default DateEvent;
