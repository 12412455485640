import React from "react";

const HotJobFilter = ({
  selectedData,
  setSelectedData,
  handleFilterToggle,
  applyFilter,
  collapsible,
}) => {
  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-collapsible"
        onClick={() => handleFilterToggle("hotJob")}
      >
        <i
          className={
            collapsible.hotJob
              ? "fal fa-angle-up fa-fw"
              : "fal fa-angle-down fa-fw"
          }
        />
        <span className="p-relative">
          <small>Hot Job</small>

          {selectedData.hotJob && <span className="jewel"></span>}
        </span>
      </button>
      {collapsible.hotJob && (
        <div className=" d-flex flex-column gap-2  p-2">
          <div class="form-floating ">
            <div className=" d-flex align-items-center  justify-content-between  px-2">
              <label className="form-check-label pt-0" htmlFor="remoteSwitch">
                Is Hot Job?
              </label>
              <input
                type="checkbox"
                className="form-check-input"
                style={{ width: "1.5rem", borderRadius: "100" }}
                checked={selectedData.hotJob}
                onClick={() => {
                  let status = !selectedData.hotJob;
                  setSelectedData((prev) => ({
                    ...prev,
                    hotJob: status,
                  }));
                  applyFilter(status, "hotJob");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotJobFilter;
