import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { FaUserPlus } from "react-icons/fa";
import Loader from "../../loader/Loader";
import Tabs from "../Tabs";
import ModalView from "../../modal/ModalView";
import RecipientsTable from "../recipients/RecipientsTable";
import SentMails from "./SentMails";
import AddRecipients from "../recipients/AddRecipients";
import { FetchLastMailSentByUser } from "../../../action/communication/Communication";

const Email = ({
  selectedTalents,
  setSelectedTalents,
  emailSubject,
  emailBody,
  emailFooter,
  setEmailBody,
  setEmailSubject,
  setEmailFooter,
  setErrorData,
  errorData,
  handleConfirmation,
  previewEmail,
  setPreviewEmail,
  isFrom,
  method,
  selectedRecipients,
  setSelectedRecipients,
}) => {
  const dispatch = useDispatch();

  const [showAllRecipients, setShowAllRecipients] = useState(false);
  const [activeTab, setActiveTab] = useState("compose");
  const [isSending, setIsSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addRecipientsModal, setAddRecipientsModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (selectedTalents && selectedTalents?.length > 0) {
      setSelectedRecipients([...selectedTalents]);
    }
    if (selectedTalents && selectedTalents?.length === 1) {
      dispatch(
        FetchLastMailSentByUser({
          mailTo: selectedTalents[0]?.email,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isSending) {
      setTimeout(() => {
        setIsSending(false);
      }, 2000);
    }
  }, [isSending]);

  const handleSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };

  const handleContentChange = (value) => {
    setEmailBody(value);
  };

  const handleFooterChange = (value) => {
    setEmailFooter(value);
  };

  const handleClear = () => {
    setEmailSubject("");
    setEmailBody("");
    setEmailFooter("");
    setErrorData({});
  };

  const handleCandidateRemove = (item) => {
    setSelectedRecipients((prev) =>
      prev.filter((candidate) => candidate.userId !== item.userId)
    );
  };

  // to enable and disable send button
  useEffect(() => {
    const trimmedSubject = emailSubject.trim();
    const trimmedBody = emailBody.trim();
    const trimmedFooter = emailFooter.trim();

    if (
      trimmedSubject === "" ||
      trimmedBody === "" ||
      trimmedFooter === "" ||
      selectedRecipients?.length === 0 ||
      isSending
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }

    if (
      trimmedSubject === "<p><br></p>" ||
      trimmedSubject === "<p><br></p><p><br></p>"
    ) {
      setEmailSubject("");
    }
    if (
      trimmedBody === "<p><br></p>" ||
      trimmedBody === "<p><br></p><p><br></p>"
    ) {
      setEmailBody("");
    }

    if (
      trimmedFooter === "<p><br></p>" ||
      trimmedFooter === "<p><br></p><p><br></p>"
    ) {
      setEmailFooter("");
    }
  }, [
    emailSubject,
    emailBody,
    emailFooter,
    selectedRecipients,
    isSending,
    setEmailSubject,
    setEmailBody,
    setEmailFooter,
  ]);

  return (
    <>
      <Container style={{ fontSize: "14px" }}>
        {loading && <Loader />}
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          method={method}
          isFrom={isFrom}
        />
        <Row style={{ minHeight: "80vh", fontSize: "14px", marginTop: "5px" }}>
          {activeTab === "compose" &&
            (addRecipientsModal ? (
              <AddRecipients
                setAddRecipientsModal={setAddRecipientsModal}
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
              />
            ) : (
              <>
                <Col md={8} sm={12} lg={8}>
                  <div className="mb-3 mt-2">
                    <div className="mt-4">
                      {(!selectedRecipients ||
                        selectedRecipients ||
                        selectedRecipients?.length > 0) &&
                        isFrom !== "TalentProfileEdit" && (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setAddRecipientsModal(true);
                              }}
                            >
                              {" "}
                              <FaUserPlus className="mr-2" /> Add Recipients
                            </button>
                          </>
                        )}
                    </div>
                    <div className="color">{errorData["recipient"]}</div>
                  </div>
                  <Row className="messageForm mb-5">
                    <Col sm={12} md={12}>
                      <label htmlFor="subject" className="label mb-2">
                        Subject*
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={emailSubject}
                        onChange={handleSubjectChange}
                        className="form-control"
                        style={{ fontWeight: "normal" }}
                      />
                      <div className="color">{errorData["subject"]}</div>
                    </Col>
                    <Col sm={12} md={12} style={{ height: "400px" }}>
                      <label htmlFor="content" className="label mb-2">
                        Body*
                      </label>
                      <ReactQuill
                        value={emailBody}
                        onChange={handleContentChange}
                        style={{
                          height: "300px",
                          fontWeight: "normal",
                        }}
                      />
                      <div className="color">{errorData["content"]}</div>
                    </Col>
                    <Col sm={12} md={12}>
                      <label htmlFor="footer" className="label mb-2">
                        Footer*
                      </label>
                      <ReactQuill
                        value={emailFooter}
                        onChange={handleFooterChange}
                        style={{
                          overflow: "auto",
                          fontWeight: "normal",
                        }}
                      />
                      <div className="color">{errorData["footer"]}</div>
                    </Col>
                    <Col sm={12} md={12}>
                      <Row className="d-flex justify-content-between mt-3">
                        <Col sm={12} md={12}>
                          <Row className="d-flex justify-content-between mt-3">
                            <Col
                              md={12}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <button
                                className="btn btn-sm btn-success align-items-right mt-2"
                                onClick={() => {
                                  handleClear();
                                }}
                                disabled={
                                  emailSubject === "" &&
                                  emailBody === "" &&
                                  emailFooter === ""
                                }
                              >
                                <i className="fa fa-eraser"></i> Clear
                              </button>
                              <button
                                onClick={() => {
                                  handleConfirmation("preview");
                                }}
                                disabled={disableButton}
                                className="btn btn-sm  btn-primary align-items-right mr-2 mt-2"
                              >
                                <i className="fa fa-eye"></i> Preview Email
                              </button>
                              <button
                                onClick={() => {
                                  handleConfirmation("sendEmail");
                                }}
                                style={{ float: "right" }}
                                disabled={disableButton}
                                className="btn btn-sm btn-success mr-2 mt-2"
                              >
                                {isSending ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  <i className="fa fa-envelope"></i>
                                )}{" "}
                                {isSending ? "Sending..." : "Send Email"}
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={4}
                  sm={12}
                  lg={4}
                  style={{
                    borderLeft: "1px solid #ccc",
                    height: "80vh",
                    overflow: "scroll",
                  }}
                >
                  <div>
                    {selectedRecipients?.length > 0 && (
                      <strong>
                        Selected
                        {selectedRecipients?.length > 1
                          ? " Recipients"
                          : " Recipient"}
                        {" : "}
                        {selectedRecipients?.length}
                      </strong>
                    )}
                  </div>
                  <div className="recipient-user-list">
                    {selectedRecipients?.length > 0 &&
                      (showAllRecipients
                        ? selectedRecipients.map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          ))
                        : selectedRecipients.slice(0, 5).map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          )))}
                    {selectedRecipients?.length > 5 && !showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(true)}
                      >
                        <span>View all</span>
                      </button>
                    )}
                    {selectedRecipients?.length > 5 && showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(false)}
                      >
                        <span>View less</span>
                      </button>
                    )}
                  </div>
                </Col>
              </>
            ))}

          {activeTab === "sent" && (
            <SentMails selectedRecipients={selectedRecipients} />
          )}
          {activeTab === "recipients" && (
            <RecipientsTable
              recipients={selectedTalents}
              setRecipients={setSelectedTalents}
              handleCandidateRemove={handleCandidateRemove}
            />
          )}
        </Row>
        {previewEmail && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Email Preview"}
            isModalOpen={previewEmail}
            setModalClose={setPreviewEmail}
            isFrom="EmailBroadcast"
            modalFor="preview"
            data={{
              footer: emailFooter,
              content: emailBody,
              subject: emailSubject,
              selectedCandidates: selectedRecipients.map((t) => t.email),
            }}
          />
        )}{" "}
      </Container>
    </>
  );
};

export default Email;
