import React, { useState, useEffect, useRef } from "react";
import { googleApiKey, googleMapApiJs } from "../../constants/Constants";
import CountrySelector from "./CountrySelector";

const GoogleLocationAPI = (props) => {
  const { setLocation } = props;
  const searchInput = useRef(null);
  const [countryCode, setCountryCode] = useState("");
  const [addressData, setAddressData] = useState(null);

  const loadAsyncScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const extractAddress = (place) => {
    const address = {
      address1: "",
      address2: "",
      address3: "",
      street: "",
      route: "",
      locality: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      if (types.includes("sublocality_level_1")) {
        address.address1 = value;
      }
      if (types.includes("sublocality_level_2" || "sublocality")) {
        address.address2 = value;
      }
      if (
        types.includes("point_of_interest" || "establishment" || "neighborhood")
      ) {
        address.address3 = value;
      }
      if (types.includes("street_number")) {
        address.street = value;
      }
      if (types.includes("route")) {
        address.route = value;
      }
      if (types.includes("locality" || "political")) {
        address.locality = value;
      }
      if (types.includes("administrative_area_level_2" || "political")) {
        address.city = value;
      }
      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }
      if (types.includes("postal_code")) {
        address.zip = value;
      }
      if (types.includes("country")) {
        address.country = value;
      }
    });
    return address;
  };

  // Address Change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    let addressData = extractAddress(place);
    setAddressData(addressData);
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;
    var options = {
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["establishment"],
      componentRestrictions: { country: countryCode?.toLowerCase() },
    };
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      options
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    if (countryCode) {
      loadAsyncScript(
        `${googleMapApiJs}?key=${googleApiKey}&libraries=places`,
        () => initAutocomplete()
      );
    }
    if (searchInput.current) {
      searchInput.current.value = "";
    }
  }, [countryCode, searchInput]);

  useEffect(() => {
    if (addressData) {
      setLocation(addressData);
    }
  }, [addressData]);

  return (
    <div className="card card-flat card-borderless bg-gray4 mb-3">
      <div className="card-body">
        <div className="row mx-2">
          <div className="col-lg-12 font-14">
            <div className="form-floating">
              <div className="searchLocation d-flex gap-1">
                <CountrySelector setCountryCode={setCountryCode} />
                <input
                  className="form-control"
                  ref={searchInput}
                  type="text"
                  placeholder="Search location...."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleLocationAPI;
