import Login from "../components/auth/Login";
import PageNotFound from "../components/error/404";
import PublicRecruiterRegistartion from "../components/recruiter/registration/public/Index";
import InviteRecruiterRegistartion from "../components/recruiter/registration/invite/Index";
import CareerJobDetails from "../components/disposition/job/career/jobdetails/Index";
import CareerHome from "../components/disposition/job/career/home/Index";
import JoinTalentPool from "../components/disposition/job/career/jobdetails/jointalentpool/Index";
import ApproveRejectCurator from "../components/curator/approverejectcurator/Index";
import CandidateForgotPassword from "../components/auth/CandidateForgotPassword";
import CommunityDetails from "../components/applycommunity/Index";
import ReadyToHireQA from "../components/applycommunity/RTH_Screening";
import AdditionalInfoReminder from "../components/applycommunity/AdditionalInfoReminder";
import UpdateAdditionalInfo from "../components/applycommunity/UpdateAdditionalInfo";
import CandidateModule from "../components/candidatemodule/Index";
import EditTalent from "../components/candidatemodule/edittalent/Index";

const PublicRouteList = [
  {
    path: "/",
    name: Login,
    isExact: true,
  },
  {
    path: "/RecruiterRegistrationForm/:Id",
    name: PublicRecruiterRegistartion,
    isExact: true,
  },
  {
    path: "/gigrecruiter/:Id",
    name: InviteRecruiterRegistartion,
    isExact: true,
  },
  {
    path: "/careers/profile",
    name: CandidateModule,
    isExact: true,
  },
  {
    path: "/career/jobs/:id/recruiter/:recruiterid",
    name: CareerJobDetails,
    isExact: true,
  },
  {
    path: "/careers/:tenant/recruiter/:recruiterid/jointalentpool",
    name: JoinTalentPool,
    isExact: true,
  },
  {
    path: "/career/home",
    name: CareerHome,
    isExact: true,
  },
  {
    path: "/curatorInvite/:recruiterId/:clientId/:communityId",
    name: ApproveRejectCurator,
    isExact: true,
  },
  {
    path: "/careers/forgot-passwordDS",
    name: CandidateForgotPassword,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/pooldescription/:id/recruiter/:recruiterid",
    name: CommunityDetails,
    isExact: true,
  },
  // {
  //   path:
  //     "/careers/:tenant/joblist/pooldescription/:id/recruiter/:recruiterid/screening/:candidateId/:dispositionId",
  //   name: Screening,
  //   isExact: true,
  // },
  {
    path: "/careers/joblist/pooldescription/:id/rthQA/:dispositionId",
    name: ReadyToHireQA,
    isExact: true,
  },
  {
    path: "/careers/joblist/pooldescription/:id/additionalQA/:userId",
    name: AdditionalInfoReminder,
    isExact: true,
  },
  {
    path: "/updateadditionalinfo/:poolId/pooldescription/:dispositionId",
    name: UpdateAdditionalInfo,
    isExact: true,
  },
  {
    path: "/careers/profile/edittalent",
    name: EditTalent,
    isExact: true,
  },
];

export default PublicRouteList;
