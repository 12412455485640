// DEFINE INITIAL STATE

import {
  GET_JOBS_BEGIN,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
} from "../../action/job/AddJob";

const initialState = {
  JobData: null,
  templateSelected: false,
  loading: false,
  jobList: null,
  error: null,
  templateDetails: null,
};

// REDUCER
const addJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_JOBS_BEGIN:
      return {
        ...state,
        jobList: null,
        loading: true,
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobList: action.payload,
        loading: false,
      };
    case GET_JOBS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case "JOB_DETAILS":
      return {
        ...state,
        JobData: action.payload,
      };
    case "TEMPLATE_DETAILS":
      return {
        ...state,
        templateDetails: action.payload,
      };
    case "TEMPLATE_SELECTED":
      return {
        ...state,
        templateSelected: action.payload,
      };

    default:
      return state;
  }
};

// EXPORT DEFAULT REDUCER
export default addJobReducer;
