import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import { getUserTenantID } from "../../../service/AuthService";

const AboutUs = ({ profile }) => {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [bio, setBio] = useState(profile);
  const [tempBio, setTempBio] = useState(profile);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  const fetchCompanyProfile = async () => {
    setLoading(true);
    const payload = {
      tenantId: getUserTenantID(),
    };
    let res = await post("/profile/getcompanydata", payload);
    setLoading(false);
    setBio(res.data.aboutus);
    setTempBio(res.data.aboutus);
  };

  const editCompanyProfile = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelCompanyProfile = () => {
    setBio(tempBio);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const saveCompanyProfile = async () => {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      const parameters = {
        profile: bio,
        tenantId: getUserTenantID(),
      };
      const res = await post("/profile/updatecompanydata", parameters);
      if (res.status === 200) {
        setTempBio(bio);
        setSubmittingFrom(false);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
      }
    } catch (error) {
      toast.error("Some Error Occured. Please Try Again");
      setSaveClicked(false);
      setSubmittingFrom(false);
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">About Us</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editCompanyProfile}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelCompanyProfile}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveCompanyProfile}
                      class="btn btn-sm btn-primary"
                      disabled={submittingForm}
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div class="col-lg-12 col-md-12 col-sm-12">
                {loading ? (
                  <h6 className="text-center">Fetching...</h6>
                ) : readOnlyMode ? (
                  <p className="m-2">
                    {bio || (
                      <span>
                        Company Profile Not Yet Updated. <br />
                        Please update it by clicking <strong>Edit</strong>{" "}
                        Button Above
                      </span>
                    )}
                  </p>
                ) : (
                  <div class="col-lg-12">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Type Here"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      ></textarea>
                      <label>My Profile</label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
