import React from "react";

const RejectionReason = ({ details, setReason, reason }) => {
  return (
    <>
      {details.rejectionReason && (
        <button
          onClick={() => setReason(!reason)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {!reason ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Reason for Rejection</small>
          </span>
        </button>
      )}
      <div className={reason ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <div class="d-flex mb-2 pl-1 ml-4">
          <p>{details.rejectionReason}</p>
        </div>
      </div>
    </>
  );
};

export default RejectionReason;
