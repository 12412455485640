import React from "react";
import { Modal } from "react-bootstrap";
import AddTalentManualEntry from "../../addtalent/manualentry/Index";

const TalentProfileEdit = (props) => {
  const { closeModal, data, handleClear, fetchData, editTalent } = props;
  const onClose = () => {
    closeModal(false);
  };

  return (
    <>
      <Modal size="lg" show={editTalent}>
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">Edit Talent</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <AddTalentManualEntry
            isFrom={"TalentProfileEdit"}
            isFor={"TalentPool"}
            closeModal={onClose}
            data={data}
            handleClear={handleClear}
            fetchData={fetchData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TalentProfileEdit;
