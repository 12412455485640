import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CommunityOptionModal from "./addcommunitymodal/CommunityOptionModal";
import FormSubmitSuccess from "../common/successanime/FormSubmitSuccess";
import { getTalentCommunity } from "../../action/talentcommunity/TalentCommunity";
import { useDispatch } from "react-redux";
import { getTenantType, getUserRole } from "../../service/AuthService";

const TalentCommunityHeader = () => {
  const dispatch = useDispatch();
  const [showAddPool, setShowAddPool] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: `Community ${
      getTenantType() === 1 ? "Published" : "Created"
    } Successfully!`,
    subTitle: ``,
    htmlTag: "",
    poolId: "",
  });

  const shareClose = (e) => {
    setSuccessFormOpen(false);
    setShowAddPool(false);
    // window.location.reload();
    const param = {
      currentPage: 1,
      sortQuery: "",
      pageSize: 9,
      searchText: "",
    };
    dispatch(getTalentCommunity(param));
  };

  return (
    <>
      <div class="d-flex align-items-center justify-content-between">
        <h6 class="mr-3 mb-0 mb-xl-0">
          <div className="d-flex justify-content-between mt-3">
            <h6>Client Talent Communities</h6>
          </div>
        </h6>

        {(getUserRole() == "CustomerSuccess" || getUserRole()=='SuperAdmin') && (
          <>
            <div
              style={{ fontSize: "14px", width: "150px" }}
              data-bs-placement="top"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <button
                type="button"
                onClick={() => {
                  setShowAddPool(true);
                }}
                className="btn btn-sm"
              >
                <i class="fas fa-plus"></i> Community
              </button>
            </div>
          </>
        )}
      </div>
      <Modal show={showAddPool} size="lg">
        <Modal.Header>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={shareClose}
            class="close p-0 bl-modal-close-btn"
            style={{ zIndex: "9999" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {successFormOpen ? (
            <FormSubmitSuccess
              successData={successData}
              handleSuccess={shareClose}
              isFrom="CreateSubCommunity"
            />
          ) : (
            <CommunityOptionModal
              setSuccessData={setSuccessData}
              setSuccessFormOpen={setSuccessFormOpen}
              setShowAddPool={setShowAddPool}
            ></CommunityOptionModal>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TalentCommunityHeader;
