import {
  GET_RECRUITER_FILTER_BEGIN,
  GET_RECRUITER_FILTER_SUCCESS,
  GET_RECRUITER_FILTER_FAILURE,
} from "../../../action/filter/talent/TalentRecruiterFilter";

const initialState = {
  recruiterLoading: false,
  recruiterData: null,
};

const getTalentRecruiterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_FILTER_BEGIN:
      return {
        ...state,
        recruiterLoading: true,
        recruiterData: null,
      };
    case GET_RECRUITER_FILTER_SUCCESS:
      return {
        ...state,
        recruiterData: payload,
        recruiterLoading: false,
      };
    case GET_RECRUITER_FILTER_FAILURE:
      return {
        ...state,
        recruiterData: payload,
        recruiterLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentRecruiterReducer;
