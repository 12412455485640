import Validator from "email-validator";

export const contactInfoValidation = (contactInfo, setContactVal) => {
  let firstNameError = "";
  let lastNameError = "";
  let emailError = "";
  let CountryNameError = "";
  let addressErr = "";
  var regex = /^[A-Za-z0-9 ]+$/;
  var regexs = /^[A-Z a-z\s.]+$/;
  if (contactInfo.firstName.trim().length === 0) {
    firstNameError = "*Required Field";
  } else if (!/^[A-Z a-z\s]+$/.test(contactInfo.firstName)) {
    firstNameError = "Invalid First Name";
  }
  if (contactInfo.firstName.trim() && !regex.test(contactInfo.firstName)) {
    firstNameError = "Special characters not allowed";
  }
  if (contactInfo.lastName.trim().length === 0) {
    lastNameError = "*Required Field";
  } else if (!/^[A-Z a-z\s]+$/.test(contactInfo.lastName)) {
    lastNameError = "Invalid Last Name";
  }
  if (contactInfo.lastName.trim() && !regex.test(contactInfo.lastName)) {
    lastNameError = "Special characters not allowed";
  }
  if (contactInfo.email.trim().length === 0) {
    emailError = "*Required Field";
  } else if (!Validator.validate(contactInfo.email)) {
    emailError = "*Enter Valid Email";
  }
  
  if (contactInfo.country === null) {
    CountryNameError = "*Required Field";
  } else if (contactInfo.country.trim().length === 0) {
    CountryNameError = "*Required Field";
  } else if (!regexs.test(contactInfo.country)) {
    CountryNameError = "Special characters & numbers not allowed";
  }
  if (contactInfo.address1?.trim() && !regex.test(contactInfo.address1)) {
    addressErr = "Special characters not allowed";
  }
  const validation = {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    country: CountryNameError,
  };
  return validation;
};

export const recruiterDetailsValidation = (data, setValidation) => {
  let industriesError = "";
  let subCategoryError = "";
  let regionsError = "";
  let languagesError = "";
  let skillsError = "";
  let yearsOfExperienceError = "";
  let jobTitleError = "";
  let educationError = "";
  let certificationError = "";

  if (!data.industries || data.industries.length == 0) {
    industriesError = "*Required field";
  }
  if (!data.subCategory || data.subCategory.length == 0) {
    subCategoryError = "*Required field";
  }
  if (!data.regions || data.regions.length == 0) {
    regionsError = "*Required field";
  }
  if (!data.languages || data.languages.length == 0) {
    languagesError = "*Required field";
  }
  if (!data.skills || data.skills.length == 0) {
    skillsError = "*Required field";
  }
  if (!data.yearsOfExperience) {
    yearsOfExperienceError = "*Required field";
  }
  if (!data.jobTitle.title1) {
    jobTitleError = "*Required field";
  }
  if (!data.education) {
    educationError = "*Required field";
  }
  if (!data.certification || data.certification.length == 0) {
    certificationError = "*Required field";
  }
  if (
    industriesError ||
    subCategoryError ||
    regionsError ||
    languagesError ||
    skillsError ||
    yearsOfExperienceError ||
    jobTitleError ||
    educationError ||
    certificationError
  ) {
    setValidation({
      yearsOfExperience: yearsOfExperienceError,
      jobTitle: jobTitleError,
      skills: skillsError,
      industries: industriesError,
      subCategory: subCategoryError,
      regions: regionsError,
      languages: languagesError,
      education: educationError,
      certification: certificationError,
    });
    return true;
  } else {
    setValidation({
      yearsOfExperience: "",
      jobTitle: "",
      skills: "",
      industries: "",
      regions: "",
      languages: "",
      education: "",
      certification: "",
    });
    return false;
  }
};