import PageNotFoundImage from "../images/high5-std.svg";
import SidebarLogo from "../images/high5-mark-circle.svg";
import LoginAppLogo from "../images/high5-std.svg";
import ButtonLoader from "../images/button-loader.gif";

export const apiDomainLocal = "http://localhost:5002";
export const apiDomainMain = "https://high5stagewebapp-api.azurewebsites.net";
export const googleApiKey = "AIzaSyD-YQ51wMkUugM_5--wxR18JoY6Kh_YWWI";
export const googleMapApiJs = "https://maps.googleapis.com/maps/api/js";
export const countryAPIurl = "https://restcountries.com/v3.1/all";
const isDevelopment = process.env.NODE_ENV === "production";
export const apiDomain = isDevelopment ? apiDomainMain : apiDomainLocal;
export const domain = "https://dev.high5hire.com";
export const ga_tracking_id = "G-LZ54RWXQTR";
export const githubClientId = "Iv1.ea6c9e6f3188d421";
export const githubClientSecret = "a13e4fd12ef38ebb81f59ec12f8afd0cd041d80d";
export const linkedInClientId = "77uhcseceaakxp";
export const linkedInClientSecret = "a71lZaOZdDapw1Vg";
export const vetStartUrl = "https://vetdev.high5hire.com";
export const emailDomain = "https://dev.high5hire.com";

export const roleNames = {
  HiringManager: "Hiring Manager",
  Recruiter: "Recruiter",
  Candidate: "Candidate",
  SuperAdmin: "Super Admin",
  CustomerSuccess: "High5 Admin",
  SalesAdmin: "Sales Admin",
  Admin: "Admin",
  High5Coordinator: "Customer Success Manager",
  CommunityManager: "Marketplace Manager",
};

export const recipients = [
  { label: "Candidate", value: "Candidate" },
  { label: "Client", value: "Client" },
  { label: "Customer Success Manager", value: "Customer Success Manager" },
  { label: "High5 Admin", value: "High5 Admin" },
  { label: "Hiring Manager", value: "Hiring Manager" },
  { label: "Marketplace Manager", value: "Marketplace Manager" },
  { label: "Recruiter", value: "Recruiter" },
];
export const publishJob = [
  { value: "public", label: "All Recruiters (Including Internal Recruiters)" },
  { value: "internal", label: "Internal Recruiters" },
];

export const visaStatus = [
  { value: "Green Card (GC)", label: "Green Card (GC)" },
  { value: "Green Card (GC) - EAD", label: "Green Card (GC) - EAD" },
  { value: "H-1B", label: "H-1B" },
  { value: "H-1B EAD", label: "H-1B EAD" },
  { value: "U.S. Citizen", label: "U.S. Citizen" },
  { value: "H-4 EAD", label: "H-4 EAD" },
  { value: "L-1", label: "L-1" },
  { value: "L2 EAD", label: "L2 EAD" },
  { value: "OPT EAD", label: "OPT EAD" },
  { value: "TN-1", label: "TN-1" },
  { value: "TN-2", label: "TN-2" },
  { value: "Canadian Citizen", label: "Canadian Citizen" },
  { value: "Canadian PR", label: "Canadian PR" },
  { value: "Canadian T4", label: "Canadian T4" },
  { value: "Permanent Resident", label: "Permanent Resident" },
  { value: "Citizen", label: "Citizen" },
  { value: "Authorised to work", label: "Authorised to work" },
];

export const additionalVisaStatus = [
  { value: "Not Required", label: "Not Applicable" },
  { value: "Need Sponsorship", label: "Need Sponsorship" },
];

export const jobTypeOptions = [
  { value: "Contract", label: "Contract" },
  { value: "Temp To Hire", label: "Temp To Hire" },
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Volunteer", label: "Volunteer" },
];

export const countryList = [
  "Afghanistan",
  "Åland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People'S Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia and Montenegro",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const healthCareQA = [
  {
    name: "Nursing Certification",
    question: "What certification/license do you have as a nurse?",
    input: [
      "Registered Nurse (RN)",
      "Licensed Practical Nurse (LPN)",
      "Certified Nursing Assistant (CNA)",
      "Medical Assistant (MA)",
      "Certified Medical Assistant (CMA)",
      "Registered Medical Assistant (RMA)",
      "Emergency Medical Technician (EMT)",
      "Certified Phlebotomy Technician (CPT)",
      "Pharmacy Technician Certification Exam (PTCE)",
      "Certified Surgical Technologist (CST)",
      "Physical Therapist Assistant (PTA)",
      "National Physical Therapy Exam (NPTE)",
      "Occupational Therapy Assistant (OTA)",
      "Certified Professional Coder (CPC)",
      "Certified Coding Specialist (CCS)",
      "American Registry of Radiologic Technologists (ARRT) Certification",
      "Basic Life Support (BLS)",
      "Advanced Cardiovascular Life Support (ACLS)",
      "Pediatric Advanced Life Support (PALS)",
      "Certified Emergency Nurse (CEN)",
      "Critical Care Registered Nurse (CCRN)",
      "Certified in Executive Nursing Practice (CENP)",
      "Family Nurse Practitioner (FNP-BC)",
      "Holistic Nurse",
      "Informatics Nursing Certification (RN-BC)",
      "Psychiatric-Mental Health Nurse Practitioner (Across the Lifespan) (PMHNP-BC)",
    ],
    isMandatory: false,
  },
  {
    name: "Nursing License",
    question:
      "In which state(s) are you currently licensed/certified as a nurse?",
    input: [],
    isMandatory: false,
  },
  {
    name: "Nursing Specialty",
    question: "What is your primary nursing specialty or area of expertise?",
    isMandatory: false,
    input: [
      "Medical-Surgical",
      "Pediatric",
      "Obstetric and Gynecological",
      "Neonatal",
      "Geriatric",
      "Psychiatric-Mental Health",
      "Critical Care (ICU, CCU, etc.)",
      "Emergency",
      "Oncology",
      "Cardiac",
      "Orthopedic",
      "Neurological",
      "Nephrology",
      "Gastroenterology",
      "Pulmonary",
      "Dermatology",
      "Infectious Disease",
      "Hematology",
      "Rehabilitation",
      "Perioperative (Surgical)",
      "Trauma",
      "Hospice and Palliative Care",
      "Diabetes",
      "Wound Care",
      "Surgical",
      "Pediatric Intensive Care",
      "Cardiovascular",
      "Plastic and Reconstructive Surgery",
      "Transplant",
      "Urology",
      "Gynecologic",
      "Endoscopy",
      "Pain Management",
      "Telemetry",
      "Radiology",
      "Burn Care",
      "Allergy and Immunology",
      "Genetics",
      "Dialysis",
      "Informatics Nurse",
      "Infection Control",
      "Public Health",
      "Informatics",
      "Ambulatory Care",
      "Infusion",
      "Gastrointestinal",
      "Rheumatology",
      "Hyperbaric",
      "Ophthalmic",
      "Reproductive Health",
      "Allergy and Asthma",
      "Chemotherapy",
      "Nephrology",
      "Post-Anesthesia Care Unit (PACU)",
      "Progressive Care unit (PCU)/Stepdown",
      "Bariatric",
      "Labor and Delivery",
    ],
  },
];

export const screeningArray = [
  {
    name: "Preferred Location",
    question: "What is your preferred job location?",
    isMandatory: false,
    input: [],
  },
  {
    name: "Certification",
    question: "Do you have any certification(s)?",
    input: ["Rn", "ACLS", "BLS", "CPR", "PALS", "NRP", "ONS/ONCC"],
    isMandatory: false,
  },
  {
    name: "Shift Preference",
    question: "What shift preference do you prefer?",
    input: ["8 hour (5 days)", "12 hours (3 days)", "Weekend"],
    isMandatory: false,
  },
  {
    name: "Work Preference",
    question:
      "Do you have a preference for working in a specific state or states?",
    input: [],
    isMandatory: false,
  },
  {
    name: "Education",
    question: "Please select the level of education you've completed.",
    input: [
      "Professional",
      "Doctorate",
      "Master's Degree",
      "Bachelor's Degree",
      "Some College Coursework Completed",
      "Vocational",
      "Certification",
      "Highschool or Equivalent",
      "Some Highschool Course Work",
      "BS/BSN",
      "MSN",
      "ADN/ASN",
      "LPN",
      "DNP",
      "PhdD",
      "RN Diploma",
      "Current Nursing Student",
      "NA",
    ],
    isMandatory: false,
  },
  {
    name: "Background Check",
    question:
      "Are you willing to undergo a background check, in accordance with local law/regulations?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "Drug Check",
    question:
      "Are you willing to take a drug test, in accordance with local law/regulations?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "Driving License",
    question: "Do you have a valid driver's license?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "License State",
    question: "In which state(s) is your driving license from?",
    input: [],
    isMandatory: false,
  },
  {
    name: "Industry Experience",
    question:
      "How many years of following Industry experience do you currently have?",
    input: "Information Technology",
    isMandatory: false,
  },
  {
    name: "Language",
    question: "What is your level of proficiency in following Languages?",
    input: [],
    isMandatory: false,
  },
  {
    name: "Work Experience",
    question: "How many years of experience do you currently have?",
    input: "Numeric",
    isMandatory: false,
  },
  {
    name: "Location",
    question: "Are you comfortable commuting to job's location?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "Remote Work",
    question: "Are you comfortable working in a remote setting?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "Urgent Hiring Need",
    question: "We must fill this position urgently. Can you start immediately?",
    isMandatory: false,
    input: "Yes/No",
  },
  {
    name: "Willing to relocate",
    question:
      "If you are willing to relocate, in which state(s) would you consider relocating to?",
    isMandatory: false,
    input: [],
  },
  {
    name: "Custom Question",
    index: 1,
    question: "",
    input: "Yes/No",
    isMandatory: false,
  },
];

export const additionalInfo = [
  {
    name: "Total Experience",
    question: "What is your total work experience? *",
    input: ["0-5 years", "5-7 years", "7-10 years", "10+ years"],
    options: [],
    isMandatory: false,
  },
  {
    name: "Country Experience",
    question: "How many years of work experience do you have in the [X]? *",
    input: ["0-5 years", "5-7 years", "7-10 years", "10+ years"],
    options: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo (Congo-Brazzaville)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czechia (Czech Republic)",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini (fmr. Swaziland)",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Holy See",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (formerly Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine State",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Availability",
    question: "What is your availability for employment? *",
    input: [
      "Immediate",
      "1 month",
      "2 months",
      "3 months",
      "More than 3 months",
    ],
    options: [],
    isMandatory: false,
  },
  {
    name: "Visa Status",
    question: "What is your current [X] visa status? *",
    input: [
      "Canadian - Citizen",
      "Canadian - PR",
      "Canadian - T4",
      "UAE - Citizen",
      "UAE - Dependent Visa",
      "UAE - Employment Visa",
      "UAE - Golden Visa",
      "UAE - Tourist Visa",
      "USA - Citizen",
      "USA - Green Card (GC)",
      "USA - Green Card (GC) - EAD",
      "USA - H-1B",
      "USA - H-1B EAD",
      "USA - H-4 EAD",
      "USA - L-1",
      "USA - L2 EAD",
      "USA - OPT EAD",
      "USA - TN-1",
      "USA - TN-2",
      "USA - U.S. Citizen",
      "Other",
    ],
    options: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua and Barbuda",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia and Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina Faso",
      "Burundi",
      "Cabo Verde",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Central African Republic",
      "Chad",
      "Chile",
      "China",
      "Colombia",
      "Comoros",
      "Congo (Congo-Brazzaville)",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czechia (Czech Republic)",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Eswatini (fmr. Swaziland)",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Holy See",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland",
      "Israel",
      "Italy",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar (formerly Burma)",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "North Korea",
      "North Macedonia",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Palestine State",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russia",
      "Rwanda",
      "Saint Kitts and Nevis",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome and Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Korea",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Sweden",
      "Switzerland",
      "Syria",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Timor-Leste",
      "Togo",
      "Tonga",
      "Trinidad and Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States of America",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Language Skills",
    question: "How would you rate your [X] skills in reading/writing? *",
    input: ["Fluent", "Intermediate", "Beginner", "None"],
    options: [
      "Abkhaz",
      "Afar",
      "Afrikaans",
      "Akan",
      "Albanian",
      "Amharic",
      "Arabic",
      "Aragonese",
      "Armenian",
      "Assamese",
      "Avaric",
      "Avestan",
      "Aymara",
      "Azerbaijani",
      "Bambara",
      "Bashkir",
      "Basque",
      "Belarusian",
      "Bengali",
      "Bihari",
      "Bislama",
      "Bosnian",
      "Breton",
      "Bulgarian",
      "Burmese",
      "Chamorro",
      "Chechen",
      "Chinese",
      "Chuvash",
      "Cornish",
      "Corsican",
      "Cree",
      "Croatian",
      "Czech",
      "Danish",
      "Dutch",
      "English",
      "Esperanto",
      "Estonian",
      "Ewe",
      "Faroese",
      "Fijian",
      "Finnish",
      "French",
      "Galician",
      "Georgian",
      "German",
      "Guaraní",
      "Gujarati",
      "Hausa",
      "Hebrew",
      "Hebrew",
      "Herero",
      "Hindi",
      "Hiri Motu",
      "Hungarian",
      "Interlingua",
      "Indonesian",
      "Interlingue",
      "Irish",
      "Igbo",
      "Inupiaq",
      "Ido",
      "Icelandic",
      "Italian",
      "Inuktitut",
      "Japanese",
      "Javanese",
      "Kannada",
      "Kanuri",
      "Kashmiri",
      "Kazakh",
      "Khmer",
      "Kinyarwanda",
      "Komi",
      "Kongo",
      "Korean",
      "Kurdish",
      "Latin",
      "Luganda",
      "Lingala",
      "Lao",
      "Lithuanian",
      "Luba-Katanga",
      "Latvian",
      "Manx",
      "Macedonian",
      "Malagasy",
      "Malay",
      "Malayalam",
      "Maltese",
      "Māori",
      "Marathi",
      "Marshallese",
      "Mongolian",
      "Nauru",
      "Navajo",
      "Norwegian Bokmål",
      "North Ndebele",
      "Nepali",
      "Ndonga",
      "Norwegian Nynorsk",
      "Norwegian",
      "Nuosu",
      "South Ndebele",
      "Occitan",
      "Ojibwe",
      "Oromo",
      "Oriya",
      "Ossetian",
      "Punjabi",
      "Pāli",
      "Persian",
      "Polish",
      "Pashto, Pushto",
      "Portuguese",
      "Quechua",
      "Romansh",
      "Kirundi",
      "Russian",
      "Sanskrit",
      "Sardinian",
      "Sindhi",
      "Northern Sami",
      "Samoan",
      "Sango",
      "Serbian",
      "Scottish Gaelic; Gaelic",
      "Shona",
      "Sinhala, Sinhalese",
      "Slovak",
      "Slovene",
      "Somali",
      "Southern Sotho",
      "Spanish",
      "Sundanese",
      "Swahili",
      "Swati",
      "Swedish",
      "Tamil",
      "Telugu",
      "Tajik",
      "Thai",
      "Tigrinya",
      "Tibetan Standard, Tibetan, Central",
      "Turkmen",
      "Tagalog",
      "Tswana",
      "Tonga (Tonga Islands)",
      "Turkish",
      "Tsonga",
      "Tatar",
      "Twi",
      "Tahitian",
      "Uighur, Uyghur",
      "Ukrainian",
      "Urdu",
      "Uzbek",
      "Venda",
      "Vietnamese",
      "Volapük",
      "Walloon",
      "Welsh",
      "Wolof",
      "Western Frisian",
      "Xhosa",
      "Yiddish",
      "Yoruba",
      "Zhuang, Chuang",
    ],
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Nationality",
    question: "What is your nationality? *",
    input: [
      "Afghan",
      "Albanian",
      "Algerian",
      "American",
      "Andorran",
      "Angolan",
      "Antiguans",
      "Argentinean",
      "Armenian",
      "Australian",
      "Austrian",
      "Azerbaijani",
      "Bahamian",
      "Bahraini",
      "Bangladeshi",
      "Barbadian",
      "Barbudans",
      "Batswana",
      "Belarusian",
      "Belgian",
      "Belizean",
      "Beninese",
      "Bhutanese",
      "Bolivian",
      "Bosnian",
      "Brazilian",
      "British",
      "Bruneian",
      "Bulgarian",
      "Burkinabe",
      "Burmese",
      "Burundian",
      "Cambodian",
      "Cameroonian",
      "Canadian",
      "Cape Verdean",
      "Central African",
      "Chadian",
      "Chilean",
      "Chinese",
      "Colombian",
      "Comoran",
      "Congolese",
      "Costa Rican",
      "Croatian",
      "Cuban",
      "Cypriot",
      "Czech",
      "Danish",
      "Djibouti",
      "Dominican",
      "Dutch",
      "East Timorese",
      "Ecuadorean",
      "Egyptian",
      "Emirian",
      "Equatorial Guinean",
      "Eritrean",
      "Estonian",
      "Ethiopian",
      "Fijian",
      "Filipino",
      "Finnish",
      "French",
      "Gabonese",
      "Gambian",
      "Georgian",
      "German",
      "Ghanaian",
      "Greek",
      "Grenadian",
      "Guatemalan",
      "Guinea-Bissauan",
      "Guinean",
      "Guyanese",
      "Haitian",
      "Herzegovinian",
      "Honduran",
      "Hungarian",
      "I-Kiribati",
      "Icelander",
      "Indian",
      "Indonesian",
      "Iranian",
      "Iraqi",
      "Irish",
      "Israeli",
      "Italian",
      "Ivorian",
      "Jamaican",
      "Japanese",
      "Jordanian",
      "Kazakhstani",
      "Kenyan",
      "Kittian and Nevisian",
      "Kuwaiti",
      "Kyrgyz",
      "Laotian",
      "Latvian",
      "Lebanese",
      "Liberian",
      "Libyan",
      "Liechtensteiner",
      "Lithuanian",
      "Luxembourger",
      "Macedonian",
      "Malagasy",
      "Malawian",
      "Malaysian",
      "Maldivan",
      "Malian",
      "Maltese",
      "Marshallese",
      "Mauritanian",
      "Mauritian",
      "Mexican",
      "Micronesian",
      "Moldovan",
      "Monacan",
      "Mongolian",
      "Moroccan",
      "Mosotho",
      "Motswana",
      "Mozambican",
      "Namibian",
      "Nauruan",
      "Nepalese",
      "New Zealander",
      "Ni-Vanuatu",
      "Nicaraguan",
      "Nigerian",
      "Nigerien",
      "North Korean",
      "Northern Irish",
      "Norwegian",
      "Omani",
      "Pakistani",
      "Palauan",
      "Panamanian",
      "Papua New Guinean",
      "Paraguayan",
      "Peruvian",
      "Polish",
      "Portuguese",
      "Qatari",
      "Romanian",
      "Russian",
      "Rwandan",
      "Saint Lucian",
      "Salvadoran",
      "Samoan",
      "San Marinese",
      "Sao Tomean",
      "Saudi",
      "Scottish",
      "Senegalese",
      "Serbian",
      "Seychellois",
      "Sierra Leonean",
      "Singaporean",
      "Slovakian",
      "Slovenian",
      "Solomon Islander",
      "Somali",
      "South African",
      "South Korean",
      "Spanish",
      "Sri Lankan",
      "Sudanese",
      "Surinamer",
      "Swazi",
      "Swedish",
      "Swiss",
      "Syrian",
      "Taiwanese",
      "Tajik",
      "Tanzanian",
      "Thai",
      "Togolese",
      "Tongan",
      "Trinidadian or Tobagonian",
      "Tunisian",
      "Turkish",
      "Tuvaluan",
      "Ugandan",
      "Ukrainian",
      "Uruguayan",
      "Uzbekistani",
      "Venezuelan",
      "Vietnamese",
      "Welsh",
      "Yemenite",
      "Zambian",
      "Zimbabwean",
    ],
    options: [],
    isMandatory: false,
  },
  {
    name: "Work mode",
    question: "What is your preferred work mode? *",
    input: ["Remote", "Hybrid", "On-site"],
    options: [],
    isMandatory: false,
  },
  {
    name: "Salary Requirements",
    question: "What are your salary requirements in [X]? *",
    input: [],
    options: [
      "AED", // United Arab Emirates Dirham
      "AFN", // Afghan Afghani
      "ALL", // Albanian Lek
      "AMD", // Armenian Dram
      "ANG", // Netherlands Antillean Guilder
      "AOA", // Angolan Kwanza
      "ARS", // Argentine Peso
      "AUD", // Australian Dollar
      "AWG", // Aruban Florin
      "AZN", // Azerbaijani Manat
      "BAM", // Bosnia-Herzegovina Convertible Mark
      "BBD", // Barbadian Dollar
      "BDT", // Bangladeshi Taka
      "BGN", // Bulgarian Lev
      "BHD", // Bahraini Dinar
      "BIF", // Burundian Franc
      "BMD", // Bermudian Dollar
      "BND", // Brunei Dollar
      "BOB", // Bolivian Boliviano
      "BRL", // Brazilian Real
      "BSD", // Bahamian Dollar
      "BTN", // Bhutanese Ngultrum
      "BWP", // Botswana Pula
      "BYN", // Belarusian Ruble
      "BZD", // Belize Dollar
      "CAD", // Canadian Dollar
      "CDF", // Congolese Franc
      "CHF", // Swiss Franc
      "CLP", // Chilean Peso
      "CNY", // Chinese Yuan
      "COP", // Colombian Peso
      "CRC", // Costa Rican Colon
      "CUP", // Cuban Peso
      "CVE", // Cape Verdean Escudo
      "CZK", // Czech Koruna
      "DJF", // Djiboutian Franc
      "DKK", // Danish Krone
      "DOP", // Dominican Peso
      "DZD", // Algerian Dinar
      "EGP", // Egyptian Pound
      "ERN", // Eritrean Nakfa
      "ETB", // Ethiopian Birr
      "EUR", // Euro
      "FJD", // Fijian Dollar
      "FKP", // Falkland Islands Pound
      "GBP", // British Pound Sterling
      "GEL", // Georgian Lari
      "GHS", // Ghanaian Cedi
      "GIP", // Gibraltar Pound
      "GMD", // Gambian Dalasi
      "GNF", // Guinean Franc
      "GTQ", // Guatemalan Quetzal
      "GYD", // Guyanaese Dollar
      "HKD", // Hong Kong Dollar
      "HNL", // Honduran Lempira
      "HRK", // Croatian Kuna
      "HTG", // Haitian Gourde
      "HUF", // Hungarian Forint
      "IDR", // Indonesian Rupiah
      "ILS", // Israeli New Sheqel
      "INR", // Indian Rupee
      "IQD", // Iraqi Dinar
      "IRR", // Iranian Rial
      "ISK", // Icelandic Krona
      "JMD", // Jamaican Dollar
      "JOD", // Jordanian Dinar
      "JPY", // Japanese Yen
      "KES", // Kenyan Shilling
      "KGS", // Kyrgystani Som
      "KHR", // Cambodian Riel
      "KMF", // Comorian Franc
      "KPW", // North Korean Won
      "KRW", // South Korean Won
      "KWD", // Kuwaiti Dinar
      "KYD", // Cayman Islands Dollar
      "KZT", // Kazakhstani Tenge
      "LAK", // Laotian Kip
      "LBP", // Lebanese Pound
      "LKR", // Sri Lankan Rupee
      "LRD", // Liberian Dollar
      "LSL", // Lesotho Loti
      "LYD", // Libyan Dinar
      "MAD", // Moroccan Dirham
      "MDL", // Moldovan Leu
      "MGA", // Malagasy Ariary
      "MKD", // Macedonian Denar
      "MMK", // Myanmar Kyat
      "MNT", // Mongolian Tugrik
      "MOP", // Macanese Pataca
      "MRU", // Mauritanian Ouguiya
      "MUR", // Mauritian Rupee
      "MVR", // Maldivian Rufiyaa
      "MWK", // Malawian Kwacha
      "MXN", // Mexican Peso
      "MYR", // Malaysian Ringgit
      "MZN", // Mozambican Metical
      "NAD", // Namibian Dollar
      "NGN", // Nigerian Naira
      "NIO", // Nicaraguan Cordoba
      "NOK", // Norwegian Krone
      "NPR", // Nepalese Rupee
      "NZD", // New Zealand Dollar
      "OMR", // Omani Rial
      "PAB", // Panamanian Balboa
      "PEN", // Peruvian Nuevo Sol
      "PGK", // Papua New Guinean Kina
      "PHP", // Philippine Peso
      "PKR", // Pakistani Rupee
      "PLN", // Polish Zloty
      "PYG", // Paraguayan Guarani
      "QAR", // Qatari Riyal
      "RON", // Romanian Leu
      "RSD", // Serbian Dinar
      "RUB", // Russian Ruble
      "RWF", // Rwandan Franc
      "SAR", // Saudi Riyal
      "SBD", // Solomon Islands Dollar
      "SCR", // Seychellois Rupee
      "SDG", // Sudanese Pound
      "SEK", // Swedish Krona
      "SGD", // Singapore Dollar
      "SHP", // Saint Helena Pound
      "SLL", // Sierra Leonean Leone
      "SOS", // Somali Shilling
      "SRD", // Surinamese Dollar
      "STN", // Sao Tome and Principe Dobra
      "SVC", // Salvadoran Colon
      "SYP", // Syrian Pound
      "SZL", // Swazi Lilangeni
      "THB", // Thai Baht
      "TJS", // Tajikistani Somoni
      "TMT", // Turkmenistani Manat
      "TND", // Tunisian Dinar
      "TOP", // Tongan Pa'anga
      "TRY", // Turkish Lira
      "TTD", // Trinidad and Tobago Dollar
      "TWD", // New Taiwan Dollar
      "TZS", // Tanzanian Shilling
      "UAH", // Ukrainian Hryvnia
      "UGX", // Ugandan Shilling
      "USD", // United States Dollar
      "UYU", // Uruguayan Peso
      "UZS", // Uzbekistan Som
      "VEF", // Venezuelan Bolivar
      "VND", // Vietnamese Dong
      "VUV", // Vanuatu Vatu
      "WST", // Samoan Tala
      "XAF", // Central African CFA Franc
      "XCD", // East Caribbean Dollar
      "XOF", // West African CFA Franc
      "XPF", // CFP Franc
      "YER", // Yemeni Rial
      "ZAR", // South African Rand
      "ZMW", // Zambian Kwacha
      "ZWL", // Zimbabwean Dollar
    ],
    selectedOption: "",
    isMandatory: false,
  },
];

export const screeningQuestionsForSubCommunity = [
  {
    name: "Location Preference",

    question: "Are you currently located or willing to relocate to [Location]?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Education",
    question: "Do you currently have [Education] or Higher?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Experience",
    question:
      "Do you have [ExperienceLevel] of experience in [specificIndustry]?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Skills and Proficiency",
    question: "Are you proficient in [X]?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Visa/Work Authorization",
    question: "Are you legally authorized to work in the [Country]?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Drug Test",
    question: "Are you willing to undergo a pre-employment drug screening?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "Background Check",
    question:
      "Are you willing to undergo a background check as a condition of employment?",
    options: [],
    input: "Yes/No",
    selectedOption: "",
    isMandatory: false,
  },
  {
    name: "License/Certification",
    question: "Do you hold the [X] License(s)/Certification(s)?",
    options: [],
    selectedOption: "",
    input: "Yes/No",

    isMandatory: false,
  },
];

export const readyToHireScreening = [
  {
    name: "Custom Question",
    index: 1,
    question: "",
    input: "Yes/No",
    isMandatory: false,
  },
];

export const toolbarRTEConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const activityOptions = [
  { value: "Hyperactive", label: "Hyperactive" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Never", label: "Never Logged In" },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const skillOptions = [
  { value: "All", label: "All" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Python Developer", label: "Python Developer" },
  { value: "C++ Developer", label: "C++ Developer" },
  { value: "Ruby Developer", label: "Ruby Developer" },
  { value: "PHP Developer", label: "PHP Developer" },
  { value: "C# Developer", label: "C# Developer" },
  { value: "JavaScript Developer", label: "JavaScript Developer" },
  { value: "HTML/CSS Developer", label: "HTML/CSS Developer" },
  { value: "JavaScript Developer", label: "JavaScript Developer" },
  { value: "React Developer", label: "React Developer" },
  { value: "Angular Developer", label: "Angular Developer" },
  { value: "Vue.js Developer", label: "Vue.js Developer" },
  { value: "Node.js Developer", label: "Node.js Developer" },
  {
    value: "Ruby on Rails Developer",
    label: "Ruby on Rails Developer",
  },
  { value: "ASP.NET Developer", label: "ASP.NET Developer" },
  { value: "Django Developer", label: "Django Developer" },
  { value: "Laravel Developer", label: "Laravel Developer" },
  {
    value: "iOS Developer (Swift/ObjectiveC)",
    label: "iOS Developer (Swift/ObjectiveC)",
  },
  {
    value: "Android Developer (Java/Kotlin)",
    label: "Android Developer (Java/Kotlin)",
  },
  { value: "React Native Developer", label: "React Native Developer" },
  { value: "Flutter Developer", label: "Flutter Developer" },
  { value: "SQL Developer", label: "SQL Developer" },
  { value: "MySQL Developer", label: "MySQL Developer" },
  { value: "PostgreSQL Developer", label: "PostgreSQL Developer" },
  { value: "Oracle Developer", label: "Oracle Developer" },
  { value: "MongoDB Developer", label: "MongoDB Developer" },
  { value: "MEAN Stack Developer", label: "MEAN Stack Developer" },
  { value: "MERN Stack Developer", label: "MERN Stack Developer" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "AWS Cloud Engineer", label: "AWS Cloud Engineer" },
  { value: "Azure Cloud Engineer", label: "Azure Cloud Engineer" },
  { value: "Google Cloud Engineer", label: "Google Cloud Engineer" },
  {
    value: "Docker/Kubernetes Engineer",
    label: "Docker/Kubernetes Engineer",
  },
  { value: "UI Designer", label: "UI Designer" },
  { value: "UX Designer", label: "UX Designer" },
  { value: "Interaction Designer", label: "Interaction Designer" },
  { value: "Visual Designer", label: "Visual Designer" },
  { value: "Usability Tester", label: "Usability Tester" },
  { value: "QA Engineer", label: "QA Engineer" },
  {
    value: "Test Automation Engineer",
    label: "Test Automation Engineer",
  },
  { value: "Manual Tester", label: "Manual Tester" },
  { value: "Performance Tester", label: "Performance Tester" },
  { value: "Security Tester", label: "Security Tester" },
  { value: "Data Scientist", label: "Data Scientist" },
  {
    value: "Machine Learning Engineer",
    label: "Machine Learning Engineer",
  },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "Big Data Engineer", label: "Big Data Engineer" },
  {
    value: "Business Intelligence Developer",
    label: "Business Intelligence Developer",
  },
  { value: "Physician", label: "Physician" },
  { value: "Surgeon", label: "Surgeon" },
  { value: "Anesthesiologist", label: "Anesthesiologist" },
  { value: "Cardiologist", label: "Cardiologist" },
  { value: "Dermatologist", label: "Dermatologist" },
  { value: "Gastroenterologist", label: "Gastroenterologist" },
  { value: "Neurologist", label: "Neurologist" },
  { value: "Pediatrician", label: "Pediatrician" },
  { value: "Radiologist", label: "Radiologist" },
  { value: "Psychiatrist", label: "Psychiatrist" },
  { value: "Oncologist", label: "Oncologist" },
  { value: "Ophthalmologist", label: "Ophthalmologist" },
  { value: "Orthopedic Surgeon", label: "Orthopedic Surgeon" },
  { value: "Urologist", label: "Urologist" },
  {
    value: "Obstetrician/Gynecologist (OB/GYN)",
    label: "Obstetrician/Gynecologist (OB/GYN)",
  },
  {
    value: "Family Medicine Physician",
    label: "Family Medicine Physician",
  },
  {
    value: "Internal Medicine Physician",
    label: "Internal Medicine Physician",
  },
  {
    value: "Emergency Medicine Physician",
    label: "Emergency Medicine Physician",
  },
  { value: "General Practitioner", label: "General Practitioner" },
  { value: "Nurse Practitioner", label: "Nurse Practitioner" },
  { value: "Registered Nurse (RN)", label: "Registered Nurse (RN)" },
  {
    value: "Licensed Practical Nurse (LPN)",
    label: "Licensed Practical Nurse (LPN)",
  },
  {
    value: "Certified Nursing Assistant (CNA)",
    label: "Certified Nursing Assistant (CNA)",
  },
  { value: "Nurse Anesthetist", label: "Nurse Anesthetist" },
  { value: "Nurse Midwife", label: "Nurse Midwife" },
  {
    value: "Clinical Nurse Specialist",
    label: "Clinical Nurse Specialist",
  },
  { value: "Nurse Educator", label: "Nurse Educator" },
  { value: "Medical Assistant", label: "Medical Assistant" },
  {
    value: "Medical Laboratory Technologist",
    label: "Medical Laboratory Technologist",
  },
  {
    value: "Radiologic Technologist",
    label: "Radiologic Technologist",
  },
  { value: "Pharmacy Technician", label: "Pharmacy Technician" },
  { value: "Pharmacist", label: "Pharmacist" },
  { value: "Physical Therapist", label: "Physical Therapist" },
  { value: "Occupational Therapist", label: "Occupational Therapist" },
  {
    value: "Speech-Language Pathologist",
    label: "Speech-Language Pathologist",
  },
  { value: "Respiratory Therapist", label: "Respiratory Therapist" },
  { value: "Dietitian/Nutritionist", label: "Dietitian/Nutritionist" },
  { value: "Medical Social Worker", label: "Medical Social Worker" },
  { value: "Clinical Psychologist", label: "Clinical Psychologist" },
  {
    value: "Medical Transcriptionist",
    label: "Medical Transcriptionist",
  },
  { value: "Medical Coder", label: "Medical Coder" },
  {
    value: "Health Information Technician",
    label: "Health Information Technician",
  },
  {
    value: "Health Services Administrator",
    label: "Health Services Administrator",
  },
  {
    value: "Health Informatics Specialist",
    label: "Health Informatics Specialist",
  },
  { value: "Health Policy Analyst", label: "Health Policy Analyst" },
  { value: "Health Educator", label: "Health Educator" },
  { value: "Biomedical Engineer", label: "Biomedical Engineer" },
  {
    value: "Medical Sales Representative",
    label: "Medical Sales Representative",
  },
  { value: "Medical Writer", label: "Medical Writer" },
  { value: "Healthcare Consultant", label: "Healthcare Consultant" },
  {
    value: "Chief Financial Officer (CFO)",
    label: "Chief Financial Officer (CFO)",
  },
  { value: "Finance Director", label: "Finance Director" },
  { value: "Financial Controller", label: "Financial Controller" },
  { value: "Treasury Manager", label: "Treasury Manager" },
  { value: "Risk Manager", label: "Risk Manager" },
  { value: "Investment Analyst", label: "Investment Analyst" },
  { value: "Portfolio Manager", label: "Portfolio Manager" },
  { value: "Financial Planner", label: "Financial Planner" },
  { value: "Credit Analyst", label: "Credit Analyst" },
  {
    value: "Equity Research Analyst",
    label: "Equity Research Analyst",
  },
  {
    value: "Investment Banking Analyst",
    label: "Investment Banking Analyst",
  },
  { value: "Fund Accountant", label: "Fund Accountant" },
  { value: "Tax Manager", label: "Tax Manager" },
  { value: "Internal Auditor", label: "Internal Auditor" },
  { value: "Compliance Officer", label: "Compliance Officer" },
  { value: "Financial Analyst", label: "Financial Analyst" },
  { value: "Budget Analyst", label: "Budget Analyst" },
  { value: "Cost Accountant", label: "Cost Accountant" },
  { value: "Payroll Manager", label: "Payroll Manager" },
  {
    value: "Accounts Payable Manager",
    label: "Accounts Payable Manager",
  },
  {
    value: "Accounts Receivable Manager",
    label: "Accounts Receivable Manager",
  },
  {
    value: "Financial Reporting Manager",
    label: "Financial Reporting Manager",
  },
  {
    value: "Corporate Finance Manager",
    label: "Corporate Finance Manager",
  },
  {
    value: "Financial Systems Analyst",
    label: "Financial Systems Analyst",
  },
  {
    value: "Mergers and Acquisitions Specialist",
    label: "Mergers and Acquisitions Specialist",
  },
  { value: "Derivatives Trader", label: "Derivatives Trader" },
  { value: "Fixed Income Analyst", label: "Fixed Income Analyst" },
  { value: "Wealth Manager", label: "Wealth Manager" },
  { value: "Insurance Underwriter", label: "Insurance Underwriter" },
  { value: "Actuary", label: "Actuary" },
  { value: "Real Estate Analyst", label: "Real Estate Analyst" },
  { value: "Financial Consultant", label: "Financial Consultant" },
  { value: "Quantitative Analyst", label: "Quantitative Analyst" },
  { value: "Financial Risk Analyst", label: "Financial Risk Analyst" },
  {
    value: "Investment Operations Analyst",
    label: "Investment Operations Analyst",
  },
  {
    value: "Venture Capital Analyst",
    label: "Venture Capital Analyst",
  },
  { value: "Private Equity Analyst", label: "Private Equity Analyst" },
  { value: "Hedge Fund Analyst", label: "Hedge Fund Analyst" },
  { value: "Credit Risk Manager", label: "Credit Risk Manager" },
  {
    value: "Derivatives Risk Manager",
    label: "Derivatives Risk Manager",
  },
  { value: "Treasury Analyst", label: "Treasury Analyst" },
  {
    value: "Commercial Banking Relationship Manager",
    label: "Commercial Banking Relationship Manager",
  },
  {
    value: "Retail Banking Branch Manager",
    label: "Retail Banking Branch Manager",
  },
  {
    value: "Financial Operations Manager",
    label: "Financial Operations Manager",
  },
  {
    value: "Financial Planning and Analysis Manager",
    label: "Financial Planning and Analysis Manager",
  },
  {
    value: "Investor Relations Manager",
    label: "Investor Relations Manager",
  },
  { value: "Pricing Analyst", label: "Pricing Analyst" },
  { value: "Compliance Analyst", label: "Compliance Analyst" },
  {
    value: "Financial Modelling Analyst",
    label: "Financial Modelling Analyst",
  },
  {
    value: "Asset Management Associate",
    label: "Asset Management Associate",
  },
];

export const inActiveOptions = [
  { value: "3", label: "3 Weeks" },
  { value: "4", label: "4 Weeks" },
  { value: "5", label: "5 Weeks" },
  { value: "6", label: "6 Weeks" },
  { value: "7", label: "7 Weeks" },
  { value: "8", label: "8 Weeks" },
  { value: "9", label: "9 Weeks" },
  { value: "10", label: "10 Weeks" },
  { value: "11", label: "11 Weeks" },
  { value: "12", label: "12 Weeks" },
];

export const industryCategories = [
  { jobName: "All jobs", path: "all-jobs" },
  { jobName: "Information Technology", path: "Information-Technology" },
  { jobName: "Healthcare", path: "Healthcare" },
  { jobName: "Accounting and Finance", path: "Accounting-and-Finance" },
  {
    jobName: "Administrative and Office Support",
    path: "Administrative-and-Office-Support",
  },
  { jobName: "Clinical and Scientific", path: "Clinical-and-Scientific" },
  {
    jobName: "Customer Service and Call Center",
    path: "Customer-Service-and-Call-Center",
  },
  { jobName: "Data Entry and Clerical", path: "Data-Entry-and-Clerical" },
  { jobName: "Engineering", path: "Engineering" },
  { jobName: "Human Resources (HR)", path: "Human-Resources-HR" },
  { jobName: "Legal", path: "Legal" },
  {
    jobName: "Manufacturing and Production",
    path: "Manufacturing-and-Production",
  },
  { jobName: "Marketing and Creative", path: "Marketing-and-Creative" },
  { jobName: "Sales", path: "Sales" },
];

export const careerFilterCategories = [
  "Information Technology (IT)",
  "Healthcare",
  "Accounting and Finance",
  "Administrative and Office Support",
  "Clinical and Scientific",
  "Customer Service and Call Center",
  "Data Entry and Clerical",
  "Engineering",
  "Human Resources (HR)",
  "Legal",
  "Manufacturing and Production",
  "Marketing and Creative",
];

export const experienceLevel = [
  "Intern",
  "Less than one year",
  "1-3 years",
  "3-5 years",
  "5-10 years",
  "10+ years",
];

export const educationTypes = [
  "Select",
  "Bachelor's Degree",
  "Certification",
  "Doctorate",
  "Highschool or Equivalent",
  "Master's Degree",
  "Professional",
  "Some College Coursework Completed",
  "Some Highschool Course Work",
  "Vocational",
  "NA",
];

export const languages = [
  "Abkhaz",
  "Afar",
  "Afrikaans",
  "Akan",
  "Albanian",
  "Amharic",
  "Arabic",
  "Aragonese",
  "Armenian",
  "Assamese",
  "Avaric",
  "Avestan",
  "Aymara",
  "Azerbaijani",
  "Bambara",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bihari",
  "Bislama",
  "Bosnian",
  "Breton",
  "Bulgarian",
  "Burmese",
  "Chamorro",
  "Chechen",
  "Chinese",
  "Chuvash",
  "Cornish",
  "Corsican",
  "Cree",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Faroese",
  "Fijian",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Guaraní",
  "Gujarati",
  "Hausa",
  "Hebrew",
  "Hebrew",
  "Herero",
  "Hindi",
  "Hiri Motu",
  "Hungarian",
  "Interlingua",
  "Indonesian",
  "Interlingue",
  "Irish",
  "Igbo",
  "Inupiaq",
  "Ido",
  "Icelandic",
  "Italian",
  "Inuktitut",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kanuri",
  "Kashmiri",
  "Kazakh",
  "Khmer",
  "Kinyarwanda",
  "Komi",
  "Kongo",
  "Korean",
  "Kurdish",
  "Latin",
  "Luganda",
  "Lingala",
  "Lao",
  "Lithuanian",
  "Luba-Katanga",
  "Latvian",
  "Manx",
  "Macedonian",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Māori",
  "Marathi",
  "Marshallese",
  "Mongolian",
  "Nauru",
  "Navajo",
  "Norwegian Bokmål",
  "North Ndebele",
  "Nepali",
  "Ndonga",
  "Norwegian Nynorsk",
  "Norwegian",
  "Nuosu",
  "South Ndebele",
  "Occitan",
  "Ojibwe",
  "Oromo",
  "Oriya",
  "Ossetian",
  "Punjabi",
  "Pāli",
  "Persian",
  "Polish",
  "Pashto, Pushto",
  "Portuguese",
  "Quechua",
  "Romansh",
  "Kirundi",
  "Russian",
  "Sanskrit",
  "Sardinian",
  "Sindhi",
  "Northern Sami",
  "Samoan",
  "Sango",
  "Serbian",
  "Scottish Gaelic; Gaelic",
  "Shona",
  "Sinhala, Sinhalese",
  "Slovak",
  "Slovene",
  "Somali",
  "Southern Sotho",
  "Spanish",
  "Sundanese",
  "Swahili",
  "Swati",
  "Swedish",
  "Tamil",
  "Telugu",
  "Tajik",
  "Thai",
  "Tigrinya",
  "Tibetan Standard, Tibetan, Central",
  "Turkmen",
  "Tagalog",
  "Tswana",
  "Tonga (Tonga Islands)",
  "Turkish",
  "Tsonga",
  "Tatar",
  "Twi",
  "Tahitian",
  "Uighur, Uyghur",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Venda",
  "Vietnamese",
  "Volapük",
  "Walloon",
  "Welsh",
  "Wolof",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang, Chuang",
];

export const stateList = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const experienceOptions = [
  { value: "Intern", label: "Intern" },
  { value: "Less than one year", label: "Fresher" },
  { value: "1-3 years", label: "1-3 years" },
  { value: "3-5 years", label: "3-5 years" },
  { value: "5-10 years", label: "5-10 years" },
  { value: "10+ years", label: "10+ years" },
];

export const educationOptions = [
  { value: "Bachelor's Degree", label: "Bachelor's Degree" },
  { value: "Certification", label: "Certification" },
  { value: "Doctorate", label: "Doctorate" },
  { value: "Highschool or Equivalent", label: "Highschool or Equivalent" },
  { value: "Master's Degree", label: "Master's Degree" },
  { value: "Professional", label: "Professional" },
  {
    value: "Some College Coursework Completed",
    label: "Some College Coursework Completed",
  },
  {
    value: "Some Highschool Course Work",
    label: "Some Highschool Course Work",
  },
  { value: "Vocational", label: "Vocational" },
];

export const subscriptionStatusOption = [
  { value: "Subscribed", label: "Subscribed" },
  { value: "Unsubscribed", label: "Unsubscribed" },
];

export const dispositionOptions = [
  { value: "38", label: "Automatched" },
  { value: "33", label: "Invited" },
  { value: "6", label: "Submitted" },
  { value: "42", label: "Engaged" },
  { value: "43", label: "Vetted" },
  { value: "8", label: "Shortlisted" },
  { value: "37", label: "Offered" },
  { value: "11", label: "Hired" },
  { value: "12", label: "Disqualified" },
  { value: "40", label: "Ready To Hire" },
];

export { PageNotFoundImage, SidebarLogo, LoginAppLogo, ButtonLoader };
