import React, { useEffect, useRef } from "react";
import GoogleLocationAPI from "../common/location/GoogleLocationAPI";

const Location = ({ formData, setFormData, errorData, handleChange }) => {
  const searchInput = useRef(null);
  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setFormData({
      ...formData,
      city: city,
      state: address.state,
      country: address.country,
      location: address,
      address: searchInput.current?.value,
      longitude: address.lng,
      latitude: address.lat,
    });
  };
  useEffect(() => {}, [searchInput]);
  return (
    <>
      {/* <div className="row mb-4"> */}
        <div className="">
          <label>Location*</label>
          <div className="form-floating ">
            <GoogleLocationAPI
              className={
                errorData["city"] && formData.experienceLevel == ""
                  ? "form-control border border-danger"
                  : "form-control"
              }
              setLocation={setLocation}
              searchInput={searchInput}
              setFormData={setFormData}
              formData={formData}
            />
          </div>
          {errorData["city"] && formData.city == "" && (
            <div className="validation">{errorData["city"]}</div>
          )}
        </div>
     
    </>
  );
};

export default Location;
