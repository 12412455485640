import React from "react";
import { Modal } from "react-bootstrap";
import ResumeDownload from "./ResumeDownload";

const Index = (props) => {
  const { resume, setResume, resumeData } = props;
  return (
    <Modal
      size="lg"
      show={resume}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <div className="bl-modal-header">
        <h6 className="bl-modal-title">{"Resume Download"}</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setResume(false)}
          className="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <ResumeDownload setResume={setResume} resumeData={resumeData} />
      </Modal.Body>
    </Modal>
  );
};

export default Index;
