import React from "react";

const Education = ({ education, qualification, onCollapsibleClick }) => {
  return (
    <>
      {education && (
        <button
          onClick={() => {
            onCollapsibleClick("qualification");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {!qualification ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Education</small>
          </span>
        </button>
      )}
      <div className={qualification ? "ml-2 ml-0-600 mt-2 mb-1" : "d-none"}>
        <div class="d-flex mb-1 pl-1 ml-4">
          <p>{education}</p>
        </div>
      </div>
    </>
  );
};

export default Education;
