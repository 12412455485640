import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_CANDIDATE_DETAILS_BEGIN = "GET_CANDIDATE_DETAILS_BEGIN";
export const GET_CANDIDATE_DETAILS_SUCCESS = "GET_CANDIDATE_DETAILS_SUCCESS";
export const GET_CANDIDATE_DETAILS_FAILURE = "GET_CANDIDATE_DETAILS_FAILURE";

export const getCandidateDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CANDIDATE_DETAILS_BEGIN,
    });
    const res = await post("/talent/getcandidateprofile", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_CANDIDATE_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CANDIDATE_DETAILS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCandidateOffers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CANDIDATE_DETAILS_BEGIN,
    });
    const res = await get("/job/getjoblist");
    if (res.status === 200) {
      dispatch({
        type: GET_CANDIDATE_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CANDIDATE_DETAILS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
