import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { AsYouType } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import HereMap from "../../../../location/HereMap";
import { post } from "../../../../../service/ApiService";
import { domain } from "../../../../../constants/Constants";
import Loader from "../../../../loader/Loader";
import { editUserValidation } from "../../../../validation/EditUser";
import { getHigh5EditUsersData } from "../../../../../action/dashboard/superadmin/high5users/High5Users";
import { getHigh5UsersData } from "../../../../../action/dashboard/superadmin/high5users/High5Users";

function EditUser({ userlist, closeAdd, addusersCondition }) {
  const dispatch = useDispatch();
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [formChanged, setFormChanged] = useState(false);
  const [form, setForm] = useState({
    ID_user: "",
    email: "",
    firstName: "",
    middleName: "",
    lastname: "",
    addressState: "",
    country: "",
    addressCity: "",
    address: "",
    zipcode: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { editUserData, editLoading } = useSelector(
    (state) => ({
      editUserData: state.getHigh5UsersReducer.editUserData,
      editLoading: state.getHigh5UsersReducer.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    const params = {
      userid: userlist.userid,
    };
    dispatch(getHigh5EditUsersData(params));
  }, []);

  useEffect(() => {
    if (editUserData) {
      setForm(editUserData);
    }
  }, [editUserData]);

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = editUserValidation(form);
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else if (!phoneValidation)
      return toast.error("Please enter a valid phone number");
    else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          domainName: domain,
        };
        const res = await post("/dashboard/sa/updatehigh5user", paramters);
        if (res.status === 200) {
          setLoading(false);
          dispatch(getHigh5UsersData());
          toast.success("User details updated successfully");
          close();
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While updating  User  Details");
      }
    }
  };

  const setField = (field, value) => {
    setFormChanged(true);
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const close = () => {
    closeAdd(true);
  };

  const handleChange = (e) => {
    setFormChanged(true);
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  let type = "";
  if (userlist) {
    type = userlist.type;
  }

  return (
    <>
      {(loading || editLoading) && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                {userlist
                  ? "Edit User"
                  : addusersCondition === true
                  ? "Edit User"
                  : "Add Tenant"}
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="showBody" class="bl-modal-body pb-0">
              <div
                v-if="isAssign === false"
                class="card card-flat card-borderless bg-gray4 text-center"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter role "
                            value={
                              form.roleText == "Customer Success"
                                ? "High5 Admin"
                                : form.roleText == "High5 Coordinator"
                                ? "Customer Success Manager"
                                : form.roleText == "Community Manager"
                                ? "Marketplace Manager"
                                : ""
                            }
                            onChange={(e) =>
                              setField("rolename", e.target.value)
                            }
                            isInvalid={!!errors.rolename}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.Usertype}
                          </Form.Control.Feedback>
                          <label>User Type*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter email "
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            isInvalid={!!errors.email}
                          />
                          <label>Email ID*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            value={form.firstName}
                            onChange={(e) =>
                              setField("firstName", e.target.value)
                            }
                            isInvalid={!!errors.firstName}
                          />
                          <label>First Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            value={form.lastName}
                            onChange={(e) =>
                              setField("lastName", e.target.value)
                            }
                            isInvalid={!!errors.lastName}
                          />
                          <label>Last Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    {(form.rolename === "High5Coordinator" ||
                      form.rolename === "CommunityManager" ||
                      form.rolename === "CustomerSuccess") && (
                      <div className="row mt-4 ">
                        <div className="col-lg-12">
                          <div className="">
                            <PhoneInput
                              inputClass={"form-control font-14 w-100"}
                              required="true"
                              placeholder="mobile"
                              id="homePhone"
                              country={"us"}
                              value={form.mobilePhone}
                              name="homePhone"
                              onChange={(value, country, e) => {
                                _onChangeText(value, country);
                                setForm({
                                  ...form,
                                  mobilePhone: value,
                                  mobilePhoneCode: country.dialCode,
                                });
                                setFormChanged(true);
                              }}
                            />
                          </div>
                          {!phoneValidation && (
                            <small className="validation">Invalid Phone</small>
                          )}
                        </div>
                      </div>
                    )}

                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMap
                          setForm={setForm}
                          form={form}
                          setFormChanged={setFormChanged}
                        />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                            />
                            <label>Address </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressCity"
                              placeholder="Address City"
                              value={form.addressCity}
                              onChange={(e) =>
                                setField("addressCity", e.target.value)
                              }
                              isInvalid={!!errors.zipcode}
                            />
                            <label>City* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.addressCity}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressState"
                              placeholder="Address State"
                              value={form.addressState}
                              onChange={(e) =>
                                setField("addressState", e.target.value)
                              }
                              isInvalid={!!errors.addressState}
                            />
                            <label> State* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.addressState}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="country"
                              placeholder="Address Country"
                              value={form.country}
                              onChange={(e) =>
                                setField("country", e.target.value)
                              }
                              isInvalid={!!errors.country}
                            />
                            <label> Country* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.country}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
                disabled={!formChanged}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditUser;
